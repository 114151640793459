import Color from 'color'

import {Colors, GlobalColors} from './LEGACY_colors'

const LUMINANCE_TRANSFORM_PERCENT = 80

export interface EventColors {
  accentColor: string
  lightMode: boolean
}

/**
 * @interface {Object} EventPagePalette
 * @property {string} accentColor the accent color of the event
 * @property {boolean} lightMode whether the event is in light mode
 * @property {number} accentColorLuminance the luminance of the accent color
 * @property {string} contrastingColor black if the accent color is light, white if the accent color is dark
 * @property {BlurTint} blurTint the color of the blur view tint
 * @property {string} primary the primary color. Used for fills and backgrounds of components
 * @property {string} textPrimary the primary text color. Used for the header and body text
 * @property {string} textSecondary the secondary text color. Used for the subtext and info
 * @property {string} textTertiary the tertiary text color. Used for descriptions and captions
 * @property {string} invertedTextColor the inverted text color. Used for text on top of the primary color
 * @property {string} backgroundPrimary the primary background color. Used for the background of the page
 * @property {string} backgroundSecondary the secondary background color. Used for backgrounds on top of the primary background
 * @property {string} backgroundLight the light background color. Used for the overlays such as the ticket modal
 * @property {string} iconColor the color of the icons. Used for icons on top of the primary color
 * @property {string} inputBackground the background color of the input fields
 * @property {string} inputBackground the background color of the input fields
 * @property {string} visualsInputBackground the background color of the input fields in the visuals editor section (create event too)
 * @property {Object} button the colors of the buttons
 * @property {string} button.backgroundDisabled the background color of the disabled button
 * @property {string} button.backgroundActive the background color of the active button
 * @property {string} button.backgroundActivePrimary the background color of the active button when the button is the primary color
 * @property {string} button.textDisabled the text color of the disabled button
 * @property {string} button.textActive the text color of the active button
 * @property {string} button.textActivePrimary the text color of the active button when the button is the primary color
 * @property {string} button.iconDisabled the icon color of the disabled button
 * @property {string} button.iconDisabled the icon color of the disabled button
 * @property {string} button.iconActive the icon color of the active button
 * @property {string} button.iconActivePrimary the icon color of the active button when the button is the primary color
 * @property {string} button.iconActiveSecondary the icon color of the active button when the button is the secondary color
 * @property {Object} glowShadow the accent color shadow used throughout many components
 * @param {EventColors} EventColors the lightMode and accentColor of the event
 */
export interface EventPagePalette extends EventColors {
  accentColorLuminance: number
  contrastingColor: string
  primary: string
  textPrimary: string
  textSecondary: string
  textTertiary: string
  invertedTextColor: string
  backgroundPrimary: string
  backgroundSecondary: string
  backgroundLight: string
  backgroundLighter: string
  inputBackground: string
  visualsInputBackground: string
  iconColor: string
  contrastingGrey: string
  button: {
    backgroundDisabled: string
    backgroundActive: string
    backgroundActivePrimary: string
    backgroundActiveMuted: string
    textDisabled: string
    textActive: string
    textActivePrimary: string
    iconDisabled: string
    iconActive: string
    iconActivePrimary: string
    iconActiveSecondary: string
  }
}

/**
 *
 * @param EventColors the lightMode and accentColor of the event
 * @returns {EventPagePalette} the palette for the event page.
 */
export const getEventPagePalette = ({lightMode, accentColor}: EventColors): EventPagePalette => {
  const accentColorLuminance = Color(accentColor).luminosity()
  const accentColorIsLight = accentColorLuminance > 0.5
  return {
    accentColor,
    lightMode,
    accentColorLuminance,
    contrastingColor: accentColorIsLight ? Colors.black : Colors.white,
    primary: accentColor,

    // Leaving these commented out so we know what has changed.
    // This will be important in polishing the palette.

    // textPrimary: lightMode ? accentColor : Colors.white,
    textPrimary: lightMode ? '#222222' : '#EEEEEE',
    // textSecondary: accentColor,
    textSecondary: lightMode ? '#333333' : '#999999',

    textTertiary: lightMode ? Colors.grey : Colors.greyLight,
    invertedTextColor: lightMode ? Colors.black : Colors.white,

    // backgroundPrimary: lightMode ? Colors.white : GlobalColors.background,
    backgroundPrimary: lightMode ? '#EEEEEE' : GlobalColors.background,

    backgroundSecondary: lightMode
      ? Color(accentColor).lightness(LUMINANCE_TRANSFORM_PERCENT).hex()
      : Color('black')
          .lightness(LUMINANCE_TRANSFORM_PERCENT / 16)
          .hex(),
    contrastingGrey: lightMode ? Colors.greyLight : Colors.backgroundLight,
    backgroundLight: lightMode ? Colors.offWhite : Colors.backgroundBlack,
    backgroundLighter: lightMode ? Colors.systemGray4 : Colors.systemGray7,
    visualsInputBackground: lightMode ? Colors.systemGray4 : Colors.systemGray3,
    inputBackground: lightMode ? '#8E8E9330' : Colors.systemGray7,
    iconColor: accentColorIsLight ? Colors.black : Colors.white,
    button: {
      backgroundDisabled: lightMode ? Colors.systemGray : Colors.backgroundLight,
      backgroundActive: lightMode ? accentColor : Colors.backgroundBlack,
      backgroundActiveMuted: lightMode ? accentColor : Colors.black,
      backgroundActivePrimary: accentColor,
      textDisabled: lightMode ? Colors.systemGray2 : Colors.systemGray,
      textActive: lightMode ? (accentColorIsLight ? Colors.black : Colors.white) : accentColor,
      textActivePrimary: lightMode ? Colors.white : Colors.black,
      iconDisabled: Colors.greyLight,
      iconActive: lightMode ? (accentColorIsLight ? Colors.black : Colors.white) : accentColor,
      iconActivePrimary: lightMode ? Colors.black : Colors.white,
      iconActiveSecondary: lightMode ? Colors.white : Colors.black,
    },
  }
}
