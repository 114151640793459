import React from 'react'

import {EventsTableListItemActionsIcon} from './Icon'
import {EventRowAction, EventsTableListItemProps} from './types'

import '../index.scss'

export function EventsTableListItemActions(props: EventsTableListItemProps) {
  const {
    item,
    status,
    isHost,
    isLoading,
    updateEventStatus,
    trackMixpanelEvent,
    onRowAction,
    showActions = true,
  } = props
  const hasAttendees = item.attending.length > 0
  const eventId = item._id as string

  if (!showActions) return null

  return (
    <div className='EventTable-row-actions'>
      <EventsTableListItemActionsIcon.RevertToDraft
        isHidden={status !== 'live' || hasAttendees || isHost}
        isLoading={isLoading}
        onClick={() => {
          updateEventStatus('draft', eventId)
          trackMixpanelEvent('Revert Event to Draft-  Curator Event List Table item', {eventId})
        }}
      />
      <EventsTableListItemActionsIcon.GoLive
        isLoading={isLoading}
        isHidden={status !== 'draft' || isHost}
        onClick={() => {
          updateEventStatus('live', eventId)
          trackMixpanelEvent('Go Live Event-  Curator Event List Table item', {eventId})
        }}
      />
      <EventsTableListItemActionsIcon.EditEvent
        onClick={() => {
          trackMixpanelEvent('Edit Event-  Curator Event List Table item', {eventId: item._id})
          onRowAction && onRowAction(EventRowAction.EDIT, item._id)
        }}
      />
      <EventsTableListItemActionsIcon.ViewEvent
        onClick={() => {
          trackMixpanelEvent('View Event-  Curator Event List Table item', {eventId: item._id})
          onRowAction && onRowAction(EventRowAction.VIEW, item._id)
        }}
      />
      <EventsTableListItemActionsIcon.DuplicateEvent
        isHidden={isHost}
        onClick={() => {
          trackMixpanelEvent('Duplicate Event-  Curator Event List Table item', {eventId: item._id})
          onRowAction && onRowAction(EventRowAction.DUPLICATE, item._id)
        }}
      />
      <EventsTableListItemActionsIcon.DeleteEvent
        isHidden={isHost || hasAttendees}
        onClick={() => {
          trackMixpanelEvent('Delete Event-  Curator Event List Table item', {eventId: item._id})
          onRowAction && onRowAction(EventRowAction.DELETE, item._id)
        }}
      />
    </div>
  )
}
