import {TGroupSmsOptIn} from '@posh/sms-opt-ins'
import {SmsOptInOptions} from 'hooks/optIns'
import {produce} from 'immer'
import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

import {GetAccountOptInsInput} from './useGetAccountOptIns'

type UpdateGroupOptInOptions = ReactQueryOptions['optIns']['updateGroupOptIn']
export type UpdateGroupOptInInput = RouterInputs['optIns']['updateGroupOptIn']
export type UpdateGroupOptInOutput = RouterOutput['optIns']['updateGroupOptIn']

/**
 * If the previous value is NoSubaccount we will not update the value
 * This function will create a copy of the previous opt-in and update the value
 * @param prev - TGroupSmsOptIn - The previous opt-in
 * @param value - boolean - The new value
 * @returns TGroupSmsOptIn - The new opt-in
 */
export const updateGroupSmsOptIn = (prev: TGroupSmsOptIn, value: boolean) =>
  produce(prev, draft => {
    if (!draft.optIns) return
    if (draft.optInType !== 'group-sms') return
    if (draft.optIns?.smsOptIn === SmsOptInOptions.NoSubaccount) return
    draft.optIns.smsOptIn = value ? SmsOptInOptions.Yes : SmsOptInOptions.NoApplication
  })

export interface UpdateGroupOptInOpts extends UpdateGroupOptInOptions {
  query?: GetAccountOptInsInput
}

export function useUpdateGroupOptIn(opts?: UpdateGroupOptInOpts) {
  const {getAccountOptIns} = trpc.useContext().optIns

  return trpc.optIns.updateGroupOptIn.useMutation({
    ...opts,
    onMutate: async input => {
      if (input && input.optInType === 'group-sms' && input.group) {
        await getAccountOptIns.cancel()
        const prevAccountOptInsWithID = getAccountOptIns.getData(opts?.query)
        getAccountOptIns.setData(opts?.query, old =>
          produce(old, draft => {
            draft?.data.forEach(optIn => {
              if (optIn.id === input.id) {
                Object.assign(optIn, input)
              }
            })
          }),
        )
        return {prevAccountOptInsWithID}
      }
    },
    onError: (error, input, context) => {
      if (context) {
        getAccountOptIns.setData(opts?.query, context.prevAccountOptInsWithID)
      }
      if (opts?.onError) opts.onError(error, input, context)
    },
    onSettled: data => {
      if (data) {
        getAccountOptIns.invalidate()
      }
    },
  })
}
