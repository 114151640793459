// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MultiSessionEventListContainer__aSNeH{max-height:400px;overflow-y:auto;display:flex;flex-direction:column;width:100%;gap:10px}.MultiSessionEventListContainer__aSNeH::-webkit-scrollbar{background-color:rgba(0,0,0,0)}.MultiSessionEventListItem__jMCSL{display:flex;justify-content:space-between;gap:10px;align-items:center;background-color:rgba(255,255,255,.1);padding:10px;border-radius:5px}", "",{"version":3,"sources":["webpack://./src/pages/CreateEvent/components/LaunchModal/components/MultiSessionEventList.module.scss"],"names":[],"mappings":"AAAA,uCACE,gBAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,QAAA,CAGF,0DACE,8BAAA,CAGF,kCACE,YAAA,CACA,6BAAA,CACA,QAAA,CACA,kBAAA,CACA,qCAAA,CACA,YAAA,CACA,iBAAA","sourcesContent":[".MultiSessionEventListContainer {\n  max-height: 400px;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 10px;\n}\n\n.MultiSessionEventListContainer::-webkit-scrollbar {\n  background-color: transparent;\n}\n\n.MultiSessionEventListItem {\n  display: flex;\n  justify-content: space-between;\n  gap: 10px;\n  align-items: center;\n  background-color: rgba(255, 255, 255, 0.1);\n  padding: 10px;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MultiSessionEventListContainer": "MultiSessionEventListContainer__aSNeH",
	"MultiSessionEventListItem": "MultiSessionEventListItem__jMCSL"
};
export default ___CSS_LOADER_EXPORT___;
