import {WebSessionAccount} from '@posh/models'
import {captureException} from '@sentry/react'
import {TFetchCheckoutSummaryOutput} from 'apis/Checkout/useFetchCheckoutSummary'
import {EventPublic} from 'apis/Events/types'
import {trackPurchaseInitiated} from 'apis/FbPixelHelpers'

import {pushToDataLayer} from '../dataLayerUtils'

export const trackPurchaseAttempt = ({
  event,
  cart,
  user,
}: {
  event: EventPublic
  cart: {
    cartId: string
    cartInfo: TFetchCheckoutSummaryOutput['cartInfo']
    cartTicketCount: number
  }
  user?: WebSessionAccount
}) => {
  try {
    const currency = cart.cartInfo.currency ?? 'USD'

    if (event.fbPixelId && cart.cartInfo) {
      trackPurchaseInitiated({
        pixelId: event.fbPixelId,
        eventName: event.name,
        currency,
        subtotal: cart.cartInfo.subtotal,
        ticketCount: cart.cartTicketCount,
      })
    }

    const purchaseAttemptEventData = {
      event: 'purchaseAttempt', // Mandatory event name
      eventId: event?.id,
      eventName: event?.name,
      currency,
      subtotal: cart.cartInfo.subtotal,
      total: cart.cartInfo.total,
      ticketCount: cart.cartTicketCount,
      cartId: cart.cartId,
      purchaserEmail: user?.email,
      purchaserId: user?._id.toString(),
      purchaserName: `${user?.firstName} ${user?.lastName}`,
      purchaserPhone: user?.phone,
    }

    // Push the event data to the dataLayer event
    pushToDataLayer(purchaseAttemptEventData)
  } catch (error) {
    captureException(error)
  }
}
