// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EventVisualsSection__8EZds{gap:10px;display:grid;width:100%;transition:.3s}.EventVisualsSection__8EZds.hidden__fwp3L{opacity:33%}.HeaderButtonContainer__12m8p{display:flex;flex-direction:row;gap:10px}.TicketSection__Qapmb{display:flex;flex-direction:column;gap:10px}.header__kZbFP{border-bottom:1px solid gray;width:100%;display:flex;justify-content:space-between}.header__kZbFP.expandable__hgEWO{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/PageComponents/EventVisuals/Page/Section/styles.module.scss"],"names":[],"mappings":"AAAA,4BACI,QAAA,CACA,YAAA,CACA,UAAA,CACA,cAAA,CACA,0CACI,WAAA,CAIR,8BACI,YAAA,CACA,kBAAA,CACA,QAAA,CAIJ,sBACI,YAAA,CACA,qBAAA,CACA,QAAA,CAEJ,eACI,4BAAA,CACA,UAAA,CACA,YAAA,CACA,6BAAA,CACA,iCACI,cAAA","sourcesContent":[".EventVisualsSection {\n    gap: 10px;\n    display: grid;\n    width: 100%;\n    transition: .3s;\n    &.hidden {\n        opacity: 33%;\n    }\n}\n\n.HeaderButtonContainer {\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n}\n\n\n.TicketSection {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n.header {\n    border-bottom: 1px solid gray;\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    &.expandable {\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EventVisualsSection": "EventVisualsSection__8EZds",
	"hidden": "hidden__fwp3L",
	"HeaderButtonContainer": "HeaderButtonContainer__12m8p",
	"TicketSection": "TicketSection__Qapmb",
	"header": "header__kZbFP",
	"expandable": "expandable__hgEWO"
};
export default ___CSS_LOADER_EXPORT___;
