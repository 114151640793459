/* eslint-disable */
import React from 'react'

import './styles.scss'

import Footer from 'components/DashboardFooter'
import {SafeMarkdownDisplay} from 'components/Text/SafeMarkdownDisplay'

export default function PrivacyPolicy() {
  const text = `# **POSH™ Privacy Policy**

Last Modified March 10,2025.  
This Privacy Policy describes how Posh Group, Inc. (“Posh,” “we,” “us” or “our”) processes personal information that we collect through our digital or online properties or services that link to this Privacy Policy (including as applicable, our https://posh.vip/ website, mobile application, social media pages, marketing activities, live events and other activities described in this Privacy Policy (collectively, the “Service”)). Posh may provide additional or supplemental privacy policies to individuals for specific products or services that we offer at the time we collect personal information.
Index

Personal information we collect
Personal Information you provide to us. Personal information you may provide to us through the Service or that we may generate about you includes:
Event Organizer data. In addition to the categories of personal information identified above, we, our service providers, and our business partners may collect personal information from individuals who choose to create events through the Service (each, an “Event Organizer”), such as:
Third-party sources. We may combine personal information we receive from you with personal information we obtain from other sources, such as:
Public sources, such as government agencies, public records, social media platforms, and other publicly available sources.
Marketing partners, such as joint marketing partners and event co-sponsors.
Third-party services, such as social media services, that you use to log into, or otherwise link to your Service account. This data may include your username, profile picture and other information associated with your account on that third-party service that is made available to us based on your account settings on that service. For example, we may receive your personal data through Google’s YouTube API Services if you choose to embed a YouTube video on the Service. You can learn more about Google’s privacy practices by reviewing the Privacy Policy at http://www.google.com/policies/privacy.
Automatic data collection. We, our service providers, and our business partners may automatically log information about you, your computer or mobile device, and your interaction over time with the Service, our communications and other online services, such as:
For more information concerning our automatic collection of data, please see the Tracking technologies section below.

Tracking Technologies
Cookies and other technologies. Some of the automatic collection described above is facilitated by the following technologies:
How we use your personal information
Service delivery and operations. We may use your personal information to:
Service improvement and analytics. We may use your personal information to analyze your usage of the Service, improve the Service, improve the rest of our business, help us understand user activity on the Service, including which pages are most and least visited and how visitors move around the Service, as well as user interactions with our emails, and to develop new products and services. For example, we use Google Analytics for this purpose. You can learn more about Google Analytics and how to prevent the use of Google Analytics relating to your use of our sites here: https://tools.google.com/dlpage/gaoptout?hl=en.
Research and development. We may use your personal information for research and development purposes, including to analyze and improve the Service and our business and to develop new products and services.
Marketing and advertising. We, our service providers and our third-party advertising partners may collect and use your personal information for marketing and advertising purposes:
Direct marketing. We may send you direct marketing communications and may personalize these messages based on your needs and interests. You may opt-out of our marketing communications as described in the Opt-out of marketing section below.
Interest-based advertising. Our third-party advertising partners may use cookies and similar technologies to collect information about your interaction (including the data described in the automatic data collection section above) with the Service, our communications and other online services over time, and use that information to serve online ads that they think will interest you. This is called interest-based advertising. We may also share information about our users with these companies to facilitate interest-based advertising to those or similar users on other online platforms. You can learn more about your choices for limiting interest-based advertising in the Your choices section below.
Compliance and protection. We may use your personal information to:
To create aggregated, de-identified and/or anonymized data. We may create aggregated, de-identified and/or anonymized data from your personal information and other individuals whose personal information we collect. We make personal information into de-identified and/or anonymized data by removing information that makes the data identifiable to you.  We may use this aggregated, de-identified and/or anonymized data and share it with third parties for our lawful business purposes, including to analyze and improve the Service and promote our business.  We will only maintain and use such data in an aggregated, de-identified and/or anonymized fashion, as applicable, and will not attempt to reidentify any such data except as permitted under applicable laws.
How we share your personal information
Your choices
Access or update your information. If you have registered for an account with us through the Service, you may review and update certain account information by logging into the account.
Opt-out of communications. You may opt-out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of the email, or by contacting us. Please note that if you choose to opt-out of marketing-related emails, you may continue to receive service-related and other non-marketing emails.
If you choose to receive text messages from us, you may opt out of receiving further text messages from us by replying STOP to our message. You may also text STOP to [SHORTCODE].
Blocking images/clear gifs. Most browsers and devices allow you to configure your device to prevent images from loading. To do this, follow the instructions in your particular browser or device settings.
Mobile location data. You can disable our access to your device’s precise geolocation in your mobile device settings.
Privacy settings. If you have an account on the Service, you can control whether your profile is public and other settings by visiting “Privacy Settings” within your account.
Do Not Track. Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We currently do not respond to “Do Not Track” signals. To find out more about “Do Not Track,” please visit http://www.allaboutdnt.com.
Declining to provide information. We need to collect personal information to provide certain services. If you do not provide the information we identify as required or mandatory, we may not be able to provide those services.
Linked third-party platforms. If you choose to connect to the Service through a third-party platform, such as a social media service, or otherwise link your Service account to, a social media or other third-party service, you may be able to use your settings in your account with the third party to limit the information we receive from it. For example, if you would like to revoke access to or delete data provided to us through Google’s YouTube API Services, you can do so via the Google security settings page at https://security.google.com/settings/security/permissions. Please note that deleting this data does not affect data about you that is already stored by YouTube. If you would like to exercise choices about data stored or otherwise processed by YouTube, please contact YouTube directly (http://www.google.com/policies/privacy).
Other sites and services
The Service may contain links to websites, mobile applications, and other online services operated by third parties. In addition, our content may be integrated into web pages or other online services that are not associated with us. These links and integrations are not an endorsement of, or representation that we are affiliated with, any third party. We do not control websites, mobile applications or online services operated by third parties, and we are not responsible for their actions. We encourage you to read the privacy policies of the other websites, mobile applications and online services you use.
Security
We employ technical, organizational and physical safeguards designed to protect the personal information we collect. However, security risk is inherent in all internet and information technologies, and we cannot guarantee the security of your personal information.
International data transfer
We are headquartered in the United States and may use service providers that operate in other countries. Your personal information may be transferred to the United States or other locations where privacy laws may not be as protective as those in your state, province, or country.
Children
The Service is not intended for use by anyone under 16 years of age. If you are a parent or guardian of a child from whom you believe we have collected personal information in a manner prohibited by law, please contact us. If we learn that we have collected personal information through the Service from a child without the consent of the child’s parent or guardian as required by law, we will comply with applicable legal requirements to delete the information.
Changes to this Privacy Policy
We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on the Service or other appropriate means. Any modifications to this Privacy Policy will be effective upon our posting the modified version (or as otherwise indicated at the time of posting). In all cases, your use of the Service after the effective date of any modified Privacy Policy indicates your acknowledging that the modified Privacy Policy applies to your interactions with the Service and our business.
How to contact us
Personal information we collect
Personal Information you provide to us. Personal information you may provide to us through the Service or that we may generate about you includes:
Contact data, such as your first and last name, salutation, email address, billing and mailing addresses, and phone number.
Demographic data, such as your city, state, country of residence, and postal code.
Profile data, such as the username and password that you may set to establish an online account on the Service, biographical details, photograph or picture, links to your profiles on social networks, interests, phone number, preferences, and any other information that you add to your account profile.  
Communications data based on our exchanges with you, including when you contact us through the Service, communicate with us via chat features, social media, or otherwise. 
Marketing data, such as your preferences for receiving our marketing communications and details about your engagement with them.
Payment data, such as payment card information needed to complete transactions, which is collected by our payment processor, Stripe. Posh does not receive your payment card information.
Transactional data, such as information relating to or needed to complete your orders on or through the Service, including order numbers and transaction history. 
User-generated content data, such as photos, images, music, videos, comments, questions, messages, works of authorship, information about your attendance at events, and other content or information that you generate, transmit, or otherwise make available on the Service, as well as associated metadata. Metadata includes information on how, when, where and by whom a piece of content was collected and how that content has been formatted or edited. Metadata also includes information that users can add or can have added to their content, such as keywords, geographical or location information, and other similar data.
Contacts data, with your opt-in consent, you may choose to provide the Service with access to your contacts.
Biometric data, such as images of your face.
Other data not specifically listed here, which we will use as described in this Privacy Policy or as otherwise disclosed at the time of collection.
Event Organizer data. In addition to the categories of personal information identified above, we, our service providers, and our business partners may collect personal information from individuals who choose to create events through the Service (each, an “Event Organizer”), such as:
Event data, such as information relating to or needed to complete your creation of an event, including the name of your event, the name and address of the venue where your event is hosted, features of your event, registered attendees of your event, and a description of your event. 
Business details data, such as your business website, industry, terms of service link, representative name and email address, date of birth, bank account number, and other information you provide in connection with organizing an event.
Government-issued identification number data, such as national identification number (e.g., Social Security Number or tax identification number), which is collected by our payment processor, Stripe, if you choose to participate in our Event Organizer onboarding process.
Capital advance data, such as information you provide when you apply for and/or participate in Posh’s capital advance program, including information about you and/or your company, the capital advance you request, your stated income, economic standing, bank account numbers, or bank statements, which is collected by our financial partner, Plaid. 

Third-party sources. We may combine personal information we receive from you with personal information we obtain from other sources, such as:
Public sources, such as government agencies, public records, social media platforms, and other publicly available sources.
Marketing partners, such as joint marketing partners and event co-sponsors.
Business transaction partners. We may receive personal information in connection with an actual or prospective business transaction. For example, we may receive your personal information from an entity we acquire or are acquired by, a successor, or assignee or any party involved in a business transaction such as a merger, acquisition, sale of assets, or similar transaction, and/or in the context of an insolvency, bankruptcy, or receivership.
Other users, such as your friends or contacts who may use the Service to invite you to use the Service yourself by providing us with certain of your contact details. See the “Data about others” section below.
Third-party services, such as social media services, that you use to log into, or otherwise link to your Service account. This data may include your username, profile picture and other information associated with your account on that third-party service that is made available to us based on your account settings on that service. For example, we may receive your personal data through Google’s YouTube API Services if you choose to embed a YouTube video on the Service. You can learn more about Google’s privacy practices by reviewing the Privacy Policy at http://www.google.com/policies/privacy. 
Automatic data collection. We, our service providers, and our business partners may automatically log information about you, your computer or mobile device, and your interaction over time with the Service, our communications and other online services, such as:
Device data, such as your computer or mobile device’s operating system type and version, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address, unique identifiers (including identifiers used for advertising purposes), language settings, mobile device carrier, radio/network information (e.g., Wi-Fi, LTE, 3G), and general location information such as city, state or geographic area.
Online activity data, such as pages or screens you viewed, how long you spent on a page or screen, the website you visited before browsing to the Service, navigation paths between pages or screens, information about your activity on a page or screen, access times and duration of access, and whether you have opened our emails or clicked links within them.
Precise geolocation data when you authorize (our mobile application/the Service) to access your device’s location.
Communication interaction data such as your interactions with our email, text or other communications (e.g., whether you open and/or forward emails) – we may do this through use of pixel tags (which are also known as clear GIFs), which may be embedded invisibly in our emails. 
For more information concerning our automatic collection of data, please see the Tracking technologies section below.
Data about others. We may offer features that allow you to invite your friends or contacts to use the Service, and we may collect contact details about these invitees from you so we can deliver their invitations. Please do not refer someone to us or share their contact details with us unless you have their consent to do so. 
Tracking Technologies
Cookies and other technologies. Some of the automatic collection described above is facilitated by the following technologies:
Cookies, which are small text files that websites store on user devices and that allow web servers to record users’ web browsing activities and remember their submissions, preferences, and login status as they navigate a site. Cookies used on our sites include both “session cookies” that are deleted when a session ends, “persistent cookies” that remain longer, “first party” cookies that we place and “third party” cookies that our third-party business partners and service providers place. 
Local storage technologies, like HTML5, that provide cookie-equivalent functionality but can store larger amounts of data on your device outside of your browser in connection with specific applications. 
Web beacons, also known as pixel tags or clear GIFs, which are used to demonstrate that a webpage or email was accessed or opened, or that certain content was viewed or clicked. 
Session-replay technologies, such as those provided by HotJar and Datadog, that employ software code to record users’ interactions with the Services in a manner that allows us to watch video replays of those user sessions. The replays include users’ clicks, mobile app touches, mouse movements, scrolls and keystrokes/key touches during those sessions. These replays help us diagnose usability problems and identify areas for improvement. You can learn more about HotJar by reviewing their privacy policy: https://www.hotjar.com/legal/policies/privacy/. You can learn more about Datadog by reviewing their privacy policy: https://www.datadoghq.com/legal/privacy/. 
Chat technologies, such as those provided by Zendesk that employ cookies and software code to operate the chat features that you can use to communicate with us through the Service. Zendesk and other third parties may access and use information about webpages visited on our website, your IP address, your general geographic information (e.g., city, state), and other personal information you share through online chats for the purposes described in this Privacy Policy.

How we use your personal information
We may use your personal information for the following purposes or as otherwise described at the time of collection:
Service delivery and operations. We may use your personal information to:
provide, operate and improve the Service and our business;
personalize the Service, including remembering the devices from which you have previously logged in and remembering your selections and preferences as you navigate the Service;
establish and maintain your user profile on the Service;
facilitate your invitations to friends who you want to invite to join the Service; 
facilitate social features of the Service, such as by identifying and suggesting connections with other users of the Service and providing chat or messaging functionality;
enable security features of the Service, such as by sending you security codes via email or SMS, and remembering devices from which you have previously logged in;
communicate with you about the Service, including by sending Service-related announcements, updates, security alerts, and support and administrative messages;
communicate with you about events or contests in which you participate;
understand your needs and interests, and personalize your experience with the Service and our communications; and
provide support for the Service, and respond to your requests, questions and feedback.
Service improvement and analytics. We may use your personal information to analyze your usage of the Service, improve the Service, improve the rest of our business, help us understand user activity on the Service, including which pages are most and least visited and how visitors move around the Service, as well as user interactions with our emails, and to develop new products and services. For example, we use Google Analytics for this purpose. You can learn more about Google Analytics and how to prevent the use of Google Analytics relating to your use of our sites here: https://tools.google.com/dlpage/gaoptout?hl=en. 
Research and development. We may use your personal information for research and development purposes, including to analyze and improve the Service and our business and to develop new products and services.
Marketing and advertising. We, our service providers and our third-party advertising partners may collect and use your personal information for marketing and advertising purposes:
Direct marketing. We may send you direct marketing communications and may personalize these messages based on your needs and interests. You may opt-out of our marketing communications as described in the Opt-out of marketing section below.  
Interest-based advertising. Our third-party advertising partners may use cookies and similar technologies to collect information about your interaction (including the data described in the automatic data collection section above) with the Service, our communications and other online services over time, and use that information to serve online ads that they think will interest you. This is called interest-based advertising. We may also share information about our users with these companies to facilitate interest-based advertising to those or similar users on other online platforms. You can learn more about your choices for limiting interest-based advertising in the Your choices section below. 
Compliance and protection. We may use your personal information to:
comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas, investigations or requests from government authorities;
protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims); 
audit our internal processes for compliance with legal and contractual requirements or our internal policies; 
enforce the terms and conditions that govern the Service; and 
prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity, including cyberattacks and identity theft.  
Data sharing in the context of corporate events, we may share certain personal information in the context of actual or prospective corporate events – for more information, see How we share your personal information, below.
To create aggregated, de-identified and/or anonymized data. We may create aggregated, de-identified and/or anonymized data from your personal information and other individuals whose personal information we collect. We make personal information into de-identified and/or anonymized data by removing information that makes the data identifiable to you.  We may use this aggregated, de-identified and/or anonymized data and share it with third parties for our lawful business purposes, including to analyze and improve the Service and promote our business.  We will only maintain and use such data in an aggregated, de-identified and/or anonymized fashion, as applicable, and will not attempt to reidentify any such data except as permitted under applicable laws.
With your consent. In some cases, we may specifically ask for your consent to collect, use or share your personal information, such as when required by law.  
Retention
We generally retain personal information to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements, to establish or defend legal claims, or for fraud prevention purposes. To determine the appropriate retention period for personal information, we may consider factors such as the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve those purposes through other means, and the applicable legal requirements.   
When we no longer require the personal information we have collected about you, we may either delete it, anonymize it, or isolate it from further processing. 
How we share your personal information
We may share your personal information with the following parties and as otherwise described in this Privacy Policy, in other applicable notices, or at the time of collection.  
Service providers. Third parties that provide services on our behalf or help us operate the Service or our business (such as hosting, information technology, customer support, online chat functionality providers, email delivery, consumer research and website analytics). 
Payment processors. Any payment card information you use to make a purchase or to pay fees on the Service is collected and processed directly by our payment processors, such as Stripe. Stripe may use your payment data in accordance with its privacy policy, https://stripe.com/privacy. You may also sign up to be billed by your mobile communications provider, who may use your payment data in accordance with their privacy policies.
Advertising partners. Third-party advertising companies for the interest-based advertising purposes described above. 
Third parties designated by you. Third parties where you have instructed us or provided your consent to do so. For example, if you are an Event Organizer, we may share your personal information with the third-party venues, DJs, photographers, security teams, or other third parties that you have chosen to provide services for your event. We will share personal information that is needed for these other companies to provide the services that you have requested. 
Business and marketing partners. Third parties with whom we co-sponsor events, with whom we jointly offer products or services, or whose products or services may be of interest to you.
Linked third-party services. If you log into the Service with, or otherwise link your Service account to, a social media or other third-party service, we may share your personal information with that third-party service. The third party’s use of the shared information will be governed by its privacy policy and the settings associated with your account with the third-party service.
Professional advisors. Professional advisors, such as lawyers, auditors, bankers and insurers, where necessary in the course of the professional services that they render to us.
Authorities and others. Law enforcement, government authorities, and private parties, as we believe in good faith to be necessary or appropriate for the Compliance and protection purposes described above. 
Business transferees. We may disclose personal information in the context of actual or prospective business transactions (e.g., investments in Posh, financing of Posh, public stock offerings, or the sale, transfer or merger of all or part of our business, assets or shares), for example, we may need to share certain personal information with prospective counterparties and their advisers. We may also disclose your personal information to an acquirer, successor, or assignee of Posh as part of any merger, acquisition, sale of assets, or similar transaction, and/or in the event of an insolvency, bankruptcy, or receivership in which personal information is transferred to one or more third parties as one of our business assets.
Other registered users. Your user-generated content data is visible to other registered users of the Service. For example, other registered users of the Service may have access to your information if you chose to make your profile or other personal information available to them through the Service, such as when you provide comments or share other content. This information can be seen, collected and used by others, including being cached, copied, screen captured or stored elsewhere by others (e.g., search engines), and we are not responsible for any such use of this information. Additionally, if you choose to register for an event through the Service, your personal information may be provided to the Event Organizer of that event. 
Your choices 
In this section, we describe the rights and choices available to all users. 
Access or update your information. If you have registered for an account with us through the Service, you may review and update certain account information by logging into the account. 
Opt-out of communications. You may opt-out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of the email, or by contacting us. Please note that if you choose to opt-out of marketing-related emails, you may continue to receive service-related and other non-marketing emails.  
If you choose to receive text messages from us, you may opt out of receiving further text messages from us by replying STOP to our message. You may also text STOP to [SHORTCODE].
Blocking images/clear gifs. Most browsers and devices allow you to configure your device to prevent images from loading. To do this, follow the instructions in your particular browser or device settings.
Mobile location data. You can disable our access to your device’s precise geolocation in your mobile device settings.
Privacy settings. If you have an account on the Service, you can control whether your profile is public and other settings by visiting “Privacy Settings” within your account.
Do Not Track. Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We currently do not respond to “Do Not Track” signals. To find out more about “Do Not Track,” please visit http://www.allaboutdnt.com.
Declining to provide information. We need to collect personal information to provide certain services. If you do not provide the information we identify as required or mandatory, we may not be able to provide those services.
Linked third-party platforms. If you choose to connect to the Service through a third-party platform, such as a social media service, or otherwise link your Service account to, a social media or other third-party service, you may be able to use your settings in your account with the third party to limit the information we receive from it. For example, if you would like to revoke access to or delete data provided to us through Google’s YouTube API Services, you can do so via the Google security settings page at https://security.google.com/settings/security/permissions. Please note that deleting this data does not affect data about you that is already stored by YouTube. If you would like to exercise choices about data stored or otherwise processed by YouTube, please contact YouTube directly (http://www.google.com/policies/privacy). 
Delete your content or close your account. You can choose to delete certain content through your account. If you wish to request to close your account, please contact us.
Other sites and services
The Service may contain links to websites, mobile applications, and other online services operated by third parties. In addition, our content may be integrated into web pages or other online services that are not associated with us. These links and integrations are not an endorsement of, or representation that we are affiliated with, any third party. We do not control websites, mobile applications or online services operated by third parties, and we are not responsible for their actions. We encourage you to read the privacy policies of the other websites, mobile applications and online services you use.
Security 
We employ technical, organizational and physical safeguards designed to protect the personal information we collect. However, security risk is inherent in all internet and information technologies, and we cannot guarantee the security of your personal information. 
International data transfer
We are headquartered in the United States and may use service providers that operate in other countries. Your personal information may be transferred to the United States or other locations where privacy laws may not be as protective as those in your state, province, or country.  
Children  
The Service is not intended for use by anyone under 16 years of age. If you are a parent or guardian of a child from whom you believe we have collected personal information in a manner prohibited by law, please contact us. If we learn that we have collected personal information through the Service from a child without the consent of the child’s parent or guardian as required by law, we will comply with applicable legal requirements to delete the information.
Changes to this Privacy Policy 
We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on the Service or other appropriate means. Any modifications to this Privacy Policy will be effective upon our posting the modified version (or as otherwise indicated at the time of posting). In all cases, your use of the Service after the effective date of any modified Privacy Policy indicates your acknowledging that the modified Privacy Policy applies to your interactions with the Service and our business.
How to contact us
Email: support@posh.vip 
Mail: Posh Group Inc. 110 Greene Street, Suite 10N New York, NY 10012 
  `

  return (
    <div className='PrivacyPolicy'>
      <div className='info-page-cont'>
        <SafeMarkdownDisplay className='PrivacyPolicy-markdown' source={text} />
      </div>
      <Footer />
    </div>
  )
}
