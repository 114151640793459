// This is the pure table component. It does not include any header (other than the column headers)
// but does include pagination and a footer.

import React, {useEffect, useRef, useState} from 'react'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'

import {flexRender, Table as TTable} from '@tanstack/react-table'
import classNames from 'classnames'
import {isNumber} from 'lodash'
import moment from 'moment'

import {useDeviceType} from '../../../hooks/useDeviceType'
import {SixGrip} from '../../assets/Icons'
import {SpinLoader} from '../../Loaders/SpinLoader'
import {TableButton} from '../TableButton/TableButton'
import {TableIcon} from '../TableIcons/TableIcon'

import styles from './Table.module.scss'

const TABLE_ROW_HEIGHT = 40
const TABLE_HEADER_HEIGHT = 35
const BORDER_HEIGHT = 1

const FULL_COL_SPAN = 100
const ICON_SIZE = 14
const DRAG_HANDLE_WIDTH = 24

export interface TableProps<T> {
  table: TTable<T>
  data: T[]
  onClickRow?: (row: T) => void
  itemsPerPage?: number
  refresh: () => void
  lastUpdated: number
  CTAComponent?: React.FC
  EmptyComponent?: React.FC
  resourceName: string
  onReorderEnd?: (oldIndex: number, newIndex: number) => void
  isLoading?: boolean
  isSearching?: boolean
}

export const Table = <T,>(props: TableProps<T>) => {
  const {
    table,
    data,
    onClickRow,
    itemsPerPage = 10,
    refresh,
    lastUpdated,
    CTAComponent,
    EmptyComponent,
    resourceName,
    onReorderEnd,
    isLoading,
    isSearching,
  } = props

  const hasNoData = !data.length
  const {isMobile} = useDeviceType()
  const isDraggable = !!onReorderEnd && !isSearching

  // Store column widths to preserve during dragging
  const [columnWidths, setColumnWidths] = useState<number[]>([])
  const tableRef = useRef<HTMLTableElement>(null)

  // Capture column widths on first render and when table layout changes
  useEffect(() => {
    if (tableRef.current) {
      const firstRow = tableRef.current.querySelector('tbody tr')
      if (firstRow) {
        const cells = firstRow.querySelectorAll('td')
        const widths = Array.from(cells).map(cell => cell.getBoundingClientRect().width)
        setColumnWidths(widths)
      }
    }
  }, [data.length, table])

  const hasNextPage = table.getCanNextPage()
  const hasPreviousPage = table.getCanPreviousPage()

  const tableHeaderGroups = table.getHeaderGroups()

  const emptyTable = !table.getRowModel().rows?.length

  const numberOfResults = table.getPrePaginationRowModel().rows?.length

  const tableHeight = itemsPerPage * (TABLE_ROW_HEIGHT + BORDER_HEIGHT) + TABLE_HEADER_HEIGHT + BORDER_HEIGHT

  return (
    <div className={styles.TableContainer}>
      <div className={styles.TableWrapper} style={{minHeight: tableHeight}}>
        <table ref={tableRef}>
          <thead style={{height: TABLE_HEADER_HEIGHT}}>
            {tableHeaderGroups.map(headerGroup => (
              <>
                {isDraggable && <th key='drag-handle-header' style={{width: DRAG_HANDLE_WIDTH}}></th>}
                {headerGroup.headers.map(header => (
                  <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>
                ))}
              </>
            ))}
          </thead>
          <DragDropContext
            onDragEnd={result =>
              !!onReorderEnd && isNumber(result.destination?.index)
                ? onReorderEnd(result.source.index, result.destination.index)
                : undefined
            }>
            <Droppable droppableId={`table-${resourceName}`} direction='vertical' isDropDisabled={isSearching}>
              {provided => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {!emptyTable ? (
                    table.getRowModel().rows.map((row, index) => (
                      <Draggable
                        isDragDisabled={!isDraggable}
                        draggableId={`row-${row.id}`} // Ensure unique id
                        key={`row-${row.id}`} // Unique key
                        index={index}>
                        {(provider, snapshot) => (
                          <tr
                            key={`row-tr-${row.id}`} // Unique key
                            ref={provider.innerRef}
                            {...provider.draggableProps}
                            className={classNames({
                              [styles.TableClickableRow]: !!onClickRow,
                              [styles.TableDragging]: snapshot.isDragging,
                            })}
                            tabIndex={0}
                            style={{
                              ...provider.draggableProps.style,
                              height: TABLE_ROW_HEIGHT,
                              width: '100%', // Ensure the row maintains full width
                              tableLayout: snapshot.isDragging ? 'fixed' : undefined, // Use fixed layout when dragging
                            }}
                            onClick={onClickRow ? () => onClickRow(row.original) : undefined}
                            onKeyDown={onClickRow ? e => e.key === 'Enter' && onClickRow(row.original) : undefined}>
                            {isDraggable && (
                              <td
                                className={styles.DragHandleCell}
                                {...provider.dragHandleProps}
                                onClick={e => e.stopPropagation()}>
                                <div className={styles.DragHandle}>
                                  <SixGrip width={16} height={16} />
                                </div>
                              </td>
                            )}
                            {row.getVisibleCells().map((cell, cellIndex) => (
                              <td
                                key={cell.id}
                                style={
                                  snapshot.isDragging && columnWidths.length > cellIndex
                                    ? {width: columnWidths[cellIndex]}
                                    : undefined
                                }>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            ))}
                          </tr>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={FULL_COL_SPAN}>
                        {isLoading ? (
                          <div className={styles.TableEmptyWrapper}>
                            <SpinLoader height={23} />
                          </div>
                        ) : hasNoData ? (
                          <div className={styles.TableEmptyWrapper}>
                            {CTAComponent ? <CTAComponent /> : `No ${resourceName.toLowerCase()}s.`}
                          </div>
                        ) : (
                          <div className={styles.TableEmptyWrapper}>
                            {EmptyComponent ? <EmptyComponent /> : `No results`}
                          </div>
                        )}
                      </td>
                    </tr>
                  )}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
      </div>
      <div className={styles.TableFooter}>
        <div>
          <p className='m-0'>
            <span style={{fontWeight: 900}}>{numberOfResults}</span> result(s)
          </p>
        </div>
        <div className={styles.TableUpdatedAt}>
          <p className='grey-light m-0 italic'>Last Updated {moment(lastUpdated).format('h:mma')}</p>
          <TableIcon name='refresh' width={ICON_SIZE} height={ICON_SIZE} cursor={'pointer'} onClick={() => refresh()} />
        </div>
        <div className={styles.TableNextPreviousButtons}>
          <TableButton text='Previous' onClick={() => table.previousPage()} disabled={!hasPreviousPage} />
          <TableButton text='Next' onClick={() => table.nextPage()} disabled={!hasNextPage} />
        </div>
      </div>
    </div>
  )
}
