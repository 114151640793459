import React from 'react'

import {PoshImage} from 'components/PoshImage/PoshImage'
import {useCopyLink} from 'hooks/useCopyLink'

import './styles.scss'

interface HeaderCopyBlockProps {
  text: string
}

const HeaderCopyBlock = (props: HeaderCopyBlockProps) => {
  const {text} = props
  const {copyLink, justCopied} = useCopyLink()

  return (
    <div className='HeaderCopyBlock' onClick={() => copyLink(text)}>
      {justCopied ? <span>Copied!</span> : <span className='HeaderCopyBlock-link'>{text}</span>}
      <PoshImage src='https://images.posh.vip/b2/clipboard.svg' />
    </div>
  )
}

export default HeaderCopyBlock
