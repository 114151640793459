import React from 'react'

import {LoginFlow} from '.'
import {EmailSVG, PhoneSVG} from './codeInputIcons'

interface LoginMethodPickerProps {
  switchLoginFlow: (method: LoginFlow) => void
  loginFlow: LoginFlow
  noMargin?: boolean
}

export const LoginMethodPicker = (props: LoginMethodPickerProps) => {
  const {switchLoginFlow, loginFlow, noMargin} = props
  return (
    <div className={`LoginPage-form-type ${noMargin ? 'm-0' : ''}`}>
      <div onClick={() => switchLoginFlow('email')} className={loginFlow == 'email' ? 'active' : ''}>
        <EmailSVG /> Email
      </div>
      <div>|</div>
      <div onClick={() => switchLoginFlow('phone')} className={loginFlow == 'phone' ? 'active' : ''}>
        <PhoneSVG /> Phone
      </div>
    </div>
  )
}
