import React from 'react'
import TagManager from 'react-gtm-module'

import {captureException} from '@sentry/react'

interface GTMNoScriptProps {
  containerId?: string
}

const CustomGTMTracking = ({containerId}: GTMNoScriptProps) => {
  try {
    if (!containerId) {
      return null
    }

    const tagManagerArgs = {
      gtmId: containerId,
    }
    TagManager.initialize(tagManagerArgs)

    // Render a noscript tag with an iframe for Google Tag Manager.
    // This is for users who have JavaScript disabled in their browsers.
    return (
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${containerId}`}
          height='0'
          width='0'
          style={{display: 'none', visibility: 'hidden'}}></iframe>
      </noscript>
    )
  } catch (error) {
    captureException(error)
    return null
  }
}

export default CustomGTMTracking
