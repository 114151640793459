import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetAllEventOrdersOptions = ReactQueryOptions['events']['getAllEventOrders']
type GetAllEventOrdersInput = RouterInputs['events']['getAllEventOrders']
export type GetAllEventOrdersOutput = NonNullable<RouterOutput['events']['getAllEventOrders']['orders']>
export type Order = GetAllEventOrdersOutput[0]

export const useGetAllEventOrders = (input: GetAllEventOrdersInput, options?: GetAllEventOrdersOptions) => {
  return trpc.events.getAllEventOrders.useQuery(input, options)
}

export const useInvalidateGetAllEventOrders = () => {
  const qc = trpc.useContext()
  const invalidateGetAllEventOrders = () => qc.events.getAllEventOrders.invalidate()
  return {invalidateGetAllEventOrders}
}
