import React from 'react'

import {link} from 'components/assets/Icons'
import {PoshImage} from 'components/PoshImage/PoshImage'

interface TrackingLinkCardProps {
  trackingLink?: string
}

export const TrackingLinkCard = (props: TrackingLinkCardProps) => {
  const {trackingLink} = props
  if (!trackingLink) return null
  return (
    <div className='ApprovalModal-Content-trackingLinkWrapper'>
      <PoshImage src={link} />
      <p className='m-0'>{trackingLink}</p>
    </div>
  )
}
