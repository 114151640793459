/**
 * Event IDs to be excluded from Grouped Fees experiment
 * and to be cleaned up after the experiment concludes.
 * This was a "quick and dirty" solution due to organisers
 * complaining and not wanting to participate in the test.
 *
 * TODO: Clean up after GROUPED_FEES_EXPERIMENT concludes.
 */
const excludedEventIds = [
  '66ad508cae6ccd14d06fbf75',
  '67d36159018e5e816ffc96b8',
  '67d364d5018e5e816ffd64bd',
  '67650807e9e0e5128a6eeed1',
  '676509afe9e0e5128a6f1b8b',
  '67650a0fe9e0e5128a6f2051',
  '67650a57e9e0e5128a6f2274',
  '67650ac3e9e0e5128a6f2621',
  '67650b11e9e0e5128a6f3038',
  '67650b51e9e0e5128a6f321e',
  '6767334b0c15ea7782eda83c',
  '67bbc3f69457be323205a797',
  '67e86d09ccb6168e444357b0',
  '67eac6ad6e4b74241f029bb1',
]

export {excludedEventIds}
