// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EventVisualsExpandedGuestListSection__XQui5{display:flex;gap:1.5rem;flex-wrap:wrap;align-items:center;justify-content:center;pointer-events:none}.EventVisualsGuestListSection__5iJ4g{padding:30px 15px;align-items:center;justify-content:center;display:flex;flex-direction:column;gap:10px}.EventVisualsGuestListSection__5iJ4g .row__Tacwa{display:flex;align-items:center;justify-content:center;gap:10px}.EventVisualsGuestListSection__5iJ4g .overlappingIcon__5RQWC{margin-left:-20px;width:60px;height:60px;border-radius:999px;border:3px solid;object-fit:cover}", "",{"version":3,"sources":["webpack://./src/pages/EventManagementPages/Visuals/Upgrade-dev/components/page-sections/GuestListSection/styles.module.scss"],"names":[],"mappings":"AAEA,6CACE,YAAA,CACA,UAAA,CACA,cAAA,CACA,kBAAA,CACA,sBAAA,CACA,mBAAA,CAGF,qCACE,iBAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,iDACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CAEF,6DACE,iBAAA,CACA,UA1BO,CA2BP,WA3BO,CA4BP,mBAAA,CACA,gBAAA,CACA,gBAAA","sourcesContent":["$iconSize: 60px;\n\n.EventVisualsExpandedGuestListSection {\n  display: flex;\n  gap: 1.5rem;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: center;\n  pointer-events: none;\n}\n\n.EventVisualsGuestListSection {\n  padding: 30px 15px;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  .row {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 10px;\n  }\n  .overlappingIcon {\n    margin-left: -20px;\n    width: $iconSize;\n    height: $iconSize;\n    border-radius: 999px;\n    border: 3px solid;\n    object-fit: cover;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EventVisualsExpandedGuestListSection": "EventVisualsExpandedGuestListSection__XQui5",
	"EventVisualsGuestListSection": "EventVisualsGuestListSection__5iJ4g",
	"row": "row__Tacwa",
	"overlappingIcon": "overlappingIcon__5RQWC"
};
export default ___CSS_LOADER_EXPORT___;
