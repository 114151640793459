import {ellipsize} from '@posh/model-types'
import {ICrossSection} from 'apis/Analytics/useGetSectionalData'

/**
 * This is a temporary function to ellipsize the chart labels of the cross section.
 * This is because the chart labels are too long and we don't have a good way to handle them.
 * @param crossSection - The cross section to ellipsize the chart labels of.
 * @returns The cross section with the ellipsized chart labels.
 */
export const ellipsizeCrossSectionChartLabels = (crossSection: ICrossSection): ICrossSection => {
  if (!crossSection.chart) return crossSection

  const maxLength = 22
  return {
    ...crossSection,
    chart: {
      ...crossSection.chart,
      data: crossSection.chart.data.map(item => {
        // Check if the label contains a date pattern in parentheses at the end
        // (bad pattern but since this is tied with the CrossSectionBuilder, we're stuck with it)
        const dateMatch = item.label.match(/\s*\(([^)]+)\)$/)

        if (!dateMatch) {
          // If no date found, use regular ellipsize
          return {...item, label: ellipsize(item.label, maxLength)}
        }

        // Extract the date portion including parentheses
        const datePortion = dateMatch[0]

        const eventName = item.label.substring(0, item.label.length - datePortion.length)
        const ellipsizedEventName = ellipsize(eventName, Math.max(5, maxLength - datePortion.length))

        // Combine the ellipsized event name with the date portion
        return {...item, label: ellipsizedEventName + datePortion}
      }),
    },
  }
}
