import React, {useEffect, useState} from 'react'

import {useFetchAccount} from 'apis/Accounts/useFetchAccount'
import useFetchEvent from 'apis/Events/useFetchEvent'
import useUpdatePoshProfile from 'apis/PoshProfile/useUpdatePoshProfile'
import {useGetIsSparkOptedIn} from 'apis/Spark/useGetIsSparkOptedIn'
import {useUpdateSparkOptIn} from 'apis/Spark/useUpdateSparkOptIn'
import {instagramOutlineGoldUrl, linkedinSquareGoldUrl, twitterFillGoldUrl} from 'components/assets/Icons'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {PoshImage} from 'components/PoshImage/PoshImage'
import UploadAvi from 'components/UploadAvi'
import useSessionContext from 'domains/Auth/SessionContext'
import {isDefaultAvi} from 'helpers/User/isDefaultAvi'

import PoshStyledModal, {PoshStyledModalProps} from '../PoshStyledModal'
import SparkOptInBanner from './SparkOptInBanner'

import './styles.scss'

interface AddSocialsModalProps extends Omit<PoshStyledModalProps, 'isOpen' | 'onClose'> {
  // this modal self-determines whether it should be open or not
  eventId: string
  allowOpen?: boolean
}

const LinkedInIcon = () => <PoshImage src={linkedinSquareGoldUrl} />

const TwitterIcon = () => <PoshImage src={twitterFillGoldUrl} />

const InstagramIcon = () => <PoshImage src={instagramOutlineGoldUrl} />

function IconWithPlus(props: {icon: JSX.Element}) {
  return (
    <div className='iconWithPlus'>
      <p>+</p>
      {props.icon}
    </div>
  )
}

const DEFAULT_HEADER = 'Add your info to interact with other guests!'
const APPROVAL_ONLY_HEADER = 'Add your info to improve your chances of being approved!'
const getHeader = (isApprovalOnlyRSVPEvent: boolean) =>
  isApprovalOnlyRSVPEvent ? APPROVAL_ONLY_HEADER : DEFAULT_HEADER

const OPEN_MODAL_DELAY = 3000

export default function AddSocialsModal(props: AddSocialsModalProps) {
  const {eventId, allowOpen = true, ...modalProps} = props

  const {currentUser, userId} = useSessionContext()
  const {refetch: updateCurrentUser} = useFetchAccount(userId!)
  const {mutateAsync: updateSocials, isLoading} = useUpdatePoshProfile()
  const {
    data: sparkStatus,
    isSuccess: isSuccessGettingSparkStatus,
    refetch: refetchSparkStatus,
  } = useGetIsSparkOptedIn({eventId})
  const {mutateAsync: updateSparkOptIn} = useUpdateSparkOptIn()
  const {shouldShowSparkOptInForm, isSparkOptedIn} = sparkStatus || {}
  const {data: fetchEventResponse} = useFetchEvent(eventId!)
  const {event} = fetchEventResponse || {}
  const {isApprovalOnlyRSVPEvent} = event || {}

  const [sparkChecked, setSparkChecked] = useState(!!isSparkOptedIn)

  const accountShouldSetSocials =
    !currentUser?.profile?.instagram ||
    !currentUser?.profile?.twitter ||
    !currentUser?.profile?.linkedIn ||
    isDefaultAvi(currentUser?.avi)

  const [shouldShowModal, setShouldShowModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const close = () => setIsOpen(false)

  useEffect(() => {
    setSparkChecked(!!isSparkOptedIn)
  }, [isSparkOptedIn])

  useEffect(() => {
    refetchSparkStatus()
  }, [allowOpen, refetchSparkStatus])

  useEffect(() => {
    setShouldShowModal((shouldShowSparkOptInForm || accountShouldSetSocials) && isSuccessGettingSparkStatus)
  }, [accountShouldSetSocials, shouldShowSparkOptInForm, isSuccessGettingSparkStatus])

  useEffect(() => {
    setTimeout(() => setIsOpen(shouldShowModal), OPEN_MODAL_DELAY)
  }, [shouldShowModal, allowOpen])

  const [socials, setSocials] = useState({
    instagram: currentUser?.profile?.instagram,
    twitter: currentUser?.profile?.twitter,
    linkedIn: currentUser?.profile?.linkedIn,
  })

  const handleSaveSocials = () => {
    Promise.all([
      updateSocials({update: {instagram: socials.instagram, twitter: socials.twitter, linkedIn: socials.linkedIn}}),
      updateSparkOptIn({eventId, optIn: sparkChecked}),
    ]).then(() => updateCurrentUser().then(close))
  }

  return (
    <PoshStyledModal
      {...modalProps}
      isOpen={isOpen && allowOpen}
      onClose={close}
      width={428}
      contentClassName='center outline'>
      <div className='AddSocialsModal'>
        <h4>{getHeader(!!isApprovalOnlyRSVPEvent)}</h4>
        {shouldShowSparkOptInForm && (
          <SparkOptInBanner isChecked={sparkChecked} onChecked={() => setSparkChecked(v => !v)} />
        )}
        <UploadAvi size={120} />
        <Input
          value={socials.instagram}
          className='w-full'
          placeholder='Instagram'
          icon={<IconWithPlus icon={<InstagramIcon />} />}
          onChange={e => {
            setSocials({...socials, instagram: e.target.value})
          }}
        />
        <Input
          value={socials.twitter}
          className='w-full'
          placeholder='Twitter'
          icon={<IconWithPlus icon={<TwitterIcon />} />}
          onChange={e => {
            setSocials({...socials, twitter: e.target.value})
          }}
        />
        <Input
          value={socials.linkedIn}
          className='w-full'
          placeholder='LinkedIn'
          icon={<IconWithPlus icon={<LinkedInIcon />} />}
          onChange={e => {
            setSocials({...socials, linkedIn: e.target.value})
          }}
        />
        <Button className='gold fullWidth' onClick={handleSaveSocials} isLoading={isLoading} disabled={isLoading}>
          Save & Continue
        </Button>
      </div>
    </PoshStyledModal>
  )
}
