import {GroupDisputesReturn} from 'apis/Groups/useFetchGroupDisputes'
import {baseURL} from 'apis/NavigationHelper'
import {useGroupMatchParams} from 'domains/Groups/helpers'

interface DisputeDetailsProps {
  selectedDisputeData: GroupDisputesReturn[0] | null
}

const DisputeDetails = ({selectedDisputeData}: DisputeDetailsProps) => {
  const {groupId} = useGroupMatchParams()
  if (!selectedDisputeData) return null

  // Format currency with thousands separator
  const formatCurrency = (amount: number): string => {
    return `$${amount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`
  }

  const orderNumber = selectedDisputeData.orderNumber
  const orderUrl = `${baseURL}/owner/groups/${groupId}/orders/${selectedDisputeData.orderId}`

  return (
    <div>
      <div className='grid grid-cols-3 text-sm'>
        <div>
          <div className='mb-1 text-gray-400'>Disputor</div>
          <div className='font-medium text-white'>{selectedDisputeData.disputor.name}</div>
        </div>
        <div>
          <div className='mb-1 text-gray-400'>Amount</div>
          <div className='font-medium text-white'>{formatCurrency(selectedDisputeData.amount)}</div>
        </div>
        <div>
          <div className='mb-1 text-gray-400'>Order Number</div>
          <div className='font-medium text-white'>
            <a href={orderUrl} className='text-blue-400 hover:underline'>
              #{orderNumber}
            </a>
          </div>
        </div>
      </div>

      <div className='mt-4 grid grid-cols-2 gap-4 text-sm'>
        <div>
          <div className='mb-1 text-gray-400'>Reason</div>
          <div className='font-medium text-white'>{selectedDisputeData.reason}</div>
        </div>
        <div>
          <div className='mb-1 text-gray-400'>Date Filed</div>
          <div className='font-medium text-white'>{new Date(selectedDisputeData.createdAt).toLocaleDateString()}</div>
        </div>
      </div>
    </div>
  )
}

export default DisputeDetails
