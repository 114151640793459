import React from 'react'

import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'

import useFetchEvent from '../../../apis/Events/useFetchEvent'
import {useResourcePageParams} from '../../PoshAppLayout'
import ApprovalSection from '../Tickets/ApprovalSection'
import ApprovalCrmContextProvider from '../Tickets/ApprovalSection/ApprovalContextProvider/ApprovalCrmContextProvider'

import '../../EventManagementPages/Tickets/EditPromoCode/index.scss'

const Approvals = () => {
  const {eventId} = useResourcePageParams()
  const {data: event} = useFetchEvent(eventId!)

  return (
    <>
      <PoshHelmet title={`Approvals - ${event?.event?.name ?? ''}`} />
      <div className='eventSection'>
        <h3>Attendees Awaiting Approval</h3>
        <p className='my-2 text-gray-200'>
          These attendees have requested to attend your event. You can approve or deny their request by clicking the
          check or x icon.
        </p>
        <ApprovalCrmContextProvider>
          <ApprovalSection />
        </ApprovalCrmContextProvider>
      </div>
    </>
  )
}

export default Approvals
