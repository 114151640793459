import React, {useEffect, useState} from 'react'
import {Control, useController} from 'react-hook-form'

import {DEFAULT_LINEUP_SECTION_TITLE} from '@posh/types'
import {EventLineUp} from 'apis/Events/types'
import classNames from 'classnames'
import {DraggableList} from 'components/DraggableList'
import PoshSwitch from 'components/form/PoshSwitch'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {EventVisualsSection} from 'components/PageComponents/EventVisuals/Page/Section'
import {EventVisualsHeaderButtons} from 'components/PageComponents/EventVisuals/Page/Section/headerButtons'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {isEmpty} from 'lodash'
import {LineupItem} from 'pages/EventPage/Lineup/LineupItem'

import {useEventVisualsContext} from '../../context/EventVisualsContext'

import styles from './styles.module.scss'

function useFormValues(control: Control<EventVisualsForm>) {
  const {
    field: {value: lineup, onChange: onChangeLineup},
  } = useController({
    control,
    name: 'lineup',
  })
  const {
    field: {value: timezone = 'America/New_York'},
  } = useController({control, name: 'timezone'})
  const {
    field: {value: performanceCategory, onChange: onChangePerformanceCategory},
  } = useController({
    control,
    name: 'performanceCategory',
  })
  return {
    timezone,
    lineup,
    onChangeLineup,
    performanceCategory,
    onChangePerformanceCategory,
  }
}

const lineupModificationFunctions = (lineup: EventLineUp[] | null, onChangeLineup: (l: EventLineUp[]) => void) => {
  const update = (index: number, item: EventLineUp) => {
    if (!lineup) return onChangeLineup([item])
    const newLineup = [...lineup]
    newLineup[index] = item
    return onChangeLineup(newLineup)
  }

  const create = (item: EventLineUp) => {
    if (!lineup) return onChangeLineup([item])
    return onChangeLineup([...lineup, item])
  }

  const remove = (index: number) => {
    if (!lineup) return
    const newLineup = [...lineup]
    newLineup.splice(index, 1)
    return onChangeLineup(newLineup)
  }

  return {
    update,
    create,
    remove,
  }
}

export function EventVisualsLineupSection({control}: {control: Control<EventVisualsForm>}) {
  const {
    lineup = null,
    onChangeLineup,
    performanceCategory,
    onChangePerformanceCategory,
    timezone,
  } = useFormValues(control)
  const {palette, currentlyAddingSection, setCurrentlyAddingSection} = useEventVisualsContext()
  const doesntHaveLineup = !lineup || isEmpty(lineup)
  const [isAdding, setIsAdding] = useState(doesntHaveLineup)
  const {update, create, remove} = lineupModificationFunctions(lineup, onChangeLineup)

  const [showLineupCategoryInput, setShowLineupCategoryInput] = useState(
    performanceCategory !== DEFAULT_LINEUP_SECTION_TITLE,
  )

  useEffect(() => {
    setShowLineupCategoryInput(performanceCategory !== DEFAULT_LINEUP_SECTION_TITLE)
  }, [])

  useEffect(() => {
    if (!showLineupCategoryInput) {
      onChangePerformanceCategory(DEFAULT_LINEUP_SECTION_TITLE)
    }
  }, [showLineupCategoryInput])

  if (doesntHaveLineup && currentlyAddingSection !== 'lineup') return null

  const removeLineupSection = () => {
    setCurrentlyAddingSection(undefined)
    onChangeLineup(null)
  }

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex) return
    const newLineup = [...(lineup || [])]
    newLineup.splice(newIndex, 0, newLineup.splice(oldIndex, 1)[0])
    onChangeLineup(newLineup)
  }

  return (
    <>
      <EventVisualsSection
        headerText={performanceCategory || DEFAULT_LINEUP_SECTION_TITLE}
        underlined
        rightIcon={
          <EventVisualsHeaderButtons
            addButtonProps={{onPress: () => setIsAdding(true), disabled: isAdding}}
            deleteButtonProps={{
              onPress: removeLineupSection,
              disabled: false,
              confirmationModalTitle: 'Are you sure you want to remove your lineup?',
              confirmationModalSubtitle: 'This action cannot be undone',
              confirmationButtonText: 'Delete Lineup',
              cancelButtonText: 'Cancel',
              deleteWithoutModalOnSectionEmpty: true,
              isSectionEmpty: doesntHaveLineup,
            }}
          />
        }>
        <div className={styles.EventVisualsLineupSection}>
          <PoshSwitch
            rightTitle={`Customize this section's title`}
            switchOptions={{
              checked: showLineupCategoryInput,
              onChange: setShowLineupCategoryInput,
            }}
          />
          {showLineupCategoryInput && (
            <EventVisualsTextInput.Text.Controlled
              control={control}
              name='performanceCategory'
              placeholder='Lineup section title'
              lightMode={palette.lightmode}
              accentColor={palette.accentColor}
            />
          )}
          <DraggableList
            onSortEnd={onSortEnd}
            className={classNames(styles.EventVisualsLineupItems)}
            draggedItemClassName={classNames(styles.EventVisualsLineupItems, {[styles.dragged]: true})}>
            {lineup?.map((artist, i) => (
              <LineupItem.Editable
                item={artist}
                key={artist.link}
                {...palette}
                onSaveItem={item => update(i, item)}
                onDeleteItem={() => remove(i)}
                eventTimezone={timezone}
              />
            ))}
          </DraggableList>
        </div>
      </EventVisualsSection>
      {isAdding && (
        <PoshStyledModal isOpen={isAdding} onClose={() => setIsAdding(false)}>
          <h4 className='center m-0'>Add an Event Feature</h4>
          {!lineup?.length && (
            <p className='center m-0'>Use this section to showcase your event&apos;s performers, sponsors, and more</p>
          )}
          <LineupItem.Editing
            item={undefined}
            {...palette}
            onSaveItem={item => {
              create(item)
              setIsAdding(false)
            }}
            onDeleteItem={() => setIsAdding(false)}
            onCancelEditItem={() => setIsAdding(false)}
            eventTimezone={timezone}
          />
        </PoshStyledModal>
      )}
    </>
  )
}
