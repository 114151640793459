import React, {useState} from 'react'

import {FeatureFlag} from '@posh/model-types'
import {OrderTag, OrderTags} from 'apis/Orders'
import Button from 'components/form/Button'
import {Checkbox} from 'components/form/checkbox/Checkbox'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'
import {useFeatureGate} from 'hooks/useFeatureGate'

interface OrderFilterModalProps {
  isOpen: boolean
  onClose: () => void
  filters: OrderTag[]
  onChangeFilters: (filters: OrderTag[]) => void
  isRSVPEvent?: boolean
}

const RSVPTags = ['scanned', 'canceled', 'created-via-ticket-transfer'] as const

const getFilterLabel = (filter: OrderTag, isRSVPEvent = false) => {
  if (filter === 'created-via-ticket-transfer') return 'Orders created via ticket transfer'
  if (filter === 'purchased-in-person') return 'In-Person Orders'
  return `${filter.charAt(0).toUpperCase()}${filter.slice(1)} ${isRSVPEvent ? 'RSVPs' : 'Orders'}`
}

export const OrderFilterModal = (props: OrderFilterModalProps) => {
  const {isOpen, onClose, filters, onChangeFilters, isRSVPEvent} = props
  const shouldRenderTicketTransferSwitch = useFeatureGate(FeatureFlag.TICKET_TRANSFER_SETTINGS)
  const [selectedFilters, setSelectedFilters] = useState<OrderTag[]>(filters)

  const onApplyFilters = () => {
    onChangeFilters(selectedFilters)
    onClose()
  }

  const onCloseWrapper = () => {
    setSelectedFilters(filters)
    onClose()
  }

  const resource = isRSVPEvent ? 'RSVPs' : 'Orders'
  const tags = isRSVPEvent ? RSVPTags : OrderTags

  return (
    <PoshStyledModal isOpen={isOpen} onClose={onCloseWrapper}>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10}}>
        <h3>Filter {resource}</h3>

        <div style={{width: '100%'}}>
          {tags
            .filter(tag => tag !== 'scanned')
            .map(filter => {
              if (filter === 'created-via-ticket-transfer' && !shouldRenderTicketTransferSwitch) return null
              return (
                <Checkbox
                  key={filter}
                  label={getFilterLabel(filter, isRSVPEvent)}
                  checked={selectedFilters.includes(filter)}
                  onChecked={() =>
                    setSelectedFilters(
                      selectedFilters.includes(filter)
                        ? selectedFilters.filter(f => f !== filter)
                        : [...selectedFilters, filter],
                    )
                  }
                  color='white'
                  hideBackground></Checkbox>
              )
            })}
        </div>

        <Button onClick={onApplyFilters}>Apply Filters</Button>
      </div>
    </PoshStyledModal>
  )
}
