import React, {useEffect, useRef, useState} from 'react'

import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {useFetchGroupAttendeesPaged} from 'apis/Groups/useFetchGroupAttendees'
import {filterUrl, tagWhiteUrl} from 'components/assets/Icons'
import Button from 'components/form/Button'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {isEmpty} from 'lodash'
import GroupPageSubNav from 'pages/GroupPage/SubNav'

import AttendeePanes from '../AttendeePanes'
import useAttendeesCrmContext from '../AttendeesCrmContext'
import {AttendeesSearchBar} from '../AttendeesSearchBar'
import AttendeesTable from '../AttendeesTable'
import AttendeesCrmActionsModal from '../Filter'
import {FilterAlert} from '../Filter/filterAlert'
import {TagsModal} from '../TagsModal'

const AttendeesListView = () => {
  const {groupId} = useGroupMatchParams()
  const {data: group} = useFetchGroup(groupId)
  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false)
  const [tagModalIsOpen, setTagModalIsOpen] = useState(false)
  const [limitModalIsOpen, setLimitModalIsOpen] = useState(false)
  const {
    page,
    setPage,
    isFilterActive,
    selectedTags,
    selectedEvents,
    filterByData,
    filterByOrder,
    matchAllEvents,
    matchAllTags,
    genderFilter,
  } = useAttendeesCrmContext()

  const [searchQuery, setSearchQuery] = useState('')
  const {data: groupAttendeesData, mutate, isError, isLoading} = useFetchGroupAttendeesPaged()
  const attendees = groupAttendeesData?.attendees

  useEffect(() => {
    const timeout = setTimeout(() => {
      mutate({
        groupId: groupId ?? '',
        page,
        selectedEvents,
        matchAllEvents,
        selectedTags,
        matchAllTags,
        filterByData,
        filterByOrder,
        searchQuery,
        genderFilter,
      })
    }, 500)

    return () => clearTimeout(timeout)
  }, [
    isFilterActive && selectedTags,
    isFilterActive && selectedEvents,
    isFilterActive && filterByData,
    isFilterActive && filterByOrder,
    searchQuery,
    page,
  ])

  useEffect(() => {
    setPage(1)
  }, [searchQuery, isFilterActive, selectedTags, selectedEvents, filterByData, filterByOrder])

  const crmStart = useRef<null | HTMLDivElement>(null)
  const startNewCampaign = () => {
    crmStart.current?.scrollIntoView({behavior: 'smooth'})
  }

  return (
    <div className='Attendees GroupPage-content'>
      <GroupPageSubNav group={group} hierarchy={['Marketing']} />

      <div className='GroupPage-titleBar'>
        <h3>Attendees</h3>
        <RequirePermissions requiredPermissions={[requirePermissionAndScope('send_blast', ['group'])]}>
          <AttendeePanes startNewCampaign={startNewCampaign} setLimitModalIsOpen={setLimitModalIsOpen} />
        </RequirePermissions>
      </div>

      <div className='Attendees-actionBar'>
        <AttendeesSearchBar setSearchQuery={setSearchQuery} />
        {!isEmpty(attendees) && (
          <RequirePermissions requiredPermissions={[requirePermissionAndScope('filter_attendee_list', ['group'])]}>
            <Button onClick={() => setFilterModalIsOpen(true)} className='dark'>
              <img src={filterUrl} />
              Filter
            </Button>
          </RequirePermissions>
        )}
        <RequirePermissions requiredPermissions={[requirePermissionAndScope('tag_attendees', ['group'])]}>
          <Button onClick={() => setTagModalIsOpen(true)} className='dark'>
            <img src={tagWhiteUrl} />
            Tag
          </Button>
        </RequirePermissions>
      </div>

      <FilterAlert onOpenModal={() => setFilterModalIsOpen(true)} />
      <AttendeesTable
        attendeeRows={attendees ?? []}
        hasMoreAttendees={groupAttendeesData?.hasMore ?? false}
        isFetching={isLoading}
      />
      {attendees && attendees.length === 0 && !isLoading && (isFilterActive || page == 1) && (
        <div className='Attendees-emptyState'>
          Once you begin selling tickets, attendee information will appear here!
        </div>
      )}
      {isError && (
        <div className='Attendees-emptyState'>There was an error fetching your attendees, please try again.</div>
      )}
      <AttendeesCrmActionsModal isOpen={filterModalIsOpen} onClose={() => setFilterModalIsOpen(false)} />
      <TagsModal isOpen={tagModalIsOpen} onClose={() => setTagModalIsOpen(false)} />
    </div>
  )
}

export default AttendeesListView
