import {MAX_TICKET_QUANTITY} from '@posh/model-types'
import {cartCreationOrigins, zobjectId, zOptionalBoolean, zOptionalObjectId} from '@posh/types'
import {z} from 'zod'

const BaseTicketFormSchema = z.object({
  name: z.string(),
  price: z
    .number()
    .gte(0, 'Price must be greater than or equal to 0')
    .multipleOf(0.01, 'Price must have two decimal places.')
    .optional()
    .transform(v => (v === undefined ? 0 : v)),
  quantityAvailable: z.union([
    z.number().gte(1, 'Quantity must be greater than 1').int('Quantity must be a whole number'),
    z
      .literal('')
      .optional()
      .transform(v => (!v ? MAX_TICKET_QUANTITY : v)),
  ]),
  onSaleUtc: z.string().datetime().optional(),
  onSale: z.string().datetime().optional(), // local date :(
  endSaleUtc: z.string().datetime().optional(),
  endSale: z.string().datetime().optional(), // local date :(
  purchaseMin: z.number().gte(1).optional(),
  purchaseLimit: z.number().gte(1).lt(MAX_TICKET_QUANTITY).optional(),
  validity: z
    .object({
      validAfter: z.string().datetime().optional(),
      validBefore: z.string().datetime().optional(),
    })
    .optional(),
  description: z.string().optional(),
  password: z.string().optional(),
  approvalRequired: zOptionalBoolean,
  isHidden: zOptionalBoolean,
  priceHidden: zOptionalBoolean,
  sellInMultiples: zOptionalBoolean,
  disabled: zOptionalBoolean,
  closed: zOptionalBoolean,
  isTransferable: zOptionalBoolean.default(true),
  availableForSaleVia: z
    .array(z.enum(cartCreationOrigins))
    .min(1, {
      message: 'Tickets must be available for sale via at least one channel',
    })
    .optional(),
})

export type BaseTicketForm = z.infer<typeof BaseTicketFormSchema>

export const UpdateTicketFormSchema = BaseTicketFormSchema.merge(
  z.object({
    id: zobjectId,
  }),
)
export type UpdateTicketForm = z.infer<typeof UpdateTicketFormSchema>

// zoptionalObjectId creates an object ID if one is not provided.
// We want this behavior when creating a ticket, but NOT if we are updating a ticket.
export const CreateTicketFormSchema = BaseTicketFormSchema.merge(z.object({id: zOptionalObjectId}))

export type CreateTicketForm = z.infer<typeof CreateTicketFormSchema>
