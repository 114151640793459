// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RowLabel__KMtqU{border:1px solid rgba(222,255,0,.8);box-shadow:none;display:inline-flex;padding:3px 10px;align-items:flex-end;gap:10px;border-radius:61px;font-size:12px;font-style:normal;font-weight:500;line-height:normal;background:rgba(255,165,0,.1490196078);color:orange;letter-spacing:-0.2px;text-transform:capitalize}", "",{"version":3,"sources":["webpack://./src/components/TableUI/TableRowLabel/TableRowLabel.module.scss"],"names":[],"mappings":"AAEA,iBACG,mCAAA,CACF,eAAA,CACA,mBAAA,CACA,gBAAA,CACA,oBAAA,CACA,QAAA,CACA,kBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,sCAAA,CACA,YAAA,CACA,qBAAA,CACA,yBAAA","sourcesContent":["@import '@global-styles/_color_pallete';\n\n.RowLabel {\n  \tborder: 1px solid #deff00cc;\n\tbox-shadow: none;\n\tdisplay: inline-flex;\n\tpadding: 3px 10px;\n\talign-items: flex-end;\n\tgap: 10px;\n\tborder-radius: 61px;\n\tfont-size: 12px;\n\tfont-style: normal;\n\tfont-weight: 500;\n\tline-height: normal;\n\tbackground: #ffa50026;\n\tcolor: orange;\n\tletter-spacing: -.2px;\n\ttext-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RowLabel": "RowLabel__KMtqU"
};
export default ___CSS_LOADER_EXPORT___;
