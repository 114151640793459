import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import Button from 'components/form/Button'
import loginRedirect from 'components/helpers/loginRedirect'
import useSessionContext from 'domains/Auth/SessionContext'

import {TProfileType} from '../types'
import {useHandleProfileFollow} from './useHandleProfileFollow'

export function ProfileFollowButton(props: {
  type: TProfileType
  isFollowing: boolean
  name: string
  _id: string
  url: string
  location: 'account-profile' | 'group-profile' | 'event-page'
}) {
  const {toggleFollowProfile, isLoading} = useHandleProfileFollow(props.type, props.location, props.name)
  const {currentUser} = useSessionContext()
  const navigate = useNavigate()
  const [optimisticFollowingState, setOptimisticFollowingState] = useState(props.isFollowing)

  const onClick = () => {
    if (!currentUser) return loginRedirect(navigate, `/${props.type === 'account' ? 'p' : 'g'}/${props.url}`)
    toggleFollowProfile(props._id, optimisticFollowingState)
    setOptimisticFollowingState(ofs => !ofs)
  }

  return (
    <Button className={optimisticFollowingState ? 'dark' : 'white'} onClick={onClick} isLoading={isLoading}>
      {optimisticFollowingState ? 'Following' : 'Follow'}
    </Button>
  )
}
