import {trpc} from 'lib/trpc'
import moment from 'moment'

import {GetMultiSessionEventInput, GetMultiSessionEventOutput} from './useGetMultiSessionEvent'

export const useGetNextMultiSessionEvents = (input: GetMultiSessionEventInput & {eventId: string; limit?: number}) => {
  const {eventId, limit = 5} = input
  return trpc.multiSessionEvents.getMultiSessionEvent.useQuery(input, {
    select: (data: GetMultiSessionEventOutput) => {
      const currentTime = moment()
      const upcomingEvents = data.events
        .filter(event => moment(event.startUtc).isAfter(currentTime) && event._id.toString() !== eventId)
        .sort((a, b) => moment(a.startUtc).diff(moment(b.startUtc)))
        .slice(0, limit)

      return upcomingEvents
    },
    enabled: !!eventId && !!input.id,
  })
}
