import {WebSessionAccount} from '@posh/models'
import {captureException} from '@sentry/react'
import {FetchEventResponsePublic} from 'apis/Events/useFetchEvent'

import {pushToDataLayer} from '../dataLayerUtils'

export const eventPageView = (event: FetchEventResponsePublic['event'], user?: WebSessionAccount) => {
  try {
    // Push the event data to the dataLayer event
    pushToDataLayer({
      event: 'eventView', // Mandatory event name
      eventId: event.id,
      eventName: event.name,
      fullEvent: event,
      purchaserEmail: user?.email,
      purchaserId: user?._id.toString(),
      purchaserName: `${user?.firstName} ${user?.lastName}`,
      purchaserPhone: user?.phone,
    })
  } catch (error) {
    captureException(error)
  }
}
