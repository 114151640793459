import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type TrackMultiSessionEventPageViewOptions = ReactQueryOptions['multiSessionEvents']['trackPageView']
export type TrackMultiSessionEventPageViewInput = RouterInputs['multiSessionEvents']['trackPageView']
export type TrackMultiSessionEventPageViewOutput = RouterOutput['multiSessionEvents']['trackPageView']

const useTrackMultiSessionEventPageView_api = (
  input: TrackMultiSessionEventPageViewInput,
  opts?: TrackMultiSessionEventPageViewOptions,
) => {
  return trpc.multiSessionEvents.trackPageView.useQuery(input, opts)
}

export function useTrackMultiSessionEventPageView(
  params: ({url: string} | {hexUrl: string}) & {
    trackingLinkValue: string | null | undefined
  },
) {
  const input = params.trackingLinkValue
    ? {
        ...params,
        trackingLinkValue: params.trackingLinkValue,
      }
    : null

  return useTrackMultiSessionEventPageView_api(input!, {enabled: !!input, retry: false})
}
