import React, {ComponentProps} from 'react'

import {pluralize} from '@posh/model-types'
import {Modal} from 'components/coreComponents/Modal'
import Button from 'components/form/Button'
import {Moment} from 'moment'
import {MultiSessionEventList} from 'pages/CreateEvent/components/LaunchModal/components/MultiSessionEventList'

interface MultiSessionNewEventsModalProps {
  isOpen: boolean
  onClose: () => void
  newDates: Moment[]
  template: ComponentProps<typeof MultiSessionEventList>['template']
  onLaunch: () => void
  isLaunching: boolean
}

export function MultiSessionNewEventsModal(props: MultiSessionNewEventsModalProps) {
  const {isOpen, onClose, newDates, template, onLaunch, isLaunching} = props
  return (
    <Modal.Glass
      className='w-full md:max-w-[650px] md:min-w-[500px]'
      isOpen={isOpen}
      onClose={onClose}
      blurRadius={50}
      blurTint='dark'
      brightness={0.9}>
      <div className='p-8'>
        <div className='flex flex-col gap-6'>
          <h3 className={'text-large noMargin text-center font-medium! tracking-tight'}>
            Ready to extend your event series?
          </h3>
          <span className={'noMargin text-medium text-center tracking-tight text-gray-300'}>
            {`This will add ${newDates.length} new ${pluralize('event', newDates.length)} to your event series.`}
          </span>
          <MultiSessionEventList eventDates={newDates} template={template} />
          <div className='flex justify-center gap-4'>
            <Button className='dark' onClick={onClose}>
              Close
            </Button>
            <Button isLoading={isLaunching} onClick={onLaunch} disabled={isLaunching}>
              Extend
            </Button>
          </div>
        </div>
      </div>
    </Modal.Glass>
  )
}
