import {StatsigExperimentKey} from '@posh/model-types'
import {useStatsigClient} from '@statsig/react-bindings'

type UseExperimentParams = {
  experimentKey: StatsigExperimentKey
  ignoreOverrides?: boolean
  enabled?: boolean
}

/**
 * A hook that returns the value of an experiment from statsig.
 * If the experiment is disabled, it returns a value of false.
 * If the experiment is enabled, it returns the value of the experiment.
 * If the experiment is not found, it returns a value of false.
 */
export const useExperiment = ({experimentKey, enabled = true}: UseExperimentParams) => {
  const statsigClient = useStatsigClient()

  // If the experiment check is not enabled, then just return false (which never calls the statsig api yet)
  if (!enabled) {
    return {value: false}
  }

  const {value} = statsigClient.getExperiment(experimentKey)
  return {value: value.enabled as boolean}
}
