import React from 'react'
import Loader from 'react-loader-spinner'

import Button from 'components/form/Button'

interface DeleteButtonProps {
  onDeleteTicket: () => void
  isDeletingTicket: boolean
  isFetchingOrders: boolean
  isErrorFetchingOrders: boolean
  totalOrders: number
}

function Text(props: {hasOrders: boolean; isFetchingOrders: boolean; isErrorFetchingOrders: boolean}) {
  const {hasOrders, isFetchingOrders, isErrorFetchingOrders} = props
  if (isErrorFetchingOrders) {
    return (
      <p className='m-0' style={{color: 'red'}}>
        <em>There was an error checking if this ticket has been purchased.</em>
      </p>
    )
  }
  if (hasOrders) {
    return (
      <p className='grey m-0'>
        <em>This ticket cannot be deleted because it has been purchased already.</em>
      </p>
    )
  }
  if (isFetchingOrders) {
    return (
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px'}}>
        <Loader type='Oval' color='#fff' height={20} width={20} />
        <p className='grey m-0'>Checking if this ticket has been purchased...</p>
      </div>
    )
  }
  return (
    <p className='m-0'>
      <em>Tickets can only be deleted if they have not been purchased.</em>
    </p>
  )
}

export function DeleteTicketButton(props: DeleteButtonProps) {
  const {onDeleteTicket, isDeletingTicket, totalOrders, isFetchingOrders, isErrorFetchingOrders} = props
  const hasOrders = totalOrders > 0

  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px'}}>
      <Button
        onClick={onDeleteTicket}
        isLoading={isDeletingTicket}
        disabled={isDeletingTicket || hasOrders || isFetchingOrders || isErrorFetchingOrders}
        className='redHover dark'>
        Delete Ticket
      </Button>
      <Text hasOrders={hasOrders} isFetchingOrders={isFetchingOrders} isErrorFetchingOrders={isErrorFetchingOrders} />
    </div>
  )
}
