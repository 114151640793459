import React, {memo, useEffect, useState} from 'react'

import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {Panel} from 'components/Panel/Panel'

import {Table, TableProps} from '../Table/Table'
import {TableButton, TableButtonProps} from '../TableButton/TableButton'
import {TableIcon} from '../TableIcons/TableIcon'
import {parseCustomColumnConfigs} from './internals/table-helpers'
import {ActionButton, ColumnConfig} from './internals/types'

import styles from './CRUDTable.module.scss'

type ExtractStringValues<T> = {
  [P in keyof T]: T[P] extends string ? P : never
}[keyof T]

const ICON_SIZE = 14

interface CreateButton {
  title?: string
  onClick?: () => void
}
interface CRUDTableProps<T> extends Omit<TableProps<T>, 'table'> {
  columns: ColumnConfig<T>[]
  actionButtons?: ActionButton<T>[]
  searchableColumn?: ExtractStringValues<T>
  resourceName: string
  hideResourceName?: boolean
  actions?: TableButtonProps[]
  editForm?: (params: {row: T; onClose: () => void}) => JSX.Element
  createForm?: (params: {onClose: () => void}) => JSX.Element
  createButton?: CreateButton
  onReorderEnd?: (oldIndex: number, newIndex: number) => void
}

const DEFAULT_PAGE_SIZE = 10

const CRUDTableComponent = <T,>(props: CRUDTableProps<T>) => {
  const {
    data,
    actionButtons = [],
    columns,
    searchableColumn,
    createForm,
    editForm,
    actions = [],
    onClickRow,
    createButton = {
      title: `+ Create ${props.resourceName}`,
    },
    onReorderEnd,
    ...tableProps
  } = props

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [editRow, setEditRow] = useState<T | null>(null)
  const [isCreatePanelOpen, setIsCreatePanelOpen] = useState(false)

  const reactTableColumns = parseCustomColumnConfigs(columns, actionButtons)
  const pageSize = tableProps.itemsPerPage || DEFAULT_PAGE_SIZE

  const table = useReactTable({
    data,
    columns: reactTableColumns,
    initialState: {
      pagination: {
        pageSize,
      },
    },
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
  })

  const resourceName = props.resourceName

  const onClickRowWrapper = editForm
    ? (row: T) => {
        setEditRow(row)
      }
    : onClickRow

  const onClickCreate = () => {
    if (createButton.onClick) return createButton.onClick()
    setIsCreatePanelOpen(true)
  }

  const onCreateFormClose = () => {
    setIsCreatePanelOpen(false)
  }

  const onEditFormClose = () => {
    setEditRow(null)
  }

  useEffect(() => {
    table.setPageSize(pageSize)
  }, [pageSize])

  const hasSearchableColumn = searchableColumn && typeof searchableColumn === 'string'
  const isSearching = (hasSearchableColumn && !!table.getColumn(searchableColumn)?.getFilterValue()) || false

  return (
    <div className={styles.CRUDTable}>
      <div className={styles.CRUDTableHeader}>
        {!props.hideResourceName && <h3 style={{textTransform: 'capitalize'}}>{resourceName}s</h3>}
        {(createForm || !!createButton.onClick) && (
          <Button type='button' style={{textTransform: 'capitalize'}} onClick={onClickCreate}>
            {createButton.title}
          </Button>
        )}
      </div>
      {hasSearchableColumn && (
        <Input
          icon={<TableIcon name='search' width={ICON_SIZE} height={ICON_SIZE} color={'#909193'} />}
          placeholder={`Search ${resourceName.toLowerCase()}s`}
          value={table.getColumn(searchableColumn)?.getFilterValue() as string}
          onChange={event => table.getColumn(searchableColumn)?.setFilterValue(event.target.value)}
          className='hasIcon'
        />
      )}
      <div className={styles.ActionButtons}>
        {actions.map((action, index) => (
          <TableButton key={`action-${index}`} {...action} />
        ))}
      </div>
      {/** TABLE */}
      <Table
        table={table}
        data={data}
        isSearching={isSearching}
        onClickRow={onClickRowWrapper}
        onReorderEnd={onReorderEnd}
        {...tableProps}
      />
      {/** CREATE PANEL */}
      <Panel isOpen={isCreatePanelOpen} onClose={onCreateFormClose} title={`Create ${resourceName}`}>
        {createForm && createForm({onClose: onCreateFormClose})}
      </Panel>

      {/** EDIT PANEL */}
      <Panel isOpen={!!editRow} onClose={onEditFormClose} title={`Edit ${resourceName}`}>
        {editRow && editForm && editForm({row: editRow, onClose: onEditFormClose})}
      </Panel>
    </div>
  )
}

// How to make this component memoized but also have a generic
// https://stackoverflow.com/questions/60386614/how-to-use-props-with-generics-with-react-memo

export const CRUDTable = memo(CRUDTableComponent) as typeof CRUDTableComponent
