import React from 'react'

import {CustomFieldType} from '@posh/model-types'
import {CheckboxesIcon, DropdownIcon, FileUploadIcon, PlusSign, TextInputIcon} from 'components/assets/Icons'

const ButtonTextMap: Record<CustomFieldType, string> = {
  input: 'Text Input',
  checkboxes: 'Checkboxes',
  dropdown: 'Dropdown',
  file_upload: 'File Upload',
}
const ButtonIconMap: Record<CustomFieldType, JSX.Element> = {
  input: <TextInputIcon />,
  checkboxes: <DropdownIcon />,
  dropdown: <CheckboxesIcon />,
  file_upload: <FileUploadIcon />,
}

interface AddFieldTypeButtonProps {
  variant: CustomFieldType
  onPress?: (variant: CustomFieldType) => void
}
const AddFieldTypeButton = ({variant, onPress}: AddFieldTypeButtonProps) => {
  const onPressButton = () => {
    onPress && onPress(variant)
  }
  return (
    <div className='AddFieldTypeButton'>
      {ButtonIconMap[variant]}
      <p className='m-0'>{ButtonTextMap[variant]}</p>
      <button onClick={onPressButton}>
        <PlusSign />
      </button>
    </div>
  )
}

export default AddFieldTypeButton
