import {TGroupSmsOptIn} from '@posh/sms-opt-ins'
import {DeepPartialWithOptInType} from '@posh/sms-opt-ins'
import {useTrackRudderStackEvent} from 'hooks/useTrackRudderstackEvent'
import {produce} from 'immer'
import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

import {GetAccountOptInsInput} from './useGetAccountOptIns'

export type UpdateAccountOptIn = ReactQueryOptions['optIns']['updateAccountOptIn']
export type UpdateAccountOptInInput = RouterInputs['optIns']['updateAccountOptIn']
export type UpdateAccountOptInOutput = RouterOutput['optIns']['updateAccountOptIn']

/**
 * The possible values for the smsOptIn field in the OptIn document
 */
export enum SmsOptInOptions {
  Yes = 'yes',
  NoApplication = 'no-application',
  NoSubaccount = 'no-subaccount',
}

/**
 * Creates a new document with updated opt-ins for marketing SMS
 * @param prev - The previous OptIn document
 * @param value - The new value
 * @returns A new OptIn document with updated opt-ins
 */
export const updateMarketingSmsOptIn = (prev: UpdateAccountOptInInput, value: boolean) =>
  produce(prev, draft => {
    if (draft.optInType !== 'posh-sms') return
    if (!draft.optIns?.marketing) return
    if (draft.optIns.marketing.smsOptIn === SmsOptInOptions.NoSubaccount) return
    draft.optIns.marketing.smsOptIn = value ? SmsOptInOptions.Yes : SmsOptInOptions.NoApplication
  })

/**
 * Creates a new document with updated opt-ins for transactional SMS
 * @param prev - The previous OptIn document
 * @param value - The new value
 * @returns A new OptIn document with updated opt-ins
 */
export const updateTransactionalSmsOptIn = (prev: UpdateAccountOptInInput, value: boolean) =>
  produce(prev, draft => {
    if (draft.optInType !== 'posh-sms') return
    if (!draft.optIns?.transactional) return
    if (draft.optIns.transactional.smsOptIn === SmsOptInOptions.NoSubaccount) return
    draft.optIns.transactional.smsOptIn = value ? SmsOptInOptions.Yes : SmsOptInOptions.NoApplication
  })

/**
 * Creates a new document with updated opt-ins for transactional and marketing SMS
 * @param prev - The previous OptIn document
 * @param value - The new value
 * @returns A new OptIn document with updated opt-ins
 */
export const updateAllPoshSmsOptIn = (prev: UpdateAccountOptInInput, value: boolean) =>
  produce(prev, draft => {
    if (draft.optInType !== 'posh-sms') return
    if (draft.optIns?.marketing && draft.optIns.marketing.smsOptIn !== SmsOptInOptions.NoSubaccount) {
      draft.optIns.marketing.smsOptIn = value ? SmsOptInOptions.Yes : SmsOptInOptions.NoApplication
    }
    if (draft.optIns?.transactional && draft.optIns.transactional.smsOptIn !== SmsOptInOptions.NoSubaccount) {
      draft.optIns.transactional.smsOptIn = value ? SmsOptInOptions.Yes : SmsOptInOptions.NoApplication
    }
  })

/**
 * Creates a document to update all group SMS opt-ins for a given account
 * @param accountId - The ID of the account
 * @param value - The new value
 * @returns An object used to update all group SMS opt-ins for a given account
 */
export const updateAllGroupSmsOptIns = ({
  accountId,
  value,
}: {
  accountId: string
  value: boolean
}): DeepPartialWithOptInType<TGroupSmsOptIn> => ({
  account: {id: accountId},
  optInType: 'group-sms',
  optIns: {smsOptIn: value ? 'yes' : 'no-application'},
})

export interface UpdateAccountOptInOpts extends UpdateAccountOptIn {
  query?: GetAccountOptInsInput
  location?: string
  optInPopUpExperiment?: boolean
}

export function useUpdateAccountOptIn(opts?: UpdateAccountOptInOpts) {
  const {getAccountOptIns} = trpc.useContext().optIns
  const {trackUserMarketingOptIn} = useTrackRudderStackEvent()

  return trpc.optIns.updateAccountOptIn.useMutation({
    ...opts,
    onMutate: async input => {
      await getAccountOptIns.cancel()
      const prevAccountOptIns = getAccountOptIns.getData(opts?.query)
      getAccountOptIns.setData(opts?.query, old =>
        produce(old, draft => {
          draft?.data.forEach(optIn => {
            if (optIn.id === input.id) {
              Object.assign(optIn, input)
            }
          })
        }),
      )
      return {prevAccountOptIns}
    },
    onError: (error, input, context) => {
      if (context) {
        getAccountOptIns.setData(opts?.query, context.prevAccountOptIns)
      }
      if (opts?.onError) opts.onError(error, input, context)
    },
    onSuccess: (data, input, context) => {
      const location = opts?.location || 'unknown-location'
      const optInPopUpExperiment = opts?.optInPopUpExperiment || false

      // check if the smsOptIn has changed from no-application to yes
      const prevOptIn = context?.prevAccountOptIns?.data.find(optIn => optIn.id === input.id)
      const prevSmsOptIn = prevOptIn?.optInType === 'posh-sms' && prevOptIn?.optIns?.marketing?.smsOptIn
      const newSmsOptIn = input.optInType === 'posh-sms' && input.optIns?.marketing?.smsOptIn

      // track the mutation if the value is changing from no-application to yes
      const isChangingFromNoApplicationToYes = prevSmsOptIn !== newSmsOptIn && newSmsOptIn === 'yes'
      if (isChangingFromNoApplicationToYes) {
        trackUserMarketingOptIn({location, optInPopUpExperiment})
      }
      if (opts?.onSuccess) opts.onSuccess(data, input, context)
    },
    onSettled: data => {
      if (data) getAccountOptIns.invalidate()
    },
  })
}
