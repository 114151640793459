import React from 'react'
import {Control, useController} from 'react-hook-form'

import PoshSwitch from 'components/form/PoshSwitch'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {EventVisualsSection} from 'components/PageComponents/EventVisuals/Page/Section'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'

import {useEventVisualsContext} from '../../context/EventVisualsContext'

function useFormValues(control: Control<EventVisualsForm>) {
  const {
    field: {value: passwordEnabled, onChange: setPasswordEnabled},
  } = useController({
    control,
    name: 'passwordEnabled',
  })
  const {
    field: {value: displayOnThirdPartySites, onChange: setDisplayOnThirdPartySites},
  } = useController({
    control,
    name: 'displayOnThirdPartySites',
  })
  return {passwordEnabled, setPasswordEnabled, displayOnThirdPartySites, setDisplayOnThirdPartySites}
}

export function EventSettingsSection({control}: {control: Control<EventVisualsForm>}) {
  const {passwordEnabled, setPasswordEnabled, displayOnThirdPartySites, setDisplayOnThirdPartySites} =
    useFormValues(control)
  const {
    palette: {accentColor, textContrasting, lightmode},
  } = useEventVisualsContext()

  return (
    <EventVisualsSection headerText='Event Settings' underlined>
      <p style={{color: textContrasting}}>
        Increase sales by driving organic discovery and list your event on the Posh Explore Page!
      </p>
      <PoshSwitch
        subtitle='This lists your event publicly on the Posh Explore Page'
        rightTitle='Show on Explore'
        switchOptions={{
          checked: displayOnThirdPartySites ?? false,
          onChange: setDisplayOnThirdPartySites,
        }}
        textColor={lightmode ? '#000' : '#fff'}
      />
      <PoshSwitch
        rightTitle='Password Protected Event'
        subtitle='Attendees will need a password to access the event page'
        switchOptions={{
          checked: passwordEnabled ?? false,
          onChange: setPasswordEnabled,
        }}
        textColor={lightmode ? '#000' : '#fff'}
      />
      {passwordEnabled && (
        <EventVisualsTextInput.Text.Controlled
          control={control}
          name='password'
          placeholder='Password'
          lightMode={lightmode}
          accentColor={accentColor}
          rules={{required: passwordEnabled}}
        />
      )}
    </EventVisualsSection>
  )
}
