// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageTitle__zK\\+mn{display:flex;flex-direction:column}.PageTitle__zK\\+mn h1.title__aqqsa{font-size:24px;margin:0;font-weight:500;cursor:default;letter-spacing:-0.72}", "",{"version":3,"sources":["webpack://./src/components/Navigation/Header/Desktop/components/PageTitle/styles.module.scss"],"names":[],"mappings":"AAAA,mBACI,YAAA,CACA,qBAAA,CAEA,mCACI,cAAA,CACA,QAAA,CACA,eAAA,CACA,cAAA,CACA,oBAAA","sourcesContent":[".PageTitle {\n    display: flex;\n    flex-direction: column;\n\n    h1.title {\n        font-size: 24px;\n        margin: 0;\n        font-weight: 500;\n        cursor: default;\n        letter-spacing: -0.72;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageTitle": "PageTitle__zK+mn",
	"title": "title__aqqsa"
};
export default ___CSS_LOADER_EXPORT___;
