// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GalleryImage__0\\+RsU{position:relative;cursor:grab}.GalleryImage__0\\+RsU img{height:300px;object-fit:contain;border-radius:5px}.GalleryImage__0\\+RsU .deleteButtonContainer__\\+gMX9{position:absolute;left:29px;top:29px;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/pages/EventManagementPages/Visuals/Upgrade-dev/components/page-sections/VenueImages/components/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,WAAA,CAEA,0BACI,YAAA,CACA,kBAAA,CACA,iBAAA,CAGJ,qDACI,iBAAA,CACA,SAAA,CACA,QAAA,CACA,cAAA","sourcesContent":[".GalleryImage {\n    position: relative;\n    cursor: grab;\n\n    img {\n        height: 300px;\n        object-fit:contain;\n        border-radius: 5px;\n    }\n\n    .deleteButtonContainer {\n        position: absolute;\n        left: 29px;\n        top: 29px;\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GalleryImage": "GalleryImage__0+RsU",
	"deleteButtonContainer": "deleteButtonContainer__+gMX9"
};
export default ___CSS_LOADER_EXPORT___;
