import React from 'react'

import {
  EventVisualsBaseNumberInput,
  EventVisualsNumberInputProps,
} from 'components/PageComponents/EventVisuals/Form/Input/TextInput/Number'
import {TableIcon} from 'components/TableUI/TableIcons/TableIcon'

import styles from './RecurrenceSettingsNumberInput.module.scss'

type RecurrenceSettingsNumberInputProps = Omit<
  EventVisualsNumberInputProps,
  'accentColor' | 'lightMode' | 'placeholder'
>

const ARROW_SIZE = 10

export const RecurrenceSettingsNumberInput = (props: RecurrenceSettingsNumberInputProps) => {
  const value = props.value ?? 0
  const increment = () => {
    if (props.disabled) return
    props.onChange(value + 1)
  }

  const decrement = () => {
    if (props.disabled) return
    props.onChange(value - 1)
  }

  return (
    <div className={styles.RecurrenceSettingsNumberInput}>
      <EventVisualsBaseNumberInput lightMode={false} accentColor='' placeholder='' {...props} />
      <div className={styles.NumberInputArrows}>
        <div className={styles.NumberInputArrowContainer} onClick={increment}>
          <TableIcon width={ARROW_SIZE} height={ARROW_SIZE} name='triangle-down' style={{rotate: '180deg'}} />
        </div>
        <div className={styles.NumberInputArrowContainer} onClick={decrement}>
          <TableIcon width={ARROW_SIZE} height={ARROW_SIZE} name='triangle-down' />
        </div>
      </div>
    </div>
  )
}
