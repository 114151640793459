import React, {useEffect} from 'react'

import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'

import {brushUrl} from '../../assets/Icons'

import './styles.scss'

interface Props {
  title?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  isLightMode?: boolean
  style?: React.CSSProperties
}

const ColorPicker = ({title, value, onChange, isLightMode, style}: Props) => {
  const {showToast} = useToast()
  const hexToInteger = (hex: any) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? parseInt(result[1], 16) + parseInt(result[2], 16) + parseInt(result[3], 16) : null
  }

  const combinedRGB = hexToInteger(value)

  useEffect(() => {
    if (combinedRGB) {
      if (combinedRGB < 200 && !isLightMode) {
        showToast({
          type: 'warning',
          title:
            "The color selected will interfere with user's visibility when using dark mode, a lighter color should be chosen.",
        })
      } else if (combinedRGB > 500 && isLightMode) {
        showToast({
          type: 'warning',
          title:
            "The color selected will interfere with user's visibility when using light mode, a darker color should be chosen.",
        })
      }
    }
  }, [combinedRGB])

  return (
    <label className='ColorPicker' style={style}>
      {title && <span className='ColorPicker'>{title}</span>}
      <div className='ColorPicker-content'>
        <input
          className='ColorPicker-input'
          onChange={onChange}
          id='accentInput'
          type='color'
          value={value}
          defaultValue={isLightMode ? '#000000' : '#FFFFFF'}
        />
        <div className='ColorPicker-content--filter' />
        <PoshImage className='ColorPicker-content--icon' src={brushUrl} />
      </div>
    </label>
  )
}

export default ColorPicker
