import React from 'react'
import {useNavigate} from 'react-router-dom'

import {TapToPayDisabled, TapToPayEnabled, TapToPayState} from '@posh/types'
import classNames from 'classnames'
import PoshInfo from 'components/form/PoshInfo'
import PoshSwitch from 'components/form/PoshSwitch'

interface TapToPayStateSliderProps {
  label?: string
  isDisabled: boolean
  tooltip?: string
  groupTapToPayState: TapToPayState
  eventTapToPayState?: TapToPayState
  tapToPayEventStateChanged?: (state: TapToPayState) => void
  tapToPayGroupStateChanged?: (state: TapToPayState) => void
  className?: string
}

export const TapToPayStateSlider = (props: TapToPayStateSliderProps) => {
  const {
    label,
    isDisabled,
    tooltip,
    groupTapToPayState,
    eventTapToPayState,
    tapToPayEventStateChanged,
    tapToPayGroupStateChanged,
    className,
  } = props

  const navigate = useNavigate()

  // Button is only checked when TTP has been setup and enabled
  const buttonChecked = groupTapToPayState == TapToPayEnabled && eventTapToPayState != TapToPayDisabled

  return (
    <div className={classNames('flex flex-row items-center gap-3', className)}>
      <div className='flex flex-col'>
        <p className='text-sm text-white/76'>{!!label ? label : 'In-Person Payments'}</p>
        {tooltip && <PoshInfo direction={'down'} info={tooltip}></PoshInfo>}
      </div>
      <PoshSwitch
        disabled={isDisabled}
        switchOptions={{
          checked: buttonChecked,
          onChange: (checked, event) => {
            if (checked && groupTapToPayState === 'not setup') {
              return navigate('/in-person-payments-app-overlay')
            }

            if (eventTapToPayState != undefined && tapToPayEventStateChanged) {
              // They are just enabling/disabling tap to pay for this specific event
              tapToPayEventStateChanged(checked ? TapToPayEnabled : TapToPayDisabled)
            } else {
              // They are just enabling/disabling tap to pay for their group
              tapToPayGroupStateChanged?.(checked ? TapToPayEnabled : TapToPayDisabled)
            }
          },
        }}
      />
    </div>
  )
}
