import {useMemo} from 'react'

export const useDeviceType = () => {
  return useMemo(() => {
    const userAgent = navigator.userAgent.toLowerCase()
    const isAndroid = /android/.test(userAgent)
    const isIOS = /iphone|ipad|ipod/.test(userAgent)
    const isMobile = isAndroid || isIOS || /mobile/i.test(userAgent)
    const isTablet = /ipad|tablet/i.test(userAgent)
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent)
    const isChrome = /chrome/.test(userAgent)
    const isFirefox = /firefox/.test(userAgent)
    const isDesktop = !isMobile && !isTablet

    // Detect PWA mode
    const isPWA = window.matchMedia('(display-mode: standalone)').matches

    // Detect if device supports touch
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0

    return {
      isAndroid,
      isIOS,
      isMobile,
      isTablet,
      isDesktop,
      isSafari,
      isChrome,
      isFirefox,
      isPWA,
      isTouchDevice,
    }
  }, [])
}
