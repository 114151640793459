import React from 'react'

import {FeatureFlag} from '@posh/model-types'
import {useFeatureGate} from 'hooks/useFeatureGate'

import {BaseFeatureFlag} from './index'

export const MultiSessionEventsOrganizerFeatureFlag = ({children}: {children: React.ReactNode}) => {
  const isMultiSessionEventsOrganizerEnabled = useFeatureGate(FeatureFlag.MULTISESSIONEVENTS_ORGANIZER)

  return (
    <BaseFeatureFlag showChildrenIf={isMultiSessionEventsOrganizerEnabled} showBorder={false}>
      {children}
    </BaseFeatureFlag>
  )
}
