import React from 'react'
import {useNavigate} from 'react-router-dom'

import useUpdateGroup from 'apis/Groups/useUpdateGroup'
import Button from 'components/form/Button'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {useToast} from 'components/toasts/ToastProvider'

import './styles.scss'

interface DeleteGroupProps extends PoshStyledModalProps {
  groupName?: string
  groupId: string
}

const DeleteGroupModal = (props: DeleteGroupProps) => {
  const {groupName, groupId} = props
  const {showToast} = useToast()
  const {mutateAsync: updateGroup, isLoading} = useUpdateGroup()
  const navigate = useNavigate()
  const handleDeleteGroup = async () => {
    try {
      await updateGroup({groupId, updates: {isDeleted: true}})
      showToast({type: 'success', title: 'Group successfully deleted'})
      navigate('/dashboard/orgs')
    } catch (error: any) {
      if (error.response) showToast({type: 'error', title: error.response.data.error})
      else showToast({type: 'error', title: 'Error deleting group. Please contact support@posh.vip'})
    }
  }

  return (
    <PoshStyledModal {...props}>
      <div className='DeleteGroup'>
        <h3 className='m-0'>{`Are you sure you want to delete ${groupName}?`}</h3>
        <p>All data associated with {groupName} will be removed. This action cannot be undone.</p>
        <Button className='redHover' onClick={handleDeleteGroup} disabled={isLoading}>{`Delete ${groupName}`}</Button>
      </div>
    </PoshStyledModal>
  )
}

export default DeleteGroupModal
