import React from 'react'

import {ColumnDef} from '@tanstack/react-table'
import {TableIcon} from 'components/TableUI/TableIcons/TableIcon'

import RequirePermissions from '../../../RequirePermissions'
import {TableCell} from '../../TableCell/TableCell'
import {TableHeader} from '../../TableHeader/TableHeader'
import {ActionButton, ColumnConfig} from './types'

import styles from '../CRUDTable.module.scss'

const ACTION_BUTTONS_WIDTH = 70
const ACTION_BUTTON_ICON_SIZE = 16

/**
 * This function is used to parse the custom column configs into the react-table column definitions.
 * The react-table column definitions are used to render the table header and cells.
 * @param customConfigs An array of custom column configs.
 * @returns An array of react-table column definitions.
 */
export const parseCustomColumnConfigs = <T,>(
  customConfigs: ColumnConfig<T>[],
  actionButtons: ActionButton<T>[] = [],
): ColumnDef<T>[] => {
  const columns: ColumnDef<T>[] = []

  customConfigs.forEach(config => {
    const {header, sortConfig, key} = config
    const column: ColumnDef<T> = {
      accessorKey: key,
      header: ({column}) => {
        if (sortConfig) {
          return (
            <TableHeader.Sortable
              text={header}
              sorted={column.getIsSorted()}
              onToggleSort={() => column.toggleSorting()}
            />
          )
        } else {
          return <TableHeader text={header} />
        }
      },
      cell: props => {
        const value = props.getValue() as T[keyof T]
        if ('render' in config) {
          return config.render(value, props.row.original)
        } else if (config.formatter) {
          return <TableCell.Text text={config.formatter(value)} />
        } else {
          return <TableCell.Text text={value as string} />
        }
      },
      ...(sortConfig
        ? {
            enableSorting: true,
            sortingFn: sortConfig.defaultSort ? 'basic' : (a, b) => sortConfig.sortFn(a.original[key], b.original[key]),
          }
        : {enableSorting: false}),
    }
    columns.push(column)
  })

  if (actionButtons.length > 0) {
    columns.push({
      id: 'actions',
      header: () => null,
      cell: ({row}) => {
        return (
          <div className={styles.ActionButtons} style={{width: ACTION_BUTTONS_WIDTH}}>
            {actionButtons.map(({icon, onClick, displayIf, requiredPermissions, toolTipProps, fill}, index) => {
              if (displayIf && !displayIf(row.original)) {
                return null
              } else
                return (
                  <RequirePermissions key={index} requiredPermissions={requiredPermissions}>
                    <TableIcon
                      width={ACTION_BUTTON_ICON_SIZE}
                      height={ACTION_BUTTON_ICON_SIZE}
                      key={index}
                      name={icon}
                      fill={fill ? fill(row.original) : 'white'}
                      toolTipProps={toolTipProps}
                      onClick={e => {
                        onClick(row.original)
                        e.stopPropagation()
                      }}
                      style={{cursor: 'pointer'}}
                    />
                  </RequirePermissions>
                )
            })}
          </div>
        )
      },
    })
  }

  return columns
}
