import React, {createContext, ReactNode, useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import ConfirmationModal from 'components/modals/Modal/ConfirmationModal'

interface MultiSessionEventContextType {
  isFormDirty: boolean
  setIsFormDirty: (isFormDirty: boolean) => void
  formDirtySafeNavigate: (path: string, newTab?: boolean) => void
  formDirtySafeGoBack: () => void
}

const MultiSessionEventContext = createContext<MultiSessionEventContextType | undefined>(undefined)

interface MultiSessionEventProviderProps {
  children: ReactNode
}

export function MultiSessionEventProvider({children}: MultiSessionEventProviderProps) {
  const navigate = useNavigate()
  const [navigateOnConfirmation, setNavigateOnConfirmation] = useState<string | number>()
  const [isFormDirty, setIsFormDirty] = useState(false)

  const formDirtySafeNavigate = (path: string, newTab?: boolean) => {
    if (isFormDirty) setNavigateOnConfirmation(path)
    else {
      setIsFormDirty(false)
      if (newTab) window.open(path, '_blank')
      else navigate(path)
    }
  }

  const formDirtySafeGoBack = () => {
    if (isFormDirty) {
      setNavigateOnConfirmation(-1)
    } else {
      navigate(-1)
    }
  }

  const value = {
    isFormDirty,
    setIsFormDirty,
    formDirtySafeNavigate,
    formDirtySafeGoBack,
  }

  return (
    <>
      <MultiSessionEventContext.Provider value={value}>{children}</MultiSessionEventContext.Provider>
      {navigateOnConfirmation && (
        <ConfirmationModal
          noborder
          isOpen={!!navigateOnConfirmation}
          onClose={() => setNavigateOnConfirmation(undefined)}
          onClick={() => {
            setIsFormDirty(false)
            if (typeof navigateOnConfirmation === 'string') {
              navigate(navigateOnConfirmation)
            } else {
              navigate(navigateOnConfirmation)
            }
            setNavigateOnConfirmation(undefined)
          }}
          successMessage={false}
          title={'Leave this page?'}
          bodyText={'Are you sure you want to leave this page? Any unsaved changes will be lost.'}
          confirmButtonText={'Yes, leave'}
          confirmationMessage=''
        />
      )}
    </>
  )
}

export function useMultiSessionEventContext() {
  const context = useContext(MultiSessionEventContext)
  if (context === undefined) {
    throw new Error('useMultiSessionEventContext must be used within a MultiSessionEventProvider')
  }
  return context
}
