import React from 'react'
import {useController, UseFormReturn} from 'react-hook-form'

import {CartCreationOrigin, TICKET_AVAILABLE_FOR_SALE_VIA_DEFAULT} from '@posh/types'
import PoshSwitch from 'components/form/PoshSwitch'
import {TicketSection} from 'components/PageComponents/EventVisuals/Page/Section/ticketSection'
import {CreateEventTicket} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'

import {BaseTicketForm} from './ticketSettingsForm/schema'

/**
 * Two different components with very similar structures because the TypeScript compiler
 * was too difficult to deal with at the moment.
 */
type CreateEventTicketAvailabilitySettingsProps = {
  form: UseFormReturn<CreateEventTicket>
}

type ManageTicketTypesTicketAvailabilitySettingsProps = {
  form: Pick<UseFormReturn<BaseTicketForm>, 'control' | 'setValue' | 'getValues' | 'watch'>
}

export const CreateEventTicketAvailabilitySettings = (props: CreateEventTicketAvailabilitySettingsProps) => {
  const {form} = props
  const {watch, setError, clearErrors} = form
  const {
    field: {onChange, value},
    fieldState: {error},
  } = useController({
    name: 'availableForSaleVia',
    control: form.control,
    defaultValue: TICKET_AVAILABLE_FOR_SALE_VIA_DEFAULT,
  })

  const onChangeAvailableForSaleVia = (availableForSaleVia: CartCreationOrigin[]) => {
    clearErrors('availableForSaleVia')
    if (availableForSaleVia.length === 0) {
      setError('availableForSaleVia', {
        message: 'Tickets must be available for sale via at least one channel',
      })
    }
    onChange(availableForSaleVia)
  }

  return (
    <TicketSection title='Ticket Type Availability' expandable style={{gap: '40px'}}>
      <TicketAvailabilitySettings
        value={value ?? TICKET_AVAILABLE_FOR_SALE_VIA_DEFAULT}
        onChangeAvailableForSaleVia={onChangeAvailableForSaleVia}
        // Free tickets are not available for in-person purchase (because of different routing through `createFreeOrder` on the backend)
        canEnableInPerson={{
          state: watch('price') !== 0 && watch('approvalRequired') === false,
          reason:
            watch('price') === 0
              ? 'Free tickets are not available in-person'
              : watch('approvalRequired') === true
                ? 'Approval required tickets are not available in-person'
                : undefined,
        }}
        errorMessage={error?.message}
      />
    </TicketSection>
  )
}

export const ManageTicketTypesTicketAvailabilitySettings = (
  props: ManageTicketTypesTicketAvailabilitySettingsProps,
) => {
  const {form} = props
  const {watch} = form
  const {
    field: {onChange: onChangeAvailableForSaleVia, value},
    fieldState: {error},
  } = useController({
    name: 'availableForSaleVia',
    control: form.control,
    defaultValue: TICKET_AVAILABLE_FOR_SALE_VIA_DEFAULT,
  })

  return (
    <div style={{margin: '5px 0', gap: '5px', display: 'flex', flexDirection: 'column'}}>
      <TicketAvailabilitySettings
        value={value ?? TICKET_AVAILABLE_FOR_SALE_VIA_DEFAULT}
        onChangeAvailableForSaleVia={onChangeAvailableForSaleVia}
        canEnableInPerson={{
          state: watch('price') !== 0 && watch('approvalRequired') === false,
          reason:
            watch('price') === 0
              ? 'Free tickets are not available in-person'
              : watch('approvalRequired') === true
                ? 'Approval required tickets are not available in-person'
                : undefined,
        }}
        errorMessage={error?.message}
      />
    </div>
  )
}

const TicketAvailabilitySettings = (props: {
  value: CartCreationOrigin[]
  onChangeAvailableForSaleVia: (value: CartCreationOrigin[]) => void
  canEnableInPerson: {
    state: boolean
    reason?: string
  }
  errorMessage?: string
}) => {
  const availableForSaleVia = props.value
  const isAvailableOnline = props.value.includes('event_page')
  const isAvailableInPerson = props.value.includes('tap_to_pay')

  const onPoshSwitchChangeAvailableForSaleVia = (value: CartCreationOrigin, checked: boolean) => {
    if (checked) {
      props.onChangeAvailableForSaleVia([...(availableForSaleVia ?? []), value])
    } else {
      props.onChangeAvailableForSaleVia(availableForSaleVia?.filter(item => item !== value))
    }
  }

  return (
    <>
      <PoshSwitch
        switchOptions={{
          onChange: checked => onPoshSwitchChangeAvailableForSaleVia('event_page', checked),
          checked: isAvailableOnline,
        }}
        rightTitle='Available Online'
        subtitle='Customers can purchase this ticket through your event page'
      />
      <PoshSwitch
        switchOptions={{
          onChange: checked => onPoshSwitchChangeAvailableForSaleVia('tap_to_pay', checked),
          checked: isAvailableInPerson,
        }}
        disabled={!props.canEnableInPerson.state}
        rightTitle='Available In-Person'
        subtitle={props.canEnableInPerson.reason ?? 'Customers can purchase this ticket through in-person payment'}
      />
      {!!props.errorMessage && (
        <div>
          <p className='error' style={{fontSize: '14px'}}>
            {props.errorMessage}
          </p>
        </div>
      )}
    </>
  )
}
