import {WebhookType} from 'apis/Groups'

export interface WebhookMap {
  type: WebhookType
  name: string
  description: string
  exampleResponse: string
}

const WebhooksMap: WebhookMap[] = [
  {
    type: 'new_order',
    name: 'New Order',
    description: 'This webhook is fired every time an attendee makes a successful purchase.',
    exampleResponse: `
      const body = {
        type: "new_order",
        account_first_name: "Jamal",
        account_last_name: "Jenkins",
        account_email: "jjenz@yahoo.com",
        account_phone: "+17267177272",
        items: [
          {
            item_id: "5e1f5b1b1b1b1b1b1b1b1b1b",
            name: "General Admission",
            price: 20
          }
        ],
        date_purchased: "2020-01-01T00:00:00.000Z",
        order_number: 128291,
        promo_code: "POSH20",
        subtotal: 20,
        total: 22.41,
        event_name: "Devante's Birthday Bash",
        event_start: "2020-01-01T00:00:00.000Z",
        event_end: "2020-01-02T00:00:00.000Z",
        event_id: "5e1f5b1b1b1b1b1b1b1b1b1b",
        tracking_link: "posh-tracking-1",
        cancelled: false,
        partialRefund: 0,
        refunded: false,
        disputed: false,
      }`,
  },
  {
    type: 'order_status_update',
    name: 'Order Updated',
    description:
      'This webhook is fired every time an order is updated, this may happen when theres a refund, dispute or other action made on an order',
    exampleResponse: `
      const body = {
        type: "order_status_update",
        account_first_name: "Jamal",
        account_last_name: "Jenkins",
        account_email: "jjenz@yahoo.com",
        account_phone: "+17267177272",
        items: [
          {
            item_id: "5e1f5b1b1b1b1b1b1b1b1b1b",
            name: "General Admission",
            price: 20
          }
        ],
        date_purchased: "2020-01-01T00:00:00.000Z",
        order_number: 128291,
        promo_code: "POSH20",
        subtotal: 20,
        total: 22.41,
        event_name: "Devante's Birthday Bash",
        event_start: "2020-01-01T00:00:00.000Z",
        event_end: "2020-01-02T00:00:00.000Z",
        event_id: "5e1f5b1b1b1b1b1b1b1b1b1b",
        tracking_link: "posh-tracking-1",
        cancelled: false,
        partialRefund: 0,
        refunded: false,
        disputed: false,
      }`,
  },
  {
    type: 'new_order_request',
    name: 'New Pending Order (Approval Required)',
    description: 'This webhook is fired every time a new pending order is created and is awaiting your approval.',
    exampleResponse: `
      const body = {
        type: "new_order_request",
        account_first_name: "Jamal",
        account_last_name: "Jenkins",
        account_email: "jjenz@yahoo.com",
        account_phone: "+17267177272",
        items: [
          {
            item_id: "5e1f5b1b1b1b1b1b1b1b1b1b",
            name: "General Admission",
            price: 20
          }
        ],
        date_purchased: "2020-01-01T00:00:00.000Z",
        promo_code: "POSH20",
        subtotal: 20,
        total: 22.41,
        event_name: "Devante's Birthday Bash",
        event_start: "2020-01-01T00:00:00.000Z",
        event_end: "2020-01-02T00:00:00.000Z",
        event_id: "5e1f5b1b1b1b1b1b1b1b1b1b",
        tracking_link: "posh-tracking-2",
      }`,
  },
]

export default WebhooksMap
