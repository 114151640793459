import React from 'react'

import classNames from 'classnames'

import {PoshWordmark, PoshWordmarkProps} from '../assets/branding/logos'

import styles from './styles.module.scss'

export const PoshLogo = (props: PoshWordmarkProps) => {
  return <PoshWordmark color={props.color} />
}

const WithAnchor = ({href = '/', className, ...props}: PoshWordmarkProps & {href?: string; className?: string}) => {
  return (
    <a className={className} href={href}>
      <PoshLogo {...props} />
    </a>
  )
}

const Animated = ({infinite = false, ...props}: PoshWordmarkProps & {infinite?: boolean}) => {
  return (
    <div className={classNames(styles.animatedLogo, {[styles.infinite]: infinite})}>
      <PoshLogo {...props} />
    </div>
  )
}

PoshLogo.Animated = Animated
PoshLogo.Link = WithAnchor
