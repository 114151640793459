import React from 'react'
import {useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'

import {zodResolver} from '@hookform/resolvers/zod'
import {ClientCustomCheckoutField, saveCustomCheckoutFieldSchema} from '@posh/model-types'
import {useFetchCustomCheckoutFields} from 'apis/CustomCheckoutFields/useFetchCustomCheckoutFields'
import {useSaveCustomCheckoutFields} from 'apis/CustomCheckoutFields/useSaveCustomCheckoutFields'
import CustomCheckoutFieldsCreator from 'components/CustomCheckoutFieldsCreator/CustomCheckoutFieldsCreator'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import SaveDiscardBottomBar from 'pages/EventManagementPages/SaveDiscardBottomBar'
import {z} from 'zod'

import './styles.scss'

export const NewCustomCheckoutFields = () => {
  const {groupId, eventId} = useParams()

  const form = useForm<{fields: ClientCustomCheckoutField[]}>({
    defaultValues: {fields: []},
    resolver: zodResolver(z.object({fields: saveCustomCheckoutFieldSchema.array()})),
    reValidateMode: 'onChange',
  })

  const {isLoading} = useFetchCustomCheckoutFields({eventId: eventId!}, {onSuccess: data => form.reset({fields: data})})

  const {mutate: saveCustomCheckoutFields} = useSaveCustomCheckoutFields()
  const onClickSaveChanges = form.handleSubmit(({fields: customCheckoutFields}) => {
    saveCustomCheckoutFields({fields: customCheckoutFields, eventId: eventId!, groupId: groupId!})
  })

  if (isLoading) {
    return (
      <div className='NewCustomCheckoutFields'>
        <SpinLoader />
      </div>
    )
  }

  return (
    <div className='NewCustomCheckoutFields'>
      <p className='m-0'>
        Custom checkout fields allow you to gather additional info from attendees during the checkout process.
      </p>
      <p className='m-0'>They appear at the beginning of checkout.</p>
      <CustomCheckoutFieldsCreator control={form.control} eventId={eventId!} />
      <SaveDiscardBottomBar
        onDiscard={() => form.reset()}
        onSave={onClickSaveChanges}
        isOpen={form.formState.isValid && form.formState.isDirty}
      />
    </div>
  )
}
