import React, {useEffect} from 'react'

import {TEventPlaylist} from 'apis/Events/playlists/useGetEventPlaylist'
import {useTrackEventPlaylistView} from 'apis/Events/playlists/useTrackEventPlaylistView'
import {useMixpanel} from 'apis/MixPanelHandler'
import UserListPreview from 'components/UserListPreview'
import {EventCard} from 'pages/EventCard'

export type EventPlaylistProps = TEventPlaylist & {
  slug: string
  locationInput: React.ReactNode
}

export const EventPlaylist = (props: EventPlaylistProps) => {
  const {
    title,
    events,
    description,
    curator,
    slug,
    totalAttendeePreview,
    totalAttending,
    backgroundImagePalette,
    locationInput,
    backgroundImage,
    thumbnailImage,
  } = props

  const {trackEvent} = useMixpanel()

  const curatorDetails = (() => {
    if (curator.type === 'account') {
      return {
        name: curator.accountName,
        avi: curator.accountAvi,
        verified: curator.accountVerified,
        link: `/p/${curator.accountUsername}`,
      }
    } else {
      return {
        name: curator.groupName,
        avi: curator.groupAvi,
        verified: curator.groupVerified,
        link: `/g/${curator.groupUrl}`,
      }
    }
  })()

  // Track the view of the playlist (in our DB)
  useTrackEventPlaylistView({playlistSlug: slug}, {staleTime: Infinity, retry: false})

  // Track the view of the playlist (in Mixpanel)
  useEffect(() => {
    trackEvent('Playlist View - Playlists', {slug})
  }, [])

  return (
    <div className='mx-auto flex w-full max-w-7xl flex-col items-center px-5 pt-10 pb-28'>
      <div className='flex flex-col items-center gap-10 pt-0 pb-16 md:flex-row md:pt-16'>
        <div className='aspect-square w-full max-w-100'>
          <img src={thumbnailImage} alt={title} className='h-full w-full rounded-lg object-cover' />
        </div>

        <div className='flex flex-col gap-4'>
          <h1 className='text-4xl font-medium text-white'>{title}</h1>

          {locationInput}

          <p>{description}</p>
          <div className='flex flex-row gap-3'>
            <p>{events.length} events</p>
            <div className='w-[1px] bg-[#fff5]' />
            <p>
              <b>Curated by {curatorDetails.name}</b>
            </p>
          </div>

          <div className='flex w-fit flex-row items-center gap-3 rounded-full bg-[#fff1] px-3 py-2 pr-4'>
            <UserListPreview>
              {totalAttendeePreview.map(({avi}, index) => (
                <UserListPreview.User key={index} imageSrc={avi} />
              ))}
            </UserListPreview>
            {totalAttending > 0 && (
              <p>
                <i>{totalAttending.toLocaleString()}+ attending</i>
              </p>
            )}
          </div>
        </div>
      </div>

      <div className='grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3'>
        {events.map(event => (
          <EventCard
            key={event.url}
            backgroundImage={event.flyer}
            url={event.url}
            timezone={event.timezone}
            startUtc={new Date(event.startUtc)}
            name={event.name}
            groupAvi={event.groupAvi}
            venueName={event.location}
            source='explore'
          />
        ))}
      </div>
    </div>
  )
}
