import {useState} from 'react'

import {ThirdPartyImageApi} from '@posh/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'

import {PictureState} from '../ImagePicker'
import Input from '../Input'
import {ImageApiSelector} from './apiSelector'
import {FlyerUploadButton} from './FlyerUploadButton'
import {ImageSelector} from './imageSelector'

import './styles.scss'

interface ImageSearchModalProps extends PoshStyledModalProps {
  setFile: (file: PictureState) => void
}

export const ImageSearchModal = (props: ImageSearchModalProps) => {
  const {setFile} = props
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const [search, setSearch] = useState('')
  const [selectedApi, setSelectedApi] = useState<ThirdPartyImageApi>('tenor')
  const searchType = selectedApi === 'tenor' ? 'gifs' : 'photos'

  const handleOnClickImage = (url: string) => {
    setFile({url, file: null})
    props.onClose()
  }

  const handleButtonClick = () => {
    trackMixpanelEvent('Create Event Upload Custom Flyer-  Stage 4 visuals page')
  }

  return (
    <PoshStyledModal {...props} noborder>
      <div className='ImageSearchModal'>
        <div className='ImageSearchModal-header'>
          <div className='ImageSearchModal-header-search'>
            <Input
              className='fullWidth'
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={`Search for ${searchType}...`}
            />
          </div>
          <FlyerUploadButton
            label={'Use Your Own'}
            onFileSelect={file => {
              setFile(file)
              props.onClose()
            }}
            onButtonClick={handleButtonClick}
          />
        </div>
        <ImageApiSelector setSelectedApi={setSelectedApi} selectedApi={selectedApi} />
        <ImageSelector search={search} onClick={img => handleOnClickImage(img)} selectedApi={selectedApi} />
      </div>
    </PoshStyledModal>
  )
}
