import React from 'react'
import {SubmitHandler, useForm} from 'react-hook-form'

import {zodResolver} from '@hookform/resolvers/zod'
import {IGroupLink} from 'apis/Groups'
import Button from 'components/form/Button'
import Input from 'components/form/Input'

import {CustomLinkSchema} from '../LinkRow'

interface AddNewLinkProps {
  onAdd: (customLink: IGroupLink) => void
  onCancel: () => void
}

const AddNewLink = (props: AddNewLinkProps) => {
  const {onAdd, onCancel} = props

  const {register, handleSubmit, formState} = useForm({
    defaultValues: {
      title: '',
      url: 'https://',
    },
    resolver: zodResolver(CustomLinkSchema),
  })

  const {errors} = formState

  const onSubmit: SubmitHandler<CustomLinkSchema> = data => onAdd(data)

  return (
    <div className='CustomLinks-L'>
      <div style={{display: 'block', width: '100%'}}>
        <div className='CustomLinks-updateLink'>
          <div>
            <p>Title</p>
            <Input placeholder={'Link Title'} {...register('title')} />
            {errors.title && <p className='CustomLinks-error'>{errors.title.message}</p>}
          </div>
          <div>
            <p>URL</p>
            <Input placeholder={'URL'} {...register('url')} />
            {errors.url && <p className='CustomLinks-error'>{errors.url.message}</p>}
          </div>
          <div className='CustomLinks-updateActions'>
            <Button className='Button--gray CustomLinks-updateActions-button cancel fullWidth dark' onClick={onCancel}>
              Cancel
            </Button>
            <Button className='CustomLinks-updateActions-button fullWidth' onClick={handleSubmit(onSubmit)}>
              Add Link
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddNewLink
