import {PropsWithChildren, useRef} from 'react'
import React from 'react'
import {Control, Controller, FieldValue, FieldValues, Path, RegisterOptions} from 'react-hook-form'
import ReactTooltip from 'react-tooltip'

import classNames from 'classnames'

import './styles.scss'

export interface CheckboxProps {
  onChecked: (checked: boolean) => void
  checked: boolean
  label?: string
  info?: string
  className?: string
  hideBackground?: boolean
  transparent?: boolean
  color?: string
  checkColor?: string
  textColor?: string
  subtitle?: string
  disabled?: boolean
  style?: React.CSSProperties
}

const DEFAULT_COLOR = '#00ccff'
const DEFAULT_TEXT_COLOR = '#ffffff'

const Checkbox = (props: PropsWithChildren<CheckboxProps>) => {
  const {
    onChecked,
    checked,
    label,
    subtitle,
    info,
    className,
    hideBackground,
    transparent,
    children,
    disabled,
    style,
    ...colors
  } = props
  const {color = DEFAULT_COLOR, textColor = DEFAULT_TEXT_COLOR} = colors
  const checkboxRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    if (disabled) return
    if (checkboxRef.current) checkboxRef.current.click()
  }

  return (
    <>
      <ReactTooltip className='poshToolTip' id='checkbox' effect='solid' place='top' />
      <div
        className={classNames('Checkbox flex items-center gap-2', className, {hideBackground, transparent, disabled})}
        data-tip={info}
        data-for='checkbox'>
        <input defaultChecked={checked} ref={checkboxRef} type='checkbox' onChange={e => onChecked(e.target.checked)} />
        <div
          className={'Checkbox-checkMark'}
          onClick={handleClick}
          style={{backgroundColor: checked ? color : 'transparent', borderColor: textColor}}></div>
        <div style={{gap: 5, display: 'flex', flexDirection: 'column', ...style}}>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            {label && (
              <label
                onClick={handleClick}
                className={`text-small m-0`}
                style={{color: textColor, width: 'fit-content'}}>
                {label}
              </label>
            )}
            {subtitle && (
              <p onClick={handleClick} className={`light m-0 text-xs`} style={{color: textColor, width: 'fit-content'}}>
                {subtitle}
              </p>
            )}
          </div>
          {children}
        </div>
      </div>
    </>
  )
}

interface ControlledCheckboxProps<T extends FieldValues> extends Omit<CheckboxProps, 'onChecked' | 'checked'> {
  control: Control<T>
  name: Path<T>
  rules?: RegisterOptions<T>
  defaultValue?: FieldValue<T>
  disabled?: boolean
}

function Controlled<T extends FieldValues>(props: ControlledCheckboxProps<T>) {
  const {control, name, defaultValue, disabled, rules, ...rest} = props

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      disabled={disabled}
      render={({field: {value, onChange}}) => (
        <Checkbox
          {...rest}
          style={disabled ? {opacity: 0.5, cursor: 'not-allowed'} : undefined}
          onChecked={checked => (!disabled ? onChange(checked) : undefined)}
          checked={value}
        />
      )}
    />
  )
}

Checkbox.Controlled = Controlled

export {Checkbox}
