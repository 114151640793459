import React from 'react'
import {useNavigate} from 'react-router-dom'

import {Group} from 'apis/Groups'

import './styles.scss'

const GroupPageSubNav = ({
  group,
  hierarchy,
  onNavigate,
}: {
  group?: Group
  hierarchy: string[]
  onNavigate?: () => void
}) => {
  const navigate = useNavigate()

  return (
    <div className='GroupPageSubNav'>
      <img
        src='https://posh-b2.s3.us-east-2.amazonaws.com/back.svg'
        onClick={() => (onNavigate ? onNavigate() : navigate(-1))}
      />
      {group && <span>{group?.name}</span>}
      {hierarchy.map((h, i) => (
        <span key={i}>
          {(group || i > 0) && '/'} {h}
        </span>
      ))}
    </div>
  )
}

export default GroupPageSubNav
