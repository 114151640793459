import React from 'react'

import BaseCopyBlock from 'components/CopyBlock'
import {generateTrackingLink} from 'pages/EventManagementPages/Overview'

import {EventsTableListItemProps} from './types'

import '../index.scss'

export function EventsTableListItemCopyBlock(props: EventsTableListItemProps & {isHidden?: boolean}) {
  const {item, trackingLink, isHidden} = props
  if (isHidden) return null
  return (
    <BaseCopyBlock
      links={generateTrackingLink({
        eventHex: item.hexUrl,
        eventUrl: item.url ?? '',
        trackingLink,
      })}
    />
  )
}
