import React from 'react'
import {Controller, FieldValues, Path} from 'react-hook-form'

import {InputContainer} from '../shared/InputContainer/InputContainer'
import {ControlledFormProps} from '../shared/types'

import styles from './RadioGroup.module.scss'

export interface RadioGroupProps<T> {
  value: T
  options: Array<{label: string; value: T; extra?: React.ReactNode}>
  onChange: (value: T) => void
  error?: string
}

export const RadioGroup = <T,>(props: RadioGroupProps<T>) => {
  const {value, options, onChange, error} = props

  return (
    <InputContainer error={error}>
      <div>
        {options.map((option, i) => {
          const stringifiedValue = JSON.stringify(option.value)
          return (
            <>
              <label key={i} className={styles.RadioLabel}>
                <input
                  type='radio'
                  value={stringifiedValue}
                  checked={value === option.value}
                  onChange={() => onChange(option.value)}
                  className={styles.RadioInput}
                />
                {option.label}
              </label>
              <div className={styles.RadioExtra}>{option.extra && option.extra}</div>
            </>
          )
        })}
      </div>
    </InputContainer>
  )
}

interface ControlledRadioGroup<T extends FieldValues, K extends Path<T>>
  extends Omit<RadioGroupProps<T[K]>, 'value' | 'onChange'>,
    ControlledFormProps<T, K> {}

const ControlledRadioGroup = <T extends FieldValues, K extends Path<T>>(props: ControlledRadioGroup<T, K>) => {
  const {control, name, rules, options} = props

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field, fieldState, formState: _formState}) => {
        return <RadioGroup {...field} error={fieldState.error?.message} options={options} />
      }}
    />
  )
}

RadioGroup.Controlled = ControlledRadioGroup
