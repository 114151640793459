import React, {useEffect, useRef, useState} from 'react'
import RGL, {Layout, WidthProvider} from 'react-grid-layout'
import {useMediaQuery} from 'react-responsive'

import {useQueryClient} from '@tanstack/react-query'
import {CartItem} from 'apis/Carts'
import {Coordinate, CreateEventTableParams, EventTable, StaticDecoration, VenueMap} from 'apis/Events/types'
import {useResize} from 'components/helpers/useResize'
import useCartContext from 'domains/Cart/CartContext'
import {useDimensions} from 'hooks/useDimensions'
import {groupBy} from 'lodash'
import compact from 'lodash/compact'
import includes from 'lodash/includes'
import isUndefined from 'lodash/isUndefined'

import useCreateEventTable from '../../../../apis/Events/useCreateEventTable'
import {useUpdateEventTable} from '../../../../apis/Events/useUpdateEventTable'
import {useResourcePageParams} from '../../../PoshAppLayout'
import TableItem, {TableItemModal} from '../TableItem'
import {generateLayout} from './helpers'

import './styles.scss'

// //  import css -- IMP!!!
// import '../node_modules/react-grid-layout/css/styles.css'
// import '../node_modules/react-resizable/css/styles.css'

const ReactGridLayout = WidthProvider(RGL)

const Legend = () => (
  <div className='InteractiveVenueMap-legend'>
    <div className='InteractiveVenueMap-legend-item'>
      <button className='TableItem available' />
      Available
    </div>
    <div className='InteractiveVenueMap-legend-item'>
      <button className='TableItem booked' />
      Booked
    </div>
    <div className='InteractiveVenueMap-legend-item'>
      <button className='TableItem disabled' />
      Disabled
    </div>
  </div>
)

const renderDecoration = (decoration: StaticDecoration, l: Layout, isLightMode: boolean) => {
  return (
    <div
      className='item disabled'
      key={l.i}
      style={{
        background: isLightMode ? '#eaeaea' : decoration.color,
        borderRadius: `10px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <p className='decoration-title'>{decoration.title}</p>
    </div>
  )
}

const renderTable = (
  tables: EventTable[],
  l: Layout,
  setIsViewingTables: (tables: EventTable[]) => void,
  cartItems: CartItem[],
  isEditable?: boolean,
  isDeleting?: boolean,
  isPurchasing?: boolean,
) => {
  const tableItemDisabled = () => {
    if (isPurchasing && tables.filter(t => t.sold || t.purchaseData?.accountId).length > 0) return true
    else if (cartItems && cartItems.length > 0) {
      const cartItemIds = cartItems.map(item => item.resourceId)
      const tableItemIds = tables.map(item => item._id)
      const intersection = cartItemIds.filter(id => includes(tableItemIds, id))
      return intersection.length > 0
    } else if (isEditable) return false
    else return tables.filter(t => t.disabled).length > 0
  }
  return (
    <div className='item disabled' key={l.i}>
      <TableItem
        tables={tables}
        onSelectTables={tables => {
          setIsViewingTables(tables)
        }}
        disabled={tableItemDisabled()}
        isDeleting={isDeleting}
      />
    </div>
  )
}

interface TablesListProps {
  tables: EventTable[]
  setTableToDuplicate: (table: CreateEventTableParams) => void
  tableToDuplicate?: CreateEventTableParams | null
}

const TablesList = (props: TablesListProps) => {
  const {tables, setTableToDuplicate, tableToDuplicate} = props
  const groupedTables = groupBy(tables, t => t.name)

  const onSelectTable = (table: EventTable) => {
    setTableToDuplicate({
      name: table.name,
      description: table.description,
      price: table.price,
      seatingLimit: table.seatingLimit,
      filterKey: table.filterKey,
    })
  }

  return (
    <div className='InteractiveVenueMap-tablesList'>
      {Object.keys(groupedTables).map(tableName => (
        <div key={tableName} className='InteractiveVenueMap-tablesList-item'>
          {tableName}
          <button
            className={'TableItem selectable clickable ' + (tableToDuplicate?.name == tableName ? 'selected' : '')}
            onClick={() => onSelectTable(groupedTables[tableName][0])}
          />
        </div>
      ))}
    </div>
  )
}

interface InteractiveVenueMapProps {
  venueMap: VenueMap
  decorations: StaticDecoration[]
  tables: EventTable[]
  onTableClick: (tables: EventTable[]) => void
  isLightMode: boolean
  // Editing the layout
  isEditing?: boolean
  isPurchasing?: boolean
  isEditable?: boolean
  isDeleting?: boolean
  boxShadow?: string
  selectedFilterKey?: string
}

const InteractiveVenueMap = (props: InteractiveVenueMapProps) => {
  const {cartItems} = useCartContext()
  const tableRef = useRef<HTMLHeadingElement>(null)
  const {width: gridWidth} = useResize(tableRef)
  const {
    venueMap,
    decorations,
    tables,
    isLightMode,
    onTableClick,
    isEditing,
    isPurchasing,
    isEditable,
    isDeleting,
    boxShadow,
    selectedFilterKey,
  } = props
  const {width, height, aspectRatio} = venueMap
  const autoScale = !!aspectRatio
  const gridHeight = aspectRatio && gridWidth * (1 / aspectRatio)
  const tableCoordinates = compact(
    tables.map(t => {
      if (t.mapPosition) {
        return {
          id: t._id,
          x: t.mapPosition[0],
          y: t.mapPosition[1],
        }
      }
    }),
  )

  const groupedTableCoordinates = Object.values(groupBy(tableCoordinates, item => `"${item.x}+${item.y}"`)).map(i => ({
    id: i.map(x => x.id),
    x: i[0].x,
    y: i[0].y,
  }))

  const {
    layout: generatedLayout,
    tableIds,
    tableIdMap,
    decorationIds,
    decorationMap,
    decorationCoordinatesAndTitle,
  } = generateLayout(width, height, groupedTableCoordinates, decorations)
  const {mutateAsync: updateEventTable} = useUpdateEventTable()

  const tableHasCustomBackground = !isUndefined(venueMap.backgroundColor) && !isUndefined(venueMap.backgroundImage)

  const {isMobile} = useDimensions()
  const isTablet = useMediaQuery({query: `(max-width: 900px)`})
  const rowHeightDivisor = isMobile ? 44 : isTablet ? 42.5 : 41
  const calculatedRowHeight = gridHeight && gridHeight / rowHeightDivisor
  const staticRowHeight = isMobile
    ? (venueMap.mobileMaxHeight ?? 40)
    : isTablet
      ? (venueMap.tabletMaxHeight ?? 40)
      : (venueMap.desktopMaxHeight ?? 40)

  const tableHeight = autoScale ? gridHeight : staticRowHeight * height
  const rowHeight = autoScale ? calculatedRowHeight : staticRowHeight
  const xValueToTotalDecorationHeightMap: {[key: number]: number} = {}

  decorationCoordinatesAndTitle.forEach(d => {
    const xs: number[] = []
    d.coordinates.forEach(c => {
      if (!includes(xs, c[0])) {
        xs.push(c[0])
      }
    })
    const {decoration} = d
    const {rectangle} = decoration
    const topLeft = rectangle[0]
    const bottomRight = rectangle[2]
    const decorationHeight = Math.abs(topLeft[1] - bottomRight[1]) + 1

    xs.forEach(x => {
      const valueAlreadySetForX = includes(Object.keys(xValueToTotalDecorationHeightMap), x.toString())

      if (valueAlreadySetForX) {
        xValueToTotalDecorationHeightMap[x] = xValueToTotalDecorationHeightMap[x] + decorationHeight
      } else {
        xValueToTotalDecorationHeightMap[x] = decorationHeight
      }
    })
  })

  decorationCoordinatesAndTitle.forEach(d => {
    const {decoration} = d
    const {rectangle} = decoration
    const topLeft = rectangle[0]
    const originPointX = d.coordinates.find(c => c[0] == topLeft[0] && c[1] == topLeft[1])![0]

    xValueToTotalDecorationHeightMap[originPointX] -= 1
  })

  const queryClient = useQueryClient()
  const {eventId} = useResourcePageParams()
  const [isViewingTable, setIsViewingTable] = useState<EventTable[] | null>(null)
  const [isCreatingTable, setIsCreatingTable] = useState(false)
  const [selectedCoordinate, setSelectedCoordinate] = useState<Coordinate | null>(null)
  const [tableToDuplicate, setTableToDuplicate] = useState<CreateEventTableParams | null>(null)
  const {mutateAsync: createEventTable} = useCreateEventTable()

  useEffect(() => {
    if (!isEditing) setTableToDuplicate(null)
  }, [isEditing])

  const renderLayoutItem = (l: Layout, i: number) => {
    const isTable = includes(tableIds, l.i)
    const isDecoration = includes(decorationIds, l.i)
    const className = isPurchasing || !isEditing || isDeleting || isCreatingTable ? 'item disabled' : 'item'

    if (includes(Object.keys(xValueToTotalDecorationHeightMap), String(l.x))) {
      const offset = xValueToTotalDecorationHeightMap[l.x]
      const maxY = height - 1 - offset
      if (l.y > maxY) {
        return <div className={`item hidden`} key={l.i} />
      }
    }

    if (isDecoration) {
      const decoration = decorationMap[i]
      return renderDecoration(decoration, l, isLightMode)
    } else if (isTable) {
      const tableIds = tableIdMap[i]
      const tableToRender = tables.filter(t => tableIds.includes(t._id))!
      return renderTable(
        tableToRender,
        l,
        tableToRender => {
          if (isDeleting) {
            onTableClick(tableToRender)
          } else {
            setIsViewingTable(tableToRender)
            setSelectedCoordinate([l.x, l.y])
          }
        },
        cartItems,
        isEditable,
        isDeleting,
        isPurchasing,
      )
    } else {
      const isSelected = selectedCoordinate && l.x == selectedCoordinate[0] && l.y == selectedCoordinate[1]
      return (
        <div
          className={`${className} ${isSelected ? 'selected' : ''}`}
          key={l.i}
          onClick={async () => {
            if (!isEditing) return
            setSelectedCoordinate([l.x, l.y])
            if (tableToDuplicate) {
              await createEventTable({
                eventId: eventId!,
                tables: [
                  {
                    ...tableToDuplicate,
                    mapPosition: [l.x, l.y],
                  },
                ],
              })
              await queryClient.invalidateQueries(['event', eventId])
            } else {
              setIsCreatingTable(true)
            }
          }}
        />
      )
    }
  }

  return (
    <div className='App InteractiveVenueMap' ref={tableRef}>
      <div className='InteractiveVenueMap-headerRow'>
        <div className='InteractiveVenueMap-leftContainer'>
          {!isPurchasing && <Legend />}
          {isEditing && (
            <TablesList tables={tables} setTableToDuplicate={setTableToDuplicate} tableToDuplicate={tableToDuplicate} />
          )}
        </div>
        {tableToDuplicate && (
          <h1 className='InteractiveVenueMap-tableInfo'>
            Creating table: <strong>{tableToDuplicate.name}</strong>
          </h1>
        )}
      </div>
      <ReactGridLayout
        className={`container ${isLightMode ? 'lightmode' : ''} ${isViewingTable ? 'blur' : ''}`}
        style={{
          background: tableHasCustomBackground
            ? `url(${venueMap.backgroundImage}) center ${venueMap.backgroundColor}`
            : isLightMode
              ? 'white'
              : '#232323',
          backgroundRepeat: 'no-repeat',
          height: `${tableHeight}px`,
          boxShadow: boxShadow,
        }}
        rowHeight={rowHeight}
        cols={width}
        maxRows={10}
        verticalCompact={false}
        width={1}
        measureBeforeMount={true}
        useCSSTransforms={false}
        isDraggable={false}
        isResizable={false}
        margin={[1, 1]}
        layout={generatedLayout}>
        {generatedLayout.map(renderLayoutItem)}
      </ReactGridLayout>
      {isViewingTable && selectedCoordinate && (
        <TableItemModal
          isPurchasing={isPurchasing}
          onPrimaryActionClicked={table => {
            setIsViewingTable(null)
            setSelectedCoordinate(null)
            onTableClick(table)
          }}
          onMarkTableSold={async table => {
            await updateEventTable({
              eventId: eventId!,
              tableId: table._id,
              tableParams: {
                ...table,
                sold: !table?.sold,
              },
            })
            await queryClient.invalidateQueries(['event', eventId])
          }}
          onDisableTable={async table => {
            await updateEventTable({
              eventId: eventId!,
              tableId: table._id,
              tableParams: {
                ...table,
                disabled: !table?.disabled,
              },
            })
            await queryClient.invalidateQueries(['event', eventId])
          }}
          tablePosition={selectedCoordinate}
          tables={isViewingTable}
          onClose={() => {
            setIsViewingTable(null)
            setSelectedCoordinate(null)
          }}
          selectedFilterKey={selectedFilterKey}
        />
      )}
      {isCreatingTable && selectedCoordinate && (
        <TableItemModal
          isPurchasing={isPurchasing}
          onPrimaryActionClicked={table => {
            setIsViewingTable(null)
            setSelectedCoordinate(null)
            setIsCreatingTable(false)
            onTableClick(table)
          }}
          tablePosition={selectedCoordinate}
          onClose={() => {
            setIsCreatingTable(false)
            setIsViewingTable(null)
            setSelectedCoordinate(null)
          }}
          selectedFilterKey={selectedFilterKey}
        />
      )}
    </div>
  )
}

export default InteractiveVenueMap
