import React, {useMemo, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {useCreateFreeOrder} from 'apis/Checkout/useCreateFreeOrder'
import useFetchCheckoutSummary from 'apis/Checkout/useFetchCheckoutSummary'
import {useFetchEventByUrl} from 'apis/Events/useFetchEvent'
import {trackPurchaseFailed} from 'apis/FbPixelHelpers'
import {useMixpanel} from 'apis/MixPanelHandler'
import CheckoutButton from 'components/form/CheckoutButton'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'
import {trackPurchaseAttempt} from 'helpers/tracking/coreEvents/purchaseAttempt'
import {useTrackRudderStackEvent} from 'hooks/useTrackRudderstackEvent'
import Stripe from 'stripe'

import {handleNavigation} from '..'
import {CartError} from '../CartError'
import {getCheckoutButtonText} from '../getCheckoutButtonText'
import useStripeConfirmPayment from '../useStripeConfirmPayment'

export type PaymentType = 'existing_card' | 'free_order'
type HandleCheckoutParams =
  | {
      paymentType: 'existing_card'
      paymentMethod: Stripe.PaymentMethod
    }
  | {
      paymentType: 'free_order'
    }

interface CheckoutButtonsProps {
  selectedPaymentMethod: Stripe.PaymentMethod | null | undefined
  hasAgreedToCuratorTos: boolean
  disabled: boolean
}

const CheckoutButtons = (props: CheckoutButtonsProps) => {
  const {hasAgreedToCuratorTos, selectedPaymentMethod, disabled} = props
  const navigate = useNavigate()
  const {currentUser} = useSessionContext()
  const urlParams = new URLSearchParams(window.location.search)
  const cartId = urlParams.get('cartId') as string
  const {eventUrl} = useParams<{eventUrl: string}>()
  const {showToast} = useToast()
  const {mutateAsync: createFreeOrder} = useCreateFreeOrder({
    onError: err => {
      showToast({type: 'error', title: 'There was an error trying to checkout.', subtitle: err.message})
    },
  })
  const {data: eventsResp} = useFetchEventByUrl(eventUrl)
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const {setFormErrors, setCustomFieldErrors, validateAllRequiredCheckoutFields, updateStripeError, stripeError} =
    useLiveCartContext()
  const [isHandlingCheckout, setIsHandlingCheckout] = useState(false)

  const {data: summary} = useFetchCheckoutSummary({cartId})
  const {cartInfo} = summary ?? {}
  const approvalRequiredForOrder = cartInfo?.approvalRequired
  const cartTicketCount = useMemo(() => {
    if (!cartInfo) return 0
    return cartInfo.items.reduce((acc, item) => acc + (item.resourceType === 'ticket' ? 1 : 0), 0)
  }, [cartInfo])

  const {trackPaidOrderCreated} = useTrackRudderStackEvent()

  const redirectUrl = useMemo(
    () =>
      handleNavigation({
        eventUrl: eventUrl!,
        cartId,
        approvalRequired: approvalRequiredForOrder,
        isRSVPEvent: eventsResp?.event.isRSVPEvent,
      }),
    [eventUrl, cartId, approvalRequiredForOrder, eventsResp?.event.isRSVPEvent],
  )

  const {confirmPayment} = useStripeConfirmPayment({
    payment: 'existing',
    redirectUrl,
  })

  const handleCheckout = async (params: HandleCheckoutParams) => {
    trackMixpanelEvent('Checkout Clicked-  Checkout Page', {source: 'free or existing card'})
    setIsHandlingCheckout(true)
    setFormErrors({})
    setCustomFieldErrors({})
    updateStripeError(undefined)
    !!cartInfo &&
      !!eventsResp &&
      trackPurchaseAttempt({cart: {cartInfo, cartTicketCount, cartId}, event: eventsResp.event, user: currentUser})

    if (!validateAllRequiredCheckoutFields()) {
      setIsHandlingCheckout(false)
      return
    }

    switch (params.paymentType) {
      case 'existing_card':
        const paymentMethodId = params.paymentMethod.id
        // try-catch here for possible confirm payment error throw
        try {
          const {error} = await confirmPayment({
            cartId,
            paymentMethodId,
            onSuccess: () => {
              if (cartInfo) {
                trackPaidOrderCreated(cartInfo.total, {
                  eventId: eventsResp?.event.id,
                  userId: currentUser?._id,
                  cartId,
                })
              }
              trackMixpanelEvent('Checkout Success-  Checkout Page')
              navigate(redirectUrl)
            },
          })
          if (error) {
            // throw here to centralize error handling in catch block
            if (eventsResp?.event.fbPixelId)
              trackPurchaseFailed({
                pixelId: eventsResp.event.fbPixelId,
                eventName: eventsResp.event.name,
                currency: eventsResp.group.currency,
                subtotal: cartInfo?.subtotal,
                ticketCount: cartTicketCount,
              })
            throw error
          }
        } catch (confirmPaymentError) {
          const displayError =
            confirmPaymentError.message ?? 'Something went wrong while processing your purchase, please try again.'
          trackMixpanelEvent('Checkout Failed Error-  Checkout Page', {
            message: displayError,
          })
          updateStripeError(displayError)
        } finally {
          setIsHandlingCheckout(false)
        }
        break

      case 'free_order':
        try {
          await createFreeOrder({cartId})
          const isRSVPEvent = eventsResp?.event.isRSVPEvent
          trackMixpanelEvent('Checkout Success-  Checkout Page')
          navigate(
            handleNavigation({
              eventUrl: eventUrl!,
              cartId,
              approvalRequired: approvalRequiredForOrder,
              isRSVPEvent,
            }),
          )
        } catch (error) {
          trackMixpanelEvent('Checkout Failed Error-  Checkout Page', {message: error.response.data.error})
          updateStripeError(error.response.data.error)
        }
        setIsHandlingCheckout(false)
        break

      default:
        trackMixpanelEvent('Checkout Failed Error-  Checkout Page', {
          message: 'Default checkout case hit, tell Michael if this shows up on mixpanel',
        })
        return
    }
  }

  const checkoutButtonText = () => {
    const {isRSVPEvent, customCheckoutSubmitText} = eventsResp?.event ?? {}
    return getCheckoutButtonText({
      isProcessing: isHandlingCheckout,
      isRSVPEvent,
      isFreeOrder: isAFreeOrder,
      isApprovalRequired: approvalRequiredForOrder,
      customCheckoutSubmitText,
    })
  }

  const isAFreeOrder = cartInfo?.total === 0
  return (
    <>
      {isHandlingCheckout && <div className='CheckoutPageLoadingOverlay' />}
      <div className='CheckoutPage-Section fade'>
        {isAFreeOrder && (
          <div>
            <CheckoutButton
              disabled={isHandlingCheckout || !hasAgreedToCuratorTos || disabled}
              className={'Gold'}
              onClick={() => {
                handleCheckout({paymentType: 'free_order'})
              }}>
              {checkoutButtonText()}
            </CheckoutButton>
          </div>
        )}
        {!isAFreeOrder && selectedPaymentMethod && (
          <>
            {approvalRequiredForOrder && (
              <p className='CheckoutPage-disclaimer'>
                Since some of the items in your cart need to be approved, your money will be put on hold until the
                organizer verifies you may attend the event.
              </p>
            )}
            <CheckoutButton
              className={'Gold'}
              disabled={isHandlingCheckout || !hasAgreedToCuratorTos || disabled}
              onClick={() => {
                handleCheckout({paymentType: 'existing_card', paymentMethod: selectedPaymentMethod})
              }}>
              {checkoutButtonText()}
            </CheckoutButton>
          </>
        )}
        <CartError error={stripeError} />
      </div>
    </>
  )
}

export default CheckoutButtons
