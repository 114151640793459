import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import useFetchEvent from 'apis/Events/useFetchEvent'
import useFetchAffiliates from 'apis/Kickback/useFetchAffiliates'
import useFetchKickBackOffers from 'apis/Kickback/useFetchKickBackOffers'
import {useGenerateAffiliation} from 'apis/Kickback/useGenerateAffiliation'
import {useMixpanel} from 'apis/MixPanelHandler'
import AccountSearchModal, {AccountParams} from 'components/modals/Modal/AccountSearchModal'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import {useEventMatchParams} from 'domains/Events/helpers'
import {isEmpty} from 'lodash'
import {Cog} from 'lucide-react'

import {AddKickBackOffer} from './AddKickBackOffer'
import {KickBackOffersTable} from './KickBackOffersTable'
import {KickbackOfferType} from './types'

import './styles.scss'

const Kickback = () => {
  const {eventId} = useEventMatchParams()
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {data: event} = useFetchEvent(eventId ?? ' ')
  const tickets = event?.event.tickets
  const [orderedTickets, setOrderedTickets] = useState(tickets ?? [])
  const [isAddingKickBack, setIsAddingKickBack] = useState<KickbackOfferType | undefined>(undefined)
  const {data: kickBackOffers, isFetching: isFetchingKickBacks} = useFetchKickBackOffers({
    entityType: 'event',
    entity: eventId!,
  })

  useEffect(() => {
    setOrderedTickets(tickets ?? [])
  }, [tickets])

  const {refetch: refetchAffiliates, data: affiliates} = useFetchAffiliates({
    entityType: 'event',
    entity: eventId ?? '',
  })
  const [isShowingAddAffiliate, setIsShowingAddAffiliate] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [userBeingAdded, setUserBeingAdded] = useState('')
  const {mutateAsync: generateAffiliation} = useGenerateAffiliation()

  const handleAddAffiliate = async (accountId: string) => {
    try {
      setUserBeingAdded(accountId)
      setSuccessMessage('')
      setErrorMessage('')
      await generateAffiliation({eventId: eventId!, userId: accountId})
      setSuccessMessage('Affiliate added successfully')
      setUserBeingAdded('')
      await refetchAffiliates()
    } catch (error) {
      setUserBeingAdded('')
      setErrorMessage(error.response.data.error)
    }
  }

  const onAddUser = async (account: AccountParams) => {
    trackMixpanelEvent('Kickback Add Affiliate Posh User Update Button-  Curator Event Kickback Page Add Modal', {
      addedAccountId: account.accountId,
    })
    handleAddAffiliate(account.accountId)
  }

  const neverCreatedKickbackOffer = !isFetchingKickBacks && (!kickBackOffers || kickBackOffers.length === 0)

  return (
    <>
      <PoshHelmet title={`KICKBACK - ${event?.event.name}`} />
      <div className='KickbackManagmentPage'>
        <div className='KickbackManagmentPage-Logo'>
          <Cog
            height={32}
            width={32}
            cursor='pointer'
            style={{position: 'absolute', right: '20px', top: '10px'}}
            onClick={() => {
              trackMixpanelEvent('Kickback Settings-  Curator Event Kickback Page Home')
              navigate('settings')
            }}
          />
        </div>
        {neverCreatedKickbackOffer && isAddingKickBack === undefined && (
          <div className='AddKickBack-section'>
            <h3>What Are Kickbacks?</h3>
            <p className='m-0'>
              Kickbacks allow attendees and affiliates to receive a commission on tickets they sell. After purchasing
              their own ticket, an attendee will be prompted to create an affiliate link.
            </p>
          </div>
        )}
        {isAddingKickBack !== undefined && !isEmpty(orderedTickets) ? (
          <AddKickBackOffer
            existingAffiliates={affiliates ?? []}
            tickets={orderedTickets}
            eventId={eventId!}
            setIsAddingKickBack={setIsAddingKickBack}
            offerType={isAddingKickBack}
            setIsShowingAddAffiliate={setIsShowingAddAffiliate}
          />
        ) : (
          !isEmpty(orderedTickets) && (
            <KickBackOffersTable
              tickets={orderedTickets}
              setIsAddingKickBack={setIsAddingKickBack}
              setErrorMessage={setErrorMessage}
              setSuccessMessage={setSuccessMessage}
              setIsShowingAddAffiliate={setIsShowingAddAffiliate}
              isFetchingKickbacks={isFetchingKickBacks}
            />
          )
        )}
        <AccountSearchModal
          isOpen={isShowingAddAffiliate}
          onClose={() => setIsShowingAddAffiliate(false)}
          onAddPoshUser={onAddUser}
          onAddNonPoshUser={onAddUser}
          successMessage={successMessage}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          userBeingAdded={userBeingAdded}
          invitedFrom='affiliation'
          primaryTitle='Add Affiliate'
          primaryButtonText='+ Invite a Non-Posh User'
          secondaryTitle='Invite Affiliate'
          secondaryButtonText='Invite Affiliate'
          noborder={true}
        />
      </div>
    </>
  )
}

export default Kickback
