import React from 'react'

import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import BlastsTable from 'components/data/BlastsTable'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import GroupPageSubNav from 'pages/GroupPage/SubNav'

import './styles.scss'

const SmsBlastsPage = () => {
  const {groupId} = useGroupMatchParams()
  const {data: group} = useFetchGroup(groupId)

  return (
    <>
      <div className='GroupPage'>
        <div className='GroupPage-content'>
          <GroupPageSubNav group={group} hierarchy={['Marketing']} />

          <div className='GroupPage-titleBar'>
            <h3>SMS Campaigns</h3>
          </div>

          <BlastsTable limit={50} fullHistory={true} />
        </div>
      </div>
    </>
  )
}

export default SmsBlastsPage
