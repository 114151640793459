import React from 'react'

import getEventFlyer from 'apis/Util/getEventFlyer'
import {PoshImageBackground} from 'components/PoshImage/PoshImage'

import {EventsTableListItemActions as Actions} from './Actions'
import {EventsTableListItemContent as Content} from './Content'
import {EventsTableListItemCopyBlock as CopyBlock} from './CopyBlock'
import {EventsTableListItemImage as Image} from './Image'
import {EventsTableListItemProps} from './types'

import '../index.scss'

export function EventsTableItem(props: EventsTableListItemProps) {
  const {item} = props

  return (
    <PoshImageBackground className='EventTable-row' style={{backgroundImage: `url('${getEventFlyer(item)}')`}}>
      <div className='EventTable-row-content'>
        <Image {...props} />
        <Content {...props} />
        <CopyBlock {...props} isHidden={!props.isHost} />
        <Actions {...props} />
      </div>
    </PoshImageBackground>
  )
}
