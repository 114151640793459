import {cn} from '@posh/design-kit/lib/utils'

import './styles.scss'

interface Tab {
  id: string
  label: string
  count?: number
}

interface TabsFilterProps {
  tabs: Tab[]
  activeTab: string
  onTabChange: (id: string) => void
  className?: string
}

const TabsFilter = ({tabs, activeTab, onTabChange, className}: TabsFilterProps) => {
  return (
    <div className={cn('tabs-filter', className)}>
      {tabs.map(tab => (
        <button
          key={tab.id}
          onClick={() => onTabChange(tab.id)}
          className={cn('button', activeTab === tab.id ? 'button--primary' : 'button--secondary')}>
          {tab.label}
          {tab.count !== undefined && (
            <span
              className={cn(
                'tabs-filter__tab__count',
                activeTab === tab.id ? 'tabs-filter__tab__count--active' : 'tabs-filter__tab__count--inactive',
              )}>
              {tab.count}
            </span>
          )}
        </button>
      ))}
    </div>
  )
}

export default TabsFilter
