import React from 'react'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import {FeatureFlag} from '@posh/model-types'
import {useFeatureGate} from 'hooks/useFeatureGate'
import GroupPageSubNav from 'pages/GroupPage/SubNav'
import AlertSettings from 'pages/OwnerPage/GroupsSelector/AccountSettings/AlertSettings'
import ChangePassword from 'pages/OwnerPage/GroupsSelector/AccountSettings/ChangePassword'
import GeneralSettings from 'pages/OwnerPage/GroupsSelector/AccountSettings/GeneralSettings'
import PrivacySettings from 'pages/OwnerPage/GroupsSelector/AccountSettings/PrivacySettings'

import useSessionContext from '../../../domains/Auth/SessionContext'

import './../styles.scss'

const AccountSettings = () => {
  const isSmsOptEnabled = useFeatureGate(FeatureFlag.SMS_OPT_INS)

  const {currentUser} = useSessionContext()

  if (!currentUser) return <></>

  return (
    <div className='GroupPage'>
      <div className='GroupPage-content'>
        <div className='w-full'>
          <GroupPageSubNav hierarchy={['My Account', 'Settings']} />
          <h3>Account Settings</h3>
          <GeneralSettings />
          {isSmsOptEnabled && (
            <>
              <hr />
              <h4 className='mt-8'>Alert Preferences</h4>
              <AlertSettings />
            </>
          )}
          <hr />
          <h4 className='mt-8'>Privacy</h4>
          <PrivacySettings />
          <hr />
          <h4 className='mt-8'>Security</h4>
          <ChangePassword />
        </div>
      </div>
    </div>
  )
}

export default AccountSettings
