import React from 'react'

import {GetPendingEventOutput as PendingEvent} from 'apis/PendingEvent/useGetPendingEvent'
import {Moment} from 'moment'

import styles from './MultiSessionEventList.module.scss'

interface MultiSessionEventListProps {
  eventDates: Moment[]
  template: Pick<PendingEvent, 'name' | 'flyer' | 'startUtc' | 'timezone'>
}

export const MultiSessionEventList = ({eventDates, template}: MultiSessionEventListProps) => {
  return (
    <div className={styles.MultiSessionEventListContainer}>
      {eventDates.map((date, i) => (
        <div key={i} className={styles.MultiSessionEventListItem}>
          <div style={{display: 'flex', gap: 10, flexGrow: 1, alignItems: 'center'}}>
            <img
              src={template.flyer}
              alt={`Flyer for ${template.name}`}
              style={{height: 40, width: 40, objectFit: 'cover', borderRadius: 5}}
            />
            <div className='flex flex-col'>
              <p className='noMargin text-xs font-medium hyphens-auto'>{template.name}</p>
              <p className='noMargin text-xs opacity-40'>{date.format('M/D/YYYY')}</p>
            </div>
          </div>
          <p className='noMargin text-xs opacity-40'>{date.format('h:mmA (z)')}</p>
        </div>
      ))}
    </div>
  )
}
