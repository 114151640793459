import {RudderAnalytics} from '@rudderstack/analytics-js'

const RUDDER_WRITE_KEY = '2tYSEylAZAtEX2E2qbrKap3Vaqf'
const RUDDER_DATA_PLANE_URL = 'https://poshmikemsjava.dataplane.rudderstack.com'

let rudderstackAnalyticsInstance: RudderAnalytics | null = null

export function initializeRudderstackAnalytics(): Promise<RudderAnalytics> {
  if (rudderstackAnalyticsInstance) {
    return Promise.resolve(rudderstackAnalyticsInstance)
  }

  rudderstackAnalyticsInstance = new RudderAnalytics()

  return new Promise(resolve => {
    rudderstackAnalyticsInstance!.load(RUDDER_WRITE_KEY, RUDDER_DATA_PLANE_URL, {})

    rudderstackAnalyticsInstance!.ready(() => {
      resolve(rudderstackAnalyticsInstance!)
    })
  })
}

export function getRudderstackAnalytics(): RudderAnalytics {
  if (rudderstackAnalyticsInstance === null) {
    throw new Error('Analytics has not been initialized. Call initializeAnalytics() first.')
  }
  return rudderstackAnalyticsInstance!
}
