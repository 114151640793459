import React from 'react'

import {groupEventsByMultiSessionEvent} from '@posh/model-types'
import {EventPublic} from 'apis/Events/types'
import {EventCard, EventSeriesCard} from 'pages/EventCard'

import './styles.scss'

interface PastEventsProps {
  type: string
  pastEvents: EventPublic[]
  shouldGroupEventSeries?: boolean
}

const PastEvents = ({type, pastEvents, shouldGroupEventSeries}: PastEventsProps) => {
  const header = () => {
    switch (type) {
      case 'organization':
        return 'Past Events'
      default:
        return 'Attended'
    }
  }

  const groupedEvents = shouldGroupEventSeries
    ? groupEventsByMultiSessionEvent<EventPublic, NonNullable<EventPublic['multiSessionEvent']>>(pastEvents)
    : pastEvents.map(event => ({
        ...event,
        isMultiSessionGroup: false as const,
      }))

  return (
    <>
      <hr />
      <p className='PProfile-subsectionTitle'>{header()}</p>
      <div className='PProfile-upcomingEventsWrapper'>
        {groupedEvents.map((eventGroup, i) => {
          if (eventGroup.isMultiSessionGroup)
            return <EventSeriesCard eventGroup={eventGroup} source='profile' key={i} />
          const event = eventGroup
          return (
            <EventCard
              source='profile'
              backgroundImage={event?.flyer}
              url={event?.url}
              timezone={event?.timezone}
              startUtc={event?.startUtc}
              name={event?.name}
              venueName={type === 'venue' ? event.groupName : event?.venue?.name}
              key={i}
            />
          )
        })}
      </div>
    </>
  )
}
export default PastEvents
