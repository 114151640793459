import React from 'react'

import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useNavigateToCreateEvent} from 'pages/CreateEvent/useNavigateToCreateEvent'

import {HomePageComponentProps} from '../interface'

import './styles.scss'

const EndCTASection = (props: HomePageComponentProps) => {
  const {changePage} = props
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {createEventUrl} = useNavigateToCreateEvent()

  const users = [
    'https://images.posh.vip/b1/attendee-avi/612d8ece6cc1c400330e6173.jpg',
    'https://images.posh.vip/b1/attendee-avi/60ae73c91fc9310015a1341d.jpg',
    'https://images.posh.vip/b1/attendee-avi/60bdacbfea07940015a67c0f.jpg',
    'https://images.posh.vip/b1/attendee-avi/623a1f49975c420033a0669e.jpg',
    'https://images.posh.vip/b1/attendee-avi/60a606bd296b78001589f273.jpg',
  ]

  return (
    <>
      <div className='LandingPage-section LandingPage-section-endCTA'>
        <div className='LandingPage-section-inner endCtaSection center'>
          <div className='endCtaSection-pyramid'>
            {users.map((user, index) => (
              <PoshImage key={index} src={user} />
            ))}
          </div>
          <h2>Join 1 million+ Event-lovers</h2>
          <p className='text-large'>It’s time to take your live experiences to the next level...</p>
          <div className='buttonWrapper'>
            <Button
              className='LandingPage-btn white outlineBtn'
              onClick={() => {
                trackMixpanelEvent('Explore Events Bottom-  Home Page')
                changePage('/explore')
              }}>
              Explore Events
            </Button>
            <Button
              className='LandingPage-btn white'
              onClick={() => {
                trackMixpanelEvent('Launch an Event Bottom-  Home Page')
                changePage(createEventUrl)
              }}>
              Launch An Event
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default EndCTASection
