import React from 'react'

import useFetchEvent from 'apis/Events/useFetchEvent'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {GetPendingApprovalsOutput} from 'apis/Orders/useGetPendingApprovals'
import {AccountDomain} from 'apis/Roles/AccountDomain'
import {useDimensions} from 'hooks/useDimensions'
import {AttendeeCheckBox} from 'pages/GroupPage/Marketing/AttendeeCheckBox'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import useApprovalCrmContext from '../ApprovalContextProvider/ApprovalCrmContext'
import ApprovalRow from './ApprovalRow'

import './styles.scss'

interface ApprovalCrm {
  newApprovalsResponse: GetPendingApprovalsOutput
}

const ApprovalCRM = (props: ApprovalCrm) => {
  const {newApprovalsResponse} = props
  const {groupId, eventId, domain} = useResourcePageParams()
  const {selectedApprovalIds, setSelectedApprovalIds} = useApprovalCrmContext()
  const {isMobile} = useDimensions()
  const isHost = domain == AccountDomain.HOST
  const {data: group} = useFetchGroup(groupId)
  const {data: event} = useFetchEvent(eventId ?? '')
  const groupCurrency = group?.currency
  const approvalRows = newApprovalsResponse.pendingOrders
  const isRSVPEvent = eventId != '' && event && event.event.isRSVPEvent
  const shouldShowTicket = () => {
    if (!isMobile && !isRSVPEvent) return true
    return false
  }

  const selectAllApprovals = () => {
    setSelectedApprovalIds(approvalRows.map(a => a._id))
  }

  return (
    <>
      <table className='AttendeesTable AttendeesTable-firstColSmall'>
        <tbody>
          <tr style={{background: 'none'}}>
            <th>
              {!isHost && (
                <AttendeeCheckBox
                  defaultChecked={
                    selectedApprovalIds && approvalRows && selectedApprovalIds.length === approvalRows.length
                  }
                  onChecked={checked => {
                    if (checked) {
                      selectAllApprovals()
                    }
                    if (!checked) {
                      setSelectedApprovalIds([])
                    }
                  }}
                />
              )}
            </th>
            <th colSpan={2} style={{paddingLeft: `${isMobile ? '0px' : '70px'}`}}>
              Name
            </th>
            {!isMobile && <th colSpan={2}>Email</th>}
            {shouldShowTicket() && <th>Tickets</th>}
            {!isMobile && !isHost && <th>Contact</th>}
            {!isMobile && <th>Tracking Link</th>}
            {!isMobile && <th>Request Date</th>}
            <th></th>
          </tr>
          {approvalRows.map(rowAttr => (
            <ApprovalRow
              key={rowAttr._id}
              rowAttrs={rowAttr}
              isHost={isHost}
              currency={groupCurrency}
              shouldShowTicket={shouldShowTicket}
            />
          ))}
        </tbody>
      </table>
    </>
  )
}
export default ApprovalCRM
