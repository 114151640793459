import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetEventAnalyticsOptions = ReactQueryOptions['events']['getEventAnalytics']
type GetEventAnalyticsInput = RouterInputs['events']['getEventAnalytics']
export type GetEventAnalyticsOutput = RouterOutput['events']['getEventAnalytics']

export type TimeseriesEventAnalytics =
  | GetEventAnalyticsOutput['lastMonth']
  | GetEventAnalyticsOutput['lastWeek']
  | GetEventAnalyticsOutput['lastYear']

export type TimeseriesDataField = keyof Omit<TimeseriesEventAnalytics[number], 'date'>

export function useGetEventAnalytics(input: GetEventAnalyticsInput, opts?: GetEventAnalyticsOptions) {
  return trpc.events.getEventAnalytics.useQuery(input, opts)
}
