import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type UpdateMultiSessionEventOptions = ReactQueryOptions['multiSessionEvents']['updateMultiSessionEvent']
export type UpdateMultiSessionEventInput = RouterInputs['multiSessionEvents']['updateMultiSessionEvent']
export type UpdateMultiSessionEventOutput = RouterOutput['multiSessionEvents']['updateMultiSessionEvent']

export type MultiSessionEventSubEventMutableFields = keyof NonNullable<UpdateMultiSessionEventInput['eventTemplate']>

export const useUpdateMultiSessionEvent = (opts?: UpdateMultiSessionEventOptions) => {
  return trpc.multiSessionEvents.updateMultiSessionEvent.useMutation(opts)
}

export const useUpdateMultiSessionEventSubEvents = (opts?: UpdateMultiSessionEventOptions) => {
  const qc = trpc.useContext()
  const {mutate, ...rest} = useUpdateMultiSessionEvent({
    ...opts,
    onSuccess: (data, variables, context) => {
      qc.multiSessionEvents.getMultiSessionEvent.invalidate({id: variables.id, groupId: variables.groupId})
      opts?.onSuccess?.(data, variables, context)
    },
  })

  const updateEventTemplate = (data: Pick<UpdateMultiSessionEventInput, 'eventTemplate' | 'id' | 'groupId'>) => {
    mutate({...data})
  }

  return {mutate: updateEventTemplate, ...rest}
}
