import React, {useEffect, useState} from 'react'
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'

import Intercom from '@intercom/messenger-js-sdk'
import {useIntercomIdentityVerification} from 'apis/Intercom/useIntercomIdentityVerification'
import {DB_BACKED_FEATURES, FeatureFlag} from 'components/FeatureFlag'
import {MultisessionEventFeatureFlag} from 'components/FeatureFlag/MultisessionEventFeatureFlag'
import {PrimaryNavigationLayout as WithSidebarNav} from 'components/Navigation/Primary/layout'
import AdminProtectedRoute from 'components/routes/AdminProtectedRoute'
import {NewAuthorizedEventRoute, NewAuthorizedGroupRoute} from 'components/routes/AuthorizedRoute'
import useSessionContext from 'domains/Auth/SessionContext'
import {useScrollToTopOnNavigate} from 'hooks/navigation/useScrollToTopOnNavigate'
import {useTrackUserSession} from 'hooks/useTrackUserSession'

import {MobileAppExclusiveFeatureOverlay} from '../components/MobileAppExclusiveFeatureOverlay'
import ProtectedRoute from '../components/routes/ProtectedRoute'
import {PoshRoutes} from '../models/PoshRoutes'
import Account from './Account'
import AdminRoutes from './AdminRoutes'
import CampaignLinkPage from './CampaignLinkPage'
import CommunityDashboard from './CommunityDashboard'
import {ConfirmAccount} from './ConfirmAccount'
import {CreateEventRouter} from './CreateEvent/router'
import {EventRoutes} from './EventManagementPages/EventRoutes'
import EventMarketplaceWebWrapper from './EventMarketplace/WebWrapper'
import EventPage from './EventPage'
import EventTOS from './EventPage/EventTOS'
import {EventPlaylistPage} from './EventPlaylistPage/EventPlaylistPage'
import FAQPage from './FAQPage'
import CommunityProfile from './GroupPage/Community/Profile'
import {CommunityRoutes} from './GroupPage/Community/Routes'
import GroupPageLayout from './GroupPage/Layout'
import {MultiSessionEventProvider} from './GroupPage/ManageMultiSessionEvent/context/ManageMultiSessionEventPagesContext'
import {MultiSessionEventPages} from './GroupPage/ManageMultiSessionEvent/MultiSessionEventPages'
import HomePage from './HomePage'
import Footer from './HomePage/Footer'
import GroupProfile from './HomePage/GroupProfile'
import KickbackDashboard from './KickbackDashboard'
import AffiliationOverview from './KickbackDashboard/AffiliationOverview'
import LoginPage from './LoginPage'
import {MagicLinkPage} from './Magic'
import {MailChimpOAuthHandler} from './MailChimpOAuthHandler'
import {ManageEventPlaylistPages} from './ManageEventPlaylists/ManageEventPlaylistPages'
import MobileAppLanding from './MobileAppLanding'
import {MultiSessionEventsPublicLongUrl} from './MultiSessionEventsPublic/longUrl'
import {MultiSessionEventsPublicShortUrl} from './MultiSessionEventsPublic/shortUrl'
import CheckoutPageWrapper from './NewCheckoutPage/CheckoutPageWrapper'
import OrderQrCodePage from './OrderQrCodePage'
import AccountOrders from './Orders'
import CreateGroupPage from './OwnerPage/CreateGroup'
import GroupsSelector from './OwnerPage/GroupsSelector'
import PoshHeader from './OwnerPage/PoshHeader'
import SignUp from './OwnerPage/SignUp'
import PageNotFound from './PageNotFound/PageNotFound'
import PoshLoaderTransition from './PoshLoaderTransition'
import PoshMobileNav from './PoshMobileNav'
import PrivacyPolicy from './PrivacyPolicy'
import UserProfile from './Profile/UserProfile'
import VenueProfile from './Profile/VenueProfile'
import RateLimitExceeded from './RateLimitExceeded'
import ReceiptPage, {DisabledReceiptPage} from './ReceiptPage'
import RedirectPage from './RedirectPage'
import ResetPasswordPage from './ResetPasswordPage'
import {SecretArea51} from './SecretArea51'
import ShortEventUrlPage from './ShortEventUrlPage'
import TermsOfService from './TermsOfService'
import VerifyEmail from './VerifyEmail/VerifyEmail'
/**
 * Routes for the "dashboard" route.
 * Sorry, there was no better place to put this, but it will be refactored/made unnecessary when we migrate to Next.js
 */
function DashboardRoutes() {
  return (
    <>
      <PoshHeader desktopOnly />
      <Routes>
        <Route path='/orgs' element={<GroupsSelector />} />
        <Route path='/orders' element={<AccountOrders />} />
        <Route path='/kickback' element={<KickbackDashboard />} />
        <Route path='/community' element={<CommunityDashboard />} />
        <Route path='/account/*' element={<Account />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
      <Footer desktopOnly />
      <PoshMobileNav />
    </>
  )
}

function TicketTransferRoutes() {
  return (
    <>
      <PoshHeader hideMenuItems isHalfTranslucent />
      <Routes>
        {/* Need to reuse AccountOrders on the '/orders route for the app deep-link since our current web account orders route is linked to /dashboard */}
        <Route path='/' element={<AccountOrders />} />
        <Route
          path='/transfer-tickets/:orderId'
          element={
            <MobileAppExclusiveFeatureOverlay
              image={'https://images.posh.vip/ticket-transfer-assets/ticket-transfer-mockup.png'}
              title={'Install the app to send tickets to your friends'}
            />
          }
        />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </>
  )
}

const Layout = () => {
  const navigate = useNavigate()
  const [pageIsChanging, setPageIsChanging] = useState(false)
  const [fadeOutTransition, setFadeOutTransition] = useState(false)
  useTrackUserSession()
  useScrollToTopOnNavigate()
  const {currentUser} = useSessionContext()
  const {data} = useIntercomIdentityVerification()

  useEffect(() => {
    if (currentUser && data?.hash) {
      Intercom({
        app_id: 'yjzpxwga',
        user_id: currentUser._id,
        name: currentUser.firstName + ' ' + currentUser.lastName,
        email: currentUser.email,
        user_hash: data?.hash,
        created_at: currentUser._id ? Math.floor(parseInt(currentUser._id.substring(0, 8), 16) * 1000) : undefined, // Convert ObjectId timestamp to Unix timestamp
      })
    }
  }, [currentUser, data])

  const changePage = (page: string) => {
    setPageIsChanging(true)
    setTimeout(() => {
      navigate(page)
    }, 1000)
    setTimeout(() => {
      setFadeOutTransition(true)
    }, 1300)
    setTimeout(() => {
      setPageIsChanging(false)
      setFadeOutTransition(false)
    }, 2000)
  }

  return (
    // TODO: remove dark class once we integrate next-theme from web-reskin
    <main className='dark'>
      <Routes>
        {/* Routes WITHOUT the sidebar navigation */}
        <Route path={PoshRoutes.ORDER_QR_CODE} element={<OrderQrCodePage />} />
        <Route path={PoshRoutes.PASSWORD} element={<ResetPasswordPage />} />
        <Route path={PoshRoutes.LOGIN} element={<LoginPage />} />
        <Route path={PoshRoutes.SIGN_UP} element={<SignUp />} />
        <Route path={PoshRoutes.HOME} element={<HomePage changePage={changePage} />} />
        <Route path={PoshRoutes.CREATE} element={<CreateEventRouter />} />
        {/* Routes WITH the sidebar navigation */}
        <Route element={<WithSidebarNav />}>
          <Route path={PoshRoutes.EXPLORE} element={<EventMarketplaceWebWrapper />} />
          <Route path={PoshRoutes.PROFILE} element={<UserProfile />} />
          <Route path={PoshRoutes.CHECKOUT} element={<CheckoutPageWrapper />} />
          <Route path={PoshRoutes.EVENT} element={<EventPage />} />
          <Route path={PoshRoutes.GROUP} element={<GroupProfile />} />
          <Route path={PoshRoutes.VENUE} element={<VenueProfile />} />
          <Route path={PoshRoutes.SHORT_EVENT_URL} element={<ShortEventUrlPage />} />
          <Route path={PoshRoutes.CAMPAIGN_LINK} element={<CampaignLinkPage />} />
          <Route path={PoshRoutes.EVENTTOS} element={<EventTOS />} />
          <Route path={PoshRoutes.FAQ} element={<FAQPage changePage={changePage} />} />
          <Route path={PoshRoutes.TOS} element={<TermsOfService />} />
          <Route path={PoshRoutes.MOBILE} element={<MobileAppLanding />} />
          <Route path={PoshRoutes.MAGIC} element={<MagicLinkPage />} />
          <Route path={PoshRoutes.PRIVACY} element={<PrivacyPolicy />} />
          <Route path={PoshRoutes.VERIFY_EMAIL} element={<VerifyEmail />} />
          <Route path={PoshRoutes.RATE_LIMIT_EXCEEDED} element={<RateLimitExceeded />} />
          <Route path={PoshRoutes.COMMUNITY_ROUTES} element={<CommunityRoutes />} />
          <Route path={PoshRoutes.COMMUNITY_PROFILE} element={<CommunityProfile />} />
          <Route path={PoshRoutes.CONFIRM_ACCOUNT} element={<ConfirmAccount />} />
          <Route path={PoshRoutes.REDIRECT} element={<RedirectPage />} />
          <Route path={PoshRoutes.MAILCHIMP_OAUTH} element={<MailChimpOAuthHandler />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<NewAuthorizedEventRoute />}>
              <Route path={PoshRoutes.EVENT_ROUTES} element={<EventRoutes />} />
            </Route>
            <Route element={<NewAuthorizedGroupRoute />}>
              <Route path={PoshRoutes.GROUP_ROUTES} element={<GroupPageLayout />} />
            </Route>
            <Route path={PoshRoutes.DASHBOARD} element={<DashboardRoutes />} />
            <Route path={PoshRoutes.CREATE_GROUP} element={<CreateGroupPage />} />
            <Route path={PoshRoutes.AFFILIATION_OVERVIEW} element={<AffiliationOverview />} />
            <Route path={PoshRoutes.RECEIPT} element={<ReceiptPage compOrder={false} />} />
            <Route path={PoshRoutes.COMP_RECEIPT} element={<ReceiptPage compOrder={true} />} />
            <Route path={PoshRoutes.DISABLED_RECEIPT_PAGE} element={<DisabledReceiptPage />} />
            <Route element={<AdminProtectedRoute />}>
              <Route path={PoshRoutes.ADMIN_ROUTES} element={<AdminRoutes />} />
            </Route>
            <Route
              path={PoshRoutes.MANAGE_EVENT_PLAYLIST_ROUTES}
              element={
                <FeatureFlag.DBBacked featureName={DB_BACKED_FEATURES.MANAGE_EVENT_PLAYLISTS}>
                  <ManageEventPlaylistPages />
                </FeatureFlag.DBBacked>
              }
            />
            <Route
              path={PoshRoutes.MANAGE_MULTI_SESSION_EVENT}
              element={
                <MultisessionEventFeatureFlag.Organizer>
                  <MultiSessionEventPages />
                </MultisessionEventFeatureFlag.Organizer>
              }
            />
          </Route>
          <Route path={PoshRoutes.EVENT_PLAYLIST} element={<EventPlaylistPage />} />
          <Route path={PoshRoutes.TICKET_TRANSFER_ROUTES} element={<TicketTransferRoutes />} />
          <Route
            element={
              <MultisessionEventFeatureFlag.Public>
                <PoshHeader desktopOnly />
                <MultiSessionEventProvider>
                  <div className='h-100vh w-full bg-black'>
                    <Outlet />
                  </div>
                </MultiSessionEventProvider>
              </MultisessionEventFeatureFlag.Public>
            }>
            <Route path={PoshRoutes.MULTI_SESSION_EVENT_PUBLIC} element={<MultiSessionEventsPublicLongUrl />} />
            <Route path={PoshRoutes.MULTI_SESSION_EVENT_PUBLIC_SHORT} element={<MultiSessionEventsPublicShortUrl />} />
          </Route>
          <Route
            path='/in-person-payments-app-overlay'
            element={
              <MobileAppExclusiveFeatureOverlay
                image={'https://images.posh.vip/tap-to-pay-assets/TapToPayMockup.png'}
                title={'Install the app to start accepting payments at the door'}
              />
            }
          />
        </Route>
        <Route path={'/secret-area-51'} element={<SecretArea51 />} />
        {/* 404 route */}
        <Route path='*' element={<PageNotFound />} />
      </Routes>
      <PoshLoaderTransition pageIsChanging={pageIsChanging} fadeOutTransition={fadeOutTransition} />
    </main>
  )
}

export default Layout
