import React, {PropsWithChildren} from 'react'

import './styles.scss'

export enum BadgeVariant {
  OVAL = 'Badge-oval',
  SQUARE = 'Badge-square',
}

interface Props {
  className?: string
  variant?: BadgeVariant
}

export default function Badge(props: PropsWithChildren<Props>) {
  const getVariant = props.variant || 'Badge-oval'

  return <div className={getVariant + ' ' + (props.className || '')}>{props.children}</div>
}

const BadgeNew = () => {
  return (
    <Badge
      className='!text-transform-unset !rounded-full !border !border-[#FDFC4B80] !bg-gradient-to-br !from-[#FDFC4B33] !to-transparent !font-light !text-[#FDFC4B]'
      variant={BadgeVariant.OVAL}>
      {'New'}
    </Badge>
  )
}

Badge.New = BadgeNew
