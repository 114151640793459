import React from 'react'
import Loader from 'react-loader-spinner'

import {KickbackOrders} from 'apis/Kickback/Affiliation'
import useFetchAffiliateData from 'apis/Kickback/useFetchAffiliateData'
import {useGetKickbackEligibility} from 'apis/Kickback/useGetKickbackEligibility'
import {useMixpanel} from 'apis/MixPanelHandler'
import CopyBlock from 'components/CopyBlock'
import {PoshImage} from 'components/PoshImage/PoshImage'
import useSessionContext from 'domains/Auth/SessionContext'
import setOptimizelyUserCustomAttribute from 'helpers/setOptimizelyUserCustomAttribute'
import {isEmpty} from 'lodash'
import WarningBlock from 'pages/GroupPage/PageComponents/WarningBlock'
import PoshHeader from 'pages/OwnerPage/PoshHeader'
import PoshMobileNav from 'pages/PoshMobileNav'

import AnalyticsGrid, {Statistic} from '../../../components/data/AnalyticsGrid'
import {useAffiliatePageParams} from '../../PoshAppLayout'

import './styles.scss'

interface CurrentOfferingsProps {
  offers?: string[]
}

interface RecentOrdersProps {
  orders?: KickbackOrders[]
}

const CurrentOfferings = (props: CurrentOfferingsProps) => {
  const offers = props.offers
  return (
    <div className='AffiliationOverview-Left-CurrentOfferings'>
      <div className='AffiliationOverview-Left-CurrentOfferings-Inner'>
        <h3>Current Kickback Offers</h3>
        {offers && !isEmpty(offers) ? (
          <>
            {offers.map((offer, index) => (
              <div key={index} className='AffiliationOverview-Left-CurrentOfferings-Inner-Offer'>
                {offer}
              </div>
            ))}
          </>
        ) : (
          <p>There are no current kickback offers on this event</p>
        )}
      </div>
    </div>
  )
}

const RecentOrders = (props: RecentOrdersProps) => {
  const orders = props.orders
  return (
    <div className='AffiliationOverview-Right-RecentOrders'>
      {orders && !isEmpty(orders) ? (
        <>
          {orders.map((order, index) => (
            <div key={index} className='AffiliationOverview-Right-RecentOrders-Order'>
              <PoshImage src={order.avi} />
              <div className='AffiliationOverview-Right-RecentOrders-Order-Info'>
                <h5 className='m-0'>You received a {order.amount} Kickback</h5>
                <p className='m-0'>
                  {order.name} purchased {order.ticketDescription} through your link
                </p>
                <div className='AffiliationOverview-Right-RecentOrders-Order-Info-Details'>
                  <span className='m-0'>{order.date}</span>
                  {order.refunded && (
                    <div className='AffiliationOverview-Right-RecentOrders-Order-Info-Refunded'>REFUNDED</div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <p className='AffiliationOverview-Right-RecentOrders-EmptyMessage'>
          You dont have any recent orders for this event
        </p>
      )}
    </div>
  )
}

const AffiliationOverview = () => {
  const {affiliateId} = useAffiliatePageParams()
  const {userId} = useSessionContext()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {data: affiliateData, isFetching} = useFetchAffiliateData(affiliateId ?? '')
  const {data: kickbackEligibility, isFetching: isFetchingKickbackEligibility} = useGetKickbackEligibility(
    {
      accountId: userId!,
    },
    {enabled: !!userId},
  )
  const gridData: Statistic[] = [
    {name: 'CLICKS', value: affiliateData?.clicks},
    {name: 'TOTAL EARNINGS', value: affiliateData?.kickbackedRevenue, isCurrency: true},
    {name: 'TICKETS SOLD', value: affiliateData?.totalTickets},
  ]

  const hasntCompletedStripeOnboard = () => {
    if (!isFetchingKickbackEligibility && kickbackEligibility && !kickbackEligibility.payoutsEnabled) return true
    else return false
  }

  return (
    <>
      <PoshHeader desktopOnly />
      <div className='GroupsDash KickbackDashboard'>
        <div className='GroupsDash-Inner'>
          <div className='KickbackDashboard'>
            <div className='KickbackDashboard-Inner'>
              <div className='KickbackDashboard-navigation'>
                <PoshImage src='https://images.posh.vip/b2/kickback.png' className='KickbackDashboard-Logo' />
              </div>
              <div className='AddKickBack-backBtn'>
                <PoshImage
                  src='https://images.posh.vip/b2/left-arrow-in-circular-button-black-symbol.svg'
                  onClick={() => window.history.back()}
                  className='AddKickBack-backBtn'
                />
              </div>
            </div>
          </div>
          {affiliateData && (
            <div className='AffiliationOverview'>
              <div className='AffiliationOverview-Left'>
                <div className='AffiliationOverview-Left-Flyer'>
                  <a href={affiliateData.eventLink} target='_blank' rel='noreferrer'>
                    <PoshImage src={affiliateData.flyer} />
                    <div className='AffiliationOverview-Left-Flyer-gradient' />
                  </a>
                </div>
                <CurrentOfferings offers={affiliateData.currentKickbackOfferings} />
                {hasntCompletedStripeOnboard() ? (
                  <WarningBlock text={'Connect a debit card to view your link!'} />
                ) : (
                  <>
                    {!isFetchingKickbackEligibility && (
                      <CopyBlock
                        links={affiliateData.eventLink}
                        onCopy={() => {
                          setOptimizelyUserCustomAttribute('Opt.EventPageKickbackClicks', null)
                          trackMixpanelEvent('Link Copy-  Attendee Kickback Offer')
                        }}
                      />
                    )}
                  </>
                )}
              </div>
              <div className='AffiliationOverview-Right'>
                <AnalyticsGrid currencySymbol={'$'} stats={gridData} isNotAbbreviated isAffiliate />
                <RecentOrders orders={affiliateData.kickbackOrders} />
              </div>
            </div>
          )}
          {isFetching && (
            <div className='AffiliationOverview-Loading'>
              <Loader type='TailSpin' color='#04A9D4' height={40} width={40} />
            </div>
          )}
        </div>
      </div>
      <PoshMobileNav />
    </>
  )
}

export default AffiliationOverview
