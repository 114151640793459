import {useGroupTextBlastStatus} from 'hooks/useGroupTextBlastStatus'

import {IClick} from './types'

export const useOnClick = ({
  setIsRecipientsModalOpen,
  trackEvent,
  pendingTextBlast,
  setEventForPendingTextBlast,
  setChosenEvent,
  setIsEventsModalOpen,
  deletePendingTextBlast,
  navigate,
  message,
  showToast,
  confirmPendingTextBlast,
  blastId,
  groupId,
  isFromEvent,
}: IClick) => {
  const {remainingTextBlasts} = useGroupTextBlastStatus({groupId})

  const onClickViewAndEditRecipients = () => {
    trackEvent('Text Blast- View & Edit Recipients', {recipientCount: pendingTextBlast?.numberOfRecipients})
    setIsRecipientsModalOpen(true)
  }

  const onClickEventLinkAction = (mode: 'attach' | 'remove') => {
    if (mode === 'remove') {
      setEventForPendingTextBlast({pendingTextBlastId: blastId ?? '', groupId: groupId ?? '', eventId: undefined})
      return setChosenEvent(undefined)
    } else if (mode === 'attach') {
      return setIsEventsModalOpen(true)
    }
  }

  const onClickCancel = () => {
    trackEvent('Text Blast- Cancel Click', {...pendingTextBlast})
    deletePendingTextBlast({pendingTextBlastId: blastId!, groupId: groupId!})
    navigate(-1)
  }

  const onClickSend = () => {
    if (!message) return showToast({type: 'error', title: 'Please enter a message to send'})

    trackEvent('Text Blast- Send Click', {...pendingTextBlast, remainingTextBlasts, isFromEvent})
    confirmPendingTextBlast({
      pendingTextBlastId: blastId!,
      groupId: groupId!,
      source: isFromEvent ? 'event-dashboard' : 'group-dashboard',
    })
  }

  return {
    onClickViewAndEditRecipients,
    onClickEventLinkAction,
    onClickCancel,
    onClickSend,
  }
}
