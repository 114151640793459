import React from 'react'
import ReactTooltip from 'react-tooltip'

import {Button} from '@posh/design-kit/components/button'
import {DEFAULT_EVENTSERIES_TRACKING_LINK, getEventPageDateDisplay} from '@posh/model-types'
import {useMixpanel} from 'apis/MixPanelHandler'
import {GetMultiSessionEventOutput} from 'apis/MultiSessionEvents/useGetMultiSessionEvent'
import classNames from 'classnames'
import {PoshImage} from 'components/PoshImage/PoshImage'
import moment from 'moment-timezone'
import {getVisualEditorUrl} from 'pages/EventManagementPages/Visuals/Upgrade-dev'
import {useMultiSessionEventContext} from 'pages/GroupPage/ManageMultiSessionEvent/context/ManageMultiSessionEventPagesContext'

import styles from './styles.module.scss'

export function MultiSessionEventsPublicListItem(props: {
  event: GetMultiSessionEventOutput['events'][number]
  groupId?: string
  isEditing: boolean
  domain?: string
  mseId?: string
  trackingLink?: string
}) {
  const {event, isEditing, groupId, domain, mseId, trackingLink} = props
  const {formDirtySafeNavigate} = useMultiSessionEventContext()
  const {trackEvent} = useMixpanel()

  const eventPublicPageUrl = `/e/${event.url}` + `?t=${trackingLink ?? DEFAULT_EVENTSERIES_TRACKING_LINK}`

  const isOver = moment(event.endUtc).isBefore(moment())

  const eventOverviewPageUrl =
    isEditing && domain && mseId
      ? `/${domain}/groups/${groupId}/events/${event._id}/overview?fromMse=${mseId}`
      : undefined

  return (
    <div className={styles.container}>
      <ReactTooltip className='poshToolTip' effect='solid' place='top' id='hoverIcon' />
      <div className={classNames(styles.flyerContainer, 'transition-all duration-300 hover:translate-y-[-5px]')}>
        <a href={eventPublicPageUrl} target='_blank' rel='noreferrer'>
          <PoshImage src={event.flyer} alt={event.name} transformOptions={{height: 400}} />
        </a>
      </div>
      <div className={styles.detailsContainer}>
        <div className='flex flex-row justify-between'>
          <div className='w-full'>
            <a
              href={eventOverviewPageUrl ?? eventPublicPageUrl}
              // don't open a new tab when nav'ing to the event-overview page
              target={!isEditing ? '_blank' : undefined}
              rel={!isEditing ? 'noreferrer' : undefined}
              style={{textDecoration: 'none'}}>
              <h4 className='hover:underline'>{event.name}</h4>
            </a>
            <p>{getEventPageDateDisplay(new Date(event.startUtc), new Date(event.endUtc), event.timezone, true)}</p>
            {event.venue && <p>{event.venue.name}</p>}
          </div>
          {!!event.soldOut && <p className='text-italic'>Sold Out</p>}
        </div>
        <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
          {isEditing && groupId ? (
            <div className={styles.actionsContainer}>
              <Button
                className='rounded-full border border-input font-[Inter] font-light'
                size='sm'
                onClick={() => {
                  formDirtySafeNavigate(
                    eventOverviewPageUrl ?? getVisualEditorUrl(props.domain ?? 'owner', groupId, event._id),
                  )
                  trackEvent('Multi Session Event Management-  Edit Event Clicked')
                }}>
                Edit Event
              </Button>
              <Button
                className='rounded-full border border-input font-[Inter] font-light'
                variant={'secondary'}
                size='sm'
                onClick={() => {
                  formDirtySafeNavigate(eventPublicPageUrl)
                  trackEvent('Multi Session Event Management-  Edit Event Clicked')
                }}>
                View Event Page
              </Button>
            </div>
          ) : (
            <Button
              className='rounded-full border border-input font-[Inter] font-light'
              disabled={isEditing}
              onClick={() => formDirtySafeNavigate(eventPublicPageUrl)}
              variant={event.soldOut || isOver ? 'secondary' : 'default'}>
              {event.soldOut || isOver ? 'View Event Page' : 'Buy Tickets'}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
