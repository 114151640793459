import {StatsigFeatureFlagKey} from '@posh/model-types'
import {FeatureGateEvaluationOptions, useStatsigClient} from '@statsig/react-bindings'

export const useFeatureGate = (featureFlagKey: StatsigFeatureFlagKey, options?: FeatureGateEvaluationOptions) => {
  const {client} = useStatsigClient()
  return client.checkGate(featureFlagKey, options)
}

/** Uses the same client and function as `useFeatureGate` except that this
 * returns the function for us to be able to call it imperatively.
 * */
export const useGetCheckGate = () => {
  const {client} = useStatsigClient()
  return client.checkGate
}
