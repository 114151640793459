import {useEffect, useState} from 'react'

const useHasImageDownloaded = (src?: string) => {
  const [imageHasDownloaded, setImageHasDownloaded] = useState(false)
  const [imageDownloadedWithError, setImageDownloadedWithError] = useState(false)

  useEffect(() => {
    if (!src) return
    const image = new Image()
    image.src = src
    image.onerror = () => setImageDownloadedWithError(true)
    image.onload = () => setImageHasDownloaded(true)
  }, [src])

  return {imageHasDownloaded, imageDownloadedWithError}
}

export default useHasImageDownloaded
