import React from 'react'
import {Control} from 'react-hook-form'

import {EventRecurrenceType} from '@posh/model-types'
import {ErrorBoundary} from '@sentry/react'
import {AlertSolid} from 'components/assets/Icons'
import {BlurBackgroundModal, BlurBackgroundModalProps} from 'components/coreComponents/Modal/variants/BlurBackground'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {EventVisualsStartEndDateTimes} from 'pages/EventManagementPages/Visuals/Upgrade-dev/components/page-sections/EventDetails/inputs/EventVisualsStartEndDateTimes'

import {RecurrenceSettings} from '../RecurrenceSettings/RecurrenceSettings'

import styles from './RecurrenceModal.module.scss'

interface RecurrenceModalProps extends BlurBackgroundModalProps {
  recurrence?: EventRecurrenceType
  onSubmit: (recurrence: EventRecurrenceType) => void
  type: 'create' | 'edit'
  startUtc: string
  endUtc?: string
  timezone: string
  displayEndTime?: boolean
  setDisplayEndTime?: (displayEndTime: boolean) => void
  control?: Control<EventVisualsForm>
}

const ICON_SIZE = 18
const ALERT_ICON_SIZE = 60

export const RecurrenceModal = (props: RecurrenceModalProps) => {
  const {
    recurrence,
    onSubmit,
    startUtc,
    endUtc,
    timezone,
    type,
    displayEndTime,
    setDisplayEndTime,
    control,
    ...modalProps
  } = props

  return (
    <BlurBackgroundModal.Centered radius={10} {...modalProps}>
      <div className={styles.RecurrenceModal}>
        <h3 className='text-large noMargin text-center font-medium! tracking-tight'>Event Series Settings</h3>
        <p className='noMargin text-center text-xs tracking-tight'>
          {type === 'create'
            ? 'Choose the cadence and length of your event series.'
            : 'Add more dates to your event series.'}
        </p>
        <ErrorBoundary
          fallback={
            <div className={styles.RecurrenceModalErrorBoundary}>
              <AlertSolid width={ALERT_ICON_SIZE} height={ALERT_ICON_SIZE} color='white' />
              <div className={styles.RecurrenceModalErrorBoundaryText}>
                An error occurred while loading the recurrence settings.
              </div>
            </div>
          }>
          {control && setDisplayEndTime && (
            <div className='flex flex-col gap-1'>
              <p className='noMargin text-xs'>First Event in Series</p>
              <EventVisualsStartEndDateTimes
                control={control}
                displayEndTime={displayEndTime}
                setDisplayEndTime={setDisplayEndTime}
                formTimezone={timezone}
                startUtc={startUtc}
                endUtc={endUtc}
                accentColor='white'
                lightMode={false}
                textContrasting='white'
                ICON_SIZE={ICON_SIZE}
              />
            </div>
          )}
          <RecurrenceSettings
            type={type}
            recurrenceSettings={recurrence}
            startUtc={startUtc}
            timezone={timezone}
            onSubmit={_newRecurrenceSettings => {
              modalProps.onClose()
              onSubmit(_newRecurrenceSettings)
            }}
            onCancel={() => modalProps.onClose()}
          />
        </ErrorBoundary>
      </div>
    </BlurBackgroundModal.Centered>
  )
}
