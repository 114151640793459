import React, {useCallback, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {MAX_TICKET_QUANTITY} from '@posh/model-types'
import {CurrencyCode} from '@posh/types'
import {useQueryClient} from '@tanstack/react-query'
import {useCreateTicketType} from 'apis/Events/useCreateTicketType'
import {useGetAllEventOrders} from 'apis/Events/useGetAllEventOrders'
import {GetAllEventTicketsOutput} from 'apis/Events/useGetAllEventTickets'
import axios from 'axios'
import {CRUDTable} from 'components/TableUI/CRUDTable/CRUDTable'
import {ColumnConfig} from 'components/TableUI/CRUDTable/internals/types'
import {TableCell} from 'components/TableUI/TableCell/TableCell'
import {useToast} from 'components/toasts/ToastProvider'
import useDragAndReorder from 'hooks/useDragAndReorder'
import moment from 'moment'

import {useResourcePageParams} from '../../../../PoshAppLayout'
import {getCurrencySymbol} from '../../../../Util/getCurrencySymbol'
import {TICKET_SALE_STATUS, ticketSaleStatus} from '../../../../Util/timezoneHelper'
import {TicketLinkModal} from './TicketLinkModal'

interface SortableTicketListProps {
  tickets: GetAllEventTicketsOutput
  setTickets: (tickets: GetAllEventTicketsOutput) => void
  currency?: CurrencyCode
  isFetchingTickets: boolean
  refetchTickets: () => void
  dateUpdatedTickets: number
  hasUpdatedTickets: boolean
  timezone?: string
}

export default function SortableTicketList(props: SortableTicketListProps) {
  const {
    tickets,
    setTickets,
    currency,
    isFetchingTickets,
    dateUpdatedTickets,
    refetchTickets,
    hasUpdatedTickets,
    timezone,
  } = props
  const {eventId} = useResourcePageParams()
  const {data, isFetching: isFetchingAllOrders, refetch: refetchOrders} = useGetAllEventOrders({eventId: eventId!})
  const {orders} = data ?? {}
  const navigate = useNavigate()
  const {showToast} = useToast()
  const queryClient = useQueryClient()
  const [currentlyEditing, setCurrentlyEditing] = useState<string | undefined>()
  const {onDragEnd} = useDragAndReorder(tickets, setTickets)

  const getParentTicket = (ticketId: string) => {
    return tickets.find(t => t.ticketLink?.nextTicket === ticketId)
  }

  const getTicket = (ticketId: string) => {
    return tickets.find(t => t.id === ticketId)
  }

  const showTicketModal = !!currentlyEditing && getTicket(currentlyEditing)
  const onRefresh = () => {
    refetchTickets()
    refetchOrders()
  }

  const onClickAddTicketType = () => {
    navigate('create')
  }

  const onEdit = (ticket: FormattedTicket) => {
    if (isFetchingTickets) return
    navigate(`${ticket.id}`)
  }

  const formattedStatusOptions = Object.values(TICKET_SALE_STATUS).map(status => status)

  const {mutate: addTicketType, isLoading: isCreating} = useCreateTicketType({
    onSuccess: async ({ticketId: createdTicketId}) => {
      await queryClient.refetchQueries(['event', eventId])
      refetchTickets()
      navigate(`${createdTicketId}`)
    },
    onError: error => {
      if (axios.isAxiosError(error)) {
        return showToast({type: 'error', title: error.response?.data.error})
      }
      if (error instanceof Error) {
        return showToast({type: 'error', title: error.message})
      }
    },
  })

  const getTotalTicketsSoldOverAvailable = useCallback(
    (ticketId: string) => {
      const ticket = tickets.find(t => t.id === ticketId)
      const formattedQuantityAvailable =
        ticket?.quantityAvailable === MAX_TICKET_QUANTITY ? 'No Limit' : ticket?.quantityAvailable
      if (isFetchingAllOrders) return `- / ${formattedQuantityAvailable}`
      const totalTicketsSold = orders?.reduce((prev, order) => {
        if (order.fromTransferDocId || order.availableForResell) return prev
        return prev + order.tickets.filter(t => t.typeID === ticketId).length
      }, 0)
      return `${totalTicketsSold ?? 0} / ${formattedQuantityAvailable}`
    },
    [tickets, isFetchingAllOrders],
  )

  const formattedTickets = useMemo(() => {
    return tickets.map(t => {
      return {
        ...t,
        status: ticketSaleStatus(t, timezone ?? 'America/New_York'),
        formattedPrice: !t.price ? 'FREE' : `${getCurrencySymbol(currency)}${t.price.toFixed(2)}`,
      }
    })
  }, [hasUpdatedTickets, tickets, isFetchingAllOrders])

  type FormattedTicket = (typeof formattedTickets)[0]

  const ticketTimeFormatter = (time?: string) => {
    if (!time) return 'No date'
    return moment.tz(time, timezone ?? 'America/New_York').format('MMM D h:mm a')
  }

  const onDuplicate = async (ticket: FormattedTicket) => {
    if (isCreating) return
    if (!eventId) throw new Error('There was an error duplicating the ticket.')
    const {status, formattedPrice, validity, ...ticketToDuplicate} = ticket
    addTicketType({
      eventId,
      ticket: {...ticketToDuplicate, name: 'Duplicate of ' + ticket.name},
    })
  }
  const filteredTickets = useMemo(() => {
    return tickets.reduce((prevList, ticket) => {
      const ticketLink = ticket.ticketLink
      if ((!ticketLink || ticketLink.nextTicket === currentlyEditing) && ticket.id !== currentlyEditing) {
        prevList.push(ticket)
      }
      return prevList
    }, [] as GetAllEventTicketsOutput)
  }, [tickets, currentlyEditing])

  const columnConfigs = useMemo<ColumnConfig<FormattedTicket>[]>(() => {
    return [
      {
        key: 'name',
        header: 'Ticket Name',
        render: name => <TableCell.Text text={name} />,
      },
      {
        key: 'status',
        header: 'Status',
        render: status => (
          <TableCell.MultiOption
            allOptions={formattedStatusOptions}
            selectedOptions={[status]}
            getText={status => status}
          />
        ),
      },
      {
        key: 'formattedPrice',
        header: 'Price',
        render: formattedPrice => <TableCell.Text text={formattedPrice} />,
      },
      {
        key: 'id',
        header: 'Sold',
        render: id => <TableCell.Text text={getTotalTicketsSoldOverAvailable(id)} />,
      },
      {
        key: 'onSaleUtc',
        header: 'Start Sale',
        render: onSaleUtc => <TableCell.Text text={ticketTimeFormatter(onSaleUtc)} />,
      },
      {
        key: 'endSaleUtc',
        header: 'End Sale',
        render: endSaleUtc => <TableCell.Text text={ticketTimeFormatter(endSaleUtc)} />,
      },
    ]
  }, [formattedTickets])

  return (
    <div className='eventSection'>
      <CRUDTable
        data={formattedTickets ?? []}
        columns={columnConfigs}
        itemsPerPage={formattedTickets?.length}
        lastUpdated={dateUpdatedTickets}
        refresh={onRefresh}
        resourceName='Ticket Type'
        searchableColumn='name'
        createButton={{
          title: '+ Add Ticket Type',
          onClick: onClickAddTicketType,
        }}
        isLoading={isFetchingTickets}
        onClickRow={onEdit}
        onReorderEnd={onDragEnd}
        actionButtons={[
          {
            icon: 'copy-squares',
            onClick: onDuplicate,
            toolTipProps: {
              tooltipText: 'Duplicate ticket',
              id: 'duplicate-tooltip',
            },
          },
          {
            icon: 'link',
            onClick: ticket => setCurrentlyEditing(ticket.id),
            toolTipProps: {
              tooltipText: 'Link to another ticket',
              id: 'link-tooltip',
            },
            fill: ticket => (ticket.ticketLink ? '#22CCEEFF' : '#FFFFFF'),
          },
        ]}
      />
      {showTicketModal && (
        <TicketLinkModal
          isOpen={!!currentlyEditing}
          parent={getParentTicket(currentlyEditing)!}
          child={getTicket(currentlyEditing)!}
          filteredTickets={filteredTickets}
          onClose={() => {
            setCurrentlyEditing(undefined)
          }}
          width={780}
        />
      )}
    </div>
  )
}
