import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetMultiSessionTrackingLinksOptions = ReactQueryOptions['multiSessionEvents']['trackingLinks']['get']
export type GetMultiSessionTrackingLinksInput = RouterInputs['multiSessionEvents']['trackingLinks']['get']
export type GetMultiSessionTrackingLinksOutput = RouterOutput['multiSessionEvents']['trackingLinks']['get']

export const useGetMultiSessionTrackingLinks = (
  input: GetMultiSessionTrackingLinksInput,
  opts?: GetMultiSessionTrackingLinksOptions,
) => {
  return trpc.multiSessionEvents.trackingLinks.get.useQuery(input, opts)
}
