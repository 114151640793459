import React, {useEffect, useMemo, useState} from 'react'
import {Control} from 'react-hook-form'

import {calculateNumberOfOccurences, EventRecurrenceType, getFormattedRecurrenceDescription} from '@posh/model-types'
import {useMixpanel} from 'apis/MixPanelHandler'
import classNames from 'classnames'
import {ChevronDown} from 'components/assets/Icons'
import Badge from 'components/Badge'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {RecurrenceModal} from 'components/RecurrenceModal/RecurrenceModal'
import {TableButton} from 'components/TableUI/TableButton/TableButton'
import moment from 'moment'
import {useEventVisualsContext} from 'pages/EventManagementPages/Visuals/Upgrade-dev/components/context/EventVisualsContext'
import {getDefaultStartInitialFocusedTime} from 'pages/EventManagementPages/Visuals/Upgrade-dev/components/page-sections/EventDetails/inputs/EventVisualsStartEndDateTimes'

import {EventVisualsInputContainer} from '../Input/shared/InputContainer'

import styles from './EventVisualsRecurrenceSettings.module.scss'

interface EventVisualsRecurrenceSettingsProps {
  type: 'create' | 'edit'
  recurrence: EventRecurrenceType | undefined
  onChangeRecurrence: (recurrence?: EventRecurrenceType) => void
  startUtc?: string
  onChangeStartUtc?: (startUtc: string) => void
  endUtc?: string
  timezone?: string
  displayEndTime?: boolean
  setDisplayEndTime?: (displayEndTime: boolean) => void
  infoTip?: string
  control?: Control<EventVisualsForm>
}

const CHEVRON_ICON_SIZE = 18
const ACTION_ICON_SIZE = 14

export const EventVisualsRecurrenceSettings = (props: EventVisualsRecurrenceSettingsProps) => {
  const {
    type,
    recurrence,
    onChangeRecurrence,
    startUtc,
    onChangeStartUtc,
    endUtc,
    timezone,
    displayEndTime,
    setDisplayEndTime,
    control,
  } = props

  const [isRecurrenceModalOpen, setIsRecurrenceModalOpen] = useState(false)
  const {trackEvent} = useMixpanel()

  useEffect(() => {
    isRecurrenceModalOpen &&
      trackEvent('Recurrence Settings-  Recurrence Modal Opened', {
        location: type === 'create' ? 'create_event_details_page' : 'edit_series_details_page',
      })
  }, [isRecurrenceModalOpen])

  const numberOfEvents = useMemo(() => {
    if (!recurrence) return 0
    return calculateNumberOfOccurences(recurrence)
  }, [recurrence])

  const {
    palette: {accentColor, lightmode},
  } = useEventVisualsContext()

  const textColor = accentColor

  const repeatsText = !!recurrence ? ((recurrence.every ?? 1) > 1 ? 'Custom' : recurrence.options.type) : 'Never'

  return (
    <>
      {type === 'create' && (
        <EventVisualsInputContainer.Row
          style={{display: 'flex', flexDirection: 'column'}}
          accentColor={accentColor}
          lightMode={lightmode}>
          <div
            onClick={() => {
              if (!startUtc) onChangeStartUtc?.(getDefaultStartInitialFocusedTime(endUtc).toISOString())
              setIsRecurrenceModalOpen(true)
            }}
            className={styles.EventVisualsRecurrenceSettingsRow}
            style={{
              cursor: 'pointer',
            }}>
            <p className={classNames('text-small noMargin flex items-center gap-2')} style={{color: textColor}}>
              Repeats
              {/* TODO: remove this after some weeks from 3/19/25 */}
              {type === 'create' && <Badge.New />}
            </p>

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
              <p className='text-small noMargin' style={{color: textColor}}>
                {repeatsText}
              </p>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <ChevronDown height={CHEVRON_ICON_SIZE} width={CHEVRON_ICON_SIZE} color={textColor} />
              </div>
            </div>
          </div>
        </EventVisualsInputContainer.Row>
      )}
      {!!recurrence && !!startUtc && (
        <EventVisualsInputContainer.Row style={{padding: 0}} accentColor={accentColor} lightMode={lightmode}>
          <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
            <div className={styles.EventVisualsRecurrenceSettingsSummary}>
              <p className='text-small center m-0' style={{color: textColor}}>
                {getFormattedRecurrenceDescription(recurrence)}
              </p>
              {recurrence.ending.hasEndDate && (
                <p className='text-small center m-0' style={{color: textColor}}>
                  ({numberOfEvents} events)
                </p>
              )}
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
                <TableButton
                  iconSize={ACTION_ICON_SIZE}
                  text={type === 'edit' ? 'Extend Series' : 'Edit'}
                  icon={type === 'edit' ? 'plus' : 'pencil'}
                  onClick={() => setIsRecurrenceModalOpen(true)}
                  iconColor={textColor}
                  noShadow
                />
                {type !== 'edit' && (
                  <TableButton
                    iconSize={ACTION_ICON_SIZE}
                    text='Remove'
                    icon='trash'
                    onClick={() => onChangeRecurrence(undefined)}
                    iconColor={textColor}
                    noShadow
                  />
                )}
              </div>
            </div>
          </div>
        </EventVisualsInputContainer.Row>
      )}
      {startUtc && (
        <RecurrenceModal
          type={type}
          recurrence={recurrence}
          isOpen={isRecurrenceModalOpen}
          onClose={() => setIsRecurrenceModalOpen(false)}
          startUtc={startUtc}
          endUtc={endUtc}
          control={control}
          timezone={timezone || moment.tz.guess()}
          onSubmit={newRecurrence => {
            onChangeRecurrence(newRecurrence)
          }}
          displayEndTime={displayEndTime}
          setDisplayEndTime={setDisplayEndTime}
        />
      )}
    </>
  )
}
