import {useEffect, useState} from 'react'

import {DEFAULT_POSH_TRACKING_LINK} from '@posh/model-types'

interface TrackingParams {
  savedTrackingLink: string | null
  savedAffiliateLink: string | null
}
export const useEventPageTrackingParams = (eventUrl: string): TrackingParams => {
  const [savedTrackingLink, setSavedTrackingLink] = useState<string | null>(
    () => sessionStorage.getItem(`tracking_${eventUrl}`) || null,
  )
  const [savedAffiliateLink, setSavedAffiliateLink] = useState<string | null>(
    () => sessionStorage.getItem(`affiliate_${eventUrl}`) || null,
  )

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const trackingParam = urlParams.get('t')
    const affiliateParam = urlParams.get('a')
    let shouldUpdateUrl = false

    if (trackingParam) {
      setSavedTrackingLink(trackingParam)
      sessionStorage.setItem(`tracking_${eventUrl}`, trackingParam)
      if (trackingParam === DEFAULT_POSH_TRACKING_LINK) {
        shouldUpdateUrl = true
      }
    }

    if (affiliateParam) {
      setSavedAffiliateLink(affiliateParam)
      sessionStorage.setItem(`affiliate_${eventUrl}`, affiliateParam)
    }

    if (shouldUpdateUrl) {
      urlParams.delete('t')
      const currentUrl = new URL(window.location.href)
      currentUrl.search = urlParams.toString()
      window.history.replaceState({}, '', currentUrl)
    }
  }, [eventUrl])

  return {
    savedTrackingLink,
    savedAffiliateLink,
  }
}
