import {createContext, ReactNode, useContext, useState} from 'react'

import ModalController, {type ModalControllerType, type ModalProps} from 'components/modals/ModalController'

interface ModalContextType {
  modalType: ModalControllerType | null
  modalProps: ModalProps[ModalControllerType] | null
  openModal: <T extends ModalControllerType>(modalType: T, modalProps: ModalProps[T]) => void
  closeModal: () => void
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)

export function ModalProvider({children}: {children: ReactNode}) {
  const [modalType, setModalType] = useState<ModalControllerType | null>(null)
  const [modalProps, setModalProps] = useState<ModalProps[ModalControllerType] | null>(null)

  const openModal = <T extends ModalControllerType>(modalType: T, modalProps: ModalProps[T]) => {
    setModalType(modalType)
    setModalProps(modalProps)
  }

  const closeModal = () => {
    setModalType(null)
    setModalProps(null)
  }

  return (
    <ModalContext.Provider value={{modalType, modalProps, openModal, closeModal}}>
      {children}
      <ModalController />
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within an ModalProvider')
  }
  return context
}
