import React from 'react'

import {CloudUploadIcon} from 'components/assets/Icons'
import {SpinLoader} from 'components/Loaders/SpinLoader'

import styles from './styles.module.scss'

export function FlyerUpload({
  children,
  isEditing,
  setIsImagePickerOpen,
  isUploading,
}: {
  children: React.ReactNode
  isEditing: boolean
  setIsImagePickerOpen: (isOpen: boolean) => void
  isUploading: boolean
}) {
  return (
    <div
      style={{position: 'relative', width: '100%', height: '100%', cursor: 'pointer'}}
      onClick={isEditing ? () => setIsImagePickerOpen(true) : undefined}>
      {children}
      {isEditing && (
        <div className={styles.imageUploadOverlay}>{isUploading ? <SpinLoader /> : <CloudUploadIcon />}</div>
      )}
    </div>
  )
}
