import React, {createContext, PropsWithChildren, useContext, useMemo, useState} from 'react'

import {CustomEventPageFont} from '@posh/model-types'
import {getRandomCreateEventFlyerPlaceholder} from '@posh/types'
import {BlurTint} from 'components/GlassView'

type EventVisualsPalette = {
  accentColor: string
  lightmode: boolean
  textContrasting: string // black if lightmode, white if darkmode
  buttonTextColor: string // white if lightmode, black if darkmode
  textContrastingWithOpacity: string // black if lightmode, white if darkmode
  blurTint: BlurTint
}

export function getEventVisualsPalette(accentColor: string, lightmode: boolean): EventVisualsPalette {
  return {
    accentColor,
    lightmode,
    textContrasting: lightmode ? '#000' : '#ffffff',
    buttonTextColor: lightmode ? '#ffffff' : '#0c0d0f',
    textContrastingWithOpacity: lightmode ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
    blurTint: lightmode ? 'light' : 'dark',
  }
}

export type EventVisualsSection = 'youtube' | 'text' | 'lineup' | 'gallery'

interface EventVisualsContextType {
  flyerPlaceholder: string
  palette: EventVisualsPalette
  fontFamily?: CustomEventPageFont
  setCurrentlyAddingSection: (section?: EventVisualsSection) => void
  currentlyAddingSection?: EventVisualsSection
}

type EventVisualsContextProviderProps = Pick<EventVisualsContextType, 'fontFamily'> &
  Pick<EventVisualsPalette, 'accentColor' | 'lightmode'>

export const EventVisualsContext = createContext<EventVisualsContextType | null>(null)
export const EventVisualsPaletteOverrideContext = createContext<EventVisualsContextType | null>(null)

export const useEventVisualsContext = () => {
  const overrideContext = useContext(EventVisualsPaletteOverrideContext)
  const eventVisualsContext = useContext(EventVisualsContext)
  if (overrideContext) return overrideContext

  if (!eventVisualsContext) throw new Error('useEventVisualsPalette must be used within <EventVisualsContextProvider/>')
  return eventVisualsContext
}

export const EventVisualsContextProvider = (props: PropsWithChildren<EventVisualsContextProviderProps>) => {
  const {children, ...contextValue} = props
  const [currentlyAddingSection, setCurrentlyAddingSection] = useState<EventVisualsSection | undefined>(undefined)
  const palette = getEventVisualsPalette(contextValue.accentColor, contextValue.lightmode)
  const flyerPlaceholder = useMemo(() => getRandomCreateEventFlyerPlaceholder(), [])
  const value = {
    palette,
    fontFamily: contextValue.fontFamily,
    currentlyAddingSection,
    setCurrentlyAddingSection,
    flyerPlaceholder,
  }

  return <EventVisualsContext.Provider value={value}>{children}</EventVisualsContext.Provider>
}
