import React from 'react'

import {useGetSectionalData} from 'apis/Analytics/useGetSectionalData'
import {CrossSection} from 'pages/EventManagementPages/OverviewUpdate/Analytics/CrossSection/CrossSection'
import {Text} from 'pages/EventManagementPages/OverviewUpdate/Analytics/Shared/Text/Text'
import {parseNumberValue} from 'pages/EventManagementPages/OverviewUpdate/Analytics/Shared/utils'

import '../../styles.scss'

interface DisputeAnalyticsProps {
  eventId?: string
  groupId: string
}

export const DisputeAnalytics = ({eventId, groupId}: DisputeAnalyticsProps) => {
  const {data, isLoading, isError} = useGetSectionalData(
    {
      eventId,
      groupId,
      dataPoints: ['totalDisputesBreakdown', 'totalDisputeRate', 'totalDisputesByReason', 'averageDisputesPerUser'],
    },
    {
      enabled: true,
    },
  )

  if (isLoading) {
    return (
      <CrossSection.Section>
        {[...Array(4)].map((_, index) => (
          <CrossSection key={index}>
            <CrossSection.Row>
              <Text.Loading width={150} />
            </CrossSection.Row>
            <CrossSection.Row>
              <Text.Loading size='xl' width={100} />
            </CrossSection.Row>
            <CrossSection.Row>
              <Text.Loading size='small' />
            </CrossSection.Row>
          </CrossSection>
        ))}
      </CrossSection.Section>
    )
  }

  if (isError) {
    return (
      <CrossSection.Section style={{display: 'flex', width: '100%', height: 136, justifyContent: 'center'}}>
        <CrossSection>
          <CrossSection.Row>
            <Text size='xl' bold>
              Error
            </Text>
          </CrossSection.Row>
          <CrossSection.Row>
            <Text>Something went wrong. Contact support@posh.vip for help.</Text>
          </CrossSection.Row>
        </CrossSection>
      </CrossSection.Section>
    )
  }

  return (
    <div className='TableContainer'>
      <div className='TableHeader'>
        <h2>Dispute Analytics</h2>
        <p>Get insights into your dispute performance and metrics</p>
      </div>
      <CrossSection.Section>
        {data.map((crossSection, index) => {
          return (
            <CrossSection key={index}>
              <CrossSection.Row>
                <Text>{crossSection.title}</Text>
              </CrossSection.Row>
              <CrossSection.Row>
                <Text size='xl' bold>
                  {parseNumberValue(crossSection.mainValue)}
                </Text>
              </CrossSection.Row>
              <CrossSection.Row>
                {crossSection.label && <Text size='small'>{crossSection.label}</Text>}
              </CrossSection.Row>
              {crossSection.chart && (
                <CrossSection.Row style={{marginTop: 5}}>
                  <CrossSection.Chart chart={crossSection.chart} id={`${crossSection.title}-chart`} />
                </CrossSection.Row>
              )}
            </CrossSection>
          )
        })}
      </CrossSection.Section>
    </div>
  )
}
