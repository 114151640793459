import React from 'react'
import CountUp from 'react-countup'
import Loader from 'react-loader-spinner'
import ReactTooltip from 'react-tooltip'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import {isUndefined} from 'lodash'

import './index.scss'

export interface Statistic {
  name: string
  value?: number
  isCurrency?: boolean
  description?: string
}
const MILLION_CURRENCY_THRESHOLD = 1000000
const ABBREVIATED_CURRENCY_THRESHOLD = 100000
const CURRENCY_DENOMINATOR = 1000

const STAT_ITEM_WIDTH = 225

const LOWER_BOUND_DECIMAL_COUNT = 1
const UPPER_BOUND_DECIMAL_COUNT = 2
const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}
const StatisticItem = ({
  stat: {isCurrency, value, name, description},
  currencySymbol,
  isNotAbbreviated,
  isAffiliate,
  gold,
}: {
  stat: Statistic
  currencySymbol: string
  isNotAbbreviated?: boolean
  isAffiliate?: boolean
  gold?: boolean
}) => {
  let numberValue =
    value! > ABBREVIATED_CURRENCY_THRESHOLD && isCurrency && !isNotAbbreviated
      ? Number(value! / CURRENCY_DENOMINATOR)
      : value!
  let suffix = value! > ABBREVIATED_CURRENCY_THRESHOLD && isCurrency && !isNotAbbreviated ? 'k' : ''
  //End
  let numberDecimals = value! > ABBREVIATED_CURRENCY_THRESHOLD ? LOWER_BOUND_DECIMAL_COUNT : UPPER_BOUND_DECIMAL_COUNT
  if (value! > MILLION_CURRENCY_THRESHOLD) {
    numberValue = value!
    suffix = ''
    numberDecimals = 2
  }
  const decimal = isCurrency ? numberDecimals : 0
  const prefix = isCurrency ? currencySymbol : ''
  const id = name.replace(' ', '-')
  return (
    <>
      {description && <ReactTooltip className='poshToolTip' effect='float' place='top' id={id} />}
      <div className='NewAnalyticsGrid-statistic' id={id} data-for={id} data-tip={description}>
        <div className={`NewAnalyticsGrid-statistic--label ${isAffiliate ? 'affiliate' : ''} ${gold ? 'gold' : ''}`}>
          {name}
        </div>
        <div className={`NewAnalyticsGrid-statistic--number ${isAffiliate ? 'affiliate' : ''} ${gold ? 'gold' : ''}`}>
          {!isUndefined(value) ? (
            <CountUp
              start={0}
              end={numberValue!}
              prefix={prefix}
              suffix={suffix}
              decimals={decimal}
              useEasing={true}
              duration={2}
              separator={','}
            />
          ) : (
            <Loader type='Rings' color={`${gold ? '#ffcb00' : 'white'}`} height={40} width={40} />
          )}
        </div>
      </div>
    </>
  )
}

const AnalyticsGrid = ({
  stats,
  currencySymbol,
  isNotAbbreviated,
  GroupDash,
  isAffiliate,
  gold,
}: {
  stats: (Statistic | null)[]
  currencySymbol: string
  isNotAbbreviated?: boolean
  GroupDash?: boolean
  isAffiliate?: boolean
  gold?: boolean
}) => {
  return (
    <div className={`NewAnalyticsGrid ${GroupDash ? 'GroupDashAnalyticsGrid' : ''}`}>
      {stats.map((s, i) => {
        if (!s) return null

        return (
          <StatisticItem
            currencySymbol={currencySymbol}
            key={i}
            stat={s}
            isNotAbbreviated={isNotAbbreviated}
            isAffiliate={isAffiliate}
            gold={gold}
          />
        )
      })}
    </div>
  )
}

export default AnalyticsGrid
