import {useEffect} from 'react'
import {useCookies} from 'react-cookie'
import {useParams} from 'react-router-dom'

import {useSetSessionEvent} from 'apis/Util/useSetSessionEvent'
import useSessionContext from 'domains/Auth/SessionContext'

export const useTrackUserSession = () => {
  const {mutate: setUserSession} = useSetSessionEvent()
  const [cookies] = useCookies(['campaignId', 'gclid'])
  const {currentUser} = useSessionContext()
  const {campaignShortUrl} = useParams<{campaignShortUrl?: string}>()
  useEffect(() => {
    // Check if the session tracking has already been initialized
    if (sessionStorage.getItem('userSessionTracked')) {
      return // Exit if the session has already been tracked
    }

    // Get URL parameters
    const urlParams = new URLSearchParams(window.location.search)
    const params: Record<string, string> = {}

    // Convert URL parameters to a plain object
    urlParams.forEach((value, key) => {
      params[key] = value
    })

    // Track Session Event
    setUserSession({
      urlParams: params,
      campaignId: campaignShortUrl,
      userId: currentUser?._id.toString(),
      source: 'web-unknown',
      additionalParams: {...cookies},
    })

    // Mark this session as tracked
    sessionStorage.setItem('userSessionTracked', 'true')
  }, [currentUser])
}
