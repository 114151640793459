import React from 'react'
import ReactSwitch, {ReactSwitchProps} from 'react-switch'

import classNames from 'classnames'

import './index.scss'

export interface PoshSwitchProps {
  title?: string
  rightTitle?: string
  className?: string
  switchOptions: ReactSwitchProps
  disabled?: boolean
  onColor?: string
  height?: number
  width?: number
  subtitle?: string
  textColor?: string
}
const PoshSwitch = (props: PoshSwitchProps) => {
  const {
    disabled,
    className,
    title,
    switchOptions,
    rightTitle,
    onColor = '#00ccff',
    height = 24,
    width = 40,
    subtitle,
    textColor = 'white',
  } = props
  const finalClassName = classNames(['PoshSwitch', className])

  return (
    <div className='flex items-center gap-2'>
      {title && <span className='text-sm'>{title}</span>}
      <div>
        <ReactSwitch
          onColor={onColor}
          height={height}
          width={width}
          checkedIcon={false}
          uncheckedIcon={false}
          disabled={disabled}
          {...switchOptions}
        />
      </div>
      {(rightTitle || subtitle) && (
        <div className='flex flex-col gap-1'>
          {rightTitle && (
            <span className='font-medium' style={{color: textColor ?? 'white'}}>
              {rightTitle}
            </span>
          )}
          {subtitle && (
            <span className='text-small m-0' style={{color: textColor ?? 'white'}}>
              {subtitle}
            </span>
          )}
        </div>
      )}
    </div>
  )
}

export default PoshSwitch
