import React, {ReactElement} from 'react'

import PoshInfo from 'components/form/PoshInfo'

const EventSetting = ({
  title,
  children,
  info,
  infoImage,
  wide,
  isTextField,
  block,
}: {
  title: string
  children: ReactElement<any, any> | ReactElement<any, any>[]
  info?: string
  infoImage?: string
  wide?: boolean
  isTextField?: boolean
  block?: boolean
}) => {
  return (
    <div
      className={`EventSettings-Setting ${wide ? 'Wide' : ''} ${isTextField ? 'TextField' : ''} ${
        block ? 'Block' : ''
      }`}>
      <div className='EventSettings-Title'>
        <label className='m-0'>{title}</label>
        {info && <PoshInfo info={info} image={infoImage} />}
      </div>
      {children}
    </div>
  )
}

export default EventSetting
