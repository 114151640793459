import React from 'react'

import {DisputesOverview} from 'pages/DisputeManagementPages/DisputesOverview'

import {RequiredPermissionAndScope, requirePermissionAndScope} from '../../components/RequirePermissions'
import CommunityHandler from './CommunityHandler'
import EventLaunch from './EventLaunch'
import Payouts from './Finance'
import Marketing from './Marketing'
import AttendeesCrmContextProvider from './Marketing/AttendeesCrmContextProvider'
import OrderRoutes from './Orders'
import OverviewHandler from './OverviewHandler'
import Profile from './Profile'
import Settings from './Settings'
import Team from './Team'

export enum GroupPageSection {
  OVERVIEW = 'overview',
  TEAM = 'team',
  FINANCE = 'finance',
  MARKETING = 'marketing',
  COMMUNITY = 'community',
  ORDERS = 'orders',
  PROFILE = 'profile',
  EVENT_LAUNCH = 'event_launch',
  DISPUTES = 'disputes',
  SETTINGS = 'settings',
}

export interface PoshPageSectionAttributes {
  route: string
  content: JSX.Element
  requiredPermissions?: RequiredPermissionAndScope[]
  allowPartialMatch?: boolean
}

export const GROUPS_ROUTE_MAP: {[key in GroupPageSection]: PoshPageSectionAttributes} = {
  [GroupPageSection.OVERVIEW]: {
    route: 'overview',
    content: <OverviewHandler />,
  },
  [GroupPageSection.TEAM]: {
    route: 'team',
    content: <Team />,
    requiredPermissions: [requirePermissionAndScope('view_team_members', ['group'])],
  },
  [GroupPageSection.COMMUNITY]: {
    route: 'community',
    content: <CommunityHandler />,
  },
  [GroupPageSection.FINANCE]: {
    route: 'finance',
    content: <Payouts />,
    requiredPermissions: [requirePermissionAndScope('view_payouts', ['group'])],
  },
  [GroupPageSection.DISPUTES]: {
    route: 'disputes',
    content: <DisputesOverview />,
    requiredPermissions: [requirePermissionAndScope('view_disputes', ['group'])],
  },
  [GroupPageSection.MARKETING]: {
    route: 'marketing',
    content: (
      <AttendeesCrmContextProvider>
        <Marketing />
      </AttendeesCrmContextProvider>
    ),
    requiredPermissions: [
      requirePermissionAndScope('view_global_attendee_list', ['group']),
      requirePermissionAndScope('view_attendee_list', ['group', 'event']),
    ],
    allowPartialMatch: true,
  },
  [GroupPageSection.ORDERS]: {
    route: 'orders',
    content: <OrderRoutes />,
  },
  [GroupPageSection.PROFILE]: {
    route: 'profile',
    content: <Profile />,
    requiredPermissions: [requirePermissionAndScope('edit_group_settings', ['group'])],
  },
  [GroupPageSection.EVENT_LAUNCH]: {
    route: 'event_launch',
    content: <EventLaunch />,
    requiredPermissions: [requirePermissionAndScope('create_event', ['group'])],
  },
  [GroupPageSection.SETTINGS]: {
    route: 'settings',
    content: <Settings />,
    requiredPermissions: [requirePermissionAndScope('edit_group_settings', ['group'])],
  },
}
