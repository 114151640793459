import React from 'react'

import {captureException, ErrorBoundary} from '@sentry/react'
import {ICrossSection} from 'apis/Analytics/useGetSectionalData'
import {GetMultiSessionEventOutput} from 'apis/MultiSessionEvents/useGetMultiSessionEvent'
import {
  TMSECrossSectionType,
  useGetMultiSessionSectionalData,
} from 'apis/MultiSessionEvents/useGetMultiSessionSectionalData'
import {ServerRequirePermissions} from 'components/ServerRequirePermissions'
import {CrossSection} from 'pages/EventManagementPages/OverviewUpdate/Analytics/CrossSection/CrossSection'
import {Text} from 'pages/EventManagementPages/OverviewUpdate/Analytics/Shared/Text/Text'
import {parseNumberValue} from 'pages/EventManagementPages/OverviewUpdate/Analytics/Shared/utils'

import {ellipsizeCrossSectionChartLabels} from '../utils/analytics.utils'
import {MultiSessionOrdersList} from './MultiSessionOrdersList'
import {MultiSessionSpecificEventAnalyticsSelect} from './MultiSessionSpecificEventAnalyticsSelect'
import {MultiSessionTrackingLinks} from './MultiSessionTrackingLinks/MultiSessionTrackingLinks'

import styles from './MultiSessionAnalytics.module.scss'

interface MultiSessionAnalyticsProps {
  groupId: string
  mseId: string
  mse?: GetMultiSessionEventOutput
}

export const MultiSessionAnalytics = (props: MultiSessionAnalyticsProps) => {
  const {groupId, mseId, mse} = props

  const {data, isLoading, isError, isSuccess} = useGetMultiSessionSectionalData({
    groupId,
    mseId,
    dataPoints: ['mseRevenue', 'mseTickets', 'msePageVisits', 'mseConversion'],
  })

  const crossSectionMap = data?.reduce(
    (acc, crossSection) => {
      acc[crossSection.type] = crossSection
      return acc
    },
    {} as Record<string, ICrossSection>,
  )

  return (
    <div className={styles.MultiSessionAnalyticsContainer}>
      <div className={styles.MultiSessionAnalytics}>
        <div className='mt-2 mb-4 flex flex-wrap items-center justify-between gap-2'>
          <h3 className='text-large noMargin font-medium! tracking-tight'>Analytics</h3>
          <MultiSessionSpecificEventAnalyticsSelect groupId={groupId} mseId={mseId} />
        </div>
        <ErrorBoundary
          fallback={({error}) => <></>}
          onError={(error, info) => {
            captureException(error, {extra: {info, where: 'web multi session analytics page'}})
          }}>
          <ServerRequirePermissions
            groupId={groupId}
            requiredPermissions={['view_multi_session_analytics']}
            fallbackComponent={
              <CrossSection style={{justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                <CrossSection.Row>
                  <Text size='xl' bold>
                    You do not have permission to view this section!
                  </Text>
                </CrossSection.Row>
                <CrossSection.Row>
                  <Text>Please contact your administrator if you believe you should have access.</Text>
                </CrossSection.Row>
              </CrossSection>
            }>
            {({isLoading: isPermissionsLoading}) => {
              if (isPermissionsLoading) return <></>

              return (
                <>
                  {isLoading && (
                    <>
                      {Array.from({length: 2}).map((_, index) => (
                        <CrossSection.Section key={index}>
                          {Array.from({length: 2}).map((_, index) => (
                            <CrossSection key={index}>
                              <CrossSection.Row>
                                <Text.Loading width={150} />
                              </CrossSection.Row>
                              <CrossSection.Row>
                                <Text.Loading width={150} />
                              </CrossSection.Row>
                              <CrossSection.Row>
                                <CrossSection.Chart.Loading type='Pie' />
                              </CrossSection.Row>
                            </CrossSection>
                          ))}
                        </CrossSection.Section>
                      ))}
                    </>
                  )}
                  {isError && (
                    <CrossSection.Section
                      style={{display: 'flex', width: '100%', height: 136, justifyContent: 'center'}}>
                      <CrossSection>
                        <CrossSection.Row>
                          <Text size='xl' bold>
                            Error
                          </Text>
                        </CrossSection.Row>
                        <CrossSection.Row>
                          <Text>Something went wrong. Contact support@posh.vip for help.</Text>
                        </CrossSection.Row>
                      </CrossSection>
                    </CrossSection.Section>
                  )}
                  {isSuccess && (
                    <>
                      <CrossSection.Section>
                        {crossSectionMap?.mseRevenue && (
                          <MSECrossSection
                            crossSection={ellipsizeCrossSectionChartLabels(crossSectionMap.mseRevenue)}
                          />
                        )}
                        {crossSectionMap?.mseTickets && (
                          <MSECrossSection
                            crossSection={ellipsizeCrossSectionChartLabels(crossSectionMap.mseTickets)}
                          />
                        )}
                      </CrossSection.Section>
                      <CrossSection.Section>
                        {crossSectionMap?.msePageVisits && (
                          <MSECrossSection
                            crossSection={ellipsizeCrossSectionChartLabels(crossSectionMap.msePageVisits)}
                          />
                        )}
                        {crossSectionMap?.mseConversion && (
                          <MSECrossSection
                            crossSection={ellipsizeCrossSectionChartLabels(crossSectionMap.mseConversion)}
                          />
                        )}
                      </CrossSection.Section>
                    </>
                  )}

                  <MultiSessionOrdersList groupId={groupId} mseId={mseId} />
                  {mse && (
                    <MultiSessionTrackingLinks
                      groupId={groupId}
                      mseId={mseId}
                      mseUrl={mse.url}
                      mseHexUrl={mse.hexUrl}
                    />
                  )}
                </>
              )
            }}
          </ServerRequirePermissions>
        </ErrorBoundary>
      </div>
    </div>
  )
}

const getChartFormatter = (type: TMSECrossSectionType) => {
  if (type === 'mseConversion') return (val: number) => `${val}%`
  if (type === 'mseRevenue') return (val: number) => `$${val}`
  return undefined
}

const getBarChartMaxValue = (type: TMSECrossSectionType, chart: ICrossSection['chart']) => {
  if (type === 'mseConversion' && chart?.data.every(d => d.data === 0)) return 100
  return undefined
}

const MSECrossSection = ({crossSection}: {crossSection: ICrossSection}) => {
  return (
    <CrossSection>
      <CrossSection.Row>
        <Text>{crossSection.title}</Text>
      </CrossSection.Row>
      <CrossSection.Row>
        <Text size='xl' bold>
          {parseNumberValue(crossSection.mainValue)}
        </Text>
      </CrossSection.Row>
      <CrossSection.Row>
        {crossSection.label && <CrossSection.Subtitle muted>{crossSection.label}</CrossSection.Subtitle>}
      </CrossSection.Row>
      {crossSection.chart && (
        <CrossSection.Row style={{marginTop: 5}}>
          <CrossSection.Chart
            formatter={getChartFormatter(crossSection.type)}
            chart={crossSection.chart}
            id={`${crossSection.title}-chart`}
            barChartMaxValue={getBarChartMaxValue(crossSection.type, crossSection.chart)}
          />
        </CrossSection.Row>
      )}
    </CrossSection>
  )
}
