import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {OrderTag} from 'apis/Orders'
import {useGetEventOrders} from 'apis/Orders/useGetEventOrders'
import useDebounce from 'hooks/useDebounce'
import {useEventManagementContext} from 'pages/EventManagementPages/EventManagementContext'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {OrderList} from './OrderList/OrderList'
import {OrdersSearchFilterContainer} from './OrdersSearchFilterContainer'

interface OrdersSectionProps {
  eventId: string
  groupId: string
  isRSVPEvent?: boolean
  isLoading?: boolean
}

export const OrdersSection = (props: OrdersSectionProps) => {
  const {eventId, groupId, isRSVPEvent} = props
  const [search, setSearch] = useState<string>()
  // Use this for the query
  const debouncedSearch = useDebounce(search, 300)
  const [filters, setFilters] = useState<OrderTag[]>([])
  const {domain} = useResourcePageParams()
  const navigate = useNavigate()

  const {
    data: orders,
    isLoading,
    isError,
    isSuccess,
  } = useGetEventOrders(
    {
      groupId: groupId,
      eventId: eventId,
      limit: 50,
      activeFilters: filters,
      search: debouncedSearch,
    },
    {
      enabled: props.isLoading !== true,
    },
  )

  const hasFilters = filters.length > 0 || !!debouncedSearch

  const navigateToOrdersPage = () => {
    navigate(`/${domain}/groups/${groupId}/orders?e=${encodeURIComponent(eventId)}`)
  }

  const {event} = useEventManagementContext()

  const ordersWithEventAttributes = orders?.map(order => ({
    ...order,
    eventUrl: event.url,
    isRSVPEvent: event.isRSVPEvent,
  }))

  return (
    <OrdersSearchFilterContainer
      search={search}
      setSearch={setSearch}
      filters={filters}
      setFilters={setFilters}
      isRSVPEvent={isRSVPEvent}
      navigateToOrdersPage={navigateToOrdersPage}>
      <>
        {(isLoading || props.isLoading) && <OrderList.Loading />}

        {isError && <OrderList.Error isRSVPEvent={isRSVPEvent} />}

        {isSuccess && !!ordersWithEventAttributes && (
          <OrderList hasFilters={hasFilters} orders={ordersWithEventAttributes} isRSVPEvent={isRSVPEvent} />
        )}
      </>
    </OrdersSearchFilterContainer>
  )
}
