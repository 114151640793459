import React from 'react'

import Button from 'components/form/Button'

import {KeepEditing} from '../components/KeepEditing'

import styles from './styles.module.scss'

export interface EnablePaymentsProps {
  onConfirm: (status: 'draft', shouldNavigateToFinanceSettings: boolean) => void
  onClose: () => void
  isMultiSession: boolean
}

export function EnablePayments({onConfirm, onClose, isMultiSession}: EnablePaymentsProps) {
  return (
    <div className={styles.EnablePayments}>
      <div className={styles.container}>
        <h4 className={'text-center'}>Almost there...</h4>
        <p className={'text-center'}>
          You must complete a short financial onboarding before you can start selling tickets
        </p>
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => onConfirm('draft', true)}
            className='noShadow goldHover'
            style={{flex: 1, width: 'fit-content'}}>
            Enable payments
          </Button>
          {!isMultiSession && (
            <h6 onClick={() => onConfirm('draft', false)} className='clickable center' style={{flex: 1}}>
              Or, save as a draft and do this later
            </h6>
          )}
        </div>
        <KeepEditing onClose={onClose} />
      </div>
    </div>
  )
}
