import {TGroupSmsOptIn, TPoshSmsOptIn} from '@posh/sms-opt-ins'
import {
  GetAccountOptInsInput,
  SmsOptInOptions,
  updateGroupSmsOptIn,
  updateMarketingSmsOptIn,
  updateTransactionalSmsOptIn,
  useGetAccountOptIns,
  useUpdateAccountOptIn,
  useUpdateGroupOptIn,
} from 'apis/Optins'
import PoshSwitch from 'components/form/PoshSwitch'
import {useToast} from 'components/toasts/ToastProvider'

import {NotificationGroupsModal} from './NotificationsGroupsModal'

const ErrorState = () => {
  return <div className='m-6 text-center text-red-500'>Error getting account opt-ins</div>
}

const LoadingState = () => {
  return <div className='m-6 text-center text-gray-500'>Loading...</div>
}

export default function AlertSettings() {
  const {showToast} = useToast()

  /* Queries */
  const {
    data: accountOptInsResponse,
    isError: isErrorGettingAccountOptIns,
    isLoading: isGettingAccountOptIns,
  } = useGetAccountOptIns({optInType: 'posh-sms', fields: ['optIns']})

  const subscribedGroupsQuery: GetAccountOptInsInput = {
    optInType: 'group-sms',
    smsOptInState: 'yes',
    fields: ['group', 'optIns', 'account'],
  }
  const {
    data: subscribedGroupsResponse,
    isError: isErrorGettingSubscribedGroups,
    isLoading: isGettingSubscribedGroups,
  } = useGetAccountOptIns(subscribedGroupsQuery)

  const blockedGroupsQuery: GetAccountOptInsInput = {
    optInType: 'group-sms',
    smsOptInState: 'no-subaccount',
    fields: ['group', 'optIns', 'account'],
  }
  const {
    data: blockedGroupsResponse,
    isError: isErrorGettingBlockedGroups,
    isLoading: isGettingBlockedGroups,
  } = useGetAccountOptIns(blockedGroupsQuery)

  const unsubscribedGroupsQuery: GetAccountOptInsInput = {
    optInType: 'group-sms',
    smsOptInState: 'no-application',
    fields: ['group', 'optIns', 'account'],
  }
  const {
    data: unsubscribedGroupsResponse,
    isError: isErrorGettingUnsubscribedGroups,
    isLoading: isGettingUnsubscribedGroups,
  } = useGetAccountOptIns(unsubscribedGroupsQuery)

  /* Mutations */
  const {mutate: updateAccountOptIns} = useUpdateAccountOptIn({
    location: 'account-settings',
    onError: () => showToast({title: 'Error updating account opt-in', type: 'error'}),
    onSuccess: () => showToast({title: 'Successfully updated account opt-in', type: 'success'}),
    query: {optInType: 'posh-sms', fields: ['optIns']},
  })

  const {mutate: updateSubscribedGroups} = useUpdateGroupOptIn({
    onError: () => showToast({title: 'Error subscribing to group', type: 'error'}),
    onSuccess: () => showToast({title: 'Successfully subscribed to group', type: 'success'}),
    query: unsubscribedGroupsQuery,
  })
  const {mutate: updateUnsubscribedGroups} = useUpdateGroupOptIn({
    onError: () => showToast({title: 'Error unsubscribing from group', type: 'error'}),
    onSuccess: () => showToast({title: 'Successfully unsubscribed from group', type: 'success'}),
    query: subscribedGroupsQuery,
  })

  /* Render Loading/Error States */
  if (
    isErrorGettingAccountOptIns ||
    isErrorGettingSubscribedGroups ||
    isErrorGettingBlockedGroups ||
    isErrorGettingUnsubscribedGroups
  ) {
    return <ErrorState />
  }
  if (isGettingAccountOptIns || isGettingSubscribedGroups || isGettingBlockedGroups || isGettingUnsubscribedGroups) {
    return <LoadingState />
  }

  /* Data */
  const {data: accountOptInsData} = accountOptInsResponse
  const poshOptIns = accountOptInsData.find(optIn => optIn.optInType === 'posh-sms') as TPoshSmsOptIn
  const transactionalOptIn = poshOptIns?.optIns?.transactional?.smsOptIn === SmsOptInOptions.Yes
  const marketingOptIn = poshOptIns?.optIns?.marketing?.smsOptIn === SmsOptInOptions.Yes

  const {data: subscribedGroupsData} = subscribedGroupsResponse as {data: TGroupSmsOptIn[]}
  const {data: blockedGroupsData} = blockedGroupsResponse as {data: TGroupSmsOptIn[]}
  const {data: unsubscribedGroupsData} = unsubscribedGroupsResponse as {data: TGroupSmsOptIn[]}

  /* Handlers */
  const handleTransactionalOptIn = (value: boolean) => {
    updateAccountOptIns(updateTransactionalSmsOptIn(poshOptIns, value))
  }
  const handleMarketingOptIn = (value: boolean) => {
    updateAccountOptIns(updateMarketingSmsOptIn(poshOptIns, value))
  }
  const handleSubscribeToGroup = (group: TGroupSmsOptIn) => {
    updateSubscribedGroups(updateGroupSmsOptIn(group, true))
  }
  const handleUnsubscribeFromGroup = (group: TGroupSmsOptIn) => {
    updateUnsubscribedGroups(updateGroupSmsOptIn(group, false))
  }

  return (
    <div className='grid gap-4 p-5'>
      <PoshSwitch
        title='SMS Transactional Alerts'
        switchOptions={{checked: transactionalOptIn, onChange: handleTransactionalOptIn}}
      />
      <PoshSwitch
        title='Marketing Promotions'
        switchOptions={{checked: marketingOptIn, onChange: handleMarketingOptIn}}
      />
      <div className='grid grid-cols-1 gap-4 pt-4 lg:grid-cols-3'>
        <NotificationGroupsModal
          type={'yes'}
          groupOptIns={subscribedGroupsData}
          onUpdateGroup={handleUnsubscribeFromGroup}
        />
        <NotificationGroupsModal type={'no-subaccount'} groupOptIns={blockedGroupsData} />
        <NotificationGroupsModal
          type={'no-application'}
          groupOptIns={unsubscribedGroupsData}
          onUpdateGroup={handleSubscribeToGroup}
        />
      </div>
    </div>
  )
}
