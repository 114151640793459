import React, {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'

import {
  calculateNumberOfOccurences,
  EventRecurrenceType,
  getAllDatesForSeries,
  getEventPageDateDisplay,
  isMultiSessionEventOver,
} from '@posh/model-types'
import {useGetMultiSessionEvent} from 'apis/MultiSessionEvents/useGetMultiSessionEvent'
import {
  UpdateMultiSessionEventInput,
  useUpdateMultiSessionEvent,
} from 'apis/MultiSessionEvents/useUpdateMultiSessionEvent'
import classNames from 'classnames'
import {PictureState} from 'components/form/ImagePicker'
import {ImageSearchModal} from 'components/form/ImageSearchModal'
import {PoshBoatLoader} from 'components/Loaders/PoshBoatLoader'
import {EventVisualsCircleButton} from 'components/PageComponents/EventVisuals/Button/CircleButton'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {EventVisualsRecurrenceSettings} from 'components/PageComponents/EventVisuals/Form/Recurrence/EventVisualsRecurrenceSettings'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'
import VerifiedGroupBadge from 'components/VerifiedGroupBadge'
import {useUploadImage} from 'hooks/uploadImage/useUploadImage'
import {useCopyLink} from 'hooks/useCopyLink'
import {useDimensions} from 'hooks/useDimensions'
import moment from 'moment-timezone'
import {EventVisualsContextProvider} from 'pages/EventManagementPages/Visuals/Upgrade-dev/components/context/EventVisualsContext'
import {useMultiSessionEventContext} from 'pages/GroupPage/ManageMultiSessionEvent/context/ManageMultiSessionEventPagesContext'

import {useNavigateToMultiSessionEventPageLongUrl} from '../longUrl'
import {EditMultiSessionEventButtons} from './components/EditSeriesButtons'
import {FlyerUpload} from './components/FlyerUpload'
import {MultiSessionEventsPublicDateGroups} from './components/MultiSessionEventsPublicDateGroup'
import {MultiSessionNewEventsModal} from './components/MultiSessionNewEventsModal'
import {usePollAfterAsyncEdit} from './usePollAfterAsyncEdit'

import styles from './styles.module.scss'

const IMAGE_SEARCH_MODAL_WIDTH = 780

type MultiSessionEventForm = Pick<
  UpdateMultiSessionEventInput,
  'name' | 'flyer' | 'eventTemplate' | 'endDate' | 'total' | 'description'
>

interface MultiSessionEventsPageProps {
  response: ReturnType<typeof useGetMultiSessionEvent>
  mseId?: string
  groupId?: string
  domain?: string
  trackingLink?: string
}

export function MultiSessionEventsPage({response, mseId, groupId, domain, trackingLink}: MultiSessionEventsPageProps) {
  const {setIsFormDirty} = useMultiSessionEventContext()

  const [isEditing, setIsEditing] = useState(false)
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false)
  const [isNewEventsModalOpen, setIsNewEventsModalOpen] = useState(false)
  // we display the recurrence settings, but only update the end date and let the BE handle the recurrence
  const [displayedRecurrence, setDisplayedRecurrence] = useState<EventRecurrenceType | undefined>(undefined)
  const {data: mse, refetch} = response
  const {showToast} = useToast()

  const {isMobile} = useDimensions()

  const navigateToMultiSessionEventPageLongUrl = useNavigateToMultiSessionEventPageLongUrl()

  const isEditable = !!groupId
  const mseForm = useForm<MultiSessionEventForm>()

  const timezone = mse?.timezone

  const watchFlyer = mseForm.watch('flyer')
  const watchEndDate = mseForm.watch('endDate')

  const isFormDirty = useMemo(() => {
    return Object.keys(mseForm.formState.dirtyFields).length > 0
  }, [JSON.stringify(mseForm.formState.dirtyFields)])

  useEffect(() => {
    setIsFormDirty(isFormDirty)
  }, [isFormDirty])

  const {mutate: updateMultiSessionEvent, isLoading: isUpdating} = useUpdateMultiSessionEvent({
    onSuccess: () => {
      refetch().then(() => {
        setIsNewEventsModalOpen(false)
      })
    },
    onError: error => {
      showToast({
        title: 'Error updating event',
        type: 'error',
        subtitle: error.message,
      })
    },
  })
  const {uploadImage: uploadAndSetOnForm, isUploading} = useUploadImage({
    imageType: 'event-flyer',
    onSuccess: (_, imageUrl) => {
      mseForm.setValue('flyer', imageUrl, {shouldDirty: true})
    },
  })

  const {setHasAsyncEdited} = usePollAfterAsyncEdit(refetch)

  const newEventDates = useMemo(() => {
    if (!displayedRecurrence) return []
    const oldNumEvents = mse?.events.length ?? 0
    const newNumEvents = calculateNumberOfOccurences(displayedRecurrence)
    return getAllDatesForSeries(displayedRecurrence, timezone)
      .slice(oldNumEvents, newNumEvents)
      .map(date => moment.tz(date, timezone ?? moment.tz.guess()))
  }, [displayedRecurrence, mse])

  const numNewEvents = useMemo(() => {
    return newEventDates.length
  }, [newEventDates])

  const {copyLink} = useCopyLink()

  useEffect(() => {
    mseForm.reset(
      {
        name: mse?.name,
        flyer: mse?.flyer,
        eventTemplate: mse?.eventTemplate,
        description: mse?.description,
      },
      {keepDirty: false},
    )
    setDisplayedRecurrence(mse?.eventTemplate?.recurrence)
  }, [mse])

  const onPickImage = (image: PictureState) => {
    if (image.file) return uploadAndSetOnForm(image.file)
    if (image.url) return mseForm.setValue('flyer', image.url, {shouldDirty: true})
  }

  const onDiscard = () => {
    mseForm.reset({
      name: mse?.name,
      flyer: mse?.flyer,
      eventTemplate: mse?.eventTemplate,
      description: mse?.description,
    })
    setIsNewEventsModalOpen(false)
    setDisplayedRecurrence(mse?.eventTemplate?.recurrence)
  }

  const onSave = mseForm.handleSubmit(data => {
    if (!mseId || !groupId) return
    updateMultiSessionEvent({
      ...data,
      id: mseId,
      groupId,
      eventTemplate: {
        ...data.eventTemplate,
        applyTo: {type: 'only-new'},
      },
    })
  })

  const isOver = mse ? isMultiSessionEventOver(mse) : false

  return (
    <EventVisualsContextProvider accentColor='white' lightmode={false}>
      <div className={styles.page}>
        {!mse ? (
          <div className={styles.loaderContainer}>
            <PoshBoatLoader />
          </div>
        ) : (
          <div className={styles.container}>
            <div className={classNames(styles.header, 'relative')}>
              <div className={styles.headerRight}>
                <FlyerUpload
                  isEditing={isEditing}
                  setIsImagePickerOpen={setIsImagePickerOpen}
                  isUploading={isUploading}>
                  <PoshImage
                    src={watchFlyer}
                    alt={mse.name}
                    className={styles.headerFlyer}
                    transformOptions={{width: 1200}}
                  />
                </FlyerUpload>
              </div>
              <div className={styles.headerLeft}>
                {isEditable && isEditing ? (
                  <EventVisualsTextInput.Text.Controlled
                    lightMode={false}
                    accentColor={'white'}
                    placeholder={'Event series name'}
                    size={isMobile ? 30 : 36}
                    control={mseForm.control}
                    name='name'
                  />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 10,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                    <h2>{mse.name}</h2>
                  </div>
                )}
                <div className={classNames(styles.headerDetails, 'grow')}>
                  <div className='flex items-center gap-2 rounded-sm'>
                    <Link
                      to={`/g/${mse.group.url}`}
                      className='flex flex-row items-center gap-2'
                      style={{textDecoration: 'none'}}>
                      <PoshImage
                        src={mse.group.avi}
                        alt={mse.group.name}
                        className='aspect-square h-6 w-6 rounded-full'
                      />
                      <p className='text-small noMargin opacity-100! hover:underline'>{mse.group.name}</p>
                    </Link>
                    {mse.group.verified && <VerifiedGroupBadge size={16} />}
                  </div>
                  <p className='noMargin'>
                    {getEventPageDateDisplay(
                      new Date(mse.startUtc),
                      new Date(watchEndDate ?? mse.endUtc),
                      mse.timezone,
                      true,
                      false,
                    )}
                  </p>
                  {!isEditing && (
                    <div className='flex grow flex-col justify-center'>
                      {!!mse.description ? (
                        <span className='whitespace-pre-wrap text-white'>{mse.description}</span>
                      ) : (
                        <>
                          {isEditable && (
                            <p
                              onClick={() => {
                                setIsEditing(true)
                              }}
                              className='text-small noMargin cursor-pointer'>
                              Add a description for this event series...
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
                {isEditable && isEditing && (
                  <>
                    <textarea
                      {...mseForm.register('description')}
                      rows={5}
                      className='block field-sizing-content min-h-[125px] w-full resize-y rounded-lg border-0 bg-[#e0e1e31a] p-2.5 font-[Inter] text-sm font-light text-white placeholder-gray-300 transition-all duration-200 hover:bg-[#e0e1e330] focus:bg-[#e0e1e330] focus:outline-none'
                      placeholder='Add a description for this event series...'></textarea>
                    {isOver ? (
                      <p className={'text-small m-0 text-gray-500!'}>
                        All events in this series have ended. Create a new series to add more events.
                      </p>
                    ) : (
                      <EventVisualsRecurrenceSettings
                        recurrence={displayedRecurrence}
                        onChangeRecurrence={r => {
                          if (!r) return
                          setDisplayedRecurrence(r)
                          if (r.ending.hasEndDate) {
                            const newEndDate = getAllDatesForSeries(r, timezone).at(-1)
                            mseForm.setValue('endDate', newEndDate, {shouldDirty: true})
                            mseForm.setValue('total', undefined, {shouldDirty: true})
                          } else {
                            mseForm.setValue('total', r.ending.nOccurrences, {shouldDirty: true})
                            mseForm.setValue('endDate', undefined, {shouldDirty: true})
                          }
                        }}
                        startUtc={mse.startUtc}
                        timezone={mse.timezone}
                        type={'edit'}
                      />
                    )}
                  </>
                )}
                <div className='flex flex-col justify-end gap-2'>
                  <>
                    {isEditable && (
                      <div className='flex flex-row items-center gap-2'>
                        <EditMultiSessionEventButtons
                          isUpdating={isUpdating}
                          onDiscard={onDiscard}
                          onSave={numNewEvents ? () => setIsNewEventsModalOpen(true) : onSave}
                          isDirty={isFormDirty}
                          isEditing={isEditing}
                          toggleEditing={setIsEditing}
                        />
                      </div>
                    )}
                    <div className='static flex flex-row items-center gap-2 lg:absolute lg:top-0 lg:right-[-55px] lg:flex-col'>
                      {isEditable && (
                        <EventVisualsCircleButton
                          iconType='eye'
                          lightMode={true}
                          accentColor={'black'}
                          size={20}
                          style={{padding: 10, border: '1px solid #222', background: 'transparent'}}
                          onClick={() => {
                            navigateToMultiSessionEventPageLongUrl({mseUrl: mse.url}, {newTab: true})
                          }}
                        />
                      )}
                      <EventVisualsCircleButton
                        iconType='copy'
                        lightMode={true}
                        accentColor={'black'}
                        size={20}
                        style={{padding: 10, border: '1px solid #222', background: 'transparent'}}
                        onClick={() => {
                          // should we add the tracking link to the url?
                          // i don't think so, because the user will be sharing the url with others
                          copyLink(`${window.location.origin}/series/${mse.url}`)
                          showToast({
                            title: 'Link copied',
                            type: 'info',
                          })
                        }}
                      />
                    </div>
                  </>
                </div>
              </div>
            </div>

            <div className={styles.listContainer}>
              <MultiSessionEventsPublicDateGroups
                events={mse.events}
                isEditing={isEditable}
                groupId={groupId}
                domain={domain}
                mseId={mseId}
                trackingLink={trackingLink}
              />
            </div>
          </div>
        )}
      </div>
      {isImagePickerOpen && (
        <ImageSearchModal
          setFile={onPickImage}
          isOpen={isImagePickerOpen}
          onClose={() => setIsImagePickerOpen(false)}
          width={IMAGE_SEARCH_MODAL_WIDTH}
        />
      )}
      {isNewEventsModalOpen && numNewEvents > 0 && mse?.eventTemplate && (
        <MultiSessionNewEventsModal
          isOpen={isNewEventsModalOpen}
          onClose={() => setIsNewEventsModalOpen(false)}
          newDates={newEventDates}
          template={{
            ...mse.eventTemplate,
            startUtc: mse.startUtc,
            timezone: mse.timezone,
          }}
          onLaunch={() => {
            onSave()
            setHasAsyncEdited(true)
            setIsEditing(false)
          }}
          isLaunching={isUpdating}
        />
      )}
    </EventVisualsContextProvider>
  )
}
