import React, {ReactNode, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {useFetchTextBlast} from 'apis/Groups/useFetchTextBlast'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import SMSCampaignBackground from 'pages/SMSCampaign/SMSCampaignBackground'
import SMSStatus from 'pages/SMSCampaign/SMSStatus'

import {useToast} from '../../components/toasts/ToastProvider'

import './SMSCampaignStatus.styles.scss'

const SMSStatuses = ({children}: {children: ReactNode}) => {
  return <div className='SMSCampaignStatus-statusContainer'>{children}</div>
}

const POLLING_THRESHOLD = 30

const SMSCampaignStatusPage = () => {
  const {blastId} = useParams()
  const {data, isFetching} = useFetchTextBlast(blastId!, true, 1000)
  const [timesFetched, setTimesFetched] = useState(0)
  useEffect(() => {
    if (isFetching) {
      setTimesFetched(t => t + 1)
    }
  }, [isFetching])

  const {sending, delivered, failed, recipients, initiated, textBlastId} = data ?? {
    sending: 0,
    delivered: 0,
    failed: 0,
    recipients: 1,
  }

  const toast = useToast()

  useEffect(() => {
    if (timesFetched > POLLING_THRESHOLD && !initiated) {
      toast.showToast({
        type: 'error',
        title: 'This is taking longer than expected',
        subtitle: 'If the issue persists, please contact support',
      })
    }
  }, [initiated, timesFetched, toast])

  const sendingText = initiated ? 'Sending' : 'Initiating'
  const navigate = useNavigate()
  const {trackEvent} = useMixpanel()

  const onClickContinue = () => {
    trackEvent('Text Blast- Continue to Campaign Click', {...data})
    navigate(`../../smscampaign/${textBlastId}/details`)
  }

  const loaderDots = '.'.repeat(timesFetched % 4)
  const deliveredPercentage = delivered / recipients
  return (
    <SMSCampaignBackground>
      <div className='SMSCampaignStatuses__container'>
        <SMSStatuses>
          <SMSStatus status='sending' size='md' percentage={sending / recipients} id='sending'>
            {sending}
          </SMSStatus>
          <SMSStatus
            status='delivered'
            size='lg'
            percentage={deliveredPercentage}
            animate={deliveredPercentage > 0.9}
            id='delivered'>
            {delivered}
          </SMSStatus>
          <SMSStatus status='failed' size='md' percentage={failed / recipients} id='failed'>
            {failed}
          </SMSStatus>
        </SMSStatuses>
        <h3 className='m-0'>
          {sendingText} your SMS campaign{loaderDots}
        </h3>
        <h4 className='center m-0'>You may leave this screen and sending will continue...</h4>
        <Button onClick={onClickContinue} disabled={!initiated}>
          Continue to Campaign
        </Button>
      </div>
    </SMSCampaignBackground>
  )
}

export default SMSCampaignStatusPage
