import React, {ReactNode, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {useMixpanel} from 'apis/MixPanelHandler'
import {useRemovePendingTextBlastRecipientFromSMSPage} from 'apis/PendingTextBlasts/useRemovePendingTextBlastRecipient'
import {useSearchPendingTextBlastRecipients} from 'apis/PendingTextBlasts/useSearchPendingTextBlastRecipients'
import {searchIconUrl} from 'assets/images/s3images'
import classNames from 'classnames'
import {CircleBack, CircleForward} from 'components/assets/Icons'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import TextIconInput from 'components/TextInput'
import useDebounce from 'hooks/useDebounce'
import {trpc} from 'lib/trpc'

import getAvi from '../../apis/Util/getAvi'
import Button from '../../components/form/Button'
import formatPhoneNumber from './formatPhoneNumber.helpers'
import {RecipientsList} from './Recipient/List'
import {RecipientRow} from './Recipient/Row'

import './RecipientsModal.styles.scss'

const RecipientsModalFooter = ({children}: {children: ReactNode}) => {
  return <div className='RecipientsModal-footer'>{children}</div>
}

const RecipientsModalFooterGroup = ({children}: {children: ReactNode}) => {
  return <div className='RecipientsModal-footer-group'>{children}</div>
}

type Props = Pick<PoshStyledModalProps, 'onClose' | 'isOpen'>

const RECIPIENTS_PER_PAGE = 8
const RecipientsModal = ({isOpen, onClose}: Props) => {
  const {groupId, blastId} = useParams()
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 400)
  const [page, setPage] = useState(0)
  const [searchedValues, setSearchedValues] = useState(new Set(''))
  const [listWasEdited, setListWasEdited] = useState(false)

  const {
    data: recipientsData,
    fetchNextPage,
    hasNextPage: queryHasNextPage,
    isFetchingNextPage: isFetchingNextPageOfRecipients,
    isRefetching: isRefetchingRecipients,
    isLoading: isLoadingRecipients,
    refetch: refetchRecipients,
  } = useSearchPendingTextBlastRecipients({
    pendingTextBlastId: blastId!,
    groupId: groupId!,
    limit: RECIPIENTS_PER_PAGE,
    search: debouncedSearchValue,
  })

  const queryClient = trpc.useContext()
  const {mutate: removeRecipient, isLoading: isRemovingRecipient} = useRemovePendingTextBlastRecipientFromSMSPage({
    onSettled: () => {
      const commonQueryKey = {pendingTextBlastId: blastId!, groupId: groupId!, limit: RECIPIENTS_PER_PAGE}
      const {searchPendingTextBlastRecipients: searchPendingTextBlastRecipientsCache} = queryClient.pendingTextBlasts

      searchPendingTextBlastRecipientsCache.setInfiniteData({...commonQueryKey, search: debouncedSearchValue}, data => {
        return {pages: data?.pages.slice(0, page + 1) ?? [], pageParams: data?.pageParams.slice(0, page + 1) ?? []}
      })
      refetchRecipients({refetchPage: (_, index) => index === page})

      for (const searchedValue of searchedValues) {
        if (searchedValue === debouncedSearchValue) continue
        searchPendingTextBlastRecipientsCache.invalidate({
          ...commonQueryKey,
          search: searchedValue,
        })
      }
      setListWasEdited(true)
    },
  })

  useEffect(() => {
    setSearchedValues(prev => new Set(prev).add(debouncedSearchValue))
    setPage(0)
  }, [debouncedSearchValue])

  const onClickClose = () => {
    setPage(0)
    setSearchValue('')
    if (listWasEdited) {
      queryClient.pendingTextBlasts.fetchPendingTextBlast.invalidate()
      setListWasEdited(false)
    }
    onClose()
  }

  const onChangeSearch = (newSearchQuery: string) => {
    setSearchValue(newSearchQuery)
  }

  const {trackEvent} = useMixpanel()
  const showLoader =
    isFetchingNextPageOfRecipients || isRefetchingRecipients || isRemovingRecipient || isLoadingRecipients
  const onClickRemove = (recipientId: string) => {
    if (showLoader) return

    trackEvent('Text Blast- Remove Recipient Click')
    removeRecipient({recipientAccountId: recipientId})
  }

  const onClickBack = () => {
    if (showLoader) return
    setPage(page - 1)
  }

  const nextPageHasData = recipientsData?.pages[page + 1] !== undefined
  const onClickForward = async () => {
    if (showLoader) return

    if (!nextPageHasData) {
      await fetchNextPage()
    }
    setPage(page + 1)
  }

  const hasPreviousPage = page > 0
  const hasNextPage = nextPageHasData || queryHasNextPage
  const totalRecipientsCount = recipientsData?.pages[0]?.totalRecipients ?? 0
  const currentPageRecipients = recipientsData?.pages[page]?.accounts ?? []
  return (
    <PoshStyledModal isOpen={isOpen} onClose={onClickClose} width={800} contentClassName='RecipientsModal'>
      <h3 className='center m-0'>View & Edit Recipients</h3>
      <div className='RecipientsModal-searchContainer'>
        <TextIconInput
          icon={searchIconUrl}
          placeholder='Search by Name, Phone Number'
          value={searchValue}
          onChange={e => onChangeSearch(e.target.value)}
          disabled={showLoader}
        />
        {showLoader && <SpinLoader />}
      </div>
      <RecipientsModal.List isLoading={isLoadingRecipients} numSkeleton={RECIPIENTS_PER_PAGE}>
        {currentPageRecipients.map(recipient => (
          <RecipientsModal.Row
            key={recipient.id}
            name={recipient.name}
            imageSrc={getAvi(recipient)}
            phoneNumber={formatPhoneNumber(recipient.phoneNumber)}
            sticker={
              <Button className='mediumDark' onClick={() => onClickRemove(recipient.id)}>
                Remove
              </Button>
            }
          />
        ))}
      </RecipientsModal.List>
      <RecipientsModal.Footer>
        <RecipientsModal.FooterGroup>
          <CircleBack
            className={classNames({clickable: hasPreviousPage, disabled_smscampaign: !hasPreviousPage})}
            onClick={hasPreviousPage ? onClickBack : void 0}
          />
          <CircleForward
            className={classNames({clickable: hasNextPage, disabled_smscampaign: !hasNextPage})}
            onClick={hasNextPage ? onClickForward : void 0}
          />
        </RecipientsModal.FooterGroup>
        {!searchValue && (
          <p className='m-0'>
            <strong>{totalRecipientsCount}</strong> Recipients
          </p>
        )}
      </RecipientsModal.Footer>
    </PoshStyledModal>
  )
}

RecipientsModal.List = RecipientsList
RecipientsModal.Row = RecipientRow
RecipientsModal.Footer = RecipientsModalFooter
RecipientsModal.FooterGroup = RecipientsModalFooterGroup
export default RecipientsModal
