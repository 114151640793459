import {FeatureFlag} from '@posh/model-types'
import useSessionContext from 'domains/Auth/SessionContext'
import {useFeatureGate} from 'hooks/useFeatureGate'
import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetAccountOptInsOptions = ReactQueryOptions['optIns']['getAccountOptIns']
export type GetAccountOptInsInput = RouterInputs['optIns']['getAccountOptIns']
export type GetAccountOptInsOutput = RouterOutput['optIns']['getAccountOptIns']

export function useGetAccountOptIns(input: GetAccountOptInsInput, opts?: GetAccountOptInsOptions) {
  return trpc.optIns.getAccountOptIns.useQuery(input, opts)
}

interface DisplayGroupOptIn {
  groupId: string
}

export function useDisplayGroupOptIn(input: DisplayGroupOptIn) {
  const {userId} = useSessionContext()
  const isSmsOptInsEnabled = useFeatureGate(FeatureFlag.SMS_OPT_INS)
  const {data: orgOptInsResponse, refetch: refetchOrgOptIns} = useGetAccountOptIns(
    {groupID: input.groupId},
    {enabled: !!userId},
  )
  const {data: accountOptInsResponse, refetch: refetchAccountOptIns} = useGetAccountOptIns({}, {enabled: !!userId})
  const orgOptInData = orgOptInsResponse?.data[0]
  const accountOptInData = accountOptInsResponse?.data[0]

  const isOptedInToGroupSms = orgOptInData?.optInType === 'group-sms' && orgOptInData?.optIns?.smsOptIn === 'yes'
  const isOptedInToPoshSms =
    accountOptInData?.optInType === 'posh-sms' && accountOptInData?.optIns?.marketing?.smsOptIn === 'yes'

  // We display the opt in checkbox if the user is opted out of group sms or posh sms
  const shouldDisplayOptInForm = (!isOptedInToGroupSms || !isOptedInToPoshSms) && isSmsOptInsEnabled

  const refetchOptIns = () => {
    refetchOrgOptIns()
    refetchAccountOptIns()
  }
  return {shouldDisplayOptInForm, refetchOptIns}
}
