import React from 'react'

import {Button} from '@posh/design-kit/components/button'
import {useMultiSessionEventContext} from 'pages/GroupPage/ManageMultiSessionEvent/context/ManageMultiSessionEventPagesContext'

interface EditMultiSessionEventButtonsProps {
  isUpdating: boolean
  onDiscard: () => void
  onSave: () => void
  isDirty: boolean
  isEditing: boolean
  toggleEditing: (v: boolean) => void
}

export function EditMultiSessionEventButtons(props: EditMultiSessionEventButtonsProps) {
  const {isUpdating, onDiscard, onSave, isDirty, isEditing, toggleEditing} = props

  const {formDirtySafeNavigate} = useMultiSessionEventContext()

  if (isEditing) {
    return (
      <>
        <Button
          className='rounded-full border border-input font-[Inter] font-light'
          variant={'secondary'}
          size='sm'
          disabled={isUpdating}
          onClick={isDirty ? onDiscard : () => toggleEditing(false)}>
          {isDirty ? 'Discard' : 'Done'}
        </Button>
        <Button
          className='rounded-full border border-input font-[Inter] font-light'
          disabled={!isDirty}
          onClick={onSave}
          size='sm'>
          Save
        </Button>
      </>
    )
  }
  return (
    <>
      <Button
        className='rounded-full border border-input font-[Inter] font-light'
        size='sm'
        onClick={() => toggleEditing(true)}>
        Edit Series
      </Button>
      <Button
        className='rounded-full border border-input font-[Inter] font-light'
        variant={'secondary'}
        size='sm'
        onClick={() => formDirtySafeNavigate('./analytics')}>
        View Analytics
      </Button>
    </>
  )
}
