import React from 'react'

import {isAllowedTapToPayCountry} from '@posh/model-types'
import {ICrossSection, useGetSectionalData} from 'apis/Analytics/useGetSectionalData'
import {useGetGroupCountryForTapToPay} from 'apis/Groups/useGetGroupForTapToPay'
import {useEventManagementContext} from 'pages/EventManagementPages/EventManagementContext'

import {CrossSection} from '../CrossSection/CrossSection'
import {Text} from '../Shared/Text/Text'
import {parseNumberValue} from '../Shared/utils'

interface SummaryProps {
  eventId: string
  groupId: string
  isLoading?: boolean
}

const getLabel = (dataPointType: ICrossSection['type'], label?: string) => {
  if (!label) return null

  if (label.includes('From Explore Page:') || label.includes('In-Person:')) {
    const prefix = label.includes('From Explore Page:') ? 'From Explore Page:' : 'In-Person:'
    return (
      <Text size='small'>
        {prefix}
        <Text size='small' style={{color: '#00ffa3', fontWeight: 700}}>
          {label.split(':')[1]}
        </Text>
      </Text>
    )
  }

  if (dataPointType === 'inPersonGMV') {
    // match the part of the label that contains the percentage of total revenue
    const percentageMatch = label.match(/(\d+\.\d+)%/m)
    if (percentageMatch) {
      return (
        <Text size='small'>
          <Text size='small' style={{color: '#00ffa3', fontWeight: 700}}>
            {percentageMatch[1]}%{' '}
          </Text>
          of total Revenue
        </Text>
      )
    }
  }

  return <Text size='small'>{label}</Text>
}

export const Summary = (props: SummaryProps) => {
  const {eventId, groupId} = props

  const {event} = useEventManagementContext()
  const isRSVPEvent = event.isRSVPEvent
  const {data: groupCountry} = useGetGroupCountryForTapToPay({groupId, eventId})

  const {data, isLoading, isError} = useGetSectionalData(
    {
      eventId,
      groupId,
      dataPoints: isRSVPEvent
        ? ['totalRSVP', 'inPersonGMV', 'totalPageVisits', 'conversion']
        : [
            'totalGmvOverview',
            'inPersonGMV',
            'totalTicketsSold',
            'paymentInstallments',
            'customFees',
            'totalPageVisits',
            'conversion',
          ],
    },
    {
      enabled: props.isLoading !== true,
    },
  )

  if (props.isLoading || isLoading) {
    return (
      <CrossSection.Section>
        {[...Array(5)].map((_, index) => (
          <CrossSection key={index}>
            <CrossSection.Row>
              <Text.Loading width={150} />
            </CrossSection.Row>
            <CrossSection.Row>
              <Text.Loading size='xl' width={100} />
            </CrossSection.Row>
            <CrossSection.Row>
              <Text.Loading size='small' />
            </CrossSection.Row>
          </CrossSection>
        ))}
      </CrossSection.Section>
    )
  }

  if (isError) {
    return (
      <CrossSection.Section style={{display: 'flex', width: '100%', height: 136, justifyContent: 'center'}}>
        <CrossSection>
          <CrossSection.Row>
            <Text size='xl' bold>
              Error
            </Text>
          </CrossSection.Row>
          <CrossSection.Row>
            <Text>Something went wrong. Contact support@posh.vip for help.</Text>
          </CrossSection.Row>
        </CrossSection>
      </CrossSection.Section>
    )
  }

  return (
    <CrossSection.Section>
      {data.map((crossSection, index) => {
        if (
          (crossSection.type === 'totalGmvOverview' || crossSection.type === 'inPersonGMV') &&
          crossSection.mainValue?.type === 'currency' &&
          crossSection.mainValue?.value === 0
        ) {
          return null
        }

        if (crossSection.type === 'totalTicketsSold' && !isAllowedTapToPayCountry(groupCountry)) {
          crossSection.label = 'Total'
        }

        if (crossSection.type === 'inPersonGMV') {
          const totalGMV = data.find(section => section.type === 'totalGmvOverview')
          if (totalGMV?.mainValue?.value && crossSection.mainValue?.value) {
            crossSection.label = `${((crossSection.mainValue.value / totalGMV.mainValue.value) * 100).toFixed(2)}%`
          }
        }

        return (
          <CrossSection key={index}>
            <CrossSection.Row>
              <Text>{crossSection.title}</Text>
            </CrossSection.Row>
            <CrossSection.Row>
              <Text size='xl' bold>
                {parseNumberValue(crossSection.mainValue)}
              </Text>
            </CrossSection.Row>
            <CrossSection.Row>{getLabel(crossSection.type, crossSection.label)}</CrossSection.Row>
          </CrossSection>
        )
      })}
    </CrossSection.Section>
  )
}
