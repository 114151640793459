import React, {useState} from 'react'

import {useDeleteAccountRole} from 'apis/Roles/useDeleteAccountRole'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import useSessionContext from 'domains/Auth/SessionContext'

import useTeamManagementModalContext from '../TeamManagementModal/TeamManagementModalContext'

import './styles.scss'

export default function DeleteTeamMember() {
  const {userId} = useSessionContext()
  const {teamMemberToDelete, scope, setSuccessMessage, setErrorMessage, isLoading, setIsLoading, closeModal, reset} =
    useTeamManagementModalContext()
  const {mutateAsync: deleteAccountRole} = useDeleteAccountRole({
    onSuccess: () => {
      setSuccessMessage(
        `Successfully removed ${teamMemberToDelete?.firstName} as a ${teamMemberToDelete?.accountRole.roleKey} for your ${scope}`,
      )
      setIsRemoved(true)
    },
    onError: error => {
      setErrorMessage(`There was an error updating permissions for ${teamMemberToDelete?.firstName}: ${error.message}`)
    },
    onSettled: () => {
      setIsLoading(false)
    },
  })
  const [isRemoved, setIsRemoved] = useState(false)

  if (!teamMemberToDelete) return null

  const handleDeleteTeamMember = async () => {
    setIsLoading(true)
    setErrorMessage('')
    setSuccessMessage('')
    const params = {
      accountRoleId: teamMemberToDelete.accountRole._id,
      assignedBy: userId!,
    }
    deleteAccountRole(params)
  }

  const RemoveGroupScopedMemberWarning = () => {
    if (teamMemberToDelete.accountRole.scope !== 'group') return null
    return (
      <p className='DeleteTeamModal-warning'>
        {teamMemberToDelete.firstName} is a group scoped {teamMemberToDelete.accountRole.roleKey}. Removing them would
        mean that they would also be removed from all other events
      </p>
    )
  }

  return (
    <>
      <h3 className='DeleteTeamModal-title'>
        Are you sure you want to remove {teamMemberToDelete.firstName + ' ' + teamMemberToDelete.lastName} as a{' '}
        {teamMemberToDelete.accountRole.roleKey} for your {scope}?
      </h3>
      <RemoveGroupScopedMemberWarning />
      <div style={{marginTop: '10px'}} className='DeleteTeamModal-bottomInputWrapper'>
        {isLoading ? (
          <SpinLoader height={30} />
        ) : (
          <>
            {!isRemoved && (
              <>
                <Button
                  className='light dark'
                  onClick={() => {
                    reset()
                    closeModal && closeModal()
                  }}>
                  Cancel
                </Button>
                <Button className='gold light' onClick={handleDeleteTeamMember}>
                  Remove Team Member
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}
