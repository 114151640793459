import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface UpdateFormChanges {
  requireApproval: boolean
  closeRsvps: boolean
  enableReceipts: boolean
  totalSpotsClaimed: number
  totalSpots: number
}

export async function setRsvpSettings(eventId: string, body: UpdateFormChanges) {
  const response = await PoshApi.setRsvpSettings(eventId, body)
  return response.data
}

export function useSetRsvpEventSettings() {
  return useMutation((params: {eventId: string; updateData: UpdateFormChanges}) =>
    setRsvpSettings(params.eventId, params.updateData),
  )
}
