import React, {useEffect, useState} from 'react'

import {Ticket} from 'apis/Events/types'
import {kickbackEquationOptions, kickbackTypeOptions} from 'apis/Kickback/KickbackOffer'
import {CreateKickbackOfferInput, useCreateKickBackOffer} from 'apis/Kickback/useCreateKickBackOffer'
import {FetchAffiliatesResponse} from 'apis/Kickback/useFetchAffiliates'
import {useMixpanel} from 'apis/MixPanelHandler'
import {addUserFilled} from 'components/assets/Icons'
import {NumberInput} from 'components/DynamicForm/Inputs/NumberInput/NumberInput'
import {Select} from 'components/DynamicForm/Inputs/Select/Select'
import Button from 'components/form/Button'
import {sanitizeNumber} from 'components/form/Input'
import {PoshImage} from 'components/PoshImage/PoshImage'
import RequirePermissions from 'components/RequirePermissions'
import {useToast} from 'components/toasts/ToastProvider'
import pluralize from 'helpers/pluralize'
import {capitalize} from 'lodash'
import Multiselect from 'multiselect-react-dropdown'
import multiSelectStyles from 'pages/GroupPage/Marketing/Filter/multi-select-styles'

import {AFFILIATE_KICKBACK_EXPLANATION, PUBLIC_ATTENDEE_KICKBACK_EXPLANATION} from '../constants'
import {KickbackOfferType} from '../types'

import './styles.scss'

type NewKickback = Omit<CreateKickbackOfferInput, 'value'> & {value?: number}

interface AddKickBackOfferProps {
  tickets: Ticket[]
  refetch?: () => void
  eventId?: string
  setIsAddingKickBack: React.Dispatch<React.SetStateAction<KickbackOfferType | undefined>>
  offerType: KickbackOfferType
  setIsShowingAddAffiliate: React.Dispatch<React.SetStateAction<boolean>>
  existingAffiliates: FetchAffiliatesResponse
}

export const AddKickBackOffer = (props: AddKickBackOfferProps) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {tickets, refetch, eventId, setIsAddingKickBack, offerType, setIsShowingAddAffiliate, existingAffiliates} =
    props
  const [newKickBack, setNewKickBack] = useState<NewKickback>({
    entity: eventId!,
    entityType: 'event',
    value: undefined,
    type: 'flat',
    equation: 'perOrder',
    enabled: true,
    limitedTo: [],
  })
  const {mutateAsync: createKickBackOffer, isLoading} = useCreateKickBackOffer()

  const minOrderValue = tickets?.reduce(
    (acc, ticket) => {
      const {price, purchaseMin = 1, name} = ticket
      if (price === 0) return acc
      const candidate = price * purchaseMin
      if (candidate >= acc.value) return acc
      return {
        value: Math.min(acc.value, candidate),
        name,
        price,
        purchaseMin,
      }
    },
    {value: Infinity, name: undefined, price: Infinity, purchaseMin: 1} as {
      value: number
      name: string | undefined
      price: number
      purchaseMin: number
    },
  ) ?? {value: Infinity, name: undefined, price: Infinity, purchaseMin: 1}

  const flatFeePerOrderValueGTMinOrderValue =
    newKickBack.type === 'flat' && newKickBack.equation === 'perOrder' && (newKickBack.value ?? 0) > minOrderValue.value
  const isOnlyFreeTickets = minOrderValue.value === Infinity

  const {showToast} = useToast()
  useEffect(() => {
    if (newKickBack.equation === 'perOrder') setNewKickBack({...newKickBack, appliesTo: undefined})
    else setNewKickBack({...newKickBack, appliesTo: tickets[0].id})
  }, [newKickBack.equation])

  const handleCreation = async () => {
    try {
      trackMixpanelEvent('Create Kickback Offer-  Curator Event Kickback Page', newKickBack)
      if (newKickBack.value === undefined)
        return showToast({type: 'error', title: 'Please enter a value for your kickback offer'})

      if (offerType === 'affiliate' && !newKickBack.limitedTo?.length) {
        return showToast({type: 'error', title: 'Please select at least one affiliate'})
      }
      // handleCreation button is disabled if value is undefined so this ! is safe here
      if (newKickBack.type === 'percentage' && newKickBack.value! > 100) {
        return showToast({type: 'error', title: 'Percentage cannot be greater than 100 on a Kickback offer'})
      }

      // weird looking spread here to get typescript to coop
      await createKickBackOffer({...newKickBack, value: newKickBack.value})
      if (refetch) refetch()
      setIsAddingKickBack(undefined)
    } catch (err) {
      showToast({type: 'error', title: err.message})
    }
  }

  const offerTypeBeneficiary = offerType === 'affiliate' ? 'affiliate' : 'attendee'

  return (
    <>
      <div className='AddKickBack'>
        <div className='AddKickBack-backBtn'>
          <PoshImage
            src='https://images.posh.vip/b2/left-arrow-in-circular-button-black-symbol.svg'
            onClick={() => setIsAddingKickBack(undefined)}
            className='AddKickBack-backBtn'
          />
        </div>
        <div className='AddKickBack-section'>
          <h3>Create {capitalize(offerType)} Kickback Offer</h3>
          <p className='m-0'>
            {offerType === 'affiliate' ? AFFILIATE_KICKBACK_EXPLANATION : PUBLIC_ATTENDEE_KICKBACK_EXPLANATION}
          </p>
          <p className='m-0'>
            Below, configure the commission amount {pluralize(offerTypeBeneficiary, 2)} will receive for each purchase
            through their link. Commission is paid out instantly to a debit card the {offerTypeBeneficiary} has
            connected.
          </p>
          <div className='AddKickBack-inputs'>
            <div className='AddKickBack-inputSection'>
              <label>Price Per Kickback</label>
              <p>This is the amount each Kickback {offerTypeBeneficiary} will make per order they sell</p>
              <div className='AddKickBack-priceInputWrapper'>
                <Select
                  onChange={v => setNewKickBack({...newKickBack, type: v})}
                  value={newKickBack.type}
                  options={kickbackTypeOptions}
                />
                <NumberInput
                  placeholder={newKickBack.type === 'flat' ? 'Price' : 'Percent'}
                  value={newKickBack.value}
                  onChange={v => setNewKickBack({...newKickBack, value: sanitizeNumber(v)})}
                />
              </div>
            </div>
            <div className='AddKickBack-inputSection'>
              <label>Applies</label>
              <p>Choose what the price of your Kickback gets deducted from</p>
              <Select
                onChange={v => setNewKickBack({...newKickBack, equation: v})}
                value={newKickBack.equation}
                options={kickbackEquationOptions}
              />
            </div>
            {newKickBack?.equation !== 'perOrder' && (
              <div className='AddKickBack-inputSection'>
                <label>Limited to Ticket Type</label>
                <Select
                  onChange={v => setNewKickBack({...newKickBack, appliesTo: v})}
                  value={newKickBack.appliesTo}
                  options={tickets.map(t => ({
                    label: t.name,
                    value: t.id,
                  }))}
                />
              </div>
            )}
          </div>
          {offerType === 'affiliate' && (
            <>
              <p className='m-0'>Choose which specific affiliates you want this offer limited to</p>
              <div className='AddKickBack-add-affiliates'>
                {existingAffiliates && existingAffiliates.length > 0 && (
                  <Multiselect
                    selectedValues={newKickBack.limitedTo?.map(id => ({
                      name: existingAffiliates.find(affiliate => affiliate.affiliateId === id)?.firstName,
                      id,
                    }))}
                    options={existingAffiliates.map(affiliate => ({
                      name: affiliate.firstName + ' ' + affiliate.lastName,
                      id: affiliate.affiliateId,
                    }))}
                    onSelect={value =>
                      setNewKickBack({...newKickBack, limitedTo: value.map((v: {id: string}) => v.id)})
                    }
                    onRemove={value =>
                      setNewKickBack({...newKickBack, limitedTo: value.map((v: {id: string}) => v.id)})
                    }
                    displayValue='name'
                    style={multiSelectStyles}
                    closeOnSelect={true}
                    placeholder={'Select Existing Event Affiliates'}
                  />
                )}
                <RequirePermissions
                  requiredPermissions={[
                    {
                      permissionKey: 'add_affiliate',
                      applicableScopes: ['event', 'group'],
                    },
                  ]}>
                  <Button onClick={() => setIsShowingAddAffiliate(true)}>
                    <PoshImage src={addUserFilled} style={{filter: 'brightness(0.1)'}} />
                    Invite Affiliates
                  </Button>
                </RequirePermissions>
              </div>
            </>
          )}
          <Button
            onClick={handleCreation}
            disabled={
              !newKickBack.value ||
              isLoading ||
              (offerType === 'affiliate' && newKickBack.limitedTo?.length == 0) ||
              flatFeePerOrderValueGTMinOrderValue ||
              isOnlyFreeTickets
            }
            style={{maxWidth: '311px'}}>
            Create Kickback Offer
          </Button>
          {isOnlyFreeTickets && (
            <div className='AddKickBack-inputSection'>
              <p style={{color: 'red'}}>
                You cannot make a per-order kickback offer for an event with only free tickets.
              </p>
            </div>
          )}
          {newKickBack?.equation === 'perOrder' && newKickBack?.type === 'flat' && !isOnlyFreeTickets && (
            <div className='AddKickBack-inputSection'>
              <p style={{color: flatFeePerOrderValueGTMinOrderValue ? 'red' : 'grey'}}>
                Flat-fee, per-order Kickback offers must be less than or equal to the minimum order value: $
                {minOrderValue.value?.toFixed(2)} (Ticket &apos;{minOrderValue.name}&apos; with price $
                {minOrderValue.price.toFixed(2)}{' '}
                {minOrderValue.purchaseMin > 1 ? `and purchase-minimum ${minOrderValue.purchaseMin}` : ''})
              </p>
            </div>
          )}
          {newKickBack.type === 'flat' && (
            <div className='AddKickBack-inputSection'>
              <p style={{color: 'grey'}}>
                If an attendee makes a purchase for less than the offer amount (e.g. by using a promo code), the full
                amount of the order will be paid out to this offer.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
