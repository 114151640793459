import React, {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {isAllowedTapToPayCountry} from '@posh/model-types'
import {TapToPayNotSetup, TapToPayState} from '@posh/types'
import useDeleteGroupCustomFee from 'apis/Groups/useDeleteGroupCustomFee'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {useGetGroupForFinancials} from 'apis/Groups/useGetGroupForFinancials'
import {useGetGroupForTapToPay} from 'apis/Groups/useGetGroupForTapToPay'
import {StripeBankAccount, useGetGroupPayouts} from 'apis/Groups/useGetGroupPayouts'
import {useMixpanel} from 'apis/MixPanelHandler'
import {useCheckPermissions} from 'apis/Permissions/useHasPermissionsEnabled'
import {useUpdateGroupTapToPayState} from 'apis/TapToPay/useUpdateGroupTapToPayState'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import ConfirmationModal from 'components/modals/Modal/ConfirmationModal'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {trpc} from 'lib/trpc'

import RequirePermissions, {requirePermissionAndScope} from '../../../components/RequirePermissions'
import GroupPageSubNav from '../SubNav'
import {AccountStatusCard} from './AccountStatusCard'
import AddBankModal from './AddBankModal'
import {AddCustomFeesModal} from './AddCustomFeesModal'
import CustomFees from './CustomFees'
import EditBankModal from './EditBankModal'
import {EditCustomFeeModal} from './EditCustomFeeModal'
import BalancesCard from './PayoutCards/BalancesCard'
import BanksCard from './PayoutCards/BanksCard'
import {FinanceCard} from './PayoutCards/FinanceCard'
import SettingsCard from './PayoutCards/SettingsCard'
import ViewPayoutsCard from './PayoutCards/ViewPayoutsCard'
import TransferBalanceModal from './TransferBalanceModal'

import './styles.scss'

export interface CardProps {
  title: string
}

// TODO: add skeletons for cards
// TODO: match mobile web view order to mobile app (payouts closer to top)
const Finance = () => {
  const {groupId} = useGroupMatchParams()
  const {trackEvent} = useMixpanel()
  const navigate = useNavigate()
  const {data: groupObj} = useFetchGroup(groupId)

  const search = useLocation().search

  const {data: group, isLoading: isLoadingGroup} = useGetGroupForFinancials({groupId: groupId!}, {enabled: !!groupId})
  const {mutateAsync: deleteGroupFee} = useDeleteGroupCustomFee()

  const [startingAfter, setStartingAfter] = useState<string>('')
  const [endingBefore, setEndingBefore] = useState<string>('')
  const {
    data: payouts,
    isFetching: isFetchingPayouts,
    isLoading: isLoadingPayouts,
    refetch: refetchGroupPayouts,
    isError,
  } = useGetGroupPayouts(
    {
      groupId: groupId!,
      startingAfter,
      endingBefore,
    },
    {keepPreviousData: true, enabled: !!groupId},
  )

  // Then we check their group edit permissions (if the feature flag is enabled)
  const {hasPermission: hasEditGroupSettingsPermission, isLoading: isLoadingEditGroupSettingsPermission} =
    useCheckPermissions(['edit_group_settings'], groupId!, undefined, {
      staleTime: 60 * 1000,
      enabled: !!groupId && isAllowedTapToPayCountry(group?.country),
    })
  // Then we get the tap to pay data (if the feature flag was enabled)
  const {
    data: groupTapToPayData,
    isLoading: isLoadingGroupTapToPay,
    refetch: refetchGroupTapToPay,
  } = useGetGroupForTapToPay({groupId: groupId!}, {enabled: !!groupId && isAllowedTapToPayCountry(group?.country)})

  //TODO: change these modals to be side panels bc modern system?
  const [payoutsListPage, setPayoutsListPage] = useState(1)
  const [isShowingTransferBalance, setIsShowingTransferBalance] = useState(false)

  const [isShowingEditBankAccount, setIsShowingEditBankAccount] = useState(false)
  const [isDeletingBankAccount, setIsDeletingBankAccount] = useState(false)
  const [editingBankAccount, setEditingBankAccount] = useState<StripeBankAccount | null>(null)
  const [addingBankAccount, setIsAddingBankAccount] = useState(false)
  const [isShowingEditBankAccountModal, setIsShowingEditBankAccountModal] = useState(false)

  const [isShowingAddCustomFees, setIsShowingAddCustomFees] = useState(false)
  const [feeToEdit, setFeeToEdit] = useState('')
  const [feeToDelete, setFeeToDelete] = useState('')
  const {mutate: updateGroupTapToPayState} = useUpdateGroupTapToPayState({
    onSuccess: () => {
      refetchGroupTapToPay()
    },
  })

  const trpcQueryClient = trpc.useContext()

  if (!group || isLoadingGroup || isLoadingPayouts)
    return (
      <div className='Payouts-loader'>
        <SpinLoader height={100} />
      </div>
    )

  const hasAccessToInstantPayouts = payouts?.bankAccounts.some(account => account.availableForInstantPayouts) ?? false
  const handleOnClickDisputes = () => {
    trackEvent('Check Disputes- Curator Finance Page')
    navigate(`/owner/groups/${groupId}/disputes` + search)
  }

  const handleGroupTapToPayStateChanged = (tapToPayState: TapToPayState) => {
    updateGroupTapToPayState({
      groupId: groupId!,
      tapToPayState,
    })
  }

  return (
    <>
      <div>
        <PoshHelmet title={`Finance - ${group?.name}`} />
        <div className='FinancePage GroupPage-content'>
          <GroupPageSubNav group={groupObj} hierarchy={['Finance']} />
          <div className='GroupPage-cols'>
            <div className='small'>
              <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_payouts', ['group'])]}>
                <AccountStatusCard
                  canInitiatePayouts={group.canInitiatePayouts}
                  canAcceptPayments={group.canAcceptPayments}
                  needsStripeOnboarding={group.needsStripeOnboarding}
                  currentOnboardingRequirements={group.currentOnboardingRequirements}
                  hasBankAccount={payouts?.bankAccounts.some(account => account.object === 'bank_account') ?? false}
                  pendingDocumentsVerification={group.isPendingDocumentVerification ?? false}
                  groupId={groupId!}
                  groupCountry={group.country ?? 'US'}
                  hasTicketSales={!!group.gmv}
                  onClickAddBankAccount={() => setIsAddingBankAccount(true)}
                  tapToPayProps={{
                    renderTapToPaySlider: !isLoadingEditGroupSettingsPermission && !isLoadingGroupTapToPay,
                    tapToPayGroupState: groupTapToPayData?.tapToPayState ?? TapToPayNotSetup,
                    onChangeGroupTapToPayState: handleGroupTapToPayStateChanged,
                    hasEditGroupSettingsPermission: hasEditGroupSettingsPermission,
                  }}
                />
              </RequirePermissions>
              <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_current_balance', ['group'])]}>
                {payouts && (
                  <BalancesCard
                    balances={{
                      availableBalance: payouts.formattedAvailableBalance,
                      pendingBalance: payouts.formattedPendingBalance,
                      instantAvailableBalance: payouts.instantAvailableBalance,
                      instantAvailableFormatBalance: payouts.instantAvailableFormatBalance,
                    }}
                    group={group}
                    setIsShowingTransferBalance={setIsShowingTransferBalance}
                  />
                )}
              </RequirePermissions>
              <RequirePermissions requiredPermissions={[requirePermissionAndScope('add_bank_account', ['group'])]}>
                <SettingsCard
                  statementDescriptor={payouts?.statementDescriptor}
                  group={group}
                  autoPayouts={!group.manualPayouts}
                />
              </RequirePermissions>

              <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_payouts', ['group'])]}>
                <BanksCard
                  //TODO: move bank modal state into here
                  bankAccounts={payouts?.bankAccounts ?? []}
                  showAddBankModal={() => setIsAddingBankAccount(true)}
                  isShowingEditBankAccount={isShowingEditBankAccount}
                  setEditingBankAccount={setEditingBankAccount}
                  setIsDeletingBankAccount={setIsDeletingBankAccount}
                  setIsShowingEditBankAccount={setIsShowingEditBankAccount}
                  setIsShowingEditBankAccountModal={setIsShowingEditBankAccountModal}
                />
              </RequirePermissions>
              <FinanceCard title='Custom Fees' animationIndex={5}>
                <CustomFees
                  setFeeToDelete={setFeeToDelete}
                  setFeeToEdit={setFeeToEdit}
                  setIsShowingAddCustomFees={setIsShowingAddCustomFees}
                  fees={group.defaultFees}
                  currency={group.currency ?? 'USD'}
                />
              </FinanceCard>
              <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_disputes', ['group'])]}>
                <FinanceCard title={'Disputes'} animationIndex={6}>
                  <Button className='fullWidth' onClick={handleOnClickDisputes}>
                    Check Disputes
                  </Button>
                </FinanceCard>
              </RequirePermissions>
            </div>

            <div className='expand'>
              <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_payouts', ['group'])]}>
                <ViewPayoutsCard
                  page={payoutsListPage}
                  setPage={setPayoutsListPage}
                  formattedPayouts={payouts?.payoutsFormatted}
                  isFetching={isFetchingPayouts}
                  setStartingAfter={setStartingAfter}
                  setEndingBefore={setEndingBefore}
                  isError={isError}
                />
              </RequirePermissions>
            </div>

            <RequirePermissions
              requiredPermissions={[requirePermissionAndScope('transfer_current_balance', ['group'])]}>
              {payouts && (
                <TransferBalanceModal
                  balances={{
                    availableBalance: payouts.availableBalance,
                    availableBalanceFormatted: payouts.formattedAvailableBalance,
                    instantAvailableBalanceFormatted: payouts.instantAvailableFormatBalance,
                    instantBalance: payouts.instantAvailableBalance,
                  }}
                  hasInstantPayout={hasAccessToInstantPayouts}
                  group={group}
                  isOpen={isShowingTransferBalance}
                  onClose={() => setIsShowingTransferBalance(false)}
                />
              )}
            </RequirePermissions>

            <RequirePermissions requiredPermissions={[requirePermissionAndScope('add_bank_account', ['group'])]}>
              {editingBankAccount && (
                <EditBankModal
                  refetchPayouts={refetchGroupPayouts}
                  groupId={groupId!}
                  account={editingBankAccount}
                  isDeleting={isDeletingBankAccount}
                  isOpen={isShowingEditBankAccountModal}
                  onClose={() => {
                    setIsShowingEditBankAccountModal(false)
                    setIsShowingEditBankAccount(false)
                  }}
                />
              )}
              {addingBankAccount && (
                <AddBankModal
                  group={group}
                  isOpen={addingBankAccount}
                  refetchPayouts={refetchGroupPayouts}
                  onClose={() => setIsAddingBankAccount(false)}
                />
              )}
            </RequirePermissions>
            <AddCustomFeesModal
              width={600}
              currency={group.currency ?? 'USD'}
              scope='group'
              isOpen={isShowingAddCustomFees}
              onClose={() => {
                trpcQueryClient.groups.getGroupForFinancials.invalidate()
                setIsShowingAddCustomFees(false)
              }}
            />
            {group.defaultFees && (
              <>
                <EditCustomFeeModal
                  width={600}
                  isOpen={feeToEdit !== ''}
                  onClose={() => {
                    trpcQueryClient.groups.getGroupForFinancials.invalidate()
                    setFeeToEdit('')
                  }}
                  fee={feeToEdit}
                  currency={group.currency ?? 'USD'}
                  allFees={group.defaultFees}
                  scope='group'
                />
                <ConfirmationModal
                  isOpen={feeToDelete !== ''}
                  onClose={() => {
                    trpcQueryClient.groups.getGroupForFinancials.invalidate()
                    setFeeToDelete('')
                  }}
                  title={`Are you sure you want to delete ${feeToDelete}?`}
                  errorMessage={`Cound not delete ${feeToDelete}`}
                  onClick={async () => {
                    await deleteGroupFee({groupId: groupId!, name: feeToDelete})
                  }}
                  bodyText={`This will delete ${feeToDelete} from the group. All events will be affected. This action cannot be undone.`}
                  confirmButtonText={'Delete Fee'}
                  confirmationMessage={`Successfully deleted fee`}
                />
              </>
            )}
          </div>

          <RequirePermissions requiredPermissions={[requirePermissionAndScope('transfer_current_balance', ['group'])]}>
            {payouts && (
              <TransferBalanceModal
                balances={{
                  availableBalance: payouts.availableBalance,
                  availableBalanceFormatted: payouts.formattedAvailableBalance,
                  instantAvailableBalanceFormatted: payouts.instantAvailableFormatBalance,
                  instantBalance: payouts.instantAvailableBalance,
                }}
                hasInstantPayout={hasAccessToInstantPayouts}
                group={group}
                isOpen={isShowingTransferBalance}
                onClose={() => setIsShowingTransferBalance(false)}
              />
            )}
          </RequirePermissions>

          <RequirePermissions requiredPermissions={[requirePermissionAndScope('add_bank_account', ['group'])]}>
            {editingBankAccount && (
              <EditBankModal
                refetchPayouts={refetchGroupPayouts}
                groupId={groupId!}
                account={editingBankAccount}
                isDeleting={isDeletingBankAccount}
                isOpen={isShowingEditBankAccountModal}
                onClose={() => {
                  setIsShowingEditBankAccountModal(false)
                  setIsShowingEditBankAccount(false)
                }}
              />
            )}
            {addingBankAccount && (
              <AddBankModal
                group={group}
                isOpen={addingBankAccount}
                refetchPayouts={refetchGroupPayouts}
                onClose={() => setIsAddingBankAccount(false)}
              />
            )}
          </RequirePermissions>
          <AddCustomFeesModal
            width={600}
            currency={group.currency ?? 'USD'}
            scope='group'
            isOpen={isShowingAddCustomFees}
            onClose={() => {
              trpcQueryClient.groups.getGroupForFinancials.invalidate()
              setIsShowingAddCustomFees(false)
            }}
          />
          {group.defaultFees && (
            <>
              <EditCustomFeeModal
                width={600}
                isOpen={feeToEdit !== ''}
                onClose={() => {
                  trpcQueryClient.groups.getGroupForFinancials.invalidate()
                  setFeeToEdit('')
                }}
                fee={feeToEdit}
                currency={group.currency ?? 'USD'}
                allFees={group.defaultFees}
                scope='group'
              />
              <ConfirmationModal
                isOpen={feeToDelete !== ''}
                onClose={() => {
                  trpcQueryClient.groups.getGroupForFinancials.invalidate()
                  setFeeToDelete('')
                }}
                title={`Are you sure you want to delete ${feeToDelete}?`}
                errorMessage={`Cound not delete ${feeToDelete}`}
                onClick={async () => {
                  await deleteGroupFee({groupId: groupId!, name: feeToDelete})
                }}
                bodyText={`This will delete ${feeToDelete} from the group. All events will be affected. This action cannot be undone.`}
                confirmButtonText={'Delete Fee'}
                confirmationMessage={`Successfully deleted fee`}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Finance
