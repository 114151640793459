import {useState} from 'react'

import {useTooltip} from 'components/Tooltip'
import Tooltip from 'components/Tooltip'

interface Evidence {
  uncategorizedText: string
  productDescription: string
  refundRefusalExplanation: string
  customerCommunicationFile?: File | null
  refundPolicyFile?: File | null
  additionalEvidenceFile?: File | null
}

interface EvidenceGuidanceProps {
  reason: string
  evidence?: Evidence
  onEvidenceChange?: (evidence: Evidence) => void
}

const EvidenceGuidance = ({reason, evidence, onEvidenceChange}: EvidenceGuidanceProps) => {
  const {referenceProps: tooltipProps1, tooltipProps: tooltipContent1} = useTooltip()
  const {referenceProps: tooltipProps2, tooltipProps: tooltipContent2} = useTooltip()
  const {referenceProps: tooltipProps3, tooltipProps: tooltipContent3} = useTooltip()
  const {referenceProps: tooltipProps4, tooltipProps: tooltipContent4} = useTooltip()

  const [formState, setFormState] = useState<Evidence>({
    uncategorizedText: evidence?.uncategorizedText || '',
    productDescription: evidence?.productDescription || '',
    refundRefusalExplanation: evidence?.refundRefusalExplanation || '',
    customerCommunicationFile: evidence?.customerCommunicationFile || null,
    refundPolicyFile: evidence?.refundPolicyFile || null,
    additionalEvidenceFile: evidence?.additionalEvidenceFile || null,
  })

  const handleInputChange = (field: keyof Evidence, value: string | File | null) => {
    const newState = {
      ...formState,
      [field]: value,
    }
    setFormState(newState)
    onEvidenceChange?.(newState)
  }

  const handleFileChange = (field: keyof Evidence) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null
    handleInputChange(field, file)
  }

  const getEvidenceGuidance = (reason: string) => {
    switch (reason.toLowerCase()) {
      case 'product_not_received':
        return (
          <div className='list-disc space-y-2 pl-4'>
            <div className='text-gray-300'>
              • Screenshots of communication where attendee acknowledges receiving the ticket/event access
            </div>
            <div className='text-gray-300'>• Proof of refund already issued (e.g., confirmation email)</div>
            <div className='text-gray-300'>• Note if attendee didn&apos;t contact you before filing the dispute</div>
          </div>
        )
      case 'fraudulent':
        return (
          <div className='list-disc space-y-2 pl-4'>
            <div className='text-gray-300'>• Screenshots of communication where attendee acknowledges the charge</div>
            <div className='text-gray-300'>• Proof of refund if already issued (e.g., confirmation email)</div>
          </div>
        )
      case 'product_not_as_described':
        return (
          <div className='list-disc space-y-2 pl-4'>
            <div className='text-gray-300'>• Screenshots of any attendee complaints & your replies</div>
            <div className='text-gray-300'>• Screenshots showing proof they withdrew the dispute</div>
            <div className='text-gray-300'>• Proof of refund if already issued</div>
            <div className='text-gray-300'>• Screenshots proving the event matched the advertised description</div>
          </div>
        )
      case 'duplicate':
        return (
          <div className='list-disc space-y-2 pl-4'>
            <div className='text-gray-300'>
              • Explanation showing the difference between the disputed payment and alleged duplicate
            </div>
            <div className='text-gray-300'>• Any communication with the attendee about this issue</div>
            <div className='text-gray-300'>• Proof of refund if already issued</div>
          </div>
        )
      case 'credit_not_processed':
        return (
          <div className='list-disc space-y-2 pl-4'>
            <div className='text-gray-300'>• Screenshots of attendee communication regarding refund policy</div>
            <div className='text-gray-300'>• Proof that refund was not eligible based on stated policy</div>
            <div className='text-gray-300'>• Proof of refund if already issued</div>
          </div>
        )
      case 'subscription_canceled':
        return (
          <div className='list-disc space-y-2 pl-4'>
            <div className='text-gray-300'>
              • Screenshots of communication where attendee acknowledges the subscription
            </div>
            <div className='text-gray-300'>• Proof of refund if already issued</div>
          </div>
        )
      case 'unrecognized':
        return (
          <div className='list-disc space-y-2 pl-4'>
            <div className='text-gray-300'>• Screenshots of communication where attendee acknowledges the charge</div>
            <div className='text-gray-300'>• Proof of refund if already issued</div>
          </div>
        )
      default:
        return (
          <div className='list-disc space-y-2 pl-4'>
            <div className='text-gray-300'>
              • Screenshots of any attendee communication or proof they withdrew the dispute
            </div>
            <div className='text-gray-300'>• Proof of refund if already issued</div>
            <div className='text-gray-300'>• Any documentation contradicting the dispute claim</div>
          </div>
        )
    }
  }

  return (
    <div className='rounded-lg border border-[#444444] bg-[#1a2234] p-4'>
      <div className='mb-2 font-medium text-blue-400'>Recommended Evidence</div>
      <div className='mb-3 text-sm'>{getEvidenceGuidance(reason)}</div>

      <div className='mt-2 rounded border border-green-700/40 bg-green-900/40 p-3 text-sm'>
        <div className='font-medium text-green-400'>Auto-submitted evidence: </div>
        <div className='text-green-300'>
          We&apos;ve already submitted transaction logs and payment verification on your behalf. You can provide
          additional evidence below to strengthen your case.
        </div>
      </div>

      <div className='mt-4 space-y-4'>
        <div className='text-sm'>
          <div className='mb-1 flex items-center gap-2 text-gray-400'>
            Customer Communication
            <div {...tooltipProps1} className='cursor-help'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='h-4 w-4 text-gray-500'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
                />
              </svg>
            </div>
          </div>
          <div className='cursor-pointer rounded-lg border-2 border-dashed border-[#444444] p-6 text-center transition-colors hover:bg-[#222222]'>
            <input
              type='file'
              accept='.pdf,.jpg,.jpeg,.png'
              onChange={handleFileChange('customerCommunicationFile')}
              className='hidden'
              id='customer-communication-file'
            />
            <label htmlFor='customer-communication-file' className='cursor-pointer'>
              <div className='text-gray-300'>
                {formState.customerCommunicationFile
                  ? formState.customerCommunicationFile.name
                  : 'Drag and drop files here, or click to browse'}
              </div>
              <div className='mt-2 text-xs text-gray-500'>Max file size: 10MB. Supported formats: PDF, JPG, PNG</div>
            </label>
          </div>
        </div>

        <div className='text-sm'>
          <div className='mb-1 flex items-center gap-2 text-gray-400'>
            Product Description
            <div {...tooltipProps2} className='cursor-help'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='h-4 w-4 text-gray-500'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
                />
              </svg>
            </div>
          </div>
          <textarea
            className='min-h-[100px] w-full resize-none rounded-lg border border-[#444444] bg-[#111111] p-3 text-white placeholder:text-gray-600'
            placeholder='Describe the event or product in detail...'
            value={formState.productDescription}
            onChange={e => handleInputChange('productDescription', e.target.value)}
          />
        </div>

        <div className='text-sm'>
          <div className='mb-1 flex items-center gap-2 text-gray-400'>
            Refund Policy & Explanation
            <div {...tooltipProps3} className='cursor-help'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='h-4 w-4 text-gray-500'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
                />
              </svg>
            </div>
          </div>
          <textarea
            className='min-h-[100px] w-full resize-none rounded-lg border border-[#444444] bg-[#111111] p-3 text-white placeholder:text-gray-600'
            placeholder='Explain why a refund was not issued...'
            value={formState.refundRefusalExplanation}
            onChange={e => handleInputChange('refundRefusalExplanation', e.target.value)}
          />
          <div className='mt-2 cursor-pointer rounded-lg border-2 border-dashed border-[#444444] p-6 text-center transition-colors hover:bg-[#222222]'>
            <input
              type='file'
              accept='.pdf,.jpg,.jpeg,.png'
              onChange={handleFileChange('refundPolicyFile')}
              className='hidden'
              id='refund-policy-file'
            />
            <label htmlFor='refund-policy-file' className='cursor-pointer'>
              <div className='text-gray-300'>
                {formState.refundPolicyFile
                  ? formState.refundPolicyFile.name
                  : 'Drag and drop files here, or click to browse'}
              </div>
              <div className='mt-2 text-xs text-gray-500'>Max file size: 10MB. Supported formats: PDF, JPG, PNG</div>
            </label>
          </div>
        </div>

        <div className='text-sm'>
          <div className='mb-1 flex items-center gap-2 text-gray-400'>
            Additional Evidence
            <div {...tooltipProps4} className='cursor-help'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='h-4 w-4 text-gray-500'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
                />
              </svg>
            </div>
          </div>
          <textarea
            className='min-h-[100px] w-full resize-none rounded-lg border border-[#444444] bg-[#111111] p-3 text-white placeholder:text-gray-600'
            placeholder='Add any additional information that might help your case...'
            value={formState.uncategorizedText}
            onChange={e => handleInputChange('uncategorizedText', e.target.value)}
          />
          <div className='mt-2 cursor-pointer rounded-lg border-2 border-dashed border-[#444444] p-6 text-center transition-colors hover:bg-[#222222]'>
            <input
              type='file'
              accept='.pdf,.jpg,.jpeg,.png'
              onChange={handleFileChange('additionalEvidenceFile')}
              className='hidden'
              id='additional-evidence-file'
            />
            <label htmlFor='additional-evidence-file' className='cursor-pointer'>
              <div className='text-gray-300'>
                {formState.additionalEvidenceFile
                  ? formState.additionalEvidenceFile.name
                  : 'Drag and drop files here, or click to browse'}
              </div>
              <div className='mt-2 text-xs text-gray-500'>Max file size: 10MB. Supported formats: PDF, JPG, PNG</div>
            </label>
          </div>
        </div>
      </div>

      <Tooltip {...tooltipContent1}>
        <div className='rounded bg-gray-800 p-2 text-sm text-white'>
          Upload any communication with the customer, such as emails, chat logs, or messages.
        </div>
      </Tooltip>

      <Tooltip {...tooltipContent2}>
        <div className='rounded bg-gray-800 p-2 text-sm text-white'>
          Provide a detailed description of the event or product, including any relevant terms and conditions.
        </div>
      </Tooltip>

      <Tooltip {...tooltipContent3}>
        <div className='rounded bg-gray-800 p-2 text-sm text-white'>
          Upload your refund policy and explain why a refund was not issued based on your policies.
        </div>
      </Tooltip>

      <Tooltip {...tooltipContent4}>
        <div className='rounded bg-gray-800 p-2 text-sm text-white'>
          Add any additional evidence that supports your case, such as event descriptions or terms of service.
        </div>
      </Tooltip>
    </div>
  )
}

export default EvidenceGuidance
