import React, {useEffect} from 'react'

import {useCheckMultisessionEventProvisioningStatus} from 'apis/MultiSessionEvents/useCheckMultiSessionEventProvisioningStatus'
import {SpinLoader} from 'components/Loaders/SpinLoader'

interface MultiSessionCreatingEventsProgressProps {
  mseId: string
  groupId: string
  onDone: () => void
}

export const MultiSessionCreatingEventsProgress = (props: MultiSessionCreatingEventsProgressProps) => {
  const {mseId, groupId, onDone} = props

  const {data: status} = useCheckMultisessionEventProvisioningStatus({groupId, id: mseId}, {refetchInterval: 300})

  useEffect(() => {
    if (!status) return

    if (status?.completed) {
      setTimeout(() => {
        onDone()
      }, 500)
    }
  }, [status, onDone])

  return (
    <div className='flex h-full w-full flex-col items-center justify-center'>
      <div className='flex flex-col items-center justify-center gap-4 rounded-lg bg-zinc-900 p-18'>
        <SpinLoader height={100} />
      </div>
    </div>
  )
}
