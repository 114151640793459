import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'
import useSessionContext from 'domains/Auth/SessionContext'
import PoshHeader from 'pages/OwnerPage/PoshHeader'

import Community from '..'
import DetailsModal, {DetailEntity} from '../DetailsModal'
import CommunityFooter from '../Footer'
import CommunityHelmet from '../Helmet'
import CommunityPathModal from '../PathModal'

import './styles.scss'

export const PublicCommunityLanding = () => {
  const navigate = useNavigate()
  const {userId} = useSessionContext()
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)
  const [selectedDetailEntity, setSelectedDetailEntity] = useState<DetailEntity>({})

  const [pathModalOpen, setPathModalOpen] = useState(false)

  useEffect(() => {
    if (detailsModalOpen) {
      document.documentElement.style.overflow = 'hidden'
    } else document.documentElement.style.overflow = 'unset'
  }, [detailsModalOpen])

  const handleNavigation = (url: string) => {
    if (url === 'staff' && userId) return navigate('signup')
    return navigate(url)
  }

  const handleGetBookedClicked = () => {
    if (userId) navigate('/community/signup')
    else setPathModalOpen(true)
  }

  return (
    <>
      <div>
        <CommunityHelmet />
        <div className='PublicCommunity'>
          <div className='PublicCommunity-header'>
            <PoshImage src='https://images.posh.vip/b2/stock-party.jpeg' className='PublicCommunity-header-imgA' />
            <PoshImage src='https://images.posh.vip/b2/bartender-stock.jpeg' className='PublicCommunity-header-imgB' />
            <PoshHeader community />

            <div className='PublicCommunity-header-cta'>
              <h1 className='m-0'>Everything you need to host an incredible experience.</h1>
              <p className='m-0'>
                Book venues, musicians, DJs, photographers, security, bartenders, videographers, and more.
              </p>
              <div className='PublicCommunity-header-cta-actions'>
                <Button onClick={() => handleNavigation('explore')} className='large'>
                  Explore
                </Button>
                <Button onClick={() => handleGetBookedClicked()} className='large outline'>
                  Get Booked
                </Button>
              </div>
            </div>
          </div>
          <Community landingPage />
        </div>
        <CommunityFooter />
      </div>
      <div>
        {detailsModalOpen && (
          <DetailsModal selectedDetailEntity={selectedDetailEntity} setDetailsModalOpen={setDetailsModalOpen} />
        )}
        {pathModalOpen && <CommunityPathModal setPathModalOpen={setPathModalOpen} />}
      </div>
    </>
  )
}
