import React, {PropsWithChildren, useState} from 'react'
import ReactTooltip from 'react-tooltip'

import classNames from 'classnames'
import Button from 'components/form/Button'
import ConfirmationModal from 'components/modals/Modal/ConfirmationModal'
import {useDefaultBackNavigation} from 'hooks/navigation/useDefaultBackNavigation'

import styles from './styles.module.scss'

export interface ActionRowSaveButtonProps {
  title: string
  className: string
  isDisabled: boolean
  isLoading: boolean
  onPress: () => void
  tooltipText?: string
}

export interface EventVisualsActionRowProps {
  isDirty: boolean
  actionButton: ActionRowSaveButtonProps
  title?: string
  exitTitle?: string
  style?: React.CSSProperties
  isLightMode: boolean
}

export const EventVisualsActionRow = (props: PropsWithChildren<EventVisualsActionRowProps>) => {
  const {isDirty, actionButton, title, exitTitle = 'Exit Editor', style, children, isLightMode} = props

  const [exitEditorConfirmationModalOpen, setExitEditorConfirmationModalOpen] = useState(false)

  const {goBack} = useDefaultBackNavigation()

  const exitEditor = () => {
    if (isDirty) {
      setExitEditorConfirmationModalOpen(true)
    } else {
      goBack()
    }
  }

  return (
    <>
      <div className={classNames(styles.EventVisualsActionRow, {[`${styles.lightMode}`]: isLightMode})} style={style}>
        {title && <h5 className='m-0'>{title}</h5>}
        <div className={classNames(styles.wrapper)}>
          <div>
            <Button className='dark' onClick={exitEditor}>
              {exitTitle}
            </Button>
          </div>
          {children}
          <ReactTooltip id='help' effect='solid' place='left' disable={!actionButton.tooltipText}>
            <p className='m-0 text-xs italic' style={{color: 'white'}}>
              {actionButton.tooltipText}
            </p>
          </ReactTooltip>
          <div data-tip data-for='help'>
            <Button
              disabled={actionButton.isDisabled}
              isLoading={actionButton.isLoading}
              onClick={actionButton.onPress}
              className={actionButton.className}>
              {actionButton.title}
            </Button>
          </div>
        </div>
      </div>
      {exitEditorConfirmationModalOpen && (
        <ConfirmationModal
          noborder
          isOpen={exitEditorConfirmationModalOpen}
          confirmButtonText='Exit Editor'
          confirmationMessage='Discarding Changes'
          title='Are you sure you want to exit?'
          bodyText='You have unsaved changes. Exiting the editor will discard them.'
          onClick={goBack}
          onClose={() => setExitEditorConfirmationModalOpen(false)}
        />
      )}
    </>
  )
}
