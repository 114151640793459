import {ReactQueryOptions, RouterOutput, trpc} from 'lib/trpc'

export interface BlockAttendeeInput {
  groupId: string
  userId: string
  block: boolean
}

type BlockAttendeeOptions = ReactQueryOptions['groups']['blockAttendee']
export type BlockAttendeeOutput = RouterOutput['groups']['blockAttendee']

export function useBlockAttendee(opts?: BlockAttendeeOptions) {
  return trpc.groups.blockAttendee.useMutation(opts)
}
