import React from 'react'
import {useNavigate} from 'react-router-dom'

import {useFetchOrdersAndEvents} from 'apis/Accounts/useFetchOrdersAndEvents'
import {useGetGroupAccount} from 'apis/Groups/useGetGroupAccount'
import {Card} from 'components/Card'
import {PoshImage} from 'components/PoshImage/PoshImage'
import AttendeeAlerts from 'pages/GroupPage/PageComponents/AttendeeAlerts'
import {useResourcePageParams} from 'pages/PoshAppLayout'

interface OrderCardCustomerProps {
  groupId: string
  attendeeId: string
}

const OrderCardCustomer = (props: OrderCardCustomerProps) => {
  const {groupId, attendeeId} = props

  const {data: account} = useGetGroupAccount({
    groupId: groupId,
    accountId: attendeeId,
  })

  const {domain} = useResourcePageParams()
  const navigate = useNavigate()

  const {data: orderAndEvents, isSuccess} = useFetchOrdersAndEvents(attendeeId)

  const onlyUnique = (value: string, index: number, self: string[]) => {
    return self.indexOf(value) === index
  }
  const uniqueEvents = orderAndEvents?.map(oe => oe.event?.name).filter(onlyUnique)
  const lifetimeSpend = orderAndEvents?.map(oe => oe.order?.subtotal).reduce((partialSum, a) => partialSum + a, 0)

  if (isSuccess && account) {
    const {joinedDate, avi, firstName, lastName, instagram, smsOptIn} = account
    return (
      <Card className='OrderPage-customerCard' animationIndex={20}>
        <PoshImage className='OrderPage-avi' src={avi} />
        <h3 onClick={() => navigate(`/${domain}/groups/${groupId}/marketing/attendee/${account._id}`)}>
          {firstName} {lastName}
        </h3>
        <p className='OrderPage-customerCard-subtitle'>Joined Posh in {joinedDate}</p>
        <AttendeeAlerts textDisabled={!smsOptIn} />

        <h6>Group Statistics</h6>
        <table className='OrderPage-customerCard-info'>
          <tr>
            <td>Events Attended</td>
            <td style={{textAlign: 'right'}}>{uniqueEvents?.length}</td>
          </tr>
          <tr>
            <td>Lifetime Spend</td>
            <td style={{textAlign: 'right'}}>${lifetimeSpend?.toFixed(2)}</td>
          </tr>
        </table>

        <h6>Contact Info</h6>
        <table className='OrderPage-customerCard-info'>
          <tr>
            <td style={{width: '35px'}}>
              <PoshImage src='https://images.posh.vip/b2/email+(1).svg' />
            </td>
            <td>{account.email}</td>
          </tr>
          <tr>
            <td>
              <PoshImage src='https://images.posh.vip/b2/phone-call.svg' />
            </td>
            <td>{account.phone}</td>
          </tr>
          {instagram && (
            <tr>
              <td>
                <PoshImage src='https://images.posh.vip/b2/instagram+(3).svg' />
              </td>
              <td>
                <a target='_blank' href={`https://instagram.com/${instagram}`} rel='noreferrer'>
                  {instagram}
                </a>
              </td>
            </tr>
          )}
        </table>
      </Card>
    )
  }
}

export default OrderCardCustomer
