import React from 'react'

import {MAX_TICKET_QUANTITY} from '@posh/model-types'
import {ArrowWithDirection} from 'components/assets/Icons'
import PoshSwitch from 'components/form/PoshSwitch'
import {EventVisualsButton} from 'components/PageComponents/EventVisuals/Button/Button'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {EventVisualsSection} from 'components/PageComponents/EventVisuals/Page/Section'
import {CreateEventForm, CreateEventTicket} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'

import {useTicketTransferSettings} from '../../../../../../../hooks/useTicketTransferSettings'
import {useEventVisualsContext} from '../../context/EventVisualsContext'
import {OpenTicketEditorInterface} from './TicketEditorPanel/TicketEditorPanel'

interface RSVPAdmissionSectionProps extends OpenTicketEditorInterface {
  ticket: CreateEventTicket
  setTickets: (tickets: CreateEventForm['tickets']) => void
}

const INPUT_WIDTH = 200

export const RSVPAdmissionSection = (props: RSVPAdmissionSectionProps) => {
  const {ticket, setTickets, openTicketEditor} = props
  const {
    palette: {accentColor, lightmode, buttonTextColor, textContrasting},
  } = useEventVisualsContext()
  const {approvalRequired, isTransferable, quantityAvailable} = ticket

  const toggleApprovalRequired = (val: boolean) => {
    setTickets([{...ticket, approvalRequired: val}])
  }

  const {
    shouldRenderTicketTransferSwitch,
    TICKET_TRANSFER_SETTING_LABEL,
    ticketTransferLabelSubtitle,
    toggleIsTransferable,
    canToggleTransfer,
  } = useTicketTransferSettings({
    ticketHasApprovalRequired: approvalRequired ?? false,
    onToggleTransfer: val => {
      setTickets([{...ticket, isTransferable: val}])
    },
  })

  const setMaxQty = (val: number) => {
    setTickets([{...ticket, quantityAvailable: val}])
  }

  const toggleQtyPicker = (val: boolean) => {
    if (!val) {
      setMaxQty(MAX_TICKET_QUANTITY)
    } else {
      setMaxQty(100)
    }
  }

  const rsvpIsUnlimited = quantityAvailable === MAX_TICKET_QUANTITY

  return (
    <>
      <EventVisualsSection
        headerText='RSVP Settings'
        underlined
        rightIcon={
          <EventVisualsButton onClick={() => openTicketEditor()}>
            <>
              <ArrowWithDirection color={buttonTextColor} direction='northEast' />
              Add Ticket
            </>
          </EventVisualsButton>
        }>
        <PoshSwitch
          switchOptions={{
            checked: approvalRequired ?? false,
            onChange: toggleApprovalRequired,
          }}
          rightTitle='Require Approval'
          subtitle='Attendees must be approved by you before they can RSVP.'
        />
        {shouldRenderTicketTransferSwitch && (
          <PoshSwitch
            switchOptions={{
              checked: isTransferable ?? true,
              onChange: toggleIsTransferable,
            }}
            rightTitle={TICKET_TRANSFER_SETTING_LABEL}
            subtitle={ticketTransferLabelSubtitle}
            disabled={!canToggleTransfer}
          />
        )}
        <PoshSwitch
          switchOptions={{
            checked: !rsvpIsUnlimited,
            onChange: toggleQtyPicker,
          }}
          rightTitle='Limit event capacity'
          subtitle='Limit the number of guests who can RSVP to this event. Leave blank for unlimited RSVPs.'
        />
        {!rsvpIsUnlimited && (
          <EventVisualsTextInput.Number
            placeholder='Spots Available'
            lightMode={lightmode}
            accentColor={accentColor}
            value={quantityAvailable}
            onChange={setMaxQty}
            style={{width: INPUT_WIDTH}}
            autoFocus
          />
        )}
        <p className={`center m-0`} style={{color: textContrasting}}>
          This is currently an RSVP event.
        </p>
      </EventVisualsSection>
    </>
  )
}
