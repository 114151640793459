import React from 'react'

import {useQueryClient} from '@tanstack/react-query'
import useFetchGroupTagsAndEvents from 'apis/Groups/useFetchGroupTagsAndEvents'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import useAttendeesCrmContext from '../AttendeesCrmContext'
import {TagContainer} from '../TagContainer'
import FilterIcon from './filterIcon'

export const FilterAlert = ({onOpenModal}: {onOpenModal: () => void}) => {
  const {groupId} = useResourcePageParams()
  const {
    setSelectedEvents,
    setSelectedTags,
    setFilterByData,
    setFilterByOrder,
    filterByOrder,
    filterByData,
    selectedEvents,
    selectedTrackingLinks,
    setIsFilterActive,
    selectedTags,
    selectedTickets,
    setSelectedTickets,
    genderFilter,
    setGenderFilter,
    setSelectedAttendeeIds,
    setSelectedAllAttendees,
    setSelectedTrackingLinks,
    selectedAttendeeIds,
    selectedAllAttendees,
  } = useAttendeesCrmContext()

  const {data: tagsAndEvents} = useFetchGroupTagsAndEvents(groupId!)
  const queryClient = useQueryClient()
  const areFiltersDefault =
    filterByData === 'attendees' &&
    filterByOrder === 'newest to oldest' &&
    selectedEvents.length === 0 &&
    selectedTickets.length === 0 &&
    !genderFilter &&
    selectedTrackingLinks.length === 0 &&
    selectedTags.length === 0 &&
    selectedAllAttendees === false &&
    selectedAttendeeIds.length === 0
  if (!tagsAndEvents) return null

  const removeSelectedTag = (tagId: string) => {
    setSelectedTags(selectedTags.filter(tag => tag !== tagId))
    queryClient.invalidateQueries(['groupAttendeesPaged'])
  }

  return (
    <div className='Attendees-filterAlert'>
      <div className='Attendees-filterList'>
        {selectedEvents && selectedEvents.length > 0 && (
          <FilterIcon
            color={'#222224'}
            label={`Attended ${selectedEvents.length} ${selectedEvents.length === 1 ? 'Event' : 'Events'}`}
            onRemove={() => setSelectedEvents([])}
          />
        )}
        {/* These are always set to this by default, so don't show them if they're the default as they can't actually be removed */}
        {filterByData && filterByData !== 'attendees' && filterByOrder !== 'newest to oldest' && (
          <FilterIcon
            color={'#222224'}
            label={`Sort By: ${filterByData} ${filterByOrder ?? 'newest to oldest'}`}
            onRemove={() => {
              setFilterByData('attendees')
              setFilterByOrder('newest to oldest')
            }}
          />
        )}
        {selectedTickets && selectedTickets.length > 0 && (
          <FilterIcon
            color={'#222224'}
            label={`${selectedTickets.length} ${selectedTickets.length === 1 ? 'ticket selected' : 'tickets selected'}`}
            onRemove={() => setSelectedTickets([])}
          />
        )}
        {genderFilter && (
          <FilterIcon color={'#222224'} label={`Gender: ${genderFilter}`} onRemove={() => setGenderFilter(null)} />
        )}
        {selectedTrackingLinks && selectedTrackingLinks.length > 0 && (
          <FilterIcon
            color={'#222224'}
            label={`${selectedTrackingLinks.length} Tracking Links`}
            onRemove={() => setSelectedTrackingLinks([])}
          />
        )}
        <div className='Attendees-filterList-tags'>
          {selectedTags.map(tagId => {
            const tag = tagsAndEvents!.tags.find(t => t._id == tagId)!
            return <TagContainer key={tag.name} tag={tag} onDelete={() => removeSelectedTag(tag._id)} />
          })}
        </div>
      </div>
      {!areFiltersDefault && (
        <a
          className='Attendees-filterAlert-removeAlert'
          onClick={() => {
            setSelectedTags([])
            setSelectedEvents([])
            setFilterByData('attendees')
            setFilterByOrder('newest to oldest')
            setIsFilterActive(false)
            setSelectedAllAttendees(false)
            setSelectedAttendeeIds([])
            setSelectedTrackingLinks([])
            setSelectedTickets([])
          }}>
          Remove All Filters
        </a>
      )}
    </div>
  )
}
