import React from 'react'

import {Icon} from 'components/TableUI/TableIcons/TableIcon'

import {Text} from '../../Shared/Text/Text'
import {OrderItem, OrdersWithEventAttributes} from '../OrderItem/OrderItem'

import styles from './OrderList.module.scss'

interface OrderListProps {
  orders: OrdersWithEventAttributes
  hasFilters: boolean
  isRSVPEvent?: boolean
}

const ICON_SIZE = 50

export const OrderList = (props: OrderListProps) => {
  const {orders, hasFilters, isRSVPEvent} = props

  if (orders.length === 0) {
    return (
      <div className={styles.OrderList}>
        <div className={styles.OrderListEmpty}>
          {hasFilters ? (
            <>
              <Icon name='search' width={ICON_SIZE} height={ICON_SIZE} color={'white'} />
              <Text size='xl' bold>
                No {isRSVPEvent ? 'RSVPs' : 'orders'} found
              </Text>
              <Text size='small' muted>
                For the selected filters and search.
              </Text>
            </>
          ) : (
            <>
              <Icon
                name={isRSVPEvent ? 'ticket' : 'orders-outlined'}
                width={ICON_SIZE}
                height={ICON_SIZE}
                color={'white'}
              />
              <Text size='xl' bold>
                No {isRSVPEvent ? 'RSVPs' : 'orders'} yet
              </Text>
              <Text size='small' muted>
                When someone {isRSVPEvent ? 'RSVPs' : 'places an order'}, it will show up here.
              </Text>
            </>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.OrderList}>
      {orders.map((order, index) => (
        <OrderItem key={index} {...order} />
      ))}
    </div>
  )
}

const OrderListLoading = () => {
  return (
    <div className={styles.OrderList}>
      {[1, 2, 3, 4].map((_, index) => (
        <OrderItem.Loading key={index} />
      ))}
    </div>
  )
}

const OrderListError = ({isRSVPEvent}: {isRSVPEvent?: boolean}) => {
  return (
    <div className={styles.OrderList}>
      <div className={styles.OrderListError}>
        <Icon name='warning' width={50} height={50} className={styles.OrderListErrorIcon} />
        <Text size='xl' bold style={{textAlign: 'center'}}>
          Oops! Something went wrong
        </Text>
        <Text size='small' muted style={{textAlign: 'center'}}>
          It seems like we could not fetch the {isRSVPEvent ? 'RSVPs' : 'orders'}. Please try again later or contact
          support.
        </Text>
      </div>
    </div>
  )
}

OrderList.Loading = OrderListLoading
OrderList.Error = OrderListError
