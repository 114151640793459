import React, {useEffect, useMemo, useState} from 'react'
import {Control, useController} from 'react-hook-form'

import {CountryCode, CurrencyCode, getCurrencyFromCountry} from '@posh/types'
import Button from 'components/form/Button'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'
import {
  CreateEventForm,
  CreateEventTicket,
  EventVisualsForm,
} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {useToast} from 'components/toasts/ToastProvider'
import {v4} from 'uuid'

import {getCurrencySymbol} from '../../../../../../Util/getCurrencySymbol'
import {RSVPAdmissionSection} from './RSVPAdmissionSection'
import {OpenTicketEditorInterface, TicketEditorPanel} from './TicketEditorPanel/TicketEditorPanel'
import {TicketAdmissionSection} from './TicketsAdmissionSection'

export const determineIsRSVPEvent = (tickets: CreateEventForm['tickets']) => {
  return (
    !!tickets && tickets.length === 1 && tickets[0].price === 0 && !tickets[0].password && tickets[0].name === 'RSVP'
  )
}

type InlineAdmissionComponentProps = OpenTicketEditorInterface & {
  currencyCode: CurrencyCode
  currencySymbol: string
  tickets: CreateEventForm['tickets']
  setTickets: (tickets: CreateEventForm['tickets']) => void
}

const InlineAdmissionComponent = (props: InlineAdmissionComponentProps) => {
  const {tickets, setTickets, currencySymbol, currencyCode, ...rest} = props

  const isRSVPEvent = useMemo(() => {
    return determineIsRSVPEvent(tickets)
  }, [tickets])

  if (isRSVPEvent && !!tickets) return <RSVPAdmissionSection ticket={tickets[0]} setTickets={setTickets} {...rest} />
  if (tickets)
    return <TicketAdmissionSection tickets={tickets} setTickets={setTickets} currencyCode={currencyCode} {...rest} />
  return null
}

interface TicketEditorPanelState {
  isOpen: boolean
  initialTicket?: CreateEventTicket
  ticketEditorIndex?: number
}

interface EventVisualsAdmissionSectionProps {
  control: Control<EventVisualsForm>
  country?: string
}

export const EventVisualsAdmissionSection = (props: EventVisualsAdmissionSectionProps) => {
  const {country = 'US', control} = props
  const {showToast} = useToast()
  const {currencySymbol, currencyCode} = useMemo(() => {
    const currencyCode = getCurrencyFromCountry(country as CountryCode)
    return {
      currencySymbol: getCurrencySymbol(currencyCode),
      currencyCode,
    }
  }, [country])

  const [ticketEditorState, setTicketEditorState] = useState<TicketEditorPanelState>({
    isOpen: false,
  })

  const {
    field: {onChange: setTickets, value: tickets},
  } = useController({
    control,
    name: 'tickets',
  })

  const {
    field: {value: recurrence},
  } = useController({
    control,
    name: 'recurrence',
  })

  const [modifiedTickets, setModifiedTickets] = useState<Array<{name: string}> | undefined>(undefined)

  useEffect(() => {
    // clear ticket sale dates and validity if recurrence is enabled.
    // we don't support these fields for multi-session events
    if (recurrence) {
      const ticketsToBeModified = tickets?.filter(
        ticket => ticket.onSaleUtc || ticket.endSaleUtc || ticket.validity?.validAfter || ticket.validity?.validBefore,
      )

      if (ticketsToBeModified?.length) {
        setTickets(
          tickets?.map(ticket => {
            return {
              ...ticket,
              onSaleUtc: null,
              endSaleUtc: null,
              validity: undefined,
            }
          }),
        )
        setModifiedTickets(ticketsToBeModified.map(ticket => ({name: ticket.name})))
      }
    }
  }, [recurrence, tickets, setTickets, setModifiedTickets])

  const openTicketEditor = (initialTicket?: CreateEventTicket, ticketEditorIndex?: number) => {
    setTicketEditorState({isOpen: true, initialTicket, ticketEditorIndex})
  }

  const closeTicketEditor = () => {
    setTicketEditorState({isOpen: false, initialTicket: undefined, ticketEditorIndex: undefined})
  }

  const addOrEditTicket = (ticket: CreateEventTicket, index?: number) => {
    if (index !== undefined) {
      // Edit existing ticket
      if (!tickets) setTickets([ticket])
      else {
        const newTickets = [...tickets]
        newTickets[index] = ticket
        setTickets(newTickets)
      }
    } else {
      // Add ticket
      tickets ? setTickets([...tickets, ticket]) : setTickets([ticket])
    }
    closeTicketEditor()
  }

  const ticketEditorModalKey = ticketEditorState.initialTicket?.id ?? v4().toString()

  return (
    <>
      <InlineAdmissionComponent
        openTicketEditor={openTicketEditor}
        closeTicketEditor={closeTicketEditor}
        currencyCode={currencyCode}
        currencySymbol={currencySymbol}
        tickets={tickets}
        setTickets={setTickets}
      />
      <TicketEditorPanel
        key={ticketEditorModalKey}
        isOpen={ticketEditorState.isOpen}
        onClose={closeTicketEditor}
        currencySymbol={currencySymbol}
        initialTicket={ticketEditorState.initialTicket}
        ticketEditorIndex={ticketEditorState.ticketEditorIndex}
        onComplete={addOrEditTicket}
        isMultiSession={!!recurrence}
      />
      <PoshStyledModal isOpen={!!modifiedTickets} onClose={() => setModifiedTickets(undefined)}>
        <div className='flex flex-col gap-6'>
          <h3 className='text-large m-0 font-medium! tracking-tight'>Heads up!</h3>
          <div className='flex flex-col gap-2'>
            <p className='text-medium'>
              Sale / Validity periods are not currently supported for tickets within recurring events. We have removed
              these settings from your tickets.
            </p>
            <p className='text-medium'>You can manage these settings later for each individual event.</p>
          </div>
          <Button className='w-36' onClick={() => setModifiedTickets(undefined)}>
            OK
          </Button>
        </div>
      </PoshStyledModal>
    </>
  )
}
