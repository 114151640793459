import React, {useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {PaymentElement} from '@stripe/react-stripe-js'
import {TFetchCheckoutSummaryOutput} from 'apis/Checkout/useFetchCheckoutSummary'
import {FetchEventResponsePublic} from 'apis/Events/useFetchEvent'
import {trackPurchaseFailed} from 'apis/FbPixelHelpers'
import {useMixpanel} from 'apis/MixPanelHandler'
import CheckoutButton from 'components/form/CheckoutButton'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'
import {trackPurchaseAttempt} from 'helpers/tracking/coreEvents/purchaseAttempt'
import {useTrackRudderStackEvent} from 'hooks/useTrackRudderstackEvent'

import {handleNavigation} from '..'
import {getCheckoutButtonText} from '../getCheckoutButtonText'
import useStripeConfirmPayment from '../useStripeConfirmPayment'

interface PaymentElementWrapperProps {
  summary: TFetchCheckoutSummaryOutput | undefined
  eventsResp: FetchEventResponsePublic | null | undefined
  hasAgreedToCuratorTos?: boolean
  checkoutDisabled: boolean
}

const PaymentElementWrapper = (props: PaymentElementWrapperProps) => {
  const {summary, eventsResp, hasAgreedToCuratorTos, checkoutDisabled} = props
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const [errorMessage, setErrorMessage] = useState<string | undefined>('')
  const [status, setStatus] = useState('CHECKOUT')
  const urlParams = new URLSearchParams(window.location.search)
  const cartId = urlParams.get('cartId') as string
  const {cartInfo} = summary ?? {}
  const approvalRequired = cartInfo?.approvalRequired
  const cartTicketCount = useMemo(() => {
    if (!cartInfo) return 0
    return cartInfo.items.reduce((acc, item) => (item.resourceType === 'ticket' ? acc + 1 : acc), 0)
  }, [cartInfo])

  const {validateAllRequiredCheckoutFields} = useLiveCartContext()
  const {trackPaidOrderCreated} = useTrackRudderStackEvent()

  const eventUrl = eventsResp?.event.url
  const isRSVPEvent = eventsResp?.event.isRSVPEvent

  const redirectUrl = useMemo(
    () =>
      handleNavigation({
        eventUrl: eventUrl!,
        cartId,
        approvalRequired,
        isRSVPEvent,
      }),
    [eventUrl, cartId, approvalRequired, isRSVPEvent],
  )

  const {confirmPayment} = useStripeConfirmPayment({
    payment: 'new',
    redirectUrl,
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    trackMixpanelEvent('Checkout Clicked-  Checkout Page', {source: 'stripe payment element'})
    event.preventDefault()
    !!cartInfo &&
      !!eventsResp &&
      trackPurchaseAttempt({cart: {cartInfo, cartTicketCount, cartId}, event: eventsResp.event})

    setErrorMessage('')
    setStatus('PROCESSING PURCHASE')
    if (!validateAllRequiredCheckoutFields()) {
      setStatus('CHECKOUT')
      trackMixpanelEvent('Checkout Failed Error-  Checkout Page', {message: '!isValidCustomDataInfo'})
      return
    }

    // try-catch here for possible confirm payment error throw
    try {
      const {error} = await confirmPayment({
        cartId,
        onSuccess: () => {
          trackMixpanelEvent('Checkout Success-  Checkout Page')
          trackPaidOrderCreated(cartInfo?.total ?? 0, {
            eventId: eventsResp?.event.id,
            cartId,
          })
          navigate(redirectUrl)
        },
      })
      if (error) {
        // throw here to centralize error handling in catch block
        if (eventsResp?.event.fbPixelId)
          trackPurchaseFailed({
            pixelId: eventsResp.event.fbPixelId,
            eventName: eventsResp.event.name,
            currency: eventsResp.group.currency,
            subtotal: cartInfo?.subtotal,
            ticketCount: cartTicketCount,
          })
        throw error
      }
    } catch (confirmPaymentError) {
      const displayErrorMessage = confirmPaymentError.message ?? 'There was an error processing your payment.'
      trackMixpanelEvent('Checkout Failed Error-  Checkout Page', {
        message: displayErrorMessage,
      })
      setErrorMessage(displayErrorMessage)
      setStatus('CHECKOUT')
      return
    }
  }

  const checkoutButtonText = () => {
    return getCheckoutButtonText({
      isProcessing: status === 'PROCESSING PURCHASE',
      isFreeOrder: false,
      isRSVPEvent: false,
      isApprovalRequired: approvalRequired,
      customCheckoutSubmitText: eventsResp?.event.customCheckoutSubmitText,
    })
  }

  return (
    <>
      {status === 'PROCESSING PURCHASE' && <div className='CheckoutPageLoadingOverlay' />}
      <div className='CheckoutPage-Section fade'>
        <form onSubmit={handleSubmit}>
          <PaymentElement />
          <div style={{marginTop: '10px'}}>
            {approvalRequired && (
              <p className='CheckoutPage-disclaimer'>
                Since some of the items in your cart need to be approved, your money will be put on hold until the
                organizer verifies you may attend the event.
              </p>
            )}
            <CheckoutButton
              className={'Gold'}
              disabled={status === 'PROCESSING PURCHASE' || !hasAgreedToCuratorTos || checkoutDisabled}>
              {checkoutButtonText()}
            </CheckoutButton>
            {errorMessage && <span className='error text-small'>{errorMessage}</span>}
          </div>
        </form>
      </div>
    </>
  )
}

export default PaymentElementWrapper
