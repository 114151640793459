import React, {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {GetGroupEventsEvent, useFetchGroupEvents} from 'apis/Groups/useFetchGroupEvents'
import EventsTable from 'components/data/EventsTable'
import {EventRowAction} from 'components/data/EventsTable/ListItem/types'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {orderBy} from 'lodash'
import moment from 'moment'

import DeleteEventModal from '../Overview/DeleteEventModal'
import DuplicateEventModal from '../Overview/DuplicateEventsModal'
import GroupPageSubNav from '../SubNav'

import './styles.scss'

const AllEvents = () => {
  const {groupId, domain} = useGroupMatchParams()
  const {data: groupData} = useFetchGroup(groupId)
  const {data, isFetching} = useFetchGroupEvents({groupId: groupId!}, {enabled: !!groupId})
  const events = data?.events ?? []

  const navigate = useNavigate()
  const [actionEvent, setActionEvent] = useState<GetGroupEventsEvent | undefined>(undefined)
  const [isShowingDuplicateEventModal, setIsShowingDuplicateEventModal] = useState(false)
  const [isShowingDeleteEventModal, setIsShowingDeleteEventModal] = useState(false)
  const search = useLocation().search

  const handleRowAction = (action: EventRowAction, eventId: string) => {
    if (action == EventRowAction.EDIT) {
      navigate(`/${domain}/groups/${groupId}/events/${eventId}/overview` + search)
    }

    if (action == EventRowAction.DELETE) {
      setActionEvent(events!.find(event => event._id == eventId)!)
      setIsShowingDeleteEventModal(true)
    }

    if (action == EventRowAction.DUPLICATE) {
      setActionEvent(events!.find(event => event._id == eventId)!)
      setIsShowingDuplicateEventModal(true)
    }

    if (action == EventRowAction.VIEW) {
      const event = events!.find(event => event._id == eventId)!
      navigate(`/e/${event.url}` + search)
    }
  }

  const sortedEvents = orderBy(
    events,
    ({startUtc}) => {
      return moment(startUtc).valueOf()
    },
    ['desc'],
  )

  return (
    <div className='GroupPage AllEvents'>
      <div className='GroupPage-content'>
        <GroupPageSubNav group={groupData} hierarchy={['Events']} />
        {!isFetching && sortedEvents && (
          <EventsTable
            data={sortedEvents ?? []}
            onRowAction={handleRowAction}
            isHost={domain === 'host'}
            activeOnly={false}
          />
        )}
        {isFetching && (
          <div className='AllEvents-loading'>
            <SpinLoader />
          </div>
        )}
        <DeleteEventModal
          event={actionEvent}
          isOpen={isShowingDeleteEventModal}
          onClose={() => setIsShowingDeleteEventModal(false)}
        />
        {actionEvent && isShowingDuplicateEventModal && (
          <DuplicateEventModal
            event={actionEvent}
            isOpen={isShowingDuplicateEventModal}
            onClose={() => setIsShowingDuplicateEventModal(false)}
            width={830}
          />
        )}
      </div>
    </div>
  )
}

export default AllEvents
