import {ComponentProps, useState} from 'react'

import {TLocation} from '@posh/model-types'
import {WhereOption} from '@posh/types'
import {UnderlinedDropdown} from 'components/Dropdown/UnderlinedDropdown'
import {useToast} from 'components/toasts/ToastProvider'

export const DEFAULT_LOCATION_PRESET = {
  type: 'preset',
  location: 'New York City',
} as const

/**
 * This component is used to map the location selected by the user (that is picked in the UnderlinedDropdown component) to a more usable format.
 * e.g. if the user selects 'Near Me', we will use the geolocation API to get the user's current location and use that as the location.
 * @param onLocationSelected When the location is selected, this function is called with the location object.
 * @returns isLoadingNearMeLocation A boolean that is true when the user has selected 'Near Me' and we are fetching the user's location.
 * @returns onSelectLocation A function that is called when the user selects a location in the UnderlinedDropdown component.
 */
export const useSelectLocation = ({onLocationSelected}: {onLocationSelected: (location: TLocation) => void}) => {
  const {showToast} = useToast()
  const [isLoadingNearMeLocation, setIsLoadingNearMeLocation] = useState(false)

  const showGeolocationError = () => {
    showToast({
      type: 'error',
      title: 'You have not shared your location with POSH. Please share to get events near you.',
    })
  }

  const onSelectLocation: ComponentProps<typeof UnderlinedDropdown>['onSelect'] = (value, cityCoordinates) => {
    if (value === 'Near Me') {
      if (!('geolocation' in navigator)) {
        showGeolocationError()
      } else {
        setIsLoadingNearMeLocation(true)
        navigator.geolocation.getCurrentPosition(
          position => {
            setIsLoadingNearMeLocation(false)
            // request user location
            onLocationSelected({
              type: 'custom',
              location: 'Near Me',
              lat: position.coords.latitude,
              long: position.coords.longitude,
            })
          },
          () => {
            setIsLoadingNearMeLocation(false)
            showGeolocationError()
          },
        )
      }
    } else if (cityCoordinates) {
      onLocationSelected({
        type: 'custom',
        location: value,
        long: cityCoordinates[0],
        lat: cityCoordinates[1],
      })
    } else if (value === 'All Locations') {
      onLocationSelected({
        type: 'all',
        location: 'All Locations',
      })
    } else {
      onLocationSelected({
        type: 'preset',
        location: value as Extract<WhereOption, 'New York City' | 'Miami' | 'Los Angeles'>,
      })
    }
  }

  return {onSelectLocation, isLoadingNearMeLocation}
}
