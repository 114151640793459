import React from 'react'

import {IPendingApproval} from 'apis/Orders/useGetPendingApprovals'
import {Table, TicketsGold} from 'components/assets/Icons'

const ICON_SIZE = 16

interface TicketsAndTablesRowProps {
  name: string
  count: number
  variant: 'ticket' | 'table'
}

const TicketsAndTablesRow = ({name, count, variant}: TicketsAndTablesRowProps) => {
  return (
    <div className='ApprovalModal-Content-TicketsAndTables-Row'>
      {variant === 'ticket' ? (
        <TicketsGold width={ICON_SIZE} height={ICON_SIZE} />
      ) : (
        <Table width={ICON_SIZE} height={ICON_SIZE} />
      )}
      <p className='primary m-0'>{name + ` (x${count})`}</p>
    </div>
  )
}

const TicketsAndTables = ({
  tickets,
  tables,
}: {
  tickets: IPendingApproval['cart']['tickets']
  tables: IPendingApproval['cart']['tables']
}) => {
  return (
    <div>
      {tickets.map((t, index) => (
        <TicketsAndTablesRow name={t.type} count={t.num} variant='ticket' key={t.type + index} />
      ))}
      {tables.map((t, index) => (
        <TicketsAndTablesRow name={t.type} count={t.num} variant='table' key={t.type + index} />
      ))}
    </div>
  )
}

export default TicketsAndTables
