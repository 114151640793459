import React from 'react'

import {useAddMultiSessionTrackingLink} from 'apis/MultiSessionEvents/trackingLinks/useAddMultiSessionTrackingLink'
import {FormInputRow} from 'components/DynamicForm/Form/Form'
import {Form} from 'components/DynamicForm/Form/Form'
import {TextInput} from 'components/DynamicForm/Inputs/TextInput/TextInput'
import {useToast} from 'components/toasts/ToastProvider'
import {trpc} from 'lib/trpc'
import {z} from 'zod'

import {generateMultiSessionTrackingLink} from './utils'

interface MultiSessionTrackingLinkCreateFormProps {
  trackingLinks: any[]
  groupId: string
  mseId: string
  mseUrl: string
  mseHexUrl: string
  onClose: () => void
}

export const MultiSessionTrackingLinkCreateForm = (props: MultiSessionTrackingLinkCreateFormProps) => {
  const {trackingLinks, groupId, mseId, mseUrl, mseHexUrl, onClose} = props

  const {showToast} = useToast()
  const queryClient = trpc.useContext()

  const {mutate: createTrackingLink, isLoading: isCreatingTrackingLink} = useAddMultiSessionTrackingLink({
    onSuccess: (_, input) => {
      showToast({type: 'success', title: `Created tracking link: ${input.trackingLink.value}`})
      queryClient.multiSessionEvents.trackingLinks.get.invalidate({groupId, mseId})
      onClose()
    },
    onError: (error, input) => {
      showToast({
        type: 'error',
        title: `There was an error creating the tracking link: ${input.trackingLink.value}`,
        subtitle: error.message,
      })
    },
  })

  const schema = z.object({
    link: z
      .string()
      .min(1)
      .max(100)
      .regex(new RegExp(/^[a-z0-9-]+$/), {
        message: 'Only lowercase letters, numbers, and hyphens without whitespace are allowed',
      })
      .transform(value => value.replace(/\s+/g, '-'))
      .refine(val => !trackingLinks.some(tL => tL.value === val), {message: 'Tracking link already exists'}),
  })

  return (
    <Form.Custom
      schema={schema}
      onSubmit={data => {
        createTrackingLink({groupId, mseId, trackingLink: {value: data.link}})
      }}
      isSubmitting={isCreatingTrackingLink}
      onCancel={onClose}>
      {form => (
        <>
          <FormInputRow label='Tracking Link' subLabel='Tracking links allow you to segment traffic and sales.'>
            <TextInput.Controlled control={form.control} name='link' placeholder='e.g. link-2' />
          </FormInputRow>
          <FormInputRow label='Link Preview'>
            <p className='noMargin secondary'>{`${generateMultiSessionTrackingLink(mseUrl, mseHexUrl, form.watch('link') ?? '').url}`}</p>
            <p className='noMargin secondary'>{`${generateMultiSessionTrackingLink(mseUrl, mseHexUrl, form.watch('link') ?? '').hex}`}</p>
          </FormInputRow>
        </>
      )}
    </Form.Custom>
  )
}
