// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FileInput__76xzr input{display:none}", "",{"version":3,"sources":["webpack://./src/components/form/FilePicker/styles.module.scss"],"names":[],"mappings":"AACI,wBACI,YAAA","sourcesContent":[".FileInput {\n    input {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FileInput": "FileInput__76xzr"
};
export default ___CSS_LOADER_EXPORT___;
