import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'
import UploadAvi from 'components/UploadAvi'
import useLogout from 'hooks/useLogout'

import useSessionContext from '../../../domains/Auth/SessionContext'

import './../styles.scss'

const AccountHome = () => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const [isShowingPhoneVerification, setIsShowingPhoneVerification] = useState(false)
  const logout = useLogout()

  const {currentUser} = useSessionContext()

  const navigate = useNavigate()

  return (
    <div className='GroupsDash'>
      <div className='GroupsDash-inner CuratorProfile'>
        <UploadAvi />

        <h2>
          {currentUser?.firstName} {currentUser?.lastName}
        </h2>

        <hr />

        <div className='CuratorProfile-actions'>
          <Button
            className='darker'
            onClick={() => {
              trackMixpanelEvent('View Profile Click- My Account')
              navigate({pathname: `/p/${currentUser?.username}`})
            }}>
            <PoshImage src='https://images.posh.vip/b2/eye.svg' style={{filter: 'invert(100)'}} />
            View Profile
          </Button>
          <Button
            className='darker'
            onClick={() => {
              trackMixpanelEvent('Account Settings- My Account')
              navigate({pathname: '/dashboard/account/settings'})
            }}>
            <PoshImage src='https://images.posh.vip/b2/settings.svg' />
            Account Settings
          </Button>
          <Button
            className='darker'
            onClick={() => {
              trackMixpanelEvent('Throw Your Own Event Button-  User profile page')
              window.open(`/create`, '_self')
            }}>
            <PoshImage src='https://images.posh.vip/b2/add.svg' />
            Throw Your Own Event
          </Button>
          <Button
            className='darker'
            onClick={() => {
              trackMixpanelEvent('Logout Click- My Account')
              logout()
            }}>
            <PoshImage src='https://images.posh.vip/b2/logout+(1).svg' />
            Logout
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AccountHome
