import {useEffect} from 'react'

import {FeatureFlag} from '@posh/model-types'

import {useFeatureGate} from './useFeatureGate'

interface TicketTransferChecksParams {
  ticketHasApprovalRequired: boolean
  onToggleTransfer: (val: boolean) => void
}

export const useTicketTransferSettings = (params: TicketTransferChecksParams) => {
  const shouldRenderTicketTransferSwitch = useFeatureGate(FeatureFlag.TICKET_TRANSFER_SETTINGS)
  const {ticketHasApprovalRequired, onToggleTransfer} = params
  const canToggleTransfer = !ticketHasApprovalRequired

  const ticketTransferLabelSubtitle = canToggleTransfer
    ? 'Customers can transfer this ticket to another person after purchasing and it will no longer be valid for the original purchaser'
    : 'Ticket transfers are disabled when approval is required'
  const TICKET_TRANSFER_SETTING_LABEL = 'Allow Ticket Transfers'

  // Only auto-disable transfer if the user has approval required
  useEffect(() => {
    if (ticketHasApprovalRequired) onToggleTransfer(!ticketHasApprovalRequired)
  }, [ticketHasApprovalRequired])

  const toggleIsTransferable = (val: boolean) => {
    if (!canToggleTransfer) return
    onToggleTransfer(val)
  }

  return {
    shouldRenderTicketTransferSwitch,
    canToggleTransfer,
    ticketTransferLabelSubtitle,
    TICKET_TRANSFER_SETTING_LABEL,
    toggleIsTransferable,
  }
}
