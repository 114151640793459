import {useCallback, useState} from 'react'

import {Currency} from '@posh/utils'
import {useGetEventAnalytics} from 'apis/Events/useGetEventAnalytics'
import pluralize from 'helpers/pluralize'
import {useIntersectionObserverRef} from 'hooks/useIntersectionObserver'

export function EventAnalyticsSnapshot({eventId, isFree}: {eventId: string; isFree: boolean}) {
  // tracking whether the component is visible to the user so we don't fetch data when it's not visible
  // this is to prevent unnecessary API calls and to improve performance
  const [isVisible, setIsVisible] = useState(false)

  const intersectionCallback = useCallback((entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setIsVisible(true)
      }
    })
  }, [])

  const ref = useIntersectionObserverRef({
    callback: intersectionCallback,
    options: {
      threshold: 0.1,
      rootMargin: '0px',
    },
  })

  const {
    data: eventAnalytics,
    isLoading: isEventAnalyticsLoading,
    isSuccess: isEventAnalyticsSuccess,
  } = useGetEventAnalytics({eventId}, {enabled: !!eventId && isVisible})

  // These are the same variables we have on mobile.
  const ticketsSold = eventAnalytics?.totalTickets ?? 0
  const revenue = eventAnalytics?.revenue ?? 0
  const pageVisits = eventAnalytics?.pageVisits ?? 0

  const shouldShowRevenue = !isFree
  const getPluralizedTicketsSoldMetricLabel = (totalTickets: number | undefined) => {
    const nTix = totalTickets ?? 0
    return isFree ? pluralize('RSVP', nTix) : `${pluralize('Ticket', nTix)} Sold`
  }

  const statistics = [
    {
      label: 'Tickets Sold',
      value: new Intl.NumberFormat().format(ticketsSold),
      metricLabel: getPluralizedTicketsSoldMetricLabel(ticketsSold),
    },
  ]

  if (shouldShowRevenue) {
    statistics.push({
      label: 'Revenue',
      value: Currency.format(revenue, eventAnalytics?.currency ?? 'USD'),
      metricLabel: 'Revenue',
    })
  }

  statistics.push({
    label: 'Page Visits',
    value: new Intl.NumberFormat().format(pageVisits),
    metricLabel: pluralize('Page Visit', pageVisits),
  })

  return (
    <div ref={ref}>
      {isEventAnalyticsLoading && <div>Loading...</div>}
      {isEventAnalyticsSuccess && (
        <div className='flex flex-col gap-2 md:flex-row'>
          {statistics.map(stat => (
            <div
              className='flex min-w-26 flex-col items-center justify-center gap-1 rounded-md bg-[#fff1] px-3 py-2'
              key={stat.label}>
              <div className='flex items-center !font-bold text-gray-200'>{stat.value}</div>
              <div className='text-xs text-gray-500'>{stat.metricLabel}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
