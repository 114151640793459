import {cn} from '@posh/design-kit/lib/utils'
import Stripe from 'stripe'

import './styles.scss'

interface StatusBadgeProps {
  status: Stripe.Dispute.Status
  className?: string
}

const statusConfig: Record<
  Stripe.Dispute.Status,
  {
    label: string
    className: string
  }
> = {
  needs_response: {
    label: 'Needs Evidence',
    className: 'status-badge--open',
  },
  warning_needs_response: {
    label: 'Needs Evidence',
    className: 'status-badge--open',
  },
  under_review: {
    label: 'Under Review',
    className: 'status-badge--review',
  },
  warning_under_review: {
    label: 'Under Review',
    className: 'status-badge--review',
  },
  charge_refunded: {
    label: 'Closed',
    className: 'status-badge--closed',
  },
  warning_closed: {
    label: 'Closed',
    className: 'status-badge--closed',
  },
  won: {
    label: 'Won',
    className: 'status-badge--won',
  },
  lost: {
    label: 'Lost',
    className: 'status-badge--lost',
  },
}

const StatusBadge = ({status, className}: StatusBadgeProps) => {
  const config = statusConfig[status]

  return <span className={cn('status-badge', config.className, className)}>{config.label}</span>
}

export default StatusBadge
