import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {useQueryClient} from '@tanstack/react-query'
import {ITicketGroup, Ticket} from 'apis/Events/types'
import {useUpdateTicketGroups} from 'apis/Events/useUpdateTicketGroups'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {SelectOption} from 'components/form/MultiSelect'
import {TicketTypeMultiSelect} from 'components/form/MultiSelect/TicketTypeMultiSelect'
import {useToast} from 'components/toasts/ToastProvider'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'
import {useResourcePageParams} from 'pages/PoshAppLayout'

interface EditTicketGroupProps {
  ticketGroups?: ITicketGroup[]
  tickets: Ticket[]
  onDeleteTicketGroup?: () => void
}

const EditTicketGroup = (props: EditTicketGroupProps) => {
  const {ticketGroups, tickets, onDeleteTicketGroup} = props
  const {eventId, ticketGroupId} = useResourcePageParams()
  const navigate = useNavigate()
  const {showToast} = useToast()
  const queryClient = useQueryClient()
  const {mutateAsync: updateTicketGroups, isLoading} = useUpdateTicketGroups({
    onSuccess: async () => {
      showToast({type: 'success', title: 'Ticket group updated successfully.'})
      await queryClient.invalidateQueries(['event', eventId])
      navigate(-1)
    },
    onError: () => {
      showToast({type: 'error', title: 'Error updating ticket group.'})
    },
  })

  const ticketGroup = ticketGroups?.find(ticketGroup => ticketGroup._id === ticketGroupId)
  const initalOptions = tickets.map(ticket => {
    return {name: ticket.name, id: ticket.id, selected: ticketGroup?.ticketTypes.includes(ticket.id) ?? false}
  })
  const [options, setOptions] = useState<SelectOption[]>(initalOptions)
  const [name, setName] = useState(ticketGroup?.name ?? '')

  const handleSubmit = async () => {
    const ticketTypes: string[] = []
    for (const option of options) {
      if (option.selected) {
        ticketTypes.push(option.id!)
      }
    }
    if (ticketTypes.length === 0) return showToast({type: 'error', title: 'Please select at least one ticket.'})
    if (name === '') return showToast({type: 'error', title: 'Please enter a name for the ticket group.'})
    const ticketGroupsCopy = ticketGroups
    const newTicketGroup = {
      name,
      ticketTypes,
    }
    const index = ticketGroupsCopy?.findIndex(ticketGroup => ticketGroup._id === ticketGroupId)
    ticketGroupsCopy?.splice(index!, 1, newTicketGroup)
    await updateTicketGroups({
      eventId: eventId!,
      ticketGroups: ticketGroupsCopy!,
    })
  }

  const handleDelete = async () => {
    onDeleteTicketGroup?.()
    const ticketGroupsCopy = ticketGroups
    const index = ticketGroupsCopy?.findIndex(ticketGroup => ticketGroup._id === ticketGroupId)
    ticketGroupsCopy?.splice(index!, 1)
    await updateTicketGroups({
      eventId: eventId!,
      ticketGroups: ticketGroupsCopy!,
    })
  }

  return (
    <div className='CreateTicketGoup'>
      <BackAndTitle />
      <Input
        className='large fullWidth'
        placeholder='Ticket Group Name'
        type='text'
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <TicketTypeMultiSelect
        ticketOptions={tickets}
        selectedTicketIds={ticketGroup?.ticketTypes ?? []}
        updateOptions={setOptions}
        name='Select Tickets'
        emptyMessage='No tickets available for grouping.'
      />
      <div className='buttonWrapper center'>
        <Button className='redHover fullWidth dark' onClick={handleDelete} disabled={isLoading}>
          Delete Ticket Group
        </Button>
        <Button className='gold fullWidth' onClick={handleSubmit} disabled={isLoading} isLoading={isLoading}>
          Update Group
        </Button>
      </div>
    </div>
  )
}

export default EditTicketGroup
