import React from 'react'

export const UPGRADED_VISUAL_EDITOR_FEATURE_FLAG = 'web_visual_editor_upgrade'

import {UseFormReturn} from 'react-hook-form'

import {
  MultisessionChildEventBulkUpdateableFields,
  TMultisessionChildEventBulkUpdateableFields,
} from '@posh/model-types'
import {GetEventForVisualEditorOutput} from 'apis/Events/useGetEventForVisualEditor'
import {useMixpanel} from 'apis/MixPanelHandler'
import {
  UpdateMultiSessionEventInput,
  useUpdateMultiSessionEvent,
} from 'apis/MultiSessionEvents/useUpdateMultiSessionEvent'
import Button from 'components/form/Button'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import moment from 'moment'

import styles from './styles.module.scss'

interface UpdateSeriesEventsModalProps {
  isOpen: boolean
  onClose: () => void
  form: UseFormReturn<EventVisualsForm>
  event: GetEventForVisualEditorOutput
  submit: () => void
}

// :)

export const fieldsToTriggerAskToUpdateSeries = new Set([
  ...MultisessionChildEventBulkUpdateableFields,
  'passwordEnabled',
])

const friendlyFieldNames: Record<TMultisessionChildEventBulkUpdateableFields | 'passwordEnabled', string> = {
  name: 'Name',
  flyer: 'Flyer',
  description: 'Description',
  shortDescription: 'Short Description',
  timezone: 'Timezone',
  venue: 'Venue',
  location: 'Location',
  guestlistEnabled: 'Guestlist',
  attendanceDisplayDisabled: 'Attendance Display',
  isLocationHiddenUntilEventDate: 'Location Hidden Until Event Date',
  displayGroupName: 'Display Group Name',
  displayGroupImage: 'Display Group Image',
  displayOnThirdPartySites: 'Show on Explore',
  lightmode: 'Lightmode',
  recurrence: 'Recurrence',
  password: 'Password',
  passwordProtected: 'Password Protected',
  passwordEnabled: 'Password Protected',
  displayEndTime: 'Display End Time',
  accentColor: 'Accent Color',
  eventTitleFont: 'Event Title Font',
  youtubeLink: 'Youtube Link',
  applyTo: 'Apply To',
  song: 'Song',
  activityEnabled: 'Activity',
}

export function UpdateSeriesEventsModal(props: UpdateSeriesEventsModalProps) {
  const {isOpen, onClose, form, event, submit} = props

  const {handleSubmit, formState} = form

  const {trackEvent} = useMixpanel()

  const {mutate: updateMultiSessionEvent} = useUpdateMultiSessionEvent({
    onSuccess: onClose,
  })

  const submitAndUpdateEventsInSeries = (
    applyTo: NonNullable<UpdateMultiSessionEventInput['eventTemplate']>['applyTo'] | {type: 'just-this'},
  ) =>
    handleSubmit(data => {
      submit() // update THIS event
      if (!!event.multiSessionEvent) {
        const dirtyFieldsOnly = Object.keys(formState.dirtyFields).reduce(
          (acc, field) => {
            if (fieldsToTriggerAskToUpdateSeries.has(field)) {
              acc[field] = data[field as keyof typeof data]
            }
            return acc
          },
          {} as Record<string, EventVisualsForm[keyof EventVisualsForm]>,
        )
        if (Object.keys(dirtyFieldsOnly).length === 0) return
        trackEvent('Event Visual Editor- Multisession Bulk Edit Modal', {
          action: applyTo?.type === 'all-starting-after' ? 'all-future' : 'just-this',
          modifiedFields: Object.keys(dirtyFieldsOnly),
        })

        if (applyTo?.type === 'just-this') return

        dirtyFieldsOnly.passwordProtected = dirtyFieldsOnly.passwordEnabled
        updateMultiSessionEvent({
          id: event.multiSessionEvent.id,
          groupId: event.groupId,
          eventTemplate: {
            ...dirtyFieldsOnly,
            applyTo,
          },
        })
      }
    })()

  return (
    <PoshStyledModal isOpen={isOpen} onClose={onClose}>
      <h3>Save changes?</h3>
      <p>
        This event is part of a series. Do you want to update the following fields on other events or just this one?
      </p>
      <ul>
        {Object.entries(formState.dirtyFields)
          .filter(([field]) => fieldsToTriggerAskToUpdateSeries.has(field as any))
          .map(([field]) => (
            <li key={field}>{friendlyFieldNames[field as keyof typeof friendlyFieldNames]}</li>
          ))}
      </ul>
      <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
        <div style={{display: 'flex'}}>
          <div className={styles.buttonsContainer}>
            <Button
              className='fullWidth dark'
              onClick={() =>
                submitAndUpdateEventsInSeries({type: 'all-starting-after', date: moment.utc(event.startUtc).toDate()})
              }>
              Update this and future events
            </Button>
            <Button
              className='fullWidth'
              style={{gridColumn: '1 / -1'}}
              onClick={() => submitAndUpdateEventsInSeries({type: 'just-this'})}>
              Update this event only
            </Button>
          </div>
        </div>
      </div>
    </PoshStyledModal>
  )
}
