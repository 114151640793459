import React from 'react'

import Button from 'components/form/Button'
import {HardLink} from 'components/HardLink'
import {useHardNavigation} from 'hooks/navigation/useHardNavigation'

export const SecretArea51 = () => {
  const hardNavigate = useHardNavigation()
  return (
    <div className='flex h-screen w-screen items-center justify-center'>
      <h1>Secret Area 51</h1>
      <Button onClick={() => hardNavigate('/test-next/ssr')}>To Next SSR</Button>
      <Button onClick={() => hardNavigate('/test-next/csr')}>To Next CSR</Button>
      <HardLink path='/test-next/ssr'>To Next SSR</HardLink>
      <HardLink path='/test-next/csr'>To Next CSR</HardLink>
    </div>
  )
}
