import React from 'react'
import {useNavigate} from 'react-router-dom'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import {getGroupAvi} from '@posh/model-types'
import {AccountRoleResponse} from 'apis/Roles'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImageBackground} from 'components/PoshImage/PoshImage'
import {useNavigateToGroupsPage} from 'hooks/navigation/useNavigateToGroupsDashboard'

import {useFetchAccountGroups} from '../../../apis/Accounts/useFetchAccountGroups'

interface GroupOptionAttributes {
  id: string
  name: string
  image?: string
  accountRole: AccountRoleResponse
}

const GroupOption = ({groupOptionAttrs}: {groupOptionAttrs: GroupOptionAttributes}) => {
  const {navigateToGroupsDashboardAndSetRole} = useNavigateToGroupsPage()

  const handlePickGroup = () =>
    navigateToGroupsDashboardAndSetRole({groupId: groupOptionAttrs.id, accountRole: groupOptionAttrs.accountRole})

  return (
    <a className='GroupImageButton' onClick={handlePickGroup}>
      <div className='GroupImageButton-content'>
        <PoshImageBackground
          className='GroupImageButton-groupBackground'
          style={{backgroundImage: `url('${groupOptionAttrs.image}')`}}
        />
        <div className='GroupImageButton-filter' />
        <div className='GroupImageButton-nameCont'>
          <img src={groupOptionAttrs.image} alt='Group Image' />
          <div className='GroupImageButton-name'>{groupOptionAttrs.name}</div>
        </div>
        <div className='GroupImageButton-role'>{groupOptionAttrs.accountRole.roleKey}</div>
        <div className='GroupImageButton-cta'>Manage Organization &#187;</div>
      </div>
    </a>
  )
}

const GroupsSelector = () => {
  const navigate = useNavigate()

  const {data: accountGroupUnions, isFetching: accountGroupsIsFetching, isError} = useFetchAccountGroups()

  const isFetching = accountGroupsIsFetching

  const Groups = () => {
    if (!accountGroupUnions) return null
    return (
      <>
        {accountGroupUnions!.map(groupAccountRoleUnions => {
          const {group, accountRole} = groupAccountRoleUnions

          const groupOptionAttrs: GroupOptionAttributes = {
            id: group._id,
            name: group.name,
            image: getGroupAvi(group),
            accountRole,
          }
          return !group.isDeleted && <GroupOption key={groupOptionAttrs.id} groupOptionAttrs={groupOptionAttrs} />
        })}
        <a className='GroupImageButton' onClick={() => navigate('/create_group')}>
          <div className='GroupImageButton-content'>
            <div className='GroupImageButton-groupBackground' style={{background: '#3a3b3d'}} />
            <div className='GroupImageButton-filter' />
            <div className='GroupImageButton-name'>+ New Organization</div>
            <div className='GroupImageButton-cta'>Create Organization &#187;</div>
          </div>
        </a>
      </>
    )
  }

  return (
    <div className='GroupsDash'>
      <div className='GroupsDash-inner'>
        <h2>My Organizations</h2>
        <div className='GroupsSelector'>
          {isError && <p>Something went wrong while fetching for your groups :(</p>}
          <div className='GroupsSelector-options'>{isFetching ? <SpinLoader height={80} /> : <Groups />}</div>
        </div>
      </div>
    </div>
  )
}

export default GroupsSelector
