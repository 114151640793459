import React from 'react'
import {useParams} from 'react-router-dom'

import {useFetchEventByUrl} from 'apis/Events/useFetchEvent'
import {SafeMarkdownDisplay} from 'components/Text/SafeMarkdownDisplay'

import './styles.scss'

const EventTOS = () => {
  const {eventUrl: eventUrlParams} = useParams<{eventUrl: string}>()
  const {data: eventData} = useFetchEventByUrl(eventUrlParams)
  const tos = eventData?.event.termsOfService

  return (
    <div className='EventTOS'>
      <SafeMarkdownDisplay source={tos} />
    </div>
  )
}

export default EventTOS
