import React, {PropsWithChildren} from 'react'

import classNames from 'classnames'

import styles from './RecurrenceSettingsItem.module.scss'

interface RecurrenceSettingsItemProps {
  label?: string
  className?: string
  children: React.ReactNode
  resizeVertical?: boolean
}

export const RecurrenceSettingsItem = (props: PropsWithChildren<RecurrenceSettingsItemProps>) => {
  const {className, children, label, resizeVertical} = props
  return (
    <div className={classNames(styles.RecurrenceSettingsItem, resizeVertical && styles.resizeVertical, className)}>
      {label && <p>{label}</p>}
      {children}
    </div>
  )
}
