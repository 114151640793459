import React from 'react'
import {useNavigate} from 'react-router-dom'

import {EventPublic, GroupPublic} from 'apis/Events/types'
import {Crown, LocationPin} from 'components/assets/Icons'

interface EventInfoTableProps {
  hostName?: string
  event: EventPublic
  group: GroupPublic
  isPersonalEvent: boolean
  shouldShowRSVPToViewLocation: boolean
  handleRSVPCheckout: () => void
  isAwaitingApproval: boolean
}

const EventInfoTable = (props: EventInfoTableProps) => {
  const {
    hostName,
    event,
    group,
    isPersonalEvent,
    shouldShowRSVPToViewLocation,
    handleRSVPCheckout,
    isAwaitingApproval,
  } = props
  const navigate = useNavigate()

  return (
    <table className='EventPage-detailText EventPage-secondaryText'>
      <tbody>
        {isPersonalEvent && hostName && (
          <tr>
            <td>
              <Crown className='EventPage-icon' style={{fill: `${event.accentColor}`}} />
            </td>
            <td style={{color: `${event.accentColor}`}}>
              Hosted by{' '}
              <span className='epst-prompt' onClick={() => navigate(`/g/${group.url}`)}>
                {hostName}
              </span>
            </td>
          </tr>
        )}
        {event.venue.address && (
          <tr>
            <td>
              <LocationPin className='EventPage-icon' color={event.accentColor} />
            </td>
            <td style={{color: `${event.accentColor}`}}>
              {shouldShowRSVPToViewLocation ? (
                <>
                  {isAwaitingApproval ? (
                    <i style={{color: '#bbb'}}>Event location will be displayed once accepted</i>
                  ) : (
                    <>
                      <span className='epst-prompt' onClick={handleRSVPCheckout}>
                        Get on the list
                      </span>
                      <i style={{color: '#bbb'}}> to see location</i>
                    </>
                  )}
                </>
              ) : (
                <>{event.venue.address}</>
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default EventInfoTable
