import React, {useEffect, useState} from 'react'

import {OrderTag} from 'apis/Orders'
import {useAggregateOrders} from 'apis/Orders/useAggregateOrders'
import getAvi from 'apis/Util/getAvi'
import {searchIconUrl} from 'assets/images/s3images'
import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import TextIconInput from 'components/TextInput'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import useDebounce from 'hooks/useDebounce'
import {isEmpty} from 'lodash'

import {OrderItem} from '../../../EventManagementPages/OverviewUpdate/Analytics/Orders/OrderItem/OrderItem'
import FilterModal from '../FilterModal'

interface OrdersListViewProps {
  expanded: boolean
  showFilter?: boolean
}

const OrdersListView = (props: OrdersListViewProps) => {
  const {expanded, showFilter} = props
  const {groupId} = useGroupMatchParams()

  const urlSearch = new URLSearchParams(window.location.search)
  const activeFilterParams = expanded ? urlSearch.get('f') : undefined
  const searchParam = expanded ? urlSearch.get('s') : undefined
  const eventIdSearchParam = expanded ? urlSearch.get('e') : undefined
  const [search, setSearch] = useState<string | undefined>(searchParam ?? undefined)
  const debouncedSearch = useDebounce(search, 500)
  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false)
  const [activeFilters, setActiveFilters] = useState<OrderTag[]>(
    activeFilterParams ? (activeFilterParams.split(',') as OrderTag[]) : [],
  )

  const {data: orders, isFetching: isFetchingOrders} = useAggregateOrders({
    expanded,
    activeFilters,
    search: debouncedSearch,
    groupId: groupId ?? '',
    eventId: eventIdSearchParam,
  })

  const handleFilterConfirm = (selected: OrderTag[]) => {
    setActiveFilters(selected)
    setFilterModalIsOpen(false)
  }

  useEffect(() => {
    if (expanded) setActiveFilters(activeFilterParams ? (activeFilterParams.split(',') as OrderTag[]) : [])
  }, [activeFilterParams])

  return (
    <>
      <FilterModal
        onConfirm={selected => handleFilterConfirm(selected as OrderTag[])}
        isOpen={filterModalIsOpen}
        onClose={() => setFilterModalIsOpen(false)}
        defaultSelected={activeFilters}
      />
      <div className='Orders-toolbar'>
        <RequirePermissions requiredPermissions={[requirePermissionAndScope('search_orders', ['group'])]}>
          <TextIconInput
            icon={searchIconUrl}
            placeholder={`Search (${eventIdSearchParam ? '' : 'Event Name, '}Attendee Name, Email, Order #)`}
            value={search ?? ''}
            onChange={e => setSearch(e.target.value)}
          />
        </RequirePermissions>
        {showFilter && (
          <RequirePermissions requiredPermissions={[requirePermissionAndScope('filter_orders', ['group'])]}>
            <Button onClick={() => setFilterModalIsOpen(true)} className='dark'>
              <PoshImage src='https://images.posh.vip/b2/filter.svg' />
              Filter
            </Button>
          </RequirePermissions>
        )}
      </div>
      <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_orders_list', ['group'])]}>
        {!isFetchingOrders ? (
          <div className='flex flex-1 flex-col gap-2 overflow-y-scroll rounded-md'>
            {orders?.map(order => (
              <OrderItem
                key={order.order._id}
                eventName={order.order.eventName ?? ''}
                orderNumber={order.order.number}
                orderDate={order.order.createdAt?.toString()}
                orderSubtotal={order.order.subtotal}
                orderCurrency={order.order.currency ?? 'USD'}
                accountName={order.order.accountName ?? ''}
                accountAvatar={getAvi({avi: order.order.accountAvi})}
                tags={order.tags}
                orderId={order.order._id}
                accountId={order.order.accountID}
                isRSVPOrder={order.isRSVPOrder}
                trackingLink={order.order.trackingLink}
                eventId={order.order.eventID ?? ''}
              />
            ))}
            {isEmpty(orders) && <div style={{color: 'white'}}>No orders found.</div>}
          </div>
        ) : (
          <div className='Orders-orderContainer'>
            {[1, 2, 3, 4, 5].map((_, index) => (
              <OrderItem.Loading key={index} />
            ))}
          </div>
        )}
      </RequirePermissions>
    </>
  )
}

export default OrdersListView
