import {Button} from '@posh/design-kit/components/button'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@posh/design-kit/components/drawer'
import {POSH_MESSAGING_AGREEMENT_STRING} from '@posh/model-types'
import {Bell, BellOff, BellRing} from 'lucide-react'

interface OptinPopUpDrawerProps {
  entityDisplayName: string
  open: boolean
  onOptIn: () => void
  onOpenChange: (open: boolean) => void
}

// TODO:Jeff remove dark once next-themes is implemented
export default function OptinModalPresentation({
  entityDisplayName,
  open,
  onOptIn,
  onOpenChange,
}: OptinPopUpDrawerProps) {
  return (
    <Drawer open={open} onAnimationEnd={onOpenChange}>
      <DrawerContent className='dark'>
        <div className='mx-auto w-full max-w-5xl'>
          <DrawerHeader className='flex flex-col items-start justify-center gap-2'>
            <DrawerTitle asChild>
              <div className='m-auto flex flex-col items-center gap-0 text-center sm:mx-0 sm:flex-row sm:gap-2'>
                <BellRing className='m-auto h-10 w-10 pt-3 text-primary sm:h-12 sm:w-12' />
                <h3 className='mt-0 text-left'>Stay in the Loop</h3>
              </div>
            </DrawerTitle>
            <DrawerDescription asChild>
              <div className='text-center'>
                You&lsquo;re now following {entityDisplayName}, but you won&lsquo;t receive notifications about their
                updates.
              </div>
            </DrawerDescription>
          </DrawerHeader>
          <div className='px-4'>
            <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
              <div className='flex items-start gap-4 rounded-lg border p-4'>
                <div className='mt-0.5 rounded-full bg-primary/10 p-2'>
                  <Bell className='block h-5 w-5 text-primary' />
                </div>
                <div>
                  <div className='text-sm font-medium text-primary'>Get Notified</div>
                  <div className='text-sm text-muted-foreground'>
                    Be the first to hear about new events, exclusive updates, and special invitations.
                  </div>
                </div>
              </div>
              <div className='flex items-start gap-4 rounded-lg border p-4'>
                <div className='mt-0.5 rounded-full bg-muted p-2'>
                  <BellOff className='block h-5 w-5 text-muted-foreground' />
                </div>
                <div>
                  <div className='text-sm font-medium text-muted-foreground'>Stay in the dark</div>
                  <div className='text-sm text-muted-foreground'>
                    Without notifications, you might miss out on important updates!
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-2 mb-4 px-1 text-xs text-muted-foreground'>{POSH_MESSAGING_AGREEMENT_STRING}</div>
          </div>
          <DrawerFooter className='sm:flex-row sm:justify-end sm:gap-2'>
            <Button className='rounded-full border-0' onClick={onOptIn}>
              Enable notifications
            </Button>
            <DrawerClose asChild>
              <Button className='rounded-full border text-secondary-foreground' variant='outline'>
                Maybe later
              </Button>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  )
}
