import React, {useMemo, useState} from 'react'
import {useLocation} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import {FeatureFlag, groupEventsByMultiSessionEvent} from '@posh/model-types'
import {TEventStatus} from '@posh/server/dist/src/models/event'
import {useUpdateEvent_DEPRECATED} from 'apis/Events/useUpdateEventDEPRECATED'
import {GetGroupEventsEvent} from 'apis/Groups/useFetchGroupEvents'
import {useMixpanel} from 'apis/MixPanelHandler'
import useSessionContext from 'domains/Auth/SessionContext'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {useFeatureGate} from 'hooks/useFeatureGate'
import {trpc} from 'lib/trpc'
import moment from 'moment-timezone'
import {calculateEventStatus} from 'pages/Util/timezoneHelper'

import {EventsTableItem} from './ListItem'
import {MultisessionDisplay} from './ListItem/MultisessionDisplay'
import {EventRowAction} from './ListItem/types'

import './index.scss'

interface Props {
  data: GetGroupEventsEvent[]
  onRowAction?: (action: EventRowAction, eventId: string) => void
  isHost?: boolean
  activeOnly: boolean
  isFetching?: boolean
}

const EventsTable = (props: Props) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {currentUser} = useSessionContext()
  const {groupId} = useGroupMatchParams()
  const search = useLocation().search
  const {isHost, activeOnly, data, isFetching} = props
  const {mutateAsync: updateEvent} = useUpdateEvent_DEPRECATED()
  const queryClient = trpc.useContext()
  const [loaderIndex, setLoaderIndex] = useState<number | null>(null)

  const mse_ff = useFeatureGate(FeatureFlag.MULTISESSIONEVENTS_ORGANIZER)
  const event_analytics_snapshot_ff = useFeatureGate(FeatureFlag.EVENT_ANALYTICS_SNAPSHOT)

  const updateEventStatus = async (status: TEventStatus, eventId: string | undefined, index: number) => {
    setLoaderIndex(index)
    await updateEvent({
      eventId: eventId!,
      groupId: groupId!,
      eventData: {
        status: status,
      },
    })
    // I'd love to stick this into the `useUpdateEvent` hook to be universal - but
    // I'd rather avoid any unintended side effects!
    if (groupId) {
      queryClient.groups.getGroupEvents.invalidate({groupId})
    }
  }

  const groupedEvents = useMemo(
    () =>
      mse_ff
        ? groupEventsByMultiSessionEvent<GetGroupEventsEvent, Required<GetGroupEventsEvent>['multiSessionEvent']>(data)
        : data.map(event => ({...event, isMultiSessionGroup: false as const})),
    [data, mse_ff],
  )

  return (
    <div className='EventTable'>
      <ReactTooltip className='poshToolTip' effect='solid' place='top' id='hoverIcon' />
      <div className='EventTable-rows'>
        {groupedEvents.map((item, index) => {
          if (item.isMultiSessionGroup) {
            return (
              <MultisessionDisplay
                key={index}
                multisessionGroup={item}
                trackingLink={currentUser!.trackingLink ?? ''}
                isLoading={!!isFetching && loaderIndex === index}
                onRowAction={props.onRowAction}
                updateEventStatus={(status: TEventStatus, eventId: string) => updateEventStatus(status, eventId, index)}
                trackMixpanelEvent={trackMixpanelEvent}
                groupId={groupId!}
                isHost={!!isHost}
                search={search}
              />
            )
          }
          const status = calculateEventStatus(moment.tz(item.endUtc, item.timezone), item.status)
          const isLive = status == 'live'
          const isDraft = status == 'draft'
          if (!activeOnly || (activeOnly && isLive) || isDraft) {
            return (
              <EventsTableItem
                key={index}
                item={item}
                status={status}
                trackingLink={currentUser!.trackingLink ?? ''}
                isLoading={!!isFetching && loaderIndex === index}
                onRowAction={props.onRowAction}
                updateEventStatus={(status: TEventStatus, eventId: string) => updateEventStatus(status, eventId, index)}
                trackMixpanelEvent={trackMixpanelEvent}
                groupId={groupId!}
                isHost={!!isHost}
                search={search}
                renderAnalyticsSnapshot={event_analytics_snapshot_ff}
              />
            )
          }
        })}
      </div>
    </div>
  )
}

export default EventsTable
