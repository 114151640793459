import React, {PropsWithChildren, useCallback, useState} from 'react'

import classNames from 'classnames'
import {Modal} from 'components/coreComponents/Modal'
import {X as XIcon} from 'lucide-react'

import './styles.scss'

export interface PoshStyledModalProps {
  isOpen: boolean
  onClose: () => void
  className?: string
  width?: number
  noborder?: boolean
  center?: boolean
  contentClassName?: string
  hideHeader?: boolean
  style?: React.CSSProperties
  enablePressEscapeToClose?: boolean
  disableOutsideClickToClose?: boolean
}

export default function PoshStyledModal({
  isOpen,
  onClose,
  children,
  className,
  width,
  noborder,
  center,
  contentClassName,
  hideHeader,
  style,
  enablePressEscapeToClose,
  disableOutsideClickToClose = false,
}: PropsWithChildren<PoshStyledModalProps>) {
  const [closing, setClosing] = useState<boolean>(false)
  const closeModal = () => {
    if (!isOpen) return
    setClosing(true)
    setTimeout(() => {
      onClose()
      setClosing(false)
    }, 200)
  }

  const backgroundClassNames = classNames('Modal', className)
  const modalContentClassNames = classNames('Modal-content', noborder, center, closing, contentClassName)

  const Background = useCallback(() => {
    return <div className={backgroundClassNames} />
  }, [backgroundClassNames])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      Background={Background}
      enablePressEscapeToClose={enablePressEscapeToClose}>
      <div className='Modal-content-position' onClick={!disableOutsideClickToClose ? closeModal : undefined}>
        <div
          className={modalContentClassNames}
          style={{...style, maxWidth: `${width}`}}
          onClick={e => e.stopPropagation()}>
          {!hideHeader && (
            <div className='Modal-header'>
              <XIcon className='Modal-content--deleteButton' onClick={closeModal} color='white' />
            </div>
          )}
          {children}
        </div>
      </div>
    </Modal>
  )
}
