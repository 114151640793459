import React, {useEffect, useState} from 'react'

import {ALLOWED_IMAGE_FORMATS} from '@posh/types'
import classNames from 'classnames'
import {CircleX, CloudUploadIcon} from 'components/assets/Icons'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'

import './FileUpload.styles.scss'

export const asyncGetBase64Encoding = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = error => {
      reject(error)
    }
  })
}

export function getBase64Encoding(
  file: File,
  onSuccess: (result: FileReader['result']) => void,
  onError: (error: ProgressEvent<FileReader>) => void,
) {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function (e) {
    if (e.target) onSuccess(e.target.result)
  }
  reader.onerror = function (error) {
    onError(error)
  }
}

function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)?.[1]
  const bstr = atob(arr[arr.length - 1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, {type: mime})
}

interface FileUploadProps {
  onFileUploaded: (fileName: string, fileBase64: string) => void
  onFileRemoved: () => void
  disableRemoveButton: boolean
  defaultFileBase64?: string
  defaultFileName?: string
}

const FileUpload = ({
  onFileUploaded,
  onFileRemoved,
  disableRemoveButton,
  defaultFileBase64,
  defaultFileName,
}: FileUploadProps) => {
  const [isDragging, setIsDragging] = useState(false)
  const [localFileUrl, setLocalFileUrl] = useState('')
  const {showToast} = useToast()

  useEffect(() => {
    if (defaultFileBase64 && !localFileUrl) {
      const file = dataURLtoFile(defaultFileBase64, 'file')
      setLocalFileUrl(URL.createObjectURL(file))
    }
  }, [defaultFileBase64, localFileUrl])

  const handleDrag: React.DragEventHandler = e => {
    e.preventDefault()
    e.stopPropagation()
    const {type} = e
    if (type === 'dragenter' || type === 'dragover') {
      setIsDragging(true)
    } else if (type === 'dragleave') {
      setIsDragging(false)
    }
  }

  const handleImageDrop = (files: FileList | null) => {
    if (files && files[0]) {
      const file = files[0]
      if (!ALLOWED_IMAGE_FORMATS.includes(file.type)) {
        showToast({
          type: 'error',
          title: 'Invalid file type',
          subtitle: 'Try uploading a .png, .jpg, .jpeg, or .pdf file.',
        })
        return
      }

      const localFileUrl = URL.createObjectURL(file)
      setLocalFileUrl(localFileUrl)
      getBase64Encoding(
        file,
        result => {
          onFileUploaded(file.name, result as string)
        },
        error => {
          showToast({type: 'error', title: 'There was an error uploading your file', subtitle: 'Try again later.'})
        },
      )
    }
  }

  const handleDrop: React.DragEventHandler = e => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
    const {files} = e.dataTransfer
    handleImageDrop(files)
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    e.preventDefault()
    const {files} = e.target
    handleImageDrop(files)
    // If we don't reset the value and try to upload the same file twice it won't work (because onChange)
    e.target.value = ''
  }

  const onClickRemove = () => {
    setLocalFileUrl('')
    onFileRemoved()
  }

  return (
    <div className={classNames('FileUpload', {['FileUpload-dragActive']: isDragging})} onDragEnter={handleDrag}>
      <div className='FileUpload-icon'>
        {!localFileUrl && (
          <>
            <CloudUploadIcon />
            <label htmlFor='file' />
          </>
        )}
      </div>
      <div>
        <input
          className='FileUpload-fileInput'
          type='file'
          id='file'
          accept='.png, .jpg, .pdf, .jpeg'
          onChange={handleChange}
        />
        {localFileUrl && (
          <div className='FileUpload-filePreview'>
            <PoshImage src={localFileUrl} />
            {!disableRemoveButton && <CircleX onClick={onClickRemove} />}
          </div>
        )}
      </div>
      {defaultFileName && <p className='m-0'>{defaultFileName}</p>}
      {isDragging && (
        <div
          className='FileUpload-draggingOverlay'
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </div>
  )
}

export default FileUpload
