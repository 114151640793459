import React from 'react'

import {Checkbox} from 'components/form/checkbox/Checkbox'

import './styles.scss'

function Lightning({position}: {position: `${'top' | 'bottom'} ${'left' | 'right'}`}) {
  return (
    <div className={`lightning ${position}`}>
      <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_593_160)'>
          <path
            d='M11.1058 6.03037H7.05034L9.07235 0.5L2.89453 8.96963H6.94999L4.92798 14.5L11.1058 6.03037Z'
            fill='#FFBAFC'
          />
        </g>
        <defs>
          <clipPath id='clip0_593_160'>
            <rect width='14' height='14' fill='white' transform='translate(0 0.5)' />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default function SparkOptInBanner({isChecked, onChecked}: {isChecked: boolean; onChecked: () => void}) {
  return (
    <div className='SparkOptInBanner'>
      <div className='SparkOptInBanner-sparkPill'>
        <Lightning position='bottom left' />
        <Lightning position='top right' />
        <p className='m-0'>Spark</p>
      </div>
      <div className='body'>
        <p className='m-0'>
          <span className='bold'>This event is Spark enabled</span>
          <br />
          Add a selfie to your profile, and Spark&apos;s facial recognition technology will automatically highlight any
          event photos you&apos;re featured in.
        </p>
      </div>
      <Checkbox onChecked={onChecked} checked={isChecked} label='Opt in to Spark' transparent />
    </div>
  )
}
