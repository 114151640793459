import React, {CSSProperties, ReactNode} from 'react'

const STYLE: CSSProperties = {
  width: '100%',
  height: '100%',
  paddingTop: '40px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}
const SMSCampaignBackground = ({children, center}: {children: ReactNode; center?: boolean}) => {
  if (center) {
    return (
      <div style={{...STYLE, justifyContent: 'center'}} className='SMSCampaignBackground'>
        {children}
      </div>
    )
  }
  return (
    <div style={STYLE} className='SMSCampaignBackground'>
      {children}
    </div>
  )
}

export default SMSCampaignBackground
