import React from 'react'

import {AccountCapabilityStatus, getCapabilityStatusDisplay, getCapabilityStatusExplanation} from '@posh/model-types'
import classNames from 'classnames'
import {QuestionMarkInSolidCircle} from 'components/assets/Icons'
import Badge from 'components/Badge'
import {WithToolTip} from 'components/form/PoshInfo'
import {startCase} from 'lodash'

import styles from './AccountStatusRow.module.scss'

const AccountCapabilityTag = ({label, variant}: {label: string; variant: 'all-good' | 'urgent' | 'not-so-urgent'}) => {
  return (
    <Badge
      className={classNames({
        'Badge--green': variant === 'all-good',
        'Badge--red': variant === 'urgent',
        'Badge--yellow': variant === 'not-so-urgent',
      })}>
      {startCase(label)}
    </Badge>
  )
}

type AccountStatusRowProps = {
  capability: string
  capabilityStatus: AccountCapabilityStatus
}

const ICON_SIZE = 20
export const AccountStatusRow = ({capability, capabilityStatus}: AccountStatusRowProps) => {
  const tooltipText = getCapabilityStatusExplanation(capabilityStatus)
  const variant =
    capabilityStatus === 'enabled'
      ? 'all-good'
      : ['disabled', 'info required now'].includes(capabilityStatus)
        ? 'urgent'
        : 'not-so-urgent'
  // to avoid confusion for our users we want to show "info required now" as "disabled"
  const label = getCapabilityStatusDisplay(capabilityStatus)

  return (
    <div className={styles.AccountStatusRow}>
      <p className={styles.Text}>{capability}</p>
      <div style={{display: 'flex', alignItems: 'center', gap: '2px'}}>
        <AccountCapabilityTag label={label} variant={variant} />
        {tooltipText && (
          <WithToolTip info={tooltipText} align='left'>
            <QuestionMarkInSolidCircle height={ICON_SIZE} width={ICON_SIZE} />
          </WithToolTip>
        )}
      </div>
    </div>
  )
}
