import React, {PropsWithChildren} from 'react'

import {palettes} from '@posh/design-tokens'
import {Time} from '@posh/model-types'
import {useMixpanel} from 'apis/MixPanelHandler'
import {RSVPEventOption, SellTicketsEventOption} from 'components/assets/Icons'
import {PageOverlay, PageOverlayProps} from 'components/PageComponents/EventVisuals/Page/PageOverlay/PageOverlay'
import useSessionContext from 'domains/Auth/SessionContext'
import {motion} from 'framer-motion'
import {useDefaultBackNavigation} from 'hooks/navigation/useDefaultBackNavigation'
import {useDimensions} from 'hooks/useDimensions'

import styles from './styles.module.scss'

interface InitializeEventParams {
  isPaidEvent: boolean
}

interface CreateEventOnboardingOverlayProps extends PageOverlayProps {
  onCreateEvent: (params: InitializeEventParams) => void
  isSubmitting?: boolean
}

// Animation variables
const ANIMATION_DELAY = new Time('0.5 seconds')
const ANIMATION_INTERVAL = new Time('0.2 seconds')

const CREATE_EVENT_OPTION_ICON_SIZE_DESKTOP = 100
const CREATE_EVENT_OPTION_ICON_SIZE_MOBILE = 75

export const CreateEventOnboardingOverlay = (props: CreateEventOnboardingOverlayProps) => {
  const {onCreateEvent, isSubmitting, isOpen} = props
  const {trackEvent} = useMixpanel()
  const {currentUser} = useSessionContext()

  const {goBack: onPressCancel} = useDefaultBackNavigation()

  const initializePendingEvent = (isPaidEvent: boolean) => {
    onCreateEvent({
      isPaidEvent,
    })
    trackEvent('Create Event - Continue Creating', {isAuthed: !!currentUser, isFreeEvent: !isPaidEvent})
  }

  const isMobile = useDimensions().isMobile

  const createEventOptionIconSize = isMobile
    ? {
        width: CREATE_EVENT_OPTION_ICON_SIZE_MOBILE,
        height: CREATE_EVENT_OPTION_ICON_SIZE_MOBILE,
      }
    : {
        width: CREATE_EVENT_OPTION_ICON_SIZE_DESKTOP,
        height: CREATE_EVENT_OPTION_ICON_SIZE_DESKTOP,
      }

  return (
    <PageOverlay isOpen={isOpen}>
      <motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{duration: ANIMATION_DELAY.toSeconds()}}
        className={styles.CreateEventOverlayContent}>
        <div className={styles.titleWrapper}>
          {isMobile ? (
            <h3 className='center m-0'>{`Let's launch your next event`}</h3>
          ) : (
            <h2 className='center m-0'>{`Let's launch your next event`}</h2>
          )}
        </div>
        <div className={styles.CreateEventOptionsContainer}>
          <CreateEventOption isDisabled={isSubmitting} tilt='left' onClick={() => initializePendingEvent(true)}>
            <SellTicketsEventOption {...createEventOptionIconSize} />
            <h4>Sell tickets</h4>
          </CreateEventOption>
          <CreateEventOption isDisabled={isSubmitting} tilt='right' onClick={() => initializePendingEvent(false)}>
            <RSVPEventOption {...createEventOptionIconSize} />
            <h4>RSVP Only</h4>
          </CreateEventOption>
        </div>
        <div className={styles.Buttons}>
          <p tabIndex={0} onClick={() => onPressCancel()}>
            Cancel
          </p>
        </div>
      </motion.div>
    </PageOverlay>
  )
}

const CREATE_EVENT_OPTION_HOVER_SCALE = 1.03
const CREATE_EVENT_OPTION_HOVER_ROTATE = 3
const CREATE_EVENT_OPTION_TAP_SCALE = 0.97

const CreateEventOption = (
  props: PropsWithChildren<{
    onClick: () => void
    tilt: 'left' | 'right'
    isDisabled?: boolean
  }>,
) => {
  const {children, onClick, tilt, isDisabled = false} = props

  return (
    <motion.button
      disabled={isDisabled}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{
        ease: 'linear',
        duration: ANIMATION_DELAY.toSeconds() + 2 * ANIMATION_INTERVAL.toSeconds(),
        scale: 10,
        rotate: 5,
      }}
      whileTap={{scale: CREATE_EVENT_OPTION_TAP_SCALE}}
      whileHover={{
        scale: CREATE_EVENT_OPTION_HOVER_SCALE,
        rotate: tilt === 'left' ? -CREATE_EVENT_OPTION_HOVER_ROTATE : CREATE_EVENT_OPTION_HOVER_ROTATE,
      }}
      style={{
        backgroundColor: palettes.grayIron.value[950],
      }}
      className={styles.CreateEventOption}
      onClick={onClick}>
      {children}
    </motion.button>
  )
}
