import {useEffect, useState} from 'react'

/**
 * A hook enabling a one-time feature flag, stored in local storage.
 * Enables things like feature announcements that should only be shown once.
 * @param key The key to use for the feature flag. This key should be unique to the feature.
 * @returns A boolean indicating whether the user has viewed the feature, and a function to mark it as viewed.
 */
export const useOneTimeView = (key: string) => {
  const [hasViewed, setHasViewed] = useState<boolean>(() => {
    const storedValue = localStorage.getItem(key)
    return storedValue ? JSON.parse(storedValue) : false
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(hasViewed))
  }, [key, hasViewed])

  const markAsViewed = () => {
    setHasViewed(true)
  }

  return [hasViewed, markAsViewed] as const
}
