/** This hook is used to "hard" navigate, instead of just "soft" navigating
 * within the bounds of the SPA app and react-router. This will primarily serve to
 * "hard" navigate to a NextJS route from the SPA.
 * */
export const useHardNavigation = () => {
  return (path: string) => {
    window.history.pushState(null, '', path)
    window.location.reload()
  }
}
