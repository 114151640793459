import {useQuery} from '@tanstack/react-query'
import {GroupOrder} from 'apis/Groups'
import PoshApi from 'apis/PoshApi'

import {OrderTag} from '.'

export type AggregateOrdersParams = {
  expanded: boolean
  groupId: string
  eventId?: string | null
  search?: string
  activeFilters?: OrderTag[]
}

export type TaggedGroupOrder = GroupOrder & {
  tags: OrderTag[]
  number: number
  date: string
  isRSVPOrder: boolean
}

export async function aggregateOrders(params: AggregateOrdersParams): Promise<TaggedGroupOrder[]> {
  const response = await PoshApi.aggregateOrders(params)
  const data: TaggedGroupOrder[] = response.data.groupOrders
  return data
}

export function useAggregateOrders(params: AggregateOrdersParams) {
  return useQuery<TaggedGroupOrder[]>(['aggregatedOrders', params], () => aggregateOrders(params))
}
