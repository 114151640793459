// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".row__6ogLM{display:flex;gap:10px;width:100%;align-items:center;animation:fadein__EmxqV .3s}", "",{"version":3,"sources":["webpack://./src/pages/EventManagementPages/Visuals/Upgrade-dev/components/page-sections/AdmissionSection/TicketEditorPanel/styles.module.scss"],"names":[],"mappings":"AAAA,YACE,YAAA,CACA,QAAA,CACA,UAAA,CACA,kBAAA,CACA,2BAAA","sourcesContent":[".row {\n  display: flex;\n  gap: 10px;\n  width: 100%;\n  align-items: center;\n  animation: fadein 0.3s;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "row__6ogLM",
	"fadein": "fadein__EmxqV"
};
export default ___CSS_LOADER_EXPORT___;
