export const TOSLinks = () => {
  return (
    <>
      <a className='underline' href='https://posh.vip/tos'>
        Terms of Service
      </a>{' '}
      &{' '}
      <a className='underline' href='https://posh.vip/privacy'>
        Privacy Policy.
      </a>
    </>
  )
}
