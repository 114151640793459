import React from 'react'
import ReactTooltip from 'react-tooltip'

import {ArrowLeft} from 'components/assets/Icons'
import {useBackNavigationContext} from 'hooks/navigation/useBackNavigationContext'

import {useMultiSessionEventContext} from '../../context/ManageMultiSessionEventPagesContext'

import styles from './MultiSessionOrganizerViewNavigationStyles.module.scss'

/**
 * This is a visual copy of the EventPageNavigation but applied to the MultiSessionOrganizerView. Unfortunately time
 * constraints disallowed me from doing things the right way and refactoring the EventPageNavigation to be more
 * reusable.
 */
export function MultiSessionOrganizerViewNavigation() {
  const {popBackNavigationFunction} = useBackNavigationContext()
  const {formDirtySafeGoBack} = useMultiSessionEventContext()

  return (
    <>
      <div className={styles.container}>
        <ReactTooltip className='poshToolTip' effect='solid' place='right' data-for='arrow-back' />
        <a
          style={{cursor: 'pointer'}}
          onClick={() => {
            const navigateBack = popBackNavigationFunction() ?? formDirtySafeGoBack
            navigateBack()
          }}>
          <ArrowLeft className='EventPageNavigation-backButton' data-tip='Back' id='arrow-back' />
        </a>
      </div>
    </>
  )
}
