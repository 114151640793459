import {TPoshSmsOptIn} from '@posh/sms-opt-ins'
import {SmsOptInOptions} from 'hooks/optIns'
import {useTrackRudderStackEvent} from 'hooks/useTrackRudderstackEvent'
import {produce} from 'immer'
import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

import {GetAccountOptInsInput} from './useGetAccountOptIns'

export type UpdateAccountOptIn = ReactQueryOptions['optIns']['updateAccountOptIn']
export type UpdateAccountOptInInput = RouterInputs['optIns']['updateAccountOptIn']
export type UpdateAccountOptInOutput = RouterOutput['optIns']['updateAccountOptIn']

/**
 * If the previous value is NoSubaccount we will not update the value
 * This function will create a copy of the previous opt-in and update the value
 * @param prev - TPoshSmsOptIn - The previous opt-in
 * @param value - boolean - The new value
 * @param type - 'marketing' | 'transactional' | 'all' - The type of opt-in to update
 * @param showToast - ({title, type}: {title: string; type: 'error' | 'success'}) => void - The function to show the toast
 * @returns TPoshSmsOptIn - The new opt-in
 */
export const updatePoshSmsOptIn = ({
  prev,
  value,
  type = 'all',
  toastError,
}: {
  prev: TPoshSmsOptIn
  value: boolean
  type?: 'marketing' | 'transactional' | 'all'
  toastError?: ({title, type}: {title: string; type: 'error'}) => void
}) =>
  produce(prev, draft => {
    if (draft.optInType !== 'posh-sms') return
    if ((type === 'marketing' || type === 'all') && draft.optIns?.marketing) {
      if (draft.optIns.marketing.smsOptIn === SmsOptInOptions.NoSubaccount) {
        toastError?.({title: 'Error updating account. Unblock by replying "START"', type: 'error'})
      } else {
        draft.optIns.marketing.smsOptIn = value ? SmsOptInOptions.Yes : SmsOptInOptions.NoApplication
      }
    }
    if ((type === 'transactional' || type === 'all') && draft.optIns?.transactional) {
      if (draft.optIns.transactional.smsOptIn === SmsOptInOptions.NoSubaccount) {
        toastError?.({title: 'Error updating account. Unblock by replying "START"', type: 'error'})
      } else {
        draft.optIns.transactional.smsOptIn = value ? SmsOptInOptions.Yes : SmsOptInOptions.NoApplication
      }
    }
  })

export interface UpdateAccountOptInOpts extends UpdateAccountOptIn {
  query?: GetAccountOptInsInput
  location?: string
  optInPopUpExperiment?: boolean
}

export function useUpdateAccountOptIn(opts?: UpdateAccountOptInOpts) {
  const {getAccountOptIns} = trpc.useContext().optIns
  const {trackUserMarketingOptIn} = useTrackRudderStackEvent()

  return trpc.optIns.updateAccountOptIn.useMutation({
    ...opts,
    onMutate: async input => {
      await getAccountOptIns.cancel()
      const prevAccountOptIns = getAccountOptIns.getData(opts?.query)
      getAccountOptIns.setData(opts?.query, old =>
        produce(old, draft => {
          draft?.data.forEach(optIn => {
            if (optIn.id === input.id) {
              Object.assign(optIn, input)
            }
          })
        }),
      )
      return {prevAccountOptIns}
    },
    onError: (error, input, context) => {
      if (context) {
        getAccountOptIns.setData(opts?.query, context.prevAccountOptIns)
      }
      if (opts?.onError) opts.onError(error, input, context)
    },
    onSuccess: (data, input, context) => {
      const location = opts?.location || 'unknown-location'
      const optInPopUpExperiment = opts?.optInPopUpExperiment || false

      // check if the smsOptIn has changed from no-application to yes
      const prevOptIn = context?.prevAccountOptIns?.data.find(optIn => optIn.id === input.id)
      const prevSmsOptIn = prevOptIn?.optInType === 'posh-sms' && prevOptIn?.optIns?.marketing?.smsOptIn
      const newSmsOptIn = input.optInType === 'posh-sms' && input.optIns?.marketing?.smsOptIn

      // track the mutation if the value is changing from no-application to yes
      const isChangingFromNoApplicationToYes = prevSmsOptIn !== newSmsOptIn && newSmsOptIn === 'yes'
      if (isChangingFromNoApplicationToYes) {
        trackUserMarketingOptIn({location, optInPopUpExperiment})
      }
      if (opts?.onSuccess) opts.onSuccess(data, input, context)
    },
    onSettled: data => {
      if (data) getAccountOptIns.invalidate()
    },
  })
}
