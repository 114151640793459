import React, {useState} from 'react'
import ReactCodeInput from 'react-code-input'

import {useCheckLoginCode} from 'apis/Auth/useCheckLoginCode'
import {useCheckSignupCode} from 'apis/Auth/useCheckSignupCode'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import {useToast} from 'components/toasts/ToastProvider'
import useGetCurrentPathWithParams from 'helpers/useGetCurrentPathWithParams'
import {useDimensions} from 'hooks/useDimensions'
import {phoneStyle} from 'pages/NewCheckoutPage/LoginForm/PhoneLoginForm/secondStep'

import {CreateEventFormDataModalStepProps} from '../util/createEventFormData'

export const CreateEventPhoneVerificationForm = (props: CreateEventFormDataModalStepProps) => {
  const {
    createEventFormData: {
      needsToConfirmAccount,
      hasExistingAccount,
      userData: {phone},
      loginFlow,
    },
    setCreateEventFormData,
  } = props
  const {trackEvent} = useMixpanel()
  const {showToast} = useToast()
  const [token, setToken] = useState('')
  const {mutateAsync: checkLoginCode, isLoading: isLoadingLoginCode} = useCheckLoginCode(phone)
  const {mutateAsync: checkSignupCode, isLoading: isLoadingSignupCode} = useCheckSignupCode()
  const {isMobile} = useDimensions()
  const {encodedPath} = useGetCurrentPathWithParams()

  const onVerify = async () => {
    trackEvent('Create Event Phone Verification Continue Button- Phone Verification Modal')
    if (token.length !== 6) {
      showToast({type: 'error', title: 'Please input a valid verification code'})
      return
    }
    try {
      if (needsToConfirmAccount === true) {
        const account = await checkLoginCode({phone, token, setSessionOnSuccess: false})
        window.location.href = `/confirm-account/${account.userId}?r=${encodedPath}`
      } else if (hasExistingAccount === true) {
        await checkLoginCode({phone, token})
      } else {
        const {token: signupTokenRes} = await checkSignupCode({phone, token})
        setCreateEventFormData(u => ({
          ...u,
          hasAccount: false,
          loginStep: {type: 'createAccount', previous: u.loginStep},
          userData: {
            ...u.userData,
            signUpToken: signupTokenRes,
          },
        }))
      }
    } catch (error: any) {
      showToast({type: 'error', title: error.response.data.error})
      return
    }
  }

  const verifyCodeButtonText = (() => {
    if (needsToConfirmAccount) return 'Confirm Account'
    else if (hasExistingAccount) return 'Login'
    else return 'Continue'
  })()

  return (
    <div className='CreateEventLogin'>
      <h4 className='center m-0'>Enter the verification code we sent to {phone}</h4>
      {hasExistingAccount && loginFlow === 'email' && (
        <p>
          You already have an account with this phone number. Please enter the verification code to login to your
          existing account.
        </p>
      )}
      <ReactCodeInput
        className='CheckoutPage-codeInput'
        type='number'
        autoFocus
        fields={6}
        name='phoneCode'
        inputMode={'tel'}
        inputStyle={phoneStyle(isMobile)}
        value={token}
        onChange={e => setToken(e)}
      />
      <Button
        className='createEventGrad'
        disabled={isLoadingLoginCode || isLoadingSignupCode || token.length === 0}
        onClick={onVerify}>
        {verifyCodeButtonText}
      </Button>
    </div>
  )
}
