import React, {useMemo} from 'react'

import {StripeBankAccount} from 'apis/Groups/useGetGroupPayouts'
import {useRemovePayoutMethod} from 'apis/Groups/useRemovePayoutMethod'
import {useSetDefaultPayoutMethod} from 'apis/Groups/useSetDefaultPayoutMethod'
import {Bank} from 'components/assets/Icons'
import Button from 'components/form/Button'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {useToast} from 'components/toasts/ToastProvider'
import {CreditCard} from 'lucide-react'

import './styles.scss'

interface Props extends PoshStyledModalProps {
  groupId: string
  account: StripeBankAccount
  isDeleting: boolean
  refetchPayouts: () => void
}

export default function EditBankModal(props: Props) {
  const {showToast} = useToast()
  const {isDeleting, account, groupId, refetchPayouts} = props
  const {mutateAsync: removePayoutMethod, isLoading: isRemovingPayoutMethod} = useRemovePayoutMethod({
    onSuccess: () => {
      refetchPayouts()
      showToast({type: 'success', title: 'Payout method removed'})
      props.onClose()
    },
    onError: error => {
      showToast({type: 'error', title: 'There was an issue removing your payout method', subtitle: error.message})
    },
  })
  const {mutateAsync: setDefaultPayoutMethod, isLoading: isSettingDefaultPayoutMethod} = useSetDefaultPayoutMethod({
    onSuccess: () => {
      refetchPayouts()
      showToast({type: 'success', title: 'Default payout method updated'})
      props.onClose()
    },
    onError: error => {
      showToast({
        type: 'error',
        title: 'There was an issue updating your default payout method',
        subtitle: error.message,
      })
    },
  })
  const isLoading = isRemovingPayoutMethod || isSettingDefaultPayoutMethod
  const onSetDefault = async () => {
    await setDefaultPayoutMethod({groupId, payoutMethodId: account.id})
  }
  const onDeleteBank = async () => {
    await removePayoutMethod({groupId, payoutMethodId: account.id})
  }

  const isCard = useMemo(() => {
    if (account.object === 'card') return true
    else return false
  }, [account.object])

  const title = isDeleting
    ? isCard
      ? 'Are you sure you want to delete this debit card?'
      : 'Are you sure you want to delete this bank account?'
    : isCard
      ? 'Debit cards are not eligible.'
      : 'Are you sure you want to make this your default bank account?'
  const description = isDeleting
    ? isCard
      ? 'This will remove this debit card from your account.'
      : 'This will remove this bank account from your account.'
    : isCard
      ? 'Debit cards are not eligible to be set as your default payout method. Please choose a bank account instead.'
      : 'This will direct your payouts to this bank account.'
  const buttonText = isDeleting
    ? isCard
      ? 'REMOVE DEBIT CARD'
      : 'REMOVE BANK ACCOUNT'
    : isCard
      ? 'MAKE DEFAULT DEBIT CARD'
      : 'MAKE DEFAULT BANK ACCOUNT'

  return (
    <>
      <PoshStyledModal {...props}>
        <div className='AddBankModal'>
          <h4 className='center m-0'>{title}</h4>
          <p className='center'>{description}</p>
          <div className='Payouts-bankInfoRow' style={{width: 'fit-content', margin: 'auto !important;'}}>
            <div className='Payouts-bankInfoContainer'>
              {isCard ? (
                <CreditCard className='Payouts-creditCard' color='#4a4b4d' />
              ) : (
                <Bank className='Payouts-bankIcon' />
              )}
              <div>
                <div>{account.bankName}</div>
                <div>{`••••••${account.last4}`}</div>
                {account.isDefaultBankAccount && (
                  <div className='Payouts-bankInfoRow-defaultAccount'>Default Account</div>
                )}
              </div>
            </div>
          </div>
          <div className='buttonWrapper'>
            <Button className='dark' disabled={isLoading} onClick={() => props.onClose()}>
              CANCEL
            </Button>
            {(isDeleting || !isCard) && (
              <Button disabled={isLoading} onClick={isDeleting ? onDeleteBank : onSetDefault}>
                {buttonText}
              </Button>
            )}
          </div>
        </div>
      </PoshStyledModal>
    </>
  )
}
