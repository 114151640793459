import React from 'react'

import styles from './RecurrenceSettingsSelect.module.scss'

interface RecurrenceSettingsSelectProps<T extends string | number | boolean> {
  options: Array<{label: string; value: T}>
  value: T
  onChange: (value: T) => void
}

export const RecurrenceSettingsSelect = <T extends string | number | boolean>(
  props: RecurrenceSettingsSelectProps<T>,
) => {
  const {options, value, onChange} = props

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = options.find(option => option.value.toString() === e.target.value)?.value

    if (selectedValue !== undefined) {
      onChange(selectedValue)
    }
  }

  return (
    <div className={styles.RecurrenceSettingsSelect}>
      <select value={value.toString()} onChange={handleChange}>
        {options.map(option => (
          <option key={option.value.toString()} value={option.value.toString()}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}
