import React from 'react'
import {Link as RouterLink} from 'react-router-dom'

import {Currency} from '@posh/utils'
import {GetEventOrdersOutput} from 'apis/Orders/useGetEventOrders'
import classNames from 'classnames'
import {Link as LinkIcon} from 'components/assets/Icons'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {useDeviceType} from 'hooks/useDeviceType'
import {upperCase} from 'lodash'
import moment from 'moment-timezone'

import {Text} from '../../Shared/Text/Text'

import styles from './OrderItem.module.scss'

export type OrdersWithEventAttributes = Array<
  GetEventOrdersOutput[number] & {
    eventUrl?: string
  }
>

export const Link = (props: Parameters<typeof RouterLink>[0]) => {
  return (
    <RouterLink {...props} className={styles.Link}>
      {props.children}
    </RouterLink>
  )
}

type TagVariant = GetEventOrdersOutput[number]['tags'][number]
const Tag = ({variant}: {variant: TagVariant}) => {
  return (
    <div className={classNames(styles.TagContainer, styles[variant])}>
      <span className={classNames(styles.Tag, styles[variant])}>{upperCase(variant)}</span>
    </div>
  )
}

const ICON_SIZE = 12

export const OrderItem = (props: OrdersWithEventAttributes[number]) => {
  const {
    eventName,
    eventUrl,
    isRSVPOrder,
    orderNumber,
    orderDate,
    orderSubtotal,
    orderCurrency,
    accountName,
    accountAvatar,
    orderId,
    accountId,
    tags,
    trackingLink,
    eventId,
  } = props

  // TODO: abstract event page navigation? Maybe part of nav rework?
  const {domain, groupId} = useGroupMatchParams()
  const {isMobile} = useDeviceType()
  const momentFormat = isMobile ? 'MM/DD/YY h:mm A' : 'MMMM D, YYYY h:mm A'
  const orgScopeAttendeePage = accountId ? `/${domain}/groups/${groupId}/marketing/attendee/${accountId}` : null
  const orgScopeOrdersPage = `/${domain}/groups/${groupId}/orders/${orderId}`
  const eventScopeTrackingLink = `/${domain}/groups/${groupId}/events/${eventId}/team/tl-breakdown/${trackingLink}`
  const eventLink = eventUrl ? `/e/${eventUrl}` : `/${domain}/groups/${groupId}/events/${eventId}/overview`

  return (
    <Link to={orgScopeOrdersPage} style={{width: '100%'}}>
      <div className='w-full rounded-lg bg-secondary p-3'>
        <div className='flex items-center gap-4'>
          {orgScopeAttendeePage === null ? (
            <PoshImage src={accountAvatar} alt='Account Avatar' className={styles.OrderItemAvatar} />
          ) : (
            <Link to={orgScopeAttendeePage}>
              <PoshImage src={accountAvatar} alt='Account Avatar' className={styles.OrderItemAvatar} />
            </Link>
          )}
          <div className='flex flex-1 flex-col gap-1'>
            <h6 className='text-sm capitalize'>
              {orgScopeAttendeePage === null ? accountName : <Link to={orgScopeAttendeePage}>{accountName}</Link>}
            </h6>
            <span className='m-0 text-xs'>{moment(orderDate).format(momentFormat)}</span>
            <span className='m-0 text-xs hyphens-auto'>
              <Link to={eventLink}>{eventName}</Link>
            </span>
            {trackingLink && (
              <div className='flex items-center gap-1'>
                <LinkIcon color='white' width={ICON_SIZE} height={ICON_SIZE} />
                <span className='text-small m-0'>
                  <Link to={eventScopeTrackingLink}>{trackingLink}</Link>
                </span>
              </div>
            )}
            <div className={styles.TagsList}>
              {tags.map(tag => (
                <Tag key={tag} variant={tag} />
              ))}
            </div>
          </div>
          <div className='flex flex-col items-end'>
            <p className='m-0 text-sm whitespace-nowrap'>
              <Link to={orgScopeOrdersPage}>{`Order #${orderNumber}`}</Link>
            </p>
            <h6>{isRSVPOrder ? 'RSVP' : Currency.format(orderSubtotal, orderCurrency)}</h6>
          </div>
        </div>
      </div>
    </Link>
  )
}

const OrderItemLoading = () => {
  return (
    <div className='w-full rounded-lg p-4'>
      <div className={styles.OrderItemContent}>
        <div className={classNames(styles.OrderItemAvatar, styles.loading)} />
        <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
          <Text.Loading size='medium' width={125} />
          <Text.Loading size='small' />
        </div>
      </div>
    </div>
  )
}

OrderItem.Loading = OrderItemLoading
