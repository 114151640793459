import React from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'

import {useFetchCampaignLinkByShortUrl} from 'apis/Events/useFetchCampaignLink'
import {SpinLoader} from 'components/Loaders/SpinLoader'

import './styles.scss'

const CampaignLinkPage = () => {
  const {campaignShortUrl} = useParams<{campaignShortUrl?: string}>()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  useFetchCampaignLinkByShortUrl(campaignShortUrl, searchParams.get('gclid') ?? undefined, {
    onError: () => {
      navigate('/')
    },
    onSuccess: campaignLink => {
      if (!campaignLink) return navigate('/')
      if (campaignLink[0] === '/') return navigate(campaignLink)
      window.location.href = campaignLink
    },
  })

  return (
    <div className='loaderWrapper'>
      <SpinLoader height={100} />
    </div>
  )
}
export default CampaignLinkPage
