import React, {PropsWithChildren, useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {useFetchCustomerPaymentMethods} from 'apis/Accounts/useFetchCustomerPaymentMethods'
import {FetchEventResponsePublic} from 'apis/Events/useFetchEvent'
import getEventFlyer from 'apis/Util/getEventFlyer'
import {Warning} from 'components/assets/MobileIcons'
import Button from 'components/form/Button'
import CustomGTMTracking from 'components/GTMTracking/CustomGTMTracking'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import useSessionContext from 'domains/Auth/SessionContext'
import {generateSupportEmailHref} from 'helpers/generateSupportEmailHref'
import {every, isUndefined} from 'lodash'
import Stripe from 'stripe'

import {useDisplayGroupOptIn} from '../../apis/Optins/useGetAccountOptIns'
import useLiveCartContext from '../../domains/LiveCart/LiveCartContext'
import CheckoutButtons from './CheckoutButtons'
import CuratorPolicyForm from './CuratorPolicyForm'
import CustomCheckoutFields from './CustomCheckoutFields'
import ElementsWrapper from './ElementsWrapper'
import LoginForm from './LoginForm'
import OrderSummaryTable from './OrderSummaryTable'
import PaymentMethods from './PaymentMethods'
import PaymentPlans from './PaymentPlans'
import PromoCodeForm from './PromoCodeForm'
import {SMSNotificationsOptInCheckbox} from './SMSNotificationsOptInCheckbox'

import './styles.scss'
import styles from './NewCheckoutPage.module.scss'

type NavigationParams = {
  eventUrl: string
  cartId: string
  approvalRequired?: boolean
  isRSVPEvent?: boolean
}

export const handleNavigation = ({eventUrl, cartId, approvalRequired, isRSVPEvent}: NavigationParams) => {
  if (isRSVPEvent) return `/e/${eventUrl}`
  else if (approvalRequired) return `/confirmation/${eventUrl}`
  else return `/receipt/${cartId}?checkout=true`
}

export interface NewCheckoutPageWrapperProps {
  eventData: FetchEventResponsePublic
}

export const NewCheckoutPageWrapper = ({children, eventData}: PropsWithChildren<NewCheckoutPageWrapperProps>) => {
  const eventFlyer = getEventFlyer(eventData.event)
  return (
    <div className='CheckoutPage'>
      <CustomGTMTracking containerId={eventData.event.gtmContainerId} />
      <div className='CheckoutPage-Flyer'>{<PoshImage src={eventFlyer} alt='Flyer' />}</div>
      <div className='CheckoutPage-Container'>{children}</div>
    </div>
  )
}

const NewCheckoutPageLoading = (props: NewCheckoutPageWrapperProps) => {
  return (
    <NewCheckoutPageWrapper {...props}>
      <div className={styles.NewCheckoutPageLoading}>
        <SpinLoader />
      </div>
    </NewCheckoutPageWrapper>
  )
}

const NewCheckoutPageError = (props: NewCheckoutPageWrapperProps & {errorMessage: string; cartId: string}) => {
  const {errorMessage, cartId, ...wrapperProps} = props
  const navigate = useNavigate()
  return (
    <NewCheckoutPageWrapper {...wrapperProps}>
      <div className={styles.NewCheckoutPageError}>
        <Warning className={styles.ErrorIcon} />
        <h3 className='m-0'>Checkout Error</h3>
        <p className='grey m-0'>Cart ID: {cartId}</p>
        <p className='text-large' style={{marginTop: 20, marginBottom: 30}}>
          {errorMessage}
        </p>
        <div style={{display: 'flex', gap: 5}}>
          <Button onClick={() => navigate(-1)}>Go Back</Button>
          <Button onClick={() => navigate('/')}>Home</Button>
        </div>
        <p>
          Unexpected? Contact:{' '}
          <a className='primary-link' href={generateSupportEmailHref('Checkout Error', `Reference Cart ID: ${cartId}`)}>
            support@posh.vip
          </a>
        </p>
      </div>
    </NewCheckoutPageWrapper>
  )
}

type NewCheckoutPageProps = NewCheckoutPageWrapperProps

export const NewCheckoutPage = (props: NewCheckoutPageProps) => {
  const {eventData} = props
  const [hasAgreedToCuratorTos, setHasAgreedToCuratorTos] = useState(true)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<Stripe.PaymentMethod | null>()
  const [isPromoCodeBeingApplied, setIsPromoCodeBeingApplied] = useState(false)

  const customCheckoutFieldsRef = useRef<HTMLDivElement>(null)

  const onPromoCodeBeingApplied = () => {
    setIsPromoCodeBeingApplied(true)
  }

  const onPromoCodeApplied = async () => {
    setIsPromoCodeBeingApplied(false)
  }

  const {formErrors, customFieldErrors, checkoutSummary: summary} = useLiveCartContext()

  const hasAccessToPaymentPlans = !!summary?.paymentPlansInfo
  const {userId} = useSessionContext()
  const {data: paymentMethods, isFetching} = useFetchCustomerPaymentMethods(userId ?? undefined)
  const {shouldDisplayOptInForm} = useDisplayGroupOptIn({groupId: eventData.event.groupID})

  const isAuthorizedUser = !!userId
  const isAFreeOrder = summary?.cartInfo.total === 0
  const hasTermsOfService = eventData?.event?.termsOfService
  const hasPreviousPaymentMethods = paymentMethods && paymentMethods.length > 0
  const shouldShowPromoCodes = () => {
    if (isAFreeOrder) return false
    else return eventData?.event.promoCodes ?? true
  }

  useEffect(() => {
    const hasCustomFieldsErrors = every(customFieldErrors, isUndefined)

    if (customCheckoutFieldsRef.current && hasCustomFieldsErrors) {
      customCheckoutFieldsRef.current.scrollIntoView({behavior: 'smooth'})
    }
  }, [formErrors, customFieldErrors])

  return (
    <NewCheckoutPageWrapper eventData={eventData}>
      {isAuthorizedUser ? (
        <>
          <CustomCheckoutFields ref={customCheckoutFieldsRef} />
          <OrderSummaryTable currency={eventData.group.currency} />
          {shouldShowPromoCodes() && (
            <PromoCodeForm onPromoCodeBeingApplied={onPromoCodeBeingApplied} onPromoCodeApplied={onPromoCodeApplied} />
          )}
          {hasAccessToPaymentPlans && <PaymentPlans currency={eventData.group.currency} />}
          {hasPreviousPaymentMethods && !isAFreeOrder && (
            <PaymentMethods
              paymentMethods={paymentMethods}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
            />
          )}
          {!selectedPaymentMethod && !isFetching && !isAFreeOrder && (
            <>
              <ElementsWrapper
                summary={summary}
                hasAgreedToCuratorTos={hasAgreedToCuratorTos}
                checkoutDisabled={isPromoCodeBeingApplied}
              />
            </>
          )}
          {hasTermsOfService && <CuratorPolicyForm setHasAgreedToCuratorTos={setHasAgreedToCuratorTos} />}
          {shouldDisplayOptInForm && <SMSNotificationsOptInCheckbox groupId={eventData.event.groupID} />}
          {!isFetching && (
            <CheckoutButtons
              hasAgreedToCuratorTos={hasAgreedToCuratorTos}
              selectedPaymentMethod={selectedPaymentMethod}
              disabled={isPromoCodeBeingApplied}
            />
          )}
        </>
      ) : (
        <LoginForm />
      )}
    </NewCheckoutPageWrapper>
  )
}

NewCheckoutPage.Loading = NewCheckoutPageLoading
NewCheckoutPage.Error = NewCheckoutPageError
