import React, {forwardRef, useEffect, useImperativeHandle} from 'react'

export interface TurnstileRef {
  getResponse: (widgetId?: string) => string
  reset: (widgetId?: string) => void
  isExpired: (widgetId?: string) => boolean
  ready(callback: () => void): void
  render(id: string, options: {sitekey: string; callback: (token: string) => void}): string
}

declare global {
  interface Window {
    handleCfTurnstile: (token: string) => void
    resetCaptcha: () => void
    turnstile: TurnstileRef
  }
}

export interface CaptchaInterfacingProps {
  captchaToken?: string
  captchaRef?: React.RefObject<TurnstileRef>
  resetCaptcha?: () => void
}

//If the environment variable is not set, use the testing value
const CLOUDFLARE_SITE_KEY = process.env.CLOUDFLARE_SITE_KEY || '3x00000000000000000000FF'

interface CloudflareTurnstileProps {
  /** This callbacl is used when Cloudflare successfully gets a new verification token from the widget */
  successCallback?: (token: string) => void
  /** This callback is used when Cloudflare automatically resets the Turnstile widget, due to an expiration or timeout */
  resetCaptchaCallback?: () => void
}

const CloudflareTurnstile = forwardRef<TurnstileRef, CloudflareTurnstileProps>(
  ({successCallback, resetCaptchaCallback}: CloudflareTurnstileProps, ref) => {
    let widgetId: string | undefined = undefined
    useImperativeHandle(ref, () => ({
      reset: () => window.turnstile.reset(widgetId),
      getResponse: () => window.turnstile.getResponse(widgetId),
      isExpired: () => window.turnstile.isExpired(widgetId),
      ready: callback => window.turnstile.ready(callback),
      render: (id, options) => window.turnstile.render(id, options),
    }))
    useEffect(() => {
      if (successCallback) window.handleCfTurnstile = successCallback
    }, [successCallback])

    useEffect(() => {
      if (resetCaptchaCallback) window.resetCaptcha = resetCaptchaCallback
    }, [resetCaptchaCallback])

    const renderTurnstile = () => {
      if (!window.turnstile || !window.turnstile.render) {
        return false
      }
      widgetId = window.turnstile.render('#cf-turnstile', {
        sitekey: CLOUDFLARE_SITE_KEY,
        callback: token => {
          if (successCallback) successCallback(token)
        },
      })
      return true
    }

    useEffect(() => {
      const intervalId = setInterval(() => {
        if (renderTurnstile()) {
          clearInterval(intervalId)
        }
      }, 1000)

      return () => clearInterval(intervalId)
    }, [])

    return (
      <div
        id='cf-turnstile'
        className='cf-turnstile'
        style={{width: '100%'}}
        data-sitekey={CLOUDFLARE_SITE_KEY}
        {...(successCallback ? {'data-callback': 'handleCfTurnstile'} : {})}
        data-size='flexible'
        data-refresh-expired='auto'
        {...(resetCaptchaCallback
          ? {'data-expired-callback': 'resetCaptcha', 'data-timeout-callback': 'resetCaptcha'}
          : {})}
        data-refresh-timeout='auto'
        data-appearance='interaction-only'
        data-theme='dark'></div>
    )
  },
)
CloudflareTurnstile.displayName = 'CloudflareTurnstile'

export default CloudflareTurnstile
