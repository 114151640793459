import React, {useState} from 'react'

import {
  getAccountFinancialCapabilityStatus,
  isAllowedTapToPayCountry,
  parseStripeOnboardingRequirements,
} from '@posh/model-types'
import {
  CountryCode,
  TapToPayDisabled,
  TapToPayEnabled,
  TapToPayNotSetup,
  TapToPayState,
  TapToPayTooltips,
} from '@posh/types'
import {TapToPayStateSlider} from 'components/TapToPay/TapToPayStateSlider'
import {useToast} from 'components/toasts/ToastProvider'
import {trpc} from 'lib/trpc'

import {AccountStatusActionButton} from './AccountStatusActionButton'
import {AccountStatusRow} from './AccountStatusRow'
import {FinanceCard} from './PayoutCards/FinanceCard'

import styles from './AccountStatusCard.module.scss'

export type AccountStatusCardProps = {
  canAcceptPayments: boolean
  canInitiatePayouts: boolean
  needsStripeOnboarding: boolean
  currentOnboardingRequirements?: string[]
  pendingDocumentsVerification: boolean
  hasBankAccount: boolean
  tapToPayProps?: {
    renderTapToPaySlider: boolean
    tapToPayGroupState: TapToPayState
    onChangeGroupTapToPayState: (newState: TapToPayState) => void
    hasEditGroupSettingsPermission: boolean
  }
}

export const AccountStatusCard = ({
  canAcceptPayments,
  canInitiatePayouts,
  hasBankAccount,
  needsStripeOnboarding,
  pendingDocumentsVerification,
  currentOnboardingRequirements,
  groupId,
  hasTicketSales,
  groupCountry,
  onClickAddBankAccount,
  tapToPayProps,
}: AccountStatusCardProps & {
  groupId: string
  onClickAddBankAccount: () => void
  hasTicketSales: boolean
  groupCountry: CountryCode
}) => {
  const {renderTapToPaySlider, tapToPayGroupState, onChangeGroupTapToPayState, hasEditGroupSettingsPermission} =
    tapToPayProps ?? {
      // Default if the tap to pay settings are undefined is to not render the slider
      renderTapToPaySlider: false,
      tapToPayGroupState: TapToPayNotSetup,
      onChangeGroupTapToPayState: () => {},
      hasEditGroupSettingsPermission: false,
    }
  const {sellTicketsStatus, payoutsStatus} = getAccountFinancialCapabilityStatus({
    canAcceptPayments,
    canInitiatePayouts,
    needsStripeOnboarding,
    pendingDocumentsVerification,
    hasBankAccount,
    hasTicketSales,
  })

  const {showToast} = useToast()
  const {mutateAsync: createLink, isLoading} = trpc.groups.createStripeOnboardingLink.useMutation({
    onError: error => {
      showToast({type: 'error', title: 'An error occurred', subtitle: error.message})
      setIsRedirecting(false)
    },
  })
  const [isRedirecting, setIsRedirecting] = useState(false)
  const onClickSubmitInfo = async () => {
    setIsRedirecting(true)
    const stripeSetupLink = await createLink({groupId, redirectUrl: document.location.pathname}).catch()
    if (stripeSetupLink) {
      window.location.href = stripeSetupLink
    }
  }

  const [viewingListOfRequirements, setViewingListOfRequirements] = useState(false)

  // Tooltip for the tap to pay slider depending on permissions and current tap to pay group state
  const tapToPayTooltip = (() => {
    // If it's not set up, then display tooltip depending on if they have permission to set it up
    if (tapToPayGroupState == TapToPayNotSetup && !hasEditGroupSettingsPermission) {
      return TapToPayTooltips.NotSetup_NoPermission
    }

    // If it's disabled, only display a tooltip if they don't have permission to enable it
    if (tapToPayGroupState == TapToPayDisabled && !hasEditGroupSettingsPermission) {
      return TapToPayTooltips.GroupDisabled_NoPermission
    }

    if (tapToPayGroupState == TapToPayEnabled && !hasEditGroupSettingsPermission) {
      return TapToPayTooltips.GroupEnabled_NoPermission
    }

    return undefined
  })()

  return (
    <FinanceCard
      title='Account Status'
      actions={
        <>
          {renderTapToPaySlider && isAllowedTapToPayCountry(groupCountry) && (
            <TapToPayStateSlider
              isDisabled={tapToPayTooltip != undefined}
              tooltip={tapToPayTooltip}
              groupTapToPayState={tapToPayGroupState}
              tapToPayGroupStateChanged={onChangeGroupTapToPayState}
            />
          )}
        </>
      }>
      <div className={styles.AccountStatusContainer}>
        {/*TODO: get currently due deadline to display here as well*/}
        <AccountStatusRow capability='Sell Tickets' capabilityStatus={sellTicketsStatus} />
        <AccountStatusRow capability='Transfer Balance' capabilityStatus={payoutsStatus} />

        {needsStripeOnboarding && (
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <p
              onClick={() => setViewingListOfRequirements(!viewingListOfRequirements)}
              className='m-0'
              style={{textDecoration: 'underline', cursor: 'pointer', alignSelf: 'center'}}>{`${
              viewingListOfRequirements ? 'Hide' : 'View'
            } Info Requirements`}</p>
            {viewingListOfRequirements && (
              <div className={styles.OnboardingRequested_requirementsList}>
                <ul style={{margin: '0'}}>
                  {parseStripeOnboardingRequirements(currentOnboardingRequirements, groupCountry)
                    ?.sort((a, b) => a.length - b.length)
                    .map(r => {
                      return <li key={r}>{r}</li>
                    })}
                </ul>
              </div>
            )}
          </div>
        )}
        <AccountStatusActionButton
          sellTicketsStatus={sellTicketsStatus}
          payoutsStatus={payoutsStatus}
          onClickAddBankAccount={onClickAddBankAccount}
          onClickSubmitInfo={onClickSubmitInfo}
          isLoading={isLoading || isRedirecting}
        />
      </div>
    </FinanceCard>
  )
}
