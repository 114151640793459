import React from 'react'

import {ICrossSection} from 'apis/Analytics/useGetSectionalData'
import {WithToolTip} from 'components/form/PoshInfo'

import {CrossSection} from '../../CrossSection/CrossSection'

export const GenderColors: Record<string, string> = {
  Male: '#0CF',
  Female: '#FF005C',
  Unknown: '#7A7B7D',
}

export const GenderRatio = ({genderCrossSection}: {genderCrossSection: ICrossSection | undefined}) => {
  if (!genderCrossSection) return null

  const majorityGender = genderCrossSection.chart?.data.reduce(
    (currentMajority, gender) => (gender.data > currentMajority.data ? gender : currentMajority),
    genderCrossSection.chart.data[0],
  )
  return (
    <CrossSection>
      <CrossSection.Row>
        <p className='text-sm font-medium'>Gender Ratio</p>
        <WithToolTip info={`A breakdown of the attendees’ estimated gender.`}>
          <CrossSection.Icon name='info' />
        </WithToolTip>
      </CrossSection.Row>
      {majorityGender && (
        <>
          <h3>{majorityGender.data === 0 ? 'No Data Yet' : majorityGender?.label}</h3>
          <p className='text-sm text-gray-400'>Majority Gender</p>
        </>
      )}

      <CrossSection.Row style={{marginTop: 5}}>
        <CrossSection.Chart
          chart={genderCrossSection.chart!}
          id='gender-ratio'
          bgColors={genderCrossSection.chart!.data.map(d => GenderColors[d.label])}
          formatter={value => value.toFixed(2) + '%'}
        />
      </CrossSection.Row>
    </CrossSection>
  )
}
