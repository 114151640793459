import React from 'react'

import {StripeBankAccount} from 'apis/Groups/useGetGroupPayouts'
import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'

import BankAccount from '../BankAccount'
import {FinanceCard} from '../FinanceCard'

interface BanksCardProps {
  bankAccounts: StripeBankAccount[]
  showAddBankModal: () => void
  isShowingEditBankAccount: boolean
  setEditingBankAccount: React.Dispatch<React.SetStateAction<StripeBankAccount | null>>
  setIsDeletingBankAccount: React.Dispatch<React.SetStateAction<boolean>>
  setIsShowingEditBankAccount: React.Dispatch<React.SetStateAction<boolean>>
  setIsShowingEditBankAccountModal: React.Dispatch<React.SetStateAction<boolean>>
}

const BanksCard = (props: BanksCardProps) => {
  const {
    bankAccounts,
    showAddBankModal,
    isShowingEditBankAccount,
    setEditingBankAccount,
    setIsDeletingBankAccount,
    setIsShowingEditBankAccount,
    setIsShowingEditBankAccountModal,
  } = props

  const handleEditBankAccount = (account: StripeBankAccount, isDeleting: boolean) => {
    setIsShowingEditBankAccountModal(true)
    setEditingBankAccount(account)
    setIsDeletingBankAccount(isDeleting)
  }
  const hasBankAccount = bankAccounts.length > 0
  const hasMultipleBankAccounts = bankAccounts.length > 1

  const toggleEditBank = () => {
    setIsShowingEditBankAccount(!isShowingEditBankAccount)
  }

  return (
    <FinanceCard
      title={'Banks & Debit Cards'}
      actions={
        <RequirePermissions requiredPermissions={[requirePermissionAndScope('add_bank_account', ['group', 'event'])]}>
          <>
            {hasBankAccount && (
              <>
                {hasMultipleBankAccounts && (
                  <div onClick={toggleEditBank}>
                    <PoshImage src='https://images.posh.vip/b2/pencil+(2).svg' />
                  </div>
                )}
                <div onClick={showAddBankModal}>
                  <PoshImage src='https://images.posh.vip/b2/add.svg' />
                </div>
              </>
            )}
          </>
        </RequirePermissions>
      }
      animationIndex={4}>
      {hasBankAccount ? (
        <>
          {bankAccounts.map((account, index) => (
            <BankAccount
              key={index}
              bankAccount={account}
              isShowingEditBankAccount={isShowingEditBankAccount}
              onEditBankAccount={() => handleEditBankAccount(account, false)}
              onDeleteBankAccount={() => handleEditBankAccount(account, true)}
            />
          ))}
        </>
      ) : (
        <RequirePermissions requiredPermissions={[requirePermissionAndScope('add_bank_account', ['group', 'event'])]}>
          <Button className='fullWidth' onClick={showAddBankModal}>
            + Add Bank Account
          </Button>
        </RequirePermissions>
      )}
    </FinanceCard>
  )
}

export default BanksCard
