import React from 'react'

import {GroupFormattedPayouts} from 'apis/Groups/useGetGroupPayouts'
import {SpinLoader} from 'components/Loaders/SpinLoader'

import PayoutsTable from '../../PayoutsTable'
import {FinanceCard} from '../FinanceCard'

interface ViewPayoutsCardProps {
  formattedPayouts?: GroupFormattedPayouts
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  isFetching: boolean
  setStartingAfter: React.Dispatch<React.SetStateAction<string>>
  setEndingBefore: React.Dispatch<React.SetStateAction<string>>
  isError: boolean
}

const ViewPayoutsCard = (props: ViewPayoutsCardProps) => {
  const {formattedPayouts, page, setPage, isFetching, setStartingAfter, setEndingBefore, isError} = props
  return (
    <FinanceCard title={'Payouts'} animationIndex={3}>
      <div style={{filter: isFetching ? 'blur(3px)' : 'none'}}>
        <PayoutsTable
          setPage={setPage}
          page={page}
          data={formattedPayouts || []}
          setStartingAfter={setStartingAfter}
          setEndingBefore={setEndingBefore}
        />
      </div>
      {isFetching && (
        <div className='Payouts-tableContainer-loadingDiv'>
          <SpinLoader height={80} />
        </div>
      )}
      {isError && formattedPayouts && (
        <div className='Payouts-emptyState'>There was an error fetching your payout history, please try again.</div>
      )}
    </FinanceCard>
  )
}

export default ViewPayoutsCard
