import React, {useEffect, useState} from 'react'
import {UseFormReturn} from 'react-hook-form'

import {Checkbox} from 'components/form/checkbox/Checkbox'
import PoshSwitch from 'components/form/PoshSwitch'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {TicketSection} from 'components/PageComponents/EventVisuals/Page/Section/ticketSection'
import {CreateEventTicket} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {useDimensions} from 'hooks/useDimensions'

import {CHECK_COLOR, COLOR_PROPS} from './TicketEditorPanel'

import styles from './styles.module.scss'

interface TicketOptionsProps {
  form: UseFormReturn<CreateEventTicket>
}

export const TicketOptions = (props: TicketOptionsProps) => {
  const {isMobile} = useDimensions()
  const checkboxFlexDirection = isMobile ? 'column' : 'row'

  const {form} = props
  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: {errors},
  } = form

  const purchaseMin = watch('purchaseMin')
  const purchaseLimit = watch('purchaseLimit')
  const sellInMultiples = watch('sellInMultiples') ?? false
  const isHidden = watch('isHidden') ?? false
  const priceHidden = watch('priceHidden') ?? false
  const disabled = watch('disabled') ?? false

  const [shouldShowLimitInputs, setShouldShowLimitInputs] = useState(!!purchaseMin || !!purchaseLimit)

  const toggleValidity = () => {
    setShouldShowLimitInputs(!shouldShowLimitInputs)
  }

  useEffect(() => {
    if (!shouldShowLimitInputs) {
      form.setValue('purchaseMin', undefined)
      form.setValue('purchaseLimit', undefined)
      form.setValue('sellInMultiples', false)
    }
  }, [shouldShowLimitInputs])

  useEffect(() => {
    clearErrors()
    if (!!purchaseMin && !!purchaseLimit && purchaseMin > purchaseLimit) {
      setError('root', {message: 'Purchase minimum must be less than the maximum'})
    }
  }, [purchaseMin, purchaseLimit])

  return (
    <TicketSection title='Ticket Options' expandable>
      <PoshSwitch
        switchOptions={{
          onChange: toggleValidity,
          checked: shouldShowLimitInputs,
        }}
        rightTitle='Limit Purchase Quantity'
        subtitle='How many tickets can customers buy?'
      />
      {shouldShowLimitInputs && (
        <div className={styles.row}>
          <EventVisualsTextInput.Number.Controlled
            control={control}
            name='purchaseMin'
            placeholder='1'
            prefix='Min'
            {...COLOR_PROPS}
          />
          <label className={'m-0'}>to</label>
          <EventVisualsTextInput.Number.Controlled
            control={control}
            name='purchaseLimit'
            placeholder='10'
            prefix='Max'
            {...COLOR_PROPS}
          />
        </div>
      )}
      {errors.root && <p className='error center m-0'>{errors.root.message}</p>}
      {purchaseMin !== undefined && (
        <Checkbox
          checked={sellInMultiples}
          onChecked={() => setValue('sellInMultiples', !sellInMultiples)}
          color={CHECK_COLOR}
          hideBackground
          label='Sell in Bundles'
          subtitle={`Customers can only this ticket in bundles of ${purchaseMin}`}
        />
      )}

      <PoshSwitch
        switchOptions={{
          onChange: checked => setValue('isHidden', checked),
          checked: isHidden,
        }}
        rightTitle='Hide Tier'
        subtitle='Customers will not see this ticket'
      />
      <PoshSwitch
        switchOptions={{
          onChange: checked => setValue('priceHidden', checked),
          checked: priceHidden,
        }}
        rightTitle='Hide Price'
        subtitle='Customers will not see the price of this ticket'
      />
      <PoshSwitch
        switchOptions={{
          onChange: checked => setValue('disabled', checked),
          checked: disabled,
        }}
        rightTitle='Disable Ticket'
        subtitle='Ticket remains visible but customers will not be able to purchase this ticket'
      />
    </TicketSection>
  )
}
