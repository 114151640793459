import {useRef, useState} from 'react'
import {useParams} from 'react-router-dom'

import {Button} from '@posh/design-kit/components/button'
import {GroupDisputesReturn} from 'apis/Groups/useFetchGroupDisputes'
import {useSubmitDisputeEvidence} from 'apis/Groups/useSubmitDisputeEvidence'
import {useToast} from 'components/toasts/ToastProvider'

import EvidenceGuidance from './EvidenceGuidance'

interface Evidence {
  uncategorizedText: string
  productDescription: string
  refundRefusalExplanation: string
  customerCommunicationFile?: File | null
  refundPolicyFile?: File | null
  additionalEvidenceFile?: File | null
}

interface OpenDisputeFormProps {
  selectedDisputeData: GroupDisputesReturn[0] | null
  onSubmitEvidence: () => void
}

const convertFileToBase64 = async (file: File | null | undefined) => {
  if (!file) return null

  return new Promise<{data: string; name: string}>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const result = reader.result
      if (typeof result === 'string') {
        // Remove the data:image/xyz;base64, prefix
        const base64 = result.split(',')[1]
        resolve({
          data: base64 || '',
          name: file.name,
        })
      } else {
        reject(new Error('Failed to read file as base64'))
      }
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

const OpenDisputeForm = ({selectedDisputeData, onSubmitEvidence}: OpenDisputeFormProps) => {
  const {groupId} = useParams()
  const {showToast} = useToast()
  const {mutateAsync: submitEvidence, isLoading} = useSubmitDisputeEvidence()

  const initialEvidence = useRef({
    uncategorizedText: selectedDisputeData?.evidence?.uncategorizedText || '',
    productDescription: selectedDisputeData?.evidence?.productDescription || '',
    refundRefusalExplanation: selectedDisputeData?.evidence?.refundRefusalExplanation || '',
    customerCommunicationFile: null,
    refundPolicyFile: null,
    additionalEvidenceFile: null,
  })

  const [evidence, setEvidence] = useState<Evidence>(initialEvidence.current)

  // Check if any field has changed from its initial value
  const hasChanges = () => {
    return Object.keys(evidence).some(key => {
      const field = key as keyof Evidence
      if (field.endsWith('File')) {
        return evidence[field] !== null
      }
      return evidence[field] !== initialEvidence.current[field]
    })
  }

  const handleSubmit = async () => {
    if (!selectedDisputeData || !groupId) return

    try {
      const [customerCommFile, refundPolicyFileData, additionalEvidenceFileData] = await Promise.all([
        convertFileToBase64(evidence.customerCommunicationFile),
        convertFileToBase64(evidence.refundPolicyFile),
        convertFileToBase64(evidence.additionalEvidenceFile),
      ])

      await submitEvidence({
        groupId,
        disputeId: selectedDisputeData.id,
        files: {
          ...(customerCommFile && {customer_communication: customerCommFile}),
          ...(refundPolicyFileData && {refund_policy: refundPolicyFileData}),
          ...(additionalEvidenceFileData && {additional_evidence: additionalEvidenceFileData}),
        },
        evidence: {
          product_description: evidence.productDescription || undefined,
          refund_refusal_explanation: evidence.refundRefusalExplanation || undefined,
          additional_notes: evidence.uncategorizedText || undefined,
        },
      })

      showToast({
        title: 'Evidence Submitted',
        subtitle: 'Your evidence has been successfully submitted.',
        type: 'success',
      })

      onSubmitEvidence()
    } catch (error) {
      console.error('Error submitting evidence:', error)
      showToast({
        title: 'Error',
        subtitle: 'Failed to submit evidence. Please try again.',
        type: 'error',
      })
    }
  }

  if (!selectedDisputeData) return null

  return (
    <div className='space-y-6'>
      <EvidenceGuidance reason={selectedDisputeData.reason} evidence={evidence} onEvidenceChange={setEvidence} />

      <div className='flex justify-end'>
        <Button variant='secondary' onClick={handleSubmit} disabled={isLoading || !hasChanges()}>
          Submit Evidence
        </Button>
      </div>
    </div>
  )
}

export default OpenDisputeForm
