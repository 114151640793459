import React, {useEffect, useState} from 'react'

import {StatsigFeatureFlagKey} from '@posh/model-types'
import {Modal} from 'components/coreComponents/Modal'
import {useGetCheckGate} from 'hooks/useFeatureGate'
import {useOneTimeView} from 'hooks/useOneTimeView'

import './styles.scss'

interface FeatureAnnouncementConfig {
  Icon: React.FC<{height?: number; width?: number; color?: string}>
  featureName: string
  featureDescription: string | ((className: string) => React.ReactNode)
  helpUrl?: string
  // Override the default "Introducing" text
  introductionText?: string
}

interface BaseFeatureAnnouncementModalProps {
  /** The unique flag used to track if this announcement has been viewed */
  oneTimeViewKey: string
  /** Optional delay before showing the modal (in ms) */
  delay?: number
  /** Configuration for the announcement content */
  config: FeatureAnnouncementConfig
  /** Optional callback when modal opens */
  onModalOpen?: () => void
}

type FeatureGateProps = BaseFeatureAnnouncementModalProps & {
  featureGateKey: StatsigFeatureFlagKey
  shouldShowModal?: never
}

type CustomCheckProps = BaseFeatureAnnouncementModalProps & {
  featureGateKey?: never
  shouldShowModal: () => boolean
}

type FeatureAnnouncementModalProps = FeatureGateProps | CustomCheckProps

const ICON_SIZE = 40

export function FeatureAnnouncementModal({
  oneTimeViewKey,
  delay = 2000,
  config,
  onModalOpen,
  ...props
}: FeatureAnnouncementModalProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [hasViewed, markAsViewed] = useOneTimeView(oneTimeViewKey)

  const checkGate = useGetCheckGate()
  const isFeatureAvailable = props.featureGateKey ? checkGate(props.featureGateKey) : props.shouldShowModal?.()

  useEffect(() => {
    const to = setTimeout(() => {
      if (!hasViewed && isFeatureAvailable) {
        setIsOpen(true)
        onModalOpen?.()
      }
    }, delay)

    return () => clearTimeout(to)
  }, [hasViewed, delay, onModalOpen, isFeatureAvailable])

  const onClose = () => {
    setIsOpen(false)
    markAsViewed()
  }

  if (!isFeatureAvailable) {
    return null
  }

  const {Icon, featureName, featureDescription, helpUrl, introductionText = 'Introducing'} = config

  const Background = () => {
    return <div className={'feature-announcement-modal'} />
  }

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} Background={Background}>
      <div className='feature-announcement-overlay'>
        <div className='feature-announcement-content'>
          <div className='icon-container'>
            <Icon height={ICON_SIZE} width={ICON_SIZE} color={'#FFFFFF'} />
          </div>
          <div className='title-container'>
            <p className='introducing'>{introductionText}</p>
            <h2>{featureName}</h2>
          </div>
          <div className='body-container'>
            {typeof featureDescription === 'string' ? (
              <p className='description'>{featureDescription}</p>
            ) : (
              featureDescription('description')
            )}
            {helpUrl && (
              <p className='help-text'>
                For support,{' '}
                <a href={helpUrl} target='_blank' rel='noreferrer'>
                  read the help article.
                </a>
              </p>
            )}
          </div>
          <div className='button-container'>
            <button className='ok-button' onClick={onClose}>
              OK
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
