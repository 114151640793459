import {useApiLogout} from 'apis/Auth/useLogout'
import {browserNavigate} from 'apis/NavigationHelper'
import useSessionContext from 'domains/Auth/SessionContext'

export default function useLogout() {
  const {unauthenticateSession} = useSessionContext()
  const currentPath = window.location.pathname
  const pathsToAvoidRedirect = ['/e/', '/g/', '/p/', '/f/']
  const shouldRedirect = pathsToAvoidRedirect.every(path => !currentPath.startsWith(path))
  const {mutate: logout} = useApiLogout({
    onSuccess: () => {
      unauthenticateSession()
      if (shouldRedirect) {
        browserNavigate('/')
      }
    },
  })

  return logout
}
