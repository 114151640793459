import React, {useEffect, useState} from 'react'
import {useParams, useSearchParams} from 'react-router-dom'

import {locationSchema, TLocation} from '@posh/model-types'
import {WHERE_OPTIONS} from '@posh/types'
import {captureException} from '@sentry/react'
import {useGetEventPlaylist} from 'apis/Events/playlists/useGetEventPlaylist'
import {
  UnderlinedDropdown,
  UnderlinedDropdownOption,
  UnderlinedDropdownOptionType,
} from 'components/Dropdown/UnderlinedDropdown'
import {useDimensions} from 'hooks/useDimensions'
import {useUpdateLocationByIp} from 'pages/EventMarketplace/MainFilters/useUpdateLocationByIp'
import PoshHeader from 'pages/OwnerPage/PoshHeader'
import {PartyFoul} from 'pages/PageNotFound/PartyFoul/PartyFoul'

import {DEFAULT_LOCATION_PRESET, useSelectLocation} from '../../hooks/useSelectLocation'
import {EventPlaylist, EventPlaylistProps} from './EventPlaylist/EventPlaylist'

const eventPlaylistBackgroundStyle = (
  {
    backgroundImage,
    mobileBackgroundImage,
  }: Pick<EventPlaylistProps, 'backgroundImage' | 'backgroundImagePalette' | 'mobileBackgroundImage'>,
  isMobile: boolean,
): React.CSSProperties => {
  const bgImage = isMobile ? (mobileBackgroundImage ?? backgroundImage) : backgroundImage
  return {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }
}

const useEventPlaylistLocationFromSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  let locationObjectFromParams = {}
  try {
    locationObjectFromParams = JSON.parse(searchParams.get('location') ?? '{}')
  } catch (e) {
    captureException(e, {extra: {searchParams}})
  }
  const locationParsed = locationSchema.safeParse(locationObjectFromParams)

  const [location, setLocation] = useState<TLocation | undefined>(
    locationParsed.success ? locationParsed.data : undefined,
  )

  const onUpdateLocationQueryParams = (location: TLocation) => {
    switch (location.type) {
      case 'all':
        setSearchParams({
          location: JSON.stringify({
            type: 'all',
            location: 'All Locations',
          }),
        })
        break
      case 'preset':
        setSearchParams({location: JSON.stringify(location)})
        break
      case 'custom':
        setSearchParams({
          location: JSON.stringify({
            type: location.type,
            location: location.location,
            // Need to convert to string for URLSearchParams.
            lat: location.lat.toString(),
            long: location.long.toString(),
          }),
        })
        break
    }
  }

  const onUpdateLocationState = (location: TLocation) => {
    setLocation(location)
  }

  useUpdateLocationByIp({
    location,
    updateLocation: onUpdateLocationState,
    fallbackLocation: DEFAULT_LOCATION_PRESET,
  })

  const {onSelectLocation, isLoadingNearMeLocation} = useSelectLocation({
    onLocationSelected: location => {
      onUpdateLocationQueryParams(location)
      onUpdateLocationState(location)
    },
  })

  return {
    location,
    onSelectLocation,
    isLoadingNearMeLocation,
    isSetByParams: Object.keys(locationObjectFromParams).length > 0,
  }
}

export const EventPlaylistPage = () => {
  const slug = useParams<{slug: string}>().slug ?? ''
  const {location, onSelectLocation, isLoadingNearMeLocation, isSetByParams} =
    useEventPlaylistLocationFromSearchParams()
  const {isMobile} = useDimensions()

  const {data, isSuccess, isError} = useGetEventPlaylist(
    {slug, location},
    {keepPreviousData: true, enabled: !!location},
  )

  const displayGlobally = data?.playlist.displayGlobally

  useEffect(() => {
    // if the "displayGlobally" is set, default the location to "All Locations" if it's not already set by params
    if (displayGlobally && !isSetByParams && location?.type !== 'all') onSelectLocation('All Locations')
  }, [displayGlobally, location, onSelectLocation, isSetByParams])

  const whereOptions: UnderlinedDropdownOption[] = [...WHERE_OPTIONS]
    .filter(o => o !== location?.location && (displayGlobally || o !== 'All Locations'))
    .map(o => ({
      type: 'text' as UnderlinedDropdownOptionType,
      value: o,
    }))
  whereOptions.push({type: 'city-input'})

  const backgroundStyle = isSuccess ? eventPlaylistBackgroundStyle(data.playlist, isMobile) : {}

  return (
    <div className='flex h-full w-full flex-col items-center'>
      {!isMobile && <PoshHeader />}
      {isSuccess && (
        <EventPlaylist
          {...data.playlist}
          locationInput={
            <>
              {!location?.location || isLoadingNearMeLocation ? (
                <div className='flex flex-row items-center gap-2'>
                  <p>finding location...</p>
                </div>
              ) : (
                <div className='flex flex-row items-center gap-2'>
                  <UnderlinedDropdown
                    selectedValue={location.location}
                    options={whereOptions}
                    onSelect={onSelectLocation}
                  />
                </div>
              )}
            </>
          }
        />
      )}
      {isError && <PartyFoul />}
    </div>
  )
}
