import React, {useState} from 'react'

import {groupEventsByMultiSessionEvent} from '@posh/model-types'
import {GetGroupEventsEvent} from 'apis/Groups/useFetchGroupEvents'
import classNames from 'classnames'
import Button from 'components/form/Button'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {useNavigateToManageMultiSessionEventPage} from 'pages/GroupPage/ManageMultiSessionEvent/page'
import {useNavigateToMultiSessionEventPageLongUrl} from 'pages/MultiSessionEventsPublic/longUrl'

import {EventsTableItem} from '..'
import {EventsTableListItemActionsIcon} from '../Icon'
import {EventsTableListItemProps} from '../types'

import styles from './styles.module.scss'

type MultisessionGroupedEvents = Extract<
  ReturnType<
    typeof groupEventsByMultiSessionEvent<GetGroupEventsEvent, Required<GetGroupEventsEvent>['multiSessionEvent']>
  >[number],
  {isMultiSessionGroup: true}
>

type MultisessionDisplayProps = {
  multisessionGroup: MultisessionGroupedEvents
} & Omit<EventsTableListItemProps, 'item' | 'status'>

const MAX_EVENT_DISPLAY = 5

export const MultisessionDisplay = ({multisessionGroup, ...props}: MultisessionDisplayProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const {domain} = useGroupMatchParams()
  const isTooManyEvents = multisessionGroup.events.length > MAX_EVENT_DISPLAY
  const navigateToMultiSessionEventPageLongUrl = useNavigateToMultiSessionEventPageLongUrl()
  const navigateToManageMultiSessionEventPage = useNavigateToManageMultiSessionEventPage()
  const handleViewSeries = () => {
    navigateToMultiSessionEventPageLongUrl({mseUrl: multisessionGroup.multiSessionEvent.url}, {newTab: true})
  }

  const handleEditSeries = () => {
    navigateToManageMultiSessionEventPage({
      groupId: props.groupId,
      mseId: multisessionGroup.multiSessionEvent.id,
      domain: domain ?? 'owner',
    })
  }

  return (
    <div className={classNames(styles.container, 'rounded-md border border-gray-600 hover:border-transparent')}>
      <div className={styles.header} onClick={() => setIsExpanded(!isExpanded)}>
        <div className={styles.headerContent}>
          <p className='m-0 text-sm text-gray-400! italic'>Event series:</p>
          <h4 className='m-0 hover:underline' onClick={handleEditSeries}>
            {multisessionGroup.multiSessionEvent.name}
          </h4>
        </div>
        <div className={styles.buttons} onClick={e => e.stopPropagation()}>
          <EventsTableListItemActionsIcon.EditEvent description='Edit series' onClick={handleEditSeries} />
          <EventsTableListItemActionsIcon.ViewEvent description='View series' onClick={handleViewSeries} />
        </div>
      </div>
      <div
        className={styles.list}
        data-expanded={isExpanded}
        onClick={e => {
          if (!isExpanded) {
            e.stopPropagation()
            setIsExpanded(true)
          }
        }}>
        {multisessionGroup.events.slice(0, MAX_EVENT_DISPLAY).map(event => (
          <EventsTableItem key={event._id} item={event} status={event.status} {...props} showActions={isExpanded} />
        ))}
        {isTooManyEvents && (
          <div className={styles.showMore}>
            <Button className='light fullwidth dark' onClick={handleEditSeries}>
              Show all events
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
