import React, {HTMLAttributes, PropsWithChildren} from 'react'

import classNames from 'classnames'
import {QuestionMarkInSolidCircle} from 'components/assets/Icons'
import {PoshImage} from 'components/PoshImage/PoshImage'

import './index.scss'

interface Props {
  info?: string
  image?: string
  color?: string
  onClick?: () => void
  align?: 'left' | 'right'
  direction?: 'up' | 'down'
}

export function WithToolTip(props: PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>) {
  const {info, onClick, image, children, align, direction = 'up', className, ...htmlProps} = props
  return (
    <div className={classNames('PoshInfo', className)} onClick={onClick} {...htmlProps}>
      {children}
      {info && (
        <div
          className={classNames('PoshInfo-data', {
            'PoshInfo-data-right': align === 'right',
            'PoshInfo-data-left': align === 'left',
            'PoshInfo-data-up': direction === 'up',
            'PoshInfo-data-down': direction === 'down',
          })}>
          <span>{info}</span>
          {image && <PoshImage src={image} />}
        </div>
      )}
    </div>
  )
}

const COLOR = '#cfd0d2'
const SIZE = 16

const PoshInfo = (props: Props) => {
  const {info, image, onClick, direction} = props

  return (
    <WithToolTip info={info} onClick={onClick} image={image} direction={direction}>
      <QuestionMarkInSolidCircle fill={COLOR} color={props.color} height={SIZE} width={SIZE} />
    </WithToolTip>
  )
}

export default PoshInfo
