import React, {useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'

import {useDeleteEventPlaylist} from 'apis/Events/playlists/manage/useDeleteEventPlaylist'
import {useGetEventPlaylistForEditor} from 'apis/Events/playlists/manage/useGetEventPlaylistForEditor'
import {useUpdateEventPlaylist} from 'apis/Events/playlists/manage/useUpdateEventPlaylist'
import Button from 'components/form/Button'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'
import {TableIcon} from 'components/TableUI/TableIcons/TableIcon'
import {useToast} from 'components/toasts/ToastProvider'
import {trpc} from 'lib/trpc'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

import {EventPlaylistForm} from './components/EventPlaylistForm'

import styles from './UpdateEventPlaylistPage.module.scss'

const ICON_SIZE = 16

export const UpdateEventPlaylistsPage = () => {
  const {playlistId} = useParams<{playlistId: string}>()
  const {showToast} = useToast()
  const navigate = useNavigate()
  const {data, isSuccess, dataUpdatedAt} = useGetEventPlaylistForEditor({id: playlistId ?? ''})
  const qc = trpc.useContext()
  const {mutate: updateEventPlaylist, isLoading: isUpdatingEventPlaylist} = useUpdateEventPlaylist({
    onSuccess: () => {
      showToast({title: 'Playlist updated!', type: 'success'})
      qc.events.playlists.manage.getEventPlaylistForEditor.invalidate({id: playlistId!})
    },
    onError: err => {
      showToast({title: 'Failed to update playlist', subtitle: err.message, type: 'error'})
    },
  })

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const {mutate: deleteEventPlaylist, isLoading: isDeletingEventPlaylist} = useDeleteEventPlaylist({
    onSuccess: () => {
      setShowDeleteModal(false)
      navigate('/manage-event-playlists')
    },
    onError: err => {
      showToast({title: 'Failed to delete playlist', subtitle: err.message, type: 'error'})
    },
  })

  return (
    <div className={styles.PageContainer}>
      <div className={styles.PageContent}>
        <BackAndTitle title='Update Event Playlist'>
          <Link to={`/c/${data?.playlist.slug}`} style={{textDecoration: 'inherit'}} target='_blank'>
            <Button className='outline'>
              <TableIcon name='external-link' width={ICON_SIZE} height={ICON_SIZE} />
              View Playlist
            </Button>
          </Link>
        </BackAndTitle>

        {isSuccess && (
          <>
            <EventPlaylistForm
              key={`event-playlist-form-${dataUpdatedAt}`}
              playlist={data.playlist}
              onSubmit={updatedEventPlaylist => updateEventPlaylist({id: data.playlist._id, updatedEventPlaylist})}
              isSubmitting={isUpdatingEventPlaylist}
              submitText='Update'
              warnUnsavedChanges
            />
            <div style={{width: '100%'}}>
              <Button className='fit destructive' onClick={() => setShowDeleteModal(true)}>
                <TableIcon name='trash' width={ICON_SIZE} height={ICON_SIZE} />
                Delete Event Playlist
              </Button>
            </div>
            <PoshStyledModal isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
              <h3>Are you sure?</h3>
              <Button
                className='fit destructive'
                isLoading={isDeletingEventPlaylist}
                onClick={() => deleteEventPlaylist({id: data.playlist._id})}>
                <TableIcon name='trash' width={ICON_SIZE} height={ICON_SIZE} />
                Delete
              </Button>
            </PoshStyledModal>
          </>
        )}
      </div>
    </div>
  )
}
