import React, {useState} from 'react'

import {
  GetMultiSessionTrackingLinksOutput,
  useGetMultiSessionTrackingLinks,
} from 'apis/MultiSessionEvents/trackingLinks/useGetMultisessionTrackingLinks'
import {useRemoveMultiSessionTrackingLink} from 'apis/MultiSessionEvents/trackingLinks/useRemoveMultiSessionTrackingLink'
import {Modal} from 'components/coreComponents/Modal'
import Button from 'components/form/Button'
import {CRUDTable} from 'components/TableUI/CRUDTable/CRUDTable'
import {ColumnConfig} from 'components/TableUI/CRUDTable/internals/types'
import {useToast} from 'components/toasts/ToastProvider'
import {useCopyLink} from 'hooks/useCopyLink'

import {MultiSessionTrackingLinkCreateForm} from './MultiSessionTrackingLinkCreateForm'
import {generateMultiSessionTrackingLink} from './utils'

interface MultiSessionTrackingLinksProps {
  groupId: string
  mseId: string
  mseUrl: string
  mseHexUrl: string
}

export const MultiSessionTrackingLinks = (props: MultiSessionTrackingLinksProps) => {
  const {groupId, mseId, mseUrl, mseHexUrl} = props

  const {showToast} = useToast()
  const {copyLink} = useCopyLink()

  const {data, refetch, dataUpdatedAt} = useGetMultiSessionTrackingLinks({groupId, mseId})
  const trackingLinks = data?.trackingLinks || []

  const [trackingLinkToDelete, setTrackingLinkToDelete] = useState<
    GetMultiSessionTrackingLinksOutput['trackingLinks'][number] | null
  >(null)

  const {mutate: deleteTrackingLink, isLoading: isDeleting} = useRemoveMultiSessionTrackingLink({
    onSuccess: () => {
      setTrackingLinkToDelete(null)
      refetch()
    },
    onError: error => {
      showToast({
        title: 'Error removing tracking link',
        subtitle: error.message,
        type: 'error',
      })
    },
  })

  const trackingLinkConfig: ColumnConfig<GetMultiSessionTrackingLinksOutput['trackingLinks'][number]>[] = [
    {
      header: 'Tracking Link',
      key: 'value',
    },
    {
      key: 'seriesPageViews',
      header: 'Series Page Views',
      sortConfig: {
        defaultSort: true,
      },
      formatter: (value: number) => value.toString(),
    },
    {
      key: 'childEventRevenue',
      header: 'Revenue (across all events)',
      sortConfig: {
        defaultSort: true,
      },
      formatter: (value: number) => value.toString(),
    },
    {
      key: 'childEventClicks',
      header: 'Clicks (across all events)',
      sortConfig: {
        defaultSort: true,
      },
      formatter: (value: number) => value.toString(),
    },
    {
      key: 'childEventTicketsSold',
      header: 'Tickets Sold (across all events)',
      sortConfig: {
        defaultSort: true,
      },
      formatter: (value: number) => value.toString(),
    },
  ]

  return (
    <>
      <CRUDTable
        createForm={({onClose}) => (
          <MultiSessionTrackingLinkCreateForm
            trackingLinks={trackingLinks}
            groupId={groupId}
            mseId={mseId}
            mseUrl={mseUrl}
            mseHexUrl={mseHexUrl}
            onClose={onClose}
          />
        )}
        resourceName='Tracking Link'
        columns={trackingLinkConfig}
        data={trackingLinks}
        refresh={refetch}
        lastUpdated={dataUpdatedAt}
        itemsPerPage={7}
        actionButtons={[
          {
            onClick: trackingLink => {
              copyLink(generateMultiSessionTrackingLink(mseUrl, mseHexUrl, trackingLink.value).hex)
              showToast({
                title: 'Copied to Clipboard',
                subtitle: `Copied ${generateMultiSessionTrackingLink(mseUrl, mseHexUrl, trackingLink.value).hex} to clipboard`,
                type: 'info',
              })
            },
            icon: 'copy-squares',
          },
          {
            onClick: trackingLink => {
              setTrackingLinkToDelete(trackingLink)
            },
            icon: 'trash',
          },
        ]}
      />
      {trackingLinkToDelete && (
        <Modal.Glass
          className='w-full md:max-w-[650px] md:min-w-[500px]'
          isOpen={!!trackingLinkToDelete}
          onClose={() => setTrackingLinkToDelete(null)}
          blurRadius={50}
          blurTint='dark'
          brightness={0.9}>
          <div className='p-8'>
            <div className='flex flex-col gap-6'>
              <h3 className={'text-large noMargin text-center font-medium! tracking-tight'}>
                Are you sure you want to delete this tracking link?
              </h3>
              <span className={'noMargin text-medium text-center tracking-tight text-gray-300'}>
                You will lose the data associated with the{' '}
                <span className='font-bold text-white'>{trackingLinkToDelete.value}</span> tracking link including
                series page visits, revenue, event page visits, and tickets sold across all events.
              </span>
              <div className='flex justify-center gap-4'>
                <Button className='dark' onClick={() => setTrackingLinkToDelete(null)}>
                  Close
                </Button>
                <Button
                  isLoading={isDeleting}
                  onClick={() =>
                    deleteTrackingLink({mseId, groupId, trackingLink: {value: trackingLinkToDelete.value}})
                  }
                  disabled={isDeleting}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </Modal.Glass>
      )}
    </>
  )
}
