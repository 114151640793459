import React from 'react'

import {useUpdateEvent_DEPRECATED} from 'apis/Events/useUpdateEventDEPRECATED'
import {GetGroupEventsEvent} from 'apis/Groups/useFetchGroupEvents'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'
import {useResourcePageParams} from 'pages/PoshAppLayout'

const DeleteEventModal = ({
  event,
  isOpen,
  onClose,
}: {
  event?: GetGroupEventsEvent
  isOpen: boolean
  onClose: () => void
}) => {
  const {trackEvent} = useMixpanel()
  const {mutateAsync: updateEvent} = useUpdateEvent_DEPRECATED()
  const {groupId} = useResourcePageParams()
  if (!event) return null

  const onDelete = () => {
    trackEvent("I'm Sure Delete Event- Curator Event Delete Modal")
    updateEvent({eventId: event._id, groupId: groupId!, eventData: {status: 'deleted'}}).then(() => {
      onClose()
      history.go(0)
    })
  }

  return (
    <PoshStyledModal isOpen={isOpen} onClose={onClose}>
      <div className='DeleteEventModal'>
        <p>Are you sure you want to delete the event &quot;{event.name}&quot;?</p>
        <p>This action cannot be undone.</p>
        <div className='flex justify-end gap-2'>
          <Button className='Button--outline' onClick={onClose}>
            Cancel
          </Button>
          <Button className='bg-red-500! text-white!' onClick={onDelete}>
            Yes, delete it
          </Button>
        </div>
      </div>
    </PoshStyledModal>
  )
}

export default DeleteEventModal
