import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type FetchGroupDisputesOptions = ReactQueryOptions['groups']['disputes']['fetchGroupDisputes']
type FetchGroupDisputesInput = RouterInputs['groups']['disputes']['fetchGroupDisputes']
type FetchGroupDisputesOutput = RouterOutput['groups']['disputes']['fetchGroupDisputes']

export type GroupDisputesReturn = FetchGroupDisputesOutput['disputes']
export type GroupDisputeStatsReturn = FetchGroupDisputesOutput['userStats']

export function useFetchGroupDisputes(input: FetchGroupDisputesInput, opts?: FetchGroupDisputesOptions) {
  return trpc.groups.disputes.fetchGroupDisputes.useQuery(input, opts)
}
