import React, {useRef, useState} from 'react'

import {LocationData} from 'apis/Events/types'
import {DownChevron} from 'components/assets/Icons'
import PoshLocationInput from 'components/form/PoshLocationInput'
import useCheckIfClickedOutsideDiv from 'components/helpers/clickedOutsideDiv'

import './styles.scss'

export type UnderlinedDropdownOptionType = 'text' | 'city-input'
export interface UnderlinedDropdownOption {
  type: UnderlinedDropdownOptionType
  value?: string
}

interface UnderlinedDropdownProps {
  selectedValue: string
  options: UnderlinedDropdownOption[]
  onSelect: (value: string, cityCoordinates?: [number, number]) => void
}

export const UnderlinedDropdown = ({selectedValue, options, onSelect}: UnderlinedDropdownProps) => {
  const hasCityInput = options.some(o => o.type === 'city-input')
  const [open, setOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  useCheckIfClickedOutsideDiv(dropdownRef, () => {
    if (!hasCityInput) setOpen(false)
  })
  const onClickDropdown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setOpen(open => !open)
  }
  const onSelectCity = (cityData: LocationData) => {
    const {address, location} = cityData
    onSelect(address ?? 'Selected City', location?.coordinates)
    setOpen(false)
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  }
  const onSelectTextValue = (value: string) => {
    onSelect(value)
    setOpen(false)
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  }

  return (
    <div className='Dropdown' ref={dropdownRef}>
      <div className='Dropdown-button' onClick={e => onClickDropdown(e)}>
        <h4 className='text-lg text-gray-500'>{selectedValue}</h4>
        <DownChevron color={'white'} />
      </div>
      {open && (
        <div className='Dropdown-options'>
          {options.map(option => {
            const {type, value} = option
            if (type === 'text') {
              if (!value) return null
              return (
                <div key={value ?? type} onClick={() => onSelectTextValue(value)}>
                  <h3>{value}</h3>
                </div>
              )
            } else if (type === 'city-input') {
              return (
                <PoshLocationInput
                  key='city-input'
                  placeholder='Enter a City'
                  onPlaceSelected={cityData => onSelectCity(cityData)}
                  options={['(cities)']}
                  className='city'
                />
              )
            }
          })}
        </div>
      )}
    </div>
  )
}
