import React, {useState} from 'react'

import {circleGreenCheckMarkIcon, circleRedX} from 'components/assets/Icons'
import Input from 'components/form/Input'
import PoshInfo from 'components/form/PoshInfo'
import {PoshImage} from 'components/PoshImage/PoshImage'

import plusIcon from '../../../assets/images/icons/plus-yellow.svg'

interface TrackingLinkTableHeadersProps {
  isMobile: boolean
  hasTables: boolean
}

export const TrackingLinkTableHeaders = (props: TrackingLinkTableHeadersProps) => {
  const {isMobile, hasTables} = props
  return (
    <tr>
      <th>Name</th>
      {!isMobile && <th>Clicks</th>}
      {!isMobile && <th>Tickets Sold</th>}
      {!isMobile && hasTables && <th>Tables Sold</th>}
      {!isMobile && (
        <th>
          Conversion Rate
          <PoshInfo info={'% of clicks that resulted in a sale'} />
        </th>
      )}
      <th>Revenue Generated</th>
      <th />
    </tr>
  )
}

export const PromoCodeTableHeader = ({isMobile}: {isMobile: boolean}) => (
  <tr>
    <th>Promo Code</th>
    <th>Discount</th>
    <th>Uses</th>
    {!isMobile && <th>Limit</th>}
    {!isMobile && <th>Limited To</th>}
  </tr>
)

export const AddButton = (props: {onClick?: () => void}) => (
  <div className='AddButton'>
    <PoshImage onClick={props.onClick} src={plusIcon} />
  </div>
)

export const YesButton = (props: {onClick?: () => void}) => (
  <div onClick={props.onClick} className='YesButton'>
    <PoshImage src={circleGreenCheckMarkIcon} />
  </div>
)

export const NoButton = (props: {onClick?: () => void}) => (
  <div onClick={props.onClick} className='NoButton'>
    <PoshImage src={circleRedX} />
  </div>
)

export const AddTrackingLinkEditor = (props: {onYes: (link: string) => void; onNo: () => void}) => {
  const [trackingLink, setTrackingLink] = useState('')

  return (
    <div className='EditorRow'>
      <div className='EditorRow-flex'>
        <Input placeholder='Tracking Link' value={trackingLink} onChange={e => setTrackingLink(e.target.value)} />
        <div className='buttonWrapper'>
          <YesButton onClick={() => props.onYes(trackingLink)} />
          <NoButton onClick={() => props.onNo()} />
        </div>
      </div>
    </div>
  )
}
