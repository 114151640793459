import React from 'react'

import classNames from 'classnames'
import {MarkdownEditor} from 'components/form/PoshMarkdownEditor'
import {useEventVisualsContext} from 'pages/EventManagementPages/Visuals/Upgrade-dev/components/context/EventVisualsContext'

import './markdownEditorStyles.scss'
import styles from './styles.module.scss'

interface EventVisualsMarkdownEditorProps {
  value?: string
  onChange: (value: string) => void
  lightmodeOverride?: boolean
  accentColorOverride?: string
  label?: string
  placeholder?: string
}

export function EventVisualsMarkdownEditor(props: EventVisualsMarkdownEditorProps) {
  const {lightmodeOverride, accentColorOverride, label} = props
  const {
    palette: {lightmode, accentColor},
  } = useEventVisualsContext()

  const lightmodeValue = lightmodeOverride ?? lightmode
  const accentColorValue = accentColorOverride ?? accentColor

  return (
    <>
      {label && <label className='m-0'>{label}</label>}
      <div
        style={{color: accentColorValue}}
        className={classNames(styles.EventVisualsMarkdownEditor, {[styles.lightmode]: lightmodeValue})}>
        <MarkdownEditor
          {...props}
          classNames={{
            editorClassName: styles.editor,
            toolbarClassName: styles.toolbar,
            wrapperClassName: styles.wrapper,
          }}
          // Documentation: https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
          toolbarOptions={{
            options: ['inline', 'blockType', 'link', 'emoji'],
            inline: {
              options: ['bold', 'italic', 'strikethrough', 'monospace'],
            },
            blockType: {
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
            },
          }}
        />
      </div>
    </>
  )
}
