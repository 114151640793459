import React, {useState} from 'react'

import {
  ContactOrganizerReason,
  contactOrganizerReasons,
  MAX_CONTACT_ORGANIZER_MESSAGE_LENGTH,
  NON_POSH_URL,
} from '@posh/types'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import useSessionContext from 'domains/Auth/SessionContext'
import * as EmailValidator from 'email-validator'

import useContactCurator from '../../../apis/Events/useContactCurator'

import './styles.scss'

interface ContactCuratorProps extends PoshStyledModalProps {
  groupName: string
  eventId: string
}

type ContactOrganizerReasonOption = ContactOrganizerReason | ''

export default function ContactCuratorModal(props: ContactCuratorProps) {
  const {currentUser} = useSessionContext()
  const [name, setName] = useState(
    `${
      currentUser && currentUser.firstName && currentUser.lastName
        ? currentUser.firstName + ' ' + currentUser.lastName
        : ''
    }` ?? '',
  )
  const [email, setEmail] = useState(`${currentUser && currentUser.email ? currentUser.email : ''}` ?? '')
  const [contactReason, setContactReason] = useState<ContactOrganizerReasonOption>('')
  const [message, setMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const {mutateAsync: contactCurator} = useContactCurator()

  const handleSubmit = async () => {
    setErrorMessage('')
    setIsLoading(true)
    if (!currentUser) {
      setErrorMessage('You must be logged in to contact the curator.')
      setIsLoading(false)
      return
    }
    if (!name) {
      setErrorMessage('Please include your name')
      setIsLoading(false)
      return
    }
    if (!email) {
      setErrorMessage('Please include your email')
      setIsLoading(false)
      return
    }
    if (!EmailValidator.validate(email)) {
      setErrorMessage('Please input a valid email address.')
      setIsLoading(false)
      return
    }
    if (!contactReason) {
      setErrorMessage('Please include the contact reason')
      setIsLoading(false)
      return
    }
    if (!message) {
      setErrorMessage('Please include a message')
      setIsLoading(false)
      return
    }
    if (message.length > MAX_CONTACT_ORGANIZER_MESSAGE_LENGTH) {
      setErrorMessage(`Please enter a message under ${MAX_CONTACT_ORGANIZER_MESSAGE_LENGTH} characters`)
      setIsLoading(false)
      return
    }
    if (new RegExp(NON_POSH_URL).test(message)) {
      setErrorMessage('Cannot submit a message with a link')
      setIsLoading(false)
      return
    }
    try {
      await contactCurator({eventId: props.eventId, message, reason: contactReason, email, fullName: name})
      setName('')
      setEmail('')
      setMessage('')
      setContactReason('')
      setSuccessMessage('Message Successfully Sent!')
      setIsLoading(false)
      return
    } catch (error) {
      setErrorMessage(error.response.data.error)
      setIsLoading(false)
      return
    }
  }

  return (
    <PoshStyledModal {...props}>
      <div className='ContactCuratorModal'>
        <h3 style={{color: 'white'}}>Contact {props.groupName}</h3>
        <Input defaultValue={name} onChange={e => setName(e.target.value)} type='text' placeholder='Your Name' />
        <Input defaultValue={email} onChange={e => setEmail(e.target.value)} type='email' placeholder='Email Address' />
        <Select onChange={e => setContactReason(e.target.value as ContactOrganizerReason)} className='size-full'>
          <option selected disabled>
            Select a contact reason...
          </option>
          {contactOrganizerReasons.map((reason, index) => (
            <option value={reason} key={index}>
              {reason}
            </option>
          ))}
        </Select>
        <textarea
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder='Your Message'
          className='ContactCuratorModal-Textarea'
        />
        {errorMessage && <p style={{color: 'red', fontWeight: 'lighter'}}>{errorMessage}</p>}
        {successMessage && <p style={{color: 'green', fontWeight: 'lighter'}}>{successMessage}</p>}
        {!successMessage.length && (
          <Button className={'Gold'} onClick={() => handleSubmit()} disabled={isLoading} style={{marginBottom: '15px'}}>
            {isLoading ? 'Sending message...' : 'Send Message'}
          </Button>
        )}
      </div>
    </PoshStyledModal>
  )
}
