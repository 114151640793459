import {useSearchParams} from 'react-router-dom'

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@posh/design-kit/components/dialog'
import {GroupDisputesReturn} from 'apis/Groups/useFetchGroupDisputes'
import {X} from 'lucide-react'

import DisputeDetails from './dispute-components/DisputeDetails'
import DisputeTimeline from './dispute-components/DisputeTimeline'
import OpenDisputeForm from './dispute-components/OpenDisputeForm'

import './styles.scss'

interface DisputeDetailDialogProps {
  disputes: GroupDisputesReturn
  onSubmitEvidence: () => void
}

const DisputeDetailDialog = ({disputes, onSubmitEvidence}: DisputeDetailDialogProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const disputeId = searchParams.get('disputeId')

  const selectedDisputeData = disputes.find(d => d.id === disputeId) || null

  const handleClose = () => {
    // Remove the disputeId from URL when closing
    searchParams.delete('disputeId')
    setSearchParams(searchParams)
  }

  return (
    <Dialog open={!!disputeId} onOpenChange={handleClose}>
      <DialogContent className='dispute-dialog-content' hideCloseButton>
        <DialogHeader className='dispute-dialog-header'>
          <DialogTitle className='dispute-dialog-title'>
            {selectedDisputeData?.status === 'needs_response' ||
            selectedDisputeData?.status === 'warning_needs_response'
              ? 'Submit Evidence'
              : 'Dispute Details'}
          </DialogTitle>
          <DialogDescription className='dispute-dialog-description'>
            Review dispute details and provide supporting evidence
          </DialogDescription>
        </DialogHeader>

        <div className='dispute-dialog-body'>
          <DisputeDetails selectedDisputeData={selectedDisputeData} />

          {selectedDisputeData?.status === 'needs_response' ||
          selectedDisputeData?.status === 'warning_needs_response' ? (
            <OpenDisputeForm selectedDisputeData={selectedDisputeData} onSubmitEvidence={onSubmitEvidence} />
          ) : (
            <DisputeTimeline selectedDisputeData={selectedDisputeData} />
          )}
        </div>

        <DialogClose className='dispute-dialog-close'>
          <X className='dispute-dialog-close-icon' />
          <span className='sr-only'>Close</span>
        </DialogClose>
      </DialogContent>
    </Dialog>
  )
}

export default DisputeDetailDialog
