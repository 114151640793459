// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Logo__N5\\+L6.mobile__kwZjF{padding:unset}.Logo__rotateIconOnHover__KFk86{transition:transform .3s ease-in-out}.Logo__rotateIconOnHover__KFk86:hover[data-is-expanded=true]{transform:rotate(90deg)}.Logo__rotateIconOnHover__KFk86:hover[data-is-expanded=false]{transform:rotate(-90deg)}", "",{"version":3,"sources":["webpack://./src/components/Navigation/Primary/components/shared/NavigationItem/Logo/styles.module.scss"],"names":[],"mappings":"AAGI,4BACI,aAAA,CAIR,gCACI,oCAAA,CAII,6DACI,uBAAA,CAGJ,8DACI,wBAAA","sourcesContent":["@import '@global-styles/color_pallete';\n\n.Logo {\n    &.mobile {\n        padding: unset;\n    }\n}\n\n.Logo__rotateIconOnHover {\n    transition: transform 0.3s ease-in-out;\n\n    &:hover {\n\n        &[data-is-expanded='true'] {\n            transform: rotate(90deg);\n        }\n\n        &[data-is-expanded='false'] {\n            transform: rotate(-90deg);\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Logo": "Logo__N5+L6",
	"mobile": "mobile__kwZjF",
	"Logo__rotateIconOnHover": "Logo__rotateIconOnHover__KFk86"
};
export default ___CSS_LOADER_EXPORT___;
