import {getBaseUrl} from '@posh/model-types'
import {EventResponse} from 'apis/Accounts/useFetchAllAttendeeOrdersAndEvents'
import {EventPublic} from 'apis/Events/types'
import {DateArray} from 'ics'
import moment from 'moment-timezone'

function getEventCalendarDescription(event: EventPublic | EventResponse) {
  const eventDescription = event.shortDescription || event.description || ''
  const url = `${getBaseUrl()}/e/${event.url}`
  return `${eventDescription}\n\nMore details: ${url}`
}

type CalendarLinkParams = {
  name: string
  startUtc: string | Date
  endUtc: string | Date
  timezone: string
  description?: string
  location?: string
}

function formatCalendarDate(date: CalendarLinkParams['startUtc'] | CalendarLinkParams['endUtc']) {
  return moment.utc(date).format('YYYYMMDDTHHmmss[Z]')
}

function getGoogleCalendarLink(params: CalendarLinkParams) {
  const {name, startUtc, endUtc, timezone, description = '', location = ''} = params
  const dates = `${formatCalendarDate(startUtc)}/${formatCalendarDate(endUtc)}`

  const url = new URL('https://calendar.google.com/calendar/render')
  const searchParams = new URLSearchParams({
    action: 'TEMPLATE',
    text: name,
    dates: dates,
    details: description,
    sf: 'true',
    output: 'xml',
    ctz: timezone,
  })

  if (location) searchParams.append('location', location)

  url.search = searchParams.toString()

  return url.toString()
}

function getOutlookCalendarLink(params: CalendarLinkParams) {
  const {name, startUtc, endUtc, timezone, description = '', location = ''} = params

  const url = new URL('https://outlook.live.com/owa/?path=/calendar/action/compose')

  const searchParams = new URLSearchParams({
    rru: 'addevent',
    startdt: formatCalendarDate(startUtc),
    enddt: formatCalendarDate(endUtc),
    subject: name,
    body: description,
    timezone,
  })

  if (location) searchParams.append('location', location)

  url.search = searchParams.toString()

  return url.toString()
}

function getEventCalendarLinkParams(event: EventPublic | EventResponse): CalendarLinkParams | null {
  const {startUtc, endUtc, timezone, name, venue: {address} = {address: ''}} = event

  if (!startUtc || !endUtc || !timezone || !name) return null

  return {
    startUtc,
    endUtc,
    timezone,
    name,
    location: address,
    description: getEventCalendarDescription(event),
  }
}

function getIcsDateArray(dateUtc: string | Date): DateArray {
  const date = moment.utc(dateUtc)
  return [date.year(), date.month() + 1, date.date(), date.hour(), date.minute()]
}

export const CalendarHelpers = {
  calendarLink: {
    google: getGoogleCalendarLink,
    outlook: getOutlookCalendarLink,
  },
  getIcsDateArray,
  getEventCalendarLinkParams,
}
