import {Link, useParams} from 'react-router-dom'

import {Button} from '@posh/design-kit/components/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@posh/design-kit/components/dropdown-menu'
import {ellipsize} from '@posh/model-types'
import {useGetMultiSessionEvent} from 'apis/MultiSessionEvents/useGetMultiSessionEvent'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {ChevronDown as ChevronDownIcon} from 'lucide-react'
import moment from 'moment-timezone'

import styles from './MultiSessionSpecificEventAnalyticsSelect.module.scss'
interface MultiSessionSpecificEventAnalyticsSelectProps {
  groupId: string
  mseId: string
}

export const MultiSessionSpecificEventAnalyticsSelect = (props: MultiSessionSpecificEventAnalyticsSelectProps) => {
  const {groupId, mseId} = props
  const {domain} = useParams()
  const {data} = useGetMultiSessionEvent({
    groupId,
    id: mseId,
  })

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className='border border-input font-[Inter] font-light text-white' variant='outline'>
          View Individual Event Analytics <ChevronDownIcon className='size-4' />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='end' className='dark max-h-96 w-80'>
        <DropdownMenuGroup>
          {data?.events.map(event => (
            <DropdownMenuItem key={event._id}>
              <Link
                to={`/${domain}/groups/${groupId}/events/${event._id}/overview?fromMse=${mseId}`}
                className={styles.menuItemLink}>
                <PoshImage className='h-10 w-10 rounded-lg object-cover' src={event.flyer} />
                <p className='text-small noMargin'>
                  {ellipsize(event.name, 30)} {moment.tz(event.startUtc, event.timezone).format('(M/D)')}
                </p>
              </Link>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
