import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type TSubmitDisputeEvidenceInput = RouterInputs['groups']['disputes']['submitDisputeEvidence']
export type TSubmitDisputeEvidenceOutput = RouterOutput['groups']['disputes']['submitDisputeEvidence']
type TSubmitDisputeEvidenceOptions = ReactQueryOptions['groups']['disputes']['submitDisputeEvidence']

export function useSubmitDisputeEvidence(
  opts?: TSubmitDisputeEvidenceOptions,
): ReturnType<typeof trpc.groups.disputes.submitDisputeEvidence.useMutation> {
  return trpc.groups.disputes.submitDisputeEvidence.useMutation(opts)
}
