import React, {ComponentProps, forwardRef} from 'react'

import {buildCloudflareImageUrl, CloudflareTransformOptions} from '@posh/model-types'

export type PoshImageProps = ComponentProps<'img'> & {
  transformOptions?: CloudflareTransformOptions
}

export const PoshImage = forwardRef<HTMLImageElement, PoshImageProps>((props: PoshImageProps, ref) => {
  const imageProps = processPoshImageProps(props)
  return <img ref={ref} {...imageProps} />
})

PoshImage.displayName = 'PoshImage'

const processPoshImageProps = ({transformOptions, ...props}: PoshImageProps) => {
  if (props.src?.startsWith('blob:')) return props

  if (props.src && (props.src.includes('https://') || props.src.includes('http://'))) {
    return {
      ...props,
      src: getWebCloudflareImageUrl(props.src, transformOptions),
    }
  }
  return props
}

type PoshImageBackgroundProps = ComponentProps<'div'> & {
  transformOptions?: CloudflareTransformOptions
}

export const PoshImageBackground = forwardRef<HTMLDivElement, PoshImageBackgroundProps>(
  (props: PoshImageBackgroundProps, ref) => {
    const imageProps = processPoshImageBackgroundProps(props)
    return <div ref={ref} {...imageProps} />
  },
)

PoshImageBackground.displayName = 'PoshImageBackground'

const extractUrlFromBackgroundImage = (backgroundImage: string) => {
  const match = backgroundImage.match(/url\(['"]?(.*?)['"]?\)/)
  return match ? match[1] : null
}

const processPoshImageBackgroundProps = ({transformOptions, ...props}: PoshImageBackgroundProps) => {
  if (
    props.style &&
    props.style.backgroundImage &&
    typeof props.style.backgroundImage === 'string' &&
    (props.style.backgroundImage.includes('https://') || props.style.backgroundImage.includes('http://'))
  ) {
    const extractedUrl = extractUrlFromBackgroundImage(props.style.backgroundImage)

    if (!extractedUrl) {
      return props
    }

    const newBackgroundImage = `url(${getWebCloudflareImageUrl(extractedUrl, transformOptions)})`

    return {
      ...props,
      style: {
        ...props.style,
        backgroundImage: newBackgroundImage,
      },
    }
  }
  return props
}

const WEB_DEFAULT_CLOUDFLARE_TRANSFORMATION_OPTIONS: CloudflareTransformOptions = {
  quality: 85,
  fit: 'scale-down',
  // We want to use the original image format if it's an SVG, otherwise we'll use WEBP
  format: 'webp',
  // For web, we will use the recommended width of 1920 by Cloudflare (since we aren't using responsive images)
  width: 1920,
}

const getDefaultCloudflareTransformationOptions = (src: string): CloudflareTransformOptions => {
  if (src.endsWith('.svg')) {
    return {
      ...WEB_DEFAULT_CLOUDFLARE_TRANSFORMATION_OPTIONS,
      format: 'svg',
    }
  }
  return WEB_DEFAULT_CLOUDFLARE_TRANSFORMATION_OPTIONS
}

export const getWebCloudflareImageUrl = (
  src: string,
  transformOptions: CloudflareTransformOptions = getDefaultCloudflareTransformationOptions(src),
) => {
  return buildCloudflareImageUrl(src, transformOptions)
}
