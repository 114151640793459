import React from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {useGetMultiSessionEvent} from 'apis/MultiSessionEvents/useGetMultiSessionEvent'
import PageNotFound from 'pages/PageNotFound/PageNotFound'
import {z} from 'zod'

import {MultiSessionAnalytics} from '../components/MultiSessionAnalytics'

const manageMultiSessionEventPageAnalyticsParamsSchema = z.object({
  groupId: z.string(),
  mseId: z.string(),
  domain: z.string(), // why do we still have this
})

function useManageMultiSessionEventPageAnalyticsParams() {
  const {groupId, mseId, domain} = useParams()
  const params = manageMultiSessionEventPageAnalyticsParamsSchema.parse({groupId, mseId, domain})
  return params
}

export function useNavigateToManageMultiSessionEventPageAnalytics() {
  const navigate = useNavigate()
  return (params: z.infer<typeof manageMultiSessionEventPageAnalyticsParamsSchema>) => {
    navigate(`/${params.domain}/groups/${params.groupId}/series/${params.mseId}/analytics`)
  }
}

export const MultiSessionAnalyticsPage = () => {
  const {groupId, mseId} = useManageMultiSessionEventPageAnalyticsParams()

  const {data: mse} = useGetMultiSessionEvent({groupId, id: mseId}, {enabled: !!groupId && !!mseId})

  if (!groupId || !mseId) {
    return <PageNotFound />
  }

  return <MultiSessionAnalytics groupId={groupId} mseId={mseId} mse={mse} />
}
