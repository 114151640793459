import React, {ButtonHTMLAttributes} from 'react'

import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'

import './styles.scss'

const filterIconUrl = 'https://images.posh.vip/b2/filter.svg'

export const FilterButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <Button className='FilterButton dark' {...props}>
      <PoshImage className='Attendees-actionBarButton--image' src={filterIconUrl} />
      Filter
    </Button>
  )
}
