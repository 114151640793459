import React, {PropsWithChildren, useCallback} from 'react'

import {BaseModal, BaseModalProps} from '../BaseModal'
import {CenteredModal} from './Centered'

export interface BlurBackgroundModalProps extends Omit<BaseModalProps, 'Background'> {
  radius?: number
  className?: string
}

function BlurBackground({radius = 5, className}: {radius?: number; className?: string}) {
  return (
    <div
      style={{
        backdropFilter: `blur(${radius}px)`,
        WebkitBackdropFilter: `blur(${radius}px)`,
        height: '100%',
        width: '100%',
      }}
      className={className}
    />
  )
}

export function BlurBackgroundModal({children, ...props}: PropsWithChildren<BlurBackgroundModalProps>) {
  const Background = useCallback(() => BlurBackground({...props}), [props])
  return (
    <BaseModal {...props} Background={Background}>
      {children}
    </BaseModal>
  )
}

function BlurBackgroundCentered(props: BlurBackgroundModalProps) {
  const Background = useCallback(() => BlurBackground({...props}), [props])
  return <CenteredModal {...props} Background={Background} />
}

BlurBackgroundModal.Centered = BlurBackgroundCentered
