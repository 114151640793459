import React, {useMemo} from 'react'

import {DownChevron, IOSBattery, IOSCellularSignal, IOSContact, IOSWifiSignal} from 'components/assets/Icons'
import {appleKeyboard} from 'components/assets/Images'
import {PoshImage} from 'components/PoshImage/PoshImage'
import * as DOMPurify from 'dompurify'
import Random from 'helpers/Random'
import moment from 'moment'

import './IMessagePreview.styles.scss'

interface IMessagePreviewProps {
  message?: string
  phoneNumber?: string
  splitOn?: number
}

const POSH_LINK_REGEX = /https:\/\/posh.vip\/.*/
const IMessagePreviewMessage = ({message}: {message: string}) => {
  return (
    <div
      className='IMessagePreview__conversation__message'
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(message).replace(
          POSH_LINK_REGEX,
          '<a target="_blank" rel="noreferrer" href="$&">$&</a>',
        ),
      }}></div>
  )
}

const IMessagePreviewOSBar = () => {
  const time = moment().format('h:mm')
  return (
    <div className='IMessagePreview__header__osBar space-between'>
      <div>{time}</div>
      <div>
        <IOSCellularSignal /> <IOSWifiSignal /> <IOSBattery id='battery' />
      </div>
    </div>
  )
}

const IMessagePreviewNotifCounter = ({id, notificationCount}: {id?: string; notificationCount: number}) => {
  return (
    <div id={id}>
      <DownChevron className='IMessagePreview__header__back' />
      <span className='IMessagePreview__header__notifCounter'>{notificationCount}</span>
    </div>
  )
}

const DEFAULT_FROM_NUMBER = '42302'
const IMessagePreview = ({message, phoneNumber}: IMessagePreviewProps) => {
  const conversationTime = moment().format('h:mm A')
  const notificationCount = useMemo(() => Random.integer(1, 999), [])

  const displayMessage =
    message ??
    "Ruffolo Hopsitality: Hey David! We’re throwing down at Bosco this Friday at 8pm!'\n\n Reply “RSVP” to RSVP.\nposh.vip/f/iu3u8?t=dt2"
  const fromNumber = !!phoneNumber ? phoneNumber : DEFAULT_FROM_NUMBER
  return (
    <div className='IMessagePreview'>
      <div className='IMessagePreview__header'>
        <IMessagePreview.OSBar />
        <div className='space-between'>
          <IMessagePreview.NotifCounter notificationCount={notificationCount} />
          <div className='IMessagePreview__header__contact'>
            <IOSContact />
          </div>
          <IMessagePreview.NotifCounter id='ghost' notificationCount={notificationCount} />
        </div>
        <div className='IMessagePreview__header__contactNumber'>{fromNumber}</div>
      </div>
      <div className='IMessagePreview__conversation'>
        <div className='IMessagePreview__conversation__time'>
          <strong>Today</strong> {conversationTime}
        </div>
        {message && <IMessagePreview.Message message={displayMessage} />}
      </div>
      <div className='IMessagePreview__keyboardImage'>
        <PoshImage src={appleKeyboard} />
        <div className='IMessagePreview__keyboardImage__fade' />
      </div>
    </div>
  )
}

IMessagePreview.Message = IMessagePreviewMessage
IMessagePreview.OSBar = IMessagePreviewOSBar
IMessagePreview.NotifCounter = IMessagePreviewNotifCounter

export default IMessagePreview
