import React, {useEffect, useState} from 'react'
import NumberFormat from 'react-number-format'

import {getInstantTransferAmounts, INSTANT_TRANSFER_MIN_AMOUNT, POSH_FEE_FOR_INSTANT_TRANSFER} from '@posh/model-types'
import {PayoutType} from 'apis/Groups'
import {TGetGroupForFinancialsOutput} from 'apis/Groups/useGetGroupForFinancials'
import {useTransferBalance} from 'apis/Groups/useTransferBalance'
import {useMixpanel} from 'apis/MixPanelHandler'
import classNames from 'classnames'
import {Bank, Bolt} from 'components/assets/Icons'
import Button from 'components/form/Button'
import PoshInfo from 'components/form/PoshInfo'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {isUndefined} from 'lodash'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

const POSH_FEE_INSTANT_TRANSFER_PERCENT = POSH_FEE_FOR_INSTANT_TRANSFER * 100

function PayoutTypeSelector(props: PayoutTypeSelectorProps) {
  const {payoutType, setPayoutType, shouldShowInstant} = props

  return (
    <>
      <a
        onClick={() => setPayoutType('standard')}
        className={classNames('flex h-[120px] w-[130px] cursor-pointer flex-col justify-between rounded-lg border', {
          'border-white bg-black transition-all': payoutType === 'standard',
          'border-gray-700 transition-all hover:shadow-md hover:shadow-white': payoutType !== 'standard',
        })}>
        <div>
          <div className='mt-1 flex flex-col items-center'>
            <Bank className='mx-auto w-10' />
          </div>
          <p className='!m-0 text-center text-lg'>2-3 Biz Days</p>
        </div>
        <p className='!m-0 text-center text-xs text-gray-400'>No Fee</p>
      </a>
      <a
        onClick={() => {
          if (shouldShowInstant === false) return
          setPayoutType('instant')
        }}
        className={classNames(
          'flex h-[120px] w-[130px] cursor-pointer flex-col justify-between rounded-lg border transition-all',
          {
            'border-purple-500 bg-black': payoutType === 'instant',
            'border-gray-700 hover:shadow-md hover:shadow-white': payoutType !== 'instant' && shouldShowInstant,
            'cursor-not-allowed opacity-50': !shouldShowInstant,
          },
        )}>
        <div>
          <div className='mt-1 flex flex-col items-center'>
            <Bolt className='mx-auto w-10' />
          </div>
          <p className='!m-0 text-center text-lg'>INSTANT</p>
        </div>
        <p className='!m-0 text-center text-xs text-gray-400'>{POSH_FEE_INSTANT_TRANSFER_PERCENT}% Fee</p>
      </a>
    </>
  )
}

const EMPTY_INPUT_WIDTH = 65
const MONETARY_DIGIT_WIDTH = 17
const INSTANT_ELIGIBLE_BANK_ACCOUNT_ERROR = 'Please add an instant eligible bank account to initiate instant payouts.'

interface Balances {
  availableBalance: string
  instantBalance: string
  availableBalanceFormatted: string
  instantAvailableBalanceFormatted: string
}

interface TransferBalanceProps extends PoshStyledModalProps {
  group: TGetGroupForFinancialsOutput
  hasInstantPayout: boolean
  balances: Balances
}

interface PayoutTypeSelectorProps {
  setPayoutType: React.Dispatch<React.SetStateAction<PayoutType>>
  payoutType: PayoutType | undefined
  shouldShowInstant: boolean
}

export default function TransferBalanceModal(props: TransferBalanceProps) {
  const {group, balances, hasInstantPayout} = props
  const {trackEvent} = useMixpanel()
  const {availableBalance, instantBalance, availableBalanceFormatted, instantAvailableBalanceFormatted} = balances
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [payoutAmount, setPayoutAmount] = useState('')
  const [formatedPayoutAmount, setFormatedPayoutAmount] = useState('')
  const [payoutType, setPayoutType] = useState<PayoutType>('standard')
  const [deductedPayoutAmount, setDeductedPayoutAmount] = useState('')
  const {mutateAsync: transferBalance, error, isLoading} = useTransferBalance()
  const currency = getCurrencySymbol(group.currency)

  // Only show instant payout if the account is verified
  const shouldShowInstant = !isUndefined(group.verified) && group.verified === true
  useEffect(() => {
    const payoutNumber = parseFloat(payoutAmount)
    if (isNaN(payoutNumber)) return
    const {transferAmount} = getInstantTransferAmounts(payoutNumber)
    setDeductedPayoutAmount(transferAmount.string)
  }, [payoutAmount])

  useEffect(() => {
    if (error) setErrorMessage(error.message)
  }, [error])

  const handleInitiatePayout = async () => {
    setErrorMessage('')
    const payout = parseFloat(payoutAmount)
    if (payoutType === 'instant') {
      trackEvent('2-3 Biz Days- Initiate Payout Finance Page', {transferAmount: payout})
    } else {
      trackEvent('Instant Payout- Initiate Payout Finance Page', {transferAmount: payout})
    }
    const groupId = group._id
    if (!payoutType) {
      setErrorMessage('Please choose a payout type.')
      return
    } else if (payoutType === 'instant' && hasInstantPayout === false) {
      setErrorMessage(INSTANT_ELIGIBLE_BANK_ACCOUNT_ERROR)
      return
    } else {
      await transferBalance({groupId, amount: payout, type: payoutType})
      setSuccessMessage('Successfully initiated payout!')
    }
  }

  const shouldDisableButton = () => {
    if (!payoutAmount) {
      return {disabled: true, message: 'Enter an amount to initiate a payout'}
    }

    if (!payoutType) {
      return {disabled: true, message: 'Select a payout type to initiate a payout'}
    }

    if (!isValidPayoutAmount()) {
      const balance = payoutType === 'instant' ? instantAvailableBalanceFormatted : availableBalanceFormatted

      if (payoutType === 'instant' && parseFloat(payoutAmount) < INSTANT_TRANSFER_MIN_AMOUNT) {
        return {
          disabled: true,
          message: `Enter a minimum of ${currency}${INSTANT_TRANSFER_MIN_AMOUNT}.00 to initiate a payout`,
        }
      }

      return {
        disabled: true,
        message: `Enter less than your balance of ${balance} to initiate a payout`,
      }
    }

    return {disabled: false, message: ''}
  }

  const isValidPayoutAmount = () => {
    const balance = payoutType === 'instant' ? instantBalance : availableBalance
    const parsedPayoutAmount = parseFloat(payoutAmount)
    if (isNaN(parsedPayoutAmount)) return false
    if (payoutType === 'instant' && parsedPayoutAmount < INSTANT_TRANSFER_MIN_AMOUNT) return false

    if (balance && parsedPayoutAmount <= parseFloat(balance)) return true
    else return false
  }

  const SelectPayout = () => {
    const limit = payoutType === 'instant' ? instantAvailableBalanceFormatted : availableBalanceFormatted
    const {disabled, message} = shouldDisableButton()

    const getButtonText = () => {
      if (disabled && message) {
        return message
      }

      if (payoutType === 'instant') {
        return `Instant Payout ${currency}${deductedPayoutAmount} (${POSH_FEE_INSTANT_TRANSFER_PERCENT}% fee)`
      }
      return `Payout ${currency}${formatedPayoutAmount} (2-3 Day Arrival)`
    }

    return (
      <>
        {limit && <p className='my-2 text-center text-sm text-gray-400'>Transfer up to {limit}</p>}
        {payoutType === 'instant' && (
          <p className='my-2 text-center text-sm text-gray-400'>
            ({currency}
            {INSTANT_TRANSFER_MIN_AMOUNT}.00 minimum)
          </p>
        )}
        <div className='my-5 flex w-full justify-center gap-5'>
          <PayoutTypeSelector
            setPayoutType={setPayoutType}
            payoutType={payoutType}
            shouldShowInstant={shouldShowInstant}
          />
        </div>
        {!shouldShowInstant && (
          <div className='my-3 flex items-center justify-center rounded-lg bg-gray-900 px-3 py-2 text-center'>
            <p className='m-0 text-sm text-gray-400'>Only standard payouts are available</p>
            <PoshInfo info='You must be a verified organizer and have an instant eligible payout method to use instant payouts' />
          </div>
        )}

        {isLoading ? (
          <div className='my-4 flex w-full justify-center'>
            <SpinLoader />
          </div>
        ) : (
          <div className='flex w-full items-center justify-center'>
            <div className='w-full'>
              <Button
                disabled={disabled}
                onClick={() => handleInitiatePayout()}
                className={classNames(
                  'text-normal animate-fadeIn mx-auto mt-3 w-4/5 rounded-full transition-all duration-200',
                  {
                    'bg-gradient-to-r from-blue-600 to-purple-600 text-white': payoutType === 'instant' && !disabled,
                    'bg-yellow-500': payoutType !== 'instant' && !disabled,
                    'cursor-not-allowed opacity-70 hover:shadow-none': disabled,
                    'hover:shadow-md hover:shadow-white': !disabled && payoutType !== 'instant',
                    'hover:shadow-md hover:shadow-purple-500': !disabled && payoutType === 'instant',
                  },
                )}>
                {getButtonText()}
              </Button>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <PoshStyledModal {...props}>
      <div className='mb-6'>
        {errorMessage && <p className='text-center text-red-500'>{errorMessage}</p>}
        {successMessage && <p className='text-center text-green-500'>{successMessage}</p>}
        <>
          <h3 className='m-0 text-center'>Initiate Payout</h3>
          <div className='flex items-center justify-center transition-all duration-100'>
            <p className='mt-6 text-lg text-gray-400'>{currency}</p>
            <NumberFormat
              className={classNames(
                'mt-4 cursor-pointer border-none bg-transparent text-center text-4xl caret-white transition-all duration-100 outline-none',
                {
                  'text-white': isValidPayoutAmount(),
                  'text-red-500': !isValidPayoutAmount() && payoutAmount,
                },
              )}
              placeholder={`0`}
              style={{width: `${EMPTY_INPUT_WIDTH + formatedPayoutAmount.length * MONETARY_DIGIT_WIDTH}`}}
              value={formatedPayoutAmount}
              thousandSeparator=','
              allowNegative={false}
              autoFocus
              onValueChange={values => {
                setFormatedPayoutAmount(values.formattedValue)
                setPayoutAmount(values.value)
              }}
            />
          </div>
          <SelectPayout />
        </>
      </div>
    </PoshStyledModal>
  )
}
