import React, {useMemo, useReducer, useState} from 'react'

import {Session} from '@posh/express-helpers'
import {AuthedAccount} from 'apis/Auth/useLoginWithEmail'
import {AccountRoleResponse} from 'apis/Roles'
import {getRudderstackAnalytics} from 'helpers/RudderstackAnalytics'
import Cookies from 'js-cookie'

import * as monitoring from '../../providers/monitoring'
import {AccountRoleGroupIdMap, SessionContext} from './SessionContext'

type SessionToken = string | null | undefined

const getObjectFromLocalStorage = (key: string) => {
  try {
    const item = localStorage.getItem(key)
    return item ? JSON.parse(item) : null
  } catch (e) {
    return null
  }
}

export function getAccountRoleGroupIdMap(): AccountRoleGroupIdMap | null {
  const accountRoles = localStorage.getItem('accountRoleGroupIdMap')
  return accountRoles ? JSON.parse(accountRoles!) : null
}

export const removeLocalStorageItems = () => {
  localStorage.removeItem('userId')
  localStorage.removeItem('token')
  localStorage.removeItem('currentUser')
  localStorage.removeItem('accountRoles')
  localStorage.removeItem('accountRoleGroupIdMap')
  localStorage.removeItem('token_expires')
  localStorage.removeItem('groupsWhereAccountIsOwnerMap')
}

export const removeCookieStorageItems = () => {
  Cookies.remove('authenticated', {path: '/'})
}

const SessionContextProvider = (props: {children: React.ReactNode}) => {
  const rudderStackClient = getRudderstackAnalytics()

  // LOCAL STORAGE VALUES
  const {
    localStorageToken,
    localStorageUserId,
    localStorageCurrentUser,
    localStorageAccountRoles,
    localStorageAccountRoleGroupIdMap,
    localStorageGroupsWhereAccountIsOwnerMap,
  } = useMemo(() => {
    return {
      localStorageToken: localStorage.getItem('token'),
      localStorageUserId: localStorage.getItem('userId'),
      localStorageCurrentUser: getObjectFromLocalStorage('currentUser'),
      localStorageAccountRoles: getObjectFromLocalStorage('accountRoles'),
      localStorageAccountRoleGroupIdMap: getObjectFromLocalStorage('accountRoleGroupIdMap'),
      localStorageGroupsWhereAccountIsOwnerMap: getObjectFromLocalStorage('groupsWhereAccountIsOwnerMap'),
    }
  }, [])
  /**
   * To track changes in the current user session we observe updates to the
   * auth token. This permits us to track all session types — account and pin.
   * And, limits our monitoring to only the data we deem relevant to include in
   * the session token itself.
   */
  const [token, setToken] = useReducer(
    (_: SessionToken, token: SessionToken) => monitorSession(token),
    undefined,
    () => monitorSession(localStorageToken),
  )

  const [userId, setUserId] = useState<string | undefined | null>(() => localStorageUserId)
  const [currentUser, setCurrentUser] = useState<AuthedAccount | undefined>(() => localStorageCurrentUser)
  const [accountRoles, setAccountRoles] = useState<AccountRoleResponse[] | undefined>(localStorageAccountRoles)
  const [accountRoleGroupIdMap, setAccountRoleGroupIdMap] = useState<AccountRoleGroupIdMap | undefined>(
    localStorageAccountRoleGroupIdMap,
  )
  const [groupsWhereAccountIsOwnerMap, setGroupsWhereAccountIsOwnerMap] = useState<Record<string, string> | undefined>(
    localStorageGroupsWhereAccountIsOwnerMap,
  )

  if (rudderStackClient && userId) {
    rudderStackClient.identify(userId, {type: 'account'}, {})
  }
  const updateLocalAccountAvi = (avi: string) => {
    const updatedCurrentUser = {...currentUser!, avi}
    setCurrentUser(updatedCurrentUser)
    localStorage.setItem('currentUser', JSON.stringify(updatedCurrentUser))
  }

  const unauthenticateSession = () => {
    setUserId(undefined)
    setToken(undefined)
    setCurrentUser(undefined)
    setAccountRoles(undefined)
    setAccountRoleGroupIdMap(undefined)
    setGroupsWhereAccountIsOwnerMap(undefined)
    removeLocalStorageItems()
    removeCookieStorageItems()
  }

  const contextState = useMemo(() => {
    return {
      userId,
      token,
      currentUser,
      setUserId,
      setToken,
      setCurrentUser,
      accountRoles,
      setAccountRoles,
      accountRoleGroupIdMap,
      setAccountRoleGroupIdMap,
      unauthenticateSession,
      groupsWhereAccountIsOwnerMap,
      setGroupsWhereAccountIsOwnerMap,
      updateLocalAccountAvi,
    }
  }, [userId, token, currentUser, accountRoles, accountRoleGroupIdMap, groupsWhereAccountIsOwnerMap])

  return <SessionContext.Provider value={contextState}>{props.children}</SessionContext.Provider>
}

function monitorSession(token?: SessionToken) {
  // parse session from token
  let session: Session | undefined
  try {
    if (token) session = JSON.parse(atob(token.split('.')[1]))
  } catch (e) {}

  // clear or updates monitored user
  monitoring.setUser(
    !session
      ? undefined
      : {
          id: session.id,
          ...(session.type === 'account' && {
            email: session.payload.email,
            operator: session.payload.operator,
          }),
          session,
        },
  )

  // pass through value
  return token
}

export default SessionContextProvider
