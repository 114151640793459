import React from 'react'

import getEventFlyer from 'apis/Util/getEventFlyer'

import {EventsTableListItemActions as Actions} from './Actions'
import {EventsTableListItemContent as Content} from './Content'
import {EventsTableListItemCopyBlock as CopyBlock} from './CopyBlock'
import {EventsTableListItemImage as Image} from './Image'
import {EventsTableListItemProps} from './types'

import '../index.scss'

export function EventsTableItem(props: EventsTableListItemProps) {
  const {item} = props

  return (
    <div
      className='overflow-hidden rounded-md border border-[#222] bg-cover bg-center'
      style={{backgroundImage: `url('${getEventFlyer(item)}')`}}>
      <div className='grid items-center justify-between gap-3 bg-[#010101cc] p-5 text-left backdrop-blur-md md:flex md:gap-4'>
        <Image {...props} />
        <Content {...props} />
        <CopyBlock {...props} isHidden={!props.isHost} />
        <div className='mx-auto block w-[185px] justify-end md:mx-0 md:flex'>
          <Actions {...props} />
        </div>
      </div>
    </div>
  )
}
