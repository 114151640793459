import React from 'react'

import {PaymentStatus} from 'apis/Checkout'
import {OrderModel} from 'apis/Orders'
import Badge from 'components/Badge'
import {Card} from 'components/Card'
import moment from 'moment'

const PaymentBadge = ({status}: {status: PaymentStatus}) => {
  const color =
    status === 'paid'
      ? 'green'
      : status === 'failed'
        ? 'red'
        : status === 'refunded'
          ? 'grey'
          : status === 'scheduled'
            ? 'blue'
            : 'orange'
  const text = status.toUpperCase().replace('_', ' ')
  return <Badge className={`Badge--${color}`}>{text}</Badge>
}

const OrderCardPayments = ({order}: {order: OrderModel}) => {
  return (
    <Card title={'Payments'} animationIndex={1}>
      <table className='OrderPage-paymentPlanInstallments'>
        <tbody>
          {order.paymentPlan && order.paymentPlan[0] ? (
            <>
              {order.paymentPlan.map((p, index) => {
                return (
                  <tr key={index}>
                    <td>Installment #{index}</td>
                    <td>{moment(p.date).format('MMMM Do YYYY, h:mm A')}</td>
                    <td>${p.amount.toFixed(2)}</td>
                    <td>
                      <PaymentBadge status={p.status} />
                    </td>
                  </tr>
                )
              })}
            </>
          ) : (
            <tr>
              <td>Subtotal</td>
              <td></td>
              <td>${order.subtotal.toFixed(2)}</td>
              <td>
                <PaymentBadge status={PaymentStatus.PAID} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Card>
  )
}

export default OrderCardPayments
