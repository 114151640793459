import React, {useMemo, useState} from 'react'

import {useFetchEventPromoCodesAndUses} from 'apis/Events/useFetchEventPromoCodesAndUses'
import {useGetAllEventTickets} from 'apis/Events/useGetAllEventTickets'
import {Group} from 'apis/Groups'
import {CRUDTable} from 'components/TableUI/CRUDTable/CRUDTable'
import {ColumnConfig} from 'components/TableUI/CRUDTable/internals/types'
import {TableCell} from 'components/TableUI/TableCell/TableCell'
import {useEventMatchParams} from 'domains/Events/helpers'
import {useRankThreshold} from 'hooks/useRankThreshold'
import CSVImportModal from 'pages/CSVImportModal'

import {PromoCodeCreateForm} from './PromoCodeCreateForm'
import {PromoCodeEditForm} from './PromoCodeEditForm'

interface PromoCodeSectionProps {
  group: Group
}

export interface PromoCode {
  id: string
  code: string
  discount: {
    type: 'percentage' | 'flat'
    value: number
  }
  uses: number
  limit?: number
  // limitedToTicket is the id of the ticket that the promo code is limited to. If it is undefined, then the promo code is not limited to any ticket.
  limitedTo?: string
  enabled: boolean
}

export default function PromoCodesSection(props: PromoCodeSectionProps) {
  const {group} = props

  const {eventId} = useEventMatchParams()
  const {data: promoCodeData, dataUpdatedAt, refetch} = useFetchEventPromoCodesAndUses(eventId!)
  const {data} = useGetAllEventTickets({eventId: eventId!})
  const tickets = data?.tickets

  const promoCodes: PromoCode[] = useMemo(() => {
    return (promoCodeData ?? [])
      .map(promoCodeData => {
        return {
          id: promoCodeData._id,
          code: promoCodeData.code,
          discount: {
            // Need to assert the type here since we do a .reverse() which breaks the type inference for some reason.
            type: (promoCodeData.type === 'flat' ? 'flat' : 'percentage') as 'flat' | 'percentage',
            value: promoCodeData.value,
          },
          uses: promoCodeData.uses,
          limit: promoCodeData.limit ? promoCodeData.limit : undefined,
          limitedTo: promoCodeData.limitedToTicket,
          enabled: !promoCodeData.disabled,
        }
      })
      .reverse()
  }, [promoCodeData])

  const ticketMapping = useMemo(() => {
    return (tickets ?? []).map(ticket => {
      return {
        name: ticket.name,
        id: ticket.id,
      }
    })
  }, [tickets])

  const columnConfigs = useMemo<ColumnConfig<PromoCode>[]>(() => {
    return [
      {
        key: 'code',
        header: 'Code',
        render: code => <TableCell.Text text={code.toLowerCase()} bold />,
        sortConfig: {
          defaultSort: true,
        },
      },
      {
        key: 'discount',
        header: 'Discount',
        formatter: discount => {
          if (discount.type === 'flat') {
            return `$${discount.value}`
          } else {
            return `${discount.value}%`
          }
        },
      },
      {
        key: 'uses',
        header: 'Uses',
        formatter: uses => uses.toString(),
        sortConfig: {
          defaultSort: true,
        },
      },
      {
        key: 'limit',
        header: 'Limit',
        formatter: limit => (!limit ? 'No Limit' : limit.toString()),
        sortConfig: {
          defaultSort: true,
        },
      },
      {
        key: 'limitedTo',
        header: 'Limited To',
        render: limitedTo => {
          return (
            <TableCell.MultiOption
              allOptions={ticketMapping}
              selectedOptions={
                ticketMapping.find(ticket => ticket.id === limitedTo)
                  ? [ticketMapping.find(ticket => ticket.id === limitedTo)!]
                  : []
              }
              getText={option => option.name}
            />
          )
        },
        sortConfig: {
          defaultSort: false,
          sortFn: (a, b) => {
            if (!a || !b) return 0
            if (a.length === b.length) {
              return a.toLocaleString().localeCompare(b.toLocaleString())
            } else {
              return a.length - b.length
            }
          },
        },
      },
    ]
  }, [tickets, ticketMapping])

  const allowCSVImport = useRankThreshold(group, 'Merchant')
  const [isCSVModalOpen, setIsCSVModalOpen] = useState(false)

  return (
    <>
      <div className='eventSection'>
        <CRUDTable
          data={promoCodes}
          lastUpdated={dataUpdatedAt}
          refresh={refetch}
          columns={columnConfigs}
          itemsPerPage={5}
          resourceName='Promo Code'
          actions={
            allowCSVImport ? [{text: 'CSV Import', icon: 'settings', onClick: () => setIsCSVModalOpen(true)}] : []
          }
          searchableColumn='code'
          createForm={({onClose}) => {
            return (
              <PromoCodeCreateForm
                eventId={eventId!}
                promoCodes={promoCodes}
                ticketMapping={ticketMapping}
                onClose={onClose}
              />
            )
          }}
          editForm={({row: promoCode, onClose}) => {
            return (
              <PromoCodeEditForm
                eventId={eventId!}
                currentPromoCode={promoCode}
                promoCodes={promoCodes}
                ticketMapping={ticketMapping}
                onClose={onClose}
              />
            )
          }}
        />
      </div>
      {allowCSVImport && isCSVModalOpen && (
        <CSVImportModal
          isOpen={isCSVModalOpen}
          onClose={() => {
            setIsCSVModalOpen(false)
          }}
          uploadType={'promoCodes'}
        />
      )}
    </>
  )
}
