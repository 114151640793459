// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GroupDisplaySettings__AIfo4{display:flex;flex-direction:row;align-items:center}.GroupDisplaySettings__AIfo4 img{max-height:40px;aspect-ratio:1;border-radius:5px;border:2px solid;object-fit:cover}.GroupDisplaySettings__AIfo4 img:hover{cursor:pointer;filter:opacity(0.7)}.GroupDisplaySettings__AIfo4 .inputContainer__AmP2d{width:100%}", "",{"version":3,"sources":["webpack://./src/pages/EventManagementPages/Visuals/Upgrade-dev/components/page-sections/GroupDisplay/styles.module.scss"],"names":[],"mappings":"AAAA,6BACI,YAAA,CACA,kBAAA,CACA,kBAAA,CAEA,iCACI,eAAA,CACA,cAAA,CACA,iBAAA,CACA,gBAAA,CACA,gBAAA,CAEA,uCACI,cAAA,CACA,mBAAA,CAIR,oDACI,UAAA","sourcesContent":[".GroupDisplaySettings { \n    display: flex;\n    flex-direction: row;\n    align-items: center;\n\n    img {\n        max-height: 40px;\n        aspect-ratio: 1;\n        border-radius: 5px;\n        border: 2px solid;\n        object-fit: cover;\n        \n        &:hover {\n            cursor: pointer;\n            filter: opacity(0.7);\n        }\n    }\n\n    .inputContainer { \n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GroupDisplaySettings": "GroupDisplaySettings__AIfo4",
	"inputContainer": "inputContainer__AmP2d"
};
export default ___CSS_LOADER_EXPORT___;
