import React, {useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import {useDeleteEventTrackingLink} from 'apis/Events/useDeleteEventTrackingLink'
import {FetchEventResponse} from 'apis/Events/useFetchEvent'
import {TTrackingLinkWithHost, useGetTrackingLinksWithHost} from 'apis/Events/useGetTrackingLinksWithHost'
import ConfirmationModal from 'components/modals/Modal/ConfirmationModal'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import {CRUDTable} from 'components/TableUI/CRUDTable/CRUDTable'
import {ColumnConfig} from 'components/TableUI/CRUDTable/internals/types'
import {TableCell} from 'components/TableUI/TableCell/TableCell'
import {TableIcon} from 'components/TableUI/TableIcons/TableIcon'
import {useToast} from 'components/toasts/ToastProvider'
import {useCopyLink} from 'hooks/useCopyLink'

import {useResourcePageParams} from '../../PoshAppLayout'
import {getCurrencySymbol} from '../../Util/getCurrencySymbol'
import {generateTrackingLink} from '../Overview'
import {TrackingLinkTable} from './TrackingLinkTable'

interface TrackingLinksSectionProps {
  eventResponse?: FetchEventResponse
}

interface HostTrackingLinkTable extends TTrackingLinkWithHost {
  hostInfo: {
    hostName: string
    trackingLinkType: string
    value: string
  }
}

export default function TrackingLinksSection(props: TrackingLinksSectionProps) {
  const {eventResponse} = props
  const eventHasTables = eventResponse?.event?.tables?.length ? eventResponse?.event?.tables?.length > 0 : false
  const {eventId} = useResourcePageParams()
  const {showToast} = useToast()
  const {data: trackingLinkResponse, dataUpdatedAt, refetch} = useGetTrackingLinksWithHost({eventId: eventId!})

  const {mutate: deleteTrackingLink} = useDeleteEventTrackingLink({
    onError: error =>
      showToast({type: 'error', title: 'There was an error removing the tracking link', subtitle: error.message}),
    onSuccess: () => {
      refetch()
      showToast({type: 'success', title: 'Tracking link removed'})
    },
  })
  const navigate = useNavigate()

  const [trackingLinkToDelete, setTrackingLinkToDelete] = useState<
    | {
        id: string
        value: string
      }
    | undefined
  >(undefined)

  const isHost = useResourcePageParams().domain === 'host'
  const {copyLink, justCopied} = useCopyLink()

  if (justCopied) {
    showToast({type: 'success', title: 'Link copied to clipboard'})
  }

  const {trackingLinks} = trackingLinkResponse ?? {trackingLinks: []}

  const [hostTrackingLinks, nonHostTrackingLinks] = trackingLinks.reduce(
    ([hostTrackingLinks, nonHostTrackingLinks], trackingLink) => {
      if (trackingLink.hostId) hostTrackingLinks.push(trackingLink)
      else nonHostTrackingLinks.push(trackingLink)
      return [hostTrackingLinks, nonHostTrackingLinks]
    },
    [[], []] as [TTrackingLinkWithHost[], TTrackingLinkWithHost[]],
  )

  const handleHostBreakdownClick = (hostId: string) => {
    navigate(`${location.pathname}/host-breakdown/${hostId}` + location.search)
  }

  const hexUrl = eventResponse?.event.hexUrl ?? ''

  const currencySymbol = getCurrencySymbol(eventResponse?.group.currency)

  const formattedHostTrackingLinks = useMemo<HostTrackingLinkTable[]>(() => {
    return hostTrackingLinks.map(trackingLink => {
      return {
        ...trackingLink,
        hostInfo: {
          hostName: trackingLink.hostName ?? 'No Name',
          trackingLinkType: trackingLink.type,
          value: trackingLink.value,
        },
      }
    })
  }, [hostTrackingLinks])

  const columnConfigs = useMemo<ColumnConfig<HostTrackingLinkTable>[]>(() => {
    return [
      {
        key: 'hostInfo',
        header: 'Host Name',
        render: hostInfo => {
          const isHostSub = hostInfo.trackingLinkType === 'host-sub'
          const hostName = isHostSub
            ? `${hostInfo.hostName} (via their "${hostInfo.value}" tracking link`
            : hostInfo.hostName
          return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5}}>
              <TableCell.Text text={hostName} bold />

              {isHostSub && (
                <>
                  <ReactTooltip className='poshToolTip' effect='solid' place='top' id='posh-tracking-link' />
                  <div data-tip={'This link was created by the host.'} data-for='posh-tracking-link'>
                    <TableIcon name='info' />
                  </div>
                </>
              )}
            </div>
          )
        },
        sortConfig: {
          defaultSort: true,
        },
      },
      {
        key: 'clicks',
        header: 'Clicks',
        render: clicks => <TableCell.Text text={clicks.toString()} />,
        sortConfig: {
          defaultSort: true,
        },
      },
      {
        key: 'ticketsSold',
        header: 'Tickets Sold',
        render: ticketsSold => <TableCell.Text text={ticketsSold.toString()} />,
        sortConfig: {
          defaultSort: true,
        },
      },
      {
        key: 'conversionRate',
        header: 'Conversion Rate',
        render: conversionRate => <TableCell.Text text={conversionRate?.toFixed(1) + '%'} />,
        sortConfig: {
          defaultSort: true,
        },
      },
      {
        key: 'revenue',
        header: 'Revenue',
        render: revenue => <TableCell.Text text={`${currencySymbol}${revenue.toFixed(2)}`} />,
        sortConfig: {
          defaultSort: true,
        },
      },
    ]
  }, [hostTrackingLinks])

  if (eventHasTables) {
    columnConfigs.splice(2, 0, {
      key: 'tablesSold',
      header: 'Tables Sold',
      render: tablesSold => <TableCell.Text text={tablesSold.toString()} />,
      sortConfig: {
        defaultSort: true,
      },
    })
  }

  const closeTrackingLinkDeleteModal = () => {
    setTrackingLinkToDelete(undefined)
  }

  return (
    <>
      {hostTrackingLinks.length > 0 && (
        <RequirePermissions
          requiredPermissions={[requirePermissionAndScope('view_host_tracking_links', ['event', 'group'])]}>
          <div className='eventSection'>
            <CRUDTable
              data={formattedHostTrackingLinks}
              lastUpdated={dataUpdatedAt}
              refresh={refetch}
              columns={columnConfigs}
              itemsPerPage={10}
              resourceName='Host Sale'
              onClickRow={trackingLink => handleHostBreakdownClick(trackingLink.hostId ? trackingLink.hostId : '')}
              actionButtons={[
                {
                  icon: 'trash',
                  onClick: trackingLink => setTrackingLinkToDelete({id: trackingLink.id, value: trackingLink.value}),
                  displayIf: trackingLink =>
                    !isHost &&
                    trackingLink.revenue === 0 &&
                    trackingLink.ticketsSold === 0 &&
                    trackingLink.tablesSold === 0,
                },
                {
                  icon: 'copy-squares',
                  onClick: trackingLink =>
                    copyLink(
                      generateTrackingLink({
                        eventHex: eventResponse!.event.hexUrl,
                        eventUrl: eventResponse!.event.url,
                        trackingLink: trackingLink.value ? trackingLink.value.toLowerCase().replace(/\s/g, '-') : '',
                      }),
                    ),
                },
              ]}
            />
          </div>
          {!!trackingLinkToDelete && (
            <ConfirmationModal
              title={`Are you sure you want to delete the tracking link: ${trackingLinkToDelete.value}?`}
              confirmButtonText={'Yes, delete it'}
              isOpen={!!trackingLinkToDelete}
              onClose={closeTrackingLinkDeleteModal}
              bodyText='This action cannot be undone.'
              onClick={() => {
                deleteTrackingLink({eventId: eventId!, trackingLinkId: trackingLinkToDelete.id})
                closeTrackingLinkDeleteModal()
              }}
            />
          )}
        </RequirePermissions>
      )}
      <div className='eventSection'>
        <TrackingLinkTable
          // For owners and admins, we only want to show the tracking links that are not associated with a host
          // (since they can see all the host tracking links in the host sales section above)
          // However, for hosts, we can show them all the tracking links since they can only see their own
          // (the endpoint filters out the tracking links that are not associated with the host).
          trackingLinks={isHost ? trackingLinks : nonHostTrackingLinks}
          refresh={refetch}
          dataUpdatedAt={dataUpdatedAt}
          eventId={eventId!}
          hexUrl={hexUrl}
        />
      </div>
    </>
  )
}
