// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menuItemLink__IF\\+bH{display:flex;gap:12px;align-items:center;text-decoration:none;color:inherit;width:100%;background-color:rgba(0,0,0,0)}", "",{"version":3,"sources":["webpack://./src/pages/GroupPage/ManageMultiSessionEvent/components/MultiSessionSpecificEventAnalyticsSelect.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,kBAAA,CACA,oBAAA,CACA,aAAA,CACA,UAAA,CACA,8BAAA","sourcesContent":[".menuItemLink {\n  display: flex;\n  gap: 12px;\n  align-items: center;\n  text-decoration: none;\n  color: inherit;\n  width: 100%;\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuItemLink": "menuItemLink__IF+bH"
};
export default ___CSS_LOADER_EXPORT___;
