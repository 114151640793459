import React, {useMemo, useState} from 'react'

import {useQueryClient} from '@tanstack/react-query'
import useFetchEvent from 'apis/Events/useFetchEvent'
import {Order, useGetAllEventOrders} from 'apis/Events/useGetAllEventOrders'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {useMixpanel} from 'apis/MixPanelHandler'
import {OrderModel} from 'apis/Orders'
import AccountSearchModal, {AccountParams} from 'components/modals/Modal/AccountSearchModal'
import {CRUDTable} from 'components/TableUI/CRUDTable/CRUDTable'
import {ColumnConfig} from 'components/TableUI/CRUDTable/internals/types'
import {TableCell} from 'components/TableUI/TableCell/TableCell'
import {useNavigateToOrgPages} from 'hooks/navigation/useNavigateToOrgPages'
import {useRankThreshold} from 'hooks/useRankThreshold'
import CSVImportModal from 'pages/CSVImportModal'
import ComplimentaryTicketSelector from 'pages/EventManagementPages/ComplimentaryTicketSelector'
import {useResourcePageParams} from 'pages/PoshAppLayout'

const getTicketNameCounts = (tickets: OrderModel['tickets']) => {
  const ticketsWithCounts = tickets.reduce(
    (acc, ticket) => {
      const name = ticket.name
      if (!acc[name]) acc[name] = 0
      acc[name]++
      return acc
    },
    {} as Record<string, number>,
  )
  return ticketsWithCounts
}

export const getTicketNameWithCount = (ticketName: string, count: number) => {
  if (count === 1) return ticketName
  return `${ticketName} (x${count})`
}

const ComplimentaryTickets = () => {
  const queryClient = useQueryClient()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {eventId, groupId} = useResourcePageParams()
  const {data: group} = useFetchGroup(groupId!)
  const {data: event} = useFetchEvent(eventId!)
  const {data, dataUpdatedAt, refetch} = useGetAllEventOrders({eventId: eventId!})
  const {orders} = data ?? {}
  const complimentaryOrders = orders?.filter(order => order.isComplimentaryOrder && !order.canceled)
  const {navigateToOrderPage} = useNavigateToOrgPages()

  const eventTickets = useMemo(() => event?.event.tickets.map(t => t.name) ?? [], [event])

  const [isShowingSendTickets, setIsShowingSendTickets] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [userBeingAdded, setUserBeingAdded] = useState('')
  const [isCSVModalOpen, setIsCSVModalOpen] = useState(false)

  const handleShowThirdStep = async (accountId: string) => {
    setUserBeingAdded(accountId)
  }

  const onClickCSVUpload = () => {
    trackMixpanelEvent('CSV Upload- Curator Event Ticketing', {eventStatus: event?.event.status ?? ''})
    setIsCSVModalOpen(true)
  }

  const onClickSendTickets = () => {
    trackMixpanelEvent('Send Tickets- Curator Event Ticketing', {eventStatus: event?.event.status ?? ''})
    setIsShowingSendTickets(true)
  }

  const onClickSendToNonPoshUser = () => {
    trackMixpanelEvent('Send to Non-Posh User- Complimentary Tickets Modal', {
      eventStatus: event?.event.status ?? '',
    })
  }

  const onClickSendTicketsInModal = (quantity: number) => {
    trackMixpanelEvent('Send Tickets- Complimentary Tickets Modal', {
      eventStatus: event?.event.status ?? '',
      numberOfTickets: quantity,
    })
    refetch()
  }

  const onClickContinue = () => {
    trackMixpanelEvent('Continue Click- Complimentary Tickets Non-Posh User Modal', {
      eventStatus: event?.event.status ?? '',
    })
  }

  const onAddUser = (account: AccountParams) => handleShowThirdStep(account.accountId)

  const allowCSVImport = useRankThreshold(
    {
      gmv: group?.gmv ?? 0,
      verified: group?.verified ?? false,
    },
    'Merchant',
  )

  const columnConfigs = useMemo<ColumnConfig<Order>[]>(() => {
    return [
      {
        header: 'Name',
        key: 'accountName',
        render: accountName => <TableCell.Text text={accountName} bold />,
        sortConfig: {
          defaultSort: true,
        },
      },
      {
        header: 'Email',
        key: 'accountEmail',
        render: accountEmail => <TableCell.Text text={accountEmail ?? 'No Email'} />,
      },
      {
        header: 'Date Created',
        key: 'createdAt',
        render: createdAt => <TableCell.Text text={createdAt ? new Date(createdAt).toLocaleDateString() : 'No Date'} />,
        sortConfig: {
          defaultSort: true,
        },
      },
      {
        header: 'Ticket Type',
        key: 'tickets',
        render: tickets => {
          const ticketsWithCounts = getTicketNameCounts(tickets)
          const uniqueTicketNames = [...new Set(tickets.map(t => t.name))]
          return (
            <TableCell.MultiOption
              allOptions={eventTickets}
              selectedOptions={uniqueTicketNames}
              getText={name => getTicketNameWithCount(name, ticketsWithCounts[name])}
            />
          )
        },
      },
      {
        header: 'Qty',
        key: 'tickets',
        render: tickets => <TableCell.Text text={tickets.length.toString()} />,
        sortConfig: {
          defaultSort: true,
        },
      },
    ]
  }, [])

  return (
    <>
      <div className='eventSection'>
        <CRUDTable
          data={complimentaryOrders ?? []}
          lastUpdated={dataUpdatedAt}
          refresh={refetch}
          columns={columnConfigs}
          itemsPerPage={10}
          resourceName='Complimentary Order'
          actions={allowCSVImport ? [{text: 'CSV Import', icon: 'settings', onClick: onClickCSVUpload}] : []}
          searchableColumn='accountName'
          createButton={{
            title: '+ Send Complimentary Tickets',
            onClick: onClickSendTickets,
          }}
          onClickRow={order => navigateToOrderPage(order._id)}
        />
      </div>
      <AccountSearchModal
        isOpen={isShowingSendTickets}
        onClickPrimaryButton={onClickSendToNonPoshUser}
        onSecondaryButtonClick={onClickContinue}
        onClose={() => {
          if (userBeingAdded) {
            trackMixpanelEvent('X Click- Complimentary Tickets Non-Posh User Modal', {
              eventStatus: event?.event.status ?? '',
            })
          }
          setSuccessMessage('')
          setUserBeingAdded('')
          setIsShowingSendTickets(false)
          queryClient.invalidateQueries(['complimentaryOrders'])
        }}
        onAddPoshUser={onAddUser}
        onAddNonPoshUser={onAddUser}
        successMessage={successMessage}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        userBeingAdded={userBeingAdded}
        invitedFrom='complimentaryTickets'
        primaryTitle='Send Complimentary Tickets'
        primaryButtonText='+ Send to a Non-Posh User'
        secondaryTitle='Add Details for User'
        secondaryButtonText='Continue'
        noborder={true}
        ThirdComponent={
          <ComplimentaryTicketSelector
            userBeingAdded={userBeingAdded}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setUserBeingAdded={setUserBeingAdded}
            onSendTickets={onClickSendTicketsInModal}
          />
        }
      />
      {isCSVModalOpen && (
        <CSVImportModal
          isOpen={isCSVModalOpen}
          onClose={() => {
            setIsCSVModalOpen(false)
          }}
          uploadType={'complimentaryTickets'}
        />
      )}
    </>
  )
}

export default ComplimentaryTickets
