// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EventPlaylistPage__TyhuQ{width:100%;height:100%;display:flex;flex-direction:column;align-items:center;overflow-y:scroll}", "",{"version":3,"sources":["webpack://./src/pages/EventPlaylistPage/EventPlaylistPage.module.scss"],"names":[],"mappings":"AAAA,0BACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CAEA,iBAAA","sourcesContent":[".EventPlaylistPage {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EventPlaylistPage": "EventPlaylistPage__TyhuQ"
};
export default ___CSS_LOADER_EXPORT___;
