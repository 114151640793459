import React, {useEffect, useMemo, useState} from 'react'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import {useQueryClient} from '@tanstack/react-query'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {useMixpanel} from 'apis/MixPanelHandler'
import {TeamMemberResponse} from 'apis/Roles'
import {PermissionKey} from 'apis/Roles/Permission'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import {PoshImage} from 'components/PoshImage/PoshImage'
import moment from 'moment'

import useFetchTeam from '../../../apis/Roles/useFetchTeam'
import RequirePermissions, {requirePermissionAndScope} from '../../../components/RequirePermissions'
import {useGroupMatchParams} from '../../../domains/Groups/helpers'
import TeamManagementModal from '../../EventManagementPages/TeamManagementModal'
import {
  TeamManagementModalContextProvider,
  TeamManagementModalOptions,
} from '../../EventManagementPages/TeamManagementModal/TeamManagementModalContext'
import GroupPageSubNav from '../SubNav'
import AddTeamMemberWrapperComponent from './AddTeamMemberWrapperComponent'

import './styles.scss'

interface TeamMemberCardProps {
  teamMember: TeamMemberResponse
  onEdit?: (teamMember: TeamMemberResponse) => void
  onDelete?: (teamMember: TeamMemberResponse) => void
}

export function TeamMemberCard({teamMember, onEdit, onDelete}: TeamMemberCardProps) {
  const isOwner = teamMember.accountRole.roleKey === 'owner'
  const editPermissionsPermissionKey: PermissionKey = isOwner
    ? 'edit_owner_team_member_permissions'
    : 'edit_team_member_permissions'
  const removeTeamMemberPermissionKey: PermissionKey = isOwner ? 'remove_owner_team_member' : 'remove_team_member'
  const shouldDisplayActions = !isOwner
  return (
    <div
      key={teamMember.accountId}
      className='flex min-h-100 flex-col items-center gap-1 rounded-lg border-1 border-border p-5'>
      <PoshImage className='m-5 h-30 w-30 rounded-full object-cover' src={teamMember.avi} />
      <h5>
        {teamMember.firstName.charAt(0).toUpperCase() +
          teamMember.firstName.slice(1) +
          ' ' +
          teamMember.lastName.charAt(0).toUpperCase() +
          teamMember.lastName.slice(1)}
      </h5>
      <p className='text-gray-400 capitalize'>{teamMember.accountRole.roleKey}</p>
      <p className='mb-2 text-[11px] text-gray-400'>
        {teamMember.lastLogin ? 'Last Login: ' + moment(teamMember.lastLogin).fromNow() : 'Hasnt logged in'}
      </p>
      {shouldDisplayActions && (
        <div className='TeamCard-Actions'>
          <RequirePermissions
            requiredPermissions={[requirePermissionAndScope(editPermissionsPermissionKey, ['group'])]}>
            <a onClick={() => onEdit && onEdit(teamMember)}>Edit Permissions</a>
          </RequirePermissions>
          <div>
            <a href={`mailto:${teamMember.email}`}>Contact</a>
            <RequirePermissions
              requiredPermissions={[requirePermissionAndScope(removeTeamMemberPermissionKey, ['group'])]}>
              <a onClick={() => onDelete && onDelete(teamMember)}>Remove</a>
            </RequirePermissions>
          </div>
        </div>
      )}
    </div>
  )
}

export default function Team() {
  const {trackEvent} = useMixpanel()
  const {groupId} = useGroupMatchParams()
  const {data: group} = useFetchGroup(groupId)

  const [teamMember, setTeamMember] = useState<TeamMemberResponse | null>(null)

  const [isAddingTeamMember, setIsAddingTeamMember] = useState(false)
  const [isEditingTeamMember, setIsEditingTeamMember] = useState(false)
  const [isDeletingTeamMember, setIsDeletingTeamMember] = useState(false)
  const [displayedTeamMembers, setDisplayedTeamMembers] = useState<TeamMemberResponse[]>([])
  const queryClient = useQueryClient()
  const {data: teamResponse, isFetching: fetchingTeamMembers} = useFetchTeam(groupId ?? '', 'group')
  const entityTeamMembers = useMemo(() => teamResponse ?? [], [teamResponse])

  const onDeleteTeamMember = (teamMember: TeamMemberResponse) => {
    setTeamMember(teamMember)
    setIsDeletingTeamMember(true)
  }

  const onEditTeamMember = (teamMember: TeamMemberResponse) => {
    setTeamMember(teamMember)
    setIsEditingTeamMember(true)
  }

  useEffect(() => {
    setDisplayedTeamMembers(entityTeamMembers)
  }, [entityTeamMembers])

  const teamManagementModalOptions: TeamManagementModalOptions | undefined = useMemo(() => {
    if (isEditingTeamMember && teamMember) {
      return {
        teamMemberToEdit: teamMember,
        fixedPanel: 'edit_permissions',
      }
    } else if (isDeletingTeamMember && teamMember) {
      return {
        teamMemberToEdit: teamMember,
        fixedPanel: 'delete_team_member',
      }
    }
  }, [isDeletingTeamMember, isEditingTeamMember, teamMember])

  const teamManagementModalIsOpen = useMemo(() => {
    return isDeletingTeamMember || isAddingTeamMember || isEditingTeamMember
  }, [isDeletingTeamMember, isEditingTeamMember])

  const onTeamManagementModalClose = () => {
    setIsAddingTeamMember(false)
    setIsEditingTeamMember(false)
    setIsDeletingTeamMember(false)
    queryClient.invalidateQueries(['team'])
    setTeamMember(null)
  }

  const onClickAddTeamMember = () => {
    trackEvent('Add Team Member- Group Team Page')
    setIsAddingTeamMember(true)
  }

  return (
    <>
      <PoshHelmet title={`Team - ${group?.name}`} />
      <div className='GroupPage-content Team'>
        <GroupPageSubNav group={group} hierarchy={['Team']} />

        <div className='GroupPage-titleBar'>
          <h3>Team Members</h3>
          <RequirePermissions requiredPermissions={[requirePermissionAndScope('create_team_member', ['group'])]}>
            <Button onClick={onClickAddTeamMember}>+ Add Team Member</Button>
          </RequirePermissions>
        </div>

        <p className='mt-2 mb-2'>Organization team members are added to all of your events by default.</p>

        {fetchingTeamMembers ? (
          <div className='Team-loader'>
            <SpinLoader height={150} />
          </div>
        ) : (
          <div className='Team-cards'>
            {displayedTeamMembers.map(member => (
              <TeamMemberCard
                key={member.accountId}
                teamMember={member}
                onEdit={onEditTeamMember}
                onDelete={onDeleteTeamMember}
              />
            ))}
          </div>
        )}
      </div>
      <TeamManagementModalContextProvider
        entityId={groupId ?? ''}
        scope={'group'}
        options={teamManagementModalOptions ?? {initialPanel: 'choose_role'}}>
        <AddTeamMemberWrapperComponent
          onTeamManagementModalClose={onTeamManagementModalClose}
          isAddingTeamMember={isAddingTeamMember}
        />
        <TeamManagementModal isOpen={teamManagementModalIsOpen} onClose={onTeamManagementModalClose} />
      </TeamManagementModalContextProvider>
    </>
  )
}
