import {TEventStatus} from '@posh/models'
import {useMixpanel} from 'apis/MixPanelHandler'

export enum EventRowAction {
  EDIT,
  VIEW,
  DUPLICATE,
  DELETE,
}

type EventsTableEvent = {
  _id: string
  name: string
  startUtc: string | Date
  timezone: string
  status: TEventStatus
  hexUrl: string
  url: string
  flyer: string
  attending: string[]
}

export type EventsTableListItemProps = {
  groupId: string
  item: EventsTableEvent
  status: TEventStatus
  isHost: boolean
  trackingLink: string
  isLoading: boolean
  updateEventStatus: (status: TEventStatus, eventId: string) => void
  onRowAction?: (action: EventRowAction, eventId: string) => void
  trackMixpanelEvent: ReturnType<typeof useMixpanel>['trackEvent']
  search?: string
  showActions?: boolean
}
