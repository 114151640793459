import React, {PropsWithChildren, useEffect, useState} from 'react'
import {isPossiblePhoneNumber} from 'react-phone-number-input'
import {useNavigate} from 'react-router-dom'

import {useFetchPortalLink} from 'apis/Accounts/useFetchPortalLink'
import {useLoginWithEmail} from 'apis/Auth/useLoginWithEmail'
import {useLoginWithPhone} from 'apis/Auth/useLoginWithPhone'
import {useMixpanel} from 'apis/MixPanelHandler'
import CloudflareTurnstile, {TurnstileRef} from 'components/CloudflareTurnstile'
import Button from 'components/form/Button'
import PhoneNumberInput from 'components/form/PhoneNumberInput'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import usePermissionsContext from 'domains/Teams/PermissionsContext'
import {handleAuthNavigation} from 'helpers/handleAuthNavigation'
import {useDimensions} from 'hooks/useDimensions'
import {isUndefined} from 'lodash'

import useSessionContext from '../../domains/Auth/SessionContext'
import {LoginBackground} from './Background'
import {LoginDivLinks, LoginFooter, LoginLogo} from './LoginLinks'
import {LoginMethodPicker} from './LoginMethodPicker'
import {VerificationCodeInput} from './VerificationCodeInput'

import './styles.scss'

export type LoginFlow = 'email' | 'phone'

const LoginButton = ({
  onClick,
  loading,
  children,
  disabled,
}: PropsWithChildren<{onClick: () => void; loading: boolean; disabled: boolean}>) => {
  return (
    <Button
      type='submit'
      onClick={onClick}
      isLoading={loading}
      disabled={loading || disabled}
      style={{fontWeight: 700, fontSize: 18}}>
      {loading ? null : children}
    </Button>
  )
}

const LoginPage = () => {
  const {isMobile} = useDimensions()
  const urlParams = new URLSearchParams(window.location.search)
  const encodedRedirectUrl = urlParams.get('r')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState('')
  const [loginFlow, setLoginFlow] = useState<LoginFlow>(isMobile ? 'phone' : 'email')
  const [showCodePage, setShowCodePage] = useState<boolean>(false)
  const [phone, setPhone] = useState<string>('')
  const {setAccountRole} = usePermissionsContext()
  const {userId, accountRoles} = useSessionContext()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(window.location.search)
  const isPortal = queryParams.get('isportal')
  const {data: url, isError: linkError} = useFetchPortalLink(userId, isPortal == 'true')
  const {mutateAsync: login, isLoading: isLoggingIn} = useLoginWithEmail({
    onError: error => {
      const errorString = error.message ?? 'Something went wrong on our end! Please try again.'
      setErrorMessage(errorString)
      trackMixpanelEvent('Login Error-  Main Login', {error: error.message})
      setCaptchaToken(undefined)
      captchaRef.current?.reset()
    },
  })
  const {mutateAsync: loginWithPhone, isLoading: isLoggingInWithPhone, error: loginError} = useLoginWithPhone()
  const [errorMessage, setErrorMessage] = useState('')
  const [hasExistingAccount, setHasAccount] = useState<boolean | undefined>(undefined)
  const [needsToConfirmAccount, setNeedsToConfirmAccount] = useState<boolean | undefined>(undefined)
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const [captchaToken, setCaptchaToken] = useState<string | undefined>()
  const captchaRef = React.useRef<TurnstileRef>(null)

  useEffect(() => {
    if (!isUndefined(hasExistingAccount)) setShowCodePage(true)
  }, [hasExistingAccount])

  const onLoginWithPhone = async () => {
    setErrorMessage('')
    if (!isPossiblePhoneNumber(phone)) {
      setErrorMessage('Please enter valid phone number.')
      return
    }
    if (!captchaToken) {
      setErrorMessage('Please complete the captcha')
      return
    }
    if (captchaRef.current?.isExpired()) {
      setErrorMessage('Captcha has expired. Please try again')
      resetCaptcha()
      return
    }
    try {
      const {hasAccount, needsToConfirmAccount} = await loginWithPhone({phone, cfTurnstileResponse: captchaToken})
      setHasAccount(hasAccount)
      setNeedsToConfirmAccount(needsToConfirmAccount)
      resetCaptcha()
    } catch (error: any) {
      setErrorMessage(error.message ?? (error.response.data.message as string))
    }
  }

  useEffect(() => {
    if (!userId || !accountRoles) return
    else if (isPortal !== 'true' || linkError) {
      const navLink = handleAuthNavigation({
        encodedRedirectUrl,
        accountRoles,
        setAccountRole,
      })
      navigate(navLink)
    } else if (url) {
      window.open(url)
      navigate('/dashboard')
    }
  }, [history, userId, url, linkError, accountRoles])

  const formIsValid = loginFlow === 'email' ? email && password && captchaToken : phone && captchaToken

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter' && formIsValid) {
      event.preventDefault()
      event.stopPropagation()
      submitMethod()
    }
  }

  const onSubmit = async () => {
    setErrorMessage('')
    trackMixpanelEvent('Login Button Clicked-  Main Login')
    if (!captchaToken) {
      setErrorMessage('Please complete the captcha')
      return
    }
    if (captchaRef.current?.isExpired()) {
      setErrorMessage('Captcha has expired. Please try again')
      resetCaptcha()
      return
    }
    login({email, password, cfTurnstileResponse: captchaToken})
  }

  const switchLoginFlow = (flow: LoginFlow) => {
    if (flow === 'phone') trackMixpanelEvent('Phone Toggle-  Main login')
    if (flow === 'email') trackMixpanelEvent('Email Toggle-  Main login')
    setLoginFlow(flow)
    setErrorMessage('')
  }

  const handleCaptcha = (token: string) => {
    setCaptchaToken(token)
  }

  const resetCaptcha = () => {
    // Reset the captcha token in our current state since it's no longer valid
    setCaptchaToken(undefined)
    // Tell the captcha widget to reset its internal state and start over a new captcha challenge
    // This doesn't trigger the reset callback, which is why we have to manually clear the state above
    captchaRef.current?.reset()
  }

  const submitMethod = loginFlow === 'email' ? onSubmit : onLoginWithPhone
  const isLoading = loginFlow === 'email' ? isLoggingIn : isLoggingInWithPhone
  const loginButtonText = loginFlow === 'email' ? 'Login' : 'Continue'

  return (
    <>
      <PoshHelmet title={'Posh - Login'} />
      <div className='LoginPage'>
        <LoginBackground />
        <div className='LoginPage-form'>
          <LoginLogo className='m-auto' />
          {errorMessage && <p className='LoginPage-form--error m-0'>{errorMessage}</p>}
          {!showCodePage && isUndefined(hasExistingAccount) ? (
            <>
              <LoginMethodPicker loginFlow={loginFlow} switchLoginFlow={switchLoginFlow} />
              {loginFlow === 'email' ? (
                <>
                  <input
                    className='poshInput square'
                    type='text'
                    name='email'
                    placeholder='Email'
                    value={email}
                    onKeyDown={e => onKeyDown(e)}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <input
                    className='poshInput square'
                    type='password'
                    name='password'
                    placeholder='Password'
                    value={password}
                    onKeyDown={e => onKeyDown(e)}
                    onChange={e => setPassword(e.target.value)}
                  />
                </>
              ) : (
                <PhoneNumberInput
                  className='OnboardPage-phoneNumber LoginPage-form-input'
                  placeholder='Phone'
                  defaultCountry='US'
                  value={phone}
                  onChange={e => setPhone(e ?? '')}
                  onKeyDown={e => onKeyDown(e)}
                />
              )}
              <CloudflareTurnstile
                ref={captchaRef}
                successCallback={handleCaptcha}
                resetCaptchaCallback={() => setCaptchaToken(undefined)}
              />
              <LoginButton onClick={submitMethod} loading={isLoading} disabled={!formIsValid}>
                {loginButtonText}
              </LoginButton>
              <LoginDivLinks />
            </>
          ) : (
            <VerificationCodeInput
              phone={phone}
              goBack={() => {
                setShowCodePage(false)
                setHasAccount(undefined)
              }}
              isMobile={isMobile}
              setErrorMessage={setErrorMessage}
              hasExistingAccount={hasExistingAccount!}
              needsToConfirmAccount={needsToConfirmAccount!}
            />
          )}
        </div>
      </div>
      <LoginFooter />
    </>
  )
}

export default LoginPage
