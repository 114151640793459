import React, {useState} from 'react'

import {CurrencyCode, ETicketsAvailableMessage} from '@posh/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import {SafeMarkdownDisplay} from 'components/Text/SafeMarkdownDisplay'
import {useTrackRudderStackEvent} from 'hooks/useTrackRudderstackEvent'

import {EventPublic, TicketEventPage} from '../../apis/Events/types'
import {FilledPlus} from '../../components/assets/Icons'
import TicketScarcityExperiment from '../../components/Optimizely/TicketScarcityExperiment'
import useCartContext from '../../domains/Cart/CartContext'
import {getCurrencySymbol} from '../Util/getCurrencySymbol'
import getTicketDisplayDetails from './getTicketDisplayDetails.helper'
import TicketCountInput from './TicketCountInput'

interface TicketActionRowProps {
  ticket: TicketEventPage
  index: number
  trackingLink?: string | null
  currency?: CurrencyCode
  eventId?: string
  ticketsAvailableMessage?: ETicketsAvailableMessage
  lightmode: EventPublic['lightmode']
  accentColor: EventPublic['accentColor']
  showFeesInPrice?: EventPublic['showFeesInPrice']
  longFormAddToCartButton?: EventPublic['longFormAddToCartButton']

  // Override the default action buttons with a custom button
  customActionButton?: React.ReactNode
}

/**
 * TicketActionRow is a component that displays a ticket and its associated actions
 */
const TicketActionRow = (props: TicketActionRowProps) => {
  const {ticket, index, currency, lightmode, accentColor, showFeesInPrice, eventId} = props
  const colorModeModifier = lightmode ? 'is-lightMode' : 'is-darkMode'

  const {isTicketPack, isSoldOut, ticketDisplayPrice} = getTicketDisplayDetails({
    ...ticket,
    showFeesInPrice: showFeesInPrice ?? false,
  })
  const [isExpanded, setIsExpanded] = useState(false)
  const shouldShowViewMore = ticket.description && ticket.description.length > 185
  const expandText = isExpanded ? 'View Less' : 'View More'

  const descriptionText = (() => {
    if (ticket.description && ticket.description.length > 185) {
      return isExpanded ? ticket.description : ticket.description.substring(0, 185) + '...'
    } else if (ticket.description) {
      return ticket.description
    }
  })()

  return (
    <>
      <div
        key={index}
        className={(index % 2 == 0 ? 'EventPage-ticketRow' : 'EventPage-ticketRowLight') + ' ' + colorModeModifier}>
        <div className='EventPage-ticketRowWrapper'>
          <div className='EventPage-tierText'>
            <h5
              className={(ticket.disabled ? 'disabledText' : '') + ' ' + 'EventPage-tierText-title'}
              style={{
                color: colorModeModifier === 'is-lightMode' ? 'black' : `${accentColor}`,
                textDecoration: `${isSoldOut ? 'line-through' : 'none'}`,
              }}>
              {ticket.name}
            </h5>
          </div>
          {isTicketPack && (
            <div
              style={{backgroundColor: accentColor}}
              className={'EventPage-ticketRow-approvalRequried ' + colorModeModifier}>
              {ticket.purchaseMin}-PACK
            </div>
          )}
          {!isSoldOut && !ticket.priceHidden && !ticket.disabled && (
            <p className={'EventPage-costText ' + colorModeModifier}>
              {ticketDisplayPrice === 0 ? (
                <i>FREE</i>
              ) : (
                `${getCurrencySymbol(currency)}${
                  ticketDisplayPrice % 1 === 0 ? ticketDisplayPrice : ticketDisplayPrice.toFixed(2)
                }`
              )}
              {showFeesInPrice && <span className='EventPage-hiddenFees'>No hidden fees!</span>}
            </p>
          )}
          {ticket.approvalRequired && (
            <div
              style={{backgroundColor: accentColor}}
              className={'EventPage-ticketRow-approvalRequried ' + colorModeModifier}>
              APPROVAL REQUIRED
            </div>
          )}
          {descriptionText && (
            <>
              <div className={'EventPage-descriptionSection ' + colorModeModifier}>
                <SafeMarkdownDisplay source={descriptionText} />
              </div>
              {shouldShowViewMore && (
                <a
                  className={
                    (index % 2 == 0 ? 'EventPage-viewMore' : 'EventPage-viewMoreLight') + ' ' + colorModeModifier
                  }
                  onClick={() => setIsExpanded(isExpanded ? false : true)}>
                  {expandText}
                </a>
              )}
            </>
          )}
        </div>
        {isSoldOut ? (
          <p className='font-bold whitespace-nowrap' style={{color: `${accentColor}`}}>
            Sold Out
          </p>
        ) : ticket.disabled ? (
          <p className='disabledText'>Coming Soon</p>
        ) : (
          <TicketActionButtons {...props} />
        )}
      </div>
    </>
  )
}

interface TicketActionButtonsProps {
  accentColor: string
  onAddTicket: () => void
  lightmode: boolean
}

const AddButton = (props: TicketActionButtonsProps) => {
  const {accentColor, onAddTicket, lightmode} = props
  return (
    <button
      className='EventPage-button firstTimeTicketAddBtn'
      onClick={onAddTicket}
      style={{background: 'none', border: 'none', outline: 'none', color: lightmode ? 'white' : 'black'}}>
      <FilledPlus className='EventPage-filledIcon' style={{fill: accentColor}} />
    </button>
  )
}

const LongAddButton = (props: TicketActionButtonsProps) => {
  const {accentColor, onAddTicket, lightmode} = props
  return (
    <button
      className='EventPage-buttonATC'
      onClick={onAddTicket}
      style={{
        background: accentColor,
        color: lightmode ? 'white' : 'black',
      }}>
      + Add To Cart
    </button>
  )
}

interface AddToCartButtonProps extends TicketActionButtonsProps {
  longFormAddToCartButton?: EventPublic['longFormAddToCartButton']
  lightmode: boolean
}

const AddToCartButton = (props: AddToCartButtonProps) => {
  const {longFormAddToCartButton, ...rest} = props
  return <>{longFormAddToCartButton ? <LongAddButton {...rest} /> : <AddButton {...rest} />}</>
}

const TicketActionButtons = (props: TicketActionRowProps) => {
  const {
    ticket,
    trackingLink,
    ticketsAvailableMessage,
    lightmode,
    accentColor,
    longFormAddToCartButton,
    customActionButton,
  } = props
  const colorModeModifier = lightmode ? 'is-lightMode' : 'is-darkMode'
  const {ticketsCount, setTrackingLink, addTicket} = useCartContext()
  const limit = ticket.quantityAvailable
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const count = ticketsCount[ticket.id] ? ticketsCount[ticket.id].quantity : 0
  const {trackAddToCart} = useTrackRudderStackEvent()

  const onAddTicket = () => {
    trackMixpanelEvent('Add Ticket Button-  Event Page')
    trackAddToCart(ticket.id, {params: {count}})
    if (trackingLink && trackingLink !== '') setTrackingLink(trackingLink)
    if (count < limit) addTicket(ticket)
  }

  return (
    <>
      {customActionButton ? (
        customActionButton
      ) : ticketsCount[ticket.id]?.quantity > 0 ? (
        <TicketCountInput ticket={ticket} iconColor={accentColor} colorModeModifier={colorModeModifier} />
      ) : (
        <div className='EventPage-limitedTicketsContainer'>
          <TicketScarcityExperiment
            ticketsAvailableMessage={ticketsAvailableMessage}
            isLightMode={lightmode}
            accentColor={accentColor}
          />
          <AddToCartButton
            accentColor={accentColor}
            onAddTicket={onAddTicket}
            longFormAddToCartButton={longFormAddToCartButton}
            lightmode={lightmode}
          />
        </div>
      )}
    </>
  )
}

export default TicketActionRow
