import React from 'react'

import classnames from 'classnames'
import {PoshLogo} from 'components/PoshLogo'

import SocialLinks from '../SocialLinks'
import {NewsletterBlock} from './NewsletterBlock'

import './styles.scss'

const CAREERS_URL = 'https://jobs.ashbyhq.com/posh'

const Footer = ({desktopOnly}: {desktopOnly?: boolean}) => {
  return (
    <div
      className={classnames('LandingPage-section LandingPage-section-footer', {
        desktopOnly,
      })}>
      <div className='LandingPage-section-inner lp23footer center'>
        <div className='lp23footer-top'>
          <div className='lp23footer-top-left'>
            <PoshLogo.Link />
            <span>Providing the world with tools to create incredible experiences</span>
            <NewsletterBlock />
          </div>
          <div className='lp23footer-top-linkLists'>
            {/* <div>
              <h5>Solutions</h5>
              <a href='/'>POSH</a>
              <a>Kickback</a>
              <a>Community</a>
            </div> */}
            <div>
              <h5>POSH</h5>
              {/* <a>About</a>
              <a>Team</a> */}
              <a href={CAREERS_URL}>Careers</a>
              <a href='https://hosted.posh.vip'>Magazine</a>
              {/* <a>Press</a> */}
              <a href='https://www.youtube.com/@poshvip'>POSH TV</a>
            </div>
            <div>
              <h5>Resources</h5>
              <a href='mailto:support@posh.vip'>Support</a>
              <a href='https://calendly.com/d/5k4-6w5-ft2' target='_blank' rel='noreferrer'>
                Request A Demo
              </a>
              <a href='/faq'>FAQ</a>
              <a href='https://docs.posh.vip/' target='_blank' rel='noreferrer'>
                Tutorials
              </a>
              {/* <a>Sign up for Newsletter</a> */}
            </div>
          </div>
        </div>
        <hr />
        <div className='lp23footer-bottom'>
          <div className='lp23footer-bottom-left'>
            <SocialLinks />
          </div>
          <div className='lp23footer-bottom-right'>
            <span>&copy; {new Date().getFullYear()} Posh Group Inc.</span>
            <a href='/privacy'>Privacy Policy</a>
            <a href='/tos'>Terms of Service</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
