import React from 'react'
import {useNavigate} from 'react-router-dom'

import {OrderModel} from 'apis/Orders'
import {Card} from 'components/Card'
import {PoshImage} from 'components/PoshImage/PoshImage'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import {useDimensions} from 'hooks/useDimensions'
import moment from 'moment'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import OrderDescriptors, {UntaggedOrderModel} from '../../OrderDescriptors'

const OrderCardOrders = ({orders}: {orders?: OrderModel[]}) => {
  const {domain, groupId} = useResourcePageParams()
  const navigate = useNavigate()
  const {isMobile} = useDimensions()
  return (
    <Card title={'Orders'} animationIndex={0}>
      <table className='OrderPage-ordersTable GroupPage-table'>
        <tr>
          <th></th>
          <th>Order</th>
          <th>Event</th>
          <th></th>
          <th>Date</th>
        </tr>
        {orders?.map(order => {
          const orderDescriptorsProps: UntaggedOrderModel = {
            ...order,
            type: 'untagged',
          }
          return (
            <tr
              className='OrderPage-ordersTable-ftd'
              key={order.number}
              onClick={() => navigate(`/${domain}/groups/${groupId}/orders/${order._id}`)}>
              {!isMobile && (
                <td>
                  <div className='OrderPage-ordersTable-iconHolder'>
                    <PoshImage src='https://images.posh.vip/b2/shopping-bag.svg' />
                  </div>
                </td>
              )}
              <td className='OrderPage-ordersTable-secondtd'>
                <span className='OrderPage-ordersTable-name'>
                  Order #{order.number}
                  <span>
                    <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_attendee', ['group'])]}>
                      <br />${order.subtotal.toFixed(2)}
                    </RequirePermissions>
                  </span>
                </span>
              </td>
              <td>{order.eventName}</td>
              <td>
                <OrderDescriptors order={orderDescriptorsProps} />
              </td>
              <td className='OrderPage-ordersTable-date'>{moment(order.createdAt).format('MMM DD, YYYY h:mm A')}</td>
            </tr>
          )
        })}
      </table>
    </Card>
  )
}

export default OrderCardOrders
