import React from 'react'

/** This is the ONLY place this component should be used -- it's vulnerable to XSS */
import MDEditor from '@uiw/react-md-editor'
import rehypeSanitize from 'rehype-sanitize'

interface SafeMarkdownDisplayProps {
  source?: string
  className?: string
  style?: React.CSSProperties
  DANGEROUS_useRawMarkdown?: boolean
}

/**
 * Safely display markdown content, sanitizing it to prevent XSS attacks.
 * @param props.source The markdown content to display.
 * @param props.className The class name to apply to the rendered markdown.
 * @param props.style The style to apply to the rendered markdown.
 * @param props.DANGEROUS_useRawMarkdown If true, the markdown will not be sanitized. This should only be used if you trust the source of the markdown.
 */
export function SafeMarkdownDisplay({DANGEROUS_useRawMarkdown = false, ...props}: SafeMarkdownDisplayProps) {
  const rehypePlugins = DANGEROUS_useRawMarkdown ? [] : [rehypeSanitize]
  return <MDEditor.Markdown {...props} rehypePlugins={rehypePlugins} />
}
