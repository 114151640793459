export type settingsTab =
  | 'home'
  | 'info'
  | 'policies'
  | 'thirdparty'
  | 'paymentplans'
  | 'marketing'
  | 'checkout'
  | 'branding'
  | 'embed'
  | 'new_custom_checkout_fields'

export type SettingsTabMap = {
  value: settingsTab
  title: string
  icon: string
  description: string
  showForRSVPEvents?: boolean
  rsvpVariant?: {
    title: string
    description: string
  }
}

interface GetEventSettingTabParams {
  isPaymentPlanEnabled: boolean
  isVerified?: boolean
  hasThirdPartySite: boolean
}

export const getEventSettingsTabMap = (params: GetEventSettingTabParams) => {
  const {hasThirdPartySite, isVerified, isPaymentPlanEnabled} = params
  const settingsTabMap: SettingsTabMap[] = [
    {
      value: 'checkout',
      title: 'Checkout',
      icon: 'shopping',
      description: 'Checkout Flow, Custom Fees, Buttons',
      showForRSVPEvents: false,
    },
    {
      value: 'marketing',
      title: 'Marketing',
      icon: 'megaphone',
      description: 'Facebook Pixel, Tiktok Pixel, Third-party Marketplaces',
      showForRSVPEvents: true,
    },
    {
      value: 'policies',
      title: 'Policies',
      icon: 'security',
      description: 'Terms of Service & Refund Policy',
    },
    {
      value: 'embed',
      title: 'Embed',
      icon: 'window',
      description: 'Embed checkout for this event on another website',
      showForRSVPEvents: true,
      rsvpVariant: {
        title: 'Embed',
        description: 'Embed this RSVP page on another website',
      },
    },
    {
      value: 'new_custom_checkout_fields',
      title: 'Custom Checkout Fields',
      icon: 'shopping',
      description: 'Add customized fields to collect additional data from attendees',
      showForRSVPEvents: true,
      rsvpVariant: {
        title: 'Custom Form Fields',
        description: 'Add customized fields to collect additional data from attendees',
      },
    },
  ]

  if (isVerified || isPaymentPlanEnabled) {
    settingsTabMap.push({
      value: 'paymentplans',
      title: 'Payment Plans',
      icon: 'paymentplans',
      description: 'View and configure payment plan behavior',
    })
  }
  if (hasThirdPartySite) {
    settingsTabMap.push({
      value: 'thirdparty',
      title: 'Custom Site',
      icon: 'www',
      description: 'Configure how this event is displayed on your custom site',
    })
  }
  return settingsTabMap
}
