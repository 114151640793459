import React, {useState} from 'react'

import {UpdatePasswordInput, UpdatePasswordOutput, useUpdatePassword} from 'apis/Accounts/useUpdateAccount'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {useToast} from 'components/toasts/ToastProvider'

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState<string | null>(null)
  const [newPassword, setNewPassword] = useState<string | null>(null)
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string | null>(null)
  const {showToast} = useToast()
  const [confirmationMessage, setConfirmationMessage] = useState<string | null>(null)

  const {mutateAsync: updateAccountPassword} = useUpdatePassword()

  const changePassword = async () => {
    setConfirmationMessage('')
    if (!currentPassword || !newPassword || !newPasswordConfirmation) {
      showToast({type: 'error', title: 'Please enter all password fields'})
      return
    } else if (newPassword !== newPasswordConfirmation) {
      showToast({type: 'error', title: 'Passwords do not match'})
      return
    }
    const updates: UpdatePasswordInput = {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmNewPassword: newPasswordConfirmation,
    }

    return updateAccountPassword(updates)
      .then((res: UpdatePasswordOutput) => {
        if (res?.success) {
          setConfirmationMessage('Passwords successfully changed!')
          setCurrentPassword('')
          setNewPasswordConfirmation('')
          setNewPassword('')
        } else {
          showToast({type: 'error', title: 'Incorrect current password provided.'})
        }
      })
      .catch((err: any) => {
        showToast({type: 'error', title: err.message ?? 'An error occurred. Please try again.'})
      })
  }

  const resetValues = () => {
    setConfirmationMessage('')
    setCurrentPassword('')
    setNewPasswordConfirmation('')
    setNewPassword('')
  }

  const isSaveDisabled = !currentPassword || !newPassword || !newPasswordConfirmation

  return (
    <div className='AccountSettings-wrapper'>
      {confirmationMessage && (
        <p style={{color: 'green', fontWeight: 'lighter', paddingLeft: '20px'}}>{confirmationMessage}</p>
      )}

      <label>Current Password</label>
      <Input
        type='password'
        value={currentPassword ?? ''}
        onChange={e => {
          setCurrentPassword(e.target.value)
        }}
      />

      <label>New Password</label>
      <Input
        type='password'
        value={newPassword ?? ''}
        onChange={e => {
          setNewPassword(e.target.value)
        }}
      />

      <label>Confirm New Password</label>
      <Input
        type='password'
        value={newPasswordConfirmation ?? ''}
        onChange={e => {
          setNewPasswordConfirmation(e.target.value)
        }}
      />

      <div className='buttonWrapper'>
        {!isSaveDisabled && (
          <Button className='light dark' onClick={() => resetValues()}>
            Cancel
          </Button>
        )}
        <Button className='gold light' disabled={isSaveDisabled} onClick={() => changePassword()}>
          Save Changes
        </Button>
      </div>
    </div>
  )
}

export default ChangePassword
