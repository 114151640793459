import React from 'react'

import {useDeviceType} from '../../hooks/useDeviceType'
import {PoshImage} from '../PoshImage/PoshImage'

import styles from './styles.module.scss'
interface AppStoreButtonProps {
  url?: string
}

export const AppStoreButtons = ({url}: AppStoreButtonProps) => {
  return (
    <div className={styles.appStoreButtons}>
      <AppleAppStoreButton url={url} />
      <GooglePlayStoreButton url={url} />
    </div>
  )
}

const AppleAppStoreButton = ({url}: AppStoreButtonProps) => {
  return (
    <a href={url ?? 'https://apps.apple.com/us/app/posh-curator/id1556928106'}>
      <PoshImage
        src='https://images.posh.vip/b3/app-ctas/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
        alt='Download on the App Store'
      />
    </a>
  )
}

const GooglePlayStoreButton = ({url}: AppStoreButtonProps) => {
  return (
    <a href={url ?? 'https://play.google.com/store/apps/details?id=com.posh.mobile&hl=en_US'}>
      <PoshImage src='https://images.posh.vip/b3/app-ctas/google-play-badge.png' />
    </a>
  )
}

/**
 * This component is used to display the correct app store button based on the device type
 * @param url - The url to the app store, if not provided, the default play store and app store urls will be used
 * @returns The correct app store button based on the device type
 */
const DeviceSpecificAppStoreButton = ({url}: AppStoreButtonProps) => {
  const {isAndroid} = useDeviceType()
  return <>{isAndroid ? <GooglePlayStoreButton url={url} /> : <AppleAppStoreButton url={url} />}</>
}

AppStoreButtons.DeviceSpecific = DeviceSpecificAppStoreButton
