import React from 'react'

import {useMixpanel} from 'apis/MixPanelHandler'
import {PoshLogo} from 'components/PoshLogo'

export const LoginDivLinks = () => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  return (
    <>
      <span style={{fontSize: '16px'}}>
        {"Don't have an account? "}
        <a
          className='LoginPage-form-forgotPassword'
          onClick={() => {
            trackMixpanelEvent('Dont Have An Account Signup-  Main Login')
          }}
          href={'/signup'}>
          Sign Up
        </a>
      </span>
      <a
        style={{fontSize: '16px'}}
        onClick={() => {
          trackMixpanelEvent('Forgot Password-  Main Login')
        }}
        className='LoginPage-form-forgotPassword'
        href={'/reset-password/new'}>
        Forgot Password
      </a>
    </>
  )
}

export const LoginLogo = ({className}: {className?: string}) => <PoshLogo.Link className={className} />

export const LoginFooter = () => (
  <div className='LoginPage-footer'>
    <a href='/'>&copy; POSH</a>
    <span>&mdash;</span>
    <a href='/tos'>Terms</a>
    <span>&mdash;</span>
    <a href='/privacy'>Privacy</a>
  </div>
)
