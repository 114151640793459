import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {ExportKeyObject} from 'apis/Events/types'
import {useExportReport} from 'apis/Events/useExportReport'
import {useFetchEventExportKeys} from 'apis/Events/useFetchEventExportKeys'
import Button from 'components/form/Button'
import {CheckBoxList} from 'components/form/CheckBoxList'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {useToast} from 'components/toasts/ToastProvider'
import {isEmpty} from 'lodash'

import './styles.scss'

export const EventExportModal = (props: PoshStyledModalProps) => {
  const {onClose, isOpen} = props
  const {eventId} = useParams()
  const {data: exportKeys, status} = useFetchEventExportKeys(eventId as string)
  const [selectedKeys, setSelectedKeys] = useState<ExportKeyObject[] | undefined>(exportKeys)
  const {mutateAsync: exportReport, isLoading} = useExportReport()
  const {showToast} = useToast()
  const defaultKeys = exportKeys
  const [includeCancelledOrders, setIncludeCancelledOrders] = useState(false)

  useEffect(() => {
    if (exportKeys) {
      setSelectedKeys(exportKeys)
    }
  }, [exportKeys])

  const handleSubmit = async () => {
    try {
      const exportedKeys = selectedKeys?.filter(el => el.selected)
      if (isEmpty(exportedKeys)) throw new Error('Please select values to initiate export')
      if (!eventId) throw new Error('Something went wrong. Please try again.')
      const url = await exportReport({
        eventId,
        exportKeys: exportedKeys as ExportKeyObject[],
        options: {includeCancelledOrders},
      })
      setSelectedKeys(defaultKeys)
      window.location.assign(url)
      onClose()
    } catch (err: any) {
      onClose()
      if (err.response.status === 429) showToast({type: 'error', title: 'Too many requests. Please try again later.'})
      else if (err.response) showToast({type: 'error', title: err.response.statusText})
      else showToast({type: 'error', title: err.message})
    }
  }

  const selectAllFlag = selectedKeys?.filter(el => el.selected).length === selectedKeys?.length
  const handleSelectAll = () => {
    if (selectAllFlag) {
      setSelectedKeys(
        selectedKeys?.map(el => {
          return {...el, selected: false}
        }),
      )
    } else {
      setSelectedKeys(
        selectedKeys?.map(el => {
          return {...el, selected: true}
        }),
      )
    }
  }

  const onIncludeCancelledOrders = () => {
    setIncludeCancelledOrders(!includeCancelledOrders)
  }

  return (
    <PoshStyledModal isOpen={isOpen} onClose={onClose}>
      <div className='EventExportModal'>
        <h3 className='center'>Export Event Report</h3>
        <p className='center'>
          The export is a list of all orders. Select which data fields you would like included. For in-person orders,
          custom amounts will be marked with an asterisk (*).
        </p>
        {status === 'loading' && (
          <div>
            <SpinLoader height={80} />
          </div>
        )}
        {status === 'success' && selectedKeys && (
          <>
            <label className='CheckBoxList-Option'>
              <input type='checkbox' checked={selectAllFlag} value='Select All' onClick={handleSelectAll} />
              <span aria-hidden='true' className='CheckBoxList-Option-Checkbox'>
                <svg viewBox='-2 -3 18 18'>
                  <path d='M1 4.5L5 10L14 1' />
                </svg>
              </span>
              Select All
            </label>
            <CheckBoxList options={selectedKeys} updateOptions={setSelectedKeys} />
            <label className='CheckBoxList-Option'>
              <input
                type='checkbox'
                checked={includeCancelledOrders}
                value='Include cancelled orders?'
                onClick={onIncludeCancelledOrders}
              />
              <span aria-hidden='true' className='CheckBoxList-Option-Checkbox'>
                <svg viewBox='-2 -3 18 18'>
                  <path d='M1 4.5L5 10L14 1' />
                </svg>
              </span>
              <i>Include cancelled orders?</i>
            </label>
            <Button onClick={handleSubmit} className='gold' disabled={isLoading}>
              {isLoading ? 'Exporting...' : 'Export Report'}
            </Button>
          </>
        )}
      </div>
    </PoshStyledModal>
  )
}
