import React, {useState} from 'react'

import {EventModel} from 'apis/Events/types'
import {updateTicket} from 'apis/Events/useUpdateTicket'
import IconButton from 'components/form/IconButton'
import {SpinLoader} from 'components/Loaders/SpinLoader'

const SwitchToApprovalBtn = ({event}: {event: EventModel}) => {
  const [approvalsRequired, setApprovalsRequired] = useState(event.tickets[0].approvalRequired)
  const [loading, setLoading] = useState(false)

  const switchToApprovalEvent = async () => {
    setLoading(true)
    setApprovalsRequired(!approvalsRequired)
    await updateTicket({eventId: event._id, ticket: {...event.tickets[0], approvalRequired: !approvalsRequired}})
    window.location.reload()
  }

  if (loading) return <SpinLoader height={20} />

  return (
    <IconButton
      className='light invertIcon dark'
      onClick={() => switchToApprovalEvent()}
      icon='https://images.posh.vip/b2/pending-black.svg'>
      {approvalsRequired ? 'Allow Anyone to RSVP' : 'Require Approval to RSVP'}
    </IconButton>
  )
}

export default SwitchToApprovalBtn
