import {ChangeEvent} from 'react'

import {useMixpanel} from 'apis/MixPanelHandler'
import {TOSLinks} from 'components/TOSLinks'

interface SMSNotificationsOptInCheckboxProps {
  groupId: string
  smsOptInValue: boolean
  setSmsOptInValue: (value: boolean) => void
  messagingString: string
}

export const SMSNotificationsOptInCheckbox = (props: SMSNotificationsOptInCheckboxProps) => {
  const {smsOptInValue, setSmsOptInValue, messagingString} = props

  const {trackEvent} = useMixpanel()

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked
    setSmsOptInValue(value)
    trackEvent('Enable Notifications - Checkout Page', {enabled: value})
  }

  return (
    <div className='CheckoutPage-CheckboxTOS'>
      <input type='checkbox' checked={smsOptInValue} onChange={handleCheckboxChange} />
      <p className='!leading-4'>
        By checking this box, {messagingString} <TOSLinks />
      </p>
    </div>
  )
}
