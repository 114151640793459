import useFetchEvent from 'apis/Events/useFetchEvent'
import {useInvalidateGetAllEventOrders} from 'apis/Events/useGetAllEventOrders'
import {useInvalidateGetAllEventTickets} from 'apis/Events/useGetAllEventTickets'
import {useToast} from 'components/toasts/ToastProvider'

export function useRefetchAndGoBack(eventId: string) {
  const {showToast} = useToast()
  const {refetch: refetchEvent, isLoading: isFetchingEvent} = useFetchEvent(eventId)
  const {invalidateGetAllEventTickets} = useInvalidateGetAllEventTickets()
  const {invalidateGetAllEventOrders} = useInvalidateGetAllEventOrders()

  const refetchAndGoBack = async () => {
    try {
      await Promise.all([refetchEvent(), invalidateGetAllEventTickets(), invalidateGetAllEventOrders()])
      history.back()
    } catch {
      showToast({type: 'error', title: 'There was an error fetching the event'})
    }
  }

  return {refetchAndGoBack, isRefetching: isFetchingEvent}
}
