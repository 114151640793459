import React, {useState} from 'react'
import {SubmitHandler, useForm} from 'react-hook-form'

import {zodResolver} from '@hookform/resolvers/zod'
import {IGroupLink} from 'apis/Groups'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {z} from 'zod'

interface LinkRowProps {
  link: IGroupLink
  onUpdate: (customLink: IGroupLink) => void
  onDelete: () => void
}

export const CustomLinkSchema = z.object({
  url: z.string().url(),
  title: z.string().nonempty(),
})

export type CustomLinkSchema = z.infer<typeof CustomLinkSchema>

const LinkRow = (props: LinkRowProps) => {
  const {link, onUpdate, onDelete} = props
  const [isEditing, setIsEditing] = useState(false)

  const {register, handleSubmit, formState, reset} = useForm({
    defaultValues: link,
    resolver: zodResolver(CustomLinkSchema),
  })

  const {errors} = formState

  const onSubmit: SubmitHandler<CustomLinkSchema> = data => {
    onUpdate(data)
    setIsEditing(false)
  }

  const cancelEditingLink = () => {
    setIsEditing(false)
    reset()
  }

  return (
    <div className='CustomLinks-L'>
      {isEditing ? (
        <div style={{display: 'block', width: '100%'}}>
          <div className='CustomLinks-updateLink'>
            <div>
              <label className='CustomLinks-label'>Title</label>
              <Input className='CustomLinks-updateLink-input' placeholder={'Link Title'} {...register('title')} />
              {errors.title && <p className='CustomLinks-error'>{errors.title.message}</p>}
            </div>
            <div>
              <label className='CustomLinks-label'>URL</label>
              <Input className='CustomLinks-updateLink-input' placeholder={'URL'} {...register('url')} />
              {errors.url && <p className='CustomLinks-error'>{errors.url.message}</p>}
            </div>
            <div className='CustomLinks-updateActions'>
              <Button
                className='Button--gray CustomLinks-updateActions-button cancel fullWidth dark'
                onClick={() => cancelEditingLink()}>
                Cancel
              </Button>
              <Button
                disabled={!formState.isDirty}
                className='CustomLinks-updateActions-button fullWidth'
                onClick={handleSubmit(onSubmit)}>
                Update
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div>{link.title}</div>
      )}
      {!isEditing && (
        <div className='CustomLinks-L-Actions'>
          <a onClick={() => setIsEditing(!isEditing)}>
            <PoshImage src='https://images.posh.vip/b2/pencil.svg' />
          </a>
          <a onClick={onDelete}>
            <PoshImage src='https://images.posh.vip/b2/delete.svg' />
          </a>
        </div>
      )}
    </div>
  )
}

export default LinkRow
