import React, {PropsWithChildren, useRef} from 'react'

import classNames from 'classnames'
import {brushUrl} from 'components/assets/Icons'
import {GlassMorphicButton} from 'components/form/Button'
import {PictureState} from 'components/form/ImagePicker'
import {FlyerUploadButton} from 'components/form/ImageSearchModal/FlyerUploadButton'
import {PoshImage, PoshImageBackground} from 'components/PoshImage/PoshImage'

import styles from './styles.module.scss'

interface EventVisualsFlyerProps {
  image?: string
  isUploadingImage: boolean
  flyerPlaceholder: string
  flyerPlaceholderText?: string
  wrapperStyles?: React.CSSProperties
  lightMode: boolean
  accentColor: string
  openImagePicker: () => void
  openDesignModal?: () => void
  onFileSelect?: (file: PictureState) => void
  useDirectUpload?: boolean
  showClickAboveText?: boolean
}

export const EventVisualsFlyer = (props: PropsWithChildren<EventVisualsFlyerProps>) => {
  const {
    image,
    isUploadingImage,
    openDesignModal,
    flyerPlaceholder,
    openImagePicker,
    lightMode,
    accentColor,
    children,
    wrapperStyles,
    flyerPlaceholderText = 'Upload your flyer to get started',
    onFileSelect,
    useDirectUpload = false,
    showClickAboveText = true,
  } = props

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleAreaClick = () => {
    if (useDirectUpload && fileInputRef.current) {
      fileInputRef.current.click()
    } else {
      openImagePicker()
    }
  }

  return (
    <div className={styles.EventVisualsFlyer}>
      <div className={styles.wrapper} style={wrapperStyles}>
        {image ? (
          <PoshImage
            className={classNames(styles.flyer, {[`${styles.isLoading}`]: isUploadingImage})}
            src={image}
            alt='event flyer'
            onClick={openImagePicker}
          />
        ) : (
          <PoshImageBackground
            onClick={handleAreaClick}
            className={styles.flyerPlaceholder}
            style={{backgroundImage: `url(${flyerPlaceholder})`}}>
            <h3 className='m-5 text-center'>{flyerPlaceholderText}</h3>
            {useDirectUpload && onFileSelect ? (
              <>
                <FlyerUploadButton onFileSelect={onFileSelect} label='Upload Flyer' inputRef={fileInputRef} />
              </>
            ) : (
              <GlassMorphicButton onClick={openImagePicker} disabled={isUploadingImage}>
                Upload Flyer*
              </GlassMorphicButton>
            )}
          </PoshImageBackground>
        )}
        <div className={styles.childrenWrapper}>{children}</div>
        <div className={styles.buttonWrapper}>
          {image && !!openDesignModal && (
            <button
              disabled={isUploadingImage}
              className={styles.brushButton}
              style={{backgroundColor: lightMode ? 'white' : accentColor}}
              onClick={openDesignModal}>
              <img src={brushUrl} alt='brush' />
            </button>
          )}
        </div>
      </div>
      {showClickAboveText && <p className='mt-2 text-center text-sm font-bold'>Click above to select a flyer.</p>}
    </div>
  )
}
