import React, {PropsWithChildren} from 'react'

import classNames from 'classnames'
import {brushUrl} from 'components/assets/Icons'
import {GlassMorphicButton} from 'components/form/Button'
import {PoshImage, PoshImageBackground} from 'components/PoshImage/PoshImage'

import styles from './styles.module.scss'

interface EventVisualsFlyerProps {
  image?: string
  isUploadingImage: boolean
  flyerPlaceholder: string
  lightMode: boolean
  accentColor: string
  openImagePicker: () => void
  openDesignModal: () => void
}

export const EventVisualsFlyer = (props: PropsWithChildren<EventVisualsFlyerProps>) => {
  const {
    image,
    isUploadingImage,
    openDesignModal,
    flyerPlaceholder,
    openImagePicker,
    lightMode,
    accentColor,
    children,
  } = props

  return (
    <div className={styles.EventVisualsFlyer}>
      <div className={styles.wrapper}>
        {image ? (
          <PoshImage
            className={classNames(styles.flyer, {[`${styles.isLoading}`]: isUploadingImage})}
            src={image}
            alt='event flyer'
            onClick={openImagePicker}
          />
        ) : (
          <PoshImageBackground
            onClick={openImagePicker}
            className={styles.flyerPlaceholder}
            style={{backgroundImage: `url(${flyerPlaceholder})`}}>
            <h3 className='akira m-0'>DESIGN YOUR EVENT PAGE</h3>
            <GlassMorphicButton onClick={openImagePicker} disabled={isUploadingImage}>
              Upload poster*
            </GlassMorphicButton>
          </PoshImageBackground>
        )}
        <div className={styles.childrenWrapper}>{children}</div>
        <div className={styles.buttonWrapper}>
          {image && (
            <button
              disabled={isUploadingImage}
              className={styles.brushButton}
              style={{backgroundColor: lightMode ? 'white' : accentColor}}
              onClick={openDesignModal}>
              <img src={brushUrl} alt='brush' />
            </button>
          )}
        </div>
      </div>
      <p className='mt-2 text-center text-sm font-bold'>Click above to select a flyer.</p>
    </div>
  )
}
