import React, {useCallback, useMemo} from 'react'

import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'
import {
  calculateNumberOfOccurences,
  EventRecurrenceType,
  getFormattedRecurrenceDescription,
  isRecurrenceValid,
} from '@posh/model-types'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import pluralize from 'helpers/pluralize'
import moment from 'moment'

import {RecurrenceSettingsDateInput} from './components/RecurrenceSettingsDateInput'
import {RecurrenceSettingsDaysOfTheWeek} from './components/RecurrenceSettingsDaysOfTheWeek'
import {RecurrenceSettingsItem} from './components/RecurrenceSettingsItem'
import {RecurrenceSettingsNumberInput} from './components/RecurrenceSettingsNumberInput'
import {RecurrenceSettingsSection} from './components/RecurrenceSettingsSection'
import {RecurrenceSettingsSelect} from './components/RecurrenceSettingsSelect'
import {useRecurrenceSettings} from './useRecurrenceSettings'

import styles from './RecurrenceSettings.module.scss'

type RecurrenceSettingsProps = {
  type: 'create' | 'edit'
  recurrenceSettings?: EventRecurrenceType
  startUtc: string
  timezone: string
  onSubmit: (recurrenceSettings: EventRecurrenceType) => void
  onCancel: () => void
}

const NUMBER_INPUT_WIDTH = 50

export const RecurrenceSettings = (props: RecurrenceSettingsProps) => {
  const {startUtc, timezone, onSubmit, onCancel, type, recurrenceSettings: defaultRecurrenceSettings} = props

  const startDate = useMemo(() => moment.tz(startUtc, timezone), [startUtc])
  const {trackEvent} = useMixpanel()

  const {
    // Repeat Criteria
    recurrenceRepeatType,
    setRecurrenceRepeatType,
    onOptions,
    onValue,
    onChangeOnValue,
    daysOfWeek,
    toggleDayOfWeek,
    recurrenceRepeatAmount,
    setRecurrenceRepeatAmount,

    // End Criteria
    hasEndDate,
    onChangeHasEndDate,
    nOccurrences,
    setNOccurrences,
    endDate,
    setEndDate,

    // Overall
    eventRecurrence,
    eventRecurrenceParsed,
    repeatCriteriaErrors,
    endCriteriaErrors,
  } = useRecurrenceSettings({
    startDate,
    defaultRecurrenceSettings,
    type,
  })

  const numberOfEvents = calculateNumberOfOccurences(eventRecurrence)

  const trackSubmit = () => {
    trackEvent('Recurrence Settings-  Recurrence settings saved', {
      totalOccurrences: numberOfEvents,
      frequency: recurrenceRepeatType,
    })
  }

  const recurrenceDescription = useMemo(() => {
    return getFormattedRecurrenceDescription(eventRecurrence)
  }, [eventRecurrence])

  const shouldDisableDate = useCallback<(day: MaterialUiPickersDate) => boolean>(
    day => {
      if (!day) return false
      const issues = isRecurrenceValid({
        ...eventRecurrence,
        ending: {
          hasEndDate: true,
          endDate: day.toISOString(true),
        },
      })

      return issues.length > 0
    },
    [eventRecurrence],
  )

  return (
    <div className={styles.RecurrenceSettingsWrapper}>
      <div className={styles.RecurrenceSettings}>
        <div className='h-[1px] bg-white opacity-10' />
        <div className={styles.RecurrenceSettingsFields}>
          <RecurrenceSettingsSection
            style={type === 'edit' ? {position: 'relative'} : undefined}
            error={repeatCriteriaErrors}>
            {type === 'edit' && <div className={styles.RecurrenceSettingsSectionDisabled} />}
            <RecurrenceSettingsItem label='Repeats Every'>
              <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                <div style={{width: NUMBER_INPUT_WIDTH}}>
                  <RecurrenceSettingsNumberInput
                    value={recurrenceRepeatAmount}
                    onChange={value => {
                      if (value < 1) return
                      setRecurrenceRepeatAmount(value)
                    }}
                  />
                </div>
                <RecurrenceSettingsSelect
                  options={[
                    {label: pluralize('day', recurrenceRepeatAmount), value: 'Daily'},
                    {
                      label: pluralize('week', recurrenceRepeatAmount),
                      value: 'Weekly',
                    },
                    {label: pluralize('month', recurrenceRepeatAmount), value: 'Monthly'},
                    {label: pluralize('year', recurrenceRepeatAmount), value: 'Annual'},
                  ]}
                  value={recurrenceRepeatType}
                  onChange={value => setRecurrenceRepeatType(value)}
                />
              </div>
            </RecurrenceSettingsItem>
            {recurrenceRepeatType === 'Weekly' && (
              <RecurrenceSettingsItem label='On' resizeVertical>
                <RecurrenceSettingsDaysOfTheWeek
                  isDayOfWeekEnabled={dayOfWeek => daysOfWeek[dayOfWeek]}
                  toggleDayOfWeek={toggleDayOfWeek}
                />
              </RecurrenceSettingsItem>
            )}
            {(recurrenceRepeatType === 'Monthly' || recurrenceRepeatType === 'Annual') && (
              <RecurrenceSettingsItem label='On'>
                <RecurrenceSettingsSelect options={onOptions} value={onValue} onChange={onChangeOnValue} />
              </RecurrenceSettingsItem>
            )}
          </RecurrenceSettingsSection>
          {type === 'edit' && (
            <p className='text-center text-xs text-gray-400!'>
              This series is already live, so the cadence can&apos;t be edited.
            </p>
          )}
          <RecurrenceSettingsSection error={endCriteriaErrors}>
            <RecurrenceSettingsItem label='Ends'>
              <RecurrenceSettingsSelect
                value={hasEndDate}
                options={[
                  {label: 'after number of occurrences', value: false},
                  {label: 'on a specific date', value: true},
                ]}
                onChange={value => onChangeHasEndDate(value)}
              />
            </RecurrenceSettingsItem>
            {hasEndDate ? (
              <RecurrenceSettingsItem label='End Date'>
                <RecurrenceSettingsDateInput
                  disabled={!hasEndDate}
                  value={endDate}
                  onChange={value => {
                    setEndDate(moment.parseZone(value))
                  }}
                  shouldDisableDate={shouldDisableDate}
                />
              </RecurrenceSettingsItem>
            ) : (
              <RecurrenceSettingsItem label='Number of Occurrences'>
                <div style={{width: NUMBER_INPUT_WIDTH}}>
                  <RecurrenceSettingsNumberInput
                    disabled={hasEndDate}
                    value={nOccurrences}
                    onChange={value => {
                      // there is a minimum of 2 occurrences for number of events within a multi-session
                      if (value < 2) return
                      setNOccurrences(value)
                    }}
                  />
                </div>
              </RecurrenceSettingsItem>
            )}
          </RecurrenceSettingsSection>
        </div>
        <div className='h-[1px] bg-white opacity-10' />
        <div className='flex flex-col items-center gap-2 md:gap-4'>
          <p className='m-0 text-center text-xs text-gray-400!'>{recurrenceDescription}</p>
          {hasEndDate && (
            <p className='m-0 text-center text-xs text-gray-400!'>
              ({numberOfEvents} {pluralize('event', numberOfEvents)})
            </p>
          )}
          <div className='mt-2 flex justify-center gap-4'>
            <Button className='dark' onClick={onCancel}>
              Cancel
            </Button>
            <Button
              disabled={eventRecurrenceParsed.type === 'error'}
              onClick={() => {
                if (eventRecurrenceParsed.type === 'error') return
                onSubmit(eventRecurrenceParsed.recurrenceSettings)
                trackSubmit()
              }}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
