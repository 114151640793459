import {Group} from 'apis/Groups'

export type GroupGmvVerified = Pick<Group, 'gmv' | 'verified'>

export const GMVRankMap = {
  Apprentice: {
    min: 0,
    max: 499,
    perks: [],
  },
  Merchant: {
    min: 2500,
    max: 9999,
    perks: ['Posh Sticker Pack'],
  },
  Explorer: {
    min: 10000,
    max: 99999,
    perks: ['Posh Gear Kit (Bronze Tier)'],
  },
  Pro: {
    min: 100000,
    max: 499999,
    perks: ['Posh Gear Kit (Silver Tier)'],
  },
  Mogul: {
    min: 500000,
    max: 999999,
    perks: ['Posh Gear Kit (Gold Tier)'],
  },
  Visionary: {
    min: 1000000,
    max: 2499999,
    perks: ['Dinner with the Posh team*'],
  },
  Icon: {
    min: 2500000,
    max: 9999999,
    perks: ['Helicopter tour in your city'],
  },
  Titan: {
    min: 10000000,
    max: 100000000,
    perks: ['Custom-wrapped Tesla Model 3*'],
  },
} as const

export type GMVRankName = keyof typeof GMVRankMap

export const useRankThreshold = (group: GroupGmvVerified, rank: GMVRankName) => {
  if (group.verified) return true
  const gmv = group.gmv ?? 0
  const tier = GMVRankMap[rank]
  return gmv >= tier.min
}
