import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetMultiSessionSectionalDataOptions = ReactQueryOptions['multiSessionEvents']['getMultiSessionSectionalData']
type GetMultiSessionSectionalDataInput = RouterInputs['multiSessionEvents']['getMultiSessionSectionalData']
export type TDataPoint = GetMultiSessionSectionalDataInput['dataPoints'][number]
export type GetMultiSessionSectionalDataOutput = RouterOutput['multiSessionEvents']['getMultiSessionSectionalData']
export type IMSECrossSection = GetMultiSessionSectionalDataOutput[number]
export type TMSECrossSectionType = IMSECrossSection['type']

export function useGetMultiSessionSectionalData(
  input: GetMultiSessionSectionalDataInput,
  opts?: GetMultiSessionSectionalDataOptions,
) {
  return trpc.multiSessionEvents.getMultiSessionSectionalData.useQuery(input, opts)
}
