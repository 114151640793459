import React, {useRef} from 'react'

import {MAX_FILE_UPLOAD_SIZE} from '@posh/types'
import {useToast} from 'components/toasts/ToastProvider'
import {bytesToMB} from 'helpers/fileUtils'
import {ACCEPTED_FILE_TYPES} from 'hooks/uploadImage/useUploadImage'

import Button from '../Button'
import {PictureState} from '../ImagePicker'

interface FlyerUploadButtonProps {
  onFileSelect: (file: PictureState) => void
  label: string
  onButtonClick?: () => void
  inputRef?: React.RefObject<HTMLInputElement>
}

export const FlyerUploadButton: React.FC<FlyerUploadButtonProps> = ({
  onFileSelect,
  label = 'Use Your Own',
  onButtonClick,
  inputRef,
}) => {
  const {showToast} = useToast()
  const internalRef = useRef<HTMLInputElement>(null)
  const fileInputRef = inputRef || internalRef

  const handleOnSubmitImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return
    }

    const file = e.target.files[0]

    if (file.size > MAX_FILE_UPLOAD_SIZE) {
      showToast({type: 'error', title: `Image too large, must be under ${bytesToMB(MAX_FILE_UPLOAD_SIZE)}MB`})
      return
    }

    const imageUrl = URL.createObjectURL(file)

    onFileSelect({file, url: imageUrl})
  }

  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onButtonClick?.()
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <Button onClick={handleButtonClick} className='small m-0 w-50'>
      {label}
      <input
        className='ImageSearchModal-input'
        type='file'
        accept={ACCEPTED_FILE_TYPES}
        multiple={false}
        onChange={handleOnSubmitImage}
        ref={fileInputRef}
        style={{display: 'none'}}
      />
    </Button>
  )
}
