import {ChangeEvent, useState} from 'react'

import {POSH_MESSAGING_AGREEMENT_STRING} from '@posh/model-types'
import {useMixpanel} from 'apis/MixPanelHandler'
import {useUpdateAccountOptIn} from 'apis/Optins/useUpdateAccountOptIn'
import {useUpdateGroupOptIn} from 'apis/Optins/useUpdateGroupOptIn'

interface SMSNotificationsOptInCheckboxProps {
  groupId: string
}

export const SMSNotificationsOptInCheckbox = (props: SMSNotificationsOptInCheckboxProps) => {
  const {groupId} = props
  const {mutate: updateGroupOptIn} = useUpdateGroupOptIn()
  const {mutate: updateAccountOptIns} = useUpdateAccountOptIn({
    location: 'checkout',
    query: {optInType: 'posh-sms', fields: ['optIns']},
  })
  const [hasAgreed, setHasAgreed] = useState(false)
  const {trackEvent} = useMixpanel()

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked
    setHasAgreed(value)
    trackEvent('Enable Notifications - Checkout Page', {enabled: value})
    updateGroupOptIn({
      group: {
        id: groupId,
      },
      optIns: {smsOptIn: value ? 'yes' : 'no-subaccount'},
      optInType: 'group-sms',
    })
    updateAccountOptIns({
      optIns: {marketing: {smsOptIn: value ? 'yes' : 'no-application'}},
      optInType: 'posh-sms',
    })
  }

  return (
    <div className='CheckoutPage-CheckboxTOS'>
      <input type='checkbox' checked={hasAgreed} onChange={handleCheckboxChange} />
      <p>By checking this box, {POSH_MESSAGING_AGREEMENT_STRING}</p>
    </div>
  )
}
