import React from 'react'

import {DEFAULT_ACCOUNT_AVI} from '@posh/model-types'
import {useDownloadCustomCheckoutFieldFile} from 'apis/CustomCheckoutFields/useDownloadCustomCheckoutFieldFile'
import useUpdatePendingApprovals from 'apis/Orders/useUpdatePendingApprovals'
import {deleteUrl} from 'components/assets/Icons'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'
import {useEventMatchParams} from 'domains/Events/helpers'
import {getFullName} from 'helpers/User/getFullName'

import ApprovalButtons, {LoadingDeactivatedApprovalButton} from '../ApprovalButtons'
import {ApprovalStatusPresentTenseMap, ApproveOrDeny} from '../ApprovalSection'
import useApprovalCrmContext from '../ApprovalSection/ApprovalContextProvider/ApprovalCrmContext'
import useApprovalButtons from '../ApprovalSection/useApprovalButtons.hooks'
import CheckoutData from './CheckoutData'
import Section from './Section'
import TicketsAndTables from './TicketsAndTables'
import {TrackingLinkCard} from './TrackingLinkCard'
import ViewSocialsButtons from './ViewSocialsButtons'

import './styles.scss'

interface ApprovalModalProps {
  onClose: () => void
}

const ApprovalModal = (props: ApprovalModalProps) => {
  const {onClose} = props
  const {showToast} = useToast()
  const {approvalProfile: pendingApproval} = useApprovalCrmContext()
  const {expandedType, handleCancelConfirmation, handleExpandButton} = useApprovalButtons()
  const {eventId} = useEventMatchParams()
  const {mutate: updatePendingApproval, isLoading} = useUpdatePendingApprovals({
    onSuccess: (_, variables) => {
      showToast({
        type: 'success',
        title: `Successfully ${variables.updates[0].status} order!`,
      })
      onClose()
    },
    onError: (error, variables) => {
      showToast({
        type: 'error',
        title: `There was an error ${ApprovalStatusPresentTenseMap[variables.updates[0].status]} this order!`,
        subtitle: error.message,
      })
    },
  })
  const {mutate: downloadFile} = useDownloadCustomCheckoutFieldFile()
  const onClickFileName = (fileKey: string) => {
    downloadFile({key: fileKey, eventId: eventId!})
  }

  const handleUpdatePendingApproval = (status: ApproveOrDeny) => {
    if (!pendingApproval) return
    const confirmedStatus = status === 'approve' ? 'approved' : 'denied'
    updatePendingApproval({updates: [{status: confirmedStatus, id: pendingApproval._id}], eventId: eventId!})
  }

  const {account, cart} = pendingApproval!
  const hasCheckoutData = cart.checkoutResponses && cart.checkoutResponses.length > 0

  return (
    <div className='ApprovalModal'>
      <div className='ApprovalModal-Content'>
        <PoshImage className='ApprovalModal-Content-close' src={deleteUrl} onClick={onClose} />
        <PoshImage className='ApprovalModal-Content-img' src={account.avi ?? DEFAULT_ACCOUNT_AVI} />
        <h3 className='ApprovalModal-Content-name m-0'>{getFullName(account)}</h3>
        {cart.trackingLink && <TrackingLinkCard trackingLink={cart.trackingLink} />}
        <div style={{width: '100%'}}>
          <Section name='tickets'>
            <TicketsAndTables tickets={cart.tickets} tables={cart.tables} />
          </Section>
          {hasCheckoutData && (
            <Section name='checkout data'>
              <CheckoutData checkoutData={cart.checkoutData} onClickFileName={onClickFileName} />
            </Section>
          )}
        </div>
        <ViewSocialsButtons username={account.username} instagram={account.profile?.instagram} />
        <div className='ApprovalModal-Content-approvalButtonsWrapper'>
          {isLoading ? (
            <LoadingDeactivatedApprovalButton isCrmRow={false} />
          ) : (
            <ApprovalButtons
              isCrmRow={false}
              expandedType={expandedType}
              handleExpandButton={handleExpandButton}
              handleUpdateApprovalStatus={handleUpdatePendingApproval}
              handleCancelConfirmation={handleCancelConfirmation}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ApprovalModal
