import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {useQueryClient} from '@tanstack/react-query'
import {ITicketGroup, Ticket} from 'apis/Events/types'
import {useUpdateTicketGroups} from 'apis/Events/useUpdateTicketGroups'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {SelectOption} from 'components/form/MultiSelect'
import {TicketTypeMultiSelect} from 'components/form/MultiSelect/TicketTypeMultiSelect'
import {useToast} from 'components/toasts/ToastProvider'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import './styles.scss'

interface CreateTicketGroupProps {
  ticketGroups?: ITicketGroup[]
  tickets: Ticket[]
  onCreateTicketGroupClick?: () => void
}

const CreateTicketGroup = (props: CreateTicketGroupProps) => {
  const {ticketGroups, tickets, onCreateTicketGroupClick} = props
  const {eventId} = useResourcePageParams()
  const navigate = useNavigate()
  const {showToast} = useToast()
  const queryClient = useQueryClient()
  const {mutateAsync: updateTicketGroups, isLoading} = useUpdateTicketGroups({
    onSuccess: async () => {
      showToast({type: 'success', title: 'Ticket group created successfully.'})
      await queryClient.invalidateQueries(['event', eventId])
      navigate(-1)
    },
    onError: () => {
      showToast({type: 'error', title: 'Error creating ticket group.'})
    },
  })

  const initalOptions = tickets.map(ticket => {
    return {name: ticket.name, id: ticket.id, selected: false}
  })
  const [options, setOptions] = useState<SelectOption[]>(initalOptions)
  const [ticketGroupName, setTicketGroupName] = useState('')

  const handleSubmit = async () => {
    onCreateTicketGroupClick?.()
    const ticketsInGroup: string[] = []
    for (const option of options) {
      if (option.selected) {
        ticketsInGroup.push(option.id!)
      }
    }
    if (ticketsInGroup.length === 0) return showToast({type: 'error', title: 'Please select at least one ticket.'})
    if (ticketGroupName === '') return showToast({type: 'error', title: 'Please enter a name for the ticket group.'})
    const ticketGroupsCopy = ticketGroups || []
    const newTicketGroup = {
      name: ticketGroupName,
      ticketTypes: ticketsInGroup,
    }
    ticketGroupsCopy?.push(newTicketGroup)
    await updateTicketGroups({
      eventId: eventId!,
      ticketGroups: ticketGroupsCopy,
    })
  }

  return (
    <div className='CreateTicketGoup'>
      <BackAndTitle />
      <Input
        className='large fullWidth'
        placeholder='Ticket Group Name'
        type='text'
        value={ticketGroupName}
        onChange={e => setTicketGroupName(e.target.value)}
      />
      <TicketTypeMultiSelect
        ticketOptions={tickets}
        selectedTicketIds={[]}
        updateOptions={setOptions}
        name='Select Tickets'
        emptyMessage='No tickets available for grouping.'
      />
      <div className='buttonWrapper center'>
        <Button className='fullWidth dark' onClick={() => navigate(-1)} disabled={isLoading}>
          Cancel
        </Button>
        <Button className='gold fullWidth' onClick={handleSubmit} disabled={isLoading} isLoading={isLoading}>
          Create Ticket Group
        </Button>
      </div>
    </div>
  )
}

export default CreateTicketGroup
