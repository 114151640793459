import React, {useEffect, useState} from 'react'

import {useGetYoutubeVideos, YoutubeVideo} from 'apis/Util/useGetYoutubeVideos'
import {YoutubeLogoRed} from 'components/assets/Icons'
import useDebounce from 'hooks/useDebounce'

import {DropdownSearchInput} from '../DropdownSearchInput'
import {EventVisualsTextInput} from '../TextInput'
import {YoutubeVideoListItem} from './Video'

interface YoutubeSearchInputProps {
  accentColor: string
  lightMode: boolean
  textColor: string
  youtubeLink: string | null
  setYoutubeLink: (link: string | null) => void
}

const YOUTUBE_LOGO_SIZE = 18
const YOUTUBE_FETCH_DEBOUNCE_TIME = 1000

export const YoutubeSearchInput = (props: YoutubeSearchInputProps) => {
  const {accentColor, lightMode, textColor, youtubeLink, setYoutubeLink} = props
  const [selectedVideo, setSelectedVideo] = useState<YoutubeVideo | null>(
    youtubeLink ? {title: '', thumbnail: '', videoId: '', link: youtubeLink} : null,
  )
  const [search, setSearch] = useState('')
  const [isPastingLink, setIsPastingLink] = useState(false)
  const debouncedSearch = useDebounce(search, YOUTUBE_FETCH_DEBOUNCE_TIME)

  const {data: getYoutubeVideos, isFetching: isFetchingYoutubeVideos} = useGetYoutubeVideos(
    {search: debouncedSearch},
    {enabled: !!debouncedSearch, keepPreviousData: !!search},
  )

  useEffect(() => {
    if (selectedVideo) {
      setYoutubeLink(selectedVideo.link)
    } else {
      setYoutubeLink('')
    }
  }, [selectedVideo])

  const onDeselectItem = () => {
    setSearch('')
    setSelectedVideo({
      title: '',
      thumbnail: '',
      videoId: '',
      link: '',
    })
  }

  const switchToPastingLink = () => {
    setIsPastingLink(true)
  }

  return (
    <>
      {isPastingLink ? (
        <EventVisualsTextInput.Text
          accentColor={accentColor}
          lightMode={lightMode}
          value={youtubeLink || ''}
          onChange={e => setYoutubeLink(e.target.value)}
          icon={<YoutubeLogoRed height={YOUTUBE_LOGO_SIZE} width={YOUTUBE_LOGO_SIZE} />}
          placeholder={'Paste a YouTube link'}
        />
      ) : (
        <>
          <DropdownSearchInput
            accentColor={accentColor}
            lightMode={lightMode}
            item={selectedVideo?.link ? selectedVideo : null}
            search={search}
            setSearch={setSearch}
            isFetching={isFetchingYoutubeVideos}
            icon={<YoutubeLogoRed height={YOUTUBE_LOGO_SIZE} width={YOUTUBE_LOGO_SIZE} />}
            placeholder={'Search for a YouTube video'}
            searchedItems={getYoutubeVideos?.videos}
            renderItem={item => <YoutubeVideoListItem video={item} textColor={textColor} />}
            renderSelectedItem={item => <YoutubeVideoListItem.Selected video={item} textColor={textColor} />}
            onSelectItem={item => setSelectedVideo(item)}
            onDeselectItem={onDeselectItem}
            fallbackMessage={"Don't see the video you want? Click to paste a link!"}
            fallbackAction={switchToPastingLink}
          />
          {!youtubeLink && (
            <p className='center text-xs' style={{color: textColor}}>
              {`By adding a YouTube video, you agree to YouTube's `}
              <a style={{color: textColor}} href='https://www.youtube.com/t/terms'>
                terms of service
              </a>
            </p>
          )}
        </>
      )}
    </>
  )
}
