import React from 'react'

import {PoshImage} from 'components/PoshImage/PoshImage'

interface TextInputProps {
  placeholder: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  icon?: string
  disabled?: boolean
}

const TextIconInput = (props: TextInputProps) => {
  const {placeholder, value, onChange, icon, disabled} = props
  return (
    <div className={`poshInputHolder`}>
      {icon && <PoshImage src={icon} />}
      <input
        type='text'
        name='ordernumber'
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className='poshInput icon w-full text-sm'
        disabled={disabled}
      />
    </div>
  )
}

export default TextIconInput
