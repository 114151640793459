import React, {PropsWithChildren, useContext, useState} from 'react'

export type BackNavigationFunction = () => void

export interface BackNavigationContext {
  popBackNavigationFunction: () => BackNavigationFunction | undefined
  pushBackNavigationFunction: (backNavigationFunction: BackNavigationFunction) => void
}

const BackNavigationContext = React.createContext<BackNavigationContext>({
  popBackNavigationFunction: () => undefined,
  pushBackNavigationFunction: () => {},
})

export const BackNavigationProvider = ({children}: PropsWithChildren) => {
  const [backNavigationStack, setBackNavigationStack] = useState<BackNavigationFunction[]>([])

  const popBackNavigationFunction = () => {
    if (backNavigationStack.length === 0) {
      return undefined
    }
    // Get the "top of the stack" aka the last function in the list
    const lastFunction = backNavigationStack[backNavigationStack.length - 1]
    // Remove it from the "stack" by removing the last element of the array
    setBackNavigationStack(prev => prev.slice(0, -1))
    return lastFunction
  }

  const pushBackNavigationFunction = (backNavigationFunction: BackNavigationFunction) => {
    setBackNavigationStack(prev => [...prev, backNavigationFunction])
    return
  }

  return (
    <BackNavigationContext.Provider value={{popBackNavigationFunction, pushBackNavigationFunction}}>
      {children}
    </BackNavigationContext.Provider>
  )
}

export const useBackNavigationContext = () => {
  const context = useContext(BackNavigationContext)
  if (context === undefined) {
    throw new Error('useBackNavigation must be used within a BackNavigationProvider')
  }
  return context
}
