import React, {HTMLAttributes, PropsWithChildren} from 'react'

import {ChartOptions} from 'chart.js'
import classNames from 'classnames'
import {TableIcon, TableIconProps} from 'components/TableUI/TableIcons/TableIcon'

import {Card} from '../Shared/Card/Card'
import {BarChart} from '../Shared/Charts/BarChart'
import {DoughnutChart} from '../Shared/Charts/DoughnutChart'
import {Legend} from '../Shared/Charts/Legend/Legend'
import {PieChart} from '../Shared/Charts/PieChart'
import {Text, TextProps} from '../Shared/Text/Text'

import styles from './CrossSection.module.scss'

export type ContainerProps = PropsWithChildren & HTMLAttributes<HTMLDivElement>

export const CrossSection = (props: ContainerProps) => {
  const {children, className, ...HTMLDivElementAttributes} = props
  return (
    <Card style={{flexGrow: 1, flexBasis: 0, minWidth: 275}}>
      <div {...HTMLDivElementAttributes} className={classNames(styles.CrossSection, className)}>
        {children}
      </div>
    </Card>
  )
}

const CrossSectionRow = (props: ContainerProps) => {
  const {children, className, ...HTMLDivElementAttributes} = props
  return (
    <div {...HTMLDivElementAttributes} className={classNames(styles.CrossSectionRow, className)}>
      {children}
    </div>
  )
}

const CrossSectionSection = (props: ContainerProps) => {
  const {children, className, ...HTMLDivElementAttributes} = props
  return (
    <div {...HTMLDivElementAttributes} className={classNames(styles.CrossSectionSection, className)}>
      {children}
    </div>
  )
}

const CrossSectionTitle = Text
const CrossSectionValue = (props: TextProps) => {
  const {children, ...textProps} = props
  return (
    <Text {...textProps} size='xl' bold>
      {children}
    </Text>
  )
}

const CrossSectionSubtitle = (props: TextProps) => {
  const {children, ...textProps} = props
  return (
    <Text {...textProps} size='small'>
      {children}
    </Text>
  )
}

const CrossSectionIcon = (props: TableIconProps) => {
  return <TableIcon {...props} width={20} height={20} color={'white'} />
}

interface CrossSectionChartProps {
  chart: {
    chartType: 'Pie' | 'Doughnut' | 'Bar'
    data: {label: string; data: number}[]
  }
  id: string
  legendTitle?: string
  bgColors?: string[]
  formatter?: (dataPoint: number) => string
  barChartMaxValue?: number
}

const CROSS_SECTION_CHART_CANVAS_STYLE = {width: '100%', aspectRatio: 2} as const

const CrossSectionChart = (props: CrossSectionChartProps) => {
  const {chart, id, legendTitle, bgColors, formatter, barChartMaxValue} = props

  const labels = chart.data.map(data => data.label)
  const data = chart.data.map(data => data.data)

  const NotEnoughData =
    (labels.length === 0 && data.length === 0) || (data.every(d => d === 0) && chart.chartType !== 'Bar')
  const chartOptions: ChartOptions<'pie' | 'doughnut' | 'bar'> = {
    plugins: {
      tooltip: {
        callbacks: {
          label: formatter
            ? context => {
                const index = context.dataIndex
                const dataValue = context.dataset.data[index]
                if (typeof dataValue === 'number') {
                  return formatter(dataValue)
                } else if (typeof dataValue === 'object' && dataValue) {
                  return formatter(dataValue[0])
                }
                return ''
              }
            : undefined,
        },
      },
    },
  }

  return (
    <>
      <div
        className={classNames(
          styles.CrossSectionChartContainer,
          chart.chartType === 'Bar' && styles.CrossSectionChartContainerBar,
        )}>
        <div
          style={{
            width: '100%',
            maxWidth: chart.chartType !== 'Bar' ? 300 : '100%',
            maxHeight: chart.chartType !== 'Bar' ? 300 : '100%',
          }}>
          {NotEnoughData ? (
            <>
              {chart.chartType === 'Bar' ? (
                <div
                  style={{
                    width: '100%',
                    height: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: 350, gap: 10}}>
                    <Text size='xl' bold style={{color: 'grey'}}>
                      No data available
                    </Text>
                    <Text size='small' style={{textAlign: 'center'}}>
                      There is currently no data available for this analytics section. Check back later to see if data
                      is available.
                    </Text>
                  </div>
                </div>
              ) : chart.chartType === 'Pie' ? (
                <div className={styles.CrossSectionChartLoading} />
              ) : (
                <div className={styles.CrossSectionChartLoading}>
                  <div className={styles.CrossSectionDoughnutChartCutout} />
                </div>
              )}
            </>
          ) : (
            <>
              {chart.chartType === 'Bar' ? (
                <BarChart
                  id={`${id}-chart`}
                  labels={labels}
                  data={data}
                  bgColors={bgColors}
                  maxValue={barChartMaxValue}
                  canvasStyle={CROSS_SECTION_CHART_CANVAS_STYLE}
                  chartOptions={chartOptions}
                />
              ) : chart.chartType === 'Pie' ? (
                <PieChart
                  id={`${id}-chart`}
                  labels={labels}
                  data={data}
                  bgColors={bgColors}
                  canvasStyle={CROSS_SECTION_CHART_CANVAS_STYLE}
                  chartOptions={chartOptions}
                />
              ) : (
                <DoughnutChart
                  id={`${id}-chart`}
                  labels={labels}
                  data={data}
                  bgColors={bgColors}
                  canvasStyle={CROSS_SECTION_CHART_CANVAS_STYLE}
                  chartOptions={chartOptions}
                />
              )}
            </>
          )}
        </div>
        {chart.chartType !== 'Bar' && <Legend labels={labels} title={legendTitle} colors={bgColors} />}
      </div>
    </>
  )
}

const CrossSectionLoadingChart = ({type}: {type: 'Pie' | 'Doughnut' | 'Bar'}) => {
  if (type === 'Pie' || type === 'Doughnut') {
    return (
      <div style={{display: 'flex', width: '100%', height: 250, justifyContent: 'center', alignItems: 'center'}}>
        <div style={{width: 200, height: 200, borderRadius: '50%', backgroundColor: 'lightgrey'}}></div>
      </div>
    )
  }
  return <div className={styles.CrossSectionChartLoading} />
}

CrossSectionChart.Loading = CrossSectionLoadingChart

CrossSection.Row = CrossSectionRow
CrossSection.Section = CrossSectionSection
CrossSection.Title = CrossSectionTitle
CrossSection.Value = CrossSectionValue
CrossSection.Subtitle = CrossSectionSubtitle
CrossSection.Icon = CrossSectionIcon
CrossSection.Chart = CrossSectionChart
