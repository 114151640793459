import React from 'react'
import QRCode from 'react-qr-code'

import {PaymentStatus} from 'apis/Checkout'
import {OrderModel} from 'apis/Orders'
import {every, isUndefined} from 'lodash'
import moment from 'moment'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

const OrderSummary = ({order, orderId}: {order: OrderModel; orderId: string}) => {
  const hasPaymentPlan = (order.paymentPlan?.length ?? 0) > 0
  const isFullyFinanced = order.paymentPlan ? every(order?.paymentPlan, pp => pp.status == PaymentStatus.PAID) : true
  const currency = order.currency ?? 'USD'

  return (
    <>
      {hasPaymentPlan && (
        <>
          <div className='AttendeeEvent-financed'>Financed Order</div>
          <table className='AttendeeEvent-pptable'>
            {order?.paymentPlan!.map((installment, i) => (
              <tr key={i}>
                <td>Installment #{i + 1}</td>
                <td>{moment(installment.date).format('MMMM DD, YYYY')}</td>
                <td>${installment.amount.toFixed(2)}</td>
                <td
                  className={`AttendeeEvent-pptable-status ${
                    installment.status == PaymentStatus.PAID
                      ? 'green'
                      : installment.status == PaymentStatus.SCHEDULED
                        ? 'blue'
                        : 'red'
                  }`}>
                  {installment.status}
                </td>
              </tr>
            ))}
          </table>
        </>
      )}

      <table className={`CheckoutSummaryTable`}>
        <tbody>
          {order.tickets.map((ticket, index) => {
            return (
              <tr key={index}>
                <td>{ticket.name}</td>
                <td>{`${getCurrencySymbol(currency)}${ticket.price.toFixed(2)}`}</td>
                <td>{`x 1`}</td>
                <td>{`${getCurrencySymbol(currency)}${ticket.price.toFixed(2)}`}</td>
              </tr>
            )
          })}
          {order.tables.map((table, index) => {
            return (
              <tr key={index}>
                <td>{table.name}</td>
                <td>{`${getCurrencySymbol(currency)}${table.price.toFixed(2)}`}</td>
                <td>{`x 1`}</td>
                <td>{`${getCurrencySymbol(currency)}${table.price.toFixed(2)}`}</td>
              </tr>
            )
          })}
          {!isUndefined(order.discount) && order.discount !== 0 && (
            <tr className='CheckoutSummaryTable-discountRow'>
              <td></td>
              <td colSpan={2}>Discount</td>
              <td>{`-${getCurrencySymbol(currency)}${order.discount?.toFixed(2)}`}</td>
            </tr>
          )}
          {order.processingFee && (
            <tr className='CheckoutSummaryTable-feeRow'>
              <td></td>
              <td colSpan={2}>Processing Fee:</td>
              <td>{`${getCurrencySymbol(currency)}${order.processingFee.toFixed(2)}`}</td>
            </tr>
          )}
          {order.feeBreakdown && (
            <>
              {order.feeBreakdown
                .filter(f => f.totalFee > 0)
                .map(fee => (
                  <tr className='CheckoutSummaryTable-feeRow' key={fee.name}>
                    <td></td>
                    <td colSpan={2}>{fee.name}</td>
                    <td>{`${getCurrencySymbol(currency)}${fee.totalFee.toFixed(2)}`}</td>
                  </tr>
                ))}
            </>
          )}

          <tr className='CheckoutSummaryTable-totalRow'>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td colSpan={2}>Total</td>
            <td>{`${getCurrencySymbol(currency)}${order.total.toFixed(2)}`}</td>
          </tr>
        </tbody>
      </table>

      {isFullyFinanced && (
        <>
          <div className='ReceiptPage-qrSpacer'>
            {order.tickets.length > 1 && (
              <span className='ReceiptPage-qrDisclaimer'>
                This QR is for the entire order. If you want to split up tickets, individual QR codes have been emailed
                to you.
              </span>
            )}
            <div className='ReceiptPage-qrContainer'>
              <QRCode
                value={`https://posh.vip/scan-ticket/${orderId}`}
                bgColor='rgba(0,0,0,0)'
                fgColor='#fc0'
                size={120}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default OrderSummary
