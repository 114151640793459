import React, {CSSProperties} from 'react'
import {Bar} from 'react-chartjs-2'

import {BarElement, ChartData, ChartOptions, Legend, Tooltip} from 'chart.js'
import Chart from 'chart.js/auto'

import {CHART_JS_DEFAULT_COLORS, CHART_LEGEND_OPTIONS} from './charts.utils'

interface BarChartProps {
  id: string
  labels: string[]
  data: number[]
  canvasStyle: CSSProperties
  bgColors?: string[]
  chartOptions?: ChartOptions<'bar'>
  maxValue?: number
}

export const BarChart = (props: BarChartProps) => {
  const {id, chartOptions = {}, bgColors = CHART_JS_DEFAULT_COLORS, data, labels, canvasStyle, maxValue} = props

  const graphData: ChartData<'bar', number[], string> = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: bgColors,
        borderColor: bgColors,
        borderWidth: 1,
        borderRadius: 4,
        hoverBackgroundColor: bgColors.map(color => color.replace(')', ', 0.8)')),
      },
    ],
  }

  const options: ChartOptions<'bar'> = {
    ...chartOptions,
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      ...chartOptions.plugins,
      legend: {
        ...chartOptions.plugins?.legend,
        ...CHART_LEGEND_OPTIONS,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: maxValue,
      },
    },
    layout: {
      ...chartOptions.layout,
      padding: chartOptions.layout?.padding ?? 15,
    },
  }

  Chart.register(BarElement, Tooltip, Legend)

  return <Bar id={id} data={graphData} options={options} style={canvasStyle} />
}
