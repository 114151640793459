import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {MAX_FILE_UPLOAD_SIZE} from '@posh/types'
import {useFetchAccount} from 'apis/Accounts/useFetchAccount'
import {useUpdateOwnAccount} from 'apis/Accounts/useUpdateAccount'
import {useMixpanel} from 'apis/MixPanelHandler'
import {uploadImage} from 'apis/Util/useUploadImage'
import Button from 'components/form/Button'
import IconButton from 'components/form/IconButton'
import SingleImagePicker, {PictureState} from 'components/form/ImagePicker'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import useSessionContext from 'domains/Auth/SessionContext'
import {Upload} from 'lucide-react'

import './styles.scss'

interface EditProfilePictureModal extends PoshStyledModalProps {
  redirectTo?: string
  onClose: (uploadedPicture?: boolean) => void
  onClickUploadImage?: () => void
  onClickNoThanks?: () => void
}

const EditProfilePictureModal = (props: EditProfilePictureModal) => {
  const {onClose, redirectTo, onClickUploadImage: onClickUploadImageFromProps, onClickNoThanks} = props
  const {mutateAsync: updateAccount, isLoading} = useUpdateOwnAccount()
  const {currentUser, userId} = useSessionContext()
  const {refetch: refetchAccount} = useFetchAccount(userId ?? '')
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const [avi, setAvi] = useState<PictureState>({url: currentUser?.avi, file: null})
  if (!currentUser || !userId) return null

  const handleSaveProfilePicture = async () => {
    const {file} = avi
    if (!file) return
    const pictureUrl = await uploadImage(file)
    trackMixpanelEvent('Add Profile Pic Modal Click', {where: 'confirm_and_upload_image'})
    await updateAccount({avi: pictureUrl})
    await refetchAccount()
    if (redirectTo) navigate(redirectTo)
    onClose(true)
  }

  const onClickUploadImage = () => {
    onClickUploadImageFromProps?.()
    trackMixpanelEvent('Add Profile Pic Modal Click', {where: 'choose_image'})
  }

  const onCancel = () => {
    trackMixpanelEvent('Add Profile Pic Modal Click', {where: 'cancel'})
    if (redirectTo) navigate(redirectTo)
    onClickNoThanks?.()
    onClose()
  }

  const onReceiveNewImage = (file: PictureState) => {
    setAvi(file)
  }

  return (
    <>
      <PoshStyledModal {...props} noborder={true}>
        <div className='EditProfilePictureModal'>
          <h2>Add a profile pic!</h2>
          <p className='m-0'>Add a profile picture so other guests can get to know you!</p>
          <SingleImagePicker
            className='EditProfilePictureModal-imagepicker'
            title={'Image'}
            showTitle={false}
            didReceiveFile={e => {
              onReceiveNewImage(e)
            }}
            iconImage={true}
            value={avi ? avi : undefined}
          />
          {avi && avi.file && avi.url ? (
            <Button
              className='EditProfilePictureModal-addButton'
              disabled={isLoading}
              onClick={handleSaveProfilePicture}>
              {isLoading ? 'Uploading Profile Picture' : 'Set Profile Picture'}
            </Button>
          ) : (
            <IconButton
              className='EditProfilePictureModal-uploadButton dark'
              icon={Upload}
              onClick={onClickUploadImage}>
              Upload Image
              <input
                type='file'
                accept='image/*'
                onChange={e => {
                  if (!e.target.files) return null
                  const file = e.target.files[0]
                  if (file.size > MAX_FILE_UPLOAD_SIZE) {
                    alert('Image too large, must be under 8MB')
                    return null
                  }
                  const imageUrl = URL.createObjectURL(file)
                  onReceiveNewImage({file, url: imageUrl})
                }}
              />
            </IconButton>
          )}
        </div>
        <div className='EditProfilePictureModal-noThanks'>
          <Button onClick={onCancel} className='dark'>
            No Thanks
          </Button>
        </div>
      </PoshStyledModal>
    </>
  )
}

export default EditProfilePictureModal
