import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'

import {useGetMultiSessionEvent} from 'apis/MultiSessionEvents/useGetMultiSessionEvent'
import {BlurBackgroundModal} from 'components/coreComponents/Modal/variants/BlurBackground'
import {useBackNavigationContext} from 'hooks/navigation/useBackNavigationContext'
import {MultiSessionEventsPage} from 'pages/MultiSessionEventsPublic/page'
import {z} from 'zod'

import {MultiSessionCreatingEventsProgress} from '../components/MultiSessionCreatingEventsProgress'
import {useMultiSessionEventContext} from '../context/ManageMultiSessionEventPagesContext'

const manageMultiSessionEventPageParamsSchema = z.object({
  groupId: z.string(),
  mseId: z.string(),
  domain: z.string(), // why do we still have this
})

function useManageMultiSessionEventPageParams() {
  const {groupId, mseId, domain} = useParams()
  const params = manageMultiSessionEventPageParamsSchema.parse({groupId, mseId, domain})
  return params
}

export function useNavigateToManageMultiSessionEventPage() {
  const navigate = useNavigate()
  return (params: z.infer<typeof manageMultiSessionEventPageParamsSchema>) => {
    navigate(`/${params.domain}/groups/${params.groupId}/series/${params.mseId}`)
  }
}

export function ManageMultiSessionEventPage() {
  const {groupId, mseId, domain} = useManageMultiSessionEventPageParams()
  const {formDirtySafeNavigate} = useMultiSessionEventContext()
  const {pushBackNavigationFunction} = useBackNavigationContext()

  // From the series main page (series/<id> we always want to go back to group overview page)
  useEffect(() => {
    pushBackNavigationFunction(() => {
      formDirtySafeNavigate(`/${domain}/groups/${groupId}/overview`)
    })
  }, [])

  const [searchParams, setSearchParams] = useSearchParams()

  const from = searchParams.get('from')
  const fromCreateEvent = from === 'createEvent'

  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(fromCreateEvent)
  const response = useGetMultiSessionEvent({id: mseId, groupId})
  return (
    <>
      <MultiSessionEventsPage response={response} mseId={mseId} groupId={groupId} domain={domain} />
      <BlurBackgroundModal
        className='bg-zinc-900/50'
        enablePressEscapeToClose={false}
        isOpen={isLoadingModalOpen}
        onClose={() => setIsLoadingModalOpen(false)}>
        <MultiSessionCreatingEventsProgress
          groupId={groupId}
          mseId={mseId}
          onDone={() => {
            searchParams.delete('from')
            setSearchParams(searchParams)
            response.refetch()
            setIsLoadingModalOpen(false)
          }}
        />
      </BlurBackgroundModal>
    </>
  )
}
