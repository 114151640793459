import React, {useMemo} from 'react'
import {useNavigate} from 'react-router-dom'

import {isAllowedTapToPayCountry} from '@posh/model-types'
import {TCrossSectionType, TDataPoint, useGetSectionalData} from 'apis/Analytics/useGetSectionalData'
import {useGetGroupCountryForTapToPay} from 'apis/Groups/useGetGroupForTapToPay'
import Button from 'components/form/Button'
import {WithToolTip} from 'components/form/PoshInfo'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {CrossSection} from '../CrossSection/CrossSection'
import {AnalyticsSection} from '../Shared/AnalyticsSection/AnalyticsSection'
import {Text} from '../Shared/Text/Text'
import {parseNumberValue} from '../Shared/utils'

import styles from './Tickets.module.scss'
interface TicketsSectionProps {
  eventId: string
  groupId: string
  isRSVPEvent?: boolean
  isLoading?: boolean
}

const RSVP_TICKET_DATA_POINTS: TDataPoint[] = ['scannedTickets', 'ticketSourceBreakdownRSVP']
const TICKET_DATA_POINTS: TDataPoint[] = ['scannedTickets', 'totalTicketTypes', 'ticketSourceBreakdown']

const HOST_TICKET_DATA_POINTS: TDataPoint[] = ['scannedTickets', 'totalTicketTypes', 'attendeeEventRetention']

export const TicketsSection = (props: TicketsSectionProps) => {
  const {eventId, groupId, isRSVPEvent} = props
  const {domain} = useResourcePageParams()
  const navigate = useNavigate()
  const {data: groupCountry} = useGetGroupCountryForTapToPay({groupId, eventId})
  const DATA_POINTS = useMemo(() => {
    if (isRSVPEvent) return RSVP_TICKET_DATA_POINTS
    if (domain === 'host') return HOST_TICKET_DATA_POINTS
    return TICKET_DATA_POINTS
  }, [isRSVPEvent, domain])

  const navigateToTicketsPage = () => {
    navigate(`/${domain}/groups/${groupId}/events/${eventId}/tickets`)
  }

  const {data, isLoading, isError, isSuccess} = useGetSectionalData(
    {
      eventId,
      groupId,
      dataPoints: DATA_POINTS,
    },
    {enabled: props.isLoading !== true},
  )

  // Temporary while feature flagging these analytics -- since the backend serves the front-end
  if (isSuccess && !isAllowedTapToPayCountry(groupCountry) && domain !== 'host') {
    const sourceBreakdownChartPosition = isRSVPEvent ? 1 : 2
    data[sourceBreakdownChartPosition] = {
      ...data[sourceBreakdownChartPosition],
      chart: {
        ...data[sourceBreakdownChartPosition].chart,
        chartType: data[sourceBreakdownChartPosition].chart?.chartType ?? 'Pie',
        data: data[sourceBreakdownChartPosition].chart?.data.filter(d => d.label !== 'via In-Person Payments') ?? [],
      },
    }
  }

  return (
    <AnalyticsSection>
      <AnalyticsSection.Header>
        <AnalyticsSection.Title>{isRSVPEvent ? 'RSVPs' : 'Tickets'}</AnalyticsSection.Title>
        <Button onClick={navigateToTicketsPage}>View {isRSVPEvent ? 'RSVPs' : 'Tickets'}</Button>
      </AnalyticsSection.Header>
      {(isLoading || props.isLoading) && (
        <CrossSection.Section className={styles.TicketsCrossSectionContainer}>
          {[...Array(DATA_POINTS.length)].map((_, index) => (
            <CrossSection key={index}>
              <CrossSection.Row>
                <Text.Loading width={150} />
              </CrossSection.Row>
              <CrossSection.Row>
                <Text.Loading size='xl' width={100} />
              </CrossSection.Row>
              <CrossSection.Row>
                <Text.Loading size='small' />
              </CrossSection.Row>
              <CrossSection.Row>
                <div
                  style={{display: 'flex', width: '100%', height: 250, justifyContent: 'center', alignItems: 'center'}}>
                  <div style={{width: 200, height: 200, borderRadius: '50%', backgroundColor: 'lightgrey'}}></div>
                </div>
              </CrossSection.Row>
            </CrossSection>
          ))}
        </CrossSection.Section>
      )}

      {isError && (
        <CrossSection.Section>
          <CrossSection>
            <CrossSection.Row>
              <Text size='xl' bold>
                Error
              </Text>
            </CrossSection.Row>
            <CrossSection.Row>
              <Text>Something went wrong. Contact support@posh.vip</Text>
            </CrossSection.Row>
          </CrossSection>
        </CrossSection.Section>
      )}

      {isSuccess && (
        <CrossSection.Section className={styles.TicketsCrossSectionContainer}>
          {data.map((crossSection, index) => (
            <CrossSection key={crossSection.title}>
              <div className='flex items-center gap-2'>
                <p className='text-sm font-medium'>{crossSection.title}</p>
                <WithToolTip
                  info={getTooltipText(crossSection.type, isRSVPEvent ?? false, isAllowedTapToPayCountry(groupCountry))}
                  align={index === data.length - 1 ? 'left' : undefined}>
                  <CrossSection.Icon name='info' />
                </WithToolTip>
              </div>

              <h3>{parseNumberValue(crossSection.mainValue)}</h3>

              {crossSection.label && <p className='text-sm text-gray-400'>{crossSection.label}</p>}

              {crossSection.chart && (
                <CrossSection.Row style={{marginTop: 5}}>
                  <CrossSection.Chart chart={crossSection.chart} id={`${crossSection.title}-chart`} />
                </CrossSection.Row>
              )}
            </CrossSection>
          ))}
        </CrossSection.Section>
      )}
    </AnalyticsSection>
  )
}

const getTooltipText = (type: TCrossSectionType, isRSVPEvent: boolean, isPurchasedInPersonEnabled: boolean) => {
  switch (type) {
    case 'scannedTickets':
      return `A breakdown of how many ${
        isRSVPEvent ? 'RSVPs' : 'tickets'
      } have been scanned or used. ${isPurchasedInPersonEnabled ? 'Tickets purchased in-person are automatically scanned at time of purchase.' : ''}`
    case 'totalTicketTypes':
      return `A breakdown of each ticket type that was sold.${isPurchasedInPersonEnabled ? ' Custom amounts created in-person are marked with a "*".' : ''}`
    case 'ticketSourceBreakdown':
      return 'A breakdown of where tickets were sold from.'
    case 'ticketSourceBreakdownRSVP':
      return 'A breakdown of where RSVPs were made from.'
    default:
      return ''
  }
}
