import React from 'react'

import {groupEventsByMultiSessionEvent} from '@posh/model-types'
import {EventPublic} from 'apis/Events/types'
import {EventCard, EventSeriesCard} from 'pages/EventCard'

const UpcomingEvents = ({
  events,
  shouldGroupEventSeries: groupEvents,
}: {
  events: EventPublic[]
  shouldGroupEventSeries?: boolean
}) => {
  events.shift()

  const groupedEvents = groupEvents
    ? groupEventsByMultiSessionEvent<EventPublic, NonNullable<EventPublic['multiSessionEvent']>>(events)
    : events.map(event => ({
        ...event,
        isMultiSessionGroup: false as const,
      }))

  return (
    <>
      <hr />
      <p className='PProfile-subsectionTitle'>Upcoming Events</p>
      <div className='PProfile-upcomingEventsWrapper'>
        {groupedEvents.map((eventGroup, i) => {
          if (eventGroup.isMultiSessionGroup)
            return <EventSeriesCard eventGroup={eventGroup} source='profile' key={i} />
          const event = eventGroup
          return (
            <EventCard
              key={i}
              backgroundImage={event?.flyer}
              url={event?.url}
              timezone={event?.timezone}
              startUtc={event?.startUtc}
              name={event?.name}
              venueName={event?.venue?.name}
              multiSessionEvent={event?.multiSessionEvent}
              source='profile'
            />
          )
        })}
      </div>
    </>
  )
}

export default UpcomingEvents
