import React from 'react'

import {TGetGroupForFinancialsOutput} from 'apis/Groups/useGetGroupForFinancials'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import PoshInfo from 'components/form/PoshInfo'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'

import {FinanceCard} from '../FinanceCard'

interface Balances {
  availableBalance: string
  pendingBalance: string
  instantAvailableFormatBalance: string
  instantAvailableBalance: string
}

interface BalancesCardProps {
  group?: TGetGroupForFinancialsOutput
  balances: Balances
  setIsShowingTransferBalance?: React.Dispatch<React.SetStateAction<boolean>>
}

const BalancesCard = (props: BalancesCardProps) => {
  const {trackEvent} = useMixpanel()
  const {group, balances, setIsShowingTransferBalance} = props
  const {availableBalance, pendingBalance, instantAvailableBalance, instantAvailableFormatBalance} = balances
  const canTransferBalance = group?.manualPayouts && group.canInitiatePayouts && !!setIsShowingTransferBalance
  const shouldShowInstantAvailableBalance = instantAvailableBalance !== '0' && group?.manualPayouts
  return (
    <FinanceCard title={'Balance'} animationIndex={1}>
      <table className='Card-statTable'>
        <tr>
          <td>Available</td>
          <td>{availableBalance}</td>
        </tr>
        <tr>
          <td>
            Pending <PoshInfo info='These funds are currently in transit to your Posh balance.' />
          </td>
          <td>{pendingBalance}</td>
        </tr>
        {shouldShowInstantAvailableBalance && (
          <tr>
            <td style={{display: 'flex'}}>
              Instant Available{' '}
              <PoshInfo info='These funds are available for instant payout to your bank account. To initiate an instant payout, click the Transfer Balance button below.' />
            </td>
            <td>{instantAvailableFormatBalance}</td>
          </tr>
        )}
        {canTransferBalance && (
          <RequirePermissions requiredPermissions={[requirePermissionAndScope('transfer_current_balance', ['group'])]}>
            <tr>
              <td colSpan={2}>
                <Button
                  className='fullWidth'
                  onClick={() => {
                    trackEvent('Transfer Balance- Curator Finance Page', {availableBalance})
                    setIsShowingTransferBalance(true)
                  }}>
                  Transfer Balance
                </Button>
              </td>
            </tr>
          </RequirePermissions>
        )}
      </table>
    </FinanceCard>
  )
}

export default BalancesCard
