import React, {useState} from 'react'
import ReactCodeInput from 'react-code-input'

import {useCheckLoginCode} from 'apis/Auth/useCheckLoginCode'
import {useCheckSignupCode} from 'apis/Auth/useCheckSignupCode'
import Button from 'components/form/Button'

interface VerificationCodeInputProps {
  phone: string
  goBack: () => void
  isMobile: boolean
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  hasExistingAccount: boolean
  needsToConfirmAccount: boolean
  signupCallback?: (token: string) => Promise<void>
  goBackOnError?: boolean
}

const TOKEN_LENGTH = 6

export const VerificationCodeInput = (props: VerificationCodeInputProps) => {
  const {
    phone,
    goBack,
    isMobile,
    hasExistingAccount,
    setErrorMessage,
    needsToConfirmAccount,
    signupCallback,
    goBackOnError,
  } = props
  const {mutateAsync: checkLoginCode, isLoading: isLoginLoading} = useCheckLoginCode(phone)
  const {mutateAsync: checkSignupCode, isLoading: isSignupLoading} = useCheckSignupCode()
  const [token, setToken] = useState('')
  const urlParams = new URLSearchParams(window.location.search)
  const redirectUrlFromParams = urlParams.get('r')

  const phoneStyle: React.CSSProperties = {
    fontSize: isMobile ? '16px' : '19px',
    padding: isMobile ? '12px 12px' : '14px 15px',
    border: '1px solid #303133',
    borderRadius: '5px',
    background: '0 0',
    color: 'white',
    textAlign: 'center',
    margin: isMobile ? '4px 3px' : '7px 4px',
    width: '100%',
  }

  const onVerify = async () => {
    setErrorMessage('')
    if (token.length !== 6) {
      setErrorMessage('Please input a valid verification code')
    }
    try {
      if (hasExistingAccount === false) {
        const {token: signupToken} = await checkSignupCode({phone, token})
        if (signupCallback) {
          await signupCallback(signupToken)
        } else {
          window.location.href = `/signup?token=${signupToken}`
        }
      } else if (needsToConfirmAccount === true) {
        const account = await checkLoginCode({phone, token, setSessionOnSuccess: false})
        let href = `/confirm-account/${account.userId}`
        if (redirectUrlFromParams) href += `?r=${encodeURIComponent(redirectUrlFromParams)}`
        window.location.href = href
      } else {
        await checkLoginCode({phone, token})
      }
    } catch (error: any) {
      setErrorMessage(error.response.data.error as string)
      if (goBackOnError) {
        goBack()
      }
    }
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        if (token.length === TOKEN_LENGTH) onVerify()
      }}>
      <div className='LoginPage-form-back' onClick={() => goBack()}>
        &larr; Back
      </div>
      {hasExistingAccount && !!signupCallback && <p>An account already exists for {phone}.</p>}
      <p>
        Enter the verification code sent to {phone}
        {hasExistingAccount ? ' to login to your Posh account' : ' to create your Posh account'}
      </p>
      <ReactCodeInput
        type='number'
        fields={TOKEN_LENGTH}
        name='phoneCode'
        className='LoginPage-codeInput'
        inputMode={'tel'}
        inputStyle={phoneStyle}
        value={token}
        autoFocus
        onChange={e => setToken(e)}
      />
      <Button
        className='center fullWidth'
        disabled={isLoginLoading || isSignupLoading || token.length < TOKEN_LENGTH}
        type='submit'>
        {hasExistingAccount && !needsToConfirmAccount ? 'Login' : 'Join Posh'}
      </Button>
    </form>
  )
}
