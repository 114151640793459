import {useEffect, useRef, useState} from 'react'

import {ExperimentKey} from '@posh/model-types'
import {GetAccountOptInsInput, useGetAccountOptIns} from 'apis/Optins/useGetAccountOptIns'
import {updateMarketingSmsOptIn, useUpdateAccountOptIn} from 'apis/Optins/useUpdateAccountOptIn'
import useSessionContext from 'domains/Auth/SessionContext'
import {useExperiment} from 'hooks/useExperiment'

import OptinModalPresentation from './OptinModalPresentation'

const DELAY = 1000

export type OptinModalProps = {
  entityDisplayName: string
  location: 'account-profile' | 'group-profile' | 'event-page'
}

export default function OptinModalContainer({
  entityDisplayName,
  location,
  onClose,
}: OptinModalProps & {
  onClose: () => void
}) {
  const [open, setOpen] = useState(false)

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const {currentUser} = useSessionContext()

  const query: GetAccountOptInsInput = {optInType: 'posh-sms', fields: ['optIns']}
  const {mutate: updateAccountOptIns} = useUpdateAccountOptIn({location, optInPopUpExperiment: true, query})
  const {
    data: accountOptInsResponse,
    isError: isErrorGettingAccountOptIns,
    isLoading: isGettingAccountOptIns,
  } = useGetAccountOptIns(query)

  const accountSms = accountOptInsResponse?.data.find(optIn => optIn.optInType === 'posh-sms')
  const marketingSmsValue = accountSms?.optInType === 'posh-sms' && accountSms?.optIns?.marketing?.smsOptIn

  const {value: isFollowingUsersOptInPopUpExperimentEnabled} = useExperiment({
    experimentKey: ExperimentKey.FOLLOWING_USERS_OPT_IN_POP_UP_EXPERIMENT_WEB,
    enabled: !!currentUser,
  })

  useEffect(() => {
    if (isGettingAccountOptIns || isErrorGettingAccountOptIns) {
      return
    } else if (isFollowingUsersOptInPopUpExperimentEnabled && marketingSmsValue === 'no-application') {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
      timerRef.current = setTimeout(() => {
        setOpen(true)
      }, DELAY)
    } else {
      setOpen(false)
      onClose()
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [
    isFollowingUsersOptInPopUpExperimentEnabled,
    isGettingAccountOptIns,
    isErrorGettingAccountOptIns,
    marketingSmsValue,
  ])

  useEffect(() => {
    return () => {
      if (timerRef?.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
      setOpen(false)
      onClose()
    }
  }, [])

  const handleOptIn = () => {
    if (accountSms?.optInType === 'posh-sms' && marketingSmsValue === 'no-application') {
      updateAccountOptIns(updateMarketingSmsOptIn(accountSms, true))
    }
    setOpen(false)
    onClose()
  }

  const onOpenChange = (isOpen: boolean) => {
    setOpen(isOpen)
    if (!isOpen) {
      setTimeout(() => {
        onClose()
      }, 500)
    }
  }

  return (
    <OptinModalPresentation
      open={open}
      entityDisplayName={entityDisplayName}
      onOptIn={handleOptIn}
      onOpenChange={onOpenChange}
    />
  )
}
