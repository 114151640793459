import React from 'react'
import {Link} from 'react-router-dom'

import {ScrollArea, ScrollBar} from '@posh/design-kit/components/scroll-area'
import {LONG_DAY_SHORT_DATE_FORMAT, TIME_12H_FORMAT} from '@posh/model-types'
import {EventModel} from 'apis/Events/types'
import {useGetNextMultiSessionEvents} from 'apis/MultiSessionEvents/useGetNextMultiSessionEvents'
import {PlusInCircle} from 'components/assets/Icons'
import moment from 'moment-timezone'
import {EventSeriesSignifier} from 'pages/EventCard'
import {useEventPageContext} from 'pages/EventPage/EventPageContext/EventPageContext'
interface MultiSessionMoreDatesSignifierProps {
  mse: EventModel['multiSessionEvent']
  eventId: string
}

export function MultiSessionMoreDatesSignifier({mse, eventId}: MultiSessionMoreDatesSignifierProps) {
  const {palette} = useEventPageContext()
  const {data: nextEvents} = useGetNextMultiSessionEvents({
    eventId,
    id: mse?.id,
    limit: 5,
  })

  if (!mse || nextEvents?.length === 0) return null

  return (
    <>
      <ScrollArea scrollHideDelay={1000}>
        <div className='flex gap-4 pb-4'>
          {nextEvents?.map(event => (
            <div
              key={event._id}
              className='h-64 w-64 flex-none justify-between rounded-lg p-3 shadow-sm transition-shadow'
              style={{backgroundColor: palette.backgroundLighter}}>
              <div className='flex h-full flex-col justify-between'>
                <div className='flex flex-col gap-2'>
                  <Link
                    to={`/e/${event.url}`}
                    className='no-underline hover:underline'
                    style={{color: palette.textPrimary}}>
                    <p className='noMargin text-2xl! font-light! tracking-tight' style={{color: palette.textPrimary}}>
                      {moment(event.startUtc).format(LONG_DAY_SHORT_DATE_FORMAT)}
                    </p>
                  </Link>
                  <p className='noMargin text-sm! font-light! tracking-tight' style={{color: palette.textSecondary}}>
                    {moment(event.startUtc).tz(event.timezone).format(TIME_12H_FORMAT).toLowerCase()} -{' '}
                    {moment(event.endUtc).tz(event.timezone).format(TIME_12H_FORMAT).toLowerCase() +
                      (event.timezone !== moment.tz.guess() ? ` (${moment.tz(event.timezone).zoneAbbr()})` : '')}
                  </p>
                </div>
                <div className='flex flex-col items-start gap-2'>
                  <p className='noMargin text-sm! font-light! tracking-tight' style={{color: palette.textSecondary}}>
                    {event.name}
                  </p>
                  <Link
                    onClick={() => window.scrollTo({top: 0, behavior: 'instant'})}
                    to={`/e/${event.url}`}
                    className='flex cursor-pointer flex-row items-center gap-1 rounded-md p-1.5 text-xs! tracking-tight no-underline select-none'
                    style={{
                      color: palette.button.textActivePrimary,
                      backgroundColor: palette.button.backgroundActivePrimary,
                    }}>
                    <PlusInCircle className='h-3 w-3' />
                    Get Tickets
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <ScrollBar orientation='horizontal' />
      </ScrollArea>
      <div className='mt-2 flex'>
        <EventSeriesSignifier mse={mse} source='event_page' text='View All' />
      </div>
    </>
  )
}
