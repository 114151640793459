import {GroupDisputesReturn} from 'apis/Groups/useFetchGroupDisputes'
import {Award} from 'lucide-react'

interface TimelineEventProps {
  title: string
  date: string
  status?: 'default' | 'success' | 'error'
}

const TimelineEvent = ({title, date, status = 'default'}: TimelineEventProps) => {
  const getStatusColor = () => {
    switch (status) {
      case 'success':
        return 'bg-green-600'
      case 'error':
        return 'bg-red-600'
      default:
        return 'bg-blue-600'
    }
  }

  return (
    <div className='flex items-start gap-2'>
      <div className={`h-4 w-4 rounded-full ${getStatusColor()} mt-1`}></div>
      <div>
        <div className='text-sm font-medium'>{title}</div>
        <div className='text-xs text-gray-400'>{date}</div>
      </div>
    </div>
  )
}

interface DisputeTimelineProps {
  selectedDisputeData: GroupDisputesReturn[0] | null
}

const DisputeTimeline = ({selectedDisputeData}: DisputeTimelineProps) => {
  if (!selectedDisputeData) return null

  // Format currency with thousands separator
  const formatCurrency = (amount: number): string => {
    return `$${amount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`
  }

  // Calculate total cost if dispute was lost
  const showDisputeFee = selectedDisputeData.status === 'lost'
  const showCoveredFee = selectedDisputeData.status === 'won'
  const disputeFee = 15.0 // Posh dispute fee
  const totalCharged = showDisputeFee ? selectedDisputeData.amount + disputeFee : selectedDisputeData.amount

  return (
    <div className='space-y-4'>
      <div className='text-sm'>
        <div className='mb-1 text-xs text-gray-400'>Status</div>
        <div className='font-medium'>
          {selectedDisputeData.status === 'under_review' || selectedDisputeData.status === 'warning_under_review'
            ? 'Under Review'
            : selectedDisputeData.status === 'won'
              ? 'Won'
              : selectedDisputeData.status === 'lost'
                ? 'Lost'
                : 'Pending'}
        </div>
      </div>

      {/* Display positive message for won disputes */}
      {showCoveredFee && (
        <div className='rounded-md border border-green-700/30 bg-green-900/20 p-3 text-sm'>
          <div className='mb-2 flex items-center gap-2'>
            <Award className='h-5 w-5 text-green-300' />
            <h4 className='font-medium text-green-300'>Dispute Fee Covered by Posh</h4>
          </div>
          <div className='space-y-1'>
            <div className='flex justify-between text-sm'>
              <span>Transaction Amount:</span>
              <span>{formatCurrency(selectedDisputeData.amount)}</span>
            </div>
            <div className='flex justify-between text-sm'>
              <span>Dispute Fee (Waived):</span>
              <span>{formatCurrency(disputeFee)}</span>
            </div>
            <div className='mt-2 flex justify-between border-t border-green-700/30 pt-2 text-sm font-medium'>
              <span>You Keep:</span>
              <span>{formatCurrency(selectedDisputeData.amount)}</span>
            </div>
          </div>
        </div>
      )}

      {/* Display amount and dispute fee information for lost disputes */}
      {showDisputeFee && (
        <div className='rounded-md border border-red-700/30 bg-red-900/20 p-3 text-sm'>
          <h4 className='mb-2 font-medium text-red-300'>Dispute Resolution Fee</h4>
          <div className='space-y-1'>
            <div className='flex justify-between text-sm'>
              <span>Transaction Amount:</span>
              <span>{formatCurrency(selectedDisputeData.amount)}</span>
            </div>
            <div className='flex justify-between text-sm'>
              <span>Dispute Fee (Charged by Posh):</span>
              <span>{formatCurrency(disputeFee)}</span>
            </div>
            <div className='mt-2 flex justify-between border-t border-red-700/30 pt-2 text-sm font-medium'>
              <span>Total Charged:</span>
              <span>{formatCurrency(totalCharged)}</span>
            </div>
          </div>
        </div>
      )}

      <div className='text-sm'>
        <div className='mb-1 text-xs text-gray-400'>Timeline</div>
        <div className='mt-2 space-y-3'>
          <TimelineEvent title='Dispute Received' date={new Date(selectedDisputeData.createdAt).toLocaleString()} />
          {selectedDisputeData.status === 'under_review' || selectedDisputeData.status === 'warning_under_review' ? (
            <TimelineEvent title='Under Review' date={new Date().toLocaleString()} />
          ) : null}
          {(selectedDisputeData.status === 'won' || selectedDisputeData.status === 'lost') && (
            <TimelineEvent
              title={`Dispute ${selectedDisputeData.status === 'won' ? 'Won' : 'Lost'}`}
              date={new Date().toLocaleString()}
              status={selectedDisputeData.status === 'won' ? 'success' : 'error'}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default DisputeTimeline
