import {GetMultiSessionEventOutput} from 'apis/MultiSessionEvents/useGetMultiSessionEvent'
import {groupBy} from 'lodash'
import moment from 'moment'

export function bucketEventsByDate(events: GetMultiSessionEventOutput['events']) {
  // Group events by month/year
  const grouped = groupBy(events, event => {
    const eventEnd = moment.tz(event.endUtc, event.timezone)
    const now = moment().tz(event.timezone)
    const hasEnded = eventEnd.isBefore(now)
    if (hasEnded) {
      return 'Past'
    }

    const isThisYear = now.isSame(eventEnd, 'year')
    const eventStart = moment.tz(event.startUtc, event.timezone)
    return eventStart.format(isThisYear ? 'MMMM' : 'MMMM YYYY')
  })

  // Get sorted keys based on the actual dates
  const sortedKeys = Object.keys(grouped).sort((a, b) => {
    if (a === 'Past') return 1
    if (b === 'Past') return -1
    const dateA = moment(grouped[a][0].startUtc)
    const dateB = moment(grouped[b][0].startUtc)
    return dateA.valueOf() - dateB.valueOf()
  })

  // Return array of {group, events} objects
  return sortedKeys.map(group => ({
    date: group,
    events: grouped[group],
  }))
}
