import React from 'react'

import generateInstagramUrl from 'apis/Util/generateInstagramUrl'
import {FilledChat, FilledPhone, Instagram} from 'components/assets/Icons'

interface AttendeeRowSocialsProps {
  instagramUrl?: string
  phoneNumber?: string
}

const AttendeeRowSocials = ({instagramUrl, phoneNumber}: AttendeeRowSocialsProps) => {
  const igLink = generateInstagramUrl(instagramUrl ?? '')
  return (
    <div className='AttendeesTable-socials'>
      {instagramUrl && (
        <a href={igLink} target='_blank' rel='noreferrer'>
          <Instagram className='AttendeesTable-icon' />
        </a>
      )}
      {phoneNumber && (
        <>
          <a href={`sms://${phoneNumber}`} target='_blank' rel='noreferrer'>
            <FilledChat className='AttendeesTable-icon' />
          </a>
          <a href={`tel:${phoneNumber}`} target='_blank' rel='noreferrer'>
            <FilledPhone className='AttendeesTable-icon' />
          </a>
        </>
      )}
    </div>
  )
}

export default AttendeeRowSocials
