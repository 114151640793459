import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type PreviewCartBreakdownOptions = ReactQueryOptions['events']['previewCartBreakdown']
type PreviewCartBreakdownInput = RouterInputs['events']['previewCartBreakdown']
export type PreviewCartBreakdownOutput = RouterOutput['events']['previewCartBreakdown']

export function usePreviewCartBreakdown(input: PreviewCartBreakdownInput, opts?: PreviewCartBreakdownOptions) {
  return trpc.events.previewCartBreakdown.useQuery(input, {
    enabled: input.items.length > 0,
    ...opts,
  })
}
