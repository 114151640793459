import React, {PropsWithChildren, useEffect, useState} from 'react'

import classNames from 'classnames'
import {PlainX} from 'components/assets/Icons'
import useComponentButtonListener from 'components/helpers/componentButtonListener'

import styles from './Panel.module.scss'

// THIS SHOULD MATCH THE TRANSITION DURATION IN Panel.module.scss
const PANEL_TRANSITION_DURATION_MS = 250

export interface PanelProps {
  isOpen: boolean
  onClose: () => void
  title?: string

  // OPTIONS
  closeOnEsc?: boolean
  closeOnOverlayClick?: boolean
  backgroundStyle?: React.CSSProperties
  headerStyle?: React.CSSProperties
  panelStyle?: React.CSSProperties

  // Class names
  slideUp?: boolean
}

export const Panel = (props: PropsWithChildren<PanelProps>) => {
  const {
    isOpen,
    onClose,
    title,
    closeOnEsc = true,
    closeOnOverlayClick = true,
    children,
    backgroundStyle,
    headerStyle,
    panelStyle,
    slideUp,
  } = props

  useComponentButtonListener('Escape', () => (closeOnEsc ? onClose() : undefined))

  const [isPanelRendered, setIsPanelRendered] = useState(isOpen)
  const [isPanelOpen, setIsPanelOpen] = useState(isOpen)

  useEffect(() => {
    if (isOpen) {
      setIsPanelRendered(true)
      // This is a hack to make sure the panel is rendered before the transition starts.
      // Otherwise, the transition won't work.
      setTimeout(() => setIsPanelOpen(true), 0)
    } else {
      setTimeout(() => setIsPanelRendered(false), PANEL_TRANSITION_DURATION_MS)
      setIsPanelOpen(false)
    }
  }, [isOpen])

  const handleOverlayClick = () => onClose()

  if (!isPanelRendered) return null

  return (
    <>
      <div
        className={classNames(styles.Overlay, {[styles.open]: isPanelOpen})}
        style={backgroundStyle}
        onClick={closeOnOverlayClick ? handleOverlayClick : undefined}
      />
      <div
        className={classNames(styles.Panel, {[styles.slideUp]: slideUp, [styles.open]: isPanelOpen})}
        style={panelStyle}>
        <div className={styles.Header} style={headerStyle}>
          {title && <p className='m-0'>{title}</p>}
          <PanelCloseButton onClick={() => onClose()} />
        </div>
        <div className={styles.Content}>{children}</div>
      </div>
    </>
  )
}

const DEFAULT_ICON_SIZE = 14
const PanelCloseButton = (props: {onClick: () => void}) => {
  return (
    <PlainX
      width={DEFAULT_ICON_SIZE}
      height={DEFAULT_ICON_SIZE}
      color={'#C0C1C3'}
      cursor='pointer'
      onClick={props.onClick}
    />
  )
}
