import React from 'react'

import {GetMultiSessionEventOutput} from 'apis/MultiSessionEvents/useGetMultiSessionEvent'
import classNames from 'classnames'

import {bucketEventsByDate} from '../../util/groupEventsByDate'
import {MultiSessionEventsPublicListItem} from '../MultiSessionEventsPublicListItem'

import styles from './styles.module.scss'

export function MultiSessionEventsPublicDateGroups(props: {
  events: GetMultiSessionEventOutput['events']
  isEditing: boolean
  groupId?: string
  domain?: string
  mseId?: string
  trackingLink?: string
}) {
  const {events, isEditing, groupId, domain, mseId, trackingLink} = props
  const groupedEvents = bucketEventsByDate(events)
  return (
    <div>
      {groupedEvents.map(({date, events}) => (
        <div key={date} className={classNames(styles.dateGroup, 'border-t-1 border-zinc-700')}>
          <h4>
            {date} <span className='text-zinc-500'>({events.length})</span>
          </h4>
          {events.map(event => (
            <MultiSessionEventsPublicListItem
              key={event._id}
              event={event}
              isEditing={isEditing}
              groupId={groupId}
              domain={domain}
              mseId={mseId}
              trackingLink={trackingLink}
            />
          ))}
        </div>
      ))}
    </div>
  )
}
