import {RefundRequestForm} from '@posh/model-types'
import {Types} from 'mongoose'

export enum RefundStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  DENIED = 'denied',
}

export interface EventOwners {
  firstName: string
  lastName: string
  email: string
  phone: string
}

interface ModalProps {
  isOpen: boolean
  onClose: () => void
}

export interface ContactInformationProps extends ModalProps {
  owners: EventOwners[]
}

export type RequestDetailsProps = ModalProps & RefundRequestForm

export interface PoshAdminNameProps {
  firstName: string
  lastName: string
  username: string
  avi: string
}

interface RefundRequestEvent {
  name: string
  url: string
  gmv: number
}

interface RefundRequestGroup {
  name: string
  payoutsDisabled?: boolean
}

export interface FinishedRequests {
  _id: Types.ObjectId
  status: string
  responder: Types.ObjectId
  respondedAt: Date
  event: RefundRequestEvent
  group: RefundRequestGroup
  poshAdmin: PoshAdminNameProps
}

export interface FinishedTableProps {
  action: string
  refunds: FinishedRequests[]
}

export type PendingRequests = {
  _id: Types.ObjectId
  eventId: Types.ObjectId
  event: RefundRequestEvent
  group: RefundRequestGroup
  owners: EventOwners[]
} & RefundRequestForm

export interface PendingTableProps {
  refunds: PendingRequests[]
}

interface ConfirmationModalText {
  bodyText: string
  title: string
  confirmationMessage: string
  confirmButtonText: string
}

interface ConfirmationModalClicks {
  onClick: () => void
  onClose: () => void
}

type ModalOpenHandler = (flag: boolean) => void

interface ConfirmationModalStateHandlers {
  text: (confirmationModalText: ConfirmationModalText) => void
  click: (ConfirmationModalClicks: ConfirmationModalClicks) => void
  open: ModalOpenHandler
}

interface ContactModalStates {
  setContactOwners: (owners: EventOwners[]) => void
  setIsContactModalOpen: ModalOpenHandler
}

type RequestDetails = RefundRequestForm

interface RequestDetailsStates {
  setIsRequestDetailsModalOpen: ModalOpenHandler
  setRequestDetails: (requestDetails: RequestDetails) => void
}

export interface PendingDynamicBodyProps {
  refund: PendingRequests
  confirmationModalStates: ConfirmationModalStateHandlers
  contactModalStates: ContactModalStates
  requestDetailsStates: RequestDetailsStates
}
