// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimePeriodOption__NcwaD{padding:6px 12px;border-radius:8px;border-color:#333}.TimePeriodOption__NcwaD.highlighted__f8gXY{background-color:#222;font-weight:500;border:1px solid #222 !important;color:#fff !important}", "",{"version":3,"sources":["webpack://./src/pages/EventManagementPages/OverviewUpdate/Analytics/Overview/TimePeriodOption.module.scss"],"names":[],"mappings":"AAEA,yBACE,gBAAA,CACD,iBAAA,CACA,iBAAA,CAEC,4CACE,qBAAA,CACA,eAAA,CACA,gCAAA,CACA,qBAAA","sourcesContent":["@import '@global-styles/color_pallete';\n\n.TimePeriodOption {\n  padding: 6px 12px;\n\tborder-radius: 8px;\n\tborder-color: #333;\n\n  &.highlighted {\n    background-color: #222;\n    font-weight: 500;\n    border: 1px solid #222 !important;\n    color: white !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TimePeriodOption": "TimePeriodOption__NcwaD",
	"highlighted": "highlighted__f8gXY"
};
export default ___CSS_LOADER_EXPORT___;
