import {useFollowAccount} from 'apis/Social/accounts/useFollowAccount'
import {useFollowGroup} from 'apis/Social/accounts/useFollowGroup'
import {useUnfollowAccount} from 'apis/Social/accounts/useUnfollowAccount'
import {useUnfollowGroup} from 'apis/Social/accounts/useUnfollowGroup'
import {ModalType} from 'components/modals/ModalController'
import {useModal} from 'providers/ModalProvider'

import {TProfileType} from '../types'

export function useHandleProfileFollow(
  type: Omit<TProfileType, 'venue'>,
  location: 'account-profile' | 'group-profile' | 'event-page',
  name: string,
) {
  const {openModal} = useModal()

  const {mutateAsync: followAccount, isLoading: isFollowingAccount} = useFollowAccount({
    onSuccess: () => {
      openModal(ModalType.OPT_IN_MODAL, {
        entityDisplayName: name,
        location,
      })
    },
  })
  const {mutateAsync: followGroup, isLoading: isFollowingGroup} = useFollowGroup({
    onSuccess: () => {
      openModal(ModalType.OPT_IN_MODAL, {
        entityDisplayName: name,
        location,
      })
    },
  })
  const {mutateAsync: unfollowAccount, isLoading: isUnfollowingAccount} = useUnfollowAccount()
  const {mutateAsync: unfollowGroup, isLoading: isUnfollowingGroup} = useUnfollowGroup()

  const toggleFollowProfile = (id: string, isFollowed: boolean) => {
    if (type === 'account') {
      isFollowed ? unfollowAccount({accountId: id}) : followAccount({accountId: id})
    } else {
      isFollowed ? unfollowGroup({groupId: id}) : followGroup({groupId: id})
    }
  }

  const isLoading = isFollowingAccount || isFollowingGroup || isUnfollowingAccount || isUnfollowingGroup

  return {toggleFollowProfile, isLoading}
}
