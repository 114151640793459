import React, {useEffect, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {ITicketGroup} from 'apis/Events/types'
import {GetAllEventTicketsOutput} from 'apis/Events/useGetAllEventTickets'
import {useUpdateTicketGroups} from 'apis/Events/useUpdateTicketGroups'
import {useMixpanel} from 'apis/MixPanelHandler'
import {CRUDTable} from 'components/TableUI/CRUDTable/CRUDTable'
import {ColumnConfig} from 'components/TableUI/CRUDTable/internals/types'
import {TableCell} from 'components/TableUI/TableCell/TableCell'
import useDragAndReorder from 'hooks/useDragAndReorder'
import {useResourcePageParams} from 'pages/PoshAppLayout'

interface TicketGroupTableProps {
  ticketGroups?: ITicketGroup[]
  ticketTypes: GetAllEventTicketsOutput
  refetch: () => void
  eventStatus: string
  lastUpdated: number
}

export const TicketGroupTable = (props: TicketGroupTableProps) => {
  const {ticketGroups, refetch, ticketTypes, eventStatus, lastUpdated} = props
  const [orderedTicketGroups, setOrderedTicketGroups] = useState(ticketGroups ?? [])
  const {eventId, domain, groupId} = useResourcePageParams()
  const {mutateAsync: updateTicketGroups} = useUpdateTicketGroups()
  const navigate = useNavigate()
  const {onDragEnd} = useDragAndReorder(orderedTicketGroups, setOrderedTicketGroups)
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  useEffect(() => {
    updateTicketGroups({eventId: eventId!, ticketGroups: orderedTicketGroups})
    refetch()
  }, [orderedTicketGroups])

  const formattedTicketGroups = useMemo(() => {
    return orderedTicketGroups.map((ticketGroup, index) => {
      const {ticketTypes: ticketTypesInTicketGroup} = ticketGroup
      const ticketsInTicketGroup = ticketTypes.filter(ticketType =>
        ticketTypesInTicketGroup.includes(ticketType.id.toString()),
      )
      return {
        name: ticketGroup.name,
        totalTickets: ticketGroup.ticketTypes.length,
        tickets: ticketsInTicketGroup.map(ticket => ticket.name),
        _id: ticketGroup._id,
      }
    })
  }, [orderedTicketGroups])

  const allTicketTypes = ticketTypes.map(ticketType => ticketType.name)

  const navigateToCreateTicketGroup = () => {
    trackMixpanelEvent('Create Ticket Group- Curator Event Ticketing', {eventStatus})
    navigate(`/${domain}/groups/${groupId}/events/${eventId}/tickets/create-ticket-group`)
  }

  const onClickRow = (row: (typeof formattedTicketGroups)[0]) => {
    navigate(`/${domain}/groups/${groupId}/events/${eventId}/tickets/${row._id}/edit-ticket-group`)
  }

  const columnConfigs = useMemo<ColumnConfig<(typeof formattedTicketGroups)[0]>[]>(() => {
    return [
      {
        key: 'name',
        header: 'Ticket Group Name',
        render: name => <TableCell.Text text={name} />,
      },
      {
        key: 'totalTickets',
        header: 'Total Ticket Types',
        render: totalTickets => <TableCell.Text text={totalTickets.toString()} />,
      },
      {
        key: 'tickets',
        header: 'Tickets',
        render: tickets => (
          <TableCell.MultiOption allOptions={allTicketTypes} selectedOptions={tickets} getText={ticket => ticket} />
        ),
      },
    ]
  }, [formattedTicketGroups])

  return (
    <div className='eventSection'>
      <CRUDTable
        resourceName='Ticket Group'
        data={formattedTicketGroups}
        columns={columnConfigs}
        itemsPerPage={formattedTicketGroups.length}
        searchableColumn={'name'}
        createButton={{
          title: '+ Create Ticket Group',
          onClick: navigateToCreateTicketGroup,
        }}
        onClickRow={onClickRow}
        lastUpdated={lastUpdated}
        onReorderEnd={onDragEnd}
        refresh={refetch}
        EmptyComponent={() => (
          <p className='emptySection'>
            Group your tickets by tier, status, availability, etc. This can be useful when you have a large number of
            ticket types.
          </p>
        )}
      />
    </div>
  )
}
