import React, {useEffect, useState} from 'react'
import {Control, useController} from 'react-hook-form'
import {usePrevious} from 'react-use'

import {useGetImageColors} from 'apis/Util/useGetImageColors'
import {PictureState} from 'components/form/ImagePicker'
import {ImageSearchModal} from 'components/form/ImageSearchModal'
import {EventVisualsFlyer} from 'components/PageComponents/EventVisuals/Form/image/Flyer'
import {EventVisualsSpotifySearchInput} from 'components/PageComponents/EventVisuals/Form/Input/SpotifySearchInput'
import {EventVisualsDesignModal} from 'components/PageComponents/EventVisuals/Modals/Design'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {useToast} from 'components/toasts/ToastProvider'
import {useUploadImage} from 'hooks/uploadImage/useUploadImage'

import {useEventVisualsContext} from '../../context/EventVisualsContext'

interface EventDetailsFormSectionProps {
  control: Control<EventVisualsForm>
}

const IMAGE_SEARCH_MODAL_WIDTH = 780

export const EventFlyerColorSongSection = (props: EventDetailsFormSectionProps) => {
  const {control} = props
  const {showToast} = useToast()
  const {palette, flyerPlaceholder} = useEventVisualsContext()
  const [isDesignModalOpen, setIsDesignModalOpen] = useState(false)
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false)

  const {
    field: {onChange: setFlyerOnForm, value: flyer},
  } = useController({
    control,
    name: 'flyer',
  })
  const {
    field: {onChange: setFlyerIdOnForm},
  } = useController({
    control,
    name: 'flyerImageId',
  })
  const {
    field: {onChange: onChangeSong, value: song},
  } = useController({
    control,
    name: 'song',
  })
  const {
    field: {onChange: onChangeAccentColor},
  } = useController({
    control,
    name: 'accentColor',
  })

  const {refetch} = useGetImageColors({imageUrl: flyer!}, {enabled: false})

  const previousFlyer = usePrevious(flyer)

  // Only update the accentColor when the flyer changes.
  useEffect(() => {
    if (flyer && previousFlyer && flyer !== previousFlyer) {
      refetch().then(({data: imageColors}) => {
        if (imageColors) onChangeAccentColor(imageColors.vibrant)
      })
    }
  }, [flyer, previousFlyer])

  const {uploadImage: uploadAndSetOnForm, isUploading} = useUploadImage({
    imageType: 'event-flyer',
    onSuccess: (imageId, imageUrl) => {
      setFlyerOnForm(imageUrl)
      setFlyerIdOnForm(imageId)
    },
    onError: () => {
      showToast({type: 'error', title: 'There was an error uploading the image, please select another one'})
    },
  })

  const openDesignModal = () => {
    setIsDesignModalOpen(true)
  }

  const closeDesignModal = () => {
    setIsDesignModalOpen(false)
  }

  const closeImagePickerModal = () => {
    setIsImagePickerOpen(false)
  }

  const openImagePickerModal = () => {
    setIsImagePickerOpen(true)
  }

  const onImageChange = (img: PictureState) => {
    if (img.file) return uploadAndSetOnForm(img.file)
    else return setFlyerOnForm(img.url)
  }

  return (
    <div className='mt-10 flex size-full justify-end'>
      <EventVisualsFlyer
        image={flyer}
        flyerPlaceholder={flyerPlaceholder}
        lightMode={palette.lightmode}
        accentColor={palette.accentColor}
        openDesignModal={openDesignModal}
        openImagePicker={openImagePickerModal}
        isUploadingImage={isUploading}>
        <EventVisualsSpotifySearchInput
          onChooseSong={onChangeSong}
          song={song ?? null}
          lightMode={palette.lightmode}
          accentColor={palette.accentColor}
        />
      </EventVisualsFlyer>
      {isImagePickerOpen && (
        <ImageSearchModal
          setFile={onImageChange}
          isOpen={isImagePickerOpen}
          onClose={closeImagePickerModal}
          width={IMAGE_SEARCH_MODAL_WIDTH}
        />
      )}
      {isDesignModalOpen && (
        <EventVisualsDesignModal control={control} isOpen={isDesignModalOpen} onClose={closeDesignModal} />
      )}
    </div>
  )
}
