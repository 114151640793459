import React from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'

import {useGetMultiSessionEvent} from 'apis/MultiSessionEvents/useGetMultiSessionEvent'
import {useTrackMultiSessionEventPageView} from 'apis/MultiSessionEvents/useTrackMultiSessionEventPageVIew'
import {PoshRoutes} from 'models/PoshRoutes'
import {z} from 'zod'

import {MultiSessionEventsPage} from './page'

const multiSessionEventPageShortUrlSchema = z.object({
  mseHexUrl: z.string(),
  trackingLinkValue: z.string().optional().nullable(),
})

function useMultiSessionEventPageShortUrlParams() {
  const {mseHexUrl} = useParams()
  const [searchParams] = useSearchParams()
  const trackingLinkValue = searchParams.get('t')
  return multiSessionEventPageShortUrlSchema.parse({mseHexUrl, trackingLinkValue})
}

export function useNavigateToMultiSessionEventPageShortUrl() {
  const navigate = useNavigate()
  return (params: z.infer<typeof multiSessionEventPageShortUrlSchema>) =>
    navigate(
      PoshRoutes.MULTI_SESSION_EVENT_PUBLIC_SHORT.replace(':mseHexUrl', params.mseHexUrl) +
        (params.trackingLinkValue ? `?t=${params.trackingLinkValue}` : ''),
    )
}

export function MultiSessionEventsPublicShortUrl() {
  const {mseHexUrl, trackingLinkValue} = useMultiSessionEventPageShortUrlParams()

  const response = useGetMultiSessionEvent({hexUrl: mseHexUrl}, {enabled: !!mseHexUrl})

  useTrackMultiSessionEventPageView({hexUrl: mseHexUrl, trackingLinkValue})

  return <MultiSessionEventsPage response={response} trackingLink={trackingLinkValue ?? undefined} />
}
