import React from 'react'

import {ICrossSection} from 'apis/Analytics/useGetSectionalData'
import {WithToolTip} from 'components/form/PoshInfo'

import {CrossSection} from '../../CrossSection/CrossSection'
import {parseNumberValue} from '../../Shared/utils'

export const AttendeeRetention = ({
  attendeeRetentionCrossSection,
}: {
  attendeeRetentionCrossSection: ICrossSection | undefined
}) => {
  if (!attendeeRetentionCrossSection) return null

  return (
    <CrossSection key={attendeeRetentionCrossSection.title}>
      <CrossSection.Row>
        <p className='text-sm font-medium'>{attendeeRetentionCrossSection.title}</p>
        <WithToolTip
          info={
            'A breakdown of customers attending your event and whether its their first, second, third, or fourth+ purchase. Orders with no accounts are not included.'
          }>
          <CrossSection.Icon name='info' />
        </WithToolTip>
      </CrossSection.Row>
      <h3>{parseNumberValue(attendeeRetentionCrossSection.mainValue)}</h3>
      {attendeeRetentionCrossSection.label && (
        <p className='text-sm text-gray-400'>{attendeeRetentionCrossSection.label}</p>
      )}
      {attendeeRetentionCrossSection.chart && (
        <CrossSection.Row style={{marginTop: 5}}>
          <CrossSection.Chart
            chart={attendeeRetentionCrossSection.chart}
            id={`${attendeeRetentionCrossSection.title}-chart`}
          />
        </CrossSection.Row>
      )}
    </CrossSection>
  )
}
