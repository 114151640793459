import React, {useEffect, useState} from 'react'
import {UseFormReturn} from 'react-hook-form'

import PoshSwitch from 'components/form/PoshSwitch'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {TicketSection} from 'components/PageComponents/EventVisuals/Page/Section/ticketSection'
import {CreateEventTicket} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {useTicketTransferSettings} from 'hooks/useTicketTransferSettings'

import {COLOR_PROPS} from './TicketEditorPanel'

interface TicketPrivacySettingsProps {
  form: UseFormReturn<CreateEventTicket>
}

export const TicketPrivacySettings = (props: TicketPrivacySettingsProps) => {
  const {form} = props
  const {control, watch, setValue} = form

  const approvalRequired = watch('approvalRequired') ?? false
  const isTransferable = watch('isTransferable') ?? true
  const {
    toggleIsTransferable,
    canToggleTransfer,
    shouldRenderTicketTransferSwitch,
    TICKET_TRANSFER_SETTING_LABEL,
    ticketTransferLabelSubtitle,
  } = useTicketTransferSettings({
    ticketHasApprovalRequired: approvalRequired,
    onToggleTransfer: val => {
      setValue('isTransferable', val)
    },
  })
  const password = watch('password')

  const [shouldShowPasswordInput, setShouldShowPasswordInput] = useState(!!password)

  const togglePasswordInput = () => {
    setShouldShowPasswordInput(!shouldShowPasswordInput)
  }

  useEffect(() => {
    if (!shouldShowPasswordInput) {
      form.setValue('password', undefined)
    }
  }, [shouldShowPasswordInput])

  return (
    <TicketSection title='Ticket Privacy Settings' expandable>
      <PoshSwitch
        switchOptions={{
          onChange: () => setValue('approvalRequired', !approvalRequired),
          checked: approvalRequired,
        }}
        rightTitle='Require Approval'
        subtitle='Customers must be approved by you before purchasing this ticket'
      />
      {shouldRenderTicketTransferSwitch && (
        <PoshSwitch
          switchOptions={{
            onChange: toggleIsTransferable,
            checked: isTransferable,
          }}
          rightTitle='Transferable'
          subtitle='Customers can transfer this ticket to another customer'
        />
      )}
      <PoshSwitch
        switchOptions={{
          onChange: togglePasswordInput,
          checked: shouldShowPasswordInput,
        }}
        rightTitle='Password Protected'
        subtitle='Customers must enter a password on the event page to purchase this ticket'
      />
      {shouldShowPasswordInput && (
        <EventVisualsTextInput.Text.Controlled
          control={control}
          name='password'
          placeholder='Password'
          {...COLOR_PROPS}
        />
      )}
    </TicketSection>
  )
}
