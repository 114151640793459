import {type UseExperimentReturn} from '@posh/models'
import {captureException} from '@sentry/react'
import {useStatsigClient} from '@statsig/react-bindings'
import {getRudderstackAnalytics} from 'helpers/RudderstackAnalytics'

type BaseEventParams = {
  experiment_name?: string
  experiment_id?: string
  variation?: string | boolean
}

type TrackPaidOrderCreatedParams = {
  userId?: string
  cartId?: string
  eventId?: string
}

type TrackAddToCartParams = {
  count: number
}

type OrderSummaryToggledParams = {
  cartId: string
}

type UserMarketingOptInParams = {
  location: string
  optInPopUpExperiment?: boolean
}

type TrackingOptions<T> = {
  params?: T
  experiment?: UseExperimentReturn
}

const transformExperimentToParams = (experiment?: UseExperimentReturn): BaseEventParams => {
  if (!experiment) return {}

  return {
    experiment_name: experiment.name,
    experiment_id: experiment.ruleID,
    variation: experiment.value,
  }
}

export const useTrackRudderStackEvent = () => {
  const rudderStackClient = getRudderstackAnalytics()
  const {client: statsigClient} = useStatsigClient()
  const {stableID: StatsigStableID} = statsigClient.getContext()

  // Wrapper function to automatically add statsigCustomIDs to all events
  const trackEvent = async <T extends Record<string, any>>(
    eventName: string,
    value: any,
    options: TrackingOptions<T> = {},
  ) => {
    try {
      rudderStackClient?.track(eventName, {
        value,
        statsigCustomIDs: ['stableID', StatsigStableID],
        ...options.params,
        ...transformExperimentToParams(options.experiment),
      })
    } catch (err) {
      captureException(err)
    }
  }

  const trackUserMarketingOptIn = async (
    params: UserMarketingOptInParams,
    options: TrackingOptions<Record<string, never>> = {},
  ) => {
    await trackEvent('user_marketing_opt_in_web', params.location, {
      params,
      ...options,
    })
  }

  const trackPaidOrderCreated = async (
    totalPrice: number,
    options: TrackingOptions<TrackPaidOrderCreatedParams> = {},
  ) => {
    await trackEvent('paid_order_created', totalPrice, options)
  }

  const trackAddToCart = async (ticketId: string, options: TrackingOptions<TrackAddToCartParams> = {}) => {
    await trackEvent('add_to_cart', ticketId, options)
  }

  const trackCartPreviewCreated = async (eventId: string, options: TrackingOptions<Record<string, never>> = {}) => {
    await trackEvent('cart_preview_created', eventId, options)
  }

  const orderSummaryToggled = async (isOpen: boolean, options: TrackingOptions<OrderSummaryToggledParams> = {}) => {
    await trackEvent('order_summary_toggled', isOpen, options)
  }

  const trackAiFlyerUploaded = async (uploaded: boolean, options: TrackingOptions<Record<string, never>> = {}) => {
    await trackEvent('ai_flyer_uploaded', uploaded, options)
  }

  const trackEventSaveDraft = async (eventId: string, options: TrackingOptions<Record<string, never>> = {}) => {
    await trackEvent('event_save_draft', eventId, options)
  }

  const trackEventPublish = async (eventId: string, options: TrackingOptions<Record<string, never>> = {}) => {
    await trackEvent('event_publish', eventId, options)
  }

  return {
    trackUserMarketingOptIn,
    trackPaidOrderCreated,
    trackAddToCart,
    trackCartPreviewCreated,
    orderSummaryToggled,
    trackAiFlyerUploaded,
    trackEventSaveDraft,
    trackEventPublish,
  }
}
