import React from 'react'

import {DayOfWeekType, DaysOfWeekValues} from '@posh/model-types'
import classNames from 'classnames'

import styles from './RecurrenceSettingsDaysOfTheWeek.module.scss'

interface RecurrenceSettingsDaysOfTheWeekProps {
  isDayOfWeekEnabled: (dayOfWeek: DayOfWeekType) => boolean
  toggleDayOfWeek: (dayOfWeek: DayOfWeekType) => void
}

export const RecurrenceSettingsDaysOfTheWeek = (props: RecurrenceSettingsDaysOfTheWeekProps) => {
  const {isDayOfWeekEnabled, toggleDayOfWeek} = props
  return (
    <div style={{display: 'flex', gap: 10}}>
      {DaysOfWeekValues.map(dayOfWeek => {
        const isEnabled = isDayOfWeekEnabled(dayOfWeek)
        return (
          <div
            key={dayOfWeek}
            role='button'
            tabIndex={0}
            onKeyDown={e => {
              // toggle day of week on enter or space
              if (e.key === 'Enter' || e.key === ' ') toggleDayOfWeek(dayOfWeek)
            }}
            onClick={() => toggleDayOfWeek(dayOfWeek)}
            className={classNames(styles.DayOfWeek, {
              [styles.DayOfWeekEnabled]: isEnabled,
            })}>
            {dayOfWeek.at(0)}
          </div>
        )
      })}
    </div>
  )
}
