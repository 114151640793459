import {useState} from 'react'

import {useBlockAttendee} from 'apis/Groups/useBlockAttendee'
import {GroupDisputeStatsReturn} from 'apis/Groups/useFetchGroupDisputes'
import {CRUDTable} from 'components/TableUI/CRUDTable/CRUDTable'
import {ColumnConfig} from 'components/TableUI/CRUDTable/internals/types'
import {Ban, Check, Search} from 'lucide-react'

import TabsFilter from '../DisputesTable/TabsFilters'

import '../../styles.scss'
import './styles.scss'

interface DisputeUsersTableProps {
  disputeUsers: GroupDisputeStatsReturn
  groupId: string
  onRefresh: () => void
}

const DisputeUsersTableCRUD = ({disputeUsers, groupId, onRefresh}: DisputeUsersTableProps) => {
  const [activeFilter, setActiveFilter] = useState('all')
  const [searchQuery, setSearchQuery] = useState('')

  const blockAttendeeMutation = useBlockAttendee({
    onSuccess: () => {
      // Refresh the disputes list after successful block/unblock
      onRefresh?.()
    },
  })

  const handleBlockUser = async (userId: string) => {
    try {
      await blockAttendeeMutation.mutateAsync({
        groupId,
        userId,
        block: true,
      })
    } catch (error) {
      console.error('Failed to block user:', error)
    }
  }

  const handleUnblockUser = async (userId: string) => {
    try {
      await blockAttendeeMutation.mutateAsync({
        groupId,
        userId,
        block: false,
      })
    } catch (error) {
      console.error('Failed to unblock user:', error)
    }
  }

  // Filter users based on active tab
  const filterUsers = (users: GroupDisputeStatsReturn) => {
    switch (activeFilter) {
      case 'active':
        return users.filter(user => !user.isBlocked)
      case 'blocked':
        return users.filter(user => !!user.isBlocked)
      default:
        return users
    }
  }

  // Search functionality
  const filteredUsers = filterUsers(disputeUsers).filter(user => {
    if (searchQuery) {
      const query = searchQuery.toLowerCase()
      return user.name.toLowerCase().includes(query) || user.email.toLowerCase().includes(query)
    }
    return true
  })

  const filterTabs = [
    {id: 'all', label: 'All Users', count: disputeUsers.length},
    {id: 'active', label: 'Active', count: disputeUsers.filter(u => !u.isBlocked).length},
    {id: 'blocked', label: 'Blocked', count: disputeUsers.filter(u => !!u.isBlocked).length},
  ]

  const columns: ColumnConfig<GroupDisputeStatsReturn[0]>[] = [
    {
      key: 'name',
      header: 'Name',
      formatter: (name: string) => name,
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'email',
      header: 'Email',
      formatter: (value: string) => value,
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'totalDisputes',
      header: 'Total Disputes',
      formatter: (value: number) => value.toString(),
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'totalValue',
      header: 'Total Value',
      formatter: (value: number) => `$${value.toFixed(2)}`,
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'pending',
      header: 'Pending',
      formatter: (value: number) => value.toString(),
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'inReview',
      header: 'In Review',
      formatter: (value: number) => value.toString(),
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'lost',
      header: 'Lost',
      formatter: (value: number) => value.toString(),
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'won',
      header: 'Won',
      formatter: (value: number) => value.toString(),
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'isBlocked',
      header: 'Actions',
      render: (isBlocked: boolean, row: GroupDisputeStatsReturn[0]) => {
        if (!isBlocked) {
          return (
            <button
              className='TableActionButton'
              onClick={e => {
                e.stopPropagation()
                handleBlockUser(row.id)
              }}>
              <Ban className='h-4 w-4' />
              Block User
            </button>
          )
        }
        return (
          <button
            className='TableActionButton'
            onClick={e => {
              e.stopPropagation()
              handleUnblockUser(row.id)
            }}>
            <Check className='h-4 w-4' />
            Unblock User
          </button>
        )
      },
    },
  ]

  return (
    <div className='dispute-users-table'>
      <div className='TableHeader'>
        <h2>Dispute User Management</h2>
        <p>View and block attendees abusing disputes</p>

        <div className='TableControls'>
          <TabsFilter tabs={filterTabs} activeTab={activeFilter} onTabChange={filter => setActiveFilter(filter)} />

          <div className='TableSearch'>
            <Search className='TableSearch__icon' />
            <input
              type='text'
              placeholder='Search users...'
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              className='TableSearch__input'
            />
          </div>
        </div>
      </div>

      <CRUDTable
        columns={columns}
        data={filteredUsers}
        hideResourceName={true}
        resourceName='Dispute User'
        itemsPerPage={10}
        refresh={onRefresh}
        lastUpdated={Date.now()}
      />
    </div>
  )
}

export default DisputeUsersTableCRUD
