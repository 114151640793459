// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Account__0hGG5{padding:unset}.Account__0hGG5 img{border-radius:50%;width:28px;height:28px;border:2px solid rgba(255,255,255,.5019607843)}.Account__0hGG5 img[data-is-selected=true]{border:2px solid #fff}", "",{"version":3,"sources":["webpack://./src/components/Navigation/Primary/components/shared/NavigationItem/Account/styles.module.scss"],"names":[],"mappings":"AAEA,gBAEI,aAAA,CAEA,oBACI,iBAAA,CACA,UAAA,CACA,WAAA,CACA,8CAAA,CAEA,2CACI,qBAAA","sourcesContent":["@import '@global-styles/color_pallete';\n\n.Account {\n\n    padding: unset;\n\n    img {\n        border-radius: 50%;\n        width: 28px;\n        height: 28px;\n        border: 2px solid $account-img-border;\n\n        &[data-is-selected=\"true\"] {\n            border: 2px solid $primary;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Account": "Account__0hGG5"
};
export default ___CSS_LOADER_EXPORT___;
