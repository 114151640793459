import React from 'react'

import {EventLineUp} from 'apis/Events/types'
import classNames from 'classnames'
import {DotsGrid} from 'components/assets/Icons'
import {EventVisualsCircleButton} from 'components/PageComponents/EventVisuals/Button/CircleButton'
import {PoshImage} from 'components/PoshImage/PoshImage'
import moment from 'moment-timezone'

import styles from './styles.module.scss'

export interface LineupItemProps {
  item: EventLineUp
  accentColor: string
  lightmode: boolean
  onClick: (artist: EventLineUp) => void
  eventTimezone: string
}

const FORMAT_STRING = 'h:mm A'
const displayTime = ({time, eventTimezone}: {time?: string; eventTimezone: string}) => {
  return time ? moment.tz(time, eventTimezone).format(FORMAT_STRING) : ''
}

const ICON_SIZE = 20

export function EditButton({accentColor, lightMode}: {accentColor: string; lightMode: boolean}) {
  return <EventVisualsCircleButton iconType='pencil' lightMode={lightMode} accentColor={accentColor} />
}

export function BaseLineupItem(props: LineupItemProps & {editable?: boolean}) {
  const {item, accentColor, lightmode, onClick, editable, eventTimezone} = props

  const navigateToItemLink = (item: EventLineUp) => {
    let {link} = item
    // Add https if not present
    if (!link.startsWith('http')) link = `https://${link}`
    window.open(link, '_blank')
  }

  const displayArtistStartTime = displayTime({time: item.time?.start, eventTimezone})
  const displayArtistEndTime = displayTime({time: item.time?.end, eventTimezone})
  const viewMoreLinkText = 'View more'
  const lightmodeClass = lightmode ? styles.light : styles.dark
  const iconColor = lightmode ? '#000' : '#fff'

  return (
    <div className={classNames(styles.EventPageLineupItem, {[styles.editable]: editable})}>
      {editable && <DotsGrid width={ICON_SIZE} height={ICON_SIZE} color={iconColor} />}
      <div onClick={() => onClick(item)} className={styles.imageContainer}>
        {editable && (
          <div className={styles.editButton}>
            <EditButton accentColor={accentColor} lightMode={lightmode} />
          </div>
        )}
        <PoshImage className={styles.avi} src={item?.avi} alt='Avatar' />
      </div>
      <div className={styles.info}>
        <h3 className={classNames(styles.name, lightmodeClass, 'm-0')}>{item?.name}</h3>
        <div onClick={() => navigateToItemLink(item)} className={styles.link} style={{color: accentColor}}>
          {viewMoreLinkText}
        </div>
        {item.time && (
          <div className={styles.time}>
            {displayArtistEndTime ? `${displayArtistStartTime} - ${displayArtistEndTime}` : displayArtistStartTime}
          </div>
        )}
      </div>
    </div>
  )
}
