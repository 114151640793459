import {useEffect, useState} from 'react'

import {ReactQueryOptions, RouterInputs, trpc} from 'lib/trpc'

export type FetchMarketplaceEventsInput = RouterInputs['events']['fetchMarketplaceEvents']
// TODO: could not figure out infinite query types
type FetchMarketplaceEventsOptions = Pick<ReactQueryOptions['events']['fetchMarketplaceEvents'], 'onError' | 'enabled'>

// In milliseconds
const DEBOUNCE_TIME = 400
// In seconds
const STALE_TIME = 1000 * 40
export function useFetchMarketplaceEvents(input: FetchMarketplaceEventsInput, options?: FetchMarketplaceEventsOptions) {
  const [searchQuery, setSearchQuery] = useState(input.search)

  useEffect(() => {
    if (input.search !== searchQuery) {
      const timerId = setTimeout(() => {
        setSearchQuery(input.search)
      }, DEBOUNCE_TIME)
      return () => {
        clearTimeout(timerId)
      }
    }
  }, [input.search])

  return trpc.events.fetchMarketplaceEvents.useInfiniteQuery(
    {...input, search: searchQuery},
    {
      ...options,
      staleTime: STALE_TIME,
      getNextPageParam: lastPage => lastPage.nextCursor,
    },
  )
}
