import React, {useEffect, useState} from 'react'
import Loader from 'react-loader-spinner'
import {useNavigate} from 'react-router-dom'

import {useQueryClient} from '@tanstack/react-query'
import {BookingRequestWithBookerInfo, GalleryImage} from 'apis/Community'
import useFetchBookingRequests from 'apis/Community/useFetchBookingRequests'
import useFetchCommunityEntity from 'apis/Community/useFetchCommunityEntity'
import useUpdateArtist from 'apis/Community/useUpdateArtist'
import useUpdateBookingRequest from 'apis/Community/useUpdateBookingRequest'
import useUpdateStaffMember from 'apis/Community/useUpdateStaffMember'
import useUpdateVenue from 'apis/Community/useUpdateVenue'
import {uploadImage} from 'apis/Util/useUploadImage'
import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {isEmpty} from 'lodash'

import Booking from './Booking'
import BookingModal from './BookingModal'
import {EntityDetails, getEntityDetails} from './dashboardHelpers'
import EditListing from './EditListing'

import './styles.scss'

const CommunityDashboard = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [isNewRequestModalOpen, setIsNewRequestModalOpen] = useState(false)
  const {data: communityEntity, isFetching: isFetchingCommunityEntity} = useFetchCommunityEntity()
  const [entityDetails, setEntityDetails] = useState<EntityDetails>()
  const {mutateAsync: updateBookingRequest} = useUpdateBookingRequest()
  const [modalBookingRequest, setModalBookingRequest] = useState<BookingRequestWithBookerInfo | null>(null)
  const [isEditing, setIsEditing] = useState(false)
  const {mutateAsync: updateVenue} = useUpdateVenue()
  const {mutateAsync: updateArtist} = useUpdateArtist()
  const {mutateAsync: updateStaffMember} = useUpdateStaffMember()

  const handleRequestOnClick = async (bookingRequest: BookingRequestWithBookerInfo) => {
    setModalBookingRequest(bookingRequest)
    setIsNewRequestModalOpen(true)
  }

  const onCloseBookingModal = async (bookingRequest: BookingRequestWithBookerInfo | null) => {
    try {
      if (bookingRequest && !bookingRequest.opened) await updateBookingRequest({id: bookingRequest._id, opened: true})
      queryClient.invalidateQueries(['fetchBookingRequests'])
      setIsNewRequestModalOpen(false)
    } catch (error) {
      console.log(error)
      queryClient.invalidateQueries(['fetchBookingRequests'])
      setIsNewRequestModalOpen(false)
    }
  }

  const changeProfilePhoto = async (icon?: File) => {
    if (icon && entityDetails && entityDetails.id) {
      const pictureUrl = await uploadImage(icon)
      switch (entityDetails?.type) {
        case 'venue':
          const images = entityDetails.pictures || []
          images.unshift(pictureUrl)
          const galleryImages: GalleryImage[] = images.map(image => {
            return {url: image}
          })
          await updateVenue({id: entityDetails.id, galleryImages: galleryImages})
          break
        case 'artist':
          await updateArtist({id: entityDetails.id, avi: pictureUrl})
          break
        case 'staff':
          await updateStaffMember({id: entityDetails.id, avi: pictureUrl})
          break
        default:
          break
      }
    }
    location.reload()
  }

  const hasBookingRequests = () => {
    if (bookingRequests) {
      if (isEmpty(bookingRequests.pending) && isEmpty(bookingRequests.accepted) && isEmpty(bookingRequests.past)) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if (communityEntity && communityEntity.type) {
      const details = getEntityDetails(communityEntity)
      setEntityDetails(details)
    }
  }, [communityEntity])

  const {data: bookingRequests} = useFetchBookingRequests({
    communityId: entityDetails?.id,
    communityCategory: communityEntity?.type,
  })

  useEffect(() => {
    if (!communityEntity?.type && !isFetchingCommunityEntity) {
      navigate('/community/signup')
    }
  }, [communityEntity])

  return !entityDetails ? (
    <div className='Loader'>
      <Loader type='Rings' color='#51D300' height={100} width={100} />
    </div>
  ) : (
    <>
      <div className='ComSupDash'>
        <div className='ComSupDash-profile'>
          <PoshImage className='ComSupDash-profile-logo' src='https://images.posh.vip/b2/posh-community.png' />
          <PoshImage
            className='ComSupDash-profile-avi'
            src={entityDetails.image ? entityDetails.image : 'https://images.posh.vip/b2/default-avi.jpg'}
          />
          <div className='CommunityProfilePicture'>
            <label>
              <PoshImage src='https://images.posh.vip/b2/dslr-camera.svg' />
              <input
                className='CommunityProfilePicture-input'
                type='file'
                accept='image/*'
                multiple={false}
                onChange={e => {
                  changeProfilePhoto(e.target.files![0])
                }}
              />
            </label>
          </div>
          <h2>{entityDetails!.title}</h2>
          <div className='ComSupDash-profile-btns'>
            <Button className='light dark' onClick={() => navigate(`/c/${entityDetails.type}/${entityDetails.url}`)}>
              <PoshImage src='https://images.posh.vip/b2/community/icons/user+(2).svg' />
              View My Profile
            </Button>
            <Button className='light dark' onClick={() => setIsEditing(!isEditing)}>
              <PoshImage
                src={
                  isEditing
                    ? 'https://images.posh.vip/b2/eye+(3).svg'
                    : 'https://images.posh.vip/b2/community/icons/pencil.svg'
                }
              />
              {isEditing ? 'View Booking Requests' : 'Edit Listing Details'}
            </Button>
          </div>
        </div>
        <div className='ComSupDash-right'>
          <div className='ComSupDash-stats'>
            <div className='ComSupDash-stats-statHolder'>
              <div className='ComSupDash-stats-stat'>
                <div className='ComSupDash-stats-stat-title'>Profile Clicks</div>
                <div className='ComSupDash-stats-stat-value'>{entityDetails.openModalClicks}</div>
              </div>
              <div className='ComSupDash-stats-stat'>
                <div className='ComSupDash-stats-stat-title'>Booking Requests</div>
                <div className='ComSupDash-stats-stat-value'>{bookingRequests?.total}</div>
              </div>
              <div className='ComSupDash-stats-stat'>
                <div className='ComSupDash-stats-stat-title'>Jobs Accepted</div>
                <div className='ComSupDash-stats-stat-value'>{bookingRequests?.totalAccepted}</div>
              </div>
            </div>
          </div>
          {isEditing ? (
            <EditListing entityDetails={entityDetails} />
          ) : (
            <div className='ComSupDash-requests'>
              {bookingRequests?.pending && bookingRequests.pending.length > 0 && (
                <div>
                  <h3>Booking Requests</h3>
                  <div className='ComSupDash-requests-btns'>
                    {bookingRequests?.pending.map(request => (
                      <div key={request._id} onClick={() => handleRequestOnClick(request)}>
                        <Booking bookingRequest={request} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {!hasBookingRequests() &&
                (entityDetails.approvalStatus === 'pending' || entityDetails.approvalStatus === 'denied') && (
                  <h3 className='ComSupDash-requests-message'>Your account is currently awaiting approval.</h3>
                )}
              {!hasBookingRequests() && entityDetails.approvalStatus === 'approved' && (
                <h3 className='ComSupDash-requests-message'>You have no booking requests.</h3>
              )}
              {bookingRequests?.accepted && bookingRequests.accepted.length > 0 && (
                <div>
                  <h3>Upcoming Bookings</h3>
                  {
                    <div className='ComSupDash-requests-btns'>
                      {bookingRequests?.accepted.map(request => (
                        <div key={request._id} onClick={() => handleRequestOnClick(request)}>
                          <Booking bookingRequest={request} />
                        </div>
                      ))}
                    </div>
                  }
                </div>
              )}
              {bookingRequests?.past && bookingRequests.past.length > 0 && (
                <div>
                  <h3>Past Bookings</h3>
                  {
                    <div className='ComSupDash-requests-btns'>
                      {bookingRequests?.past.map(request => (
                        <div key={request._id} onClick={() => handleRequestOnClick(request)}>
                          <Booking bookingRequest={request} />
                        </div>
                      ))}
                    </div>
                  }
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {isNewRequestModalOpen && (
        <BookingModal
          isOpen={isNewRequestModalOpen}
          onClose={() => onCloseBookingModal(modalBookingRequest)}
          bookingRequest={modalBookingRequest}
          setIsNewRequestModalOpen={setIsNewRequestModalOpen}
          entityDetails={entityDetails}
        />
      )}
    </>
  )
}

export default CommunityDashboard
