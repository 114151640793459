import React from 'react'

import {CustomEventPageFonts} from '@posh/model-types'
import {getEventTitleFont} from 'components/Text/EventTitleFontStyleProvider'
import FontPicker from 'font-picker-react'

import './styles.scss'

const GOOGLE_FONTS_API_KEY = 'AIzaSyA0vBI1N5C6WqNpv-qSf0pjgaXJxDLD-xc'

interface EditEventFontProps {
  eventTitleFont: string | undefined
  setEventTitleFont: React.Dispatch<React.SetStateAction<string | undefined>>
  label?: string
}

const EditEventFont = (props: EditEventFontProps) => {
  const {eventTitleFont, setEventTitleFont, label} = props
  return (
    <div className='FontPicker'>
      {label && <label className='m-0'>{label}</label>}
      <FontPicker
        apiKey={GOOGLE_FONTS_API_KEY}
        activeFontFamily={getEventTitleFont(eventTitleFont)}
        onChange={nextFont => setEventTitleFont(nextFont.family)}
        families={Object.values(CustomEventPageFonts)}
      />
    </div>
  )
}

export default EditEventFont
