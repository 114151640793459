// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableCell__Ih4xB{color:#fff;font-size:16px;font-style:normal;font-weight:300;line-height:normal;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;font-weight:400;letter-spacing:-0.2px}.bold__\\+r0YZ{font-weight:500}.TableCellMultiOption__gyFWW{overflow:hidden;display:flex;align-items:center;gap:8px}.hover__Cl6x\\+{cursor:pointer;border-bottom:1px solid #fff}", "",{"version":3,"sources":["webpack://./src/components/TableUI/TableCell/TableCell.module.scss"],"names":[],"mappings":"AAEA,kBACE,UAAA,CACD,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CACA,eAAA,CACA,qBAAA,CAGD,cACE,eAAA,CAGF,6BACE,eAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,eACE,cAAA,CACA,4BAAA","sourcesContent":["@import '@global-styles/_color_pallete';\n\n.TableCell {\n  color: white;\n\tfont-size: 16px;\n\tfont-style: normal;\n\tfont-weight: 300;\n\tline-height: normal;\n\twhite-space: nowrap;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n\tfont-weight: 400;\n\tletter-spacing: -.2px;\n}\n\n.bold {\n  font-weight: 500;\n}\n\n.TableCellMultiOption {\n  overflow: hidden;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.hover {\n  cursor: pointer;\n  border-bottom: 1px solid $white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableCell": "TableCell__Ih4xB",
	"bold": "bold__+r0YZ",
	"TableCellMultiOption": "TableCellMultiOption__gyFWW",
	"hover": "hover__Cl6x+"
};
export default ___CSS_LOADER_EXPORT___;
