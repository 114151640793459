import React from 'react'
import {Control, useController} from 'react-hook-form'

import {Button} from '@posh/design-kit/components/button'
import {FileInput} from 'components/form/FilePicker'
import {WithToolTip} from 'components/form/PoshInfo'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {EventVisualsSection} from 'components/PageComponents/EventVisuals/Page/Section'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {Undo2} from 'lucide-react'

import {useEventVisualsContext} from '../../context/EventVisualsContext'

import styles from './styles.module.scss'

interface EventVisualsGroupDisplayProps {
  groupName: string
  groupImage: string
  control: Control<EventVisualsForm>
}

const TOOLTIP_INFO =
  'The organizer image and name that is shown on the event page. This does not change the name of your group for other events.'

function useEventVisualsGroupDisplayFormValues(control: EventVisualsGroupDisplayProps['control']) {
  const {
    field: {value: displayGroupImage, onChange: onChangeDisplayGroupImage},
  } = useController({control, name: 'displayGroupImage'})

  const {
    field: {value: displayGroupName, onChange: onChangeDisplayGroupName},
  } = useController({control, name: 'displayGroupName'})

  return {displayGroupImage, onChangeDisplayGroupImage, displayGroupName, onChangeDisplayGroupName}
}

export function EventVisualsGroupDisplaySettingsSection({
  groupName,
  groupImage,
  control,
}: EventVisualsGroupDisplayProps) {
  const {displayGroupImage, onChangeDisplayGroupImage, displayGroupName, onChangeDisplayGroupName} =
    useEventVisualsGroupDisplayFormValues(control)
  const {
    palette: {accentColor, lightmode: lightMode, textContrasting, buttonTextColor},
  } = useEventVisualsContext()

  const hasCustomGroupName = !!displayGroupName
  const groupNameValue = displayGroupName ?? groupName

  const hasCustomGroupImage = !!displayGroupImage
  const groupImageSrc = displayGroupImage ?? groupImage

  const hasCustomGroupDisplayField = hasCustomGroupImage || hasCustomGroupName

  const resetGroupDisplayFields = () => {
    onChangeDisplayGroupImage(null)
    onChangeDisplayGroupName(null)
  }

  return (
    <EventVisualsSection headerText='Organizer Details' tooltipInfo={TOOLTIP_INFO}>
      <div className={styles.GroupDisplaySettings}>
        <FileInput.WithUploadImage
          imageType='group-avi'
          onSuccess={(_, imageUrl) => onChangeDisplayGroupImage(imageUrl)}>
          <PoshImage src={groupImageSrc} alt='Group Display Image' style={{borderColor: textContrasting}} />
        </FileInput.WithUploadImage>
        <div className={styles.inputContainer}>
          <EventVisualsTextInput.Text
            value={groupNameValue}
            onChange={onChangeDisplayGroupName}
            accentColor={accentColor}
            lightMode={lightMode}
            placeholder='Group Name'
          />
        </div>
        {hasCustomGroupDisplayField && (
          <div className='flex items-center justify-center'>
            <WithToolTip
              direction='up'
              info='This will reset your custom group name and image to the values from your group profile.'
              className='mr-0! ml-2'>
              <Button
                className='border-none'
                style={{backgroundColor: accentColor}}
                size='icon'
                onClick={resetGroupDisplayFields}>
                <Undo2 className='h-4 w-4' color={buttonTextColor} />
              </Button>
            </WithToolTip>
          </div>
        )}
      </div>
    </EventVisualsSection>
  )
}
