import React from 'react'

import {MAX_FILE_UPLOAD_SIZE} from '@posh/types'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'

import {addUserIcon, Image, imageUrl} from '../../assets/Icons'
import {FileInput} from '../FilePicker'

import './index.scss'

interface Props {
  title: string
  showTitle?: boolean
  didReceiveFile?: (file: PictureState) => void
  value?: PictureState
  className?: string
  horizontal?: boolean
  block?: boolean
  icon?: boolean
  banner?: boolean
  required?: boolean
  iconImage?: boolean
  mobileAutoHeight?: boolean
}

export interface PictureState {
  url?: string | null
  file?: File | null
}

const SingleImagePicker = (props: Props) => {
  const {
    title,
    showTitle,
    didReceiveFile,
    value,
    className,
    horizontal,
    block,
    icon,
    banner,
    required,
    iconImage,
    mobileAutoHeight,
  } = props
  const {showToast} = useToast()
  const handleOnSubmitImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return null

    const file = e.target.files[0]
    if (file.size > MAX_FILE_UPLOAD_SIZE) {
      showToast({type: 'error', title: 'Image too large, must be under 8MB'})
      return null
    }
    const imageUrl = URL.createObjectURL(file)
    if (didReceiveFile) didReceiveFile({file, url: imageUrl})
  }

  return (
    <>
      {horizontal ? (
        <FileInput.Image onChange={handleOnSubmitImage}>
          <div className={`HorizontalImagePicker ${className || ''}`}>
            {title && showTitle && <label>{title}</label>}
            <div className='HorizontalImagePicker-container'>
              {value?.url && <PoshImage src={value.url} className='HorizontalImagePicker-selectedImage' />}
              <div className={`HorizontalImagePicker-trigger ${required ? 'Required' : ''}`}>
                <Image className='HorizontalImagePicker-icon' />
              </div>
            </div>
          </div>
        </FileInput.Image>
      ) : icon ? (
        <FileInput.Image onChange={handleOnSubmitImage}>
          <div className={`IconImagePicker ${className || ''}`}>
            {title && showTitle && <label>{title}</label>}
            <div className='IconImagePicker-container'>
              {value?.url && <PoshImage src={value.url} className='IconImagePicker-selectedImage' />}
              <div className={`IconImagePicker-trigger${required ? 'Required' : ''}`}>
                <Image className='IconImagePicker-icon' />
              </div>
            </div>
          </div>
        </FileInput.Image>
      ) : iconImage ? (
        <FileInput.Image onChange={handleOnSubmitImage}>
          <div className={`IconImagePicker ${className || ''}`}>
            {title && showTitle && <label>{title}</label>}
            <div className='IconImagePicker-container'>
              {value?.url ? (
                <PoshImage src={value.url} className='IconImagePicker-selectedImage' />
              ) : (
                <div
                  className={`IconImagePicker-trigger${required ? 'Required' : ''} ${iconImage ? 'addUserIcon' : ''} ${
                    className || ''
                  }`}>
                  {iconImage ? <PoshImage src={addUserIcon} /> : <Image className='IconImagePicker-icon' />}
                </div>
              )}
            </div>
          </div>
        </FileInput.Image>
      ) : block ? (
        <div
          className={`PoshImagePicker ${className || ''} ${mobileAutoHeight && 'mobileAutoHeight'}`}
          style={{display: 'block'}}>
          {title && showTitle && <label>{title}</label>}
          <div className='PoshImagePicker-container'>
            {value?.url && <PoshImage className='PoshImagePicker-image' src={value.url} />}
            <PoshImage className='PoshImagePicker-uploadIcon' src='https://images.posh.vip/b2/cloud-computing.png' />
            <div className='PoshImagePicker-cover' />
            <PoshImage className='PoshImagePicker-icon' src={imageUrl} />
            <input
              className='PoshImagePicker-input'
              id={title}
              type='file'
              accept='image/*'
              multiple={false}
              onChange={handleOnSubmitImage}
            />
          </div>
        </div>
      ) : banner ? (
        <div className={`PoshImagePicker banner`}>
          {title && showTitle && <label>{title}</label>}
          <div className='PoshImagePicker-container banner'>
            {value?.url && <PoshImage className='PoshImagePicker-image banner' src={value.url} />}
            <PoshImage className='PoshImagePicker-uploadIcon' src='https://images.posh.vip/b2/cloud-computing.png' />
            <div className='PoshImagePicker-cover' />
            <PoshImage className='PoshImagePicker-icon' src={imageUrl} />
            <input
              className='PoshImagePicker-input'
              id={title}
              type='file'
              accept='image/*'
              multiple={false}
              onChange={handleOnSubmitImage}
            />
          </div>
        </div>
      ) : (
        <div className={`PoshImagePicker ${className || ''}`}>
          {title && showTitle && <label>{title}</label>}
          <div className='PoshImagePicker-container'>
            {value?.url && <PoshImage className='PoshImagePicker-image' src={value.url} />}
            <div className='PoshImagePicker-cover' />
            <PoshImage className='PoshImagePicker-icon' src={imageUrl} />
            <input
              className='PoshImagePicker-input'
              id={title}
              type='file'
              accept='image/*'
              multiple={false}
              onChange={handleOnSubmitImage}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default SingleImagePicker

interface UploadImageButtonProps {
  title: string
  showTitle?: boolean
  didReceiveFiles?: (files: PictureState[]) => void
  value?: PictureState
  className?: string
  horizontal?: boolean
  block?: boolean
  icon?: boolean
  multiple?: boolean
}

const UploadImageButton = ({title, didReceiveFiles, multiple}: UploadImageButtonProps) => {
  const {showToast} = useToast()
  const handleOnSubmitImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return null

    const images: PictureState[] = []
    let file: File
    let imageUrl: string

    let numberOfTooLargeImages = 0

    for (let i = 0; i < e.target.files.length; i++) {
      file = e.target.files[i]
      if (file.size > MAX_FILE_UPLOAD_SIZE) {
        numberOfTooLargeImages++
        continue
      }
      imageUrl = URL.createObjectURL(file)
      images.push({file, url: imageUrl})
    }

    if (didReceiveFiles) didReceiveFiles(images)

    if (numberOfTooLargeImages > 0) {
      showToast({type: 'error', title: `${numberOfTooLargeImages} were too large to be uploaded. Must be under 8MB.`})
    }
  }

  return (
    <>
      <div className='UploadImageButton'>
        <input hidden id={title} type='file' accept='image/*' multiple={!!multiple} onChange={handleOnSubmitImage} />
        <label htmlFor={title}>{`Upload Image${multiple ? 's' : ''}`}</label>
      </div>
    </>
  )
}

export {UploadImageButton}

export const PoshImagePicker = (props: Props) => {
  return (
    <div className='PoshImagePicker'>
      {props.value?.url && <PoshImage className='PoshImagePicker-image' src={props.value.url} />}
      <div className='PoshImagePicker-cover' />
      <PoshImage className='PoshImagePicker-icon' src={imageUrl} />
      <input
        className='PoshImagePicker-input'
        id={props.title}
        type='file'
        accept='image/*'
        multiple={false}
        onChange={() => null}
      />
    </div>
  )
}
