import React from 'react'

import classNames from 'classnames'

import {TableIcon, TTableIcon} from '../TableIcons/TableIcon'

import styles from './TableButton.module.scss'

export interface TableButtonProps {
  text: string
  disabled?: boolean
  onClick: () => void
  icon?: TTableIcon
  iconColor?: string
  iconSize?: number
  noShadow?: boolean
}

const DEFAULT_ICON_SIZE = 16

export const TableButton = (props: TableButtonProps) => {
  const {text, disabled = false, icon, onClick, iconColor, iconSize = DEFAULT_ICON_SIZE, noShadow} = props
  return (
    <button
      type='button'
      className={classNames(styles.TableButton, {[styles.disabled]: disabled})}
      disabled={disabled}
      onClick={onClick}
      style={{boxShadow: noShadow ? 'none' : undefined}}>
      {icon && <TableIcon name={icon} width={iconSize} height={iconSize} color={iconColor} />} {text}
    </button>
  )
}
