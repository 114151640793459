import React from 'react'
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd'

import {PlusSign, TrashIcon} from 'components/assets/Icons'

import styles from './EventPlaylistSortOrderInput.module.scss'

const SortOrderOptions = ['date', 'ticketsSold', 'totalGmv'] as const
type SortOrder = (typeof SortOrderOptions)[number]

interface EventPlaylistSortOrderInputProps {
  value: SortOrder[]
  onChange: (value: SortOrder[]) => void
}

const SortOrderLabelMap: Record<SortOrder, string> = {
  date: 'Start Date',
  ticketsSold: 'Event Tickets Sold',
  totalGmv: 'Group GMV (Total)',
}

const ICON_SIZE = 16

export const EventPlaylistSortOrderInput = (props: EventPlaylistSortOrderInputProps) => {
  const {value: sortOrder, onChange} = props

  const reorder = (list: SortOrder[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  async function onDragEnd(result: DropResult) {
    if (!result.destination) return
    if (result.destination.index === result.source.index) return
    const reorderedSortOrder = reorder(sortOrder, result.source.index, result.destination.index)
    onChange(reorderedSortOrder)
  }

  return (
    <div>
      <div className={styles.AvailableSortFields}>
        <p className='m-0'>Available Sort Fields:</p>
        {SortOrderOptions.filter(sortOrderField => !sortOrder.includes(sortOrderField)).map(sortOrderField => (
          <div
            key={sortOrderField}
            onClick={() => onChange([...sortOrder, sortOrderField])}
            className={styles.SortField}
            style={{cursor: 'pointer'}}>
            <p
              className='m-0'
              style={{
                userSelect: 'none',
              }}>
              {SortOrderLabelMap[sortOrderField]}
            </p>
            <PlusSign color={'white'} width={ICON_SIZE} height={ICON_SIZE} />
          </div>
        ))}
      </div>
      <div className={styles.ChosenSortFields}>
        <p className='m-0'>Chosen Sort Fields:</p>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable direction='vertical' droppableId='list'>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {sortOrder.map((sortOrderField, index) => (
                  <>
                    <Draggable draggableId={index.toString()} index={index}>
                      {provider => (
                        <div ref={provider.innerRef} {...provider.draggableProps} {...provider.dragHandleProps}>
                          <div className={styles.SortField}>
                            <p
                              className='m-0'
                              style={{
                                userSelect: 'none',
                              }}>
                              {SortOrderLabelMap[sortOrderField]}
                            </p>
                            {sortOrder.length > 1 && (
                              <TrashIcon
                                onClick={() => onChange(sortOrder.filter(field => field !== sortOrderField))}
                                color={'white'}
                                width={ICON_SIZE}
                                height={ICON_SIZE}
                                style={{cursor: 'pointer'}}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  </>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  )
}
