import {useEffect, useRef, useState} from 'react'

import {ExperimentKey} from '@posh/model-types'
import {updatePoshSmsOptIn} from 'apis/Optins/useUpdateAccountOptIn'
import {updateGroupSmsOptIn} from 'apis/Optins/useUpdateGroupOptIn'
import useSessionContext from 'domains/Auth/SessionContext'
import {useAccountGroupOptIns} from 'hooks/optIns'
import {useExperiment} from 'hooks/useExperiment'

import OptinModalPresentation from './OptinModalPresentation'

const DELAY = 1000
const OPT_IN_POP_UP_EXPERIMENT_HAS_ASKED_IN_USER_SESSION_KEY = 'optInPopUpExperimentHasAskedInUserSession'

export type OptinModalProps = {
  entityDisplayName: string
  location: 'event-page'
  groupId?: string
  isAttendee?: boolean
}

export default function OptinModalContainer({
  entityDisplayName,
  location,
  groupId,
  isAttendee,
  onClose,
}: OptinModalProps & {
  onClose: () => void
}) {
  const [open, setOpen] = useState(false)
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const {currentUser} = useSessionContext()

  const {
    shouldDisplayOptInForm,
    accountOptInData,
    isLoadingAccountOptIns,
    isLoadingOrgOptIns,
    orgOptInData,
    updateGroupOptIn,
    updateAccountOptIns,
  } = useAccountGroupOptIns({
    optInPopUpExperiment: true,
    location,
    groupId,
    toastSuccess: 'account',
  })

  const {value: isFollowingUsersOptInPopUpExperimentEnabled} = useExperiment({
    experimentKey: ExperimentKey.FOLLOWING_USERS_OPT_IN_POP_UP_EXPERIMENT_WEB,
    enabled: !!currentUser,
  })

  // Show the popup if; we havent asked the user in this session, the user is an attendee,
  // the user is on a mobile device, the user has a profile picture, and the experiment is enabled
  const shouldDisplayPopUp = () => {
    const hasAskedInUserSession = sessionStorage.getItem(OPT_IN_POP_UP_EXPERIMENT_HAS_ASKED_IN_USER_SESSION_KEY)
    const isMobile = window.innerWidth < 768
    const hasProfilePicture = currentUser?.avi || currentUser?.instagram?.avi
    return (
      isFollowingUsersOptInPopUpExperimentEnabled &&
      shouldDisplayOptInForm &&
      isMobile &&
      hasProfilePicture &&
      !hasAskedInUserSession &&
      isAttendee
    )
  }

  useEffect(() => {
    if (isLoadingAccountOptIns || isLoadingOrgOptIns) {
      return
    } else if (shouldDisplayPopUp()) {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
      timerRef.current = setTimeout(() => {
        sessionStorage.setItem(OPT_IN_POP_UP_EXPERIMENT_HAS_ASKED_IN_USER_SESSION_KEY, 'true')
        setOpen(true)
      }, DELAY)
    } else {
      setOpen(false)
      onClose()
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [
    isFollowingUsersOptInPopUpExperimentEnabled,
    shouldDisplayOptInForm,
    isLoadingAccountOptIns,
    isLoadingOrgOptIns,
    orgOptInData,
    groupId,
    isAttendee,
    currentUser,
    window.innerWidth,
  ])

  useEffect(() => {
    return () => {
      if (timerRef?.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
      setOpen(false)
      onClose()
    }
  }, [])

  const handleOptIn = () => {
    setTimeout(() => {
      // wait for animation to finish
      if (orgOptInData) {
        updateGroupOptIn(updateGroupSmsOptIn(orgOptInData, true))
      }
      if (accountOptInData) {
        updateAccountOptIns(updatePoshSmsOptIn({prev: accountOptInData, value: true, type: 'all'}))
      }
      onClose()
    }, 1000)
  }

  const onOpenChange = (isOpen: boolean) => {
    setOpen(isOpen)
    if (!isOpen) {
      setTimeout(() => {
        // wait for animation to finish
        onClose()
      }, 1000)
    }
  }

  return (
    <OptinModalPresentation
      open={open}
      entityDisplayName={entityDisplayName}
      onOptIn={handleOptIn}
      onOpenChange={onOpenChange}
    />
  )
}
