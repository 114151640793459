import React, {useEffect, useState} from 'react'

import {DEFAULT_KICKBACK_CTA} from '@posh/model-types'
import useFetchEvent from 'apis/Events/useFetchEvent'
import {UpdateEventAttributes_DEPRECATED, useUpdateEvent_DEPRECATED} from 'apis/Events/useUpdateEventDEPRECATED'
import {useMixpanel} from 'apis/MixPanelHandler'
import Input from 'components/form/Input'
import PoshInfo from 'components/form/PoshInfo'
import PoshSwitch from 'components/form/PoshSwitch'
import {useToast} from 'components/toasts/ToastProvider'
import {useEventMatchParams} from 'domains/Events/helpers'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {isUndefined} from 'lodash'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

import SaveDiscardBottomBar from '../SaveDiscardBottomBar'

import './styles.scss'

const KickbackSettings = () => {
  const {eventId} = useEventMatchParams()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {groupId} = useGroupMatchParams()
  const {data: eventData, refetch} = useFetchEvent(eventId!)
  const [updateEventAttributes, setUpdateEventAttributes] = useState<UpdateEventAttributes_DEPRECATED>()
  const isCtaChecked = updateEventAttributes?.displayKickbackCTA ?? true
  const [shouldShowSave, setShouldShowSave] = useState(false)
  const {mutateAsync: updateEvent} = useUpdateEvent_DEPRECATED()
  const {showToast} = useToast()
  const setUpdateEventAttributesToDefault = () => {
    setUpdateEventAttributes({
      displayKickbackCTA: eventData?.event.displayKickbackCTA,
      kickbackCTAText: eventData?.event.kickbackCTAText,
    })
  }
  useEffect(() => {
    if (eventData && eventData.event) {
      setUpdateEventAttributesToDefault()
    }
  }, [eventData])
  useEffect(() => {
    if (updateEventAttributes && eventData && eventData.event) {
      if (
        updateEventAttributes.displayKickbackCTA !== eventData.event.displayKickbackCTA ||
        updateEventAttributes.kickbackCTAText !== eventData.event.kickbackCTAText
      ) {
        setShouldShowSave(true)
      }
    }
  }, [updateEventAttributes, eventData])
  const handleSave = async () => {
    try {
      if (!isUndefined(updateEventAttributes) && eventId && groupId) {
        await updateEvent({eventId: eventId, groupId: groupId, eventData: updateEventAttributes})
        setShouldShowSave(false)
        await refetch()
        showToast({type: 'success', title: 'Kickback settings saved'})
      }
    } catch (e) {
      console.error(e)
      showToast({type: 'error', title: e.response.data.error})
    }
  }

  return (
    <div className='KickbackSettings'>
      <BackAndTitle title='Kickback Settings' />
      {eventData && eventData.event && (
        <>
          <div className='EventSettings-Setting'>
            <p className='m-0'>Receipt CTA</p>
            <PoshInfo info='The prompt for attendees to enroll for kickbacks for your event. If this is off you are only limited to adding affiliates yourself through the dashboard.' />
            <PoshSwitch
              switchOptions={{
                checked: isCtaChecked,
                onChange: () => {
                  trackMixpanelEvent('Kickback Receipt CTA Change Edit-  Curator Event Kickback Page Settings')
                  setUpdateEventAttributes({...updateEventAttributes, displayKickbackCTA: !isCtaChecked})
                },
              }}
            />
          </div>
          {isCtaChecked && (
            <div className='EventSettings-Setting'>
              <p className='m-0'>Receipt CTA Text</p>
              <PoshInfo info={'The text that your attendees will see before enrolling to kickbacks'} />
              <Input
                type='text'
                value={updateEventAttributes?.kickbackCTAText}
                onChange={e => {
                  trackMixpanelEvent('Kickback Receipt CTA Text Edit-  Curator Event Kickback Page Settings')
                  setUpdateEventAttributes({...updateEventAttributes, kickbackCTAText: e.target.value})
                }}
                placeholder={DEFAULT_KICKBACK_CTA}
              />
            </div>
          )}
        </>
      )}
      <SaveDiscardBottomBar
        isOpen={shouldShowSave}
        onSave={() => handleSave()}
        onDiscard={() => setUpdateEventAttributesToDefault()}
      />
    </div>
  )
}

export default KickbackSettings
