import {getBaseUrl} from '@posh/model-types'

export const generateMultiSessionTrackingLink = (mseUrl: string, mseHexUrl: string, trackingLink: string) => {
  return {
    hex: generateMultiSessionTrackingLinkHex(mseHexUrl, trackingLink),
    url: generateMultiSessionTrackingLinkUrl(mseUrl, trackingLink),
  }
}

export const generateMultiSessionTrackingLinkHex = (mseHexUrl: string, trackingLink: string) => {
  return `${getBaseUrl()}/s/${mseHexUrl}?t=${trackingLink}`
}

export const generateMultiSessionTrackingLinkUrl = (mseUrl: string, trackingLink: string) => {
  return `${getBaseUrl()}/series/${mseUrl}?t=${trackingLink}`
}
