import React from 'react'

import {OrderResponse} from '@posh/model-types'
import {Card} from 'components/Card'
import isMultipleEventResponses from 'components/CheckoutFieldResponses/isMultipleEventResponses.helper'
import ResponsesMapper from 'components/CheckoutFieldResponses/ResponsesMapper'

const CheckoutFieldResponses = ({
  responses,
}: {
  responses?:
    | Array<OrderResponse & {eventId: string}>
    | {eventName: string; eventResponses?: OrderResponse[]; eventId: string}[]
}) => {
  if (!responses || responses.length === 0) return null

  if (isMultipleEventResponses(responses)) {
    return (
      <Card title={'Order Info'} animationIndex={1}>
        <table className='GroupPage-table'></table>
        {responses.map((response, index) => {
          if (!response.eventResponses || response.eventResponses.length === 0) return null
          const {eventId} = response
          return (
            <div key={index}>
              <h5>{response.eventName}</h5>
              {ResponsesMapper(response.eventResponses, eventId)}
            </div>
          )
        })}
      </Card>
    )
  }

  return (
    <Card title={'Customer Responses'} animationIndex={1} className='CheckoutFieldResponses'>
      <table className='GroupPage-table'>
        <tr>
          <th>Prompt</th>
          <th>Response</th>
        </tr>
        {ResponsesMapper(responses, responses[0].eventId)}
      </table>
    </Card>
  )
}

export default CheckoutFieldResponses
