import {useModal} from 'providers/ModalProvider'

import OptinModal, {type OptinModalProps} from './Modal/OptinModal/OptinModalContainer'

export enum ModalType {
  OPT_IN_MODAL = 'OPT_IN_MODAL',
}

export interface ModalProps {
  [ModalType.OPT_IN_MODAL]: OptinModalProps
}

/**
 * This component is used to control modals.
 * It will render the modal based on the modalType and modalProps.
 * It will also close the modal when the closeModal function is called.
 */
export default function ModalController() {
  const {modalType, modalProps, closeModal} = useModal()

  if (!modalType || !modalProps) return null

  switch (modalType) {
    case ModalType.OPT_IN_MODAL:
      return <OptinModal {...modalProps} onClose={closeModal} />
    default:
      return null
  }
}
