import React, {PropsWithChildren, useEffect, useMemo, useState} from 'react'

import {StatsigClient, StatsigProvider as StatsigProviderBase, StatsigUser} from '@statsig/react-bindings'
import useSessionContext from 'domains/Auth/SessionContext'
import {environment} from 'helpers/environment'

// Colocating this hook here because it should contain the state for the FeatureFlagContext (minus accountId which is managed by the session context)
export const usePoshFeatureFlagContextState = () => {
  const [groupId, setGroupId] = useState<string | undefined>(undefined)
  const [eventId, setEventId] = useState<string | undefined>(undefined)

  return {groupId, setGroupId, eventId, setEventId}
}

/*
  This is all the data that we want to send with every request to Statsig. All can be undefined to not be sent.
  Typical flow of how this state will change:

  --Homepage. No user logged in:
  {
    accountId: undefined,
    groupId: undefined,
    eventId: undefined
  }
  --User logs in:
  {
    accountId: <account ID>,
    groupId: undefined,
    eventId: undefined
  }
  --User clicks into one of the organizations they are an owner/admin/etc of:
  {
    accountId: <account ID>,
    groupId: <group ID>,
    eventId: undefined
  }
  --User clicks into one of the events on that organization:
  {
    accountId: <account ID>,
    groupId: <group ID>,
    eventId: <event ID>
  }
  --User clicks out of that event:
  {
    accountId: <account ID>,
    groupId: <group ID>,
    eventId: undefined
  }
  --User clicks out of that organization:
  {
    accountId: <account ID>,
    groupId: undefined,
    eventId: undefined
  }
*/
export type PoshFeatureFlagState = {
  accountId?: string
  groupId?: string
  eventId?: string
  setAccountId: (accountId: string | undefined) => void
  setGroupId: (groupId: string | undefined) => void
  setEventId: (eventId: string | undefined) => void
}

// Context that holds the PoshFeatureFlagState
export const PoshFeatureFlagContext = React.createContext<PoshFeatureFlagState | null>(null)

// Hook to use this context. Should mostly just be used by the FeatureFlagProvider and OverrideProvider
export default function usePoshFeatureFlagContext() {
  const context = React.useContext(PoshFeatureFlagContext)
  if (!context) throw 'PoshFeatureFlagContext must be provided'
  return context
}

// This feature flag provider sets up our context to be provided, then the StatsigProvider which we set up below consumes that context
export const FeatureFlagProvider = (props: PropsWithChildren) => {
  // The userId from the session context is actually the accountId
  const {userId, setUserId} = useSessionContext() // accountId (aka userId) is managed by the session context
  const {groupId, setGroupId, eventId, setEventId} = usePoshFeatureFlagContextState()

  return (
    <PoshFeatureFlagContext.Provider
      value={{accountId: userId ?? undefined, groupId, setAccountId: setUserId, setGroupId, eventId, setEventId}}>
      <StatsigFeatureFlagProvider {...props} />
    </PoshFeatureFlagContext.Provider>
  )
}

// This is the Statsig specific code that provides the Statisg client to the Statsig feature flag calls
const StatsigFeatureFlagProvider: React.FC<PropsWithChildren> = props => {
  const {children} = props
  const {accountId, groupId, eventId} = usePoshFeatureFlagContext()
  const locale = Intl.DateTimeFormat().resolvedOptions().locale
  const STATSIG_API_KEY = process.env.STATSIG_API_KEY

  // We memo the StatsigUser object so it only changes when we actually change values that need to appear on the user for a feature flag call
  const user: StatsigUser = useMemo(() => {
    return {
      // The userID used by Statsig needs to be the accountId
      userID: accountId ?? undefined,
      locale,
      custom: {groupId, eventId},
      customIDs: {groupId, eventId}, // Any values where we want Statsig to refresh it's cache needs to go here. Their cache key is userID + customIDs + some other shit
    }
  }, [accountId, groupId, eventId, locale])

  // We also memo the Statsig client so we only need to change it when the user (or api key) changes.
  // This is important because whenever we create a new client, we need to initialize it (which triggers a cache refresh in the background over the network)
  const client = useMemo(() => {
    const client = new StatsigClient(STATSIG_API_KEY ?? '', user, {
      environment: {tier: environment},
    })
    client.initializeAsync().catch(err => {
      console.error('Error when calling Statsig', err)
    })
    return client
  }, [user, STATSIG_API_KEY])

  // This provides our client to all the Statsig feature flag calls
  return <StatsigProviderBase client={client}>{children}</StatsigProviderBase>
}

// This is the component that needs to be placed inside the Group or Event routes to override the FeatureFlagContext with the appropriate groupId and/or eventId
export const FeatureFlagOverrideProvider = ({
  children,
  groupId,
  eventId,
}: PropsWithChildren<{groupId?: string; eventId?: string}>) => {
  const {setGroupId, setEventId} = usePoshFeatureFlagContext()

  useEffect(() => {
    // Update the groupId/eventId in the FeatureFlagContext for feature flag calls nested under this component to have these values
    setGroupId(groupId)
    setEventId(eventId)

    //On unmount we will restore back to the state we were before. So that feature flag calls above this component don't have the wrong values
    return () => {
      setGroupId(undefined)
      setEventId(undefined)
    }
  }, [groupId, eventId]) // Only override when the props change

  return children
}
