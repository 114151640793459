import React from 'react'

import {chartsUrl} from 'components/assets/Icons'
import HoverIcon from 'components/form/HoverIcon'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'

interface EventListItemIconProps {
  onClick: () => void
  isLoading: boolean
  isHidden?: boolean
  description?: string
}

function RevertToDraft({isHidden, isLoading, onClick, description = 'Revert to Draft'}: EventListItemIconProps) {
  if (isHidden) return null

  if (isLoading) return <SpinLoader height={30} />

  return (
    <HoverIcon
      className={'status'}
      source={'https://images.posh.vip/images/drafts-1.svg'}
      description={description}
      onClick={onClick}
    />
  )
}

function GoLive(props: EventListItemIconProps) {
  const {isHidden, isLoading, onClick, description = 'Go Live'} = props
  if (isLoading) return <SpinLoader height={30} />
  if (isHidden) return null

  return (
    <HoverIcon
      className='statusIcon'
      source='https://images.posh.vip/images/space-rocket-launch+(1)-1.svg'
      description={description}
      onClick={onClick}
    />
  )
}

function ViewEvent({onClick, description = 'View Event'}: Pick<EventListItemIconProps, 'onClick' | 'description'>) {
  return <HoverIcon source='https://images.posh.vip/b2/eye.svg' description={description} onClick={onClick} />
}

function EditEvent({onClick, description = 'Edit Event'}: Pick<EventListItemIconProps, 'onClick' | 'description'>) {
  return <HoverIcon source='https://images.posh.vip/b2/pencil.svg' description={description} onClick={onClick} />
}

function DuplicateEvent({
  onClick,
  isHidden,
  description = 'Duplicate Event',
}: Omit<EventListItemIconProps, 'isLoading'>) {
  if (isHidden) return null
  return (
    <RequirePermissions
      requiredPermissions={[
        {
          permissionKey: 'duplicate_event',
          applicableScopes: ['group'],
        },
      ]}>
      <HoverIcon source='https://images.posh.vip/b2/duplicate.svg' description={description} onClick={onClick} />
    </RequirePermissions>
  )
}

function DeleteEvent({onClick, isHidden, description = 'Delete Event'}: Omit<EventListItemIconProps, 'isLoading'>) {
  if (isHidden) return null
  return (
    <RequirePermissions requiredPermissions={[requirePermissionAndScope('delete_event', ['group', 'event'])]}>
      <HoverIcon source='https://images.posh.vip/b2/delete.svg' description={description} onClick={onClick} />
    </RequirePermissions>
  )
}

function ViewAnalytics({
  onClick,
  description = 'View Analytics',
}: Pick<EventListItemIconProps, 'onClick' | 'description'>) {
  return <HoverIcon source={chartsUrl} description={description} onClick={onClick} />
}

export const EventsTableListItemActionsIcon = {
  RevertToDraft,
  GoLive,
  ViewEvent,
  EditEvent,
  DuplicateEvent,
  DeleteEvent,
  ViewAnalytics,
}
