import React from 'react'

import classNames from 'classnames'

import {ContainerProps} from '../../CrossSection/CrossSection'
import {TextProps} from '../Text/Text'

import styles from './AnalyticsSection.module.scss'

export const AnalyticsSection = (props: ContainerProps) => {
  const {children, className, style} = props
  return (
    <div className={classNames(styles.AnalyticsSection, className)} style={style}>
      {children}
    </div>
  )
}

export const AnalyticsHeader = (props: ContainerProps) => {
  const {children, className, style} = props
  return (
    <div className={classNames(styles.AnalyticsHeader, className)} style={style}>
      {children}
    </div>
  )
}

export const AnalyticsTitle = (props: TextProps) => {
  const {children, ...textProps} = props
  return <h3>{children}</h3>
}

AnalyticsSection.Header = AnalyticsHeader
AnalyticsSection.Title = AnalyticsTitle
