import React from 'react'
import {useNavigate} from 'react-router-dom'

import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useGroupBaseUrl, useGroupMatchParams} from 'domains/Groups/helpers'
import {useGroupSettingsPageParams} from 'pages/PoshAppLayout'

import GroupPageSubNav from '../SubNav'
import SettingsMap, {SettingsPage} from './map'

import './styles.scss'

const Settings = () => {
  const baseUrl = useGroupBaseUrl()
  const navigate = useNavigate()

  const {groupId} = useGroupMatchParams()
  const {data: group} = useFetchGroup(groupId)

  const changePage = (page: SettingsPage) => {
    navigate(`${baseUrl}/settings/${page.route}`)
  }
  const {category: currentCategory} = useGroupSettingsPageParams()

  const currentPage =
    SettingsMap.map(category => category.pages)
      .flat()
      .find(p => p.route === currentCategory) ?? SettingsMap[0].pages[0]

  return (
    <div className='GroupPage-content Settings'>
      <GroupPageSubNav group={group} hierarchy={['Settings']} />

      <div className='GroupPage-titleBar'>
        <h3>Organization Settings</h3>
      </div>

      <div className='Settings-panes'>
        <div>
          <div className='Settings-pane Settings-menu'>
            {SettingsMap.map(category => (
              <div key={category.category} className='Settings-menu-category'>
                <h4>{category.category}</h4>
                {category.pages.map(page => (
                  <div
                    key={page.title}
                    onClick={() => changePage(page)}
                    className={`Settings-menu-page ${currentCategory === page.route ? 'selected' : ''}`}>
                    <PoshImage src={page.icon} />
                    <span>{page.title}</span>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className='Settings-stpContent Settings-pane grow'>
            <h3>{currentPage.title}</h3>
            {currentPage.content}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
