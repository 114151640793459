import React, {PropsWithChildren, useEffect, useState} from 'react'

import {Button} from '@posh/design-kit/components/button'
import {palettes} from '@posh/design-tokens'
import {ExperimentKey, Time} from '@posh/model-types'
import {DEFAULT_ACCENT_COLOR, getRandomCreateEventFlyerPlaceholder} from '@posh/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import {CreatePendingEventInput} from 'apis/PendingEvent/useCreatePendingEvent'
import {AnalyzeWordsFromFlyerOutput, useAnalyzeWordsFromFlyer} from 'apis/Util/useAnalyzeWordsFromFlyer'
import {useGetImageColors} from 'apis/Util/useGetImageColors'
import {RSVPEventOption, SellTicketsEventOption} from 'components/assets/Icons'
import {GlassMorphicButton} from 'components/form/Button'
import {PictureState} from 'components/form/ImagePicker'
import {ImageSearchModal} from 'components/form/ImageSearchModal'
import {EventVisualsFlyer} from 'components/PageComponents/EventVisuals/Form/image/Flyer'
import {PageOverlay, PageOverlayProps} from 'components/PageComponents/EventVisuals/Page/PageOverlay/PageOverlay'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'
import {AnimatePresence, motion} from 'framer-motion'
import {useDefaultBackNavigation} from 'hooks/navigation/useDefaultBackNavigation'
import {useUploadImage} from 'hooks/uploadImage/useUploadImage'
import {useDimensions} from 'hooks/useDimensions'
import {useExperiment} from 'hooks/useExperiment'
import {useTrackRudderStackEvent} from 'hooks/useTrackRudderstackEvent'

import {useCreateEventParams} from '../useCreateEventParams'

import styles from './styles.module.scss'

interface CreateEventOnboardingOverlayProps extends PageOverlayProps {
  onCreateEvent: (params: CreatePendingEventInput, displayFlyerAnalysisToast?: boolean) => void
  isSubmitting?: boolean
}

const DISALLOWED_PROVIDERS = ['tenor', 'unsplash']

// Animation variables
const ANIMATION_DELAY = new Time('0.5 seconds')
const ANIMATION_INTERVAL = new Time('0.2 seconds')

const CREATE_EVENT_OPTION_ICON_SIZE_DESKTOP = 100
const CREATE_EVENT_OPTION_ICON_SIZE_MOBILE = 75

export const CreateEventOnboardingOverlay = (props: CreateEventOnboardingOverlayProps) => {
  const {onCreateEvent, isSubmitting, isOpen} = props
  const {trackEvent} = useMixpanel()
  const {currentUser} = useSessionContext()

  const {groupId} = useCreateEventParams()
  const {goBack: onPressCancel} = useDefaultBackNavigation()
  const flyerPlaceholder = getRandomCreateEventFlyerPlaceholder()
  const {showToast} = useToast()

  const [flyer, setFlyerOnForm] = useState<string | undefined>(undefined)
  const [flyerIdOnForm, setFlyerIdOnForm] = useState<string | undefined>(undefined)
  const [isShowingFlyerAnalysisOverlay, setIsShowingFlyerAnalysisOverlay] = useState(false)
  const [accentColor, setAccentColor] = useState<string>(DEFAULT_ACCENT_COLOR)
  const [hasPressedContinue, setHasPressedContinue] = useState(false)
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false)
  const [attributesFromFlyer, setAttributesFromFlyer] = useState<AnalyzeWordsFromFlyerOutput | undefined>(undefined)
  const [pendingIsPaidEvent, setPendingIsPaidEvent] = useState<boolean | undefined>(undefined)
  const {trackAiFlyerUploaded} = useTrackRudderStackEvent()

  const {mutateAsync: analyzeWordsFromFlyer, isLoading: isAnalyzingWordsFromFlyer} = useAnalyzeWordsFromFlyer({
    onSuccess: data => {
      setAttributesFromFlyer(data)
      if (aiFlyerExperiment.value) {
        trackAiFlyerUploaded(true, {experiment: aiFlyerExperiment})
      }
    },
  })

  const flyerIsntFromDisallowedProviders = flyer && !DISALLOWED_PROVIDERS.some(provider => flyer.includes(provider))

  const onNoFlyerContinue = () => {
    if (aiFlyerExperiment.value) {
      trackAiFlyerUploaded(false, {experiment: aiFlyerExperiment})
    }
    onContinueProcess()
  }

  const onContinueProcess = () => {
    if (isAnalyzingWordsFromFlyer) return setHasPressedContinue(true)
    const hasFilledNonControlledFields = !!attributesFromFlyer
    onCreateEvent(
      {
        isPaidEvent: pendingIsPaidEvent,
        groupId,
        defaultAttributes: {
          flyer: flyer!,
          accentColor,
          endUtc: attributesFromFlyer?.attributesObject?.endDate?.text ?? undefined,
          startUtc: attributesFromFlyer?.attributesObject?.startDate?.text ?? undefined,
          name: attributesFromFlyer?.attributesObject?.eventName?.text ?? undefined,
          address: attributesFromFlyer?.locationObject?.formattedAddress ?? undefined,
          venueName: attributesFromFlyer?.attributesObject?.venueName?.text ?? undefined,
          timezone: attributesFromFlyer?.attributesObject?.timezone?.text ?? undefined,
          coordinates:
            !!attributesFromFlyer?.locationObject?.lat && !!attributesFromFlyer?.locationObject?.lng
              ? [attributesFromFlyer.locationObject.lat, attributesFromFlyer.locationObject.lng]
              : undefined,
        },
      },
      hasFilledNonControlledFields,
    )
    setPendingIsPaidEvent(undefined)
    setHasPressedContinue(false)
    trackEvent('Create Event - Continue Creating', {isAuthed: !!currentUser, isFreeEvent: !pendingIsPaidEvent})
  }

  // Handle continuing event creation when analysis completes
  useEffect(() => {
    if (pendingIsPaidEvent === undefined) return
    if (!isAnalyzingWordsFromFlyer && hasPressedContinue) {
      onContinueProcess()
    }
  }, [isAnalyzingWordsFromFlyer, hasPressedContinue])

  const aiFlyerExperiment = useExperiment({
    experimentKey: ExperimentKey.AI_FLYER,
    enabled: pendingIsPaidEvent,
  })

  useEffect(() => {
    if (pendingIsPaidEvent === undefined) return
    if (pendingIsPaidEvent === false) onContinueProcess()
    if (!aiFlyerExperiment.value) onContinueProcess()
    else setIsShowingFlyerAnalysisOverlay(true)
  }, [pendingIsPaidEvent])

  const {isMobile} = useDimensions()

  const createEventOptionIconSize = isMobile
    ? {
        width: CREATE_EVENT_OPTION_ICON_SIZE_MOBILE,
        height: CREATE_EVENT_OPTION_ICON_SIZE_MOBILE,
      }
    : {
        width: CREATE_EVENT_OPTION_ICON_SIZE_DESKTOP,
        height: CREATE_EVENT_OPTION_ICON_SIZE_DESKTOP,
      }
  const {refetch} = useGetImageColors({imageUrl: flyer!}, {enabled: !!flyer})
  const {uploadImage: uploadAndSetOnForm, isUploading} = useUploadImage({
    imageType: 'event-flyer',
    onSuccess: async (imageId, imageUrl) => {
      setFlyerOnForm(imageUrl)
      setFlyerIdOnForm(imageId)
    },
    onError: () => {
      showToast({type: 'error', title: 'There was an error uploading the image, please select another one'})
    },
  })

  const openImagePickerModal = () => {
    setIsImagePickerOpen(true)
  }

  const closeImagePickerModal = () => {
    setIsImagePickerOpen(false)
  }

  const onImageChange = (img: PictureState) => {
    if (img.file) return uploadAndSetOnForm(img.file)
    else return setFlyerOnForm(img.url ? img.url : undefined)
  }
  const continueButtonDisabled = isUploading || (isAnalyzingWordsFromFlyer && hasPressedContinue)
  const continueButtonDisabledReason = isUploading
    ? 'Uploading image...'
    : isAnalyzingWordsFromFlyer && hasPressedContinue
      ? 'Initializing event...'
      : 'Continue'

  useEffect(() => {
    // Only update the accentColor when the flyer changes.
    if (!flyer) return
    refetch().then(({data: imageColors}) => {
      if (imageColors) setAccentColor(imageColors.vibrant ?? DEFAULT_ACCENT_COLOR)
    })
    if (flyerIsntFromDisallowedProviders) {
      // Only analyze the words from the flyer if it's not from Tenor or Unsplash, do this in the background
      analyzeWordsFromFlyer({flyerUrl: flyer})
    }
  }, [flyer])

  const titleClass = 'm-0 text-center'

  return (
    <>
      <PageOverlay isOpen={isOpen}>
        {flyer && (
          <div
            className='absolute inset-0 z-0 bg-cover bg-fixed bg-center bg-no-repeat blur-[30px]'
            style={{
              backgroundImage: `url(${flyer})`,
            }}
          />
        )}
        <motion.div
          style={{position: 'relative', zIndex: 1, width: '100%', height: '100%'}}
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          transition={{duration: ANIMATION_DELAY.toSeconds()}}
          className={styles.CreateEventOverlayContent}>
          <AnimatePresence mode='wait'>
            {isShowingFlyerAnalysisOverlay ? (
              <motion.div
                key='flyer-analysis'
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: ANIMATION_DELAY.toSeconds()}}>
                {!flyer && <h1 className={titleClass}>Create Your Event Faster with AI</h1>}
                <motion.div
                  className={styles.CreateEventOptionsContainer}
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  transition={{duration: ANIMATION_DELAY.toSeconds()}}>
                  <EventVisualsFlyer
                    image={flyer}
                    isUploadingImage={false}
                    flyerPlaceholder={flyerPlaceholder}
                    flyerPlaceholderText={'Upload your flyer to quickly generate your event'}
                    openImagePicker={openImagePickerModal}
                    lightMode={false}
                    accentColor={accentColor}
                    useDirectUpload={true}
                    onFileSelect={onImageChange}
                    showClickAboveText={!!flyer}
                  />
                </motion.div>
                <motion.div
                  className={styles.Buttons}
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  transition={{duration: ANIMATION_DELAY.toSeconds()}}>
                  {flyer ? (
                    <GlassMorphicButton
                      onClick={onContinueProcess}
                      disabled={continueButtonDisabled}
                      isLoading={isAnalyzingWordsFromFlyer && hasPressedContinue}>
                      {continueButtonDisabledReason}
                    </GlassMorphicButton>
                  ) : (
                    <Button variant={'link'} tabIndex={0} onClick={onNoFlyerContinue}>
                      Skip and manually create your event
                    </Button>
                  )}
                </motion.div>
              </motion.div>
            ) : (
              <motion.div
                key='event-options'
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: ANIMATION_DELAY.toSeconds()}}>
                <motion.div
                  className={styles.titleWrapper}
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  transition={{duration: ANIMATION_DELAY.toSeconds()}}>
                  <h1 className={titleClass}>{`Let's launch your next event`}</h1>
                </motion.div>
                <motion.div
                  className={styles.CreateEventOptionsContainer}
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  transition={{duration: ANIMATION_DELAY.toSeconds()}}>
                  <CreateEventOption isDisabled={isSubmitting} tilt='left' onClick={() => setPendingIsPaidEvent(true)}>
                    <SellTicketsEventOption {...createEventOptionIconSize} />
                    <h4>Sell tickets</h4>
                  </CreateEventOption>
                  <CreateEventOption
                    isDisabled={isSubmitting}
                    tilt='right'
                    onClick={() => setPendingIsPaidEvent(false)}>
                    <RSVPEventOption {...createEventOptionIconSize} />
                    <h4>RSVP Only</h4>
                  </CreateEventOption>
                </motion.div>
                <motion.div
                  className={styles.Buttons}
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  transition={{duration: ANIMATION_DELAY.toSeconds()}}>
                  <Button variant={'link'} tabIndex={0} onClick={() => onPressCancel()}>
                    Cancel
                  </Button>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </PageOverlay>
      {isImagePickerOpen && (
        <ImageSearchModal
          setFile={onImageChange}
          isOpen={isImagePickerOpen}
          onClose={closeImagePickerModal}
          width={IMAGE_SEARCH_MODAL_WIDTH}
        />
      )}
    </>
  )
}

const CREATE_EVENT_OPTION_HOVER_SCALE = 1.03
const CREATE_EVENT_OPTION_HOVER_ROTATE = 3
const CREATE_EVENT_OPTION_TAP_SCALE = 0.97

const CreateEventOption = (
  props: PropsWithChildren<{
    onClick: () => void
    tilt: 'left' | 'right'
    isDisabled?: boolean
  }>,
) => {
  const {children, onClick, tilt, isDisabled = false} = props

  return (
    <motion.button
      disabled={isDisabled}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{
        ease: 'linear',
        duration: ANIMATION_DELAY.toSeconds() + 2 * ANIMATION_INTERVAL.toSeconds(),
        scale: 10,
        rotate: 5,
      }}
      whileTap={{scale: CREATE_EVENT_OPTION_TAP_SCALE}}
      whileHover={{
        scale: CREATE_EVENT_OPTION_HOVER_SCALE,
        rotate: tilt === 'left' ? -CREATE_EVENT_OPTION_HOVER_ROTATE : CREATE_EVENT_OPTION_HOVER_ROTATE,
      }}
      style={{
        backgroundColor: palettes.grayIron.value[950],
      }}
      className={styles.CreateEventOption}
      onClick={onClick}>
      {children}
    </motion.button>
  )
}
const IMAGE_SEARCH_MODAL_WIDTH = 780
