import React, {PropsWithChildren} from 'react'
import {Outlet, Route, Routes, useLocation, useNavigate} from 'react-router-dom'

import {dateFromObjectId, isAllowedTapToPayCountry} from '@posh/model-types'
import {TapToPayDisabled, TapToPayEnabled, TapToPayState, TapToPayTooltips} from '@posh/types'
import {EventModel} from 'apis/Events/types'
import {useGetGroupForTapToPay} from 'apis/Groups/useGetGroupForTapToPay'
import {useMixpanel} from 'apis/MixPanelHandler'
import {useCheckPermissions} from 'apis/Permissions/useHasPermissionsEnabled'
import {useToggleEventTapToPayAbility} from 'apis/TapToPay/useToggleTapToPayAbility'
import classNames from 'classnames'
import BallOfTruth from 'components/BallOfTruth'
import {DB_BACKED_FEATURES, useIsFeatureEnabled} from 'components/FeatureFlag'
import Button from 'components/form/Button'
import {useSetContextButton} from 'components/Navigation/NavigationContext'
import {TapToPayStateSlider} from 'components/TapToPay/TapToPayStateSlider'
import {isEventKickbackEligible} from 'helpers/isEventKickbackEligible'
import {useDimensions} from 'hooks/useDimensions'
import moment from 'moment-timezone'
import AttendeesCrmContextProvider from 'pages/GroupPage/Marketing/AttendeesCrmContextProvider'
import PoshHeader from 'pages/OwnerPage/PoshHeader'
import PageNotFound from 'pages/PageNotFound/PageNotFound'
import {calculateEventStatus} from 'pages/Util/timezoneHelper'
import {FeatureFlagOverrideProvider} from 'providers/FeatureFlagProvider'

import useFetchEvent from '../../apis/Events/useFetchEvent'
import {
  attendeesUrl,
  attendeesUrlGold,
  brushUrl,
  brushUrlGold,
  chartsgoldUrl,
  chartsUrl,
  Eye,
  eyeUrl,
  hostsUrl,
  hostsUrlGold,
  settingsUrl,
  settingsUrlGold,
  tableGoldUrl,
  tableUrl,
  ticketsUrl,
  ticketsUrlGold,
} from '../../components/assets/Icons'
import IconButton from '../../components/form/IconButton'
import RequirePermissions, {requirePermissionAndScope} from '../../components/RequirePermissions'
import {PoshPageSectionAttributes} from '../GroupPage/GroupRoutes'
import {useResourcePageParams} from '../PoshAppLayout'
import Approvals from './Approvals'
import Attendees from './Attendees'
import {EventManagementContextProvider} from './EventManagementContext'
import EventPageNavigation from './EventPageNavigation'
import KickbackRouter from './KickbackRouter'
import MobileEventPageNavigation from './MobileEventPageNavigation'
import {OverviewWrapper} from './Overview'
import Settings from './Settings'
import SwitchToApprovalBtn from './SwitchToApprovalBtn'
import Tables from './Tables'
import TeamMembers from './TeamMembers'
import TicketsRoutes from './Tickets'
import Visuals from './Visuals'

import './styles.scss'
import './EventPageSectionHeader.scss'
import layoutStyles from './layout.module.scss'

export enum EventPageSection {
  OVERVIEW = 'overview',
  VISUALS = 'visuals',
  APPROVALS = 'approvals',
  TICKETS = 'tickets',
  ATTENDEES = 'attendees',
  TEAM_MEMBERS = 'team',
  TABLES = 'tables',
  KICKBACK = 'kickback',
  SETTINGS = 'settings',
}

export interface PoshEventPageSectionAttributes extends PoshPageSectionAttributes {
  tooltip: string
  icon?: string
  activeIcon?: string
  backgroundClassName?: string
  showAlert?: boolean
  showEventPageSectionHeader?: boolean
}

interface EventPageWrapperProps {
  section: EventPageSection
  hideNavigation?: boolean
  event: EventModel
  action?: React.ReactNode
  backgroundClassName?: string
  showEventPageSectionHeader?: boolean
  tapToPaySlider?: React.ReactNode
}

interface EventPageSectionHeaderProps {
  section: EventPageSection
  eventName: string
  status: string
  timezone: string
  eventStart: moment.Moment
  eventEnd?: moment.Moment
  rightHeaderComponent?: React.ReactNode
}

const EventPageSectionHeader = (props: EventPageSectionHeaderProps) => {
  const {section, eventName, status, timezone, eventStart, eventEnd, rightHeaderComponent} = props
  const eventStatus = calculateEventStatus(eventEnd, status)
  const isLive = eventStatus == 'live'
  const isDraft = eventStatus === 'draft'

  const title = section.charAt(0).toUpperCase() + section.slice(1)
  return (
    <>
      <div className='EventPageSectionHeader'>
        <div className='EventPageSectionHeader-identifiers pshSubPageHeader'>
          {eventName}
          {section !== 'overview' && (
            <>
              <span className='EventPageSectionHeader-separator'> — </span>
              <span className={'EventPageSectionHeader-identifiers'}>{title}</span>
            </>
          )}
          {!!rightHeaderComponent && rightHeaderComponent}
        </div>
        <div className='EventPageSectionHeader-details'>
          <span className={`EventPageSectionHeader-details--status ${isDraft ? 'orange' : isLive ? 'green' : ''}`}>
            <BallOfTruth isLive={isLive} isDraft={isDraft} /> {eventStatus}
          </span>
          <span className='EventPageSectionHeader-details--countDown'>
            --{' '}
            {isLive
              ? `event starts ${eventStart.fromNow()} (${moment.tz.zone(timezone)?.abbr(360)})`
              : eventStart.fromNow()}
          </span>
        </div>
      </div>
    </>
  )
}

/**
 * This is a layout component for the Event Management pages.
 * @returns
 */
function EventPageLayout() {
  const {isMobile} = useDimensions()
  const {pathname} = useLocation()
  const isOnUpgradedVisualsPage = pathname.includes('visuals')

  const {isFeatureEnabled} = useIsFeatureEnabled(DB_BACKED_FEATURES.WEB_NAVIGATION_REDESIGN)
  const containerClassNames = classNames([layoutStyles.navigation, {[layoutStyles.hidden]: isOnUpgradedVisualsPage}])

  return (
    <div className={layoutStyles.container}>
      {isMobile ? ( // always show mobile navigation
        <div className={containerClassNames}>
          <MobileEventPageNavigation />
        </div>
      ) : !isFeatureEnabled ? ( // otherwise show desktop navigation if feature flag is NOT enabled
        <div className={containerClassNames}>
          <EventPageNavigation />
        </div>
      ) : (
        <></>
      )}
      <Outlet />
    </div>
  )
}

const EventPageWrapper = (props: PropsWithChildren<EventPageWrapperProps>) => {
  const {event, tapToPaySlider, backgroundClassName, showEventPageSectionHeader = true} = props
  const isKickbackPage = props.section === 'kickback'

  return (
    <div className={`EventManagementPage ${backgroundClassName} ${isKickbackPage && 'kickback'}`}>
      <div
        className={classNames('EventManagementPage-content', {
          [`EventManagementPage-content-${backgroundClassName}`]: !!backgroundClassName,
        })}>
        {showEventPageSectionHeader && (
          <div className='EventManagementPage-content--header'>
            <EventPageSectionHeader
              section={props.section}
              eventName={event.name}
              timezone={event.timezone}
              status={event.status ?? ''}
              eventStart={moment.tz(event.startUtc, event.timezone)}
              eventEnd={moment.tz(event.endUtc, event.timezone)}
              rightHeaderComponent={tapToPaySlider}
            />
            {props.action}
          </div>
        )}
        {props.children}
      </div>
    </div>
  )
}

export const EVENTS_ROUTE_MAP: {[key in EventPageSection]: PoshEventPageSectionAttributes} = {
  [EventPageSection.OVERVIEW]: {
    route: 'overview',
    content: <OverviewWrapper />,
    icon: chartsUrl,
    activeIcon: chartsgoldUrl,
    tooltip: 'Overview',
  },
  [EventPageSection.VISUALS]: {
    route: 'visuals',
    content: <Visuals />,
    icon: brushUrl,
    activeIcon: brushUrlGold,
    requiredPermissions: [requirePermissionAndScope('edit_event_visuals', ['event', 'group'])],
    tooltip: 'Visuals',
    showEventPageSectionHeader: false,
  },
  [EventPageSection.APPROVALS]: {
    route: 'approvals',
    content: <Approvals />,
    icon: 'https://images.posh.vip/b2/pending-black.svg',
    activeIcon: 'https://images.posh.vip/b2/pending-gold.svg',
    requiredPermissions: [requirePermissionAndScope('edit_event_tickets', ['event', 'group'])],
    tooltip: 'Pending Guests',
  },
  [EventPageSection.TICKETS]: {
    route: 'tickets',
    content: <TicketsRoutes />,
    icon: ticketsUrl,
    activeIcon: ticketsUrlGold,
    requiredPermissions: [requirePermissionAndScope('edit_event_tickets', ['event', 'group'])],
    tooltip: 'Tickets',
  },
  [EventPageSection.ATTENDEES]: {
    route: 'attendees',
    content: (
      <AttendeesCrmContextProvider>
        <Attendees />
      </AttendeesCrmContextProvider>
    ),
    icon: attendeesUrl,
    activeIcon: attendeesUrlGold,
    requiredPermissions: [requirePermissionAndScope('view_attendee_list', ['event', 'group'])],
    tooltip: 'Attendees',
  },
  [EventPageSection.TEAM_MEMBERS]: {
    route: 'team',
    content: <TeamMembers />,
    icon: hostsUrl,
    activeIcon: hostsUrlGold,
    tooltip: 'Team & Tracking',
  },
  [EventPageSection.TABLES]: {
    route: 'tables',
    content: <Tables />,
    icon: tableUrl,
    activeIcon: tableGoldUrl,
    requiredPermissions: [requirePermissionAndScope('edit_event_tables', ['event', 'group'])],
    tooltip: 'Tables',
  },
  [EventPageSection.KICKBACK]: {
    route: 'kickback',
    content: <KickbackRouter />,
    icon: 'https://images.posh.vip/b2/kickback-black.png',
    activeIcon: 'https://images.posh.vip/b2/kickback-Recovered-k.png',
    requiredPermissions: [requirePermissionAndScope('enable_kickbacks', ['event', 'group'])],
    tooltip: 'Kickback',
  },
  [EventPageSection.SETTINGS]: {
    route: 'settings',
    content: <Settings />,
    icon: settingsUrl,
    activeIcon: settingsUrlGold,
    requiredPermissions: [requirePermissionAndScope('edit_event_settings', ['event', 'group'])],
    tooltip: 'Settings',
  },
}

export const HOST_SECTIONS = [EventPageSection.OVERVIEW, EventPageSection.ATTENDEES, EventPageSection.TEAM_MEMBERS]

const now = moment()

const EventRoutesContent = () => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {eventId, domain, groupId} = useResourcePageParams()
  const {data: fetchEventData, refetch: refetchEventData} = useFetchEvent(eventId!)

  // Then we check their event edit permissions (if the feature flag is enabled)
  const {hasPermission: hasEditGroupSettingsPermission, isLoading: isLoadingEditGroupSettingsPermission} =
    useCheckPermissions(['edit_group_settings'], groupId!, undefined, {
      staleTime: 60 * 1000,
      enabled: !!groupId,
    })
  const {hasPermission: hasEditEventSettingsPermission, isLoading: isLoadingEditEventSettingsPermission} =
    useCheckPermissions(['edit_event_settings'], groupId!, eventId!, {
      staleTime: 60 * 1000,
      enabled: !!groupId && !!eventId,
    })
  const {data: groupTapToPayData, isLoading: isLoadingGroupTapToPay} = useGetGroupForTapToPay(
    {groupId: groupId!, eventId: eventId!},
    {enabled: !!groupId && !!eventId},
  )
  const {mutate: mutateEventTapToPayState} = useToggleEventTapToPayAbility({
    onSuccess: () => {
      refetchEventData()
    },
  })

  const {search, pathname} = useLocation()
  const isOnUpgradedVisualsPage = pathname.includes('visuals')
  const navigate = useNavigate()

  const navigateToEventPage = () => {
    window.open(`/e/${event.url}` + search, '_blank')
  }

  useSetContextButton({
    content: (
      <>
        <Eye />
        View Event Page
      </>
    ),
    onClick: navigateToEventPage,
  })

  if (!fetchEventData) return null
  const {event} = fetchEventData

  const groupTapToPayState: TapToPayState = groupTapToPayData?.tapToPayState ?? 'not setup'
  const eventTapToPayState: TapToPayState = event.isTapToPayDisabled ? 'disabled' : 'enabled'
  const disabledTooltip = (() => {
    // If tap to pay isn't set up on the group, then show tooltip based on if they can set it up
    if (groupTapToPayState === 'not setup' && !hasEditGroupSettingsPermission) {
      return TapToPayTooltips.NotSetup_NoPermission
    }

    // If tap to pay is disabled on the group, then show tooltip still based on if they can enable it
    if (groupTapToPayState == TapToPayDisabled) {
      if (hasEditGroupSettingsPermission) {
        return TapToPayTooltips.GroupDisabled_HasPermission_Web
      }
      return TapToPayTooltips.GroupDisabled_NoPermission
    }

    // If tap to pay is disabled on the event and they can't just enable it here, show them a tooltip
    if (eventTapToPayState == TapToPayDisabled && !hasEditEventSettingsPermission) {
      return TapToPayTooltips.EventDisabled_NoPermission
    }

    // Even if tap to pay is enabled, if they don't have permission to toggle it then disable and tell them that.
    if (eventTapToPayState == TapToPayEnabled && !hasEditEventSettingsPermission) {
      return TapToPayTooltips.EventEnabled_NoPermission
    }

    return undefined
  })()
  const handleEventTapToPayStateChanged = (tapToPayState: TapToPayState) => {
    mutateEventTapToPayState({
      eventId: event._id,
      enabled: tapToPayState == 'enabled',
    })
  }
  const renderTapToPaySlider =
    isAllowedTapToPayCountry(groupTapToPayData?.country) &&
    !isLoadingEditGroupSettingsPermission &&
    !isLoadingEditEventSettingsPermission &&
    !isLoadingGroupTapToPay
  const TapToPaySlider = renderTapToPaySlider ? (
    <div className='absolute right-4'>
      <TapToPayStateSlider
        label='In-Person Payments Enabled'
        isDisabled={disabledTooltip !== undefined}
        tooltip={disabledTooltip}
        groupTapToPayState={groupTapToPayState}
        eventTapToPayState={eventTapToPayState}
        tapToPayEventStateChanged={handleEventTapToPayStateChanged}
      />
    </div>
  ) : null

  const eventRoutes = Object.keys(EVENTS_ROUTE_MAP).map(eventSection => {
    const action = () => {
      if (eventSection == EventPageSection.VISUALS) {
        const onClick = () => {
          trackMixpanelEvent('Copy Link- Curator Event Overview Page', {eventStatus: event.status ?? ''})
          navigateToEventPage()
        }
        return (
          <>
            <IconButton className='gold' icon={eyeUrl} onClick={onClick}>
              View Event Page
            </IconButton>
          </>
        )
      } else if (eventSection == EventPageSection.ATTENDEES && event.isRSVPEvent) {
        return <SwitchToApprovalBtn event={event} />
      } else if (eventSection == EventPageSection.OVERVIEW) {
        return (
          <>
            <div className='buttonWrapper'>
              {event.isRSVPEvent && (
                <Button
                  className='light gold'
                  onClick={() => navigate(`/${domain}/groups/${groupId}/events/${eventId}/tickets/create` + search)}>
                  + Add Paid Ticket Type
                </Button>
              )}
            </div>
          </>
        )
      } else {
        return null
      }
    }

    const {route, content, requiredPermissions, backgroundClassName, showEventPageSectionHeader} =
      EVENTS_ROUTE_MAP[eventSection as EventPageSection]

    const venue = event.venue
    if (!venue && eventSection == EventPageSection.TABLES) return null
    if (
      !isEventKickbackEligible({isRSVPEvent: event.isRSVPEvent, country: fetchEventData.group.country}) &&
      eventSection == EventPageSection.KICKBACK
    )
      return null

    return (
      <Route
        path={route + '/*'}
        key={route}
        element={
          <EventPageWrapper
            section={eventSection as EventPageSection}
            tapToPaySlider={
              (eventSection as EventPageSection) === EventPageSection.TICKETS && !!TapToPaySlider
                ? TapToPaySlider
                : undefined
            }
            event={event}
            action={action()}
            hideNavigation={isOnUpgradedVisualsPage}
            backgroundClassName={
              isOnUpgradedVisualsPage
                ? ' hidden'
                : pathname.includes('overview')
                  ? 'Analytics'
                  : // END TODO
                    backgroundClassName
            }
            showEventPageSectionHeader={showEventPageSectionHeader && !isOnUpgradedVisualsPage}>
            {requiredPermissions ? (
              <RequirePermissions requiredPermissions={requiredPermissions}>{content}</RequirePermissions>
            ) : (
              content
            )}
          </EventPageWrapper>
        }
      />
    )
  })

  const eventData = {
    ...fetchEventData,
    event: {
      ...event,
      isOver: now.isAfter(moment(event.endUtc)),
      createdAt: dateFromObjectId(event._id),
    },
  }

  return (
    <div style={{height: '100dvh', display: 'flex', flexDirection: 'column'}}>
      <PoshHeader desktopOnly />
      <EventManagementContextProvider eventData={eventData}>
        <Routes>
          <Route element={<EventPageLayout />}>
            {eventRoutes}
            <Route path={'*'} element={<PageNotFound />} />
          </Route>
        </Routes>
      </EventManagementContextProvider>
    </div>
  )
}

export const EventRoutes = () => {
  const {groupId, eventId} = useResourcePageParams()

  // Wrap all the event routes content in a feature flag provider that sets the group ID and event IDfor feature flag checks
  return (
    <FeatureFlagOverrideProvider groupId={groupId} eventId={eventId}>
      <EventRoutesContent />
    </FeatureFlagOverrideProvider>
  )
}
