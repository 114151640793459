import React from 'react'

import {isCustomAmountTicketTypeId} from '@posh/model-types'
import {OrderModel} from 'apis/Orders'
import {useGetTransferredOrder} from 'apis/Orders/transfers/useGetTransferredOrder'
import {Card} from 'components/Card'
import {useGroupMatchParams} from 'domains/Groups/helpers'

import {Link} from '../../../../EventManagementPages/OverviewUpdate/Analytics/Orders/OrderItem/OrderItem'
interface TransferredLabelAndLinkProps {
  transferDocId: NonNullable<OrderModel['tickets'][number]['sentToTransferDocId']>
}

const TransferredLabelAndLink = ({transferDocId}: TransferredLabelAndLinkProps) => {
  const {domain, groupId} = useGroupMatchParams()
  const {data} = useGetTransferredOrder({
    transferId: transferDocId,
    transferRole: 'organizer',
    status: 'accepted',
  })

  const transferOrderLink = !!data?.transfer.toOrderId
    ? `/${domain}/groups/${groupId}/orders/${data.transfer.toOrderId}`
    : null

  return (
    <span className='OrderPage-ticketsTable-info'>
      {transferOrderLink ? (
        <>
          <Link to={transferOrderLink}>View transferred order</Link>
        </>
      ) : (
        <>- Transferred</>
      )}
    </span>
  )
}

const OrderCardTickets = ({order}: {order: OrderModel}) => {
  return (
    <Card animationIndex={0}>
      <table className='OrderPage-ticketsTable GroupPage-table'>
        <tr>
          <th>Item</th>
          <th>Quantity</th>
          <th></th>
          <th>Price</th>
        </tr>
        {order.tickets.map((t, index) => (
          <tr className='OrderPage-ticketsTable-ftd' key={index}>
            <td>
              <span className='OrderPage-ticketsTable-name'>{t.name}</span>{' '}
              {t.sentToTransferDocId && <TransferredLabelAndLink transferDocId={t.sentToTransferDocId} />}
              {isCustomAmountTicketTypeId(t.typeID) && (
                <span className='OrderPage-ticketsTable-info'> - Custom Amount</span>
              )}
              <br />${t.price.toFixed(2)}
            </td>
            <td>x1</td>
            <td></td>
            <td>{order.isComplimentaryOrder ? <i>Free</i> : `$${t.price.toFixed(2)}`}</td>
          </tr>
        ))}
        {order.promoCode && order.discount && (
          <tr>
            <td></td>
            <td className='OrderPage-ticketsTable-promoCode'>
              <span>Promo code &quot;{order.promoCode}&quot; applied</span>
            </td>
            <td></td>
            <td>-${order.discount.toFixed(2)}</td>
          </tr>
        )}
        <tr className='grey'>
          <td></td>
          <td></td>
          <td>Subtotal</td>
          <td>${order.subtotal.toFixed(2)}</td>
        </tr>
        {order.feeBreakdown?.map(fee => (
          <tr key={fee.name} className='grey'>
            <td></td>
            <td></td>
            <td>{fee.name}</td>
            <td>${fee.totalFee.toFixed(2)}</td>
          </tr>
        ))}
        <tr className='large'>
          <td></td>
          <td></td>
          <td>Total</td>
          <td>${order.total.toFixed(2)}</td>
        </tr>
      </table>
    </Card>
  )
}

export default OrderCardTickets
