import React from 'react'
import {useParams} from 'react-router-dom'

import {useFetchOrdersAndEvents} from 'apis/Accounts/useFetchOrdersAndEvents'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {useGetGroupAccount} from 'apis/Groups/useGetGroupAccount'
import CheckoutFieldResponses from 'components/CheckoutFieldResponses/CheckoutFieldResponses'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import OrderCardCustomer from 'pages/GroupPage/Orders/OrderPage/OrderPageCards/customer'
import OrderCardOrders from 'pages/GroupPage/Orders/OrderPage/OrderPageCards/orders'
import CustomerCardTimeline from 'pages/GroupPage/Orders/OrderPage/OrderPageCards/timelinecustomer'
import GroupPageSubNav from 'pages/GroupPage/SubNav'

import './styles.scss'

export default function AttendeeProfilePage() {
  const {attendeeId} = useParams<{attendeeId: string}>()
  const {groupId} = useParams<{groupId: string}>()
  const {data: group} = useFetchGroup(groupId)
  const {data: account} = useGetGroupAccount({
    groupId: groupId!,
    accountId: attendeeId!,
  })
  const {data: orderAndEvents} = useFetchOrdersAndEvents(attendeeId)

  return (
    <div className='GroupPage'>
      <div className='GroupPage-content'>
        {account ? (
          <>
            <GroupPageSubNav
              group={group}
              hierarchy={['Marketing', 'Attendees', `${account.firstName} ${account.lastName}`]}
            />

            <div className='GroupPage-cols'>
              <div className='small'>
                <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_orders_list', ['group'])]}>
                  {attendeeId && groupId && <OrderCardCustomer attendeeId={attendeeId} groupId={groupId} />}
                </RequirePermissions>
              </div>
              <div className='expand'>
                <OrderCardOrders orders={orderAndEvents?.map(oe => oe.order)} />
                <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_orders_list', ['group'])]}>
                  <CustomerCardTimeline account={account} orders={orderAndEvents?.map(oe => oe.order)} />
                </RequirePermissions>
                <CheckoutFieldResponses
                  responses={orderAndEvents?.map(oe => ({
                    eventName: oe.event.name,
                    eventResponses: oe.order.customFieldResponses,
                    eventId: oe.event._id,
                  }))}
                />
              </div>
            </div>
          </>
        ) : (
          <SpinLoader height={100} />
        )}
      </div>
    </div>
  )
}
