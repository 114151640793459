import React from 'react'

import {PoshLogo} from 'components/PoshLogo'

import {AppStoreButtons} from '../../components/AppStore/AppStoreButtons'

import './styles.scss'

const MobileAppLanding = () => {
  return (
    <>
      <div className='MALWrapper'>
        <PoshLogo.Link />
        <AppStoreButtons />
      </div>
    </>
  )
}

export default MobileAppLanding
