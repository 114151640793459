import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type TGetGroupForTapToPayInput = RouterInputs['groups']['getGroupForTapToPay']
export type TGetGroupForTapToPayOutput = RouterOutput['groups']['getGroupForTapToPay']
type TGetGroupForTapToPayOptions = ReactQueryOptions['groups']['getGroupForTapToPay']

export const useGetGroupForTapToPay = (input: TGetGroupForTapToPayInput, opts?: TGetGroupForTapToPayOptions) => {
  return trpc.groups.getGroupForTapToPay.useQuery(input, opts)
}

// To minimize the number of requests, we only refetch the group country if the group is not already in the cache
export const useGetGroupCountryForTapToPay = (input: TGetGroupForTapToPayInput) => {
  const trpcContext = trpc.useContext()
  const data = trpcContext.groups.getGroupForTapToPay.getData(input)
  const query = trpc.groups.getGroupForTapToPay.useQuery(input, {
    enabled: !data,
  })
  return {
    ...query,
    data: data?.country,
  }
}
