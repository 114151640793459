import React, {useState} from 'react'
import {useLocation} from 'react-router-dom'

import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import {LoginBackground} from 'pages/LoginPage/Background'

import {useResetPassword} from '../../../apis/Accounts/useResetPassword'

import './index.scss'

const ResetPasswordSubmit = () => {
  const [password, setPassword] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState('')
  const [accountToConfirm, setAccountToConfirm] = useState('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const {search} = useLocation()
  const query = new URLSearchParams(search)
  const token = query.get('token') as string
  const email = query.get('email') as string
  const {mutateAsync: resetPassword, isLoading} = useResetPassword()

  const onSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault()
      setErrorMessage('')
      if (password.length < 8) throw new Error('Password Must be at least 8 characters.')
      if (password !== confirmPassword) throw new Error('Passwords Must Match')
      await resetPassword({email, token, password})
      window.location.href = '/login'
    } catch (error) {
      if (error.response) {
        if (error.response.data.accountId) setAccountToConfirm(error.response.data.accountId)
        else setErrorMessage(error.response.data.error)
      } else setErrorMessage(error.message)
    }
  }
  return (
    <>
      <PoshHelmet title='Posh - Reset Password' />
      <div className='LoginPage'>
        <LoginBackground />
        <div className='RequestPasswordReset-container'>
          <h4 className='center m-0'>Reset Password</h4>
          <p className='center white text-small m-0'>{email}</p>
          <form className='formWrapper'>
            <Input
              type='password'
              className='fullWidth square'
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              placeholder='New Password'
            />
            <Input
              type='password'
              className='fullWidth square'
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder='Confirm New Password'
            />
            <Button
              className='fullWidth gold m-0'
              type='submit'
              disabled={isLoading}
              isLoading={isLoading}
              onClick={onSubmit}>
              {isLoading ? 'Loading...' : 'Submit'}
            </Button>
          </form>
          {errorMessage && <p className='error center m-0'>{errorMessage}</p>}
          {accountToConfirm && (
            <p className='error center m-0'>
              This account has not been setup yet. Click{' '}
              <a className='white' href={`/confirm-account/${accountToConfirm}`}>
                here
              </a>{' '}
              to setup your Posh account.
            </p>
          )}
        </div>
      </div>
    </>
  )
}

export default ResetPasswordSubmit
