import {ds24 as tokens, mapTokenValues, palettes} from '@posh/design-tokens'

// -- ALERT --
// This file is a legacy file that is used to map the legacy colors to the new colors.
// We should not use ANY colors from this file (please use TailwindCSS instead)
// The only reason we are keeping this file is to maintain some amount of consistency between the mobile app and the web app.
// (for the event page palette, specifically)
// -- ALERT --

const iOS_SystemColors = {
  systemGray: '#8E8E93',
  systemGray2: '#636366',
  systemGray3: '#48484A',
  systemGray4: '#D2D2D7',
  systemGray5: '#2C2C2E',
  systemGray6: '#1C1C1E',
  systemGray7: '#7676803D',
  systemGray8: '#7676803D',
  systemGray9: '#7676803D',
  systemWhite: '#FFFFFF',
}

export const Colors = {
  ...mapTokenValues(tokens.legacyColors),
  ...mapTokenValues(tokens.legacyGradients),
  palettes: mapTokenValues(palettes),
  ...iOS_SystemColors,
}

// while Colors puts names to hex values, GlobalColors puts "locations" in the app to Colors for use across the app.
// this enables us to change the colors in one place and have it affect the entire app.
export const GlobalColors = {
  background: Colors.black,
  item: Colors.backgroundDarker,
}
