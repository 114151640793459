import {useEffect} from 'react'

import {LOCATION_PRESETS, LOCATION_PRESETS_REGIONS, TLocation} from '@posh/model-types'
import {useGetGeolocationInfo} from 'apis/Util/useGetGeolocationInfo'

/**
 * This hook updates the location based on the user's IP address.
 * If the location is already set, it will not be updated.
 */
export const useUpdateLocationByIp = (params: {
  location: TLocation | undefined
  updateLocation: (location: TLocation) => void
  fallbackLocation: TLocation
}): {isLoading: boolean} => {
  const {updateLocation, fallbackLocation, location} = params
  const {data: geolocationInfo, isLoading, isError} = useGetGeolocationInfo(undefined)

  useEffect(() => {
    if (location) return
    if (isError) {
      console.error('Failed to get geolocation info')
      updateLocation(fallbackLocation)
      return
    }

    if (geolocationInfo) {
      const {country, city, regionCode, latitude, longitude} = geolocationInfo
      if (!country || !city || !regionCode || !latitude || !longitude) {
        updateLocation(fallbackLocation)
        return
      }

      let preset: (typeof LOCATION_PRESETS)[number] | undefined

      for (const presetLocation of LOCATION_PRESETS) {
        if (
          (presetLocation.includes(city) || city.includes(presetLocation)) &&
          regionCode === LOCATION_PRESETS_REGIONS[presetLocation] &&
          country === 'US'
        ) {
          preset = presetLocation
          break
        }
      }

      if (preset) updateLocation({type: 'preset', location: preset})
      else
        updateLocation({
          type: 'custom',
          lat: +latitude,
          long: +longitude,
          location: country === 'US' ? `${city}, ${regionCode}` : `${city}, ${country}`,
        })
    }
  }, [location, geolocationInfo, isError, updateLocation, fallbackLocation])

  return {isLoading}
}
