import React, {useState} from 'react'
import {Control} from 'react-hook-form'

import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import useSessionContext from 'domains/Auth/SessionContext'

import {useGetNeedsPaymentsEnabled} from '../api/useGetNeedsPaymentsEnabled'
import {CreateEventLaunchModal, CreateEventLaunchModalProps} from '../components/LaunchModal'
import {useCreateEventParams} from '../useCreateEventParams'
import {CreateEventLogin} from './CreateEventLogin'
import {CreateEventGroupSelector, DEPRECATEDGroupSelector} from './GroupSelector'

import './styles.scss'

// TODO: Deprecate this after the new CreateEvent flow is fully implemented
export const DEPRECATEDLaunchEventModal = (props: PoshStyledModalProps) => {
  const {currentUser} = useSessionContext()
  return (
    <PoshStyledModal {...props} noborder>
      {currentUser ? <DEPRECATEDGroupSelector /> : <CreateEventLogin />}
    </PoshStyledModal>
  )
}

export const LaunchEventModal = (
  props: PoshStyledModalProps &
    Omit<
      CreateEventLaunchModalProps,
      'groupId' | 'pendingEventId' | 'needsPaymentsEnabled' | 'isLoadingNeedsPaymentsEnabled'
    > & {
      control: Control<EventVisualsForm>
    },
) => {
  const {groupId, pendingEventId} = useCreateEventParams()
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(groupId)
  const {currentUser} = useSessionContext()

  const {requiresPaymentsEnabled, isLoading} = useGetNeedsPaymentsEnabled(selectedGroupId!, props.control)

  if (!pendingEventId) return null
  if (!selectedGroupId)
    return (
      <PoshStyledModal {...props} noborder>
        {currentUser ? (
          <CreateEventGroupSelector
            currentUser={currentUser}
            pendingEventId={pendingEventId}
            setSelectedGroupId={setSelectedGroupId}
          />
        ) : (
          <CreateEventLogin />
        )}
      </PoshStyledModal>
    )
  else
    return (
      <CreateEventLaunchModal
        {...props}
        groupId={selectedGroupId}
        pendingEventId={pendingEventId}
        needsPaymentsEnabled={requiresPaymentsEnabled}
        isLoadingNeedsPaymentsEnabled={isLoading}
      />
    )
}
