import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type CheckMultisessionEventProvisioningStatusOptions =
  ReactQueryOptions['multiSessionEvents']['checkMultisessionEventProvisioningStatus']
type CheckMultisessionEventProvisioningStatusInput =
  RouterInputs['multiSessionEvents']['checkMultisessionEventProvisioningStatus']
export type CheckMultisessionEventProvisioningStatusOutput =
  RouterOutput['multiSessionEvents']['checkMultisessionEventProvisioningStatus']

export function useCheckMultisessionEventProvisioningStatus(
  input: CheckMultisessionEventProvisioningStatusInput,
  opts?: CheckMultisessionEventProvisioningStatusOptions,
) {
  return trpc.multiSessionEvents.checkMultisessionEventProvisioningStatus.useQuery(input, opts)
}
