const POSSIBLE_FLYERS = [
  'https://images.posh.vip/create-event-default-flyers/052824_Default_Flyer_1.webp',
  'https://images.posh.vip/create-event-default-flyers/052824_Default_Flyer_2.webp',
]

const getRandomFlyer = () => {
  return POSSIBLE_FLYERS[Math.floor(Math.random() * POSSIBLE_FLYERS.length)]
}

export {getRandomFlyer}
