import React, {useState} from 'react'

import useFetchEvent from 'apis/Events/useFetchEvent'
import {useFetchGroupDisputes} from 'apis/Groups/useFetchGroupDisputes'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import PageNotFound from 'pages/PageNotFound/PageNotFound'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {DisputeAnalytics} from './components/DisputeAnalytics/DisputeAnalytics'
import DisputeDetailDialog from './components/DisputeDetailDialog'
import DisputeTableCRUD from './components/DisputesTable/DisputesTableCRUD'
import DisputeUsersTableCRUD from './components/DisputeUsersTable/DisputeUsersTableCRUD'
import ResolutionGuide from './components/ResolutionGuide/ResolutionGuide'

import './components/DisputesTable/styles.scss'
import styles from './Overview.module.scss'

export const DisputesOverview = () => {
  const {eventId, groupId} = useResourcePageParams()
  const {
    data: disputesData,
    isLoading: isDisputesLoading,
    refetch: refetchDisputes,
  } = useFetchGroupDisputes({
    groupId: groupId!,
  })
  const {data: event, isLoading: isEventLoading} = useFetchEvent(eventId!)
  const [selectedDispute, setSelectedDispute] = useState<string | null>(null)
  const [contactDialogOpen, setContactDialogOpen] = useState(false)
  const [contactCustomer, setContactCustomer] = useState('')
  const [contactDisputeId, setContactDisputeId] = useState('')

  if (!groupId) {
    return <PageNotFound />
  }

  const handleDisputeClick = (id: string) => {
    setSelectedDispute(id)
  }

  const handleContactClick = (disputeId: string, customer: string) => {
    setContactCustomer(customer)
    setContactDisputeId(disputeId)
    setContactDialogOpen(true)
  }

  return (
    <div className='GroupPage-content'>
      <PoshHelmet title={'Disputes Overview'} />
      <DisputeTableCRUD
        disputes={disputesData?.disputes ?? []}
        onDisputeClick={handleDisputeClick}
        onContactClick={handleContactClick}
        groupId={groupId}
        onRefresh={refetchDisputes}
      />
      <div className={styles.TableSeparator} />
      <DisputeAnalytics eventId={eventId} groupId={groupId} />
      <div className={styles.TableSeparator} />
      <DisputeUsersTableCRUD
        disputeUsers={disputesData?.userStats ?? []}
        groupId={groupId}
        onRefresh={refetchDisputes}
      />
      <div className={styles.TableSeparator} />
      <ResolutionGuide />

      {/* Single instance of DisputeDetailDialog */}
      {disputesData?.disputes && (
        <DisputeDetailDialog disputes={disputesData.disputes} onSubmitEvidence={refetchDisputes} />
      )}
    </div>
  )
}
