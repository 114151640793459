import React, {useEffect} from 'react'

import {useMixpanel} from 'apis/MixPanelHandler'
import Stripe from 'stripe'

import StripeCardComponent from '../StripeCardComponent'

interface PaymentMethodProps {
  paymentMethods?: Stripe.PaymentMethod[]
  selectedPaymentMethod: Stripe.PaymentMethod | null | undefined
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<Stripe.PaymentMethod | null | undefined>>
}

const PaymentMethods = (props: PaymentMethodProps) => {
  const {paymentMethods, selectedPaymentMethod, setSelectedPaymentMethod} = props
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  useEffect(() => {
    if (paymentMethods) {
      setSelectedPaymentMethod(paymentMethods[0])
    }
  }, [paymentMethods, setSelectedPaymentMethod])

  return (
    <div className='CheckoutPage-Section fade'>
      <h5>Payment Options</h5>
      <div className='CheckoutPage-CardSelector fade'>
        {!selectedPaymentMethod && paymentMethods ? (
          paymentMethods.map(paymentMethod => (
            <>
              {paymentMethod.card && (
                <StripeCardComponent
                  key={paymentMethod.id}
                  isSelected={false}
                  card={paymentMethod.card}
                  onClick={() => {
                    trackMixpanelEvent('Checkout Select Existing Payment Card-  Checkout Page')
                    setSelectedPaymentMethod(paymentMethod)
                  }}
                />
              )}
            </>
          ))
        ) : (
          <>
            {paymentMethods &&
              paymentMethods.map(paymentMethod => (
                <>
                  {paymentMethod.card && (
                    <StripeCardComponent
                      key={paymentMethod.id}
                      isSelected={selectedPaymentMethod === paymentMethod}
                      card={paymentMethod.card}
                      onClick={() => {
                        trackMixpanelEvent('Checkout Select Existing Payment Card-  Checkout Page')
                        if (selectedPaymentMethod === paymentMethod) setSelectedPaymentMethod(null)
                        else setSelectedPaymentMethod(paymentMethod)
                      }}
                    />
                  )}
                </>
              ))}
            <div
              onClick={() => {
                trackMixpanelEvent('Add new card button-  Checkout Page')
                setSelectedPaymentMethod(null)
              }}
              className={`StripeCardSelector addNewCard`}>
              <p className='StripeCardSelector-digits'>+ Add New Card</p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default PaymentMethods
