import {useCallback, useMemo, useState} from 'react'

import {
  calculateNumberOfOccurences,
  DayOfWeekType,
  EventRecurrenceType,
  getAllDatesForSeries,
  getAllowedRulesBySelectedDate,
  MonthsType,
  RecurringEventTerminationSettingsType,
  RefinedEventRecurrence,
} from '@posh/model-types'
import {ordinalNumber} from 'helpers/ordinalNumber'
import moment from 'moment'

const getDateInfo = (
  date: moment.Moment,
): {
  dayOfWeek: DayOfWeekType
  dayOfMonth: number
  month: MonthsType
} => {
  return {
    dayOfWeek: date.format('dddd') as DayOfWeekType,
    dayOfMonth: date.date(),
    month: date.format('MMMM') as MonthsType,
  }
}

const ALL_DAYS_FALSE: Record<DayOfWeekType, boolean> = {
  Sunday: false,
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
}

interface UseRecurrenceSettingsParams {
  startDate: moment.Moment
  defaultRecurrenceSettings?: EventRecurrenceType
  type: 'edit' | 'create'
}

// Repeat Criteria
const DEFAULT_RECURRENCE_TYPE: EventRecurrenceType['options']['type'] = 'Weekly'
const DEFAULT_SELECTED_RULE_INDEX = 0
const DEFAULT_RECUR_ON_SELECTED_DATE = true
const DEFAULT_RECURRENCE_REPEAT_AMOUNT = 1

// End Criteria
const DEFAULT_HAS_END_DATE = false
const DEFAULT_N_OCCURRENCES = 5

export const useRecurrenceSettings = (params: UseRecurrenceSettingsParams) => {
  const {startDate, defaultRecurrenceSettings, type} = params

  const {
    dayOfWeek: startDateDayOfWeek,
    dayOfMonth: startDateDayOfMonth,
    month: startDateMonth,
  } = useMemo(() => {
    return getDateInfo(startDate)
  }, [startDate])

  // Repeat Criteria
  const [recurrenceRepeatType, setRecurrenceRepeatType] = useState<EventRecurrenceType['options']['type']>(
    defaultRecurrenceSettings?.options.type ?? DEFAULT_RECURRENCE_TYPE,
  )

  const allowedRulesBySelectedDate = useMemo(() => {
    return getAllowedRulesBySelectedDate(startDate.toString(), recurrenceRepeatType)
  }, [startDate, recurrenceRepeatType])

  const [selectedRuleIndex, setSelectedRuleIndex] = useState(() => {
    if (defaultRecurrenceSettings?.options.type === 'Monthly' || defaultRecurrenceSettings?.options.type === 'Annual') {
      const defaultRecurrenceSettingsOptionsOptions = defaultRecurrenceSettings.options.options
      if (defaultRecurrenceSettingsOptionsOptions?.recurOnSelectedDate === false) {
        const rule = defaultRecurrenceSettingsOptionsOptions.rule
        const index = allowedRulesBySelectedDate.findIndex(r => r === rule)
        return index === -1 ? DEFAULT_SELECTED_RULE_INDEX : index
      }
    }
    return DEFAULT_SELECTED_RULE_INDEX
  })

  const [recurOnSelectedDate, setRecurOnSelectedDate] = useState(() => {
    if (defaultRecurrenceSettings?.options.type === 'Monthly' || defaultRecurrenceSettings?.options.type === 'Annual') {
      return defaultRecurrenceSettings.options.options.recurOnSelectedDate
    }
    return DEFAULT_RECUR_ON_SELECTED_DATE
  })

  const [daysOfWeek, setDaysOfWeek] = useState<Record<DayOfWeekType, boolean>>(() => {
    return {
      ...ALL_DAYS_FALSE,
      ...(defaultRecurrenceSettings?.options.type === 'Weekly'
        ? defaultRecurrenceSettings.options.selectedDays
        : {[startDateDayOfWeek]: true}),
    }
  })

  const [recurrenceRepeatAmount, setRecurrenceRepeatAmount] = useState(
    defaultRecurrenceSettings?.every ?? DEFAULT_RECURRENCE_REPEAT_AMOUNT,
  )

  const toggleDayOfWeek = (dayOfWeek: DayOfWeekType) => {
    setDaysOfWeek(prevDaysOfWeek => {
      const updatedDaysOfWeek = {...prevDaysOfWeek, [dayOfWeek]: !prevDaysOfWeek[dayOfWeek]}
      // if all the days are unselected, select the day of the startDate
      const allWillBeFalse = Object.values(updatedDaysOfWeek).every(v => !v)

      return allWillBeFalse ? {...updatedDaysOfWeek, [startDateDayOfWeek]: true} : updatedDaysOfWeek
    })
  }

  // End Criteria
  const [hasEndDate, setHasEndDate] = useState<boolean>(
    defaultRecurrenceSettings?.ending.hasEndDate ?? DEFAULT_HAS_END_DATE,
  )

  const onChangeHasEndDate = (newHasEndDate: boolean) => {
    /**
     * If we are extending an existing event series, we need to make sure that if we switch from ending on a specific date to ending after a certain number of occurrences,
     * we set the number of occurrences to the correct number (i.e. the number of occurrences between the start date and the end date).
     * The same goes for switching from ending after a certain number of occurrences to ending on a specific date.
     */
    if (type === 'edit' && defaultRecurrenceSettings) {
      const switchingFromHasEndDateToNumberOfOccurrences = !newHasEndDate && defaultRecurrenceSettings.ending.hasEndDate
      const switchingFromNumberOfOccurrencesToHasEndDate = newHasEndDate && !defaultRecurrenceSettings.ending.hasEndDate
      if (switchingFromHasEndDateToNumberOfOccurrences) {
        const numberOfOccurrencesWithEndDate = calculateNumberOfOccurences(defaultRecurrenceSettings)
        setNOccurrences(numberOfOccurrencesWithEndDate)
      } else if (switchingFromNumberOfOccurrencesToHasEndDate) {
        const newEndDateWithNumberOfOccurrences = getAllDatesForSeries(defaultRecurrenceSettings).at(-1)
        if (newEndDateWithNumberOfOccurrences) setEndDate(moment(newEndDateWithNumberOfOccurrences))
      }
    }
    setHasEndDate(newHasEndDate)
  }
  const [nOccurrences, setNOccurrences] = useState<number>(() => {
    const ending = defaultRecurrenceSettings?.ending
    if (!ending || ending.hasEndDate) return DEFAULT_N_OCCURRENCES
    return ending.nOccurrences
  })
  const [endDate, setEndDate] = useState<moment.Moment>(() => {
    const ending = defaultRecurrenceSettings?.ending
    if (!ending) return startDate.clone().add(1, 'week').utcOffset(startDate.utcOffset(), true)
    return ending.hasEndDate
      ? moment(ending.endDate).utcOffset(startDate.utcOffset(), true)
      : startDate.clone().add(1, 'week').utcOffset(startDate.utcOffset(), true)
  })

  const [lastDayOfMonth, setLastDayOfMonth] = useState<boolean>(() => {
    if (
      (defaultRecurrenceSettings?.options.type === 'Monthly' || defaultRecurrenceSettings?.options.type === 'Annual') &&
      defaultRecurrenceSettings.options.options?.recurOnSelectedDate &&
      defaultRecurrenceSettings.options.options?.lastDayOfMonth
    ) {
      return true
    }
    return false
  })

  const lastDayOfMonthOption: Array<{label: string; value: 'lastDayOfMonth'}> = useMemo(() => {
    return startDate.clone().endOf('month').date() === startDate.date()
      ? [
          {
            label: `the last day of ${recurrenceRepeatType === 'Monthly' ? 'the month' : startDateMonth}`,
            value: 'lastDayOfMonth',
          },
        ]
      : []
  }, [startDate, recurrenceRepeatType, startDateMonth])

  const onOptions: Array<{label: string; value: 'lastDayOfMonth' | 'recursOnSelectedDate' | number}> = useMemo(() => {
    return [
      {
        label: `the ${ordinalNumber(startDateDayOfMonth)} day${
          recurrenceRepeatType === 'Monthly' ? '' : ` of ${startDateMonth}`
        }`,
        value: 'recursOnSelectedDate',
      },
      ...allowedRulesBySelectedDate.map((rule, index) => ({
        label: `the ${rule.toLowerCase()} ${startDateDayOfWeek}${
          recurrenceRepeatType === 'Monthly' ? '' : ` of ${startDateMonth}`
        }`,
        value: index,
      })),
      ...lastDayOfMonthOption,
    ]
  }, [
    startDate,
    startDateDayOfMonth,
    startDateDayOfWeek,
    startDateMonth,
    recurrenceRepeatType,
    allowedRulesBySelectedDate,
  ])

  const onChangeOnValue = useCallback(
    (value: 'lastDayOfMonth' | 'recursOnSelectedDate' | number) => {
      if (value === 'lastDayOfMonth') {
        setRecurOnSelectedDate(true)
        setLastDayOfMonth(true)
      } else if (value === 'recursOnSelectedDate') {
        setRecurOnSelectedDate(true)
        setLastDayOfMonth(false)
        return
      } else {
        setRecurOnSelectedDate(false)
        setLastDayOfMonth(false)
        setSelectedRuleIndex(value)
      }
    },
    [setRecurOnSelectedDate, setLastDayOfMonth, setSelectedRuleIndex],
  )

  const onValue: 'lastDayOfMonth' | 'recursOnSelectedDate' | number = useMemo(() => {
    return lastDayOfMonth ? 'lastDayOfMonth' : recurOnSelectedDate ? 'recursOnSelectedDate' : selectedRuleIndex
  }, [lastDayOfMonth, recurOnSelectedDate, selectedRuleIndex])

  const endCriteria: RecurringEventTerminationSettingsType = useMemo(
    () =>
      hasEndDate
        ? {
            hasEndDate: true,
            endDate: endDate.toISOString(true),
          }
        : {
            hasEndDate: false,
            nOccurrences,
          },
    [hasEndDate, endDate, nOccurrences],
  )

  const eventRecurrence: EventRecurrenceType = useMemo(() => {
    return {
      startDate: startDate.toISOString(true),
      ending: endCriteria,
      every: recurrenceRepeatAmount,
      options: ((): EventRecurrenceType['options'] => {
        if (recurrenceRepeatType === 'Daily') {
          return {
            type: 'Daily',
          }
        } else if (recurrenceRepeatType === 'Weekly') {
          return {
            type: 'Weekly',
            selectedDays: daysOfWeek,
          }
        } else if (recurrenceRepeatType === 'Monthly') {
          return {
            type: 'Monthly',
            options: recurOnSelectedDate
              ? {
                  recurOnSelectedDate: true,
                  lastDayOfMonth,
                }
              : {
                  recurOnSelectedDate: false,
                  dayOfWeek: startDateDayOfWeek,
                  rule: allowedRulesBySelectedDate[selectedRuleIndex],
                },
          }
        } else {
          return {
            type: 'Annual',
            options: recurOnSelectedDate
              ? {
                  recurOnSelectedDate: true,
                  lastDayOfMonth,
                }
              : {
                  recurOnSelectedDate: false,
                  dayOfWeek: startDateDayOfWeek,
                  rule: allowedRulesBySelectedDate[selectedRuleIndex],
                },
          }
        }
      })(),
    }
  }, [
    startDate,
    endCriteria,
    recurrenceRepeatAmount,
    recurrenceRepeatType,
    daysOfWeek,
    recurOnSelectedDate,
    selectedRuleIndex,
    lastDayOfMonth,
  ])

  const eventRecurrenceParsed:
    | {
        type: 'success'
        recurrenceSettings: EventRecurrenceType
      }
    | {
        type: 'error'
        repeatCriteriaErrors?: string
        endCriteriaErrors?: string
      } = useMemo(() => {
    const recurrenceSettingsParsed = RefinedEventRecurrence.safeParse(eventRecurrence)

    if (!recurrenceSettingsParsed.success) {
      const errors = recurrenceSettingsParsed.error.errors

      const repeatCriteriaErrors = [
        errors.find(error => error.path[0] === 'options')?.message,
        errors.find(error => error.path[0] === 'every')?.message,
      ]
        .filter(Boolean)
        .join(', ')
      const endCriteriaErrors = errors.find(error => error.path[0] === 'ending')?.message

      return {
        type: 'error',
        repeatCriteriaErrors,
        endCriteriaErrors,
      }
    } else {
      const newEventRecurrence = recurrenceSettingsParsed.data
      if (type === 'edit' && defaultRecurrenceSettings) {
        const existingNumberOfOccurrences = calculateNumberOfOccurences(defaultRecurrenceSettings)
        const newNumberOfOccurrences = calculateNumberOfOccurences(newEventRecurrence)
        if (newNumberOfOccurrences < existingNumberOfOccurrences) {
          return {
            type: 'error',
            endCriteriaErrors: 'You cannot reduce the number of occurrences when editing an existing event.',
          }
        }
      }

      return {
        type: 'success',
        recurrenceSettings: eventRecurrence,
      }
    }
  }, [eventRecurrence])

  const repeatCriteriaErrors =
    eventRecurrenceParsed.type === 'error' ? eventRecurrenceParsed.repeatCriteriaErrors : undefined
  const endCriteriaErrors = eventRecurrenceParsed.type === 'error' ? eventRecurrenceParsed.endCriteriaErrors : undefined

  return {
    startDate,

    // Repeat Criteria
    recurrenceRepeatType,
    setRecurrenceRepeatType,
    onOptions,
    onValue,
    onChangeOnValue,
    daysOfWeek,
    toggleDayOfWeek,
    recurrenceRepeatAmount,
    setRecurrenceRepeatAmount,

    // End Criteria
    hasEndDate,
    onChangeHasEndDate,
    nOccurrences,
    setNOccurrences,
    endDate,
    setEndDate,

    // Overall
    eventRecurrence,
    eventRecurrenceParsed,
    repeatCriteriaErrors,
    endCriteriaErrors,
  }
}
