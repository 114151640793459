import React, {useEffect, useMemo, useState} from 'react'

import {getAllDatesForSeries} from '@posh/model-types'
import {useMixpanel} from 'apis/MixPanelHandler'
import {GetPendingEventOutput as PendingEvent, useGetPendingEvent} from 'apis/PendingEvent/useGetPendingEvent'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import pluralize from 'helpers/pluralize'
import moment from 'moment-timezone'

import {KeepEditing} from '../components/KeepEditing'
import {MultiSessionEventList} from '../components/MultiSessionEventList'

import styles from './styles.module.scss'

export interface LaunchBodyProps {
  pendingEventId: string
  groupId: string
  onConfirm: (status: 'live' | 'draft', shouldNavigateToFinanceSettings: false) => void
  onClose: () => void
}

function PendingEventInfo({pendingEvent}: {pendingEvent: PendingEvent}) {
  const {name, startUtc, timezone} = pendingEvent
  return (
    <div className={styles.eventInfo}>
      <h3 className='center m-0'>{name}</h3>
      <h5 className='center m-0'>{moment.tz(startUtc, timezone).format('M/D/YYYY @ h:mmA (z)')}</h5>
    </div>
  )
}

export function LaunchBody({pendingEventId, groupId, onConfirm, onClose}: LaunchBodyProps) {
  const [pendingEvent, setPendingEvent] = useState<PendingEvent | null>(null)
  const {refetch} = useGetPendingEvent({_id: pendingEventId!, groupId}, {enabled: !!pendingEventId})
  const {trackEvent} = useMixpanel()
  const trackLaunch = (type: 'launch' | 'keepEditing', isMultiSession: boolean, numberOfEvents: number) => {
    if (!isMultiSession) return
    if (type === 'launch') {
      trackEvent('Create Event- Multi Session Launch Modal-  Launch Button Clicked', {numberOfEvents})
    } else {
      trackEvent('Create Event- Multi Session Launch Modal-  Keep Editing Clicked', {numberOfEvents})
    }
  }

  // we need to fetch the data when this component is mounted
  // and unset it when it is unmounted because otherwise it shows
  // stale data for a split second when the modal is opened.
  useEffect(() => {
    refetch().then(({data}) => {
      if (data) setPendingEvent(data.pendingEvent)
    })
  }, [refetch])

  const closeAndUnsetPendingEvent = () => {
    onClose()
    setPendingEvent(null)
    trackLaunch('keepEditing', isMultiSession, eventDates.length)
  }

  // if the event is a multi-session event, we need to know what dates are in the series
  const eventDates = useMemo(() => {
    if (!pendingEvent) return []
    return pendingEvent.recurrence
      ? getAllDatesForSeries(pendingEvent.recurrence, pendingEvent.timezone).map(dateString => {
          return moment.tz(dateString, pendingEvent.timezone)
        })
      : [moment.tz(pendingEvent.startUtc, pendingEvent.timezone)]
  }, [pendingEvent])

  const isMultiSession = eventDates.length > 1

  const launchString = `Ready to launch your ${isMultiSession ? `${eventDates.length} ` : ''}${pluralize(
    'event',
    eventDates.length,
  )}?`

  if (!pendingEvent)
    return (
      <div className='flex h-1/2 w-full max-w-[800px] items-center justify-center'>
        <SpinLoader />
      </div>
    )

  return (
    <div className={styles.Launch}>
      <div className={styles.container}>
        <span className={'center m-0 text-xl font-medium tracking-tighter text-white'}>{launchString}</span>
        {isMultiSession ? (
          <div className='flex flex-col gap-6'>
            <span
              className={
                'center noMargin text-medium tracking-tight text-gray-300'
              }>{`This will launch ${pluralize('event', eventDates.length)} on the following dates. You can update event info individually or in bulk later, in the visual editor.`}</span>
            <MultiSessionEventList eventDates={eventDates} template={pendingEvent} />
          </div>
        ) : (
          <div className={styles.imageContainer}>
            <PoshImage src={pendingEvent.flyer} alt={`Flyer for ${pendingEvent.name}`} className={styles.image} />
            <PendingEventInfo pendingEvent={pendingEvent} />
          </div>
        )}
        <div className={styles.buttonContainer}>
          {!isMultiSession && (
            <Button
              onClick={() => onConfirm('draft', false)}
              className='noShadow outline'
              style={{flex: 1, maxWidth: 400}}>
              Save as draft
            </Button>
          )}
          <Button
            onClick={() => {
              trackLaunch('launch', isMultiSession, eventDates.length)
              onConfirm('live', false)
            }}
            className='noShadow goldHover'
            style={{flex: 1, maxWidth: 400}}>
            {isMultiSession ? `Create ${eventDates.length} Events` : 'Launch'}
          </Button>
        </div>
        <KeepEditing onClose={closeAndUnsetPendingEvent} />
      </div>
    </div>
  )
}
