import React, {PropsWithChildren, ReactNode, useEffect} from 'react'
import {Route, Routes} from 'react-router-dom'

import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import useSessionContext from 'domains/Auth/SessionContext'
import {DisputesOverview} from 'pages/DisputeManagementPages/DisputesOverview'
import Footer from 'pages/HomePage/Footer'
import PageNotFound from 'pages/PageNotFound/PageNotFound'
import PoshMobileNav from 'pages/PoshMobileNav'
import {FeatureFlagOverrideProvider} from 'providers/FeatureFlagProvider'

import {AccountDomain} from '../../apis/Roles/AccountDomain'
import RequirePermissions, {RequiredPermissionAndScope} from '../../components/RequirePermissions'
import PoshHeader from '../OwnerPage/PoshHeader'
import {usePageParams, useResourcePageParams} from '../PoshAppLayout'
import CommunityHandler from './CommunityHandler'
import EventLaunch from './EventLaunch'
import Finance from './Finance'
import GroupPageNavigation from './GroupPageNavigation'
import {GroupPageSection, GROUPS_ROUTE_MAP} from './GroupRoutes'
import {GroupHelmet} from './Helmet'
import Marketing from './Marketing'
import AttendeesCrmContextProvider from './Marketing/AttendeesCrmContextProvider'
import OrderRoutes from './Orders'
import OverviewHandler from './OverviewHandler'
import Profile from './Profile'
import Settings from './Settings'
import Team from './Team'

import './styles.scss'

const {OVERVIEW, MARKETING, ORDERS, TEAM, COMMUNITY, FINANCE, PROFILE, EVENT_LAUNCH, DISPUTES, SETTINGS} =
  GroupPageSection

interface GroupPageWrapperProps {
  isOwner: boolean
  section: GroupPageSection
}

interface GroupContentProps {
  requiredPermissions?: RequiredPermissionAndScope[]
  allowPartialMatch?: boolean
}

const GroupContent = (props: PropsWithChildren<GroupContentProps>) => {
  const {requiredPermissions, children, allowPartialMatch} = props
  return (
    <>
      {requiredPermissions ? (
        <RequirePermissions allowPartialMatch={allowPartialMatch} requiredPermissions={requiredPermissions}>
          <>{children}</>
        </RequirePermissions>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

const GroupPageWrapper = (props: PropsWithChildren<GroupPageWrapperProps>) => {
  const {children} = props
  const {requiredPermissions, allowPartialMatch} = GROUPS_ROUTE_MAP[props.section]

  return (
    <div className='GroupPage'>
      <GroupPageNavigation />
      <GroupContent allowPartialMatch={allowPartialMatch} requiredPermissions={requiredPermissions}>
        {children}
      </GroupContent>
    </div>
  )
}

export function wrap(children: ReactNode, isOwner: boolean, groupPageSection: GroupPageSection) {
  return (
    <GroupPageWrapper section={groupPageSection} isOwner={isOwner}>
      {children}
    </GroupPageWrapper>
  )
}

export const GROUP_BASE_URL = `/:domain/groups/:groupId`

const GroupPageLayout = () => {
  const {domain} = usePageParams()
  const {groupId} = useResourcePageParams()
  const {data: group} = useFetchGroup(groupId)
  //TODO: this should use the same logic as down below
  const isOwner = domain === AccountDomain.OWNER
  const {currentUser, userId} = useSessionContext()

  // This triggers the Net Promoter Score (NPS) survey in Vitally.
  useEffect(() => {
    const sendUserData = async () => {
      try {
        // Check to see if the current user is an owner of the group before we show them any vitally nps or tag them to any group
        const isOwnerOfGroup = group?.owners?.some(ownerId => ownerId === userId)

        if (isOwnerOfGroup && !!currentUser && (window as any).Vitally) {
          await (window as any).Vitally.user({
            userId,
            accountId: groupId,
            traits: {
              name: `${currentUser.firstName} ${currentUser.lastName}`,
              email: currentUser.email,
            },
          })
          await (window as any).Vitally.account({
            accountId: groupId,
            traits: {
              name: group?.name, // required
            },
          })
          await (window as any).Vitally.nps('survey')
        }
      } catch {
        return
      }
    }
    sendUserData()
  }, [userId, groupId])

  return (
    <FeatureFlagOverrideProvider groupId={groupId}>
      <PoshHeader desktopOnly />
      <GroupHelmet />
      <Routes>
        <Route path={OVERVIEW + '/*'} element={<>{wrap(<OverviewHandler />, isOwner, OVERVIEW)}</>} />
        <Route path={TEAM} element={wrap(<Team />, true, TEAM)} />
        <Route path={FINANCE} element={wrap(<Finance />, true, FINANCE)} />
        <Route path={ORDERS + '/*'} element={wrap(<OrderRoutes />, true, ORDERS)} />
        <Route path={PROFILE} element={wrap(<Profile />, true, PROFILE)} />
        <Route path={DISPUTES} element={wrap(<DisputesOverview />, true, DISPUTES)} />
        <Route path={COMMUNITY + '/*'} element={wrap(<CommunityHandler />, true, COMMUNITY)} />
        <Route path={SETTINGS + '/:category'} element={wrap(<Settings />, true, SETTINGS)} />
        <Route
          path={MARKETING + '/*'}
          element={wrap(
            <AttendeesCrmContextProvider>
              <Marketing />
            </AttendeesCrmContextProvider>,
            true,
            MARKETING,
          )}
        />
        <Route path={`${EVENT_LAUNCH}/:eventId`} element={wrap(<EventLaunch />, true, EVENT_LAUNCH)} />
        <Route path={'*'} element={<PageNotFound />} />
      </Routes>
      <PoshMobileNav />
      <Footer desktopOnly />
    </FeatureFlagOverrideProvider>
  )
}

export default GroupPageLayout
