import {useEffect, useState} from 'react'

const POLL_INCREASE = 1.1
const POLL_MAX = 10000
const POLL_INITIAL = 1000

/**
 * Since event updates are asyncronously made by a trigger,
 * we need to poll the events to see if the changes have been made.
 * This helper function will poll the events and increase the interval
 * interval reaches 10 seconds.
 * @param refetch - The function to refetch the events
 * @returns
 */
export function usePollAfterAsyncEdit(refetch: () => void) {
  const [hasEdited, setHasEdited] = useState(false)
  const [pollInterval, setPollInterval] = useState(1000) // 1 second initial interval

  useEffect(() => {
    if (!hasEdited) {
      setPollInterval(POLL_INITIAL) // Reset interval when not polling
      return
    }

    if (hasEdited && pollInterval > POLL_MAX) {
      setHasEdited(false)
      setPollInterval(POLL_INITIAL)
      return
    }

    const poll = async () => {
      refetch()
      setPollInterval(prev => Math.min(Math.round(prev * POLL_INCREASE), POLL_MAX))
    }

    const timer = setTimeout(poll, pollInterval)
    return () => clearTimeout(timer)
  }, [hasEdited, pollInterval, refetch])

  return {setHasAsyncEdited: setHasEdited}
}
