// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".imageUploadOverlay__HjIaR{position:absolute;top:0;left:0;right:0;bottom:0;display:flex;align-items:center;justify-content:center;color:#fff;background-color:rgba(0,0,0,.5)}", "",{"version":3,"sources":["webpack://./src/pages/MultiSessionEventsPublic/page/components/FlyerUpload/styles.module.scss"],"names":[],"mappings":"AAAA,2BACI,iBAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,+BAAA","sourcesContent":[".imageUploadOverlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n    background-color: rgba(0, 0, 0, 0.5);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageUploadOverlay": "imageUploadOverlay__HjIaR"
};
export default ___CSS_LOADER_EXPORT___;
