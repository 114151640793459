import React, {ReactNode} from 'react'
import {NavLink, useLocation} from 'react-router-dom'

import {parseBlastMessage} from '@posh/utils'
import classNames from 'classnames'

import {useFetchTextBlastDetails} from '../../../apis/TextBlasts/useFetchTextBlastDetails'
import useSessionContext from '../../../domains/Auth/SessionContext'
import formatPhoneNumber from '../formatPhoneNumber.helpers'
import IMessagePreview from '../IMessagePreview'
import SMSCampaignBackground from '../SMSCampaignBackground'
import Details from './Details'
import Recipients from './Recipients'

import '../../GroupPage/GroupPageNavigation/styles.scss'
import './Breakdown.styles.scss'

const GroupNavLink = ({tabName, isSelected, to}: {tabName: string; isSelected: boolean; to: string}) => {
  const getClassName = (navData: {isActive: boolean}) => {
    return classNames('GroupPageNavigation-navLink', {['is-active']: navData.isActive})
  }

  return (
    <div key={tabName} className='GroupPageNavigation-navLinkWrapper BreakdownNavBar-navLinkWrapper'>
      <NavLink className={getClassName} to={to}>
        {tabName.toUpperCase()}
      </NavLink>
      <div
        className={`GroupPageNavigation-navLinkBorder BreakdownNavBar-navLinkBorder ${isSelected ? 'selected' : ''}`}
      />
    </div>
  )
}

const BREAKDOWN_TABS = ['details', 'recipients']

const SMSCampaignBreakdown = ({children}: {children: ReactNode}) => {
  const {pathname} = useLocation()
  const {data} = useFetchTextBlastDetails()
  const {currentUser} = useSessionContext()

  const {phoneNumberUsed, message, attachedEventLink} = data ?? {}
  const previewMessage = message ?? '' + attachedEventLink ?? ''
  return (
    <SMSCampaignBackground>
      <div className='BreakdownWrapper'>
        <h2 className='center m-0'>Your SMS Campaign</h2>
        <div className='BreakdownWrapper__mainDetailsContainer'>
          <div className='BreakdownWrapper__tabsContainer'>
            <div className='GroupPageNavigation' style={{backgroundColor: 'transparent'}}>
              <div className='flex gap-2'>
                {BREAKDOWN_TABS.map(tab => {
                  const isSelected = pathname.includes(tab)
                  return <GroupNavLink key={tab} tabName={tab} isSelected={isSelected} to={`../${tab}`} />
                })}
              </div>
            </div>
            {children}
          </div>
          <IMessagePreview
            message={parseBlastMessage(previewMessage, {
              firstName: currentUser!.firstName!,
              lastName: currentUser!.lastName!,
            })}
            phoneNumber={formatPhoneNumber(phoneNumberUsed)}
          />
        </div>
      </div>
    </SMSCampaignBackground>
  )
}

SMSCampaignBreakdown.Details = Details
SMSCampaignBreakdown.Recipients = Recipients

export default SMSCampaignBreakdown
