import {setSessionContext} from 'apis/Auth/setSessionContext'
import useSessionContext from 'domains/Auth/SessionContext'
import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

export type ConfirmAndAuthenticateAccountParams = RouterInputs['auth']['confirmAndAuthenticateAccount']
export type ConfirmAndAuthenticateAccountOutput = RouterOutput['auth']['confirmAndAuthenticateAccount']
type ConfirmAndAuthenticateAccountOptions = ReactQueryOptions['auth']['confirmAndAuthenticateAccount']

export function useConfirmAndAuthenticateAccount(opts?: ConfirmAndAuthenticateAccountOptions) {
  const sessionContext = useSessionContext()
  return trpc.auth.confirmAndAuthenticateAccount.useMutation({
    ...opts,
    onSuccess: (data, variables, context) => {
      const {userId, token, account, expires, accountRoles, groupsWhereAccountIsOwnerMap} = data
      setSessionContext(
        {
          userId,
          token,
          account,
          expires,
          accountRoles,
          groupsWhereAccountIsOwnerMap,
        },
        sessionContext,
      )
      opts?.onSuccess?.(data, variables, context)
    },
  })
}
