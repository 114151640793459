import React, {ComponentProps} from 'react'

import {DateInput} from 'components/PageComponents/EventVisuals/Form/Input/DateInput/Date'

interface RecurrenceSettingsDateInputProps {
  value: moment.Moment
  onChange: (value: Date) => void
  disabled: boolean
  shouldDisableDate?: ComponentProps<typeof DateInput>['shouldDisableDate']
}

export const RecurrenceSettingsDateInput = (props: RecurrenceSettingsDateInputProps) => {
  const {value, onChange, disabled, shouldDisableDate} = props

  const handleChange = (value: Date | null) => {
    if (!value) return
    onChange(value)
  }

  return (
    <DateInput
      value={value.toISOString()}
      onChange={handleChange}
      disabled={disabled}
      shouldDisableDate={shouldDisableDate}
      disablePast
      accentColor=''
      lightMode
      placeholder=''
      style={{maxWidth: 95}}
      inputProps={{
        style: {
          textAlign: 'center',
        },
      }}
      inputWrapperClassName='gap-0'
    />
  )
}
