import React from 'react'

import useFetchMailChimpAuthLink from 'apis/MailChimp/useFetchMailChimpAuthLink'
import {useIsMailChimpUserAuthenticated} from 'apis/MailChimp/useIsMailChimpUserAuthenticated'
import {useUnAuthenticateMailChimpAccount} from 'apis/MailChimp/useUnAuthenticateMailChimpAccount'
import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'
import {useGroupMatchParams} from 'domains/Groups/helpers'

export const MailChimpIntegration = () => {
  const {groupId, domain} = useGroupMatchParams()
  const {mutateAsync: fetchMailChimpAuthLink, isLoading} = useFetchMailChimpAuthLink()
  const {data: mailChimpAuthStatus, isFetching, refetch} = useIsMailChimpUserAuthenticated(groupId || '')
  const {mutate: unAuthenticateMailChimpAccount, isLoading: isUnauthenticating} = useUnAuthenticateMailChimpAccount({
    onSuccess: () => {
      refetch()
      showToast({type: 'success', title: 'MailChimp account unlinked'})
    },
    onError: error => {
      showToast({type: 'error', title: 'There was an issue unlinking your MailChimp account', subtitle: error.message})
    },
  })
  const {showToast} = useToast()
  const isAuthed = mailChimpAuthStatus?.isAuthenticated ?? false
  const buttonTitle = (() => {
    if (isLoading) return 'Connecting...'
    if (isFetching) return 'Checking connection...'
    if (isUnauthenticating) return 'Loading...'
    return isAuthed ? 'Unlink MailChimp account' : 'Connect'
  })()
  const buttonDisabled = isLoading || isFetching || isUnauthenticating

  const onPress = async () => {
    try {
      if (!groupId) throw new Error('No group id')
      if (isAuthed) return unAuthenticateMailChimpAccount({groupId})
      else {
        const link = await fetchMailChimpAuthLink({groupId, scope: domain})
        window.location.href = link
      }
    } catch (error) {
      if (error.response) showToast({type: 'error', title: error.response.data.error})
      else showToast({type: 'error', title: error.message})
    }
  }

  return (
    <div className='IntegrationsCont-integration'>
      <PoshImage
        src='https://images.posh.vip/b2/settings-page-icons/mailchimp-logo.jpeg'
        className='IntegrationsCont-integration-logo'
      />
      <h5>MailChimp</h5>
      <p>Email blast your attendees</p>
      <Button
        disabled={buttonDisabled}
        onClick={onPress}
        isLoading={buttonDisabled}
        className={`${isAuthed ? 'destructive light' : 'light dark'} fullWidth`}>
        {buttonTitle}
      </Button>
    </div>
  )
}
