import React from 'react'
import {Link} from 'react-router-dom'

import {isAllowedTapToPayCountry} from '@posh/model-types'
import {CountryCode} from '@posh/types'
import {GetGroupEventsEvent} from 'apis/Groups/useFetchGroupEvents'
import {GetGroupOutput} from 'apis/Groups/useGetGroup'
import moment from 'moment'

import styles from './GroupCtaBanner.module.scss'
const getPayoutsAndPaymentsCta = ({
  canInitiatePayouts,
  canAcceptPayments,
  needsStripeOnboarding,
  upcomingEvent,
  revenue,
}: {
  canInitiatePayouts: boolean
  canAcceptPayments: boolean
  needsStripeOnboarding: boolean
  upcomingEvent?: GetGroupEventsEvent
  revenue?: number
}) => {
  // We only want to bother folks who have revenue
  // Or have an upcoming event that is paid
  if (!revenue && upcomingEvent?.isFree) return null

  if (!canInitiatePayouts && !canAcceptPayments)
    return 'You cannot sell tickets or initiate payouts at the moment, click here for more information.'
  if (!canAcceptPayments) return 'You cannot sell tickets at the moment, click here for more information.'
  if (!canInitiatePayouts) return 'You cannot initiate payouts at the moment, click here for more information.'
  // If functionality is active but Stripe is asking for onboarding soon
  if (needsStripeOnboarding)
    return 'Your ability to sell tickets or initiate payouts may be disrupted in the near future, click here for more information.'

  return null
}

interface GetGroupCtaBannerProps {
  canInitiatePayouts: boolean
  canAcceptPayments: boolean
  needsStripeOnboarding: boolean
  baseUrl: string
  country?: CountryCode
  tapToPayState: GetGroupOutput['tapToPayState']
  upcomingEvent?: GetGroupEventsEvent
  revenue?: number
}

export const getGroupCtaBanner = (props: GetGroupCtaBannerProps) => {
  const {
    canInitiatePayouts,
    canAcceptPayments,
    baseUrl,
    needsStripeOnboarding,
    revenue,
    tapToPayState,
    upcomingEvent,
    country,
  } = props

  const payoutsAndPaymentsCta = getPayoutsAndPaymentsCta({
    canInitiatePayouts,
    canAcceptPayments,
    needsStripeOnboarding,
    revenue,
    upcomingEvent,
  })

  if (payoutsAndPaymentsCta)
    return (
      <Link to={`${baseUrl}/finance`} style={{textDecoration: 'none'}}>
        <div className={styles.GroupCtaBanner}>{payoutsAndPaymentsCta}</div>
      </Link>
    )

  const tapToPayCta =
    tapToPayState === 'not setup' &&
    upcomingEvent &&
    moment().isBetween(moment(upcomingEvent.startUtc).subtract(7, 'days'), moment(upcomingEvent.endUtc)) &&
    isAllowedTapToPayCountry(country)
      ? 'Download the Posh app to start selling tickets at the door for your next event!'
      : null

  if (tapToPayCta)
    return (
      <Link to={'/in-person-payments-app-overlay'} style={{textDecoration: 'none'}}>
        <div className={styles.GroupCtaBanner}>{tapToPayCta}</div>
      </Link>
    )

  return null
}
