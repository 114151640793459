// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EventAttributes__IOyDA,.GroupAttributes__E95mr{display:flex;align-items:center;padding-top:5px;padding-bottom:5px;gap:5px}.EventAttributes__IOyDA img,.GroupAttributes__E95mr img{width:50px;height:50px;border-radius:5px}.EventPlaylistEventsInput__xerk6{display:flex;flex-direction:column;gap:10px}", "",{"version":3,"sources":["webpack://./src/pages/ManageEventPlaylists/components/EventPlaylistEventsInput.module.scss"],"names":[],"mappings":"AAAA,gDACE,YAAA,CACA,kBAAA,CACA,eAAA,CACA,kBAAA,CACA,OAAA,CAEA,wDACE,UAAA,CACA,WAAA,CACA,iBAAA,CAQJ,iCACE,YAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":[".EventAttributes {\n  display: flex;\n  align-items: center;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  gap: 5px;\n\n  img {\n    width: 50px;\n    height: 50px;\n    border-radius: 5px;\n  }\n}\n\n.GroupAttributes {\n  @extend .EventAttributes;\n}\n\n.EventPlaylistEventsInput {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EventAttributes": "EventAttributes__IOyDA",
	"GroupAttributes": "GroupAttributes__E95mr",
	"EventPlaylistEventsInput": "EventPlaylistEventsInput__xerk6"
};
export default ___CSS_LOADER_EXPORT___;
