import {TEventStatus} from '@posh/server/dist/src/models/event'
import {Ticket} from 'apis/Events/types'
import {GetAllEventTicketsOutput} from 'apis/Events/useGetAllEventTickets'
import {isEmpty} from 'lodash'
import moment from 'moment'

export const calculateEventStatus = (eventEndDate?: moment.Moment, status?: string): TEventStatus => {
  const today = moment.utc()
  if (!eventEndDate) return 'incomplete'
  if (status === 'draft') return 'draft'
  if (eventEndDate.isBefore(today)) {
    return 'ended'
  } else if (eventEndDate.isAfter(today)) {
    return 'live'
  } else {
    return 'incomplete'
  }
}

export const TICKET_SALE_STATUS = ['On Sale', 'Scheduled', 'Closed', 'Hidden'] as const
export type TicketSaleStatus = (typeof TICKET_SALE_STATUS)[number]

export const ticketSaleStatus = (t: Ticket | GetAllEventTicketsOutput[0], timezone: string): TicketSaleStatus => {
  const date = moment.tz(timezone ?? 'America/New_York')
  const today = moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss')
  const endSale = moment.tz(t.endSaleUtc, timezone ?? 'America/New_York')
  const startSale = moment.tz(t.onSaleUtc, timezone ?? 'America/New_York')

  if (t.closed || (!isEmpty(t.endSaleUtc) && endSale.isBefore(today))) return 'Closed'
  else if (t.isHidden) return 'Hidden'
  else if (
    !isEmpty(t.onSaleUtc) &&
    startSale.isAfter(today) &&
    (isEmpty(t.endSaleUtc) || (!isEmpty(t.endSaleUtc) && endSale.isAfter(today)))
  )
    return 'Scheduled'
  else return 'On Sale'
}
