// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageOverlay__Jne94{width:100%;height:100vh;position:fixed;z-index:999;top:0;display:flex;justify-content:center;overflow:hidden}", "",{"version":3,"sources":["webpack://./src/components/PageComponents/EventVisuals/Page/PageOverlay/styles.module.scss"],"names":[],"mappings":"AAAA,oBACE,UAAA,CACA,YAAA,CACA,cAAA,CACA,WAAA,CACA,KAAA,CACA,YAAA,CACA,sBAAA,CACA,eAAA","sourcesContent":[".PageOverlay {\n  width: 100%;\n  height: 100vh;\n  position: fixed;\n  z-index: 999;\n  top: 0;\n  display: flex;\n  justify-content: center;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageOverlay": "PageOverlay__Jne94"
};
export default ___CSS_LOADER_EXPORT___;
