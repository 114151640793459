import React, {useEffect, useState} from 'react'

import {AccountSearchResponse, useSearchAccounts} from 'apis/Accounts/useSearchAccounts'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import PhoneNumberInput from 'components/form/PhoneNumberInput'
import {useDimensions} from 'hooks/useDimensions'
import {LoginFlow} from 'pages/LoginPage'
import {LoginMethodPicker} from 'pages/LoginPage/LoginMethodPicker'

import {SearchedData} from '..'
import AccountSearchAttendeesList from './AccountSearchAttendeesList'

interface AccountSearchModalPrimaryStepProps {
  onAddUser: (account: AccountSearchResponse) => Promise<void>
  userBeingAdded: string
  primaryTitle: string
  primaryButtonText: string
  switchToSecondaryStep: (searchedData: SearchedData) => void
}

const AccountSearchModalPrimaryStep = (props: AccountSearchModalPrimaryStepProps) => {
  const {onAddUser, primaryTitle, primaryButtonText, switchToSecondaryStep, userBeingAdded} = props
  const [searchText, setSearchText] = useState('')
  const {isMobile} = useDimensions()
  const {mutateAsync: searchAccounts, isLoading} = useSearchAccounts()
  const [searchMethod, setSearchMethod] = useState<LoginFlow>(isMobile ? 'phone' : 'email')
  const [accounts, setAccounts] = useState<AccountSearchResponse[]>([])
  const [isTyping, setIsTyping] = useState(false)
  const noAccountsFound = searchText.length > 0 && accounts.length === 0 && !isLoading && isTyping === false
  const handleSearchAccounts = async () => {
    try {
      const response = await searchAccounts(searchText)
      setAccounts(response)
      setIsTyping(false)
    } catch (error) {
      console.log(error)
    }
  }

  const switchSearchMethod = (flow: LoginFlow) => {
    setSearchMethod(flow)
    setAccounts([])
    setSearchText('')
  }

  useEffect(() => {
    // debounce search attendees
    setIsTyping(true)
    const timeout = setTimeout(() => {
      if (searchText && searchText.length > 0) handleSearchAccounts()
    }, 1500)
    return () => clearTimeout(timeout)
  }, [searchText])

  return (
    <div className='AccountSearchModal'>
      <h3>{primaryTitle}</h3>
      <LoginMethodPicker loginFlow={searchMethod} switchLoginFlow={switchSearchMethod} />
      {searchMethod === 'email' ? (
        <Input
          className='fullWidth'
          type='text'
          onChange={e => setSearchText(e.target.value)}
          placeholder='Search by email'
          value={searchText}
        />
      ) : (
        <PhoneNumberInput placeholder='Search by phone' value={searchText} onChange={e => setSearchText(e ?? '')} />
      )}
      <AccountSearchAttendeesList
        accounts={accounts}
        onAddUser={onAddUser}
        isLoading={isLoading}
        userBeingAdded={userBeingAdded}
      />
      {noAccountsFound && (
        <p className='m-0'>
          <i>No accounts found</i>
        </p>
      )}
      <Button
        className='light fullWidth dark'
        onClick={() => switchToSecondaryStep({data: searchText, type: searchMethod})}>
        {primaryButtonText}
      </Button>
    </div>
  )
}

export default AccountSearchModalPrimaryStep
