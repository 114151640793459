import React, {useEffect, useState} from 'react'
import ReactCodeInput from 'react-code-input'
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input'

import {
  ConfirmAndAuthenticateAccountParams,
  useConfirmAndAuthenticateAccount,
} from 'apis/Accounts/useConfirmAndAuthenticateAccount'
import {FetchUnconfirmedAccountOutput} from 'apis/Accounts/useFetchUnconfirmedAccount'
import {useSendUnconfirmedAccountSignupCode} from 'apis/Auth/useSendUnconfirmedAccountSignupCode'
import {useCreateAccountOptIn} from 'apis/Optins/useCreateAccountOptIn'
import {uploadImage} from 'apis/Util/useUploadImage'
import Button from 'components/form/Button'
import SingleImagePicker, {PictureState} from 'components/form/ImagePicker'
import Input from 'components/form/Input'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'
import {useDimensions} from 'hooks/useDimensions'
import {phoneStyle} from 'pages/NewCheckoutPage/LoginForm/PhoneLoginForm/secondStep'
import {useTOSCheckForm} from 'pages/OwnerPage/SignUp/TOSCheckForm'

interface ConfirmAccountEditorProps {
  unconfirmedAccount: FetchUnconfirmedAccountOutput
  invalidateAccount: () => void
}

export const ConfirmAccountEditor = (props: ConfirmAccountEditorProps) => {
  const {isNewTOSCheckFormEnabled, oldCheckForms, setOldCheckForms, newTOS, setNewTOS, TOSCheckForm} = useTOSCheckForm()
  const {unconfirmedAccount, invalidateAccount} = props
  const {userId} = useSessionContext()
  const {mutateAsync: createOptIn} = useCreateAccountOptIn()
  const {mutateAsync: confirmAndAuthenticateAccount, isLoading} = useConfirmAndAuthenticateAccount()
  const {mutateAsync: sendUnconfirmedAccountSignupCode, isLoading: isSendingCode} =
    useSendUnconfirmedAccountSignupCode()
  const {showToast} = useToast()
  const {isMobile} = useDimensions()

  const [email, setEmail] = useState(
    unconfirmedAccount.email && !unconfirmedAccount.hasTempEmail ? unconfirmedAccount.email : '',
  )
  const [firstName, setFirstName] = useState(unconfirmedAccount.firstName ?? '')
  const [lastName, setLastName] = useState(unconfirmedAccount.lastName ?? '')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [verifyCode, setVerifyCode] = useState('')
  const [redirectLink, setRedirectLink] = useState('')
  const [phone, setPhone] = useState(unconfirmedAccount.phone ?? '')
  const [hasSentVerificationCode, setHasSentVerificationCode] = useState(false)
  const [hasSubmittedOnceWithoutSMSOptIn, setHasSubmittedOnceWithoutSMSOptIn] = useState(false)
  const [profilePicture, setProfilePicture] = useState<PictureState>({
    url: 'https://images.posh.vip/b2/default-avi.jpg',
    file: null,
  })
  const urlParams = new URLSearchParams(window.location.search)
  const encodedRedirectUrlFromParams = urlParams.get('r')

  useEffect(() => {
    if (redirectLink && userId) window.location.href = redirectLink
  }, [redirectLink, userId])

  const confirmAcount = async () => {
    try {
      if (!firstName) throw new Error('Please enter your first name')
      if (!lastName) throw new Error('Please enter your last name')
      if (!email && unconfirmedAccount.hasTempEmail)
        throw new Error('You need to provide an email to confirm this account')
      if (password !== confirmPassword) throw new Error('Passwords do not match')
      if (password.length < 8) throw new Error('Password must be at least 8 characters')
      if (!verifyCode) throw new Error('Please enter the verification code')
      let avi
      if (profilePicture.file && profilePicture.url != 'https://images.posh.vip/b2/default-avi.jpg')
        avi = await uploadImage(profilePicture.file)

      const confirmAccount: ConfirmAndAuthenticateAccountParams = {
        accountId: unconfirmedAccount.accountId,
        smsOptIn: isNewTOSCheckFormEnabled ? newTOS.transactionalSmsOptIn : oldCheckForms.smsOptIn,
        email,
        password,
        confirmPassword,
        verificationCode: verifyCode,
        avi,
        phone,
        firstName,
        lastName,
      }
      const response = await confirmAndAuthenticateAccount(confirmAccount)
      if (isNewTOSCheckFormEnabled) {
        await createOptIn({
          marketing: newTOS.marketingSmsOptIn,
          transactional: newTOS.transactionalSmsOptIn,
        })
      }
      setRedirectLink(encodedRedirectUrlFromParams ?? response.redirectTo.desktop)
    } catch (error) {
      if (error.response) showToast({type: 'error', title: error.response.data.error})
      else showToast({type: 'error', title: error.message})
    }
  }

  const continueFlow = async () => {
    try {
      if (!password || !confirmPassword) throw new Error('Please enter a password')
      if (password !== confirmPassword) throw new Error('Passwords do not match')
      if (!oldCheckForms.tos || !newTOS.tos)
        throw new Error('Must read and accept terms of service and privacy policy to sign up')
      if (!oldCheckForms.smsOptIn && !newTOS.transactionalSmsOptIn && !hasSubmittedOnceWithoutSMSOptIn) {
        setHasSubmittedOnceWithoutSMSOptIn(true)
        return showToast({
          type: 'warning',
          title: 'Are you sure you want to opt out of text notifications for important event-related alerts?',
        })
      }
      if (!hasSentVerificationCode) {
        if (!isPossiblePhoneNumber(phone)) throw new Error('Please enter a valid phone number')
        await sendUnconfirmedAccountSignupCode({phone, accountId: unconfirmedAccount.accountId})
        setHasSentVerificationCode(true)
      } else {
        await confirmAcount()
      }
    } catch (error) {
      if (error.response) showToast({type: 'error', title: error.response.data.error})
      else showToast({type: 'error', title: error.message})
    }
  }

  const hasNotCheckedTOS = isNewTOSCheckFormEnabled ? !newTOS.tos : !oldCheckForms.tos

  return (
    <>
      <h3 className='m-0'>{unconfirmedAccount.title}</h3>
      <p className='m-0'>{unconfirmedAccount.description}</p>
      {!unconfirmedAccount.hideProfilePicSetting && (
        <SingleImagePicker
          className='ConfirmAccount-imagePicker'
          title='Profile Picture'
          showTitle={false}
          value={profilePicture}
          didReceiveFile={e => setProfilePicture(e)}
          icon={true}
        />
      )}
      {hasSentVerificationCode ? (
        <>
          <p>Enter the code we sent to {phone}</p>
          <ReactCodeInput
            className='CheckoutPage-codeInput'
            type='number'
            fields={6}
            name='phoneCode'
            inputMode={'tel'}
            inputStyle={phoneStyle(isMobile)}
            value={verifyCode}
            onChange={e => setVerifyCode(e)}
          />
        </>
      ) : (
        <>
          <Input
            className='ConfirmAccount-input'
            placeholder='First Name'
            type='text'
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            required
          />
          <Input
            className='ConfirmAccount-input'
            placeholder='Last Name'
            type='text'
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            required
          />
          <PhoneInput
            className='AccountSettings-phoneInput'
            placeholder='Enter phone number'
            value={phone}
            onChange={e => setPhone(e ?? '')}
            required
            defaultCountry='US'
          />
          <Input
            className='ConfirmAccount-input'
            placeholder='Set your email'
            type='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
          <Input
            className='ConfirmAccount-input'
            placeholder='Choose a password'
            type='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />
          <Input
            className='ConfirmAccount-input'
            placeholder='Confirm password'
            type='password'
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            required
          />
        </>
      )}
      {!hasSentVerificationCode && (
        <TOSCheckForm
          newTOS={newTOS}
          setNewTOS={setNewTOS}
          checkForms={oldCheckForms}
          setCheckForms={setOldCheckForms}
        />
      )}
      {redirectLink ? (
        <a href={redirectLink}>Redirect to Dashboard</a>
      ) : (
        <>
          <Button
            disabled={isLoading || isSendingCode || redirectLink.length > 0 || hasNotCheckedTOS}
            onClick={continueFlow}
            className='blue'>
            <PoshImage src={unconfirmedAccount.buttonIcon} />
            {unconfirmedAccount.buttonText /*TODO: uncouple button text from the fetch unconfirmed account endpoint*/}
          </Button>
          {hasSentVerificationCode && (
            <Button disabled={isLoading || isSendingCode} onClick={() => setHasSentVerificationCode(false)}>
              Go Back
            </Button>
          )}
        </>
      )}

      <hr />
      <div>
        <p className='text-small m-0'>Dont want to create an account?</p>
        <a className='text-small m-0' onClick={invalidateAccount}>
          Click here to remove your data.
        </a>
      </div>
    </>
  )
}
