import React, {PropsWithChildren, ReactNode} from 'react'

import classNames from 'classnames'
import {ToggleableEye} from 'components/assets/ToggleableEye'
import Button from 'components/form/Button'
import PoshInfo, {WithToolTip} from 'components/form/PoshInfo'
import {useEventVisualsContext} from 'pages/EventManagementPages/Visuals/Upgrade-dev/components/context/EventVisualsContext'

import styles from './styles.module.scss'

interface BaseEventVisualsSectionProps {
  headerText?: string
  underlined?: boolean
  isHidden?: boolean
  className?: string
  colorOverride?: string
  tooltipInfo?: string
  style?: React.CSSProperties
}
interface WithRightIconProps {
  rightIcon?: ReactNode
}

interface WithButtonProps {
  buttonProps?: {text: string | ReactNode; onClick: () => void; className?: 'gold' | 'dark'}
}

export type EventVisualsSectionProps = BaseEventVisualsSectionProps & (WithRightIconProps | WithButtonProps)

export function EventVisualsSection(props: PropsWithChildren<EventVisualsSectionProps>) {
  const {headerText, underlined, children, colorOverride, tooltipInfo, style} = props
  const {
    palette: {textContrasting},
  } = useEventVisualsContext()
  const rightIcon =
    'rightIcon' in props ? (
      props.rightIcon
    ) : 'buttonProps' in props ? (
      <Button {...props.buttonProps}>{props.buttonProps?.text}</Button>
    ) : null

  const textColor = colorOverride ? colorOverride : textContrasting

  return (
    <div
      className={classNames(styles.EventVisualsSection, {[`${styles.hidden}`]: props.isHidden}, props.className)}
      style={style}>
      {headerText && (
        <div
          style={{borderColor: textColor}}
          className={classNames(`${styles.EventVisualsSectionHeader} flex items-center justify-between`)}>
          <h5 className='m-0' style={{color: textColor}}>
            {headerText}
            {tooltipInfo && <PoshInfo info={tooltipInfo} />}
          </h5>
          {rightIcon}
        </div>
      )}
      {underlined && <div className={styles.underline} style={{backgroundColor: textColor}}></div>}
      {children}
    </div>
  )
}

function WithEye(
  props: PropsWithChildren<
    Omit<BaseEventVisualsSectionProps, 'rightIcon'> & {
      eyeProps: {fill: string; onClick: () => void}
    }
  >,
) {
  const {eyeProps, tooltipInfo, ...rest} = props
  return (
    <EventVisualsSection
      {...rest}
      rightIcon={
        <WithToolTip info={tooltipInfo}>
          <ToggleableEye {...eyeProps} visible={!rest.isHidden} />
        </WithToolTip>
      }
    />
  )
}

EventVisualsSection.WithEye = WithEye
