import React from 'react'

const EventSection = ({
  children,
  header,
  accentColor,
  className,
  colorModeModifier,
}: {
  children: any
  header?: string
  accentColor: string
  className?: string
  colorModeModifier?: string
}) => {
  return (
    <>
      <div className={`EventPage-section ${className}`}>
        <div className='EventPage-section-inner'>
          {header && <h4 style={{color: colorModeModifier === 'is-lightMode' ? accentColor : '#fff'}}>{header}</h4>}
          {children}
        </div>
      </div>
    </>
  )
}

export default EventSection
