import {zobjectId} from '@posh/types'
import {z} from 'zod'

const AccountOrdersPageParamsSchema = z.object({
  transfer: zobjectId.optional(),
})

export const useAccountOrdersPageParams = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const parsedParams = AccountOrdersPageParamsSchema.safeParse({
    transfer: queryParams.get('transfer') ?? undefined,
  })
  if (!parsedParams.success) throw new Error('Invalid Create Event params')
  return parsedParams.data
}
