import React, {HTMLAttributes, PropsWithChildren} from 'react'

import styles from './RecurrenceSettingsSection.module.scss'

interface RecurrenceSettingsSectionProps extends Pick<HTMLAttributes<HTMLDivElement>, 'style'> {
  label?: string
  error?: string
  innerStyle?: React.CSSProperties
}

export const RecurrenceSettingsSection = (props: PropsWithChildren<RecurrenceSettingsSectionProps>) => {
  const {children, style, innerStyle} = props
  return (
    <div className={styles.RecurrenceSettingsSectionWrapper} style={style}>
      {props.label && <p className={styles.RecurrenceSettingsSectionLabel}>{props.label}</p>}
      <div className={styles.RecurrenceSettingsSection} style={innerStyle}>
        {children}
      </div>
      {props.error && <p className='m-0 pt-2 text-xs text-red-300!'>{props.error}</p>}
    </div>
  )
}
