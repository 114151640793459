import React, {useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {StaffMember} from 'apis/Community'
import useUpdateCommunityClicks from 'apis/Community/useUpdateCommunityClicks'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import PoshInfo from 'components/form/PoshInfo'
import GalleryCarousel from 'components/GalleryCarousel'
import useCheckIfClickedOutsideDiv from 'components/helpers/clickedOutsideDiv'
import loginRedirect from 'components/helpers/loginRedirect'
import signupRedirect from 'components/helpers/signupRedirect'
import {PoshImage} from 'components/PoshImage/PoshImage'
import useSessionContext from 'domains/Auth/SessionContext'
import {startCase} from 'lodash'

import './styles.scss'

interface StaffGroupProps {
  staffMembers: StaffMember[]
}

interface StaffCardProps {
  staffMember: StaffMember
  setIsShowingNotAuthedMenu: React.Dispatch<React.SetStateAction<string>>
  isShowingNotAuthedMenu: string
}

interface StaffCardNotAuthedProps {
  url: string
}

const StaffCardNotAuthedMenu = (props: StaffCardNotAuthedProps) => {
  const {url} = props
  const redirectLink = `/community/book?c=staff&u=${url}`
  const navigate = useNavigate()
  return (
    <div className='StaffCard-nABook'>
      <div className='StaffCard-nABook-corner' />
      <h5>Book David</h5>
      <p className='text-small'>Sign up to make booking requests</p>
      <div className='StaffCard-nABook-actions'>
        <a onClick={() => loginRedirect(navigate, redirectLink)}>Login</a>
        <Button onClick={() => signupRedirect(navigate, redirectLink, true)}>Sign up</Button>
      </div>
    </div>
  )
}

const StaffCard = (props: StaffCardProps) => {
  const {staffMember, isShowingNotAuthedMenu, setIsShowingNotAuthedMenu} = props
  const {mutateAsync: updateCommunityClicks} = useUpdateCommunityClicks()
  const {currentUser} = useSessionContext()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const navigate = useNavigate()
  const notAuthedPopupRef = useRef(null)
  const navigateToStaffMember = async (url: string) => {
    const staffName = `${staffMember.firstName} ${staffMember.lastName}`
    await updateCommunityClicks({id: staffMember._id, category: 'staff'})
    const accountEmail = currentUser?.email
    const accountId = currentUser?._id
    const accountName = currentUser?.firstName + ' ' + currentUser?.lastName
    trackMixpanelEvent('Community Profile Clicked', {
      communityCategory: 'staffMembers',
      communityName: staffName,
      accountEmail,
      accountId,
      accountName,
    })
    navigate(`/c/staff/${url}`)
  }

  const formattedStaffType = startCase(staffMember.staffType)

  const handleBookStaff = (url: string) => {
    if (!currentUser) {
      setIsShowingNotAuthedMenu(url)
      return
    } else navigate(`/community/book?c=staff&u=${url}`)
  }

  useCheckIfClickedOutsideDiv(notAuthedPopupRef, () => setIsShowingNotAuthedMenu(''))

  return (
    <div className='StaffCard'>
      <div className='StaffCard-info'>
        <PoshImage src={staffMember.avi ?? 'https://images.posh.vip/b2/blank-avi.png'} className='StaffCard-info-avi' />
        <div className='StaffCard-info-details'>
          <div className='StaffCard-info-details-name'>
            {staffMember.firstName} {staffMember.lastName}
          </div>
          <div className='StaffCard-info-details-type'>{formattedStaffType}</div>
          <div className='StaffCard-info-details-location'>
            <PoshImage src='https://images.posh.vip/b2/maps-and-flags.svg' />
            {staffMember.location}
          </div>
        </div>
      </div>
      <GalleryCarousel images={staffMember.galleryImages ? staffMember.galleryImages.map(i => i.url) : []} />
      <div className='StaffCard-price'>
        {currentUser ? (
          <span>
            {staffMember.hourlyRate == 0 && (!staffMember.minimumPrice || staffMember.minimumPrice == 0) ? (
              <i>Negotiable</i>
            ) : (
              <>
                {staffMember.hourlyRate && staffMember.hourlyRate > 0 ? (
                  <>
                    ${staffMember.hourlyRate}/hr
                    {staffMember.minimumPrice && staffMember.minimumPrice !== 0 && (
                      <PoshInfo info={`$${staffMember.minimumPrice} min`} />
                    )}
                  </>
                ) : (
                  <>${staffMember.minimumPrice} min</>
                )}
              </>
            )}
          </span>
        ) : (
          <span>
            $<span style={{filter: 'blur(3px)'}}>XXX</span>/hr
          </span>
        )}
        <div className='StaffCard-price-actions'>
          <Button onClick={() => navigateToStaffMember(staffMember.url)} className='round light outline'>
            View Profile
          </Button>
          <Button onClick={() => handleBookStaff(staffMember.url)} className='round light'>
            Book
          </Button>
        </div>
      </div>
      {isShowingNotAuthedMenu && isShowingNotAuthedMenu === staffMember.url && (
        <div ref={notAuthedPopupRef}>
          <StaffCardNotAuthedMenu url={staffMember.url} />
        </div>
      )}
    </div>
  )
}

const StaffGroup = (props: StaffGroupProps) => {
  const {staffMembers} = props
  const [isShowingNotAuthedMenu, setIsShowingNotAuthedMenu] = useState('')
  return (
    <>
      <div>
        <div className='Community-staffHolder'>
          {staffMembers?.map(staffMember => (
            <StaffCard
              staffMember={staffMember}
              isShowingNotAuthedMenu={isShowingNotAuthedMenu}
              setIsShowingNotAuthedMenu={setIsShowingNotAuthedMenu}
              key={staffMember._id}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default StaffGroup
