import {useEffect} from 'react'

import useFetchEvent from 'apis/Events/useFetchEvent'
import {useGetAvailableMarketingTextBlasts} from 'apis/Groups/useGetAvailableMarketingTextBlasts'
import pluralize from 'helpers/pluralize'

enum SMS_LIMIT_REASONS {
  MONTHLY_LIMIT = 'SMS is limited to 10 text blasts within a rolling 30 day period.',
  EVENT_ENDED = 'SMS blasts are only available within 7 days of event end date',
}

const REMAINING_BLAST_WARNING_THRESHOLD = 7
const EVENT_END_GRACE_PERIOD_DAYS = 7

/**
 * Hook to check the status of text blast capabilities for a group
 * @param groupId - Required. The ID of the group to check text blast status for
 * @param eventEndDate - Optional. Only provide if checking blasts in event context.
 *                      If provided, will disable blasts if event ended more than 3 days ago
 * @returns {Object} Status info including:
 *  - remainingTextBlasts: Number of blasts remaining this month, defaults to 0 if eventEndDate is provided/ invalid, and 10 otherwise
 *  - isSMSCampaignEnabled: Whether SMS campaigns are currently enabled
 *  - shouldShowRemainingCampaigns: Whether to show remaining campaign count warning
 *  - remainingCampaignsText: Text to display remaining campaign count
 *  - smsLimitReasoningInfo: Reason if SMS campaigns are disabled
 */
export const useGroupTextBlastStatus = ({groupId, eventId}: {groupId?: string; eventId?: string}) => {
  const {data: remainingBlasts} = useGetAvailableMarketingTextBlasts(
    {groupId: groupId!, eventId: eventId},
    {enabled: !!groupId},
  )
  const {data: event, refetch} = useFetchEvent(eventId ?? '')
  const eventEndDate = event?.event?.endUtc

  useEffect(() => {
    refetch()
  }, [])

  let remainingTextBlasts = remainingBlasts?.remainingTextBlasts ?? 0
  let smsLimitReasoningInfo: SMS_LIMIT_REASONS = SMS_LIMIT_REASONS.MONTHLY_LIMIT
  // If event end date is provided, check if it's past the grace period
  if (eventEndDate) {
    const eventEndDateTime = new Date(eventEndDate)
    const gracePeriodCutoff = new Date(eventEndDateTime)
    gracePeriodCutoff.setDate(gracePeriodCutoff.getDate() + EVENT_END_GRACE_PERIOD_DAYS)

    // If current date is after grace period cutoff (event end + 3 days), disable SMS blasts
    if (new Date() > gracePeriodCutoff) {
      remainingTextBlasts = 0
      smsLimitReasoningInfo = SMS_LIMIT_REASONS.EVENT_ENDED
    }
    // If theres an event present then allow blasts (we are within the grace period)
    else if (!!event) {
      remainingTextBlasts = 10
    }
    // Set remaining blasts to the remaining blasts from the API if there is no event
    else {
      remainingTextBlasts = remainingBlasts?.remainingTextBlasts ?? 10
    }
  }

  const isSMSCampaignEnabled = remainingTextBlasts > 0
  const shouldShowRemainingCampaigns = remainingTextBlasts <= REMAINING_BLAST_WARNING_THRESHOLD

  const remainingDaysUntilNextBlast = remainingBlasts?.daysUntilNextBlast
    ? `(${remainingBlasts.daysUntilNextBlast} ${pluralize('day', remainingBlasts.daysUntilNextBlast)} until next available blast)`
    : ''
  const remainingCampaignsText = `${remainingTextBlasts} Remaining SMS ${pluralize('Blast', remainingTextBlasts)} ${remainingDaysUntilNextBlast}`

  return {
    remainingTextBlasts,
    isSMSCampaignEnabled,
    shouldShowRemainingCampaigns,
    remainingCampaignsText,
    smsLimitReasoningInfo,
  }
}
