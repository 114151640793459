import {useNavigate, useParams} from 'react-router-dom'

import {useIsFeatureEnabled} from 'components/FeatureFlag'

type NavigateToCreateEvent =
  | {
      useSearchParams?: false
      groupId?: string
      pendingEventId?: string
    }
  | {
      useSearchParams: true
    }

export const useNavigateToCreateEvent = () => {
  const navigate = useNavigate()
  const canGoToNewCreateEvent = useIsFeatureEnabled('create_event_updated_editor')
  const createEventUrl = '/create'

  const urlParams = useParams()

  const navigateToCreateEvent = (params?: NavigateToCreateEvent) => {
    let groupId: string | undefined
    let pendingEventId: string | undefined
    if (params?.useSearchParams) {
      groupId = urlParams.groupId
    } else {
      groupId = params?.groupId
      pendingEventId = params?.pendingEventId
    }

    const unfilteredQueryParams = {
      g: groupId,
      p: canGoToNewCreateEvent ? pendingEventId : undefined,
    }
    const queryParams = JSON.parse(JSON.stringify(unfilteredQueryParams))
    const searchParams = `?${new URLSearchParams(queryParams).toString()}`
    return navigate({pathname: createEventUrl, search: searchParams})
  }
  return {navigateToCreateEvent, createEventUrl}
}
