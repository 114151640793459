import {useCallback} from 'react'

/**
 * Custom hook to handle drag-and-drop reordering for any array.
 *
 * @param items - The array of items to be reordered.
 * @param setItems - The setter function for updating the array.
 * @returns An object containing handlers for drag-and-drop functionality.
 */
function useDragAndReorder<T>(items: T[], setItems: (items: T[]) => void) {
  /**
   * Reorders the items array by moving an item from the startIndex to the endIndex.
   *
   * @param startIndex - The index of the item to move.
   * @param endIndex - The index where the item should be moved.
   * @returns A new reordered array.
   */
  const reorder = useCallback(
    (startIndex: number, endIndex: number): T[] => {
      const result = Array.from(items)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)
      return result
    },
    [items],
  )

  /**
   * Handles the drag-and-drop event to reorder items.
   *
   * @param oldIndex - The index of the item being dragged.
   * @param newIndex - The index where the item is dropped.
   */
  const onDragEnd = useCallback(
    (oldIndex: number, newIndex: number) => {
      if (oldIndex === newIndex) return
      const reorderedItems = reorder(oldIndex, newIndex)
      setItems(reorderedItems)
    },
    [reorder, setItems],
  )

  return {reorder, onDragEnd}
}

export default useDragAndReorder
