import {updateGroupSmsOptIn} from 'apis/Optins'
import {useFollowAccount} from 'apis/Social/accounts/useFollowAccount'
import {useFollowGroup} from 'apis/Social/accounts/useFollowGroup'
import {useUnfollowAccount} from 'apis/Social/accounts/useUnfollowAccount'
import {useUnfollowGroup} from 'apis/Social/accounts/useUnfollowGroup'
import {useAccountGroupOptIns} from 'hooks/optIns'

import {TProfileType} from '../types'

export function useHandleProfileFollow(
  type: Omit<TProfileType, 'venue'>,
  location: 'account-profile' | 'group-profile' | 'event-page',
  id?: string,
) {
  const {mutateAsync: followAccount, isLoading: isFollowingAccount} = useFollowAccount()
  const {mutateAsync: followGroup, isLoading: isFollowingGroup} = useFollowGroup()
  const {mutateAsync: unfollowAccount, isLoading: isUnfollowingAccount} = useUnfollowAccount()
  const {mutateAsync: unfollowGroup, isLoading: isUnfollowingGroup} = useUnfollowGroup()

  const {orgOptInData, isOptedInToPoshSms, updateGroupOptIn} = useAccountGroupOptIns({
    optInPopUpExperiment: true,
    location,
    groupId: id,
  })

  const toggleFollowProfile = (id: string, isFollowed: boolean) => {
    if (type === 'account') {
      isFollowed ? unfollowAccount({accountId: id}) : followAccount({accountId: id})
    } else {
      if (isFollowed) {
        unfollowGroup({groupId: id})
        orgOptInData && updateGroupOptIn(updateGroupSmsOptIn(orgOptInData, false))
      } else {
        followGroup({groupId: id})
        isOptedInToPoshSms && orgOptInData && updateGroupOptIn(updateGroupSmsOptIn(orgOptInData, true))
      }
    }
  }

  const isLoading = isFollowingAccount || isFollowingGroup || isUnfollowingAccount || isUnfollowingGroup

  return {toggleFollowProfile, isLoading}
}
