import React, {PropsWithChildren, useState} from 'react'
import {usePopper} from 'react-popper'

import {Options as PopperOptions, Placement, VirtualElement} from '@popperjs/core'
import isUndefined from 'lodash/isUndefined'

export const generatePopperOptions = (
  arrowElement: HTMLDivElement | null,
  placement?: Placement,
): Partial<PopperOptions> => ({
  strategy: 'absolute',
  placement: placement ?? 'top',
  modifiers: [
    {name: 'flip', enabled: isUndefined(placement)},
    {name: 'arrow', options: {element: arrowElement}},
  ],
})

export const useTooltip = (placement?: Placement) => {
  const [referenceElement, setReferenceElement] = useState<Element | VirtualElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)

  const {styles, attributes} = usePopper(
    referenceElement,
    popperElement,
    generatePopperOptions(arrowElement, placement),
  )

  const [isShowing, setIsShowing] = useState(false)

  const showTooltip = () => setIsShowing(true)
  const hideTooltip = () => setIsShowing(false)

  return {
    referenceProps: {
      ref: setReferenceElement,
      onMouseEnter: showTooltip,
      onMouseLeave: hideTooltip,
      onFocus: showTooltip,
      onBlur: hideTooltip,
    },
    tooltipProps: {
      isShowing,
      popperRef: setPopperElement,
      arrowRef: setArrowElement,
      styles,
      attributes,
    },
  }
}

type Props = ReturnType<typeof useTooltip>['tooltipProps']

const Tooltip: React.FunctionComponent<PropsWithChildren<Props>> = props => {
  if (!props.children || !props.isShowing) return null

  return (
    <div className='Tooltip' ref={props.popperRef} style={props.styles.popper} {...props.attributes.popper}>
      <div className='Tooltip-body'>{props.children}</div>
      <div className='Tooltip-arrow' ref={props.arrowRef} style={props.styles.arrow} {...props.attributes.arrow} />
    </div>
  )
}

export default Tooltip
