import {AlertTriangle, Clock, FileText, FileWarning} from 'lucide-react'

import '../../styles.scss'
import './ResolutionGuide.scss'

const ResolutionGuide = () => {
  return (
    <section className='resolution-guide'>
      <div className='TableHeader'>
        <h2>Dispute Resolution Guide</h2>
        <p>
          Follow these steps to maximize your chances of winning disputes. For more tips, see our{' '}
          <a
            href='https://posh.vip/university/post/a-guide-to-disputes-at-posh'
            target='_blank'
            rel='noopener noreferrer'>
            Posh University Guide
          </a>
          .
        </p>
      </div>

      <div className='resolution-guide__content'>
        <div className='resolution-guide__grid'>
          {/* Step 1 */}
          <div className='resolution-guide__step'>
            <div className='resolution-guide__step-icon'>
              <FileWarning />
            </div>
            <div className='resolution-guide__step-content'>
              <h3>Act Quickly</h3>
              <p>
                Respond within 48 hours of receiving a dispute notification. Quick action shows commitment and increases
                your chances of success.
              </p>
            </div>
          </div>

          {/* Step 2 */}
          <div className='resolution-guide__step'>
            <div className='resolution-guide__step-icon'>
              <FileText />
            </div>
            <div className='resolution-guide__step-content'>
              <h3>Provide Clear Evidence</h3>
              <p>
                Submit transaction records, customer communications, and proof of services rendered. Clear evidence is
                critical to winning disputes.
              </p>
            </div>
          </div>

          {/* Step 3 */}
          <div className='resolution-guide__step'>
            <div className='resolution-guide__step-icon'>
              <Clock />
            </div>
            <div className='resolution-guide__step-content'>
              <h3>Meet All Deadlines</h3>
              <p>
                Submit evidence before the deadline. Late submissions are automatically rejected and will result in a
                lost dispute.
              </p>
            </div>
          </div>

          {/* Common Dispute Reasons */}
          <div className='resolution-guide__reasons'>
            <h3>Common Dispute Reasons & How to Counter Them</h3>

            <div className='resolution-guide__reasons-list'>
              <div className='resolution-guide__reasons-item'>
                <AlertTriangle />
                <div>
                  <h4>Fraudulent Charge</h4>
                  <p>
                    When a customer claims they didn&apos;t authorize the purchase, provide any conversations you may
                    have had with the attendee that indicates that they intended to purchase the ticket.
                  </p>
                </div>
              </div>

              <div className='resolution-guide__reasons-item'>
                <AlertTriangle />
                <div>
                  <h4>Product Not Received</h4>
                  <p>
                    Provide screenshot of attendee texts or email exchanges detailing their receipt of the email
                    confirmation.
                  </p>
                </div>
              </div>

              <div className='resolution-guide__reasons-item'>
                <AlertTriangle />
                <div>
                  <h4>Product Not as Described</h4>
                  <p>
                    Provide detailed product descriptions from your site, photos, and any communications showing the
                    customer understood what they were purchasing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ResolutionGuide
