// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error__diUFz{color:red;font-size:15px}", "",{"version":3,"sources":["webpack://./src/components/DynamicForm/Inputs/shared/Error/Error.module.scss"],"names":[],"mappings":"AAAA,cACE,SAAA,CACA,cAAA","sourcesContent":[".error {\n  color: red;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "error__diUFz"
};
export default ___CSS_LOADER_EXPORT___;
