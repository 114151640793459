import React, {useEffect, useState} from 'react'

import {CurrencyCode} from '@posh/types'
import {useQueryClient} from '@tanstack/react-query'
import useFetchEvent from 'apis/Events/useFetchEvent'
import useUpdateEventCustomFee from 'apis/Events/useUpdateEventCustomFee'
import {EventFee} from 'apis/Groups/useGetGroupForFinancials'
import useUpdateGroupCustomFee from 'apis/Groups/useUpdateGroupCustomFee'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {PoshMultiSelect} from 'components/form/MultiSelect'
import PoshSwitch from 'components/form/PoshSwitch'
import Select from 'components/form/Select'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {useToast} from 'components/toasts/ToastProvider'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {isEmpty, isEqual} from 'lodash'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

import {TicketTierFees} from '../AddCustomFeesModal'

import '../AddCustomFeesModal/styles.scss'

interface EditCustomFeeModal extends PoshStyledModalProps {
  scope: 'group' | 'event'
  currency: CurrencyCode
  fee: string
  allFees: EventFee[]
  eventId?: string
}

export const EditCustomFeeModal = (props: EditCustomFeeModal) => {
  const {onClose, scope, currency, fee, allFees, eventId} = props
  const {groupId} = useGroupMatchParams()
  const {data, isFetched} = useFetchEvent(eventId!)
  const {mutateAsync: updateGroupFee, isLoading: isLoadingGroupFee} = useUpdateGroupCustomFee()
  const {mutateAsync: updateEventFee, isLoading: isLoadingEventFee} = useUpdateEventCustomFee()
  const currentFee = allFees.find(f => f.name === fee)
  const {showToast} = useToast()
  // Tickets for event
  const [ticketTypes, setTicketTypes] = useState<TicketTierFees[]>([])
  // Show if the user has updated the tickets
  const [updatedTickets, setUpdatedTickets] = useState(false)
  const queryClient = useQueryClient()
  useEffect(() => {
    if (currentFee) {
      setName(currentFee.name)
      setType(currentFee.type)
      if (currentFee.type === 'percent') setAmount(currentFee.amount * 100)
      else setAmount(currentFee.amount)
    }
  }, [currentFee])
  const [name, setName] = useState(currentFee?.name)
  const [amount, setAmount] = useState(currentFee?.amount)
  const [type, setType] = useState(currentFee?.type)
  const [errorMessage, setErrorMessage] = useState('')
  const [isTicketTypeChecked, setIsTicketTypeChecked] = useState(false)
  const handleUpdateFee = async () => {
    setErrorMessage('')
    if (!name || !amount || !type || !fee) {
      setErrorMessage('Name and amount are required')
    } else {
      try {
        if (scope === 'group') await updateGroupFee({name, amount, type, groupId: groupId!, fee})
        else if (scope === 'event' && eventId) {
          const limitedToId: string[] = []
          for (const ticket of ticketTypes) {
            if (ticket.selected) limitedToId.push(ticket.id)
          }
          await updateEventFee({name, amount, type, eventId, fee, limitedToId})
        }
        queryClient.invalidateQueries([scope])
        onClose()
      } catch (error: any) {
        setErrorMessage(error.response.data.error as string)
      }
    }
  }

  const handleUpdatedTickets = (updates: TicketTierFees[]) => {
    setUpdatedTickets(true)
    setTicketTypes(updates)
  }
  const eventTickets = data?.event?.tickets
  useEffect(() => {
    // Check to set original state after tickets are fetched
    const originalCheck = !isEmpty(eventTickets) && isEmpty(ticketTypes)

    // Check to see that the originally shown tickets are the same as the database for the current fee
    let selectedTicketsCheck = true
    // Wrapped in if statement to prevent excessive array iteration
    // If tickets have been updated by user do not iterate the arrays again because they will not be used
    if (!isEmpty(ticketTypes) && !updatedTickets) {
      const ticketTypesCheck: string[] = []
      ticketTypes.forEach(el => {
        if (el.selected) ticketTypesCheck.push(el.id)
      })
      selectedTicketsCheck = isEqual(ticketTypesCheck, currentFee?.limitedToId)
    }

    if (originalCheck) {
      setTicketTypes(
        eventTickets!.map(el => {
          return {
            selected: false,
            name: el.name,
            id: el.id,
          }
        }),
      )
      setIsTicketTypeChecked(false)
      // Check to see that the originally shown tickets are the same as the database for the current fee
      // If tickets have been updated by user do not update state
    } else if (currentFee && !selectedTicketsCheck && !updatedTickets) {
      setTicketTypes(
        eventTickets!.map(el => {
          return {
            selected: currentFee?.limitedToId?.includes(el.id) ?? false,
            name: el.name,
            id: el.id,
          }
        }),
      )
      // Check to see if a different fee is being shown in the modal
      // If so, update tickets to match the new fee
    } else if (currentFee === undefined) {
      setUpdatedTickets(false)
      setIsTicketTypeChecked(false)
    }
    if (!isEmpty(currentFee?.limitedToId)) setIsTicketTypeChecked(true)
  }, [eventTickets, ticketTypes, currentFee, updatedTickets])

  return (
    <PoshStyledModal {...props}>
      <div className='AddCustomFeesModal'>
        <h3 className='m-0'>Edit Custom Fee</h3>
        {errorMessage && <p className='error m-0'>{errorMessage}</p>}
        <Input
          type='text'
          placeholder='Name of Fee'
          value={name}
          onChange={e => setName(e.target.value)}
          className='fullWidth'
        />
        <div className='AddCustomFeesModal-priceWrapper'>
          <Select value={type} onChange={e => setType(e.target.value as 'percent' | 'flat')}>
            <option value='percent'>%</option>
            <option value='flat'>{getCurrencySymbol(currency)}</option>
          </Select>
          <Input
            type='number'
            placeholder='Amount'
            value={amount}
            onChange={e => setAmount(Number(e.target.value))}
            className='AddCustomFeesModal-input'
          />
        </div>
        {scope === 'event' && (
          <PoshSwitch
            title='Limit to ticket type(s)'
            switchOptions={{
              checked: isTicketTypeChecked,
              onChange: () => {
                if (isEmpty(ticketTypes.filter(t => t.selected))) setIsTicketTypeChecked(!isTicketTypeChecked)
                else showToast({type: 'error', title: 'Must remove ticket types from fee before unchecking'})
              },
            }}
          />
        )}
        {isTicketTypeChecked &&
          (isFetched ? (
            <div className='AddCustomFeesModal-ticket-types'>
              <PoshMultiSelect
                options={ticketTypes}
                name='Assign Fee To Ticket Types'
                emptyMessage='No ticket types'
                placeholder='Select ticket types'
                updateOptions={handleUpdatedTickets}
              />
            </div>
          ) : (
            <SpinLoader height={80} />
          ))}
        <div className='buttonWrapper center'>
          <Button className='fullWidth dark' onClick={onClose}>
            CANCEL
          </Button>
          <Button
            className='gold fullWidth'
            disabled={isLoadingEventFee || isLoadingGroupFee}
            onClick={handleUpdateFee}>
            UPDATE FEE
          </Button>
        </div>
      </div>
    </PoshStyledModal>
  )
}
