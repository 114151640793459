import {RudderstackEventSource} from '@posh/express-helpers'
import {useMutation} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

export interface ISetUserSession {
  urlParams: Record<string, string>
  campaignId?: string
  userId?: string
  source: RudderstackEventSource
  additionalParams?: Record<string, any>
}

const setSessionEvent = async (body: ISetUserSession) => {
  const response = await PoshApi.setUserSession(body)
  return response.data
}

export function useSetSessionEvent() {
  return useMutation((params: ISetUserSession) => setSessionEvent(params))
}
