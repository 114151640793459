import React, {useCallback} from 'react'

import {Share} from 'lucide-react'

import {Camera, CopySquares, Equalizer, Eye, Gallery, Pencil} from '../../../assets/Icons'
import IconButton, {Props} from '../../../form/IconButton'

type IconType = 'camera' | 'pencil' | 'gallery' | 'equalizer' | 'copy' | 'share' | 'eye'

interface EventVisualsCircleButtonProps extends Omit<Props, 'icon'> {
  iconType: IconType
  lightMode: boolean
  accentColor: string
  size?: number
  style?: React.CSSProperties
}

const ICON_SIZE = 20
const ICON_PADDING = 11

const generateIcon = (iconType: IconType, iconColor: string, iconSize: number) => {
  switch (iconType) {
    case 'camera':
      return <Camera color={iconColor} width={iconSize} height={iconSize} />
    case 'pencil':
      return <Pencil color={iconColor} width={iconSize} height={iconSize} />
    case 'gallery':
      return <Gallery color={iconColor} width={iconSize} height={iconSize} />
    case 'equalizer':
      return <Equalizer color={iconColor} width={iconSize} height={iconSize} />
    case 'copy':
      return <CopySquares color={iconColor} width={iconSize} height={iconSize} />
    case 'share':
      return <Share color={iconColor} width={iconSize} height={iconSize} />
    case 'eye':
      return <Eye color={iconColor} width={iconSize} height={iconSize} />
    default:
      return null
  }
}

export const EventVisualsCircleButton = (props: EventVisualsCircleButtonProps) => {
  const {iconType, lightMode, accentColor, size = ICON_SIZE, style = {padding: ICON_PADDING}, ...rest} = props
  const iconColor = lightMode ? 'white' : 'black'

  const icon = useCallback(() => generateIcon(iconType, iconColor, size), [iconType, iconColor, size])

  return <IconButton {...rest} style={{backgroundColor: accentColor, ...style}} icon={icon} />
}
