import React from 'react'
import PhoneInput from 'react-phone-number-input'

import {CountryCode} from '@posh/types'

interface PhoneNumberInputProps {
  className?: string
  onChange: (value: string) => void
  value: string
  defaultCountry?: CountryCode
  placeholder?: string
  required?: boolean
  disabled?: boolean
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const PhoneNumberInput = (props: PhoneNumberInputProps) => {
  const {className, onChange, value, defaultCountry = 'US', placeholder, required, disabled, onKeyDown} = props
  return (
    <PhoneInput
      className={'poshPhoneInput ' + (className ?? '')}
      onChange={onChange}
      value={value}
      defaultCountry={defaultCountry}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      numberInputProps={{
        onKeyDown,
      }}
    />
  )
}

export default PhoneNumberInput
