import React from 'react'

import PoshSwitch, {PoshSwitchProps} from 'components/form/PoshSwitch'

interface EventVisualsSwitchProps extends PoshSwitchProps {
  accentColor?: string
  lightMode: boolean
}

const ICON_SIZE = 15

const SWITCH_WIDTH = 50
const SWITCH_HEIGHT = 30

export const EventVisualsSwitch = (props: EventVisualsSwitchProps) => {
  const {accentColor} = props

  return (
    <PoshSwitch
      {...props}
      onColor={accentColor}
      width={SWITCH_WIDTH}
      height={SWITCH_HEIGHT}
      switchOptions={props.switchOptions}
    />
  )
}
