import React, {useState} from 'react'
import {useParams} from 'react-router-dom'

import useFetchEvent from 'apis/Events/useFetchEvent'
import {RoleKey} from 'apis/Roles/Role'
import DoughnutGraph from 'components/charts/DoughnutGraph'
import AnalyticsGrid from 'components/data/AnalyticsGrid'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import useSessionContext from 'domains/Auth/SessionContext'
import isEmpty from 'lodash/isEmpty'
import AnalyticsCard from 'pages/EventManagementPages/Overview/AnalyticsCard'
import useAttendeesCrmContext from 'pages/GroupPage/Marketing/AttendeesCrmContext'
import AttendeesCrmContextProvider from 'pages/GroupPage/Marketing/AttendeesCrmContextProvider'
import AttendeesTable from 'pages/GroupPage/Marketing/AttendeesTable'
import {TagsModal} from 'pages/GroupPage/Marketing/TagsModal'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import useFetchEventAttendees from '../../../../apis/Events/useFetchEventAttendees'
import {
  useFetchEventAffiliateTrackingLinkBreakdown,
  useFetchEventHostBreakdown,
  useFetchEventTrackingLinkBreakdown,
} from '../../../../apis/Events/useFetchEventHostBreakdown'

import './index.scss'

type Params = Record<'hostId' | 'eventId' | 'affiliateTrackingLink' | 'trackingLink', string>

function HostBreakdown() {
  const {hostId, eventId, affiliateTrackingLink, trackingLink} = useParams<Params>()
  const {data: hbreakdown, isFetching: isLoading} = useFetchEventHostBreakdown(eventId, hostId)
  const {data: tLbreakdown, isFetching: tLisLoading} = useFetchEventTrackingLinkBreakdown(eventId ?? '', trackingLink)
  const {data: abreakdown, isFetching: aisLoading} = useFetchEventAffiliateTrackingLinkBreakdown(
    eventId ?? '',
    affiliateTrackingLink,
  )
  const {currentUser} = useSessionContext()
  const {data: eventData} = useFetchEvent(eventId!)
  const breakdown = hostId ? hbreakdown : affiliateTrackingLink ? abreakdown : tLbreakdown

  const TICKET_TYPE_RATIO_BG_COLORS = [
    '#FFECB3',
    '#FFE082',
    '#FFD54F',
    '#FFCA28',
    '#FFC107',
    '#FFB300',
    '#FFA000',
    '#FF8F00',
    '#FF6F00',
    '#FFE57F',
    '#FFD740',
    '#FFC400',
    '#FFAB00',
    '#FF9800',
    '#FFF3E0',
    '#FFE0B2',
  ]

  const {
    setIsEventScoped,
    page,
    selectedTags,
    filterByData,
    filterByOrder,
    matchAllTags,
    matchAllTrackingLinks,
    genderFilter,
    currentCheckoutField,
  } = useAttendeesCrmContext()
  const {domain} = useResourcePageParams()
  const hostTrackingLinks = eventData?.event?.trackingLinks?.filter(tL => tL.hostId?.toString() === hostId) ?? []
  const hostTrackingLinkValues = hostTrackingLinks?.map(tL => tL.value)
  const selectedTrackingLink = trackingLink
    ? [trackingLink]
    : affiliateTrackingLink
      ? [affiliateTrackingLink]
      : hostTrackingLinkValues
  setIsEventScoped(true)
  const {data: eventAttendeesData, isFetching: isCRMLoading} = useFetchEventAttendees({
    eventId: eventId!,
    page,
    domain: (domain ?? '') as RoleKey,
    currentCheckoutField,
    trackingLink: currentUser?.trackingLink,
    selectedTrackingLinks: selectedTrackingLink,
    selectedTags: selectedTags,
    filterByData: filterByData,
    searchQuery: '',
    filterByOrder: filterByOrder,
    matchAllTags: matchAllTags,
    matchAllTrackingLinks: matchAllTrackingLinks,
    genderFilter: genderFilter,
  })

  const [tagModalIsOpen, setTagModalIsOpen] = useState(false)

  const attendees = eventAttendeesData?.attendees
  const eventHasCustomCheckoutFields = eventAttendeesData?.eventHasCustomCheckoutFields
  const checkoutFieldNamesAndIds = eventAttendeesData?.checkoutFieldNamesAndIds
  const hasMore = eventAttendeesData?.hasMore ?? false

  if (isLoading || tLisLoading || aisLoading) {
    return <SpinLoader />
  } else {
    const ticketsSoldSum = Object.keys(breakdown!.ticketTierBreakdown)
      .map(t => breakdown!.ticketTierBreakdown[t].quantity)
      .reduce((a, b) => a + b, 0)

    const genderCount = {
      female: 0,
      male: 0,
      undetected: 0,
    }
    attendees?.forEach(a => {
      if (a.detectedGender) {
        if (a.detectedGender === 'female') genderCount.female++
        else if (a.detectedGender === 'male') genderCount.male++
        else genderCount.undetected++
      } else genderCount.undetected++
    })

    return (
      <div className='eventSection'>
        <div className='breakdownHeader'>
          <div className='breakdownHeader-avi'>
            {breakdown?.avi ? (
              <PoshImage className='avi' src={breakdown?.avi} />
            ) : (
              <PoshImage className='icon' src='https://images.posh.vip/b2/trackingLink.svg' />
            )}
          </div>
          <div className='breakdownHeader-info'>
            <h3>{breakdown?.fullName ? `${breakdown?.fullName}` : trackingLink}</h3>
            <AnalyticsGrid
              currencySymbol={'$'}
              stats={[
                {name: 'Page Visits', value: breakdown!.clicks},
                {name: 'Tickets Sold', value: ticketsSoldSum},
                {name: 'Revenue Generated', value: breakdown?.total, isCurrency: true},
              ]}
              isNotAbbreviated
              isAffiliate={affiliateTrackingLink ? true : false}
            />
          </div>
        </div>

        <div className='breakdownGrid'>
          <div className='breakdownGrid-tables'>
            {!isEmpty(breakdown?.ticketTierBreakdown) && (
              <>
                <div className='eventSection-header'>
                  <h3>Ticket Sales</h3>
                </div>
                <table className='TicketTable breakdown'>
                  <tbody>
                    <tr>
                      <th>Ticket Type</th>
                      <th>Tickets Sold</th>
                      <th>Revenue Generated</th>
                    </tr>
                    {Object.keys(breakdown!.ticketTierBreakdown).map(ticketTier => {
                      const ticketTierBreakdown = breakdown!.ticketTierBreakdown[ticketTier]
                      return (
                        <tr key={ticketTier}>
                          <td>
                            <div className='TicketTable-IconTd'>
                              <PoshImage src='https://images.posh.vip/b2/coupon.svg' width={'22px'} />
                              {ticketTier}
                            </div>
                          </td>
                          <td>{ticketTierBreakdown.quantity}</td>
                          <td>${ticketTierBreakdown.total.toFixed(2)}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </>
            )}

            {!isEmpty(breakdown?.tableNameBreakdown) && (
              <>
                <div className='eventSection-header'>
                  <h3>Table Sales</h3>
                </div>
                <table className='TicketTable breakdown'>
                  <tbody>
                    <tr>
                      <th>Table</th>
                      <th>Tables Sold</th>
                    </tr>
                    {Object.keys(breakdown!.tableNameBreakdown).map(tableName => {
                      const tableNameBreakdown = breakdown!.tableNameBreakdown[tableName]
                      return (
                        <tr key={tableName}>
                          <td>
                            <div className='TicketTable-IconTd'>
                              <PoshImage src='https://images.posh.vip/b2/table.svg' width={'22px'} />
                              {tableName}
                            </div>
                          </td>
                          <td>{tableNameBreakdown.quantity}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </>
            )}

            {!isEmpty(breakdown?.promoCodeFrequencyBreakdown) && (
              <>
                <div className='eventSection-header'>
                  <h3>Promo Code Uses</h3>
                </div>
                <table className='TicketTable breakdown'>
                  <tbody>
                    <tr>
                      <th>Promo Code</th>
                      <th>Uses</th>
                    </tr>
                    {Object.keys(breakdown!.promoCodeFrequencyBreakdown).map(promoCode => {
                      const tableNameBreakdown = breakdown!.promoCodeFrequencyBreakdown[promoCode]
                      return (
                        <tr key={promoCode}>
                          <td>
                            <div className='TicketTable-IconTd'>
                              <PoshImage src='https://images.posh.vip/b2/tag.svg' width={'22px'} />
                              {promoCode}
                            </div>
                          </td>
                          <td>{tableNameBreakdown.quantity}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </>
            )}

            {!isCRMLoading && attendees && !isEmpty(attendees) && (
              <>
                <div className='eventSection-header'>
                  <h3>Attendees</h3>
                </div>
                <AttendeesTable
                  attendeeRows={attendees ?? []}
                  isHost={false}
                  eventHasCustomCheckoutFields={eventHasCustomCheckoutFields}
                  checkoutFieldNamesAndIds={checkoutFieldNamesAndIds}
                  hasMoreAttendees={hasMore}
                  isFetching={isCRMLoading}
                />
              </>
            )}
          </div>

          <div className='breakdownGrid-charts'>
            <AnalyticsCard
              title={'Sales By Ticket Type'}
              loading={!breakdown}
              isEmpty={isEmpty(breakdown?.ticketTierBreakdown)}>
              <DoughnutGraph
                bgColors={TICKET_TYPE_RATIO_BG_COLORS}
                canvasStyle={{height: '350px', width: '350px'}}
                id={'ticket-types'}
                labels={Object.keys(breakdown!.ticketTierBreakdown)}
                data={Object.keys(breakdown!.ticketTierBreakdown).map(t => breakdown!.ticketTierBreakdown[t].quantity)}
              />
            </AnalyticsCard>
            <AnalyticsCard title={'Gender Ratio'} isEmpty={Math.max(...Object.values(genderCount)) === 0}>
              <DoughnutGraph
                bgColors={['#ff00fb', 'blue', 'gray']}
                canvasStyle={{height: '350px', width: '350px'}}
                id={'ticket-types'}
                labels={['Female', 'Male', 'Undetected']}
                data={Object.values(genderCount)}
              />
            </AnalyticsCard>
          </div>
        </div>
        <TagsModal isOpen={tagModalIsOpen} onClose={() => setTagModalIsOpen(false)} />
      </div>
    )
  }
}

export default function HostBreakdownHandler() {
  return (
    <AttendeesCrmContextProvider>
      <HostBreakdown />
    </AttendeesCrmContextProvider>
  )
}
