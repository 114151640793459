import React from 'react'
import {Control, useController} from 'react-hook-form'

import {useGetImageColors} from 'apis/Util/useGetImageColors'
import ColorPicker from 'components/form/ColorPicker'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import EditEventFont from 'pages/EventManagementPages/Settings/EditEventFont'

import PoshStyledModal, {PoshStyledModalProps} from '../../../modals/Modal/PoshStyledModal'
import {EventVisualsSwitch} from '../Form/switch/Switch'
import {EventVisualsSection} from '../Page/Section'

import styles from './styles.module.scss'

interface EventVisualsDesignModalProps extends PoshStyledModalProps {
  control: Control<EventVisualsForm>
}

export const EventVisualsDesignModal = (props: EventVisualsDesignModalProps) => {
  const {control} = props

  const {
    field: {onChange: setLightMode, value: lightmode},
  } = useController({control, name: 'lightmode'})
  const {
    field: {value: eventTitleFont, onChange: setEventTitleFont},
  } = useController({control, name: 'eventTitleFont'})
  const {
    field: {onChange: setAccentColor, value: accentColor},
  } = useController({
    control,
    name: 'accentColor',
  })
  const {
    field: {value: flyer},
  } = useController({
    control,
    name: 'flyer',
  })
  const {data: imageColors} = useGetImageColors({imageUrl: flyer!}, {enabled: !!flyer})

  return (
    <PoshStyledModal {...props} noborder>
      <EventVisualsSection headerText='Event Page Design' colorOverride='white'>
        <div className={styles.EventVisualsDesignModal}>
          <div className={styles.row}>
            <EditEventFont
              eventTitleFont={eventTitleFont}
              setEventTitleFont={font => setEventTitleFont(font)}
              label='Event Title Font'
            />
          </div>
          <div className={styles.row}>
            <ColorPicker
              onChange={e => setAccentColor(e.target.value)}
              title={'Accent Color'}
              value={accentColor}
              isLightMode={lightmode}
              style={{
                alignItems: 'center',
              }}
            />
            <EventVisualsSwitch
              title='Light Mode'
              switchOptions={{
                checked: lightmode ?? false,
                onChange: setLightMode,
              }}
              // accentColor={accentColor || DEFAULT_ACCENT_COLOR}
              lightMode={lightmode ?? false}
            />
          </div>
          {imageColors && (
            <>
              <p className='text-sm font-bold'>Based on your flyer:</p>
              <div className={styles.row}>
                {Object.entries(imageColors).map(([key, value]) => (
                  <div
                    key={key}
                    className={styles.colorSwatch}
                    style={{backgroundColor: value}}
                    onClick={() => setAccentColor(value)}
                    data-is-selected={accentColor === value}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </EventVisualsSection>
    </PoshStyledModal>
  )
}
