import {Ticket} from 'apis/Events/types'

/**
 * This method is used to calculate the absolute maximum number of tickets that can be purchased for a specific ticket type.
 * It will check the quantity of tickets available, purchase limit, and if the ticket is being sold in multiples to ensure that
 * the maximum respects all the various configuration states.
 * @param ticket The ticket type definition
 * @returns numeric maximum
 */
export const calculateMaximumPurchasableTickets = (ticket: Ticket): number => {
  let maximumTickets =
    ticket.purchaseLimit && ticket.purchaseLimit < ticket.quantityAvailable
      ? ticket.purchaseLimit
      : ticket.quantityAvailable
  if (ticket.sellInMultiples && maximumTickets % (ticket.purchaseMin ?? 1) !== 0) {
    maximumTickets -= maximumTickets % ticket.purchaseMin!
  }
  return maximumTickets
}

/**
 * This method is used to check if more tickets can be purchased for a specific ticket type. It takes purchase minimum,
 * purchase maximum, quantity, and selling in multiples into account.
 * @param ticket The ticket type definition
 * @param amountCurrentlyInCart Optional numeric amount of this ticket type already in the cart, defaults to zero.
 * @returns boolean value
 */
export const canPurchaseMoreTickets = (ticket: Ticket, amountCurrentlyInCart?: number): boolean => {
  const maximumPurchasableTickets = calculateMaximumPurchasableTickets(ticket)
  return (
    (amountCurrentlyInCart ?? 0) + (ticket.sellInMultiples ? (ticket.purchaseMin ?? 1) : 1) <= maximumPurchasableTickets
  )
}
