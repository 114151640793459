import React, {PropsWithChildren, useState} from 'react'

import {OrderTag} from 'apis/Orders'
import {searchIconUrl} from 'assets/images/s3images'
import Button from 'components/form/Button'
import {PoshImage} from 'components/PoshImage/PoshImage'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import TextIconInput from 'components/TextInput'

import {Card} from '../Shared/Card/Card'
import {Text} from '../Shared/Text/Text'
import {OrderFilterModal} from './OrderFilterModal/OrderFilterModal'

import styles from './OrdersSection.module.scss'

interface OrdersSearchFilterContainerProps {
  search: string | undefined
  setSearch: (search: string) => void
  filters: OrderTag[]
  setFilters: (filters: OrderTag[]) => void
  navigateToOrdersPage: () => void
  isRSVPEvent?: boolean
}

export const OrdersSearchFilterContainer = (props: PropsWithChildren<OrdersSearchFilterContainerProps>) => {
  const {search, setSearch, filters, setFilters, isRSVPEvent, children, navigateToOrdersPage} = props

  const [isOrderFilterModalOpen, setIsOrderFilterModalOpen] = useState(false)

  const OrdersOrRSVPs = isRSVPEvent ? 'RSVPs' : 'Orders'

  return (
    <div className={styles.OrderSection}>
      <div className={styles.OrderSectionHeader}>
        <Text size='large' bold>
          {`Recent ${OrdersOrRSVPs}`}
        </Text>
        <Button onClick={navigateToOrdersPage}>View All {OrdersOrRSVPs}</Button>
      </div>
      <Card>
        <div className={styles.OrderSectionContent}>
          <div className={styles.OrderSearchFilterSection}>
            <TextIconInput
              placeholder={`Search ${OrdersOrRSVPs}`}
              value={search ?? ''}
              onChange={e => setSearch(e.target.value)}
              icon={searchIconUrl}
            />
            <RequirePermissions requiredPermissions={[requirePermissionAndScope('filter_orders', ['group'])]}>
              <Button onClick={() => setIsOrderFilterModalOpen(true)} className='dark'>
                <PoshImage src='https://images.posh.vip/b2/filter.svg' />
                Filter
              </Button>
            </RequirePermissions>
          </div>
          {children}
        </div>
      </Card>
      {isOrderFilterModalOpen && (
        <OrderFilterModal
          isOpen={isOrderFilterModalOpen}
          onClose={() => setIsOrderFilterModalOpen(false)}
          filters={filters}
          onChangeFilters={setFilters}
          isRSVPEvent={isRSVPEvent}
        />
      )}
    </div>
  )
}
