import React, {ReactNode} from 'react'

import classNames from 'classnames'
import {capitalize} from 'lodash'

import './SMSStatus.styles.scss'

type SMSStatusProps = {
  status: 'sending' | 'delivered' | 'failed' | 'opted out'
  size: 'sm' | 'md' | 'lg'
  children: ReactNode
  percentage: number
  animate?: boolean
  id?: string
}
export const MessageStatusColorMap: Record<SMSStatusProps['status'], string> = {
  sending: '#0CF',
  delivered: '#05FF00',
  failed: '#F44',
  'opted out': '#D7D7D7',
}
const SizeMap: Record<SMSStatusProps['size'], number> = {
  sm: 84,
  md: 126,
  lg: 213,
}
// These colors come from the figma svg's
const MatrixColorValuesMap: Record<SMSStatusProps['status'], string> = {
  sending: '0 0 0 0 0 0 0 0 0 0.8 0 0 0 0 1 0 0 0 1 0',
  delivered: '0 0 0 0 0.02 0 0 0 0 1 0 0 0 0 0 0 0 0 1 0',
  failed: '0 0 0 0 1 0 0 0 0 0.266667 0 0 0 0 0.266667 0 0 0 1 0',
  'opted out': '0 0 0 0 0 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0',
}

const VIEW_BOX = 213
const CENTER = VIEW_BOX / 2
const STROKE_WIDTH = 5
const RADIUS = 84
const CIRCUMFERENCE = RADIUS * Math.PI * 2
const SMSStatusCircle = ({children, status, size, percentage, animate, id}: SMSStatusProps) => {
  const color = MessageStatusColorMap[status]
  const dash = percentage * CIRCUMFERENCE
  const textStyling = {
    fill: color,
    fontFamily: 'Inter',
    fontWeight: '900',
    x: '50%',
    dy: '20px',
    textAnchor: 'middle',
  }

  return (
    <svg
      className={classNames({SMSStatus_animate: animate})}
      width={SizeMap[size]}
      height={SizeMap[size]}
      viewBox={`0 0 213 213`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      id={id}>
      <g clipPath='url(#clip0_6_605)'>
        <path
          d='M103.738 3.44457C103.788 5.35824 103.887 12.0478 103.937 18.3379L104.054 29.7867H105.452H106.866L106.766 28.3223C106.716 27.5236 106.633 22.0488 106.583 16.1414C106.533 10.2339 106.45 4.1934 106.4 2.69574L106.284 -3.8147e-05H104.952H103.621L103.738 3.44457Z'
          fill='#909193'
        />
        <path
          d='M150.714 24.9276C147.086 31.4841 143.842 37.3249 143.525 37.8907L142.96 38.9224L144.108 39.588L145.256 40.2537L145.572 39.7212C145.872 39.2386 159.617 14.5273 159.717 14.3109C159.75 14.2277 157.57 12.9797 157.404 12.9797C157.37 12.9797 154.358 18.3546 150.714 24.9276Z'
          fill='#909193'
        />
        <path
          d='M51.8355 15.143C51.2697 15.4924 50.8537 15.8752 50.887 15.9917C50.9702 16.3577 66.1132 41.1024 66.2463 41.1024C66.446 41.1024 68.3929 39.9042 68.3929 39.7877C68.3929 39.6047 53.0836 14.5606 52.9504 14.5273C52.8839 14.5106 52.3847 14.7935 51.8355 15.143Z'
          fill='#909193'
        />
        <path
          d='M113.589 15.509C113.439 16.1414 112.008 29.6869 112.058 29.7534C112.174 29.8699 114.521 30.1362 114.604 30.053C114.654 30.003 115.02 26.9412 115.419 23.2636C115.819 19.5694 116.168 16.3244 116.218 16.0249C116.301 15.4258 116.384 15.4758 114.454 15.2262C113.722 15.1263 113.655 15.1596 113.589 15.509Z'
          fill='#909193'
        />
        <path
          d='M95.1511 15.925C94.7018 15.975 94.5188 16.0748 94.5188 16.2745C94.5188 16.7238 96.3659 30.3525 96.4325 30.4357C96.5157 30.5022 98.8454 30.1694 98.9452 30.0696C98.9951 30.0197 98.6124 26.8579 98.0965 23.0139C97.5973 19.1866 97.1813 15.9916 97.1813 15.925C97.1813 15.8086 96.2993 15.8086 95.1511 15.925Z'
          fill='#909193'
        />
        <path
          d='M123.074 16.9567C122.841 17.6723 120.012 30.7019 120.062 30.7518C120.129 30.835 122.542 31.4507 122.575 31.4008C122.691 31.2843 125.653 17.5891 125.604 17.4393C125.57 17.3395 125.004 17.1232 124.339 16.9734C123.257 16.7071 123.141 16.7071 123.074 16.9567Z'
          fill='#909193'
        />
        <path
          d='M85.9987 17.4227C85.4662 17.5559 85.0336 17.7389 85.0336 17.8055C85.0336 18.0551 88.1953 31.6838 88.2785 31.7503C88.3617 31.8335 90.4418 31.4341 90.6747 31.2844C90.7746 31.2345 90.1922 28.3723 89.227 24.1622C87.4132 16.2579 87.746 16.9568 85.9987 17.4227Z'
          fill='#909193'
        />
        <path
          d='M132.376 18.9869C132.127 19.6859 128.149 32.2662 128.1 32.5324C128.066 32.782 128.266 32.9151 129.281 33.2313C129.963 33.4476 130.529 33.5974 130.546 33.5808C130.646 33.4476 134.989 19.6193 134.939 19.5694C134.906 19.5527 134.423 19.403 133.874 19.2366C133.325 19.0701 132.776 18.8871 132.676 18.8538C132.559 18.8039 132.426 18.8705 132.376 18.9869Z'
          fill='#909193'
        />
        <path
          d='M77.0128 20.0852C76.3804 20.3182 75.8812 20.5678 75.8812 20.6344C75.8812 20.7675 80.707 33.9968 80.7736 34.08C80.8401 34.1299 83.0533 33.3145 83.1698 33.1981C83.2697 33.0982 78.3607 19.636 78.2276 19.6526C78.1776 19.6526 77.6285 19.8523 77.0128 20.0852Z'
          fill='#909193'
        />
        <path
          d='M138.483 28.7384L135.638 35.3613L136.819 35.9105C137.468 36.21 138.051 36.3931 138.117 36.3265C138.2 36.2267 143.376 24.2454 143.725 23.3468C143.775 23.2137 143.442 22.9641 142.693 22.6313C142.078 22.365 141.528 22.1321 141.462 22.1321C141.395 22.1321 140.047 25.1107 138.483 28.7384Z'
          fill='#909193'
        />
        <path
          d='M68.2265 23.6629C67.6275 23.9292 67.1283 24.1788 67.095 24.2121C67.0617 24.2454 73.1855 37.1252 73.2853 37.225C73.3019 37.2583 73.8677 37.0087 74.5167 36.6925L75.7148 36.1268L74.8162 34.2464C72.0539 28.3889 69.4912 23.1304 69.408 23.1471C69.3581 23.1471 68.8256 23.38 68.2265 23.6629Z'
          fill='#909193'
        />
        <path
          d='M153.976 37.1418L149.799 42.9993L150.781 43.7149C151.313 44.1142 151.829 44.4304 151.912 44.4304C151.979 44.4304 153.926 41.8178 156.222 38.6062L160.399 32.7986L159.334 32.0332C158.752 31.6171 158.252 31.2843 158.219 31.2843C158.186 31.301 156.289 33.9302 153.976 37.1418Z'
          fill='#909193'
        />
        <path
          d='M51.7524 33.4643C51.2531 33.8304 50.8538 34.2298 50.8538 34.3629C50.8704 34.5792 59.3571 45.7617 59.5069 45.7617C59.6234 45.7617 61.5703 44.2641 61.5703 44.1809C61.5703 44.0644 52.9006 32.8652 52.7841 32.8153C52.7009 32.7987 52.2516 33.0816 51.7524 33.4643Z'
          fill='#909193'
        />
        <path
          d='M160.948 42.4669L156.255 47.8418L157.204 48.7238C157.72 49.2063 158.202 49.5724 158.252 49.5558C158.319 49.5225 160.482 47.093 163.062 44.1309L167.738 38.7727L167.055 38.157C166.24 37.4248 165.857 37.1252 165.724 37.1086C165.674 37.1086 163.527 39.5215 160.948 42.4669Z'
          fill='#909193'
        />
        <path
          d='M44.2808 39.6712L43.3988 40.5532L48.4576 45.8116L53.4997 51.0701L54.4648 50.1216L55.4133 49.1897L54.1986 47.925C53.5329 47.2427 51.2698 44.8964 49.1897 42.7165C47.093 40.5532 45.3291 38.7726 45.2792 38.7726C45.2126 38.7726 44.7633 39.172 44.2808 39.6712Z'
          fill='#909193'
        />
        <path
          d='M167.405 48.4242C164.526 51.0701 162.146 53.3332 162.113 53.4497C162.096 53.5662 162.462 54.0488 162.945 54.5313L163.827 55.38L169.069 50.5542C171.948 47.9084 174.311 45.6785 174.311 45.612C174.311 45.5454 173.944 45.0628 173.479 44.547L172.646 43.6151L167.405 48.4242Z'
          fill='#909193'
        />
        <path
          d='M37.7576 46.444C37.2583 47.0264 36.9588 47.509 37.042 47.5922C37.4913 48.0248 47.8584 56.7445 47.925 56.7445C48.0415 56.7445 49.639 54.7476 49.5724 54.6811C49.4892 54.6145 43.5818 49.6223 40.7362 47.2094L38.6395 45.4289L37.7576 46.444Z'
          fill='#909193'
        />
        <path
          d='M175.392 53.3665C173.612 54.6977 171.032 56.5948 169.684 57.6098L167.222 59.4237L167.738 60.1226C168.02 60.5053 168.37 60.9879 168.536 61.1876L168.819 61.5703L174.56 57.327C177.722 54.9973 180.318 53.017 180.351 52.9172C180.434 52.7009 179.053 50.9203 178.82 50.937C178.72 50.937 177.173 52.0352 175.392 53.3665Z'
          fill='#909193'
        />
        <path
          d='M184.711 58.5084C177.805 62.5688 172.097 65.9634 172.014 66.0466C171.898 66.1631 172.647 67.6941 173.162 68.3597C173.212 68.4429 198.19 53.7825 198.506 53.483C198.689 53.3166 198.623 53.1002 198.09 52.185C197.724 51.5859 197.408 51.0867 197.358 51.1034C197.308 51.1034 191.617 54.4315 184.711 58.5084Z'
          fill='#909193'
        />
        <path
          d='M31.8169 54.0321C31.4175 54.6312 31.1513 55.1803 31.2345 55.2469C31.4175 55.4299 43.0992 63.068 43.1658 63.068C43.2657 63.068 44.6302 60.8881 44.5636 60.8381C44.5303 60.8049 33.897 53.8324 32.8653 53.1502C32.5657 52.9505 32.4825 53.0171 31.8169 54.0321Z'
          fill='#909193'
        />
        <path
          d='M13.5788 54.3483C13.2293 54.9473 13.013 55.5131 13.0962 55.5797C13.2792 55.7627 38.6229 70.057 38.756 70.057C38.8059 70.057 39.1388 69.5411 39.4716 68.9254L40.0873 67.7939L27.274 60.5552C20.2184 56.5781 14.4108 53.3165 14.3442 53.2833C14.2777 53.2666 13.9282 53.7492 13.5788 54.3483Z'
          fill='#909193'
        />
        <path
          d='M182.88 69.8906C179.636 71.4382 176.707 72.836 176.357 73.0024L175.758 73.3019L176.357 74.4834L176.973 75.6649L183.446 72.6197C187.007 70.939 189.969 69.5245 190.003 69.4746C190.119 69.3748 189.021 67.0617 188.888 67.0784C188.838 67.0784 186.125 68.3597 182.88 69.8906Z'
          fill='#909193'
        />
        <path
          d='M22.8642 69.9738C22.7477 70.29 22.5147 70.8225 22.3816 71.1719C22.2485 71.5047 22.1486 71.7876 22.1653 71.8043C22.1985 71.8209 24.6114 72.836 27.5402 74.0507C30.4689 75.2655 33.431 76.5135 34.1299 76.8131L35.4112 77.3456L35.9271 76.1641C36.21 75.5151 36.393 74.9327 36.3264 74.8661C36.16 74.7164 23.4799 69.3914 23.2802 69.3914C23.1803 69.3914 22.9973 69.6576 22.8642 69.9738Z'
          fill='#909193'
        />
        <path
          d='M185.593 78.2608C182.015 79.4756 179.02 80.5073 178.97 80.5739C178.87 80.6571 179.469 82.8037 179.652 83.02C179.769 83.1532 193.364 78.4938 193.364 78.3107C193.364 78.1942 193.214 77.6451 193.015 77.0793C192.765 76.3471 192.582 76.0476 192.399 76.0642C192.232 76.0642 189.187 77.0627 185.593 78.2608Z'
          fill='#909193'
        />
        <path
          d='M19.3198 79.459C19.1035 80.1579 18.9704 80.757 18.987 80.7902C19.1534 80.9234 32.5158 84.8672 32.6656 84.8173C32.832 84.7674 33.664 82.3711 33.5143 82.3711C33.4477 82.3711 20.1352 78.3607 19.8689 78.2609C19.7691 78.2276 19.5361 78.7435 19.3198 79.459Z'
          fill='#909193'
        />
        <path
          d='M188.039 86.7142C184.428 87.5296 181.383 88.2285 181.283 88.2618C181.15 88.3117 181.167 88.6612 181.366 89.5099C181.516 90.1422 181.682 90.708 181.732 90.7579C181.832 90.8744 195.544 87.8791 195.727 87.7127C195.794 87.6628 195.727 87.0637 195.577 86.3981C195.344 85.3664 195.261 85.2 194.962 85.2166C194.762 85.2166 191.65 85.8989 188.039 86.7142Z'
          fill='#909193'
        />
        <path
          d='M17.223 87.5962C17.0733 88.0455 16.6739 90.0258 16.7404 90.0258C16.7571 90.0258 17.8221 90.2421 19.0868 90.525C25.9094 91.9894 30.8351 93.0211 30.8683 92.9878C30.9183 92.9046 31.4341 90.4418 31.4008 90.4251C31.3676 90.3919 29.0212 89.8926 19.8356 87.9457C18.0551 87.563 17.2563 87.4631 17.223 87.5962Z'
          fill='#909193'
        />
        <path
          d='M189.553 95.3674C185.859 95.8167 182.814 96.1994 182.781 96.2327C182.681 96.3159 182.914 98.4626 183.03 98.6622C183.113 98.7787 185.377 98.579 190.202 97.9966C194.08 97.5473 197.275 97.148 197.291 97.1313C197.308 97.1147 197.241 96.5156 197.141 95.8001C197.008 94.6519 196.958 94.5187 196.626 94.5354C196.443 94.552 193.248 94.9181 189.553 95.3674Z'
          fill='#909193'
        />
        <path
          d='M15.7253 97.0814C15.6921 97.1813 15.6255 97.7637 15.5756 98.396C15.4924 99.228 15.5257 99.5276 15.6754 99.5775C15.7919 99.6108 17.5392 99.7938 19.5527 99.9935C21.5662 100.193 24.6946 100.509 26.5085 100.709C28.3223 100.892 29.82 101.025 29.8532 100.992C29.8865 100.959 29.9697 100.36 30.0363 99.6607L30.1361 98.396L28.2557 98.1963C19.12 97.2145 15.7753 96.915 15.7253 97.0814Z'
          fill='#909193'
        />
        <path
          d='M195.111 104.021C190.169 104.07 185.51 104.137 184.761 104.154L183.38 104.17V105.502V106.849L191.999 106.75C196.725 106.7 203.398 106.616 206.81 106.567L213 106.467V105.152V103.837L208.557 103.871C206.094 103.887 200.054 103.954 195.111 104.021Z'
          fill='#909193'
        />
        <path
          d='M14.5106 106.45L0 106.5V107.831V109.162H14.8102H29.6203V107.748C29.6203 106.5 29.587 106.334 29.3374 106.367C29.171 106.4 22.4981 106.433 14.5106 106.45Z'
          fill='#909193'
        />
        <path
          d='M183.13 112.324C183.113 112.374 183.063 112.973 183.03 113.655L182.964 114.904L189.62 115.519C193.281 115.852 196.509 116.135 196.809 116.135L197.324 116.152L197.441 114.904C197.508 114.205 197.541 113.622 197.508 113.589C197.391 113.472 183.197 112.224 183.13 112.324Z'
          fill='#909193'
        />
        <path
          d='M22.7976 115.07L15.7253 115.902L15.7919 116.568C15.8917 117.915 16.0582 118.564 16.3078 118.531C16.4409 118.514 19.6525 118.148 23.43 117.699L30.3025 116.9L30.186 115.819C30.1195 115.22 30.0363 114.621 29.9697 114.487C29.8865 114.271 28.9713 114.338 22.7976 115.07Z'
          fill='#909193'
        />
        <path
          d='M181.849 121.56C181.599 122.791 181.599 122.808 181.949 122.891C182.498 123.024 195.594 125.637 195.694 125.637C195.81 125.637 196.276 123.157 196.176 123.074C196.093 122.991 182.697 120.312 182.365 120.312C182.165 120.312 182.032 120.628 181.849 121.56Z'
          fill='#909193'
        />
        <path
          d='M24.312 123.923C20.5179 124.771 17.3895 125.504 17.3396 125.554C17.2897 125.603 17.3729 126.203 17.5393 126.901C17.7889 127.916 17.8887 128.133 18.1051 128.066C18.2715 128.016 21.2668 127.334 24.7946 126.552C28.3224 125.77 31.3343 125.088 31.5008 125.038C31.767 124.954 31.7836 124.838 31.7004 124.256C31.6339 123.873 31.4841 123.307 31.3843 122.974L31.2012 122.375L24.312 123.923Z'
          fill='#909193'
        />
        <path
          d='M179.902 128.981C179.719 129.564 179.552 130.146 179.552 130.296C179.552 130.479 180.151 130.745 181.766 131.245C190.785 134.057 193.248 134.806 193.331 134.722C193.381 134.656 193.58 134.09 193.797 133.458L194.163 132.293L191.8 131.561C190.519 131.145 187.473 130.196 185.044 129.447C182.614 128.699 180.551 128.05 180.451 128.016C180.334 127.983 180.102 128.382 179.902 128.981Z'
          fill='#909193'
        />
        <path
          d='M26.4585 132.243C22.7477 133.491 19.7024 134.523 19.6858 134.539C19.6525 134.556 19.8356 135.138 20.0685 135.821L20.4846 137.085L27.2573 134.839C30.9848 133.591 34.0633 132.559 34.0966 132.526C34.1964 132.426 33.4143 129.963 33.2978 129.98C33.2479 129.98 30.1694 131.012 26.4585 132.243Z'
          fill='#909193'
        />
        <path
          d='M177.09 136.852L176.59 138.101L177.772 138.6C178.437 138.866 180.95 139.914 183.38 140.913C187.723 142.71 189.903 143.609 189.953 143.609C190.103 143.609 190.868 141.229 190.768 141.129C190.652 141.029 179.17 136.204 177.822 135.688C177.672 135.638 177.439 136.004 177.09 136.852Z'
          fill='#909193'
        />
        <path
          d='M31.0348 140.048C28.2059 141.379 25.2605 142.743 24.495 143.109L23.1305 143.758L23.663 144.89C23.9625 145.506 24.2288 146.055 24.2454 146.088C24.2953 146.155 36.9755 140.297 37.1752 140.097C37.3083 139.981 36.6094 138.15 36.2766 137.768C36.2267 137.701 33.8803 138.733 31.0348 140.048Z'
          fill='#909193'
        />
        <path
          d='M173.528 144.091L172.896 145.256L174.36 146.071C180.151 149.366 198.739 159.75 198.772 159.717C199.172 159.201 200.004 157.504 199.904 157.404C199.737 157.254 174.743 143.159 174.394 143.043C174.211 142.976 173.961 143.292 173.528 144.091Z'
          fill='#909193'
        />
        <path
          d='M27.041 152.112C20.1851 156.222 14.494 159.6 14.4108 159.634C14.2444 159.683 15.4758 161.913 15.6921 161.913C15.8086 161.913 40.7363 147.17 40.9692 146.953C41.0857 146.837 39.821 144.59 39.638 144.624C39.5714 144.64 33.9136 148.002 27.041 152.112Z'
          fill='#909193'
        />
        <path
          d='M169.085 150.914C168.703 151.463 168.403 151.946 168.403 151.995C168.403 152.029 170.4 153.443 172.863 155.107C175.309 156.771 177.988 158.618 178.82 159.201L180.351 160.249L181.05 159.151C181.666 158.236 181.732 158.036 181.533 157.853C181.183 157.52 170.317 150.115 170.017 149.999C169.834 149.932 169.568 150.215 169.085 150.914Z'
          fill='#909193'
        />
        <path
          d='M38.6062 155.54C35.6276 157.786 33.0649 159.717 32.8818 159.833C32.5823 160.049 32.6156 160.116 33.2979 161.065C33.6972 161.614 34.0966 162.08 34.1798 162.08C34.2963 162.08 45.0961 154.092 45.6618 153.576C45.795 153.46 44.3472 151.413 44.1309 151.446C44.0643 151.446 41.5849 153.293 38.6062 155.54Z'
          fill='#909193'
        />
        <path
          d='M164.276 157.171L163.444 158.119L168.852 162.928L174.277 167.737L175.159 166.789C175.642 166.256 176.041 165.791 176.041 165.741C176.025 165.607 165.508 156.372 165.308 156.305C165.191 156.272 164.742 156.655 164.276 157.171Z'
          fill='#909193'
        />
        <path
          d='M43.9313 162.579C41.1357 165.225 38.8392 167.488 38.806 167.604C38.7893 167.721 39.1554 168.203 39.638 168.686L40.5199 169.535L45.3457 164.975C48.0082 162.462 50.3546 160.216 50.5875 160L50.9869 159.584L50.0883 158.668C49.5891 158.169 49.1398 157.753 49.0899 157.753C49.04 157.753 46.7269 159.933 43.9313 162.579Z'
          fill='#909193'
        />
        <path
          d='M158.502 163.111L157.587 163.993L161.597 168.32C163.81 170.7 166.023 173.079 166.523 173.612L167.438 174.577L168.403 173.662L169.352 172.746L164.542 167.538C161.897 164.676 159.65 162.296 159.567 162.279C159.484 162.246 159.001 162.612 158.502 163.111Z'
          fill='#909193'
        />
        <path
          d='M50.0383 168.786L45.2625 174.144L46.1943 175.009C46.6935 175.492 47.1595 175.891 47.226 175.891C47.3425 175.891 56.628 165.491 56.7112 165.258C56.7278 165.175 56.3118 164.742 55.7793 164.276L54.8142 163.428L50.0383 168.786Z'
          fill='#909193'
        />
        <path
          d='M152.561 167.954C152.029 168.337 151.596 168.719 151.596 168.786C151.596 168.919 157.004 176.307 159.384 179.436L160.099 180.384L160.798 179.885C161.181 179.602 161.664 179.253 161.88 179.086L162.246 178.803L158.003 173.062C155.673 169.901 153.693 167.305 153.626 167.288C153.56 167.272 153.077 167.571 152.561 167.954Z'
          fill='#909193'
        />
        <path
          d='M56.8444 174.144C54.5979 177.289 52.7342 179.952 52.7176 180.052C52.6843 180.151 53.1336 180.567 53.6994 180.983L54.7311 181.732L55.3135 180.933C59.3738 175.259 63.0347 170.001 62.9848 169.901C62.9515 169.851 62.469 169.485 61.9198 169.102L60.938 168.403L56.8444 174.144Z'
          fill='#909193'
        />
        <path
          d='M145.655 172.547C145.006 172.913 144.64 173.229 144.69 173.362C144.807 173.678 159.434 198.223 159.65 198.473C159.8 198.639 160.083 198.523 160.965 198.007C162.046 197.358 162.08 197.308 161.863 196.975C161.747 196.775 158.319 191.051 154.259 184.245C150.215 177.456 146.853 171.898 146.787 171.914C146.737 171.931 146.221 172.197 145.655 172.547Z'
          fill='#909193'
        />
        <path
          d='M67.3279 173.612C66.3628 175.276 53.5828 198.739 53.5828 198.839C53.5828 198.889 54.0986 199.205 54.7143 199.538L55.8625 200.137L62.9182 187.24C66.7954 180.151 69.9904 174.294 70.0071 174.244C70.0403 174.194 69.5245 173.861 68.8921 173.528L67.7439 172.896L67.3279 173.612Z'
          fill='#909193'
        />
        <path
          d='M138.7 176.257C138.051 176.557 137.518 176.807 137.485 176.823C137.452 176.84 143.376 189.77 143.525 190.003C143.559 190.053 145.805 189.071 145.905 188.954C145.972 188.904 140.913 177.822 140.131 176.274L139.864 175.725L138.7 176.257Z'
          fill='#909193'
        />
        <path
          d='M72.1371 183.313C70.6061 186.924 69.3747 189.903 69.408 189.936C69.6909 190.119 71.7543 190.885 71.8209 190.835C71.9207 190.718 77.3789 177.855 77.3789 177.722C77.3789 177.605 75.3154 176.723 75.0658 176.723C74.9993 176.723 73.6846 179.685 72.1371 183.313Z'
          fill='#909193'
        />
        <path
          d='M131.128 179.236C130.629 179.403 130.13 179.586 130.013 179.636C129.88 179.685 130.229 180.917 131.145 183.579C131.894 185.709 132.942 188.771 133.491 190.369C134.04 191.966 134.523 193.314 134.556 193.347C134.589 193.381 135.172 193.198 135.837 192.948L137.085 192.499L136.12 189.736C135.588 188.205 134.523 185.144 133.757 182.93C132.992 180.701 132.31 178.887 132.21 178.903C132.127 178.903 131.627 179.053 131.128 179.236Z'
          fill='#909193'
        />
        <path
          d='M81.9883 181C78.577 192.216 78.2608 193.281 78.4439 193.381C78.8266 193.58 80.707 194.08 80.7902 194.013C80.8235 193.963 81.772 190.951 82.8703 187.324C83.9852 183.679 84.9171 180.601 84.967 180.468C85.0336 180.301 84.7341 180.151 83.902 179.902C83.2697 179.702 82.6706 179.552 82.5874 179.552C82.5042 179.552 82.2213 180.201 81.9883 181Z'
          fill='#909193'
        />
        <path
          d='M123.174 181.499C122.525 181.649 122.142 181.815 122.192 181.949C122.225 182.048 122.958 185.127 123.79 188.788C124.638 192.449 125.354 195.527 125.387 195.644C125.437 195.777 125.77 195.744 126.635 195.527C127.301 195.361 127.867 195.178 127.916 195.128C127.983 195.061 125.254 182.864 124.871 181.499C124.771 181.166 124.588 181.166 123.174 181.499Z'
          fill='#909193'
        />
        <path
          d='M90.0257 181.699C90.0257 181.882 87.6129 194.762 87.4465 195.477C87.3632 195.794 87.4631 195.844 88.6612 196.06C89.9093 196.293 89.9425 196.293 90.059 195.96C90.1755 195.644 92.6882 182.415 92.6882 182.165C92.6882 182.115 92.2556 181.982 91.7397 181.882C91.2072 181.782 90.6082 181.649 90.4085 181.599C90.1755 181.549 90.0257 181.599 90.0257 181.699Z'
          fill='#909193'
        />
        <path
          d='M115.12 182.864C114.687 182.93 114.304 183.014 114.255 183.064C114.155 183.163 115.885 197.141 116.002 197.241C116.035 197.291 116.651 197.241 117.366 197.141L118.648 196.992L118.564 196.459C118.515 196.176 118.148 193.214 117.732 189.87C117.316 186.525 116.95 183.546 116.9 183.247C116.801 182.664 116.717 182.648 115.12 182.864Z'
          fill='#909193'
        />
        <path
          d='M98.1797 183.163C98.1797 183.33 97.8802 186.558 97.5141 190.352C97.148 194.146 96.8651 197.275 96.8984 197.291C96.9151 197.308 97.4975 197.358 98.213 197.424L99.4777 197.541L99.5776 196.908C99.7606 195.71 100.892 183.197 100.826 183.147C100.792 183.113 100.177 183.03 99.4777 182.964C98.2796 182.864 98.1797 182.88 98.1797 183.163Z'
          fill='#909193'
        />
        <path
          d='M106.167 187.174C106.167 189.27 106.217 195.927 106.284 201.984L106.384 213H107.698H108.996V208.707C108.996 206.344 108.946 199.671 108.88 193.897L108.78 183.38H107.465H106.167V187.174Z'
          fill='#909193'
        />
      </g>
      <circle
        fill='none'
        stroke={color}
        cx={CENTER}
        cy={CENTER}
        r={RADIUS}
        strokeWidth={STROKE_WIDTH + 'px'}
        transform={`rotate(-90 ${VIEW_BOX / 2} ${VIEW_BOX / 2})`} //Need this so that the circle "starts" from the top/north
        strokeDasharray={`${dash} ${CIRCUMFERENCE - dash}`} // this creates the dash (filled in portion) and the gap (unfilled portion)
        strokeLinecap='round'
        style={{transition: 'all 0.5s'}}
        filter={`url(#filter${status})`}
      />
      <text fontSize='36px' y='40%' {...textStyling}>
        {children}
      </text>
      <text fontSize='20px' y='55%' {...textStyling}>
        {capitalize(status)}
      </text>
      <defs>
        <clipPath id='clip0_6_605'>
          <rect width='213' height='213' fill='white' />
        </clipPath>
        <filter
          //need to set different id here per status otherwise the first filter declared overrides all the rest
          id={`filter${status}`}
          x='0'
          y='0.5'
          width='195.5'
          height='193.5'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values={MatrixColorValuesMap[status]} />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_6_670' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_6_670' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}

export default SMSStatusCircle
