import React, {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

import {calculateNumberOfOccurences, DUMMY_ATTENDEES, ExperimentKey, FeatureFlag} from '@posh/model-types'
import {DEFAULT_ACCENT_COLOR} from '@posh/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import {useConfirmPendingEvent} from 'apis/PendingEvent/useConfirmPendingEvent'
import {useGetPendingEvent} from 'apis/PendingEvent/useGetPendingEvent'
import {FeatureAnnouncementModal} from 'components/FeatureAnnouncementModal'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'
import {useExperiment} from 'hooks/useExperiment'
import {useToggle} from 'hooks/useToggle'
import {useTrackRudderStackEvent} from 'hooks/useTrackRudderstackEvent'
import {CalendarDays} from 'lucide-react'
import moment from 'moment'
import {ActionRowSaveButtonProps} from 'pages/EventManagementPages/Visuals/Upgrade-dev/components/page-sections/ActionRow'
import {CreateEventActionRow} from 'pages/EventManagementPages/Visuals/Upgrade-dev/components/page-sections/CreateEventActionRow'

import {useCreatePendingEvent} from '../../apis/PendingEvent/useCreatePendingEvent'
import {transformGetPendingEventOutputToUpdatePendingEventInput} from '../../apis/PendingEvent/useUpdatePendingEvent'
import {
  EventVisualsForm,
  getCreateEventMixpanelParams,
  getEventVisualsAttributes,
} from '../../components/PageComponents/EventVisuals/types/eventVisualsForm'
import {EventVisualsContextProvider} from '../EventManagementPages/Visuals/Upgrade-dev/components/context/EventVisualsContext'
import {VisualEditorWrapper} from '../EventManagementPages/Visuals/Upgrade-dev/components/page/VisualEditorWrapper/index'
import {
  determineIsRSVPEvent,
  EventVisualsAdmissionSection,
} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/AdmissionSection/EventVisualsAdmissionSection'
import {EventVisualsBackgroundImage} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/BackgroundImage'
import {EventVisualsCustomSections} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/CustomSections'
import {EventDetailsFormSection} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/EventDetails'
import {EventFlyerColorSongSection} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/EventFlyer/EventFlyerColorSongSection'
import {EventSettingsSection} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/EventSettings'
import {EventVisualsGuestlistSection} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/GuestListSection/EventVisualsGuestlistSection'
import {EventVisuals} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/Layout'
import {useAutosave} from './api/useAutosavePendingEvent'
import {useUpdatePendingEventForCreateEvent} from './api/useUpdatePendingEventForCreateEvent'
import {CreateEventOnboardingOverlay} from './components/OnboardingOverlay'
import {useGetCreateEventProgress} from './components/ProgressBar/useGetProgress'
import {getRandomFlyer} from './hooks/createEventDefaultAttributes'
import {LaunchEventModal} from './LaunchEventModal'
import {useCreateEventParams} from './useCreateEventParams'
import {useNavigateToCreateEvent} from './useNavigateToCreateEvent'

import styles from './styles.module.scss'

const DEFAULT_PENDING_EVENT_ATTRIBUTES = {
  timezone: moment.tz.guess(),
  flyer: getRandomFlyer(),
}

export const CreateEvent = () => {
  const [isShowingAdvancedSettings, toggleAdvancedSettings] = useToggle(false)
  const [isLaunchModalOpen, setIsLaunchModalOpen] = useState(false)

  const showAdvancedSettingsText = isShowingAdvancedSettings ? 'Hide Advanced Settings' : 'Show Advanced Settings'

  const {currentUser} = useSessionContext()
  const {trackEvent} = useMixpanel()
  const {showToast} = useToast()
  const {navigateToCreateEvent} = useNavigateToCreateEvent()
  const navigate = useNavigate()
  const {updatePendingEvent, isUpdatingPendingEvent} = useUpdatePendingEventForCreateEvent()
  const {groupId, pendingEventId} = useCreateEventParams()
  const {trackEventPublish, trackEventSaveDraft} = useTrackRudderStackEvent()
  const {mutate: createPendingEvent, isLoading: isCreatingPendingEvent} = useCreatePendingEvent({
    onSuccess: data => {
      navigateToCreateEvent({pendingEventId: data.pendingEventId, groupId})
    },
    onError: error => {
      navigateToCreateEvent()
      showToast({
        type: 'error',
        title: 'There was an issue creating your event. Please try again.',
        subtitle: error.message,
      })
    },
  })

  const {data: pendingEvent} = useGetPendingEvent(
    {_id: pendingEventId!, groupId},
    {
      enabled: !!pendingEventId,
      onError: error => {
        showToast({
          type: 'error',
          title: 'There was an issue loading your event. Please try again.',
          subtitle: error.message,
        })
        navigateToCreateEvent({groupId})
      },
    },
  )

  const {mutateAsync: confirm} = useConfirmPendingEvent({
    onError: error => {
      showToast({
        title: 'There was an issue creating your event. Please try again.',
        subtitle: error.message,
        type: 'error',
      })
    },
  })

  const {
    control,
    watch,
    getValues: getPendingEventFromForm,
    handleSubmit,
    formState: {dirtyFields, isValid: isFormFilledOut, errors},
    reset,
  } = useForm<EventVisualsForm>({
    defaultValues: transformGetPendingEventOutputToUpdatePendingEventInput(pendingEvent),
    mode: 'onChange',
  })

  const eventAttributes = getEventVisualsAttributes(watch)
  const {flyer, lightmode, accentColor, eventTitleFont, country, tickets, hasFilledNonControlledFields, recurrence} =
    eventAttributes

  const isRSVPEvent = useMemo(() => {
    return determineIsRSVPEvent(tickets)
  }, [tickets])

  const aiFlyerExperiment = useExperiment({enabled: !isRSVPEvent, experimentKey: ExperimentKey.AI_FLYER})

  const confirmPendingEvent = (groupId: string, status: 'live' | 'draft', shouldNavigateToFinanceSettings: boolean) => {
    // Mixpanel create event events
    trackEvent('Create Event - Launch Event Clicked', {
      status,
      redirectedToFinanceSettings: shouldNavigateToFinanceSettings,
    })

    // Confirm pending event
    confirm({_id: pendingEventId!, groupId, status}).then(res => {
      if (status === 'live') {
        trackEventPublish(res._id, {experiment: aiFlyerExperiment})
      } else {
        trackEventSaveDraft(res._id, {experiment: aiFlyerExperiment})
      }

      if (shouldNavigateToFinanceSettings) {
        navigate({pathname: `/owner/groups/${groupId}/finance`})
      } else if (res.collectionName === 'events') {
        // regular, single session event (navigate to the event page)
        navigate({pathname: `/e/${res.url}`})
      } else {
        // multi session event (navigate to the multi-session dashboard)
        navigate({pathname: `/owner/groups/${groupId}/series/${res._id}?from=createEvent`})
      }
    })
  }

  const isDirty = Object.keys(dirtyFields).length > 0

  useEffect(() => {
    const defaultValues = transformGetPendingEventOutputToUpdatePendingEventInput(pendingEvent)
    reset(defaultValues)
  }, [pendingEvent === undefined])

  useAutosave(getPendingEventFromForm(), handleSubmit(updatePendingEvent), isFormFilledOut)

  const numberOfEvents = useMemo(() => {
    if (!recurrence) return 0
    return calculateNumberOfOccurences(recurrence)
  }, [recurrence])

  const isMultiSession = !!recurrence

  const progress = useGetCreateEventProgress(control, isRSVPEvent)

  const guestList = pendingEvent?.guestlistPreview ?? DUMMY_ATTENDEES

  const onPressSave = handleSubmit(data => {
    updatePendingEvent(data)
    setIsLaunchModalOpen(true)
    const createEventMixpanelParams = getCreateEventMixpanelParams(data, !!currentUser)
    trackEvent('Create Event - Create Event Clicked', createEventMixpanelParams)
  })

  const tooltipText = (() => {
    if (!isFormFilledOut) return 'Please fill out the required fields'
    if (!hasFilledNonControlledFields) return 'Please add a flyer'
    if (isUpdatingPendingEvent) return 'Saving...'
  })()

  const actionButton: ActionRowSaveButtonProps = {
    title: isUpdatingPendingEvent ? 'Saving...' : recurrence ? `Create Events (${numberOfEvents})` : 'Create Event',
    isDisabled: !isFormFilledOut || isUpdatingPendingEvent || !hasFilledNonControlledFields,
    isLoading: isCreatingPendingEvent,
    onPress: onPressSave,
    className: 'gold',
    tooltipText,
  }

  return (
    <>
      <EventVisualsContextProvider
        lightmode={lightmode ?? false}
        accentColor={accentColor ?? DEFAULT_ACCENT_COLOR}
        fontFamily={eventTitleFont}>
        {pendingEvent && (
          <VisualEditorWrapper className={styles.CreateEventWrapper}>
            <CreateEventActionRow
              isDirty={isDirty}
              actionButton={actionButton}
              {...progress}
              isLightMode={!!lightmode}
            />
            <EventVisualsBackgroundImage flyer={flyer}>
              <EventVisuals.Content className={styles.content}>
                <EventVisuals.Row>
                  <EventFlyerColorSongSection control={control} />
                  <EventVisuals.Column>
                    <EventDetailsFormSection
                      control={control}
                      showShortDescription={isRSVPEvent}
                      showDescription={!isRSVPEvent}
                      type='create'
                    />
                    {isRSVPEvent && <EventVisualsAdmissionSection control={control} country={country} />}
                  </EventVisuals.Column>
                </EventVisuals.Row>
                {!isRSVPEvent && (
                  <EventVisuals.Row>
                    <EventVisualsAdmissionSection control={control} country={country} />
                  </EventVisuals.Row>
                )}
                {isShowingAdvancedSettings && (
                  <>
                    <EventVisuals.Row>
                      <EventSettingsSection control={control} />
                    </EventVisuals.Row>
                    <EventVisuals.Row>
                      <EventVisualsGuestlistSection.Expanded attendees={guestList} control={control} />
                    </EventVisuals.Row>
                    <EventVisualsCustomSections control={control} watch={watch} showDescription={isRSVPEvent} />
                  </>
                )}
                <h6 onClick={toggleAdvancedSettings} className={`clickable m-0`} style={{color: accentColor}}>
                  {showAdvancedSettingsText}
                </h6>
              </EventVisuals.Content>
            </EventVisualsBackgroundImage>
          </VisualEditorWrapper>
        )}
      </EventVisualsContextProvider>
      <CreateEventOnboardingOverlay
        isSubmitting={isCreatingPendingEvent}
        isOpen={!pendingEventId}
        onCreateEvent={({isPaidEvent, defaultAttributes}, displayFlyerAnalysisToast) => {
          createPendingEvent({
            groupId,
            isPaidEvent,
            defaultAttributes: {
              ...DEFAULT_PENDING_EVENT_ATTRIBUTES,
              ...defaultAttributes,
            },
          })
          if (displayFlyerAnalysisToast) {
            showToast(
              {
                title: 'We did some of the heavy lifting for you',
                subtitle:
                  'We pre-filled some of the event details for you, be sure to double check them before you launch!',
                type: 'info',
              },
              5000,
            )
          }
        }}
      />
      <LaunchEventModal
        control={control}
        isOpen={isLaunchModalOpen}
        onClose={() => setIsLaunchModalOpen(false)}
        onConfirm={confirmPendingEvent}
        isMultiSession={isMultiSession}
      />
      {!!pendingEventId && (
        <FeatureAnnouncementModal
          oneTimeViewKey='event-series-announcement'
          featureGateKey={FeatureFlag.MULTISESSIONEVENTS_ORGANIZER}
          delay={1000}
          config={{
            Icon: () => <CalendarDays width={48} color='white' />,
            featureName: 'Event Series',
            featureDescription:
              'Now you can create and manage recurring events directly in the Posh app—no more manual duplication. Set up your series and simplify ticketing today.',
            helpUrl:
              'https://support.posh.vip/hc/en-us/articles/34946418426779-Event-Series-Streamlining-Your-Recurring-Events',
          }}
        />
      )}
    </>
  )
}
