import React, {useRef, useState} from 'react'

import {MAX_FILE_UPLOAD_SIZE, ThirdPartyImageApi} from '@posh/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {useToast} from 'components/toasts/ToastProvider'
import {ACCEPTED_FILE_TYPES} from 'hooks/uploadImage/useUploadImage'

import Button from '../Button'
import {PictureState} from '../ImagePicker'
import Input from '../Input'
import {ImageApiSelector} from './apiSelector'
import {ImageSelector} from './imageSelector'

import './styles.scss'

interface ImageSearchModalProps extends PoshStyledModalProps {
  setFile: (file: PictureState) => void
}

export const ImageSearchModal = (props: ImageSearchModalProps) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {setFile} = props
  const {showToast} = useToast()
  const [search, setSearch] = useState('')
  const [selectedApi, setSelectedApi] = useState<ThirdPartyImageApi>('tenor')
  const searchType = selectedApi === 'tenor' ? 'gifs' : 'photos'
  const imageRef = useRef<HTMLInputElement>(null)
  const handleOnSubmitImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return null
    const [file] = e.target.files
    if (file.size > MAX_FILE_UPLOAD_SIZE) {
      showToast({type: 'error', title: 'Image too large, must be under 8MB'})
      return null
    }
    const imageUrl = URL.createObjectURL(file)
    setFile({file, url: imageUrl})
    props.onClose()
  }

  const handleOnClickImage = (url: string) => {
    setFile({url, file: null})
    props.onClose()
  }

  const handleButtonClick = () => {
    trackMixpanelEvent('Create Event Upload Custom Flyer-  Stage 4 visuals page')
    if (imageRef.current) imageRef.current.click()
  }

  return (
    <PoshStyledModal {...props} noborder>
      <div className='ImageSearchModal'>
        <div className='ImageSearchModal-header'>
          <div className='ImageSearchModal-header-search'>
            <Input
              className='fullWidth'
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={`Search for ${searchType}...`}
            />
          </div>
          <Button onClick={handleButtonClick} className='small m-0 w-50'>
            Use Your Own
            <input
              className='ImageSearchModal-input'
              id={'Create Event Flyer Picker'}
              type='file'
              accept={ACCEPTED_FILE_TYPES}
              multiple={false}
              onChange={handleOnSubmitImage}
              ref={imageRef}
            />
          </Button>
        </div>
        <ImageApiSelector setSelectedApi={setSelectedApi} selectedApi={selectedApi} />
        <ImageSelector search={search} onClick={img => handleOnClickImage(img)} selectedApi={selectedApi} />
      </div>
    </PoshStyledModal>
  )
}
