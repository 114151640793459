import React from 'react'
import {PropsWithChildren} from 'react'

import {FeatureFlag} from '@posh/model-types'
import {useFeatureGate} from 'hooks/useFeatureGate'

function Organizer({children}: PropsWithChildren) {
  const mse_organizer_ff = useFeatureGate(FeatureFlag.MULTISESSIONEVENTS_ORGANIZER)
  if (!mse_organizer_ff) {
    return <></>
  }
  return children
}

function Public({children}: PropsWithChildren) {
  const mse_public_ff = useFeatureGate(FeatureFlag.MULTISESSIONEVENTS_PUBLIC)
  if (!mse_public_ff) {
    return <></>
  }
  return children
}

export const MultisessionEventFeatureFlag = {
  Organizer,
  Public,
}
