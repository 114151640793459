import React from 'react'

import getEventFlyer from 'apis/Util/getEventFlyer'
import {PoshImage} from 'components/PoshImage/PoshImage'

import {EventsTableListItemProps} from './types'

import '../index.scss'

export function EventsTableListItemImage({item}: EventsTableListItemProps) {
  return (
    <PoshImage
      className='h-100 w-full rounded-md object-cover md:h-[100px] md:w-[75px]'
      src={getEventFlyer(item)}
      transformOptions={{width: 200}}
    />
  )
}
