import React from 'react'

import Button from 'components/form/Button'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'

import './styles.scss'

const LimitModal = ({isOpen, onClose}: {isOpen: boolean; onClose: () => void}) => {
  return (
    <PoshStyledModal isOpen={isOpen} onClose={onClose}>
      <div className='LimitModal'>
        <h3>We’ve Updated SMS Limits — Here’s Why</h3>
        <p>
          To improve engagement and reduce opt-outs, <b>SMS blasts are now limited to 10 per rolling 30-day period.</b>
        </p>
        <p>
          We analyzed our 2024 sales data and found that organizers who send fewer, more concise messages see{' '}
          <b>higher conversions and better ticket sales.</b> The most successful organizers:
        </p>
        <ul style={{listStyleType: 'none', paddingLeft: '0'}}>
          <li>
            ✅ Send <b>10 or fewer</b> text blasts per month
          </li>
          <li>
            ✅ Keep messages under <b>140 characters</b>
          </li>
          <li>✅ Use minimal or no emojis</li>
        </ul>
        <p>
          <b>
            Good news! Per-event SMS blasts are still unlimited, so you can continue engaging attendees without
            restrictions.
          </b>
        </p>
        <p>👉 Need help crafting high-performing SMS messages? Reach out to your Posh Business Partner.</p>
        <div className='LimitModal-buttonContainer'>
          <Button onClick={onClose}>Close</Button>
        </div>
      </div>
    </PoshStyledModal>
  )
}

export default LimitModal
