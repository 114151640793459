import React, {useState} from 'react'

import {FeatureFlag, POSH_MESSAGING_AGREEMENT_STRING} from '@posh/model-types'
import {useFeatureGate} from 'hooks/useFeatureGate'

export interface TOSCheck {
  tos: boolean
  smsOptIn: boolean
}

interface TOSCheckForm {
  setCheckForms: React.Dispatch<React.SetStateAction<TOSCheck>>
  checkForms: TOSCheck
}

/**
 * TODO: remove this once we have a new TOS check form on 100%
 */
export const OldTOSCheckForm = (props: TOSCheckForm) => {
  const {setCheckForms, checkForms} = props
  return (
    <table className='OnboardPage-privacyPolicy'>
      <tr>
        <td>
          <div className='OnboardPage-privacyPolicy-container'>
            <input
              className='Checkbox'
              type='checkbox'
              checked={checkForms.tos}
              onChange={e => {
                setCheckForms({...checkForms, tos: e.target.checked})
              }}
            />
            <span className='checkmark' />
          </div>
        </td>
        <td>
          <p className='OnboardPage-tos'>
            I have read and agree to the <a href='https://posh.vip/tos'>Terms of Service</a> and
            <a href='https://posh.vip/privacy'>&nbsp;Privacy Policy.</a>
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <div className='OnboardPage-privacyPolicy-container'>
            <input
              className='Checkbox'
              type='checkbox'
              checked={checkForms.smsOptIn}
              onChange={e => {
                setCheckForms({...checkForms, smsOptIn: e.target.checked})
              }}
            />
            <span className='checkmark' />
          </div>
        </td>
        <td>
          <p className='OnboardPage-tos'>
            I would like to receive alert text notifications about receipts and other Posh important event-related
            messages at the phone number provided. Message and data rates may apply. Message frequency varies. Text HELP
            for help Text STOP to unsubscribe.
          </p>
        </td>
      </tr>
    </table>
  )
}

interface NewTOS {
  tos: boolean
  marketingSmsOptIn: boolean
  transactionalSmsOptIn: boolean
}

interface NewTOSCheckFormProps {
  newTOS: NewTOS
  setNewTOS: React.Dispatch<React.SetStateAction<NewTOS>>
}

const NewTOSCheckForm = (props: NewTOSCheckFormProps) => {
  const {newTOS, setNewTOS} = props
  return (
    <table className='OnboardPage-privacyPolicy'>
      <tr>
        <td>
          <div className='OnboardPage-privacyPolicy-container'>
            <input
              className='Checkbox'
              type='checkbox'
              checked={newTOS.tos}
              onChange={e => {
                setNewTOS({...newTOS, tos: e.target.checked})
              }}
            />
            <span className='checkmark' />
          </div>
        </td>
        <td>
          <p className='OnboardPage-tos'>
            I have read and agree to the <a href='https://posh.vip/tos'>Terms of Service</a> and
            <a href='https://posh.vip/privacy'>&nbsp;Privacy Policy.</a>
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <div className='OnboardPage-privacyPolicy-container'>
            <input
              className='Checkbox'
              type='checkbox'
              checked={newTOS.marketingSmsOptIn}
              onChange={e => {
                setNewTOS({...newTOS, marketingSmsOptIn: e.target.checked, transactionalSmsOptIn: e.target.checked})
              }}
            />
            <span className='checkmark' />
          </div>
        </td>
        <td>
          <p className='OnboardPage-tos'>By checking this box, {POSH_MESSAGING_AGREEMENT_STRING}</p>
        </td>
      </tr>
    </table>
  )
}

export const useTOSCheckForm = () => {
  const isNewTOSCheckFormEnabled = useFeatureGate(FeatureFlag.SMS_OPT_INS)
  const [oldCheckForms, setOldCheckForms] = useState<TOSCheck>({
    tos: true,
    smsOptIn: false,
  })
  const [newTOS, setNewTOS] = useState<NewTOS>({
    tos: true,
    marketingSmsOptIn: false,
    transactionalSmsOptIn: false,
  })
  const TOSCheckForm = isNewTOSCheckFormEnabled ? NewTOSCheckForm : OldTOSCheckForm
  return {isNewTOSCheckFormEnabled, oldCheckForms, setOldCheckForms, newTOS, setNewTOS, TOSCheckForm}
}
