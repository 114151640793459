import React from 'react'

import {isValidUrl, prependHttpsProtocolIfMissing} from '@posh/model-types'
import {IPendingApproval} from 'apis/Orders/useGetPendingApprovals'
import classNames from 'classnames'

const CheckoutData = ({
  checkoutData,
  onClickFileName,
}: {
  checkoutData: IPendingApproval['cart']['checkoutData']
  onClickFileName: (fileKey: string) => void
}) => {
  return (
    <div className='ApprovalModal-Content-CheckoutData'>
      {checkoutData.map(checkoutResponse => {
        const {question, answer, isFileResponse, fileKey} = checkoutResponse
        if (!answer) return null

        const answerIsUrl = isValidUrl(answer)

        const onClick = () => {
          if (isFileResponse) {
            onClickFileName(fileKey!)
          } else if (answerIsUrl) {
            window.open(prependHttpsProtocolIfMissing(answer), '_blank')
          }
        }
        return (
          <div key={question} className='ApprovalModal-Content-CheckoutData-Response'>
            <p className='grey-light m-0'>{question}</p>
            <p className={classNames('m-0', {['white primary-link']: isFileResponse || answerIsUrl})} onClick={onClick}>
              {answer}
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default CheckoutData
