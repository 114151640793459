import {Avatar, AvatarFallback, AvatarImage} from '@posh/design-kit/components/avatar'
import {Button} from '@posh/design-kit/components/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@posh/design-kit/components/dialog'
import {ScrollArea} from '@posh/design-kit/components/scroll-area'
import {TGroupSmsOptIn} from '@posh/sms-opt-ins'
import {formatPhoneNumber_Legacy} from '@posh/types'
import {Bell, BellOff} from 'lucide-react'

type NotificationGroupsModalProps = {
  type: 'yes' | 'no-subaccount' | 'no-application'
  groupOptIns: TGroupSmsOptIn[]
  onUpdateGroup?: (group: TGroupSmsOptIn) => void
}

export function NotificationGroupsModal({type, groupOptIns, onUpdateGroup}: NotificationGroupsModalProps) {
  const filteredGroupOptIns = groupOptIns.filter(optIn => optIn.optIns?.smsOptIn === type)

  const getIcon = () => {
    switch (type) {
      case 'yes':
        return <Bell className='mt-0.5 h-5 w-5 flex-shrink-0 text-primary' />
      case 'no-subaccount':
        return <Bell className='mt-0.5 h-5 w-5 flex-shrink-0 text-destructive' />
      case 'no-application':
        return <BellOff className='mt-0.5 h-5 w-5 flex-shrink-0 text-muted-foreground' />
    }
  }

  const getTitle = () => {
    switch (type) {
      case 'yes':
        return 'Subscribed'
      case 'no-subaccount':
        return 'Blocked'
      case 'no-application':
        return 'Unsubscribed'
    }
  }

  const getDescription = () => {
    switch (type) {
      case 'yes':
        return "Organizations you've allowed to send you alerts."
      case 'no-subaccount':
        return 'These organizations have been removed because you replied "STOP" in messages.'
      case 'no-application':
        return "You've opted out in your app settings."
    }
  }

  const getActionButtonText = () => {
    switch (type) {
      case 'yes':
        return 'Unsubscribe'
      case 'no-subaccount':
        return 'Unblock by replying "START"'
      case 'no-application':
        return 'Subscribe'
    }
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant='outline' className='flex h-auto w-full items-start justify-start gap-2 px-4 py-3 text-left'>
          {getIcon()}
          <div className='w-full overflow-hidden'>
            <div className='font-medium text-foreground'>{getTitle()}</div>
            <div className='overflow-hidden text-sm text-ellipsis text-muted-foreground'>{getDescription()}</div>
          </div>
        </Button>
      </DialogTrigger>
      <DialogContent className='dark sm:max-w-xl'>
        <DialogHeader>
          <DialogTitle asChild>
            <div className='flex items-center gap-2 text-lg leading-none font-semibold text-foreground'>
              {getIcon()}
              {getTitle()}
            </div>
          </DialogTitle>
        </DialogHeader>

        <DialogDescription asChild>
          <div className='p-0 text-muted-foreground'>{getDescription()}</div>
        </DialogDescription>

        <ScrollArea className='max-h-[60vh] overflow-y-auto'>
          {filteredGroupOptIns.length > 0 ? (
            <div className='space-y-3'>
              {filteredGroupOptIns.map(optIn => (
                <div key={optIn.id} className='flex items-center justify-between rounded-lg border p-3'>
                  <div className='flex items-center gap-2'>
                    <Avatar>
                      <AvatarImage src={optIn.group.aviLocation} />
                      <AvatarFallback>{optIn.group.name.slice(0, 2)}</AvatarFallback>
                    </Avatar>
                    <div>
                      <div className='text-md font-medium text-foreground'>{optIn.group.name}</div>
                      {optIn.group.groupPhoneNumber && (
                        <div className='text-sm text-muted-foreground'>
                          {formatPhoneNumber_Legacy(optIn.group.groupPhoneNumber)}
                        </div>
                      )}
                    </div>
                  </div>
                  <Button
                    className='border border-input text-muted-foreground'
                    variant='outline'
                    size='sm'
                    disabled={!onUpdateGroup}
                    onClick={() => onUpdateGroup?.(optIn)}>
                    {getActionButtonText()}
                  </Button>
                </div>
              ))}
            </div>
          ) : (
            <div className='py-6 text-center text-muted-foreground'>No groups found.</div>
          )}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
