import * as Yup from 'yup'

export const duplicateEventValidator = Yup.object().shape({
  name: Yup.string().required('Name is required').min(3, 'Name must be at least 3 characters'),
  startUtc: Yup.date().required('Start date is required'),
  endUtc: Yup.date().required('End date is required'),
  timezone: Yup.string().required('Timezone is required'),
  flyer: Yup.string().optional(),
  smallFlyer: Yup.string().optional(),
})
