import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetGroupEventsOptions = ReactQueryOptions['groups']['getGroupEvents']
type GetGroupEventsInput = RouterInputs['groups']['getGroupEvents']
type GetGroupEventsOutput = RouterOutput['groups']['getGroupEvents']

export type GetGroupEventsEvent = GetGroupEventsOutput['events'][number]

export function useFetchGroupEvents(input: GetGroupEventsInput, opts?: GetGroupEventsOptions) {
  return trpc.groups.getGroupEvents.useQuery(input, opts)
}
