import React, {useState} from 'react'

import useFetchEvent from 'apis/Events/useFetchEvent'
import {GetAllEventTicketsOutput, useGetAllEventTickets} from 'apis/Events/useGetAllEventTickets'
import {useUpdateEvent_DEPRECATED} from 'apis/Events/useUpdateEventDEPRECATED'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import ComplimentaryTickets from 'pages/EventManagementPages/TeamMembers/ComplimentaryTickets'
import {TicketGroupTable} from 'pages/EventManagementPages/TeamMembers/TicketGroupTable'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import PromoCodesSection from '../PromoCodes/PromoCodesSection'
import SortableTicketList from './SortableTicketsList'

const TicketsSection = () => {
  const {eventId, groupId} = useResourcePageParams()
  const {
    data: eventTicketsResponse,
    isFetching: isFetchingTickets,
    refetch: refetchTickets,
    dataUpdatedAt: dateUpdatedTickets,
  } = useGetAllEventTickets({eventId: eventId!}, {onSuccess: data => setOrderedTickets(data.tickets ?? [])})

  const {data: event, refetch: refetchEvent} = useFetchEvent(eventId!)
  const [orderedTickets, setOrderedTickets] = useState(eventTicketsResponse?.tickets ?? [])
  const {mutateAsync: updateEvent, isLoading: isUpdatingEventTickets} = useUpdateEvent_DEPRECATED()

  const onRerenderTickets = async (tickets: GetAllEventTicketsOutput) => {
    setOrderedTickets(tickets)
    // Convert string dates to Date objects before updating
    const ticketsWithDateObjects = tickets.map(ticket => ({
      ...ticket,
      validity: ticket.validity
        ? {
            ...ticket.validity,
            validAfter: ticket.validity.validAfter ? new Date(ticket.validity.validAfter) : null,
            validBefore: ticket.validity?.validBefore ? new Date(ticket.validity.validBefore) : null,
          }
        : undefined,
    }))

    await updateEvent({eventId: eventId!, groupId: groupId!, eventData: {tickets: ticketsWithDateObjects}})
  }

  const refetchTicketGroups = () => {
    refetchTickets()
    refetchEvent()
  }

  const group = event?.group
  const currency = event?.group.currency
  const ticketGroups = event?.event.ticketGroups
  const isRSVPEvent = event?.event.isRSVPEvent

  //TODO: use the sidebar editor panel here?
  // no shot this can easily plug into it right?
  return (
    <>
      <RequirePermissions requiredPermissions={[requirePermissionAndScope('edit_event_tickets', ['event', 'group'])]}>
        <SortableTicketList
          tickets={orderedTickets}
          setTickets={onRerenderTickets}
          isFetchingTickets={isFetchingTickets}
          refetchTickets={refetchTickets}
          dateUpdatedTickets={dateUpdatedTickets}
          currency={currency}
          hasUpdatedTickets={isUpdatingEventTickets === false}
          timezone={event?.event.timezone}
        />
      </RequirePermissions>
      <RequirePermissions
        requiredPermissions={[requirePermissionAndScope('view_and_send_complimentary_tickets', ['event', 'group'])]}>
        {ticketGroups && eventTicketsResponse && (
          <TicketGroupTable
            ticketGroups={event?.event.ticketGroups}
            ticketTypes={eventTicketsResponse.tickets}
            refetch={refetchTicketGroups}
            eventStatus={event?.event.status ?? ''}
            lastUpdated={dateUpdatedTickets}
          />
        )}
      </RequirePermissions>
      <RequirePermissions
        requiredPermissions={[requirePermissionAndScope('view_and_send_complimentary_tickets', ['event', 'group'])]}>
        <ComplimentaryTickets />
      </RequirePermissions>
      <RequirePermissions requiredPermissions={[requirePermissionAndScope('add_promo_code', ['event', 'group'])]}>
        {!!group && !isRSVPEvent && <PromoCodesSection group={group} />}
      </RequirePermissions>
    </>
  )
}

export default TicketsSection
