import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {useGroupTextBlastStatus} from 'hooks/useGroupTextBlastStatus'
import {useResourcePageParams} from 'pages/PoshAppLayout'

const AttendeePanes = ({
  startNewCampaign,
  setLimitModalIsOpen,
}: {
  startNewCampaign: () => void
  setLimitModalIsOpen: (isOpen: boolean) => void
}) => {
  const {groupId} = useGroupMatchParams()
  const {domain} = useResourcePageParams()
  const search = useLocation().search
  const {trackEvent} = useMixpanel()

  const onClickNewSMSCampaign = () => {
    trackEvent('New SMS Campaign- Group Marketing Page')
    startNewCampaign()
  }

  const onClickViewSMSCampaign = () => {
    trackEvent('View SMS Campaign- Group Marketing Page')
    navigate(`/${domain}/groups/${groupId}/marketing/view-sms` + search)
  }

  const navigate = useNavigate()

  const {isSMSCampaignEnabled, shouldShowRemainingCampaigns, remainingCampaignsText, smsLimitReasoningInfo} =
    useGroupTextBlastStatus({groupId})

  return (
    <div className='Attendees-Panes'>
      {shouldShowRemainingCampaigns && (
        <div className='Attendees-Panes-Pane-sms-content-status-container' onClick={() => setLimitModalIsOpen(true)}>
          <span className='Attendees-Panes-Pane-sms-content-status'>{remainingCampaignsText}</span>
        </div>
      )}
      <Button onClick={onClickViewSMSCampaign}>View SMS Campaigns</Button>
    </div>
  )
}

export default AttendeePanes
