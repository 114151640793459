import React, {ComponentType} from 'react'

import useComponentButtonListener from 'components/helpers/componentButtonListener'
import {AnimatePresence, AnimationProps, motion} from 'framer-motion'

import styles from './styles.module.scss'

export interface BaseModalProps {
  isOpen: boolean
  onClose: () => void
  Background?: ComponentType
  animate?: AnimationProps['animate']
  exit?: AnimationProps['exit']
  enablePressEscapeToClose?: boolean
  children?: React.ReactNode
}

const DEFAULT_TRANSITION = {
  duration: 0.2,
}

const DEFAULT_ENTERING_ANIMATION = {
  opacity: [0, 1],
  transition: DEFAULT_TRANSITION,
}
const DEFAULT_EXITING_ANIMATION = {
  opacity: 0,
  transition: DEFAULT_TRANSITION,
}

/**
 * A generic modal component.
 * A "Modal" is simply a container that is displayed on top of the rest of the page content and can be hidden and shown.
 * This component simply provides the hiding and showing logic and minimal styling to position its contents. It leaves any other styling as the responsibility of its children
 *
 * @props isOpen Whether the modal is currently open or not.
 * @param onClose A function to call when the modal is closed. The modal will close when the user presses the escape key.
 * @props children The "content" of the modal
 * @props [Background] A component to render as the background of the modal. Defaults to a semi-opaque black
 * @props [animate] An object specifying how the modal animates when it appears. Defaults to an opacity transition
 * @props [exit] An object specifying how the modal animates when it exits. Defaults to an opacity transition
 * @props [enablePressEscapeToClose] Whether the user can press the escape key to close the modal. Defaults to true
 */
export function BaseModal(props: BaseModalProps) {
  const {
    isOpen,
    onClose,
    Background,
    animate = DEFAULT_ENTERING_ANIMATION,
    exit = DEFAULT_EXITING_ANIMATION,
    children,
    enablePressEscapeToClose = true,
  } = props

  useComponentButtonListener('Escape', () => {
    enablePressEscapeToClose && onClose()
  })

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div className={styles.modal} animate={animate} exit={exit}>
          <div className={styles.content}>{children}</div>
          {Background && (
            <div className={styles.background}>
              <Background />
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
