// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CRUDTable__mixWq{display:flex;flex-direction:column;gap:12px;max-width:100%}.CRUDTable__mixWq .ActionButtons__xlyCs{display:flex;align-items:flex-end;gap:12px}.CRUDTableHeader__uGVFF{display:flex;justify-content:space-between;align-items:center}.CRUDTableHeader__uGVFF>h3{padding:0;margin:0}", "",{"version":3,"sources":["webpack://./src/components/TableUI/CRUDTable/CRUDTable.module.scss"],"names":[],"mappings":"AAEA,kBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,cAAA,CAEA,wCACE,YAAA,CACA,oBAAA,CACA,QAAA,CAIJ,wBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAEA,2BACE,SAAA,CACA,QAAA","sourcesContent":["@import '@global-styles/_color_pallete';\n\n.CRUDTable {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  max-width: 100%;\n\n  .ActionButtons {\n    display: flex;\n    align-items: flex-end;\n    gap: 12px;\n  }\n}\n\n.CRUDTableHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  > h3 {\n    padding: 0;\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CRUDTable": "CRUDTable__mixWq",
	"ActionButtons": "ActionButtons__xlyCs",
	"CRUDTableHeader": "CRUDTableHeader__uGVFF"
};
export default ___CSS_LOADER_EXPORT___;
