import React from 'react'

import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import GroupPageSubNav from 'pages/GroupPage/SubNav'

import OrdersListView from '../OrdersListView'

export const OrdersPage = () => {
  const {groupId} = useGroupMatchParams()
  const {data: group} = useFetchGroup(groupId)

  return (
    <div className='GroupPage-content'>
      <GroupPageSubNav group={group} hierarchy={['Orders']} />
      <OrdersListView expanded showFilter={true} />
    </div>
  )
}
