import React from 'react'

import {AnalyticsDateRangePicker, Dates} from './AnalyticsDateRangePicker'
import {TimePeriodOption} from './TimePeriodOption'

export type TOverviewTimePeriod = 'day' | 'week' | 'month' | 'ytd' | 'event-lifetime' | 'custom'
type OverviewTimePeriodSelectorProps = {
  onClick: (timeRange: TOverviewTimePeriod) => void
  selectedTimePeriod: TOverviewTimePeriod
  customDates: Dates
  setCustomDates: (arg: Dates) => void
}

const OverviewTimePeriodStyle: React.CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  gap: '7px',
}

export const OverviewTimePeriodSelector = ({
  onClick,
  selectedTimePeriod,
  customDates,
  setCustomDates,
}: OverviewTimePeriodSelectorProps) => {
  const getTimePeriodOptionProps = (timePeriod: TOverviewTimePeriod) => {
    return {
      highlighted: selectedTimePeriod === timePeriod,
      onClick: () => {
        onClick(timePeriod)
      },
    }
  }

  return (
    <div style={OverviewTimePeriodStyle}>
      <TimePeriodOption {...getTimePeriodOptionProps('day')}>
        <p className='text-sm text-gray-400'>Day</p>
      </TimePeriodOption>
      <TimePeriodOption {...getTimePeriodOptionProps('week')}>
        <p className='text-sm text-gray-400'>Week</p>
      </TimePeriodOption>
      <TimePeriodOption {...getTimePeriodOptionProps('month')}>
        <p className='text-sm text-gray-400'>Month</p>
      </TimePeriodOption>
      <TimePeriodOption {...getTimePeriodOptionProps('ytd')}>
        <p className='text-sm text-gray-400'>YTD</p>
      </TimePeriodOption>
      <TimePeriodOption {...getTimePeriodOptionProps('event-lifetime')}>
        <p className='text-sm text-gray-400'>All Time</p>
      </TimePeriodOption>
      <TimePeriodOption {...getTimePeriodOptionProps('custom')} style={{minWidth: '200px'}}>
        <AnalyticsDateRangePicker {...customDates} setDates={setCustomDates} />
      </TimePeriodOption>
    </div>
  )
}
