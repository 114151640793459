// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputContainer__-ttBD{width:100%;display:flex;flex-direction:column;gap:5px;position:relative}", "",{"version":3,"sources":["webpack://./src/components/DynamicForm/Inputs/shared/InputContainer/InputContainer.module.scss"],"names":[],"mappings":"AAAA,uBACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CACA,iBAAA","sourcesContent":[".InputContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputContainer": "InputContainer__-ttBD"
};
export default ___CSS_LOADER_EXPORT___;
