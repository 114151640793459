import React from 'react'
import {useParams} from 'react-router-dom'

import {ExperimentKey} from '@posh/model-types'
import {CurrencyCode} from '@posh/types'
import {Accordion, AccordionItem} from '@szhsin/react-accordion'
import useFetchCheckoutSummary from 'apis/Checkout/useFetchCheckoutSummary'
import {useFetchEventByUrl} from 'apis/Events/useFetchEvent'
import useLiveCartContext from 'domains/LiveCart/LiveCartContext'
import {useExperiment} from 'hooks/useExperiment'
import {useTrackRudderStackEvent} from 'hooks/useTrackRudderstackEvent'
import {isUndefined} from 'lodash'
import {ChevronRight} from 'lucide-react'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'
import {excludedEventIds} from 'utils/experiments'

const OrderSummaryTable = ({currency}: {currency?: CurrencyCode}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const cartId = urlParams.get('cartId') as string
  const {eventUrl} = useParams<{eventUrl: string}>()
  const {selectedPaymentPlan} = useLiveCartContext()
  const {data: summary} = useFetchCheckoutSummary({cartId})
  const {data: eventData} = useFetchEventByUrl(eventUrl)
  const {cartInfo, promoCodeInfo, paymentPlansInfo} = summary ?? {}
  const cartItemCharges = cartInfo?.items
  const currencySymbol = getCurrencySymbol(currency)
  const paymentPlanIndex = selectedPaymentPlan
  const isShowingPaymentPlanSummary = !isUndefined(paymentPlanIndex)

  const showPromoCodeDiscount = !isUndefined(promoCodeInfo) && (promoCodeInfo.discount ?? 0) !== 0
  const cartTotal = cartInfo?.total?.toFixed(2) ?? 0
  const paymentPlanDueToday =
    isShowingPaymentPlanSummary && paymentPlansInfo?.paymentPlans[paymentPlanIndex]![0].amount.toFixed(2)

  const hasFlatFee = Boolean(cartInfo?.feeBreakdown?.filter((item: {type: string}) => item.type === 'flat').length)
  const groupedFeesExperiment = useExperiment({
    experimentKey: ExperimentKey.GROUPED_FEES_EXPERIMENT_V2,
    enabled:
      Number(cartTotal) > 0 &&
      !hasFlatFee &&
      !eventData?.event.showFeesInPrice &&
      !!eventData?.event.id &&
      !excludedEventIds.includes(eventData?.event.id),
  })

  const {orderSummaryToggled} = useTrackRudderStackEvent()

  const checkoutSummaryTableRender = (showTotal = false) => (
    <table className='CheckoutSummary'>
      <tbody>
        {cartItemCharges?.map(cartItemCharge => {
          return (
            <tr className='CheckoutSummary-Row' key={cartItemCharge.resourceId}>
              <td>{cartItemCharge.descriptor}</td>
              <td>{`${currencySymbol}${cartItemCharge.resourceAmount.toFixed(2)}`}</td>
              <td>{`x${cartItemCharge.quantity}`}</td>
              <td>{`${currencySymbol}${cartItemCharge.amount.toFixed(2)}`}</td>
            </tr>
          )
        })}
        {showPromoCodeDiscount && (
          <tr className='CheckoutSummary-DiscountRow'>
            <td>Discount</td>
            <td></td>
            <td></td>
            <td>{`-${currencySymbol}${promoCodeInfo.discount.toFixed(2)}`}</td>
          </tr>
        )}
        {cartInfo?.feeBreakdown && (
          <>
            {cartInfo?.feeBreakdown
              .filter(fee => fee.totalFee > 0)
              .map(f => (
                <tr className='CheckoutSummary-FeeRow' key={f.name}>
                  <td>{f.name}</td>
                  <td></td>
                  <td></td>
                  <td>{`${currencySymbol}${f.totalFee.toFixed(2)}`}</td>
                </tr>
              ))}
          </>
        )}
        {showTotal && (
          <tr className='CheckoutSummary-TotalRow'>
            <td>Total</td>
            <td></td>
            <td></td>
            <td>{`${currencySymbol}${cartTotal}`}</td>
          </tr>
        )}
        {isShowingPaymentPlanSummary && paymentPlanDueToday && (
          <tr className='CheckoutSummary-TotalRow paymentPlan'>
            <td>Due Today</td>
            <td></td>
            <td></td>
            <td>{`${currencySymbol}${paymentPlanDueToday}`}</td>
          </tr>
        )}
      </tbody>
    </table>
  )

  return (
    <>
      {summary && (
        <div className='CheckoutPage-Section fade'>
          <h5>Your Order</h5>

          {groupedFeesExperiment.value ? (
            <Accordion
              transition
              transitionTimeout={150}
              className='CheckoutSummary OrderSummaryAccordion'
              onStateChange={({current}) => {
                if (current.isResolved) {
                  orderSummaryToggled(current.isEnter, {params: {cartId}, experiment: groupedFeesExperiment})
                }
              }}>
              <AccordionItem
                header={
                  <div className='flex w-full items-center'>
                    <div>
                      <ChevronRight width={20} color='white' />
                    </div>
                    <div className='flex-1 px-1 text-left'>Total</div>
                    <div className='textRight px-1'>{`${currencySymbol}${cartTotal}`}</div>
                  </div>
                }>
                {checkoutSummaryTableRender()}
              </AccordionItem>
            </Accordion>
          ) : (
            checkoutSummaryTableRender(true)
          )}
        </div>
      )}
    </>
  )
}

export default OrderSummaryTable
