import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetAllEventTicketsOptions = ReactQueryOptions['events']['getAllEventTickets']
type GetAllEventTicketsInput = RouterInputs['events']['getAllEventTickets']
export type GetAllEventTicketsOutput = NonNullable<RouterOutput['events']['getAllEventTickets']['tickets']>

export const useGetAllEventTickets = (input: GetAllEventTicketsInput, options?: GetAllEventTicketsOptions) => {
  return trpc.events.getAllEventTickets.useQuery(input, options)
}

export const useInvalidateGetAllEventTickets = () => {
  const qc = trpc.useContext()
  const invalidateGetAllEventTickets = () => qc.events.getAllEventTickets.invalidate()
  return {invalidateGetAllEventTickets}
}
