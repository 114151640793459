import React, {useCallback, useEffect, useRef, useState} from 'react'
import Confetti from 'react-confetti'
import Helmet from 'react-helmet'
import Loader from 'react-loader-spinner'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import {Route, Routes, useNavigate, useParams} from 'react-router-dom'
import useWindowSize from 'react-use/lib/useWindowSize'

import {getEventPageDateDisplay} from '@posh/model-types'
import {getGroupAvi} from '@posh/model-types'
import {GUESTLIST_DISPLAY_THRESHOLD, shouldShowGuestList as determineShouldShowGuestList} from '@posh/types'
import {useQueryClient} from '@tanstack/react-query'
import useCreateCart from 'apis/Carts/useCreateCart'
import useFetchCheckoutSummary from 'apis/Checkout/useFetchCheckoutSummary'
import {EventTable, EventTicketAndTicketsAvailable} from 'apis/Events/types'
import {useFetchEventGuestlist} from 'apis/Events/useFetchEventGuestlist'
import {useTrackEventPageView} from 'apis/Events/useTrackEventPageView'
import {facebookPixelPageView, facebookPixelViewContent} from 'apis/FbPixelHelpers'
import useFetchEventKickbackAmount from 'apis/Kickback/useFetchEventKickbackAmount'
import {useMixpanel} from 'apis/MixPanelHandler'
import {baseURL} from 'apis/NavigationHelper'
import getAvi from 'apis/Util/getAvi'
import getEventFlyer from 'apis/Util/getEventFlyer'
import {KickbackIcon} from 'components/assets/KickbackIcon'
import EventFooter from 'components/EventFooter'
import {MultisessionEventFeatureFlag} from 'components/FeatureFlag/MultisessionEventFeatureFlag'
import Button from 'components/form/Button'
import CustomGTMTracking from 'components/GTMTracking/CustomGTMTracking'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage, PoshImageBackground} from 'components/PoshImage/PoshImage'
import {PoshLogo} from 'components/PoshLogo'
import {EventTitleFontStyleProvider, getEventTitleFont} from 'components/Text/EventTitleFontStyleProvider'
import {SafeMarkdownDisplay} from 'components/Text/SafeMarkdownDisplay'
import {IToast, useToast} from 'components/toasts/ToastProvider'
import UserListPreview from 'components/UserListPreview/UserListPreview'
import VerifiedGroupBadge from 'components/VerifiedGroupBadge'
import {LiveCartContextProvider} from 'domains/LiveCart/LiveCartContext'
import setOptimizelyUserCustomAttribute from 'helpers/setOptimizelyUserCustomAttribute'
import {trackCreateCart} from 'helpers/tracking/coreEvents/createCart'
import {eventPageView} from 'helpers/tracking/coreEvents/eventView'
import {useDimensions} from 'hooks/useDimensions'
import {useTrackRudderStackEvent} from 'hooks/useTrackRudderstackEvent'
import {useUnauthenticateIfExpiredToken} from 'hooks/useUnauthenticateIfExpiredToken'
import {isEmpty, isUndefined} from 'lodash'
import moment from 'moment-timezone'
import {EventSeriesSignifier} from 'pages/EventCard'
import ProfilePreviewModal, {PreviewProfileForModal} from 'pages/EventPage/Guestlist/ProfilePreviewModal'
import useHasImageDownloaded from 'pages/EventPage/useHasImageDownloaded.hooks'
import {useShowProfileFromLink} from 'pages/EventPage/useShowProfileFromLink'
import PoshHeader from 'pages/OwnerPage/PoshHeader'
import PageNotFound from 'pages/PageNotFound/PageNotFound'
import KickbackModal from 'pages/ReceiptPage/KickbackElements'

import {affiliateTrackingLinkClick} from '../../apis/Events'
import {useFetchEventByUrl} from '../../apis/Events/useFetchEvent'
import {useFetchEventTicketsUrl} from '../../apis/Events/useFetchEventTicketsUrl'
import useFetchVenue from '../../apis/Venues/useFetchVenue'
import {FilledMinus, FilledPlus} from '../../components/assets/Icons'
import useSessionContext from '../../domains/Auth/SessionContext'
import useCartContext from '../../domains/Cart/CartContext'
import InteractiveVenueMap from '../EventManagementPages/Tables/TableMap/InteractiveVenueMap'
import {RSVPModal} from '../RSVPModal'
import AttendeeActions from './AttendeeActions'
import CartOverlay from './CartOverlay'
import DeletedEvent from './DeletedEvent'
import EditProfilePictureModal from './EditProfilePictureModal'
import {generateCartItems, getCheckoutUrl} from './EventCheckoutHelpers'
import EventInfoTable from './EventInfoTable'
import {EventPageContextProvider} from './EventPageContext/EventPageContext'
import {getEventPagePalette} from './EventPageContext/EventPagePalette'
import EventPagePassword from './EventPagePassword'
import EventPageToolbar from './EventPageToolbar'
import EventSection from './EventSection'
import Guestlist from './Guestlist'
import {EventPageLineupSection} from './Lineup'
import {MultiSessionMoreDatesSignifier} from './MultiSessionEvent/MultiSessionMoreDatesSignifier/MultiSessionMoreDatesSignifier'
import SongPlayer from './SongPlayer'
import TableFilter from './TableFilter'
import TicketActionRow from './TicketActionRow'
import {TicketGroup} from './TicketGroup'
import {TicketsContainer} from './TicketsContainer'
import {useEventPageTrackingParams} from './useEventPageTrackingParams'
import {useGetKickbackButtonPosition} from './useGetKickbackButtonPosition'
import {VenueImages} from './VenueImages'
import {YoutubeIFrame} from './Youtube'

export const FilledPlusIconWrapper = (accentColor: string) => (
  <FilledPlus className='StatsSection-plusIcon' style={{fill: `${accentColor}`}} />
)
export const FilledMinusIconWrapper = (accentColor: string) => (
  <FilledMinus className='StatsSection-plusIcon' style={{fill: `${accentColor}`}} />
)

interface EventPageProps {
  isOwner: boolean
  isAdmin: boolean
  eventUrlProps?: string
}

export interface ProfilePreviewModalState {
  profile?: PreviewProfileForModal
  isOpen: boolean
}

const parseErrorForToast = (error: Error): Omit<IToast, 'id' | 'duration'> => {
  // Better way to create this mapping from back-end error string to front-end display?
  if (error.message === 'Group has not been setup properly to sell tickets') {
    return {
      type: 'warning',
      title: "This event wasn't setup completely.",
      subtitle: "We've notified the event's organizer. Please try again later.",
    }
  }
  return {
    type: 'error',
    title: error.message ?? 'An error occured while creating the cart.',
  }
}

function EventPageIndex(props: EventPageProps) {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {isOwner, isAdmin, eventUrlProps} = props
  const {eventUrl: eventUrlParams} = useParams<{eventUrl: string}>()
  const eventUrl = eventUrlProps ?? eventUrlParams
  const navigate = useNavigate()
  const {ticketsCount, tables, setTrackingLink, addTables, cartItems, resetCart} = useCartContext()
  const {userId, currentUser} = useSessionContext()
  const queryClient = useQueryClient()
  const [isLocked, setIsLocked] = useState(true)
  const urlParams = new URLSearchParams(window.location.search)
  const [scrollTo, setScrollTo] = useState('')
  const {savedTrackingLink, savedAffiliateLink} = useEventPageTrackingParams(eventUrl!)

  const [unlockedTickets, setUnlockedTickets] = useState<EventTicketAndTicketsAvailable[] | null>(null)
  const [ticketPassword, setTicketPassword] = useState<string>('')
  const [ticketPasswordErrorMessage, setTicketPasswordErrorMessage] = useState<string>('')
  const [eventDescriptionCapped, setEventDescriptionCapped] = useState<boolean>(false)
  const [showViewMoreSection, setShowViewMoreSection] = useState<boolean>(true)
  const [shouldShowAddProfilePicture, setShouldShowAddProfilePicture] = useState(false)
  const [profilePreviewModal, setProfilePreviewModal] = useState<ProfilePreviewModalState>({
    profile: undefined,
    isOpen: false,
  })
  const [isUnLockingTickets, setIsUnLockingTickets] = useState<boolean>(false)
  const showAddProfilePictureModal = () => {
    setShouldShowAddProfilePicture(true)
  }
  const [isRSVPModalOpen, setIsRSVPModalOpen] = useState<boolean>(false)
  const [isKickbackModalOpen, setIsKickbackModalOpen] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('')
  const [tablesToDisplay, setTablesToDisplay] = useState<EventTable[]>([])

  const {width, height} = useWindowSize()
  const {isMobile} = useDimensions()

  const {trackCartCreated} = useTrackRudderStackEvent()

  useEffect(() => {
    queryClient.invalidateQueries(['event'])
  }, [currentUser])

  useTrackEventPageView(
    {
      url: eventUrl!,
      isUsingShortLink: false,
      trackingLink: savedTrackingLink ?? undefined,
      affiliateLink: savedAffiliateLink ?? undefined,
    },
    {
      enabled: !!eventUrl,
      retry: false,
      onSuccess: () => {
        trackMixpanelEvent('Event Page View- Event Page')
        eventData && eventPageView(eventData?.event, currentUser)
      },
    },
  )

  useEffect(() => {
    if (savedAffiliateLink && savedAffiliateLink !== '') {
      affiliateTrackingLinkClick(eventUrl ?? '', savedAffiliateLink)
    }
  }, [savedAffiliateLink]) // eslint-disable-line react-hooks/exhaustive-deps

  const [cartId, setCartId] = useState<string | null>(null)
  const {data: eventData, refetch: refetchEventData, isFetching: isEventDataFetching} = useFetchEventByUrl(eventUrl)
  const {data: venue} = useFetchVenue(eventData?.event.venueId)
  const {data: kickbackAmount, isFetching: isFetchingKickbackAmount} = useFetchEventKickbackAmount(
    {
      eventId: eventData?.event.id ?? '',
    },
    {
      enabled: !!eventData?.event.id,
    },
  )
  const {data: checkoutSummary} = useFetchCheckoutSummary({cartId: cartId!}, {enabled: !!cartId})
  const activityRef = useRef<HTMLDivElement>(null)
  const ticketSectionRef = useRef<HTMLDivElement>(null)

  const {imageHasDownloaded: isEventFlyerLoaded, imageDownloadedWithError} = useHasImageDownloaded(
    eventData ? getEventFlyer(eventData.event) : undefined,
  )

  useEffect(() => {
    if (scrollTo) {
      switch (scrollTo) {
        case 'activity':
          activityRef.current?.scrollIntoView({behavior: 'smooth'})
          break
        default:
          break
      }
    }
  }, [scrollTo, activityRef.current])

  const {data: eventTicketsData, refetch: refetchTickets} = useFetchEventTicketsUrl(eventUrl ?? '', ticketPassword)
  const {data, isInitialLoading: isLoadingGuestlist} = useFetchEventGuestlist(
    {
      eventId: eventData?.event.id ?? '',
      viewerId: userId,
    },
    {
      enabled: (() => {
        if (eventData && eventTicketsData) {
          const {event} = eventData
          const {totalTicketsSold} = eventTicketsData
          const {guestlistEnabled, fakeGuestlistCount} = event
          const guestlistIsEnabledForEvent = guestlistEnabled || !!fakeGuestlistCount
          const ticketsSoldMeetsGuestlistThreshold = (totalTicketsSold ?? 0) > GUESTLIST_DISPLAY_THRESHOLD
          return guestlistIsEnabledForEvent && ticketsSoldMeetsGuestlistThreshold
        } else {
          return false
        }
      })(),
    },
  )
  const guestlist = data?.guestlist

  const {mutateAsync: createCart} = useCreateCart()

  const ticketsToDisplay = (eventTickets: EventTicketAndTicketsAvailable[]) => {
    const ticketGroups = eventData?.event.ticketGroups ?? []
    if (isEmpty(ticketGroups)) return eventTickets
    return eventTickets.reduce((acc: EventTicketAndTicketsAvailable[], ticket) => {
      if (!ticketGroups.some(({ticketTypes}) => ticketTypes.includes(ticket.ticket.id))) {
        acc.push(ticket)
      }
      return acc
    }, [])
  }

  useEffect(() => {
    if (eventTicketsData?.eventTickets) {
      const eventTickets = eventTicketsData?.eventTickets
      const ticketsOutsideOfGroups = ticketsToDisplay(eventTickets)
      setUnlockedTickets(ticketsOutsideOfGroups)
    }
  }, [eventTicketsData, eventData])

  useEffect(() => {
    resetCart()
  }, [])

  useEffect(() => {
    if (eventData && eventData.event.tables && eventData.event.tables.length > 0) {
      const tables = eventData.event.tables.filter(table => table.filterKey === selectedFilter)
      if (tables.length > 0) setTablesToDisplay(tables)
      else if (selectedFilter === 'Both' && eventData.event.tableFilterKeys) {
        const firstKey = eventData.event.tableFilterKeys[0]
        const secondKey = eventData.event.tableFilterKeys[1]
        if (firstKey && secondKey) {
          const tablesWithFirstKey = eventData.event.tables.filter(table => table.filterKey === firstKey)
          const tablesWithSecondKey = eventData.event.tables.filter(table => table.filterKey === secondKey)
          const tablesWithSameMapPosition: EventTable[] = []
          tablesWithFirstKey.forEach(t => {
            tablesWithSecondKey.forEach(s => {
              if (
                t.mapPosition &&
                s.mapPosition &&
                t.mapPosition[0] === s.mapPosition[0] &&
                t.mapPosition[1] === s.mapPosition[1]
              )
                tablesWithSameMapPosition.push(t, s)
            })
          })
          setTablesToDisplay(tablesWithSameMapPosition)
        }
      } else setTablesToDisplay(eventData.event.tables)
    }
  }, [selectedFilter])

  useEffect(() => {
    if (eventData && eventData.event) {
      if (window.location.hash) setScrollTo(window.location.hash.substring(1))
      if (eventData.event.fbPixelId) {
        const viewContentData = {
          content_name: `View Content - ${eventData.event.name}`,
        }
        facebookPixelPageView(eventData.event.fbPixelId)
        facebookPixelViewContent(viewContentData, eventData.event.fbPixelId)
      }
      if (eventData.event.tables && eventData.event.tables.length > 0) {
        if (
          eventData.event.tableFilterKeys &&
          eventData.event.tableFilterKeys.length > 0 &&
          !isUndefined(eventData.event.tableFilterKeys)
        )
          setSelectedFilter(eventData.event.tableFilterKeys[0])
        else setTablesToDisplay(eventData.event.tables)
      }
    }
  }, [eventData?.event])

  const onCloseProfilePreviewModal = useCallback((save?: boolean) => {
    if (save) {
      setProfilePreviewModal(modal => ({...modal, isOpen: false}))
    } else {
      setProfilePreviewModal({isOpen: false, profile: undefined})
    }
  }, [])

  useShowProfileFromLink({isEventDataFetching, setProfilePreviewModal, guestlist})
  const {showToast} = useToast()

  const numberOfItems =
    cartItems && cartItems.length > 0 ? cartItems.map(i => i.quantity).reduce((partialSum, a) => partialSum + a, 0) : 0
  const isCartOverlayOpen = numberOfItems > 0
  const [isCartBreakdownExpanded, setIsCartBreakdownExpanded] = useState(true)
  const {cartOverlayRef, position} = useGetKickbackButtonPosition({
    isCartOverlayOpen,
    isCartBreakdownExpanded,
    numberOfItems,
  })

  if (!eventData || !eventTicketsData || !(isEventFlyerLoaded || imageDownloadedWithError)) return <></>

  const {event, group} = eventData
  const {eventTickets, hasPasswordProtectedTickets, totalTicketsSold} = eventTicketsData
  const {
    isAttendee,
    isAwaitingApproval,
    lightmode,
    venue: eventVenue,
    song,
    accentColor,
    displayGroupName,
    cartId: attendeeCartId,
    isRSVPEvent,
    isPersonalEvent,
    isApprovalOnlyRSVPEvent,
    youtubeLink,
  } = event

  const eventDescriptionWordCountOverage = event.description && event.description?.split(' ').length >= 200
  const shortEventDescription = event.description?.split(' ').slice(0, 200).join(' ') + '......'

  if (
    eventDescriptionWordCountOverage &&
    !eventDescriptionCapped &&
    showViewMoreSection &&
    !event.expandedDescription
  ) {
    setEventDescriptionCapped(true)
  }

  const shouldShowGuestList = determineShouldShowGuestList(event.guestlistEnabled, guestlist?.length ?? 0)

  const colorModeModifier = lightmode ? 'is-lightMode' : 'is-darkMode'

  const shouldShowVenueImages = (() => {
    if (!event.venue.images?.length) return false
    else return true
  })()

  const handleCheckoutRedirect = async () => {
    const ticketsCountWithQuantity = Object.values(ticketsCount).filter(ticket => ticket.quantity > 0)
    const cartItems = generateCartItems({ticketsCountWithQuantity, tables})
    const dbCartParams = {
      eventId: event.id,
      items: cartItems,
      affiliatePublicId: savedAffiliateLink ? savedAffiliateLink : undefined,
      trackingLink: savedTrackingLink ? savedTrackingLink : undefined,
    }
    try {
      const {cartId} = await createCart(dbCartParams)
      if (cartId) {
        setCartId(cartId)
        navigate(
          getCheckoutUrl({
            cartId,
            trackingLink: savedTrackingLink,
            affiliateTrackingLink: savedAffiliateLink,
            eventUrl,
          }),
        )

        trackCreateCart(ticketsCount, event, group, currentUser)
      }
    } catch (error) {
      showToast(parseErrorForToast(error))
    }
  }

  const shouldShowPassword = () => {
    if (event.passwordProtected && isLocked) {
      if (userId && (event.attending.includes(userId) || group.owners.includes(userId))) {
        return false
      } else return true
    }
    return false
  }

  const formattedDates = getEventPageDateDisplay(
    event.startUtc,
    event.endUtc,
    event.timezone,
    event.displayEndTime ?? true,
  )

  const now = new Date()
  const eventIsOver = moment(event.endUtc).isBefore(moment(now.toUTCString()))
  const eventIsDraft = !isUndefined(event.status) && event.status === 'draft'
  if (event.ttPixelId) {
    const tikTokScript = document.createElement('script')
    tikTokScript.setAttribute('type', 'text/javascript')
    const tta = document.createTextNode(
      `!function (w, d, t) {w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};ttq.load('${event.ttPixelId}');ttq.page();}(window, document, 'ttq');`,
    )
    tikTokScript.appendChild(tta)
    document.head.appendChild(tikTokScript)
  }

  if (event.geniusMonkeyId) {
    const geniusMonkeyScript = document.createElement('script')
    geniusMonkeyScript.setAttribute('type', 'text/javascript')
    geniusMonkeyScript.setAttribute('async', 'async')
    geniusMonkeyScript.setAttribute('src', `https://pm.geniusmonkey.com/gm.js?id=${event.geniusMonkeyId}&z=1`)
    document.head.appendChild(geniusMonkeyScript)
  }

  const backgroudColor =
    colorModeModifier == 'is-darkMode'
      ? `linear-gradient(-45deg, #0a0b0d, ${accentColor}23, #161719, #0a0b0d, ${accentColor}3e, #0a0b0d, #262729, ${accentColor}0e, #0a0b0d)`
      : `linear-gradient(-45deg, #fafbfd, ${accentColor}23, #f6f7f9, #fafbfd, ${accentColor}5e, #fafbfd, #f6f7f9, ${accentColor}0e, #fafbfd)`

  const backgroundSize = '500% 500%'

  const shouldShowToolBar = isRSVPEvent && isOwner

  const rsvpCheckoutIsEnabled = !(isRSVPModalOpen || isEventDataFetching || eventIsDraft)
  const rsvpCheckoutButtonText = (() => {
    if (eventIsDraft) return 'This event is a draft'
    if (isApprovalOnlyRSVPEvent) {
      return 'GET ON THE LIST'
    } else return 'RSVP'
  })()
  const rsvpCheckoutButtonBackgroundColor = (() => {
    return rsvpCheckoutIsEnabled ? accentColor : '#bbbbbb'
  })()
  const rsvpCheckoutButtonTextColor = (() => {
    if (!rsvpCheckoutIsEnabled) return '#888888'
    if (colorModeModifier === 'is-darkMode') return '#0a0b0d'
    return '#fff'
  })()
  const handleRSVPCheckout = async () => {
    try {
      const {guestlistEnabled, guestlistNumber, spotifySong, lineup, youtubeVideo, lightOrDarkMode} = mixpanelProps

      trackMixpanelEvent('RSVP Button-  Event Page', {
        guestlistEnabled,
        guestlistNumber,
        spotifySong,
        lineup,
        lightOrDarkMode,
        youtubeVideo,
      })
      setIsRSVPModalOpen(true)
      if (event.fbPixelId) trackCreateCart(ticketsCount, event, group, currentUser)
      const ticketsCountWithQuantity = [{data: eventTickets[0].ticket, quantity: 1}]
      const cartItems = generateCartItems({ticketsCountWithQuantity})
      const dbCartParams = {
        eventId: event.id,
        items: cartItems,
        affiliatePublicId: savedAffiliateLink ? savedAffiliateLink : undefined,
        trackingLink: savedTrackingLink ? savedTrackingLink : undefined,
      }
      const {cartId} = await createCart(dbCartParams)
      setCartId(cartId)
    } catch (error) {
      showToast(parseErrorForToast(error))
      setIsRSVPModalOpen(false)
    }
  }

  const rsvpTicket = eventTickets.length ? eventTickets[0].ticket : null

  const rsvpEventIsSoldOut = isRSVPEvent && rsvpTicket && rsvpTicket.quantityAvailable < 1
  const eventRSVPAreClosed = isRSVPEvent && rsvpTicket && rsvpTicket.closed === true
  const isRSVPButtonDisabled =
    isAttendee || isAwaitingApproval || rsvpEventIsSoldOut || eventRSVPAreClosed || eventIsOver || !rsvpTicket
  const showRSVPButton = isRSVPEvent && !isRSVPButtonDisabled
  const disabledRSVPButtonText = (() => {
    if (rsvpEventIsSoldOut) {
      return 'RSVPs Full'
    } else if (isAttendee) {
      return "You RSVP'd!"
    } else if (!rsvpTicket) {
      return 'RSVPs not available'
    } else if (isAwaitingApproval) {
      return 'You requested to attend.'
    } else if (eventRSVPAreClosed) {
      return 'RSVPs Closed for Event'
    } else if (eventIsOver) {
      return 'This event is over'
    } else {
      return 'RSVPs not available'
    }
  })()

  const keepTicketSectionPadding =
    (unlockedTickets && unlockedTickets.length > 0) ||
    (event.ticketGroups && event.ticketGroups.length > 0) ||
    (hasPasswordProtectedTickets && !eventIsOver) ||
    eventIsOver ||
    eventIsDraft

  const redirectToLogin = () => {
    navigate(`/login?r=${encodeURIComponent(window.location.pathname)}`)
  }

  const shouldShowGuestListProfile = (avi: string, index: number) => {
    if (index < 8 && avi !== 'https://images.posh.vip/b2/default-avi.jpg') return true
    else return false
  }

  const onCloseRSVPCheckoutModal = async () => {
    setIsRSVPModalOpen(false)
    await refetchEventData()
    setCartId(null)
    if (profilePreviewModal.profile) {
      setProfilePreviewModal(modal => ({...modal, isOpen: true}))
    }
  }

  const shouldShowPaymentPlansBadge =
    !isUndefined(event.paymentPlans) &&
    event.paymentPlans.length > 0 &&
    event.isPaymentPlanEligible === true &&
    !savedAffiliateLink

  const longEventTitle = event.name.length > 68

  const mixpanelProps = {
    guestlistEnabled: event.guestlistEnabled,
    guestlistNumber: guestlist?.length ?? 0,
    spotifySong: !!event.song,
    lineup: event.lineup?.length ?? 0,
    purchaseOrRSVPAndPurchase: eventTicketsData?.eventTickets.some(t => t.ticket.price === 0) ?? false,
    kickbackEnabled: !!kickbackAmount,
    youtubeVideo: !!event.youtubeLink,
    lightOrDarkMode: lightmode ? 'light' : 'dark',
  }

  const isCircularStd = event.eventTitleFont === 'Inter' || !event.eventTitleFont
  const fontWeight = isCircularStd ? '900' : '400'
  const letterSpacing = !isCircularStd || longEventTitle ? '-1px' : '-3px'

  const shareableUrl = `${baseURL}/e/${eventData.event.url}${window.location.search}`

  const palette = getEventPagePalette({lightMode: lightmode ?? false, accentColor: event.accentColor ?? 'white'})

  return (
    <EventPageContextProvider palette={palette} eventId={event.id}>
      <CustomGTMTracking containerId={event.gtmContainerId} />
      <Helmet>
        <title>{`${eventData.event.name} - ${displayGroupName ? displayGroupName : group.name}`}</title>
        <meta name='description' content={eventData.event.description} />
        <meta itemProp='name' content={eventData.event.name} />
        <meta itemProp='image' content={getEventFlyer(eventData.event)} />
        <meta property='og:title' content={eventData.event.name} />
        <meta property='og:description' content={eventData.event.name} />
        <meta property='og:image' content={getEventFlyer(eventData.event)} />
        <meta property='og:url' content={shareableUrl} />
        <meta property='og:type' content='website' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={eventData.event.name} />
        <meta property='twitter:domain' content='posh.vip' />
        <meta property='twitter:url' content={shareableUrl} />
        <meta name='twitter:image' content={getEventFlyer(eventData.event)} />
        <meta name='twitter:description' content={eventData.event.name} />
        <meta name='google' content='notranslate' />
        <link rel='canonical' href={shareableUrl} />
      </Helmet>
      {shouldShowPassword() ? (
        <EventPagePassword event={event} setIsLocked={setIsLocked} />
      ) : event.status === 'deleted' ? (
        <DeletedEvent />
      ) : (
        <>
          <div className={'pageContainer ' + colorModeModifier}>
            <div className='loading'>
              <PoshLogo.Animated size='lg' />
            </div>
            <div
              className={'EventPage ' + colorModeModifier}
              style={{
                background: backgroudColor,
                backgroundSize: backgroundSize,
              }}>
              <div className='EventPage-container'>
                <EventTitleFontStyleProvider eventTitleFont={event.eventTitleFont} />
                {isRSVPEvent && (
                  <>
                    <PoshImageBackground
                      className={'EventPage-bg fixed ' + colorModeModifier}
                      style={{backgroundImage: `url(${getEventFlyer(event)})`}}
                    />
                    <div className='pageContainer-confetti'>
                      <Confetti
                        width={width}
                        height={height}
                        colors={[accentColor]}
                        gravity={0.01}
                        numberOfPieces={40}
                        recycle={false}
                      />
                    </div>
                  </>
                )}

                <div className={`EventPage-bgContainer ${isRSVPEvent ? 'rsvp' : ''} ` + colorModeModifier}>
                  {!isRSVPEvent && (
                    <PoshImageBackground
                      className={'EventPage-bg ' + colorModeModifier}
                      style={{backgroundImage: `url(${getEventFlyer(event)})`}}
                    />
                  )}

                  <div className='EventPage-headerContainer'>
                    <div
                      className={'EventPage-infoContainer ' + colorModeModifier}
                      style={{borderColor: `${accentColor}`}}>
                      <div className='EventPage-eventImageWrapper'>
                        <PoshImage className='EventPage-eventImage' src={getEventFlyer(event)} />
                        {song && song.previewLink && song.previewLink.length > 0 && (
                          <SongPlayer
                            isLightMode={lightmode ?? false}
                            accentColor={accentColor}
                            previewLink={song.previewLink}
                            name={song.name}
                            artist={song.artist}
                          />
                        )}
                      </div>
                      <div className='EventPage-detailTextsContainer'>
                        {!isPersonalEvent && (
                          <div className='EventPage-groupDetailContainer'>
                            {group.aviLocation && (
                              <PoshImage
                                className='EventPage-avatar'
                                src={
                                  eventData.event.displayGroupImage
                                    ? eventData.event.displayGroupImage
                                    : getGroupAvi(group)
                                }
                                alt='Avatar'
                              />
                            )}
                            <span
                              className={'EventPage-groupName ' + colorModeModifier}
                              onClick={() => navigate(`/g/${group.url}`)}>
                              {displayGroupName ? displayGroupName : group.name}
                              {group?.verified && <VerifiedGroupBadge size={18} />}
                            </span>
                          </div>
                        )}
                        <h1
                          className={
                            longEventTitle
                              ? 'EventPage-name-smaller ' + colorModeModifier
                              : 'EventPage-name ' + colorModeModifier
                          }
                          style={{
                            color: `${lightmode ? accentColor : '#FAFAFC'}`,
                            fontFamily: getEventTitleFont(event.eventTitleFont) ?? 'Inter',
                            fontWeight,
                            letterSpacing,
                          }}>
                          {event.name}
                        </h1>

                        <p className='m-0 text-xl' style={{color: `${accentColor}`}}>
                          {eventVenue.name}
                        </p>

                        <p className={`m-0 text-xl`} style={{color: `${accentColor}`}}>
                          {formattedDates}
                        </p>

                        <EventInfoTable
                          event={event}
                          hostName={eventData.hostName}
                          isPersonalEvent={isPersonalEvent}
                          shouldShowRSVPToViewLocation={isApprovalOnlyRSVPEvent && !isAttendee && showRSVPButton}
                          group={group}
                          handleRSVPCheckout={handleRSVPCheckout}
                          isAwaitingApproval={isAwaitingApproval}
                        />

                        {event.shortDescription && (
                          <span
                            className={
                              event.shortDescription.length > 185
                                ? 'EventPage-shortDescription-smaller ' + colorModeModifier
                                : 'EventPage-shortDescription ' + colorModeModifier
                            }>
                            {event.shortDescription}
                          </span>
                        )}
                        {shouldShowGuestList && totalTicketsSold && (
                          <div className='EventPage-preview-list'>
                            {isLoadingGuestlist ? (
                              <SpinLoader height={30} />
                            ) : (
                              <UserListPreview>
                                {guestlist?.map(
                                  (guest, index) =>
                                    shouldShowGuestListProfile(guest.attendeeAvi, index) && (
                                      <UserListPreview.User
                                        key={index}
                                        imageSrc={guest.attendeeAvi}
                                        alt={`${guest.attendeeFirstName} ${guest.attendeeLastName}'s Posh profile picture`}
                                      />
                                    ),
                                )}
                              </UserListPreview>
                            )}
                            {!event.attendanceDisplayDisabled && (
                              <p className='EventPage-totalAttending' style={{color: `${accentColor} `}}>
                                {totalTicketsSold ?? 0}+ Going
                              </p>
                            )}
                          </div>
                        )}
                        <div className='flex flex-row justify-start'>
                          <EventSeriesSignifier mse={event.multiSessionEvent} source='event_page' />
                        </div>
                        {showRSVPButton && (
                          <div className='pt-5'>
                            <Button
                              onClick={() => handleRSVPCheckout()}
                              style={{
                                color: rsvpCheckoutButtonTextColor,
                                background: rsvpCheckoutButtonBackgroundColor,
                              }}
                              className='EventPage-rsvpBtn'
                              disabled={!rsvpCheckoutIsEnabled}>
                              {isRSVPModalOpen || isEventDataFetching ? (
                                <Loader type='TailSpin' color={rsvpCheckoutButtonTextColor} width={18} height={18} />
                              ) : (
                                <>{rsvpCheckoutButtonText}</>
                              )}
                            </Button>
                          </div>
                        )}
                        {isRSVPEvent && isRSVPButtonDisabled && (
                          <Button
                            className='EventPage-rsvpBtn attending'
                            style={{
                              color: accentColor,
                              background: colorModeModifier == 'is-darkMode' ? '#0a0b0d' : '#fff',
                            }}>
                            {disabledRSVPButtonText}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  {!currentUser && (
                    <div className='EventPage-bgContainer-loginBtn'>
                      <Button className={lightmode ? '' : 'black'} onClick={redirectToLogin}>
                        Login
                      </Button>
                    </div>
                  )}
                </div>

                <div className='EventPage-sections'>
                  {isRSVPEvent && isAttendee && (
                    <AttendeeActions
                      event={event}
                      accentColor={accentColor}
                      colorModeModifier={colorModeModifier}
                      attendeeCartId={attendeeCartId}
                      groupName={group.name}
                    />
                  )}

                  {!eventIsOver && !isRSVPEvent && (
                    <EventSection
                      accentColor={accentColor}
                      className={`${!keepTicketSectionPadding ? 'noPad' : ''} noMinHeight`}>
                      <div className='EventPage-ticketSection' ref={ticketSectionRef}>
                        {shouldShowPaymentPlansBadge && (
                          <div
                            style={{
                              background: lightmode ? accentColor : 'black',
                              boxShadow: lightmode ? `0 0 3px -1px ${accentColor}` : '0 0 3px -1px #fc0',
                              border: lightmode ? `1px solid ${accentColor}` : '1px solid #fc0',
                            }}
                            className={'EventPage-paymentPlanBadge ' + colorModeModifier}>
                            Posh Payment Plans Available At Checkout!
                          </div>
                        )}
                        {event.ticketGroups && event.ticketGroups.length > 0 && !eventIsDraft && !eventIsOver && (
                          <>
                            {event.ticketGroups?.map((tg, index) => (
                              <TicketGroup
                                tg={tg}
                                key={index}
                                event={event}
                                trackingLink={savedTrackingLink}
                                currency={group.currency}
                                affiliateTrackingLink={savedAffiliateLink}
                                eventIsOver={eventIsOver}
                                eventTickets={eventTickets}
                                colorModeModifier={colorModeModifier}
                              />
                            ))}
                          </>
                        )}
                        {unlockedTickets && (
                          <TicketsContainer accentColor={accentColor} lightmode={lightmode}>
                            {!eventIsDraft &&
                              !eventIsOver &&
                              unlockedTickets.map((t, i) => (
                                <TicketActionRow
                                  key={i}
                                  ticket={t.ticket}
                                  eventId={event.id}
                                  index={i}
                                  trackingLink={savedTrackingLink}
                                  currency={group.currency}
                                  ticketsAvailableMessage={t.ticketsAvailableMessage}
                                  accentColor={accentColor}
                                  lightmode={lightmode}
                                  showFeesInPrice={event.showFeesInPrice}
                                  longFormAddToCartButton={isMobile ? event.longFormAddToCartButton : true}
                                />
                              ))}
                            {eventIsOver && !eventIsDraft && (
                              <div>
                                <p style={{color: accentColor}} className='EventPage-eventEndedText'>
                                  This event has ended, you cannot purchase tickets for it anymore.
                                </p>
                              </div>
                            )}
                            {eventIsDraft && (
                              <div>
                                <p style={{color: accentColor}} className='EventPage-eventEndedText'>
                                  This event is still a draft, you cannot purchase tickets for it yet.
                                </p>
                              </div>
                            )}
                          </TicketsContainer>
                        )}
                        {hasPasswordProtectedTickets && !eventIsOver && (
                          <form
                            className='EventPage-ticketPasswordForm'
                            onSubmit={e => {
                              e.preventDefault()
                              setIsUnLockingTickets(true)
                              trackMixpanelEvent('Unlock Hidden Tickets-  Event Page')
                              refetchTickets()
                              setTimeout(() => {
                                setIsUnLockingTickets(false)
                              }, 700)
                            }}>
                            {ticketPasswordErrorMessage && (
                              <div className='EventPage-errorMessageCont'>
                                <p className='EventPage-errorMessage'>{ticketPasswordErrorMessage}</p>
                              </div>
                            )}
                            <div
                              className='EventPage-ticketPasswordContainer'
                              style={{
                                borderColor: `${accentColor}`,
                                boxShadow: `0 0 20px -8px ${accentColor}`,
                              }}>
                              <input
                                className={colorModeModifier}
                                placeholder='Unlock Hidden Tickets'
                                type='text'
                                value={ticketPassword}
                                onChange={e => setTicketPassword(e.target.value)}
                              />
                              <PoshImage src='' />
                              <button
                                type='submit'
                                className={
                                  'EventPage-ticketPasswordContainer-passwordInputButton ' + colorModeModifier
                                }>
                                <PoshImage src='https://images.posh.vip/b2/open-padlock.svg' />
                              </button>
                            </div>
                            <span
                              style={{visibility: isUnLockingTickets ? 'visible' : 'hidden'}}
                              className='EventPage-ticketPasswordContainer-unlockingText'>
                              Unlocking...
                            </span>
                          </form>
                        )}
                      </div>
                    </EventSection>
                  )}

                  {youtubeLink && <YoutubeIFrame url={youtubeLink} accentColor={accentColor} />}
                  {event.tables && venue?.venueMap && event.tables.length !== 0 && (
                    <>
                      <EventSection colorModeModifier={colorModeModifier} accentColor={accentColor} header={'Tables'}>
                        {event.tableFilterKeys && event.tableFilterKeys.length > 0 && (
                          <TableFilter
                            selectedFilter={selectedFilter}
                            setSelectedFilter={setSelectedFilter}
                            isLightMode={lightmode ?? false}
                            accentColor={accentColor}
                            filterKeys={event.tableFilterKeys}
                          />
                        )}
                        {event.tables && venue?.venueMap && event.tables.length !== 0 && (
                          <div className='EventPage-tableContainer'>
                            <div className={'EventPage-tableSection venueMap ' + colorModeModifier}>
                              <InteractiveVenueMap
                                venueMap={venue.venueMap}
                                decorations={venue?.decorations ?? []}
                                tables={tablesToDisplay}
                                onTableClick={tables => {
                                  const tablesToAdd = tables.filter(t => !t.disabled && !t.purchaseData).map(t => t._id)
                                  addTables(tablesToAdd)
                                }}
                                isLightMode={lightmode ?? false}
                                isPurchasing
                                boxShadow={`0 0 30px -5px ${accentColor}`}
                                selectedFilterKey={selectedFilter}
                              />
                            </div>
                          </div>
                        )}
                        <div className='TableLegend'>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <button className='TableItem available static' />
                                </td>
                                <td>Available</td>
                              </tr>
                              <tr>
                                <td>
                                  <button className='TableItem booked static' />
                                </td>
                                <td>Booked</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </EventSection>
                    </>
                  )}

                  {event.description && (
                    <EventSection
                      colorModeModifier={colorModeModifier}
                      accentColor={accentColor}
                      className='noMinHeight'>
                      <div className={'EventPage-descriptionSection ' + colorModeModifier}>
                        {eventDescriptionCapped ? (
                          <>
                            <div className='EventPage-descriptionSection-capped'>
                              <SafeMarkdownDisplay source={shortEventDescription} />
                            </div>
                            <div
                              style={{color: accentColor}}
                              className='EventPage-descriptionSection-view-more'
                              onClick={() => {
                                setEventDescriptionCapped(false)
                                setShowViewMoreSection(false)
                              }}>
                              View More
                            </div>
                          </>
                        ) : (
                          <div>
                            <SafeMarkdownDisplay source={event.description} />
                            {eventDescriptionWordCountOverage && !event.expandedDescription && (
                              <div
                                style={{color: accentColor}}
                                className='EventPage-descriptionSection-view-less'
                                onClick={() => {
                                  setShowViewMoreSection(true)
                                }}>
                                View Less
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </EventSection>
                  )}

                  {shouldShowGuestList && (
                    <EventSection
                      colorModeModifier={colorModeModifier}
                      accentColor={accentColor}
                      header={'Guestlist'}
                      className='noPad bordered'>
                      {isLoadingGuestlist ? (
                        <SpinLoader height={80} />
                      ) : (
                        <Guestlist
                          guestlistProfiles={guestlist ?? []}
                          colorModeModifier={colorModeModifier}
                          isLightMode={lightmode ?? false}
                          event={event}
                          setProfilePreviewModal={setProfilePreviewModal}
                        />
                      )}
                    </EventSection>
                  )}

                  <EventPageLineupSection
                    event={event}
                    onClickItem={item =>
                      trackMixpanelEvent('View Artist-  Event Page', {
                        artistName: item.name,
                        artistLink: item.link,
                      })
                    }
                  />
                  {shouldShowVenueImages && (
                    <VenueImages venue={eventVenue} lightMode={lightmode} accentColor={accentColor} />
                  )}

                  {event.multiSessionEvent && (
                    <MultisessionEventFeatureFlag.Public>
                      <EventSection accentColor={accentColor} header={'More Dates'}>
                        <MultiSessionMoreDatesSignifier mse={event.multiSessionEvent} eventId={event.id} />
                      </EventSection>
                    </MultisessionEventFeatureFlag.Public>
                  )}

                  <EventFooter
                    group={group}
                    accentColor={accentColor}
                    colorModeModifier={colorModeModifier}
                    event={event}
                    isPersonalEvent={isPersonalEvent}
                  />
                  {kickbackAmount && !isFetchingKickbackAmount && (
                    <>
                      <div className={`KickbackButtonOutline ${lightmode ? 'lightmode' : ''}`} style={position}>
                        <div
                          onClick={() => {
                            trackMixpanelEvent('Kickback Icon Button-  Event Page')
                            setOptimizelyUserCustomAttribute('Opt.EventPageKickbackClicks')
                            setIsKickbackModalOpen(true)
                          }}
                          className='KickbackButton'>
                          <KickbackIcon
                            size={35}
                            primaryColor={lightmode ? 'black' : 'white'}
                            secondaryColor={lightmode ? 'white' : 'black'}
                            rotation={90}
                          />
                          {kickbackAmount}
                        </div>
                      </div>
                      <KickbackModal
                        isOpen={isKickbackModalOpen}
                        onClose={() => setIsKickbackModalOpen(false)}
                        eventId={event.id}
                        shouldShowButton={eventData.event.isAttendee}
                        kickbackAmount={kickbackAmount}
                        onClickNavigateToPurchaseTickets={() => {
                          setIsKickbackModalOpen(false)
                          ticketSectionRef.current?.scrollIntoView({behavior: 'smooth'})
                        }}
                        isAttendee={isAttendee}
                      />
                    </>
                  )}
                  {!isRSVPEvent && isCartOverlayOpen && (
                    <CartOverlay
                      ref={cartOverlayRef}
                      isLightMode={lightmode}
                      cartItems={cartItems}
                      numberOfItems={numberOfItems}
                      unlockedTickets={eventTicketsData?.eventTickets.map(t => t.ticket) ?? []}
                      accentColor={accentColor}
                      handleCheckoutRedirect={handleCheckoutRedirect}
                      currency={group.currency}
                      showEventFees={event.showFeesInPrice}
                      eventTables={event.tables}
                      eventId={event.id}
                      groupId={group._id}
                      onChangeIsCartBreakdownExpanded={setIsCartBreakdownExpanded}
                      onCheckout={({total, itemsWithInfo, currency}) => {
                        const {
                          guestlistEnabled,
                          guestlistNumber,
                          spotifySong,
                          lineup,
                          purchaseOrRSVPAndPurchase,
                          kickbackEnabled,
                          youtubeVideo,
                        } = mixpanelProps
                        const isLightMode = colorModeModifier === 'is-lightMode'
                        const numberOfItems = itemsWithInfo.length

                        return trackMixpanelEvent('Checkout Clicked-  Event Page', {
                          cartItems: numberOfItems,
                          cartTotal: total,
                          currency: currency,
                          guestlistEnabled: guestlistEnabled,
                          guestlistNumber: guestlistNumber,
                          spotifySong: spotifySong,
                          lineup: lineup,
                          lightOrDarkMode: isLightMode ? 'light' : 'dark',
                          purchaseOrRSVPAndPurchase: purchaseOrRSVPAndPurchase ? 'RSVP and Purchase' : 'Purchase',
                          lowestTicketPrice: itemsWithInfo.reduce(
                            (min, p) => (p.info.price < min ? p.info.price : min),
                            itemsWithInfo[0].info.price,
                          ),
                          kickbackEnabled: kickbackEnabled,
                          youtubeVideo: youtubeVideo,
                        })
                      }}
                    />
                  )}
                  {showRSVPButton && (
                    <div className='RSVPOverlay'>
                      <Button
                        onClick={() => handleRSVPCheckout()}
                        style={{
                          color: colorModeModifier == 'is-darkMode' ? '#0a0b0d' : '#fff',
                          background: accentColor,
                        }}>
                        {isRSVPModalOpen ? (
                          <Loader
                            type='TailSpin'
                            color={colorModeModifier == 'is-darkMode' ? '#0a0b0d' : '#fff'}
                            width={18}
                            height={18}
                          />
                        ) : isApprovalOnlyRSVPEvent ? (
                          'GET ON THE LIST'
                        ) : (
                          'RSVP'
                        )}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {shouldShowToolBar && <EventPageToolbar eventId={event.id} groupId={group._id} />}
            <EditProfilePictureModal
              isOpen={shouldShowAddProfilePicture}
              onClose={() => setShouldShowAddProfilePicture(false)}
            />
            {checkoutSummary && cartId && isRSVPEvent && (
              <LiveCartContextProvider checkoutSummary={checkoutSummary} cartId={cartId} isRSVP>
                <RSVPModal
                  noborder
                  isOpen={isRSVPModalOpen}
                  onClose={onCloseRSVPCheckoutModal}
                  rsvpButtonText={isApprovalOnlyRSVPEvent ? 'GET ON THE LIST' : undefined}
                />
              </LiveCartContextProvider>
            )}
            {profilePreviewModal.profile && (
              <ProfilePreviewModal
                width={420}
                noborder
                isOpen={profilePreviewModal.isOpen}
                onClose={onCloseProfilePreviewModal}
                previewProfile={profilePreviewModal.profile}
                isViewerAttendingEvent={isAttendee || isOwner || isAdmin}
                isViewingInRSVPEvent={isRSVPEvent || isOwner || isAdmin}
                eventId={event.id}
                rsvpCheckout={handleRSVPCheckout}
              />
            )}
          </div>
        </>
      )}
    </EventPageContextProvider>
  )
}

const EventPage = ({eventUrlProps}: {eventUrlProps?: string}) => {
  const {eventUrl} = useParams<{eventUrl: string}>()
  const {data: eventData, isFetching: eventDataIsFetching} = useFetchEventByUrl(eventUrlProps ?? eventUrl)
  const {accountRoleGroupIdMap, userId, currentUser} = useSessionContext()
  const navigate = useNavigate()

  useUnauthenticateIfExpiredToken()
  const isOwner = () => {
    const groupId = eventData?.group?._id
    if (!groupId) return false
    const accountRole = accountRoleGroupIdMap ? accountRoleGroupIdMap[groupId] : undefined
    if (accountRole) return accountRole.roleKey === 'owner'
    return false
  }

  const isAdmin = () => {
    const eventId = eventData?.event?.id
    const groupId = eventData?.group?._id
    if (!groupId || !eventId) return false
    const accountRole = accountRoleGroupIdMap
      ? accountRoleGroupIdMap[groupId] || accountRoleGroupIdMap[eventId]
      : undefined
    if (accountRole) return accountRole.roleKey === 'admin'
    return false
  }

  if (!eventData && !eventDataIsFetching) return <PageNotFound />
  if (eventData && eventData.event.status === 'draft' && !isOwner() && !isAdmin()) return <PageNotFound />

  return (
    <>
      <CustomGTMTracking containerId={eventData?.event.gtmContainerId} />
      {!isOwner() && userId && <PoshHeader isHalfTranslucent desktopOnly />}
      <Routes>
        <Route
          path=''
          element={<EventPageIndex eventUrlProps={eventUrlProps} isOwner={isOwner()} isAdmin={isAdmin()} />}
        />
        <Route path={'*'} element={<PageNotFound />} />
      </Routes>
      {userId && (
        <PoshImage
          src={getAvi(currentUser)}
          className='dashboardAviLink'
          onClick={() => navigate('/dashboard/account')}
        />
      )}
    </>
  )
}

export default EventPage
