import React, {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

import {DEFAULT_ACCENT_COLOR} from '@posh/types'
import {GetEventForVisualEditorOutput, useGetEventForVisualEditor} from 'apis/Events/useGetEventForVisualEditor'
import {transformGetEventForVisualEditorOutputToUpdateEventInput, useUpdateEvent} from 'apis/Events/useUpdateEvent'
import {
  EventVisualsForm,
  getEventVisualsAttributes,
} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {useToast} from 'components/toasts/ToastProvider'
import {useResourcePageParams} from 'pages/PoshAppLayout'
import PoshLoaderTransition from 'pages/PoshLoaderTransition'

import {EventVisualsContextProvider} from './components/context/EventVisualsContext'
import {ActionRowSaveButtonProps, EventVisualsActionRow} from './components/page-sections/ActionRow'
import {EventVisualsBackgroundImage} from './components/page-sections/BackgroundImage'
import {EventVisualsCustomSections} from './components/page-sections/CustomSections'
import {EventDetailsFormSection} from './components/page-sections/EventDetails'
import {EventFlyerColorSongSection} from './components/page-sections/EventFlyer/EventFlyerColorSongSection'
import {EventSettingsSection} from './components/page-sections/EventSettings'
import {EventVisualsGroupDisplaySettingsSection} from './components/page-sections/GroupDisplay'
import {EventVisualsGuestlistSection} from './components/page-sections/GuestListSection/EventVisualsGuestlistSection'
import {EventVisuals} from './components/page-sections/Layout'

export const UPGRADED_VISUAL_EDITOR_FEATURE_FLAG = 'web_visual_editor_upgrade'

import {VisualEditorWrapper} from './components/page/VisualEditorWrapper'
import {fieldsToTriggerAskToUpdateSeries, UpdateSeriesEventsModal} from './components/UpdateSeriesEvents'

export const getVisualEditorUrl = (role: string, groupId: string, eventId: string) =>
  `/${role}/groups/${groupId}/events/${eventId}/visuals`

export const useNavigateToVisualEditor = (role = 'owner') => {
  // 'owner' doesn't matter here, it can literally be anything - 'foo' works for example
  const navigate = useNavigate()
  return (groupId: string, eventId: string) => navigate(getVisualEditorUrl(role, groupId, eventId))
}

function VisualEditor({event, refetchEvent}: {event: GetEventForVisualEditorOutput; refetchEvent: () => void}) {
  const navigate = useNavigate()

  const {showToast} = useToast()

  const [isAskUpdateEventsInSeriesModalOpen, setIsAskUpdateEventsInSeriesModalOpen] = useState(false)

  const {
    mutate: updateEvent,
    isLoading: isSaving,
    isSuccess: hasUpdatedEvent,
  } = useUpdateEvent({
    onSuccess: () => {
      showToast({
        type: 'success',
        title: 'Successfully updated your event!',
      })
      refetchEvent()
      setIsAskUpdateEventsInSeriesModalOpen(false)
    },
    onError: error => {
      showToast({
        type: 'error',
        title: 'Something went wrong!',
        subtitle: error.message,
      })
    },
  })

  const form = useForm<EventVisualsForm>({
    defaultValues: transformGetEventForVisualEditorOutputToUpdateEventInput(event),
    mode: 'onChange',
  })
  const {
    control,
    watch,
    handleSubmit,
    formState: {dirtyFields, isValid},
    reset,
  } = form
  useEffect(() => {
    reset(transformGetEventForVisualEditorOutputToUpdateEventInput(event))
  }, [event])

  const isDirty = Object.keys(dirtyFields).length > 0

  const {flyer, lightmode, accentColor, eventTitleFont, hasFilledNonControlledFields} = getEventVisualsAttributes(watch)

  const shouldShowUpdateAllEventsInSeries = useMemo(
    () => Object.keys(dirtyFields).some(field => fieldsToTriggerAskToUpdateSeries.has(field)),
    [JSON.stringify(dirtyFields)],
  )

  const submit = handleSubmit(d => updateEvent(d))

  const onSave = () => {
    if (event.multiSessionEvent && !isAskUpdateEventsInSeriesModalOpen && shouldShowUpdateAllEventsInSeries)
      return setIsAskUpdateEventsInSeriesModalOpen(true)
    return submit()
  }

  const navigateToEvent = () => {
    navigate(`/e/${event.url}`)
  }

  const tooltipText = (() => {
    if (!isValid) return 'Please fill out the required fields'
    if (!hasFilledNonControlledFields) return 'Please add a flyer'
  })()

  const actionButton = ((): ActionRowSaveButtonProps => {
    if (hasUpdatedEvent && !isDirty)
      return {
        title: 'View Event',
        isDisabled: false,
        isLoading: false,
        className: 'success',
        onPress: navigateToEvent,
      }
    else if (isSaving)
      return {
        title: 'Saving...',
        isDisabled: true,
        isLoading: true,
        className: 'gold',
        onPress: () => {},
      }
    else
      return {
        title: 'Save Changes',
        isDisabled: !isDirty || !isValid || !hasFilledNonControlledFields,
        isLoading: isSaving,
        className: 'gold',
        onPress: onSave,
        tooltipText,
      }
  })()

  return (
    <>
      <EventVisualsContextProvider
        lightmode={lightmode ?? false}
        accentColor={accentColor ?? DEFAULT_ACCENT_COLOR}
        fontFamily={eventTitleFont}>
        <VisualEditorWrapper>
          <EventVisualsActionRow
            actionButton={actionButton}
            isDirty={isDirty}
            title='Edit Event'
            isLightMode={!!lightmode}
          />
          <EventVisualsBackgroundImage flyer={flyer}>
            <EventVisuals.Content>
              <EventVisuals.Row>
                <EventFlyerColorSongSection control={control} />
                <EventVisuals.Column>
                  <EventDetailsFormSection control={control} type='edit' />
                  <EventVisualsGroupDisplaySettingsSection
                    groupName={event.groupName}
                    groupImage={event.groupImage!}
                    control={control}
                  />
                  <EventVisualsGuestlistSection.Preview
                    attendeesCount={event.attendingCount}
                    attendees={event.guestlistPreview}
                    control={control}
                  />
                </EventVisuals.Column>
              </EventVisuals.Row>
              <EventVisuals.Row>
                <EventSettingsSection control={control} />
              </EventVisuals.Row>
              <EventVisuals.Row>
                <EventVisualsGuestlistSection.Expanded attendees={event.guestlistPreview} control={control} />
              </EventVisuals.Row>
              <EventVisualsCustomSections control={control} watch={watch} />
            </EventVisuals.Content>
          </EventVisualsBackgroundImage>
        </VisualEditorWrapper>
        <UpdateSeriesEventsModal
          isOpen={isAskUpdateEventsInSeriesModalOpen}
          onClose={() => setIsAskUpdateEventsInSeriesModalOpen(false)}
          form={form}
          event={event}
          submit={submit}
        />
      </EventVisualsContextProvider>
    </>
  )
}

export function VisualEditorPage() {
  const {eventId} = useResourcePageParams()
  const {data: event, refetch: refetchEvent} = useGetEventForVisualEditor({eventId: eventId!})

  if (!event) return <PoshLoaderTransition pageIsChanging={true} fadeOutTransition={true} />

  return <VisualEditor event={event} refetchEvent={refetchEvent} />
}
