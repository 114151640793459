import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {facebookPixelPageView} from 'apis/FbPixelHelpers'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import useSessionContext from 'domains/Auth/SessionContext'
import usePermissionsContext from 'domains/Teams/PermissionsContext'
import {handleAuthNavigation} from 'helpers/handleAuthNavigation'
import {FAQSection} from 'pages/FAQPage'

import {useNavigateToCreateEvent} from '../CreateEvent/useNavigateToCreateEvent'
import ChangingEventCard from './ChangingEventCard'
import CompareSection from './CompareSection'
import CoverSection from './CoverSection'
import EndCTASection from './EndCTA'
import Footer from './Footer'
import {HomePageComponentProps} from './interface'
import HomePageNavigation from './Navigation'
import ReviewSection from './ReviewSection'
import SectionWrapper from './SectionWrapper'
import SmsModalLandingPage from './SmsModalLandingPage'
import TransferBalanceModalLandingPage from './TransferBalanceLandingPage'

import './styles.scss'

export default function HomePage(props: HomePageComponentProps) {
  const {changePage} = props
  const {createEventUrl} = useNavigateToCreateEvent()
  const {setAccountRole} = usePermissionsContext()
  const {currentUser, accountRoles} = useSessionContext()
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  useEffect(() => {
    if (currentUser) {
      const navLink = handleAuthNavigation({
        accountRoles,
        setAccountRole,
      })
      navigate(navLink)
    }
  }, [currentUser])

  const [sectionIndex, setSectionIndex] = useState(0)

  facebookPixelPageView('151398092578382')

  return (
    <div className={`LandingPage section-${sectionIndex}`}>
      <div className='CoverSectionWrapper'>
        <SectionWrapper className='CoverSection' index={0} setSectionIndex={setSectionIndex} loadOnceMobile>
          <CoverSection changePage={changePage} />
        </SectionWrapper>
      </div>

      <SectionWrapper className='fullPageHeight' index={1} setSectionIndex={setSectionIndex}>
        <div className='LandingPage-section-inner featureSection mobileReverse'>
          <div className='featureSection-text'>
            <h3>Customizable Free (RSVP) &amp; Ticketed Event Pages</h3>
            <p>
              Brand your event pages to a T by choosing whatever colors, fonts, images, and layout best communicate your
              brand and experience. Easily allow attendees to RSVP for free or sell paid tickets tiers.
            </p>
            <Button
              className='LandingPage-btn outlineBtn white wide'
              onClick={() => {
                changePage(createEventUrl)
                trackMixpanelEvent('Create Event- Posh home page RSVP section')
              }}>
              Get Started
            </Button>
          </div>
          <div className='featureSection-feature'>
            <ChangingEventCard />
          </div>
        </div>
      </SectionWrapper>

      <SectionWrapper className='fullPageHeight' index={2} setSectionIndex={setSectionIndex}>
        <svg
          className='svg-background'
          viewBox='0 0 100 100'
          preserveAspectRatio='none'
          xmlns='http://www.w3.org/2000/svg'>
          <polygon fill='#fc0' points='0,20 100,0 100,80 0,100' />
        </svg>
        <div className='LandingPage-section-inner featureSection mobileReverse'>
          <div className='featureSection-text goldbg'>
            <h3>Unrivaled Tools to Manage &amp; Grow Your Community</h3>
            <p>
              Segment and engage attendees like never before with our integrated CRM. Organizers have seen up to 32% of
              attendees convert from a single SMS blast.
            </p>
            <Button
              className='LandingPage-btn wide dark'
              onClick={() => {
                changePage(createEventUrl)
                trackMixpanelEvent('Create Event- Posh home page Unrivaled Tools Section')
              }}>
              Get Started
            </Button>
          </div>
          <div className='featureSection-feature'>
            <SmsModalLandingPage />
          </div>
        </div>
      </SectionWrapper>

      <SectionWrapper className='fullPageHeight' index={3} setSectionIndex={setSectionIndex}>
        <div className='LandingPage-section-inner featureSection mobileReverse'>
          <div className='featureSection-text'>
            <h3>Instant Access to Capital for Organizers &amp; Affiliates</h3>
            <p>
              It&apos;s all about the money. On Posh, every stakeholder in an event gets instant access to their
              capital. Payout via bank or debit card within seconds of selling your first ticket..
            </p>
            <Button
              className='LandingPage-btn outlineBtn white wide'
              onClick={() => {
                changePage(createEventUrl)
                trackMixpanelEvent('Create Event- Posh home page Instant Access to Capital Section')
              }}>
              Get Started
            </Button>
          </div>
          <div className='featureSection-feature'>
            <TransferBalanceModalLandingPage />
          </div>
        </div>
      </SectionWrapper>

      <SectionWrapper className='fullPageHeight' index={4} setSectionIndex={setSectionIndex}>
        <div className='featureSection-featureLarge'>
          <video
            className='featureSection-feature-video'
            autoPlay
            muted
            loop
            playsInline
            controls={false}
            src='https://images.posh.vip/b3/landing-page-animations/Feature+Grid.mp4'
          />
        </div>
        <video
          className='featureSection-featureVideoMobile'
          autoPlay
          muted
          loop
          playsInline
          controls={false}
          src='https://images.posh.vip/b3/landing-page-animations/feature_grid_sq.mp4'
        />
        <div className='LandingPage-section-inner featureSection'>
          <div className='featureSection-text'>
            <h3>Everything required to scale from dinner party to music festival</h3>
            <p>
              Posh was built by experienced event curators. We know what it takes to run world-class events. Posh has
              the most advanced arsenal of event management tools on the market.
            </p>
            <Button
              className='LandingPage-btn outlineBtn white wide'
              onClick={() => {
                changePage(createEventUrl)
                trackMixpanelEvent('Create Event- Posh home page Everything required to scale section')
              }}>
              Explore
            </Button>
          </div>
        </div>
      </SectionWrapper>

      <SectionWrapper index={5} setSectionIndex={setSectionIndex} loadOnce>
        <CompareSection />
      </SectionWrapper>

      <SectionWrapper className='fullPageHeight' index={6} setSectionIndex={setSectionIndex} loadOnce>
        <ReviewSection />
      </SectionWrapper>

      <div className='LandingPage-section'>
        <div className='LandingPage-section-inner'>
          <FAQSection />
        </div>
      </div>

      <EndCTASection changePage={changePage} />
      <Footer />
      <HomePageNavigation changePage={changePage} hasBegunScrolling={false} />
    </div>
  )
}
