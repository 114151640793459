import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {EventModel} from 'apis/Events/types'
import {GetRSVPSettingsResponse, useFetchEventRsvpSettings} from 'apis/Events/useFetchRsvpSettings'
import {useSetRsvpEventSettings} from 'apis/Events/useSetRsvpSettings'
import {Checkbox} from 'components/form/checkbox/Checkbox'
import Input from 'components/form/Input'
import {useToast} from 'components/toasts/ToastProvider'
import {useTicketTransferSettings} from 'hooks/useTicketTransferSettings'
import {isEqual} from 'lodash'
import SaveDiscardBottomBar from 'pages/EventManagementPages/SaveDiscardBottomBar'

import {EventSettingCard} from '../EventSettingCard'
import {settingsTab, SettingsTabMap} from '../eventSettings'

interface EventSettingCardsProps {
  settingsTabMap: SettingsTabMap[]
  event: EventModel
}

const RSVPSettings = ({event}: {event: EventModel}) => {
  const {data: settingsData, isLoading} = useFetchEventRsvpSettings(event._id)
  const {mutateAsync: updateRsvpSettings} = useSetRsvpEventSettings()
  const [showSave, setShowSave] = useState(false)
  const {showToast} = useToast()

  const [formState, setFormState] = useState<GetRSVPSettingsResponse | undefined>(settingsData)
  const {
    toggleIsTransferable,
    canToggleTransfer,
    shouldRenderTicketTransferSwitch,
    TICKET_TRANSFER_SETTING_LABEL,
    ticketTransferLabelSubtitle,
  } = useTicketTransferSettings({
    ticketHasApprovalRequired: formState?.requireApproval ?? false,
    onToggleTransfer: val => {
      if (!formState) return
      setFormState({...formState, isTransferable: val})
    },
  })

  useEffect(() => {
    if (settingsData && !isLoading && formState) {
      if (!isEqual(settingsData, formState)) {
        setShowSave(true)
      } else {
        setShowSave(false)
      }
    }
    if (formState && !formState.totalSpots) {
      setFormState({...formState, totalSpots: 9999999})
    }
  }, [formState])

  useEffect(() => {
    setFormState(settingsData)
  }, [settingsData])

  if (!settingsData || !formState) {
    return null
  }

  const onSave = async () => {
    try {
      await updateRsvpSettings({eventId: event._id, updateData: formState})
      setShowSave(false)
      showToast({type: 'success', title: `Settings saved`})
    } catch (error) {
      setShowSave(false)
      setFormState(settingsData)
      showToast({type: 'error', title: `Something went wrong when saving settings`})
    }
  }

  const onDiscardSaveBar = () => {
    setShowSave(false)
    setFormState(settingsData)
  }
  return (
    <>
      <SaveDiscardBottomBar onDiscard={onDiscardSaveBar} onSave={onSave} isOpen={showSave} />
      <div className='EventSettings-RsvpSettings'>
        <div className='inputWrapper'>
          <Input
            label='RSVP Spots Available'
            name='spotsAvailable'
            type='number'
            placeholder='Unlimited'
            value={formState.totalSpots === 9999999 ? undefined : formState.totalSpots}
            onChange={e => {
              setFormState({...formState, totalSpots: parseFloat(e.currentTarget.value)})
            }}
          />
          <i>({settingsData.totalSpotsClaimed} claimed)</i>
        </div>
        <div className='pshCheckboxCont'>
          <Checkbox
            checked={formState.requireApproval ?? false}
            hideBackground
            label='Require Approval'
            info='Users with this ticket in their cart wont receive a QR code until you approve their order. Paid approvals expire in 7 days.'
            onChecked={checked => {
              setFormState({...formState, requireApproval: checked})
            }}
          />
          {shouldRenderTicketTransferSwitch && (
            <Checkbox
              checked={formState?.isTransferable ?? true}
              disabled={!canToggleTransfer}
              onChecked={() => toggleIsTransferable(!formState.isTransferable)}
              hideBackground
              label={TICKET_TRANSFER_SETTING_LABEL}
              info={ticketTransferLabelSubtitle}
            />
          )}
          <Checkbox
            checked={formState.closeRsvps ?? false}
            label='Close RSVPs'
            hideBackground
            info='Disallow any more RSVPs (mark the event as sold out).'
            onChecked={checked => {
              setFormState({...formState, closeRsvps: checked})
            }}
          />
          <Checkbox
            checked={formState.enableReceipts ?? true}
            label='Enable Receipts'
            hideBackground
            info='Send receipts with a QR code associated with the RSVP.'
            onChecked={checked => {
              setFormState({...formState, enableReceipts: checked})
            }}
          />
        </div>
      </div>
    </>
  )
}

export const EventSettingCards = (props: EventSettingCardsProps) => {
  const {settingsTabMap, event} = props
  const navigate = useNavigate()

  const navigateToSetting = (tab: settingsTab) => navigate(tab)

  const isRSVPEvent = event?.isRSVPEvent

  return (
    <>
      {isRSVPEvent && (
        <>
          <h4>General Settings</h4>
          <RSVPSettings event={event} />
          <h4>More Settings</h4>
        </>
      )}
      <div className='EventSettings-catCards'>
        {settingsTabMap.map(tab => {
          if (isRSVPEvent && !tab.showForRSVPEvents) return null
          return (
            <EventSettingCard
              key={tab.value}
              onClick={() => navigateToSetting(tab.value)}
              tab={tab}
              isRSVPEvent={isRSVPEvent}
            />
          )
        })}
      </div>
    </>
  )
}
