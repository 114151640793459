import React from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'

import PoshHeader from 'pages/OwnerPage/PoshHeader'
import PageNotFound from 'pages/PageNotFound/PageNotFound'

import {MultiSessionOrganizerViewNavigation} from '../components/MultiSessionOrganizerViewNavigation'
import {MultiSessionEventProvider} from '../context/ManageMultiSessionEventPagesContext'
import {ManageMultiSessionEventPage} from '../page'
import {MultiSessionAnalyticsPage} from '../page/analytics'

import styles from './styles.module.scss'

function Layout() {
  return (
    <MultiSessionEventProvider>
      <div className={styles.pageContainer}>
        <PoshHeader desktopOnly />
        <div className={styles.pageContent}>
          <div className={styles.navigationContainer}>
            <MultiSessionOrganizerViewNavigation />
          </div>
          <div className={styles.outletContainer}>
            <Outlet />
          </div>
        </div>
      </div>
    </MultiSessionEventProvider>
  )
}

export const MultiSessionEventPages = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route path='/' element={<ManageMultiSessionEventPage />} />
        <Route path='/analytics' element={<MultiSessionAnalyticsPage />} />
        <Route path={'*'} element={<PageNotFound />} />
      </Route>
    </Routes>
  )
}
