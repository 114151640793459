import {datadogLogs} from '@datadog/browser-logs'
import {datadogRum} from '@datadog/browser-rum'

import {version} from '../../../package.json'
import type {MonitoringProps} from './mount'
import type {MonitoringUser} from './setUser'

const APPLICATION_ID = process.env.DATADOG_RUM_APPLICATION_ID
const CLIENT_TOKEN = process.env.DATADOG_RUM_CLIENT_TOKEN
const RUM_SERVICE_NAME = process.env.DATADOG_RUM_SERVICE_NAME || 'posh-react-client'

const LOG_SAMPLE_RATE_PCT = 100
const RUM_REPLAY_SAMPLE_RATE_PCT = 0
const RUM_INTERACTION_SAMPLE_RATE_PCT = 3

export function mount({env}: MonitoringProps) {
  if (!APPLICATION_ID || !CLIENT_TOKEN) {
    return console.warn('Datadog monitoring is disabled for ', env)
  }

  datadogLogs.init({
    clientToken: CLIENT_TOKEN,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    sessionSampleRate: LOG_SAMPLE_RATE_PCT,
    env,
    service: RUM_SERVICE_NAME,
    version,
  })

  datadogRum.init({
    applicationId: APPLICATION_ID,
    clientToken: CLIENT_TOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: RUM_SERVICE_NAME,
    env,
    sessionSampleRate: RUM_INTERACTION_SAMPLE_RATE_PCT,
    sessionReplaySampleRate: RUM_REPLAY_SAMPLE_RATE_PCT,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    version,
    enableExperimentalFeatures: ['feature_flags'],
  })
}

export function setUser(props?: MonitoringUser) {
  if (props) {
    datadogRum.setUser(props)
  } else {
    datadogRum.clearUser()
  }
}
