import React, {createContext, PropsWithChildren, useContext} from 'react'

import {EventPagePalette} from './EventPagePalette'

// TODO: Add the tailwind theme definitions for the Event Page here.

interface EventPageContextType {
  palette: EventPagePalette
  eventId: string
  groupId?: string
}

export const EventPageContext = createContext<EventPageContextType | null>(null)

export const useEventPageContext = () => {
  const eventPageContext = useContext(EventPageContext)
  if (!eventPageContext) throw new Error('useEventPagePalette must be used within <EventPageContextProvider/>')
  return eventPageContext
}

export const EventPageContextProvider = (props: PropsWithChildren<EventPageContextType>) => {
  const {children, eventId, groupId, palette} = props

  return <EventPageContext.Provider value={{eventId, groupId, palette}}>{children}</EventPageContext.Provider>
}
