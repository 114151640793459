import React from 'react'
import {useNavigate} from 'react-router-dom'

import {useFetchAccountByUsername} from 'apis/Accounts/useFetchAccountByUsername'
import {EventModel} from 'apis/Events/types'
import IAffiliation from 'apis/Kickback/Affiliation'
import {OrderModel} from 'apis/Orders'
import {Card} from 'components/Card'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useResourcePageParams} from 'pages/PoshAppLayout'

const OrderCardAffiliation = ({
  affiliation,
  event,
  order,
}: {
  affiliation: IAffiliation
  event: EventModel
  order: OrderModel
}) => {
  const {domain, groupId} = useResourcePageParams()
  const navigate = useNavigate()

  const {data: affiliate} = useFetchAccountByUsername(affiliation.affiliatePublicId!)

  return (
    <Card
      title={'Kickback Affiliate'}
      className='OrderPage-affiliateCard'
      animationIndex={2}
      info={"This order was made through this affiliate's Kickback link."}>
      <PoshImage
        className='OrderPage-avi'
        src={
          affiliate?.avi ??
          (affiliate?.instagram?.avi != '' ? affiliate?.instagram?.avi : null) ??
          'https://images.posh.vip/b2/blank-avi.png'
        }
      />
      <div className='OrderPage-affiliateCard-name'>
        <a
          className='text-xl'
          onClick={() => navigate(`/${domain}/groups/${groupId}/marketing/attendee/${affiliate?._id}`)}>
          {affiliate?.firstName} {affiliate?.lastName}
        </a>
      </div>
      <h5>On This Order</h5>
      <table>
        <tbody>
          <tr>
            <td>Revenue Kickbacked</td>
            <td>${order.kickbackAmount?.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <h5>{event.name} Stats</h5>
      <table>
        <tbody>
          <tr>
            <td>Clicks</td>
            <td>{affiliation.clicks}</td>
          </tr>
          <tr>
            <td>Tickets Sold</td>
            <td>{affiliation.totalTickets}</td>
          </tr>
          <tr>
            <td>Revenue Generated</td>
            <td>${affiliation.revenueGenerated.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </Card>
  )
}

export default OrderCardAffiliation
