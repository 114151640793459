import React, {useEffect, useState} from 'react'

import {TicketLink} from 'apis/Events/types'
import {useCreateTicketLink} from 'apis/Events/useCreateTicketLink'
import {GetAllEventTicketsOutput, useInvalidateGetAllEventTickets} from 'apis/Events/useGetAllEventTickets'
import {useRemoveTicketLink} from 'apis/Events/useRemoveTicketLink'
import {useUpdateTicketLink} from 'apis/Events/useUpdateTicketLink'
import Button from 'components/form/Button'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {useToast} from 'components/toasts/ToastProvider'
import {useResourcePageParams} from 'pages/PoshAppLayout'

import {TicketLinkSelect} from './TicketLinkSelect'

import './styles.scss'

export interface ExpandedTicketLink extends TicketLink {
  fromTicketId: string
}

enum DisplayConditions {
  sold_out = 'sells out',
  sale_period_end = 'sale period ends',
}

enum DisplayType {
  open = 'Will be disabled until',
  show = 'Will be hidden until',
}

interface TicketLinkModalProps extends PoshStyledModalProps {
  parent: GetAllEventTicketsOutput[0]
  child: GetAllEventTicketsOutput[0]
  filteredTickets: GetAllEventTicketsOutput
}

export const TicketLinkModal = (props: TicketLinkModalProps) => {
  const {parent, child, filteredTickets, onClose} = props
  const {showToast} = useToast()
  const {invalidateGetAllEventTickets} = useInvalidateGetAllEventTickets()
  const {eventId} = useResourcePageParams()

  const onTicketLinksUpdate = async () => {
    onClose()
    await invalidateGetAllEventTickets()
  }

  const {mutateAsync: createTicketLink, isLoading: createIsLoading} = useCreateTicketLink({
    onSuccess: data => {
      onTicketLinksUpdate()
      showToast({type: 'success', title: `Successfully created ticket link.`})
    },
    onError: err => {
      showToast({type: 'error', title: `Something went wrong creating ticket link: ${err.response.data.error}`})
    },
  })

  const {mutateAsync: removeTicketLink, isLoading: removeIsLoading} = useRemoveTicketLink({
    onSuccess: data => {
      onTicketLinksUpdate()
      showToast({type: 'success', title: `Successfully removed ticket link.`})
    },
    onError: err => {
      showToast({type: 'error', title: `Something went wrong removing ticket link: ${err.response.data.error}`})
    },
  })

  const {mutateAsync: updateTicketLink, isLoading: updateIsLoading} = useUpdateTicketLink({
    onSuccess: data => {
      onTicketLinksUpdate()
      showToast({type: 'success', title: `Successfully updated ticket link.`})
    },
    onError: err => {
      showToast({type: 'error', title: `Something went wrong updating ticket link: ${err.response.data.error}`})
    },
  })

  const isUpdating = !!parent?.ticketLink

  const [changesMade, setChangesMade] = useState(false)
  // Need this to track changes (if isUpdating then a link already exists, if not create a new default link object)
  const initialTicketLink = isUpdating
    ? {
        ...parent.ticketLink!,
        fromTicketId: parent.id ?? '',
      }
    : {
        openOrShow: 'open',
        displayCondition: 'sold_out',
        nextTicket: child.id,
        fromTicketId: filteredTickets.at(0)?.id ?? '',
      }
  const [ticketLink, setTicketLink] = useState<Omit<ExpandedTicketLink, '_id'>>(initialTicketLink)

  useEffect(() => {
    if (isUpdating) {
      setChangesMade(JSON.stringify(ticketLink) !== JSON.stringify(initialTicketLink))
    }
  }, [ticketLink])

  const updateLocalTicketLink = (update: Partial<ExpandedTicketLink>) => {
    setTicketLink(prev => {
      return {...prev, ...update}
    })
  }

  const createNewTicketLink = async () => {
    await createTicketLink({
      eventId: eventId ?? '',
      parentTicketId: ticketLink.fromTicketId,
      childTicketId: child.id,
      openOrShow: ticketLink.openOrShow,
      displayCondition: ticketLink.displayCondition,
    })
  }

  const updateExistingTicketLink = async () => {
    await updateTicketLink({
      eventId: eventId ?? '',
      parentTicketId: ticketLink.fromTicketId,
      childTicketId: child.id,
      openOrShow: ticketLink.openOrShow,
      displayCondition: ticketLink.displayCondition,
    })
  }

  const removeExistingTicketLink = async () => {
    await removeTicketLink({
      eventId: eventId ?? '',
      parentTicketId: ticketLink.fromTicketId,
    })
  }

  return (
    <PoshStyledModal {...props}>
      <div className='TicketLinkModal'>
        <h3 className='center'>Link Ticket Tiers</h3>
        <p className='center'>{child.name}</p>
        <TicketLinkSelect
          value={ticketLink.openOrShow}
          onChange={e => updateLocalTicketLink({openOrShow: e.target.value})}
          className='TicketLinkModal-select'
          options={Object.entries(DisplayType).map(([key, value]) => {
            return [key, value]
          })}
        />
        <TicketLinkSelect
          value={ticketLink.fromTicketId}
          onChange={e => updateLocalTicketLink({fromTicketId: e.target.value})}
          className='TicketLinkModal-select'
          options={filteredTickets.map(ticket => {
            return [ticket.id, ticket.name]
          })}
        />
        <TicketLinkSelect
          value={ticketLink.displayCondition}
          onChange={e => updateLocalTicketLink({displayCondition: e.target.value})}
          className='TicketLinkModal-select'
          options={Object.entries(DisplayConditions).map(([key, value]) => {
            return [key, value]
          })}
        />

        <div className='TicketLinkModal-buttons'>
          <Button onClick={onClose} className='dark m-0'>
            Cancel
          </Button>
          {isUpdating && !changesMade && (
            <Button
              onClick={removeExistingTicketLink}
              disabled={removeIsLoading}
              isLoading={removeIsLoading}
              className='gold m-0'>
              Unlink Tiers
            </Button>
          )}
          {isUpdating && changesMade && (
            <Button
              onClick={updateExistingTicketLink}
              disabled={updateIsLoading}
              isLoading={updateIsLoading}
              className='gold m-0'>
              Update Link Tiers
            </Button>
          )}
          {!isUpdating && (
            <Button
              onClick={createNewTicketLink}
              disabled={createIsLoading}
              isLoading={createIsLoading}
              className='gold m-0'>
              Link Tiers
            </Button>
          )}
        </div>
      </div>
    </PoshStyledModal>
  )
}
