import React, {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {useGetMultiSessionEvent} from 'apis/MultiSessionEvents/useGetMultiSessionEvent'
import {useBackNavigationContext} from 'hooks/navigation/useBackNavigationContext'
import PageNotFound from 'pages/PageNotFound/PageNotFound'
import {z} from 'zod'

import {MultiSessionAnalytics} from '../components/MultiSessionAnalytics'
import {useMultiSessionEventContext} from '../context/ManageMultiSessionEventPagesContext'

const manageMultiSessionEventPageAnalyticsParamsSchema = z.object({
  groupId: z.string(),
  mseId: z.string(),
  domain: z.string(), // why do we still have this
})

function useManageMultiSessionEventPageAnalyticsParams() {
  const {groupId, mseId, domain} = useParams()
  const params = manageMultiSessionEventPageAnalyticsParamsSchema.parse({groupId, mseId, domain})
  return params
}

export function useNavigateToManageMultiSessionEventPageAnalytics() {
  const navigate = useNavigate()
  return (params: z.infer<typeof manageMultiSessionEventPageAnalyticsParamsSchema>) => {
    navigate(`/${params.domain}/groups/${params.groupId}/series/${params.mseId}/analytics`)
  }
}

export const MultiSessionAnalyticsPage = () => {
  const {groupId, mseId} = useManageMultiSessionEventPageAnalyticsParams()
  const {formDirtySafeGoBack} = useMultiSessionEventContext()
  const {pushBackNavigationFunction} = useBackNavigationContext()

  // From the analytics page (series/<id>/analytics) we always want to go back to the series main page (series/<id>)
  useEffect(() => {
    pushBackNavigationFunction(formDirtySafeGoBack)
  }, [])

  const {data: mse} = useGetMultiSessionEvent({groupId, id: mseId}, {enabled: !!groupId && !!mseId})

  if (!groupId || !mseId) {
    return <PageNotFound />
  }

  return <MultiSessionAnalytics groupId={groupId} mseId={mseId} mse={mse} />
}
