import React, {useEffect} from 'react'

import {TEventPlaylist} from 'apis/Events/playlists/useGetEventPlaylist'
import {useTrackEventPlaylistView} from 'apis/Events/playlists/useTrackEventPlaylistView'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import UserListPreview from 'components/UserListPreview'

import './styles.scss'

export type EventPlaylistProps = TEventPlaylist & {
  slug: string
  locationInput: React.ReactNode
}

export const EventPlaylist = (props: EventPlaylistProps) => {
  const {
    title,
    events,
    description,
    curator,
    slug,
    totalAttendeePreview,
    totalAttending,
    backgroundImagePalette,
    locationInput,
    backgroundImage,
  } = props

  const {trackEvent} = useMixpanel()

  const curatorDetails = (() => {
    if (curator.type === 'account') {
      return {
        name: curator.accountName,
        avi: curator.accountAvi,
        verified: curator.accountVerified,
        link: `/p/${curator.accountUsername}`,
      }
    } else {
      return {
        name: curator.groupName,
        avi: curator.groupAvi,
        verified: curator.groupVerified,
        link: `/g/${curator.groupUrl}`,
      }
    }
  })()

  // Track the view of the playlist (in our DB)
  useTrackEventPlaylistView({playlistSlug: slug}, {staleTime: Infinity, retry: false})

  // Track the view of the playlist (in Mixpanel)
  useEffect(() => {
    trackEvent('Playlist View - Playlists', {slug})
  }, [])

  return (
    <div className='EPlaylist'>
      <div className='EPlaylist-inner'>
        <img src={backgroundImage} alt={title} className='EPlaylist-bgimg' />
        <h1>{title}</h1>
        <p>{description}</p>
        <p>{events.length} events</p>
        <p>Curated by {curatorDetails.name}</p>
        <div className='EPlaylist-preview'>
          <UserListPreview>
            {totalAttendeePreview.map(({avi}, index) => (
              <UserListPreview.User key={index} imageSrc={avi} />
            ))}
          </UserListPreview>
          {totalAttending > 0 && <p>{totalAttending}+ attending</p>}
        </div>

        <div className='EPlaylist-events'>
          {events.map(event => (
            <div className='EPlaylist-event' key={event.name}>
              <h3>SAT</h3>
              <img src={event.flyer} alt={event.name} />
              <h3>{event.groupName}</h3>
              <h2>{event.name}</h2>
              <h3>{event.location}</h3>
              <Button>Get Tickets</Button>
            </div>
          ))}
        </div>
      </div>
    </div>
    // <div className={styles.EventPlaylist}>
    //   <div className={styles.EventPlaylistDetails}>
    //     <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
    //       <h1>{title}</h1>
    //       {locationInput}
    //     </div>
    //     <div className={styles.EventPlaylistSubheader}>
    //       <p>{events.length} events</p>
    //       <div style={{height: 30, width: 1, backgroundColor: 'white'}}></div>
    //       <UserListPreview>
    //         <Link to={curatorDetails.link}>
    //           <UserListPreview.User imageSrc={curatorDetails.avi} />
    //         </Link>
    //       </UserListPreview>

    //       <p className={styles.EventPlaylistCuratedBy}>
    //         Curated by{' '}
    //         <Link style={{color: 'white'}} to={curatorDetails.link}>
    //           {curatorDetails.name}
    //         </Link>
    //         {curatorDetails.verified && <VerifiedGroupBadge id={curatorDetails.name} size={15} />}
    //       </p>
    //     </div>
    //     <p className={styles.EventPlaylistDescription}>{description}</p>
    //     <motion.div
    //       initial={{opacity: 0}}
    //       animate={{opacity: 1}}
    //       transition={{duration: 0.5, ease: 'easeIn'}}
    //       className={styles.EventPlaylistAttendingPreview}>
    //       <UserListPreview>
    //         {totalAttendeePreview.map(({avi}, index) => (
    //           <UserListPreview.User key={index} imageSrc={avi} />
    //         ))}
    //       </UserListPreview>
    //       <p>
    //         <CountUp useEasing end={totalAttending} formattingFn={new Intl.NumberFormat().format} duration={2} />+
    //         attending
    //       </p>
    //     </motion.div>
    //   </div>
    //   <EventPlaylistList events={events} slug={props.slug} backgroundImagePalette={backgroundImagePalette} />
    // </div>
  )
}
