// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fullScreen__-JhOe,.content__YdMqp,.background__2ksnD,.modal__Fr29M{width:100vw;height:100dvh;top:0;left:0}.modal__Fr29M{position:fixed !important;z-index:1000 !important;overflow-x:hidden;overflow-y:scroll}.background__2ksnD{position:fixed}.content__YdMqp{z-index:1001;position:relative}", "",{"version":3,"sources":["webpack://./src/components/coreComponents/Modal/BaseModal/styles.module.scss"],"names":[],"mappings":"AAEA,oEACE,WAAA,CACA,aAAA,CACA,KAAA,CACA,MAAA,CAGF,cAEE,yBAAA,CACA,uBAAA,CACA,iBAAA,CACA,iBAAA,CAGF,mBAEE,cAAA,CAGF,gBAEE,YAAA,CACA,iBAAA","sourcesContent":["$above-all: 1000;\n\n.fullScreen {\n  width: 100vw;\n  height: 100dvh;\n  top: 0;\n  left: 0;\n}\n\n.modal {\n  @extend .fullScreen;\n  position: fixed !important;\n  z-index: $above-all !important;\n  overflow-x: hidden;\n  overflow-y: scroll;\n}\n\n.background {\n  @extend .fullScreen;\n  position: fixed;\n}\n\n.content {\n  @extend .fullScreen;\n  z-index: $above-all + 1;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullScreen": "fullScreen__-JhOe",
	"content": "content__YdMqp",
	"background": "background__2ksnD",
	"modal": "modal__Fr29M"
};
export default ___CSS_LOADER_EXPORT___;
