import React, {forwardRef} from 'react'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import {useNavigate, useSearchParams} from 'react-router-dom'

import {groupEventsByMultiSessionEvent} from '@posh/model-types'
import {HIDDEN_LOCATION_COPY} from '@posh/types'
import {EventPublic} from 'apis/Events/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import classNames from 'classnames'
import {MultisessionEventFeatureFlag} from 'components/FeatureFlag/MultisessionEventFeatureFlag'
import {PoshImage, PoshImageBackground} from 'components/PoshImage/PoshImage'
import {motion} from 'framer-motion'
import {CalendarDays, ChevronRight} from 'lucide-react'
import moment from 'moment'
import {useNavigateToMultiSessionEventPageLongUrl} from 'pages/MultiSessionEventsPublic/longUrl'

import './styles.scss'

const EventCardDate = ({start, timezone}: {start: Date | undefined; timezone: string | undefined}) => {
  const now = moment.utc()
  if (moment(start).isSame(now, 'day')) {
    return <div className='EventCard-dotw'>Today</div>
  }
  if (moment(start).isBefore(moment().add(6, 'day')) && moment(start).isAfter(now, 'day')) {
    return <div className='EventCard-dotw'> {moment.tz(start, timezone ?? 'America/New_York').format('ddd')} </div>
  }
  return (
    <>
      <div className='EventCard-month'>{moment.tz(start, timezone ?? 'America/New_York').format('MMM')}</div>
      <div className='EventCard-day'>{moment.tz(start, timezone ?? 'America/New_York').format('DD')}</div>
    </>
  )
}

interface EventCardProps {
  backgroundImage: string
  ranking?: number
  url?: string
  useTrackingLink?: boolean
  timezone?: string
  startUtc: Date
  name?: string
  groupAvi?: string
  venueName?: string
  multiSessionEvent?: {
    url: string
  }
  source: 'explore' | 'event_page' | 'profile'
}

const SKELETON_BASE_COLOR = 'rgb(68 68 68)'
const SKELETON_HIGHLIGHT_COLOR = 'rgb(80,80,80)'

export const EventCardSkeleton = () => {
  return (
    <SkeletonTheme baseColor={SKELETON_BASE_COLOR} highlightColor={SKELETON_HIGHLIGHT_COLOR} duration={0.8}>
      <Skeleton className='EventCard' style={{backgroundColor: SKELETON_BASE_COLOR}} />
    </SkeletonTheme>
  )
}

interface EventSeriesSignifierProps {
  mse: {url: string} | undefined
  text?: string
  className?: string
  source: EventCardProps['source']
}

export function EventSeriesSignifier({mse, className, source, text = 'More Dates'}: EventSeriesSignifierProps) {
  const navigate = useNavigateToMultiSessionEventPageLongUrl()
  const {trackEvent} = useMixpanel()

  const springTransition = {
    type: 'spring',
    stiffness: 500,
    damping: 30,
    mass: 1,
  }

  if (!mse) return null
  return (
    <MultisessionEventFeatureFlag.Public>
      <motion.div
        className={classNames('cursor-pointer', className)}
        initial='initial'
        whileHover='hover'
        variants={{
          hover: {
            transition: springTransition,
          },
        }}>
        <motion.div
          className='EventCard-eventSeriesSignifier-signifier flex items-center overflow-hidden'
          onClick={e => {
            e.stopPropagation()
            trackEvent('Event Series Signifier', {source})
            navigate({mseUrl: mse.url})
          }}>
          <CalendarDays color='#000' width={16} />
          <p className='noMargin pl-2 text-xs! font-medium whitespace-nowrap text-black!'>{text}</p>
          <motion.div
            className='flex items-center'
            variants={{
              initial: {width: 0, opacity: 0, marginLeft: 0},
              hover: {
                width: 'auto',
                opacity: 1,
                marginLeft: '0.25rem',
                transition: {
                  ...springTransition,
                  opacity: {duration: 0.2}, // Keep opacity on a simple duration
                },
              },
            }}>
            <ChevronRight color='#000' width={16} />
          </motion.div>
        </motion.div>
      </motion.div>
    </MultisessionEventFeatureFlag.Public>
  )
}

type EventGroup = Extract<
  ReturnType<typeof groupEventsByMultiSessionEvent<EventPublic, NonNullable<EventPublic['multiSessionEvent']>>>[number],
  {isMultiSessionGroup: true}
>

export function EventSeriesCard({eventGroup, source}: {eventGroup: EventGroup; source: EventCardProps['source']}) {
  const nextEventInSeries = eventGroup.events.at(0)
  const props: EventCardProps | undefined = nextEventInSeries
    ? {
        backgroundImage: nextEventInSeries.flyer,
        url: nextEventInSeries.url,
        timezone: nextEventInSeries.timezone,
        startUtc: nextEventInSeries.startUtc,
        name: nextEventInSeries.name,
        multiSessionEvent: eventGroup.multiSessionEvent,
        venueName: nextEventInSeries.venue.name,
        source: source,
      }
    : undefined
  if (!props) return null
  return <EventCard {...props} />
}

export const EventCard = forwardRef<HTMLDivElement, EventCardProps>((props, ref) => {
  const {backgroundImage, url, useTrackingLink, timezone, startUtc, name, groupAvi, venueName, multiSessionEvent} =
    props
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const [searchParams] = useSearchParams()

  const handleClick = () => {
    if (url) {
      trackMixpanelEvent('Event Click-  Explore Page', {
        eventUrl: url,
        when: searchParams.get('when') ?? 'This Week',
        where: searchParams.get('where') ?? 'New York City',
        sort: searchParams.get('sort') ?? 'Trending',
        rankingInExplore: String(props.ranking),
      })
      const navigateUrl = `/e/${url}${useTrackingLink ? '?t=posh' : ''}`
      navigate(navigateUrl)
    }
  }

  return (
    <PoshImageBackground
      ref={ref}
      className='EventCard'
      style={{backgroundImage: `url(${backgroundImage})`}}
      onClick={handleClick}>
      <div className='EventCard-filter' />
      <EventSeriesSignifier
        mse={multiSessionEvent}
        className='absolute! top-[15px] right-[15px]'
        source={props.source}
      />
      <div className='EventCard-info'>
        <div className='EventCard-date'>
          <EventCardDate start={startUtc} timezone={timezone} />
        </div>
        <div>
          <div className='EventCard-name'>{name ?? ''}</div>
          {/* Venue name is mandatory when creating an event, for now if it is missing its because
          it's hidden by back end logic such as approval awaiting or until event date'*/}
          <div className='EventCard-location'>{venueName || HIDDEN_LOCATION_COPY}</div>
        </div>
      </div>
      {groupAvi && <PoshImage className='EventCard-organizer' src={groupAvi} />}
    </PoshImageBackground>
  )
})

EventCard.displayName = 'EventCard'
