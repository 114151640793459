import React from 'react'

import {CSSProperties} from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import DOMPurify from 'dompurify'

import Badge from '../../../components/Badge'

import '../MessageEditor.styles.scss'
import './MessageView.styles.scss'

function capitalizePlaceholders(text: string) {
  return text.replace(/{{([^}]+)}}/g, (match, placeholder) => {
    const capitalized = placeholder.split(/(?=[A-Z])/).join(' ')
    return `{{${capitalized.toUpperCase()}}}`
  })
}

interface MessageViewProps {
  message: string
  attachedEventLink: boolean
  className?: string
  style?: CSSProperties
}

const MessageView = ({message, attachedEventLink, className, style}: MessageViewProps) => {
  return (
    <div className={classNames('ComposeYourMessage-messageContainer', className)} style={style}>
      <p
        className='text-sm'
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(capitalizePlaceholders(message)).replace(
            /{{(.*?)}}/g,
            "<span class='ComposeYourMessage-editor-badge MessageView__inlineBadge'>$1</span>",
          ),
        }}
      />
      <div className='ComposeYourMessage-settingsBadges'>
        {attachedEventLink && <Badge className='MessageView__settingsBadge'>EVENT LINK</Badge>}
      </div>
    </div>
  )
}

export default MessageView
