import {useState} from 'react'
import {useSearchParams} from 'react-router-dom'

import {GroupDisputesReturn} from 'apis/Groups/useFetchGroupDisputes'
import {CRUDTable} from 'components/TableUI/CRUDTable/CRUDTable'
import {ColumnConfig} from 'components/TableUI/CRUDTable/internals/types'
import {Check, Clock, FileText, MessageCircle, Search, X} from 'lucide-react'
import Stripe from 'stripe'

import {DisputesSummary} from '../../DisputesSummary'
import StatusBadge from './Status'
import TabsFilter from './TabsFilters'

import '../../styles.scss'

// Add helper function for time remaining
const getTimeRemaining = (date: string) => {
  const deadline = new Date(date)
  const now = new Date()
  const diff = deadline.getTime() - now.getTime()

  if (diff <= 0) return 'Expired'

  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  if (days > 0) return `${days} days left`

  const hours = Math.floor(diff / (1000 * 60 * 60))
  return `${hours} hours left`
}

interface DisputeTableProps {
  onDisputeClick: (id: string) => void
  onContactClick?: (disputeId: string, customer: string) => void
  disputes: GroupDisputesReturn
  groupId: string
  onRefresh?: () => void
}

const formatReason = (reason: string): string => {
  return reason
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const DisputeTableCRUD = ({onDisputeClick, onContactClick, disputes, groupId, onRefresh}: DisputeTableProps) => {
  const [activeFilter, setActiveFilter] = useState('all')
  const [searchQuery, setSearchQuery] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedDispute, setSelectedDispute] = useState<GroupDisputesReturn[0] | null>(null)

  // Filter disputes based on active tab
  const filterDisputes = (disputes: GroupDisputesReturn) => {
    switch (activeFilter) {
      case 'open':
        return disputes.filter(d => d.status === 'needs_response' || d.status === 'warning_needs_response')
      case 'review':
        return disputes.filter(d => d.status === 'under_review' || d.status === 'warning_under_review')
      case 'closed':
        return disputes.filter(
          d =>
            d.status === 'charge_refunded' ||
            d.status === 'lost' ||
            d.status === 'warning_closed' ||
            d.status === 'won',
        )
      default:
        return disputes
    }
  }

  // Search functionality
  const filteredDisputes = filterDisputes(disputes).filter(dispute => {
    if (searchQuery) {
      const query = searchQuery.toLowerCase()
      return (
        dispute.disputor.name.toLowerCase().includes(query) ||
        dispute.disputor?.username?.toLowerCase().includes(query) ||
        dispute.amount.toString().includes(query)
      )
    }
    return true
  })

  const filterTabs = [
    {id: 'all', label: 'All', count: disputes.length},
    {
      id: 'open',
      label: 'Open',
      count: disputes.filter(d => d.status === 'needs_response' || d.status === 'warning_needs_response').length,
    },
    {
      id: 'review',
      label: 'Under Review',
      count: disputes.filter(d => d.status === 'under_review' || d.status === 'warning_under_review').length,
    },
    {
      id: 'closed',
      label: 'Closed',
      count: disputes.filter(
        d =>
          d.status === 'charge_refunded' || d.status === 'lost' || d.status === 'warning_closed' || d.status === 'won',
      ).length,
    },
  ]

  const handleDisputeClick = (dispute: GroupDisputesReturn[0]) => {
    searchParams.set('disputeId', dispute.id)
    setSearchParams(searchParams)
    setSelectedDispute(dispute)
    onDisputeClick(dispute.id)
  }

  const handleSubmitEvidence = async () => {
    // TODO: Implement evidence submission logic
    if (onRefresh) {
      onRefresh()
    }
  }

  const columns: ColumnConfig<GroupDisputesReturn[0]>[] = [
    {
      key: 'id',
      header: 'Name',
      render: (value: string) => (
        <div className='disputes-table__id-cell'>
          <span className='disputes-table__transaction-info'>
            {disputes.find(d => d.id.toString() === value)?.disputor.name}{' '}
          </span>
        </div>
      ),
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'amount',
      header: 'Amount',
      formatter: (value: number) => `$${value.toFixed(2)}`,
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'status',
      header: 'Status',
      render: (value: Stripe.Dispute.Status) => <StatusBadge status={value} />,
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'deadline',
      header: 'Deadline',
      render: (value: string) => (
        <div className='disputes-table__deadline-cell'>
          <Clock className='mr-2 h-4 w-4' />
          <span>{getTimeRemaining(value)}</span>
        </div>
      ),
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'reason',
      header: 'Reason',
      render: (value: string, row: GroupDisputesReturn[0]) => (
        <div className='disputes-table__reason-cell'>{formatReason(row.reason)}</div>
      ),
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'outcome',
      header: 'Outcome',
      render: (value: Stripe.Dispute.Status) => (
        <div className='disputes-table__outcome-cell'>
          {value === 'won' ? (
            <>
              <Check className='h-4 w-4 text-green-500' />
              <span>Won</span>
            </>
          ) : value === 'lost' ? (
            <>
              <X className='h-4 w-4 text-red-500' />
              <span>Lost</span>
            </>
          ) : (
            <span>Pending</span>
          )}
        </div>
      ),
      sortConfig: {
        defaultSort: true,
      },
    },
    {
      key: 'id',
      header: 'Actions',
      render: (value, row: GroupDisputesReturn[0]) => {
        const dispute = row

        return (
          <div className='disputes-table__actions-cell'>
            <button
              className='TableActionButton'
              onClick={e => {
                e.stopPropagation()
                handleDisputeClick(dispute)
              }}>
              <FileText className='h-4 w-4' />
              {dispute.status === 'needs_response' || dispute.status === 'warning_needs_response' ? 'Submit' : 'View'}
            </button>
            {onContactClick && (
              <button
                className='TableActionButton'
                onClick={e => {
                  e.stopPropagation()
                  window.location.href = `mailto:${dispute.disputor.email}?subject=Regarding Your Dispute - ${dispute.id}`
                }}>
                <MessageCircle className='h-4 w-4' />
              </button>
            )}
          </div>
        )
      },
    },
  ]

  return (
    <div className='disputes-table'>
      <div className='TableHeader'>
        <h2>Dispute Management</h2>
        <p>Track and respond to all your disputes</p>
        <DisputesSummary groupId={groupId} />

        <div className='TableControls'>
          <TabsFilter tabs={filterTabs} activeTab={activeFilter} onTabChange={filter => setActiveFilter(filter)} />

          <div className='TableSearch'>
            <Search className='TableSearch__icon' />
            <input
              type='text'
              placeholder='Search disputes...'
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              className='TableSearch__input'
            />
          </div>
        </div>
      </div>

      <CRUDTable<GroupDisputesReturn[0]>
        data={filteredDisputes}
        columns={columns}
        isLoading={false}
        hideResourceName={true}
        resourceName='Dispute'
        itemsPerPage={12}
        refresh={onRefresh ? onRefresh : () => {}}
        lastUpdated={Date.now()}
        onClickRow={dispute => handleDisputeClick(dispute)}
      />
    </div>
  )
}

export default DisputeTableCRUD
