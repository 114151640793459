import React, {useEffect} from 'react'

import {useMixpanel} from 'apis/MixPanelHandler'
import PhoneNumberBadge from 'components/Badge/PhoneNumberBadge'
import moment from 'moment'

import {useFetchTextBlastDetails} from '../../../apis/TextBlasts/useFetchTextBlastDetails'
import formatPhoneNumber from '../formatPhoneNumber.helpers'
import SMSStatusCircle, {MessageStatusColorMap} from '../SMSStatus'
import ColorLegend from './ColorLegend'
import MessageView from './MessageView'

import './Details.styles.scss'

const Details = () => {
  const {data} = useFetchTextBlastDetails()

  const {phoneNumberUsed, attemptedRecipientCount, sentAt, messageStatusBreakdown, message, attachedEventLink} =
    data ?? {}
  const sent = moment(sentAt).format('MMMM D, YYYY, h:mmA')
  const {
    delivered,
    failed,
    sending,
    'opted out': optedOut,
  } = messageStatusBreakdown ?? {delivered: 0, failed: 0, sending: 0}
  const attemptedRecipients = attemptedRecipientCount ?? 0

  const {trackEvent} = useMixpanel()
  useEffect(() => {
    trackEvent('Text Blast- Breakdown Details View', {textBlastWasSentAt: sent})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const showSendingStatus = sending > 0
  const showDeliveredStatus = delivered > 0
  const showFailedStatus = failed > 0
  const showOptedOutStatus = optedOut > 0

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '40px'}}>
      <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
        <p className='m-0'>
          Phone Number used:{' '}
          {phoneNumberUsed && <PhoneNumberBadge>{formatPhoneNumber(phoneNumberUsed)}</PhoneNumberBadge>}
        </p>
        <p className='m-0'>
          Attempted Recipients: <strong>{attemptedRecipients}</strong>
        </p>
        <p className='m-0'>
          Sent: <strong>{sent}</strong>
        </p>
      </div>
      <MessageView message={message ?? ''} attachedEventLink={!!attachedEventLink} style={{marginTop: '0px'}} />
      <div className='Details__statusCircles'>
        {showSendingStatus && (
          <SMSStatusCircle size='md' status='sending' percentage={sending / attemptedRecipients}>
            {sending}
          </SMSStatusCircle>
        )}
        {showDeliveredStatus && (
          <SMSStatusCircle size='md' status='delivered' percentage={delivered / attemptedRecipients}>
            {delivered}
          </SMSStatusCircle>
        )}
        {showOptedOutStatus && (
          <SMSStatusCircle size='md' status='opted out' percentage={optedOut / attemptedRecipients}>
            {optedOut}
          </SMSStatusCircle>
        )}
        {showFailedStatus && (
          <SMSStatusCircle size='md' status='failed' percentage={failed / attemptedRecipients}>
            {failed}
          </SMSStatusCircle>
        )}
      </div>
      <div>
        {showSendingStatus && (
          <ColorLegend color={MessageStatusColorMap['sending']}>Text is still being processed</ColorLegend>
        )}
        {showDeliveredStatus && (
          <ColorLegend color={MessageStatusColorMap['delivered']}>
            Text was successfully delivered to the recipient
          </ColorLegend>
        )}
        {showOptedOutStatus && (
          <ColorLegend color={MessageStatusColorMap['opted out']}>
            Recipient has opted out of receiving text messages
          </ColorLegend>
        )}
        {showFailedStatus && (
          <ColorLegend color={MessageStatusColorMap['failed']}>
            Text was not delivered to the recipient due to a carrier issue
          </ColorLegend>
        )}
      </div>
    </div>
  )
}

export default Details
