import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {POSH_MESSAGING_AGREEMENT_STRING} from '@posh/model-types'
import {EventPublic} from 'apis/Events/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import {useDisplayGroupOptIn} from 'apis/Optins/useGetAccountOptIns'
import {useUpdateGroupOptIn} from 'apis/Optins/useUpdateGroupOptIn'
import {BellOutlined} from 'components/assets/Icons'
import Button from 'components/form/Button'
import ConfirmationModal from 'components/modals/Modal/ConfirmationModal'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'
import {useToast} from 'components/toasts/ToastProvider'
import {CalendarSvg, ReceiptSvg, ShareSvg} from 'pages/ReceiptPage/SVGs/svgs'

import AddToCalendarModal from '../AddToCalendarModal'
import EventSection from '../EventSection'

import './styles.scss'

const AttendeeActions = ({
  event,
  colorModeModifier,
  accentColor,
  attendeeCartId,
  groupName,
}: {
  event: EventPublic
  colorModeModifier: string
  accentColor: string
  attendeeCartId?: string
  groupName: string
}) => {
  const contrastColor = colorModeModifier == 'is-darkMode' ? '#0a0b0d' : '#fff'
  const {showToast} = useToast()
  const {trackEvent} = useMixpanel()

  const {shouldDisplayOptInForm, refetchOptIns} = useDisplayGroupOptIn({groupId: event.groupID})
  const {mutate: updateGroupOptIn, isLoading: isUpdatingGroupOptIn} = useUpdateGroupOptIn({
    onSuccess: () => {
      refetchOptIns()
      showToast({
        title: `Notifications enabled`,
        subtitle: `You will now receive notifications for this event and any future events from ${groupName}`,
        type: 'success',
      })
    },
  })

  const [shareModalOpen, setShareModalOpen] = useState(false)
  const [addCalendarModalOpen, setAddCalendarModalOpen] = useState(false)
  const [isOptInModalOpen, setIsOptInModalOpen] = useState(false)

  const share = () => {
    if (navigator.share) {
      navigator
        .share({
          title: event.name,
          text: event.description,
          url: window.location.href,
        })
        .then(() => console.log('Successful share'))
        .catch(error => console.log('Error sharing', error))
    } else {
      setShareModalOpen(true)
    }
  }

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href)
  }

  const navigate = useNavigate()
  const goToReceipt = () => {
    navigate(`/receipt/${attendeeCartId}`)
  }

  const onPressEnableNotifications = () => {
    setIsOptInModalOpen(true)
    trackEvent('Enable Notifications - RSVP Event Page')
  }
  return (
    <>
      <EventSection accentColor={accentColor} className='noMinHeight smallPadding'>
        <div className='AttendeeEPActions'>
          <Button
            style={{
              color: contrastColor,
              background: accentColor,
            }}
            onClick={() => share()}>
            <ShareSvg accentColor={contrastColor} />
            Share Event
          </Button>
          <Button
            style={{
              color: contrastColor,
              background: accentColor,
            }}
            onClick={() => setAddCalendarModalOpen(true)}>
            <CalendarSvg accentColor={contrastColor} />
            Add to Calendar
          </Button>
          {attendeeCartId && (
            <Button
              style={{
                color: contrastColor,
                background: accentColor,
              }}
              onClick={() => goToReceipt()}>
              <ReceiptSvg accentColor={contrastColor} />
              View Receipt &amp; QR
            </Button>
          )}
          {shouldDisplayOptInForm && (
            <Button
              style={{
                color: contrastColor,
                background: accentColor,
              }}
              onClick={onPressEnableNotifications}>
              <BellOutlined color={contrastColor} />
              Enable Notifications
            </Button>
          )}
        </div>
      </EventSection>
      <PoshStyledModal isOpen={shareModalOpen} onClose={() => setShareModalOpen(false)} center noborder>
        <h3>Share Event</h3>
        <p>
          Anyone with the link will be able to view &amp; {event.isRSVPEvent ? 'RSVP' : 'purchase tickets'} to this
          event.
        </p>
        <div className='buttonWrapper fullWidth center'>
          <Button onClick={() => copyLink()}>Copy Link</Button>
        </div>
      </PoshStyledModal>
      <AddToCalendarModal
        event={event}
        addCalendarModalOpen={addCalendarModalOpen}
        setAddCalendarModalOpen={setAddCalendarModalOpen}
      />
      <ConfirmationModal
        isOpen={isOptInModalOpen}
        onClose={() => setIsOptInModalOpen(false)}
        title={`Enable Marketing Notifications for ${groupName}`}
        bodyText={`By clicking confirm, ${POSH_MESSAGING_AGREEMENT_STRING}`}
        confirmButtonText={isUpdatingGroupOptIn ? 'Enabling Notifications...' : `Confirm`}
        onClick={() => {
          updateGroupOptIn({
            optInType: 'group-sms',
            group: {
              id: event.groupID,
            },
            optIns: {
              smsOptIn: 'yes',
            },
          })
          trackEvent('Enabled Notifications Modal Confirmation - RSVP Event Page')
        }}
      />
    </>
  )
}

export default AttendeeActions
