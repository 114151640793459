import React from 'react'

import {FeatureFlag} from '@posh/model-types'
import {EventPublic} from 'apis/Events/types'
import {IGroupLink} from 'apis/Groups'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import useSessionContext from 'domains/Auth/SessionContext'
import {useFeatureGate} from 'hooks/useFeatureGate'

import {ProfileFollowButton} from './components/ProfileFollowButton'
import PreviouslyAttendedEvents from './PastEvents'
import ProfileSocials from './Socials'
import Statistics, {Statistic} from './Statistics'
import {TProfileType} from './types'
import UpcomingEvent from './UpcomingEvent'
import UpcomingEvents from './UpcomingEvents'

import './styles.scss'

interface ProfileProps {
  _id: string
  isFetching?: boolean
  type: TProfileType
  avi?: string
  title?: string
  subtitle?: string
  statistics?: Statistic[]
  instagram?: string
  twitter?: string
  linkedIn?: string
  upcomingEvent?: EventPublic | null
  upcomingEvents?: EventPublic[]
  pastEvents?: EventPublic[] | null
  verified?: boolean
  firstName?: string
  customLinks?: IGroupLink[]
  cover?: string | undefined
  website?: string
  accentColor?: string
  location?: string
  bio?: string
  isFollowedByViewer?: boolean
  url: string
}

const Profile = (props: ProfileProps) => {
  const {
    isFetching,
    type,
    avi,
    title,
    subtitle,
    statistics,
    instagram,
    twitter,
    linkedIn,
    upcomingEvent,
    upcomingEvents,
    pastEvents,
    verified,
    firstName,
    customLinks,
    cover,
    website,
    accentColor,
    location,
    bio,
  } = props

  const {currentUser} = useSessionContext()
  const isCurrentUserProfile = currentUser?._id === props._id

  const eventseries_ff = useFeatureGate(FeatureFlag.MULTISESSIONEVENTS_PUBLIC)

  if (isFetching)
    return (
      <div className={`PProfile ${type}`}>
        <div className='PProfile-banner' />
        <div className='PProfile-avi placeholder' />
        <div className='PProfile-body'>
          <div>
            <h2 className='PProfile-body-title'>&nbsp;</h2>
            <p className='text-small'>&nbsp;</p>
          </div>
          <SpinLoader height={100} />
        </div>
      </div>
    )

  return (
    <div className={`PProfile ${type}`}>
      <div className='PProfile-banner'>
        <PoshImage src={cover ?? avi} className={cover ? 'cover' : 'avi'} />
      </div>
      <PoshImage className={`PProfile-avi ${type}`} src={avi} />
      <div className='PProfile-body'>
        <div>
          <h2 className='PProfile-body-title'>
            {title}
            {verified && <PoshImage src='https://images.posh.vip/b2/verify.png' />}
          </h2>
          <p className='text-small'>{subtitle}</p>
        </div>
        <Statistics statistics={statistics} type={type} />
        {type !== 'venue' && !isCurrentUserProfile && (
          <div className='PProfile-customLinks'>
            <ProfileFollowButton
              location={type === 'account' ? 'account-profile' : 'group-profile'}
              isFollowing={!!props.isFollowedByViewer}
              type={type}
              _id={props._id}
              url={props.url}
            />
          </div>
        )}
        {bio && <p className='m-0'>{bio}</p>}
        {(location || website) && (
          <div className='PProfile-locationLine'>
            {location && <p className='text-small'>{location}</p>}
            {location && website && <div className='PProfile-locationLine-separator' />}
            {website && (
              <p className='text-small'>
                <a href={website} rel='noreferrer' target='_blank' style={{color: accentColor ?? '#fff'}}>
                  {website}
                </a>
              </p>
            )}
          </div>
        )}
        <ProfileSocials
          instagram={instagram}
          twitter={twitter}
          linkedIn={linkedIn}
          location='profile page'
          hideLikeButton
        />
        {customLinks && customLinks.length > 0 && (
          <div className='PProfile-customLinks'>
            {customLinks.map((link, i) => (
              <a href={link.url} key={i} target='_blank' rel='noreferrer'>
                <Button className='dark'>{link.title}</Button>
              </a>
            ))}
          </div>
        )}
        {upcomingEvent && (
          <UpcomingEvent type={type} title={title} upcomingEvent={upcomingEvent} firstName={firstName} />
        )}
        {upcomingEvents && upcomingEvents.length > 1 && (
          <UpcomingEvents events={upcomingEvents} shouldGroupEventSeries={eventseries_ff && type === 'organization'} />
        )}
        {pastEvents && pastEvents.length > 1 && (
          <PreviouslyAttendedEvents
            type={type}
            pastEvents={pastEvents}
            shouldGroupEventSeries={eventseries_ff && type === 'organization'}
          />
        )}
      </div>
    </div>
  )
}

export default Profile
