import React, {ReactNode, useEffect, useState} from 'react'
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'

import classNames from 'classnames'
import {TextSelection} from 'components/assets/Icons'
import {TrackingLinks} from 'components/assets/MobileIcons'
import DeleteableBadge from 'components/DeleteableBadge/DeleteableBadge'
import Button from 'components/form/Button'
import PoshInfo from 'components/form/PoshInfo'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'
import Element from 'pages/SMSCampaign/Element'
import multipleCapitalize from 'pages/SMSCampaign/multipleCapitalize.helpers'
import {ATTENDEE_FIELDS, useEditor} from 'pages/SMSCampaign/useEditor.hooks'
import {Descendant} from 'slate'
import {Editable, Slate} from 'slate-react'
import {count} from 'sms-length'

import './MessageEditor.styles.scss'
/**
 * Maximum number of characters allowed in a GSM-7 encoded SMS message
 * GSM-7 encoding is used for messages containing only basic ASCII characters
 */
const CHARACTER_LIMIT_GSM_7 = 140

/**
 * Maximum number of characters allowed in a UTS-2 encoded SMS message
 * UTS-2 encoding is used when messages contain special characters or emojis
 */
const CHARACTER_LIMIT_UTS = 115

const MessageEditorButton = ({
  children,
  onClick,
  disabled,
}: {
  children: ReactNode
  onClick?: () => void
  disabled?: boolean
}) => {
  return (
    <Button className='small' onClick={onClick} disabled={disabled}>
      <div className='ComposeYourMessage-buttonGroup-button'>{children}</div>
    </Button>
  )
}

const MessageEditorButtons = ({
  onClick = () => {},
  disabledAttachEventLink,
}: {
  onClick?: (whichButton: string) => void
  disabledAttachEventLink: boolean
}) => {
  return (
    <div className='ComposeYourMessage-buttonGroup'>
      <MessageEditor.Button onClick={() => onClick('insert_field')}>
        <TextSelection />
        Insert Field
      </MessageEditor.Button>
      <MessageEditor.Button onClick={() => onClick('attach_event_link')} disabled={disabledAttachEventLink}>
        <TrackingLinks style={{color: disabledAttachEventLink ? '#7a7b7d' : 'black'}} />
        Attach Event Link
      </MessageEditor.Button>
    </div>
  )
}

interface MessageEditorProps {
  organizationLabel: string
  initialMessageValue?: string
  isEventLinkAttached: boolean
  onClickEventLink: (mode: 'attach' | 'remove') => void
  onMessageChange: (message: string) => void
  setGeneralMessageError: (error: string | undefined) => void
  previewMessage: string
  errorMessage?: string
}
const MessageEditor = ({
  organizationLabel,
  initialMessageValue,
  isEventLinkAttached,
  onClickEventLink,
  onMessageChange,
  setGeneralMessageError,
  errorMessage,
  previewMessage,
}: MessageEditorProps) => {
  const initialValue: Descendant[] = [{type: 'paragraph', children: [{text: initialMessageValue ?? ''}]}]
  const {editor, onChangeMessage, insertBadge, parsedMessage, adjustedMessageLength} = useEditor()
  const [messageEditorIsFocused, setMessageEditorIsFocused] = useState(false)
  const [isInsertFieldModalOpen, setIsInsertFieldModalOpen] = useState(false)
  // Check if message contains any non-GSM characters that require UTF-16 encoding
  const isUTS = count(previewMessage).encoding === 'UTF16'
  const CHARACTER_LIMIT = isUTS ? CHARACTER_LIMIT_UTS : CHARACTER_LIMIT_GSM_7

  const characterCount = isUTS ? previewMessage.length : adjustedMessageLength + organizationLabel.length

  const isMessageOverCharacterLimit = characterCount > CHARACTER_LIMIT

  useEffect(() => {
    if (isMessageOverCharacterLimit) {
      setGeneralMessageError(`Please reduce the number of characters in your message.`)
    } else {
      setGeneralMessageError(undefined)
    }
  }, [CHARACTER_LIMIT, isMessageOverCharacterLimit, setGeneralMessageError])

  const onClickMessageEditorButton = (whichButton: string) => {
    switch (whichButton) {
      case 'insert_field':
        setIsInsertFieldModalOpen(true)
        break
      case 'attach_event_link':
        onClickEventLink('attach')
        break
    }
  }

  const onClickEventLinkBadge = () => {
    onClickEventLink('remove')
  }

  const onCloseInsertFieldModal = () => {
    setIsInsertFieldModalOpen(false)
  }

  useEffect(() => {
    onMessageChange(parsedMessage ?? '')
  }, [onMessageChange, parsedMessage])

  const handleBeforeInput = (event: InputEvent) => {
    const {inputType, dataTransfer} = event
    const enteringTextGoesOverLimit = inputType === 'insertText' && characterCount >= CHARACTER_LIMIT
    const pastingTextGoesOverLimit =
      inputType === 'insertFromPaste' && characterCount + (dataTransfer?.getData('text').length ?? 0) > CHARACTER_LIMIT
    if (enteringTextGoesOverLimit || pastingTextGoesOverLimit) {
      event.preventDefault()
      return
    }
  }

  if (initialMessageValue === undefined) {
    return <Skeleton height={100} baseColor='#305da4' style={{borderRadius: '20px'}} />
  }

  return (
    <div>
      <MessageEditor.Buttons onClick={onClickMessageEditorButton} disabledAttachEventLink={isEventLinkAttached} />
      <div className={classNames('ComposeYourMessage-messageContainer', {highlighted: messageEditorIsFocused})}>
        <span className='ComposeYourMessage-messageContainer-organizer'>{organizationLabel}</span>
        <Slate editor={editor} initialValue={initialValue} onChange={onChangeMessage}>
          <Editable
            onDOMBeforeInput={handleBeforeInput}
            className='ComposeYourMessage-editor'
            renderElement={props => <Element {...props} />}
            onFocus={() => setMessageEditorIsFocused(true)}
            onBlur={() => setMessageEditorIsFocused(false)}
            placeholder='Enter your message here...'
          />
        </Slate>
        <div className='ComposeYourMessage-settingsBadges'>
          {isEventLinkAttached && (
            <DeleteableBadge variant='blue' onClick={onClickEventLinkBadge}>
              EVENT LINK
            </DeleteableBadge>
          )}
        </div>
      </div>
      <p
        className={classNames('ComposeYourMessage-characterCount m-0', {
          'ComposeYourMessage-characterCount-error': characterCount >= CHARACTER_LIMIT,
        })}>
        {errorMessage && <p className='error m-0'>{errorMessage}</p>}
        {characterCount >= CHARACTER_LIMIT && (
          <p className='error m-0 text-xs'>{`Texts above ${CHARACTER_LIMIT} characters are unable to be sent. Use of Emojis and other special characters reduce the limit to 115 characters.`}</p>
        )}
        {characterCount}/{CHARACTER_LIMIT} Characters
        <PoshInfo info={`Texts above ${CHARACTER_LIMIT} characters are likely to be blocked by carriers.`} />
      </p>
      <PoshStyledModal
        isOpen={isInsertFieldModalOpen}
        width={580}
        onClose={onCloseInsertFieldModal}
        contentClassName='InsertFieldModal'>
        <h3 className='center m-0'>Insert Field</h3>
        <div className='InsertFieldModal-section'>
          <p className='center grey-light text-xxs m-0'>ATTENDEE INFO</p>
          <div className='InsertFieldModal-buttonGroup'>
            {ATTENDEE_FIELDS.map(field => (
              <Button key={field} onClick={() => insertBadge(field)}>
                {multipleCapitalize(field)}
              </Button>
            ))}
          </div>
        </div>
      </PoshStyledModal>
    </div>
  )
}

MessageEditor.Buttons = MessageEditorButtons
MessageEditor.Button = MessageEditorButton

export default MessageEditor
