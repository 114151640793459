import React from 'react'

import BallOfTruth from 'components/BallOfTruth'
import {requirePermissionAndScope} from 'components/RequirePermissions'
import RequirePermissions from 'components/RequirePermissions'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import moment from 'moment-timezone'

import {EventAnalyticsSnapshot} from './EventAnalyticsSnapshot'
import {EventsTableListItemProps} from './types'

import '../index.scss'

export function EventsTableListItemContent(props: EventsTableListItemProps) {
  const {item, status, groupId, search} = props
  const {domain} = useGroupMatchParams()
  const eventId = item._id as string
  return (
    <>
      <div className='flex flex-1 flex-col gap-1'>
        <a href={`/${domain}/groups/${groupId}/events/${eventId}/overview${search}`}>
          <h5 className='hover:underline'>{item.name}</h5>
        </a>
        <p className='color-secondary text-sm'>
          {moment.tz(item.startUtc, item.timezone).format('MMMM Do YYYY, h:mm a')}
        </p>
        <div
          className={`EventPageSectionHeader-details--status ${
            status === 'draft' ? 'orange' : status === 'live' ? 'green' : ''
          }`}>
          <BallOfTruth isLive={status === 'live'} isDraft={status === 'draft'} />{' '}
          {status[0].toUpperCase() + status.substring(1)}
        </div>
      </div>
      <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_sales_analytics', ['event', 'group'])]}>
        <EventAnalyticsSnapshot eventId={eventId} isFree={item.isFree} />
      </RequirePermissions>
    </>
  )
}
