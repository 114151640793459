import {OrderResponse} from '@posh/model-types'
import {CurrencyCode} from '@posh/types'
import {IOrderFee} from 'apis/Checkout'
import {IOrderPaymentPlan} from 'apis/Checkout'
import {EventModel, EventPublic, EventTable} from 'apis/Events/types'
import IAffiliation from 'apis/Kickback/Affiliation'

export interface OrderTicketModel {
  id: string
  name: string
  price: number
  typeID: string
  scanned: boolean
  sentToTransferDocId?: string
}

export interface RefundMetaData {
  refundedAt?: Date
  totalRefunded?: number
  error?: string
}

export interface OrderModel {
  _id: string
  tickets: OrderTicketModel[]
  stripeCustomerId: string
  accountID: string
  subtotal: number
  processingFee?: number
  total: number
  redeemed?: boolean
  promoCode?: string
  discount?: number
  barcode?: number
  eventID?: string
  host?: string
  groupID?: string
  trackingLink?: string
  scanned?: boolean
  scannedAt?: Date
  transferGroup?: string
  paymentIntent?: string
  refunded?: boolean
  refundId?: string
  refundMetaData?: RefundMetaData
  gratuity?: number
  tables: EventTable[]
  feeBreakdown?: IOrderFee[]
  totalFees?: number
  accountName: string
  accountEmail?: string
  eventName?: string
  number?: number
  paymentPlan?: IOrderPaymentPlan[]
  availableForResell?: boolean
  isComplimentaryOrder?: boolean
  isTapToPayOrder?: boolean
  partialRefund?: number
  kickbackAmount?: number
  createdAt: Date
  cartId?: string
  disputeId?: string
  affiliateId?: string
  currency?: CurrencyCode
  canceled?: boolean
  customFieldResponses?: OrderResponse[]
  fromTransferDocId?: string
  accountAvi?: string
}

export interface OrderPageModel {
  order: OrderModel
  event: EventModel
  riskLevel?: string
  riskScore?: number
  affiliation?: IAffiliation
}

export interface OrderAndName extends OrderModel {
  name?: string
}

export interface OrderReceiptResponse {
  order: OrderModel
  event: EventPublic
}

export const OrderTags = [
  'financed',
  'refunded',
  'scanned',
  'disputed',
  'tables',
  'canceled',
  'kickback',
  'created-via-ticket-transfer',
  'purchased-in-person',
] as const
export type OrderTag = (typeof OrderTags)[number]
export interface OrdersForTicket extends OrderAndName {
  phone?: string
  email?: string
}
