import React from 'react'

import {Link} from 'lucide-react'

interface TrackingLinkCardProps {
  trackingLink?: string
}

export const TrackingLinkCard = (props: TrackingLinkCardProps) => {
  const {trackingLink} = props
  if (!trackingLink) return null
  return (
    <div className='ApprovalModal-Content-trackingLinkWrapper'>
      <Link height={16} width={16} />
      <p className='m-0'>{trackingLink}</p>
    </div>
  )
}
