import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {useGetMultiSessionOrders} from 'apis/MultiSessionEvents/useGetMultiSessionOrders'
import {OrderTag} from 'apis/Orders'
import useDebounce from 'hooks/useDebounce'
import {OrderList} from 'pages/EventManagementPages/OverviewUpdate/Analytics/Orders/OrderList/OrderList'
import {OrdersSearchFilterContainer} from 'pages/EventManagementPages/OverviewUpdate/Analytics/Orders/OrdersSearchFilterContainer'
import {useResourcePageParams} from 'pages/PoshAppLayout'

interface MultiSessionOrdersListProps {
  groupId: string
  mseId: string
}

export const MultiSessionOrdersList = (props: MultiSessionOrdersListProps) => {
  const [search, setSearch] = useState<string>()
  const debouncedSearch = useDebounce(search, 300)
  const [filters, setFilters] = useState<OrderTag[]>([])
  const {domain} = useResourcePageParams()
  const navigate = useNavigate()

  const {
    data: orders,
    isLoading,
    isError,
    isSuccess,
  } = useGetMultiSessionOrders({
    groupId: props.groupId,
    mseId: props.mseId,
    limit: 50,
    activeFilters: filters,
    search: debouncedSearch,
  })

  const hasFilters = filters.length > 0 || !!debouncedSearch

  const navigateToOrdersPage = () => {
    navigate(`/${domain}/groups/${props.groupId}/orders`)
  }

  return (
    <OrdersSearchFilterContainer
      search={search}
      setSearch={setSearch}
      filters={filters}
      setFilters={setFilters}
      navigateToOrdersPage={navigateToOrdersPage}
      isRSVPEvent={false}>
      <>
        {isLoading && <OrderList.Loading />}

        {isError && <OrderList.Error isRSVPEvent={false} />}

        {isSuccess && <OrderList hasFilters={hasFilters} orders={orders} isRSVPEvent={false} />}
      </>
    </OrdersSearchFilterContainer>
  )
}
