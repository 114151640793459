import React from 'react'
import {PropsWithChildren} from 'react'

const CheckoutFieldResponseLabel = ({children}: PropsWithChildren<unknown>) => {
  return <td>{children}</td>
}

interface ValueProps {
  onClick?: () => void
  className?: string
}

const CheckoutFieldResponseValue = ({children, onClick, className = ''}: PropsWithChildren<ValueProps>) => {
  return (
    <td onClick={onClick}>
      {children}
      {!children && 'No Response'}
    </td>
  )
}

const CheckoutFieldResponse = ({children}: PropsWithChildren<unknown>) => {
  return <tr>{children}</tr>
}

CheckoutFieldResponse.Label = CheckoutFieldResponseLabel
CheckoutFieldResponse.Value = CheckoutFieldResponseValue

export default CheckoutFieldResponse
