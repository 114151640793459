import { mapTokenValues } from './map-token-values';
const defaults = { base: 16, beta: false };
export const mapDimensionTokensToPixels = (dimensionTokens, props = defaults) => mapTokenValues(dimensionTokens, (token) => convertDimensionTokenToPixel(token, props));
export const convertDimensionTokenToPixel = (dimension, { base, beta } = defaults) => {
    let { value, unit } = parseDimension(dimension.value);
    // default
    if (!beta)
        return unit === 'px' ? value : value * base;
    // beta using snap points
    else {
        // … so convert to rem
        if (unit === 'px')
            value = value / base;
        // Example of how dimension scale would apply to snap points
        // value =
        //   dimension.scale === 'size'
        //     ? snapTo(1 / 2, value)
        //     : dimension.scale === 'spacing'
        //     ? snapTo(1 / 4, value)
        //     : snapTo(1 / 8, value);
        // Snap all dimensions to 2px increments (with 16px base)
        return snapTo(1 / 8, value) * base;
    }
};
export const parseDimension = (dimension) => {
    const [, raw, unit] = dimension.match(/(\d+(?:\.\d+)?|(?:\.\d+))\s*(rem|px)/) ?? ['', 0, 'rem'];
    const value = Number(raw);
    if (Number.isNaN(dimension)) {
        throw new Error(`"${dimension}" is not a number`);
    }
    return { value, unit };
};
/**
 * Snaps values to a certain precision
 * Example: snapTo(.25, .3125rem) === .25rem
 *          snapTo(4, 5px) === 4px
 */
export const snapTo = (interval, value) => {
    return Math.round(value / interval) * interval;
};
