import React from 'react'

import {ICrossSection, useGetSectionalData} from 'apis/Analytics/useGetSectionalData'
import {CrossSection} from 'pages/EventManagementPages/OverviewUpdate/Analytics/CrossSection/CrossSection'
import {parseNumberValue} from 'pages/EventManagementPages/OverviewUpdate/Analytics/Shared/utils'

import {Text} from '../EventManagementPages/OverviewUpdate/Analytics/Shared/Text/Text'

interface SummaryProps {
  eventId?: string
  groupId: string
  isLoading?: boolean
}

const getLabel = (dataPointType: ICrossSection['type'], label?: string) => {
  if (!label) return null
  return <Text size='small'>{label}</Text>
}

export const DisputesSummary = (props: SummaryProps) => {
  const {eventId, groupId} = props

  const {data, isLoading, isError} = useGetSectionalData(
    {
      eventId,
      groupId,
      dataPoints: ['openDisputes', 'disputesUnderReview', 'closedDisputes'],
    },
    {
      enabled: props.isLoading !== true,
    },
  )

  if (props.isLoading || isLoading) {
    return (
      <CrossSection.Section>
        {[...Array(3)].map((_, index) => (
          <CrossSection key={index}>
            <CrossSection.Row>
              <Text.Loading width={150} />
            </CrossSection.Row>
            <CrossSection.Row>
              <Text.Loading size='xl' width={100} />
            </CrossSection.Row>
            <CrossSection.Row>
              <Text.Loading size='small' />
            </CrossSection.Row>
          </CrossSection>
        ))}
      </CrossSection.Section>
    )
  }

  if (isError) {
    return (
      <CrossSection.Section
        style={{display: 'flex', width: '100%', height: 136, justifyContent: 'center', paddingTop: '30px'}}>
        <CrossSection>
          <CrossSection.Row>
            <Text size='xl' bold>
              Error
            </Text>
          </CrossSection.Row>
          <CrossSection.Row>
            <Text>Something went wrong. Contact support@posh.vip for help.</Text>
          </CrossSection.Row>
        </CrossSection>
      </CrossSection.Section>
    )
  }

  return (
    <CrossSection.Section>
      {data.map((crossSection, index) => {
        return (
          <CrossSection key={index}>
            <CrossSection.Row>
              <Text>{crossSection.title}</Text>
            </CrossSection.Row>
            <CrossSection.Row>
              <Text size='xl' bold>
                {parseNumberValue(crossSection.mainValue)}
              </Text>
            </CrossSection.Row>
            <CrossSection.Row>{getLabel(crossSection.type, crossSection.label)}</CrossSection.Row>
          </CrossSection>
        )
      })}
    </CrossSection.Section>
  )
}
