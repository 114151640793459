import React from 'react'

import getEventFlyer from 'apis/Util/getEventFlyer'
import {PoshImage} from 'components/PoshImage/PoshImage'

import {EventsTableListItemProps} from './types'

import '../index.scss'

export function EventsTableListItemImage({item}: EventsTableListItemProps) {
  return <PoshImage className='EventTable-row-image' src={getEventFlyer(item)} transformOptions={{width: 200}} />
}
