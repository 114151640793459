// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dateGroup__W-4FL{margin-bottom:20px}.dateGroup__W-4FL>h4{margin:24px 0;font-weight:500;font-size:28px;letter-spacing:-1px}", "",{"version":3,"sources":["webpack://./src/pages/MultiSessionEventsPublic/page/components/MultiSessionEventsPublicDateGroup/styles.module.scss"],"names":[],"mappings":"AAAA,kBACE,kBAAA,CAEA,qBACE,aAAA,CACA,eAAA,CACA,cAAA,CACA,mBAAA","sourcesContent":[".dateGroup {\n  margin-bottom: 20px;\n\n  > h4 {\n    margin: 24px 0;\n    font-weight: 500;\n    font-size: 28px;\n    letter-spacing: -1px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateGroup": "dateGroup__W-4FL"
};
export default ___CSS_LOADER_EXPORT___;
