import {WebSessionAccount} from '@posh/models'
import {captureException} from '@sentry/react'
import {POSH_PIXEL_ID, trackPurchaseSubtotal, trackPurchaseSuccess as trackPurchaseSuccessfb} from 'apis/FbPixelHelpers'
import {OrderReceiptResponse} from 'apis/Orders'

import {pushToDataLayer} from '../dataLayerUtils'

interface PurchaseEventData {
  receipt: OrderReceiptResponse
  user?: WebSessionAccount
}

export const trackPurchaseSuccess = ({receipt, user}: PurchaseEventData) => {
  try {
    const pixelData = {
      eventName: receipt?.event.name,
      currency: receipt?.order.currency ?? 'USD',
      ticketCount: receipt?.order.tickets.length,
    }

    // A pixel ID set on the event is one used by the organizer directly
    if (receipt.event.fbPixelId) {
      trackPurchaseSuccessfb({pixelId: receipt?.event.fbPixelId, value: receipt?.order.subtotal, ...pixelData})
    }

    trackPurchaseSuccessfb({
      pixelId: POSH_PIXEL_ID,
      value: receipt.order.feeBreakdown?.find(f => f.name === 'Processing Fee')?.totalFee ?? 0,
      ...pixelData,
    })
    trackPurchaseSubtotal({pixelId: POSH_PIXEL_ID, value: receipt?.order.subtotal, ...pixelData})

    const purchaseEventData = {
      event: 'purchase', // Mandatory event name
      orderId: receipt?.order?._id.toString(),
      eventId: receipt?.event?.id,
      eventName: receipt?.event?.name,
      currency: receipt?.order?.currency ?? 'USD',
      subtotal: receipt?.order?.subtotal,
      ticketCount: receipt?.order?.tickets?.length,
      tickets: receipt?.order?.tickets,
      purchaserEmail: receipt?.order?.accountEmail,
      purchaserName: receipt?.order?.accountName,
      purchaserId: receipt?.order?.accountID,
      purchaserPhone: user?.phone,
      feesTotal: receipt?.order?.totalFees,
    }

    // Push the event data to the dataLayer event
    pushToDataLayer(purchaseEventData)
  } catch (error) {
    captureException(error)
  }
}
