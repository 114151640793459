import React from 'react'
import {Control} from 'react-hook-form'

import {ClockWithCircle} from 'components/assets/Icons'
import {ToggleableEye} from 'components/assets/ToggleableEye'
import {EventVisualsDateTimeInput} from 'components/PageComponents/EventVisuals/Form/Input/DateInput'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import moment from 'moment-timezone'

import styles from '../styles.module.scss'

interface EventVisualsStartEndDateTimesProps {
  control: Control<EventVisualsForm>
  displayEndTime?: boolean
  setDisplayEndTime: (displayEndTime: boolean) => void
  formTimezone: string
  startUtc?: string
  endUtc?: string
  accentColor: string
  lightMode: boolean
  textContrasting: string
  ICON_SIZE: number
}

// upon opening the picker, the default date is 1 hour from now
const defaultInitialFocusedTime = moment().minute(0).second(0).millisecond(0).add(1, 'hours')
// if the start time is set, the default date is 1 hour from the start time
export const getDefaultEndInitialFocusedTime = (startUtc: moment.MomentInput | undefined) =>
  !startUtc || !moment(startUtc).isValid() ? defaultInitialFocusedTime : moment(startUtc).add(1, 'hours')
// if the end time is set, the default date is 1 hour from the end time
export const getDefaultStartInitialFocusedTime = (endUtc: moment.MomentInput | undefined) =>
  !endUtc || !moment(endUtc).isValid() ? defaultInitialFocusedTime : moment(endUtc).subtract(1, 'hours')

export const EventVisualsStartEndDateTimes = (props: EventVisualsStartEndDateTimesProps) => {
  const {
    control,
    displayEndTime,
    setDisplayEndTime,
    formTimezone,
    startUtc,
    endUtc,
    accentColor,
    lightMode,
    textContrasting,
    ICON_SIZE,
  } = props
  return (
    <div className='flex flex-col gap-2'>
      <div className={styles.StartEndDatesContainer} data-tip data-for='timezone'>
        <EventVisualsDateTimeInput.DateTime.Controlled
          control={control}
          name='startUtc'
          placeholder='Start time'
          rules={{
            required: 'Start time is required',
            validate: (value: any) => {
              const momentEndUtc = moment(endUtc)
              const momentStartUtc = moment(value)
              return endUtc && value && momentStartUtc.isBefore(momentEndUtc)
            },
          }}
          accentColor={accentColor}
          lightMode={lightMode}
          leftIcon={<ClockWithCircle height={ICON_SIZE} width={ICON_SIZE} color={accentColor} />}
          timezone={formTimezone || moment.tz.guess()}
          displayTimezone
          initialFocusedDate={getDefaultStartInitialFocusedTime(endUtc)}
          openTo={!!endUtc ? 'hours' : undefined}
        />
        <EventVisualsDateTimeInput.DateTime.Controlled
          control={control}
          name='endUtc'
          accentColor={accentColor}
          placeholder='End time'
          rules={{
            required: 'End time is required',
            validate: (value: any) => {
              const momentStartUtc = moment(startUtc)
              const momentEndUtc = moment(value)
              return startUtc && value && momentStartUtc.isBefore(momentEndUtc)
            },
          }}
          lightMode={lightMode}
          rightIcon={
            <ToggleableEye
              size={ICON_SIZE}
              onClick={() => setDisplayEndTime(!displayEndTime)}
              visible={displayEndTime || false}
              color={textContrasting}
            />
          }
          timezone={formTimezone}
          displayTimezone
          initialFocusedDate={getDefaultEndInitialFocusedTime(startUtc)}
          openTo={!!startUtc ? 'hours' : undefined}
        />
      </div>
      {startUtc && endUtc && moment(startUtc).isAfter(moment(endUtc)) && (
        <p className='noMargin text-xs text-red-300!'>Start time must be before end time</p>
      )}
    </div>
  )
}
