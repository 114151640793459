import React from 'react'
import {Control, useController} from 'react-hook-form'
import ReactTooltip from 'react-tooltip'

import {getTimezoneInformation} from '@posh/model-types'
import {useGetTimezoneMutation} from 'apis/Util/useGetTimezone'
import {MultiSessionEventsOrganizerFeatureFlag} from 'components/FeatureFlag/MultiSessionEventsOrganizerFeatureFlag'
import {EventVisualsMarkdownEditor} from 'components/PageComponents/EventVisuals/Form/Input/MarkdownInput'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {EventVisualsRecurrenceSettings} from 'components/PageComponents/EventVisuals/Form/Recurrence/EventVisualsRecurrenceSettings'
import {EventVisualsSection} from 'components/PageComponents/EventVisuals/Page/Section'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {EventTitleFontStyleProvider} from 'components/Text/EventTitleFontStyleProvider'
import {useToast} from 'components/toasts/ToastProvider'
import {useDimensions} from 'hooks/useDimensions'
import moment from 'moment-timezone'

import {useEventVisualsContext} from '../../context/EventVisualsContext'
import {EventVisualsCombinedVenueAddressInput} from './inputs/EventVisualsCombinedVenueAddressInput'
import {EventVisualsStartEndDateTimes} from './inputs/EventVisualsStartEndDateTimes'

interface EventDetailsFormSectionProps {
  control: Control<EventVisualsForm>
  showShortDescription?: boolean
  showDescription?: boolean
  type: 'create' | 'edit'
}

const ICON_SIZE = 16

function Timezone(props: {timezone: string | undefined}) {
  const timezone = props.timezone || moment.tz.guess()
  const text = timezone.replace(/_/g, ' ').replace(/\//g, ', ')
  return (
    <p className='m-0 text-xs italic' style={{color: 'white'}}>
      Timezone: {text}
    </p>
  )
}

export function EventDetailsFormSection(props: EventDetailsFormSectionProps) {
  const {control, showShortDescription = true, showDescription = false, type} = props
  const {isMobile} = useDimensions()
  const {showToast} = useToast()
  const {
    palette: {accentColor, lightmode: lightMode, textContrasting},
    fontFamily,
  } = useEventVisualsContext()

  const {
    field: {onChange: setDisplayEndTime, value: displayEndTime},
  } = useController({
    control,
    name: 'displayEndTime',
  })

  const {
    field: {value: startUtc, onChange: onChangeStartUtc},
  } = useController({
    control,
    name: 'startUtc',
  })
  const {
    field: {value: endUtc, onChange: onChangeEndUtc},
  } = useController({
    control,
    name: 'endUtc',
  })
  const {
    field: {value: formTimezone, onChange: onChangeFormTimezone},
  } = useController({
    control,
    name: 'timezone',
  })
  const {
    field: {onChange: setDescriptionOnForm, value: description},
  } = useController({
    control,
    name: 'description',
  })
  const {
    field: {value: recurrence, onChange: onChangeRecurrence},
  } = useController({
    control,
    name: 'recurrence',
  })

  const {mutate: getAndUpdateTimezoneFromLocation} = useGetTimezoneMutation({
    onSuccess: data => {
      const newTimezone = data?.timezone
      const previousTimezone = formTimezone
      if (newTimezone && previousTimezone && newTimezone !== previousTimezone) {
        onChangeFormTimezone(newTimezone)

        // to maintain the same local time, we need to update the start and end times

        if (startUtc) {
          const startLocal = moment(startUtc).tz(previousTimezone)
          const newStartLocal = startLocal.clone().tz(newTimezone, true)
          onChangeStartUtc(newStartLocal.utc().toISOString())
        }

        if (endUtc) {
          const endLocal = moment(endUtc).tz(previousTimezone)
          const newEndLocal = endLocal.clone().tz(newTimezone, true)
          onChangeEndUtc(newEndLocal.utc().toISOString())
        }

        const {friendlyName} = getTimezoneInformation(newTimezone)
        showToast({
          type: 'info',
          title: `Timezone Updated`,
          subtitle: `Since you changed the location of your event, we've updated the timezone to ${friendlyName}`,
        })
      }
    },
  })

  const isCreateEvent = type === 'create'

  return (
    <>
      <EventVisualsSection headerText='Event Details'>
        <EventVisualsTextInput.Text.Controlled
          control={control}
          lightMode={lightMode}
          accentColor={accentColor}
          name='name'
          placeholder='My Event'
          autoComplete='none'
          rules={{required: 'Event name is required'}}
          size={isMobile ? 30 : 36}
          fontFamily={fontFamily}
        />
        <ReactTooltip id='timezone' effect='solid' place='right'>
          <Timezone timezone={formTimezone} />
        </ReactTooltip>
        <EventVisualsStartEndDateTimes
          control={control}
          displayEndTime={displayEndTime}
          setDisplayEndTime={setDisplayEndTime}
          formTimezone={formTimezone || moment.tz.guess()}
          startUtc={startUtc}
          endUtc={endUtc}
          accentColor={accentColor}
          lightMode={lightMode}
          textContrasting={textContrasting}
          ICON_SIZE={ICON_SIZE}
        />
        <MultiSessionEventsOrganizerFeatureFlag>
          {isCreateEvent && (
            <EventVisualsRecurrenceSettings
              type={type}
              recurrence={recurrence}
              onChangeRecurrence={onChangeRecurrence}
              startUtc={startUtc}
              onChangeStartUtc={onChangeStartUtc}
              endUtc={endUtc}
              timezone={formTimezone}
              displayEndTime={displayEndTime}
              setDisplayEndTime={setDisplayEndTime}
              control={control}
            />
          )}
        </MultiSessionEventsOrganizerFeatureFlag>
        <EventVisualsCombinedVenueAddressInput
          control={control}
          onChangeAddress={placeResult => {
            const lat = placeResult.geometry?.location?.lat()
            const lon = placeResult.geometry?.location?.lng()
            if (lat && lon) getAndUpdateTimezoneFromLocation({lat, lon})
          }}
        />
        {showShortDescription && (
          <EventVisualsTextInput.Text.Controlled
            control={control}
            lightMode={lightMode}
            accentColor={accentColor}
            name='shortDescription'
            placeholder='Short Event Summary (optional)'
          />
        )}
        {showDescription && (
          <EventVisualsMarkdownEditor
            placeholder={'Show your attendees what they can expect from your event.'}
            value={description ?? ''}
            onChange={setDescriptionOnForm}
          />
        )}
      </EventVisualsSection>
      <EventTitleFontStyleProvider eventTitleFont={fontFamily} />
    </>
  )
}
