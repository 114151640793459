import React from 'react'
import {Controller, ControllerFieldState, ControllerRenderProps, FieldValues, Path} from 'react-hook-form'

import classNames from 'classnames'
import {ControlledFormProps} from 'components/DynamicForm/Inputs/shared/types'
import {LocationInput, LocationInputProps, UseLocationInputRefParams} from 'components/form/PoshLocationInput'
import {getEventTitleFont} from 'components/Text/EventTitleFontStyleProvider'

import {EventVisualsTextInputWrapper, EventVisualsTextInputWrapperProps} from './EventVisualsTextInputWrapper'

import styles from './styles.module.scss'

type EventVisualsLocationInputProps = LocationInputProps &
  EventVisualsTextInputWrapperProps & {
    icon?: React.ReactNode
    rightIcon?: React.ReactNode
    googlePlacesInputOptions: Omit<UseLocationInputRefParams, 'onPlaceSelected'>
    onPlaceSelected: (place: google.maps.places.PlaceResult) => void // make required and top-level
  }

/**
 * Note: Do not use this directly. Use it as EventVisualsTextInput.Location instead
 */
export function EventVisualsLocationInput(props: EventVisualsLocationInputProps) {
  const {googlePlacesInputOptions, onPlaceSelected, icon, rightIcon, required, value, ...rest} = props
  const {lightMode, accentColor, fontFamily} = rest

  const DEFAULT_FONT_SIZE = 16

  const lightModeClassName = lightMode ? styles.light : styles.dark
  const inputClassName = classNames(styles.input, lightModeClassName)
  const fontSize = props.size ?? DEFAULT_FONT_SIZE

  const isOutlined = required === true && !value

  return (
    <EventVisualsTextInputWrapper.Row {...rest} isOutlined={isOutlined}>
      {icon}
      <LocationInput
        {...props}
        googlePlacesInputOptions={{
          ...googlePlacesInputOptions,
          onPlaceSelected: place => {
            onPlaceSelected(place)
          },
        }}
        className={inputClassName}
        style={{fontSize, color: accentColor, fontFamily: getEventTitleFont(fontFamily)}}
        placeholder={props.placeholder + (props.required ? '*' : '')}
      />
      {rightIcon}
    </EventVisualsTextInputWrapper.Row>
  )
}

type ControlledEventVisualsLocationInputProps<T extends FieldValues, K extends Path<T>> = Omit<
  EventVisualsLocationInputProps,
  'value' | 'onPlaceSelected'
> &
  ControlledFormProps<T, K> & {
    formatPlace: (data: google.maps.places.PlaceResult) => string
    onPlaceSelected?: EventVisualsLocationInputProps['onPlaceSelected'] // make optional
  }

type LocationFieldProps<T extends FieldValues> = {
  field: ControllerRenderProps<T, Path<T>>
  fieldState: ControllerFieldState
  formatPlace: (data: google.maps.places.PlaceResult) => string
  onPlaceSelected?: (place: google.maps.places.PlaceResult) => void
  googlePlacesInputOptions: Omit<UseLocationInputRefParams, 'onPlaceSelected'>
  isRequired: boolean
  props: Omit<EventVisualsLocationInputProps, 'value' | 'onPlaceSelected' | 'googlePlacesInputOptions'>
}

const LocationField = <T extends FieldValues>({
  field,
  fieldState,
  formatPlace,
  onPlaceSelected,
  googlePlacesInputOptions,
  isRequired,
  props,
}: LocationFieldProps<T>) => {
  return (
    <>
      <EventVisualsLocationInput
        {...props}
        {...field}
        {...fieldState}
        value={field.value}
        onChange={e => {
          field.onChange(e)
        }}
        required={isRequired}
        googlePlacesInputOptions={googlePlacesInputOptions}
        onPlaceSelected={place => {
          onPlaceSelected && onPlaceSelected(place)
          field.onChange(formatPlace(place))
        }}
      />
    </>
  )
}

function ControlledLocationInput<T extends FieldValues, K extends Path<T>>({
  name,
  control,
  googlePlacesInputOptions,
  formatPlace,
  onPlaceSelected,
  rules,
  ...props
}: ControlledEventVisualsLocationInputProps<T, K>) {
  const isRequired = typeof rules?.required === 'object' ? rules.required.value : !!rules?.required

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field, fieldState}) => (
        <LocationField
          field={field}
          fieldState={fieldState}
          formatPlace={formatPlace}
          onPlaceSelected={onPlaceSelected}
          googlePlacesInputOptions={googlePlacesInputOptions}
          isRequired={isRequired}
          props={props}
        />
      )}
    />
  )
}

EventVisualsLocationInput.Controlled = ControlledLocationInput
