// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".row__Ki3hB,.ListItem__fX8R2,.Input__srqVw{display:flex;flex-direction:row;gap:10px;align-items:center}.Input__srqVw{justify-content:space-between;width:100%}.Results__Ez48R{gap:10px;overflow:hidden}.ListItem__fX8R2{margin:10px 0;cursor:pointer}.TrashIconContainer__ygQ3d{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/PageComponents/EventVisuals/Form/Input/DropdownSearchInput/styles.module.scss"],"names":[],"mappings":"AAAA,2CACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,kBAAA,CAIF,cAEE,6BAAA,CACA,UAAA,CAIF,gBACE,QAAA,CACA,eAAA,CAEF,iBAEE,aAAA,CACA,cAAA,CAEF,2BACE,cAAA","sourcesContent":[".row {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n  align-items: center;\n}\n\n\n.Input {\n  @extend .row;\n  justify-content: space-between;\n  width: 100%;\n}\n\n\n.Results {\n  gap: 10px;\n  overflow: hidden;\n}\n.ListItem {\n  @extend .row;\n  margin: 10px 0;\n  cursor: pointer;\n}\n.TrashIconContainer {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "row__Ki3hB",
	"ListItem": "ListItem__fX8R2",
	"Input": "Input__srqVw",
	"Results": "Results__Ez48R",
	"TrashIconContainer": "TrashIconContainer__ygQ3d"
};
export default ___CSS_LOADER_EXPORT___;
