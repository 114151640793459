import React, {useMemo} from 'react'

import {Ticket} from 'apis/Events/types'
import {GetAllEventOrdersOutput, Order} from 'apis/Events/useGetAllEventOrders'
import {CRUDTable} from 'components/TableUI/CRUDTable/CRUDTable'
import {ColumnConfig} from 'components/TableUI/CRUDTable/internals/types'
import {TableCell} from 'components/TableUI/TableCell/TableCell'
import {useNavigateToOrgPages} from 'hooks/navigation/useNavigateToOrgPages'

interface TicketOrdersTableProps {
  ticket: Ticket
  ordersWithTicket: NonNullable<GetAllEventOrdersOutput>
  currencySymbol: string
  onRefresh: () => void
  dataUpdatedAt: number
}

const TicketOrdersTable = (props: TicketOrdersTableProps) => {
  const {ticket, ordersWithTicket, dataUpdatedAt, onRefresh, currencySymbol} = props
  const {navigateToOrderPage} = useNavigateToOrgPages()

  const columnConfigs = useMemo<ColumnConfig<Order>[]>(() => {
    return [
      {
        header: 'Name',
        key: 'accountName',
        render: accountName => <TableCell.Text text={accountName} bold />,
        sortConfig: {
          defaultSort: true,
        },
      },
      {
        header: 'Email',
        key: 'accountEmail',
        render: accountEmail => <TableCell.Text text={accountEmail ?? 'No Email'} />,
      },
      {
        header: 'Date Created',
        key: 'createdAt',
        render: createdAt => <TableCell.Text text={createdAt ? new Date(createdAt).toLocaleDateString() : 'No Date'} />,
        sortConfig: {
          defaultSort: true,
        },
      },
      {
        header: 'Qty',
        key: 'tickets',
        render: tickets => {
          return <TableCell.Text text={tickets.filter(t => t.typeID === ticket.id).length.toString()} />
        },
        sortConfig: {
          defaultSort: true,
        },
      },
      {
        header: 'Total',
        key: 'subTotal',
        render: subTotal => <TableCell.Text text={`${currencySymbol}${subTotal}`} />,
        sortConfig: {
          defaultSort: true,
        },
      },
    ]
  }, [])

  return (
    <div style={{paddingBottom: '200px'}}>
      <CRUDTable
        data={ordersWithTicket ?? []}
        lastUpdated={dataUpdatedAt}
        refresh={onRefresh}
        columns={columnConfigs}
        itemsPerPage={10}
        resourceName={`Orders for ${ticket.name}`}
        searchableColumn='accountName'
        onClickRow={order => navigateToOrderPage(order._id)}
      />
    </div>
  )
}

export default TicketOrdersTable
